import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setupPage } from '../../../components/Layout/setupPage';
import { 
    Section,
    Button,
    Label,
    SimpleSelect,
    ValidateField,
    ErrorFeedback,
    NavLink
} from '../../../components';

import { ErrorMessage } from '../../components/partials/ErrorMessage';

import  * as actions  from '../../../store/actions';
import * as actionTypes from '../../../store/actionType';
import { Authority } from '../../../components';
import { getIconUrl } from '../../../common/images-catalogue';
import { CabinetIcon , ColorSwatch} from './FileCabinet';
import { Indicator, Option, SelectedOption } from '../CreateUpdate';
import { ApiRequestManager } from '../../../utility/ApiRequestManager';
import { pictureInitials } from '../../../utility/helpers';
import { Caret } from '../../../utility/Caret';
import { Loader } from '../../components/partials/LoadingIndicator';
import ConfirmModal from '../../components/partials/ConfirmModal';
import { ACTION_BAR_MODES, REQUESTS_TYPE } from '../../../common/constant';
import './FileCabinet.style.scss';
import { PromptMessage, getPromptMessageProps } from '../../components/partials/PromptMessage';

const COLORS = [
    '#0099FF',
    '#00D36B',
    '#FF692B',
    '#9760FF',
    '#FF315D',
    '#F7D71E',
];

const mapStateToProps = state => ({
    useClientLayout: state.global.useClientDashboard,
    apiRequestManager: ApiRequestManager.getInstance(ApiRequestManager),
    ...state.specimen.samplesState,
});

const mapDispatchToProps = dispatch => ({
    getAllClients: () => dispatch(actions.getAllAccountsAction()),
    unLoad: () => dispatch(actions.cabinetModalUnLoadAction()),
    submitFormData: (payload) => dispatch(actions.submitCabinetAction(payload)),
});

class CabinetModal extends React.Component {

    static pageOption = {pageTitle: 'Client'};

    viewModes = {
        client: 'client',
        cabinet: 'cabinet'
    }

    constructor (props) {
        super(props);
        this.state = {
            selectedClient: null,
            selectedSwatch: '',
            cabinetLabel: 'Label',
            selectedColor: '#cdcdcd',
            formErrors: { },
            viewMode: this.viewModes.cabinet,
            isSavingCabinet: false,
            cabinetContextMode: props.cabinetMode,
            shouldShowConfirmModal: false,
            isDeleting: false,
            defaultWasSet: false,
        }

        this.clientSelectRef = React.createRef();
        this.labelFieldRef = React.createRef();
        
        this.onLabelNameChange = this.onLabelNameChange.bind(this);
        this.didSelectColorSwatch = this.didSelectColorSwatch.bind(this);
        this.handleSwtichView = this.handleSwtichView.bind(this);
        this.didSelectClientOption = this.didSelectClientOption.bind(this);
        this.composeClientSelectOption = this.composeClientSelectOption.bind(this);
        this.submitData = this.submitData.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.submitDelete = this.submitDelete.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.showHideConfimStatusDialog = this.showHideConfimStatusDialog.bind(this);
        this.setDefaultData = this.setDefaultData.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.getPromptMessage = this.getPromptMessage.bind(this);

        props.apiRequestManager.register(actionTypes.FETCH_ACCOUNTS, props.getAllClients);
        props.apiRequestManager.register(actionTypes.CABINET_SUBMIT_DATA, props.submitFormData);
    }

    componentDidMount() {
        if(!this.props.apiRequestManager.inProgress(actionTypes.FETCH_ACCOUNTS) && !this.props.useClientLayout){
            this.props.apiRequestManager.queueRequest(actionTypes.FETCH_ACCOUNTS);
        }

        this.unlistenAbort = this.props.history.listen(() => { 
            this.props.apiRequestManager.abortRequest(actionTypes.FETCH_ACCOUNTS);
        });

    }

    componentWillUnmount(){
        this.unlistenAbort();
        this.props.setCabinetContextItem(null);
    }

    static getDerivedStateFromProps(nextProps, prevState){
        let stateUpdates = null;

        if(nextProps.apiRequestManager.inProgress(actionTypes.CABINET_SUBMIT_DATA) !== prevState.isSavingCabinet){
            stateUpdates = {
                isSavingCabinet: nextProps.apiRequestManager.inProgress(actionTypes.CABINET_SUBMIT_DATA),
            }
        }
        /**
         * @Ricardo, feel free to refactor this and find another way to do it -- I don't really like this
         * way.
         * Basically a way to clear state after deleting cabinate
         */
        if(!nextProps.apiRequestManager.inProgress(actionTypes.CABINET_SUBMIT_DATA) && prevState.isDeleting){
            stateUpdates = stateUpdates ?? {};
            
            const apiOutcome = nextProps.apiRequestManager.getApiOutcome(actionTypes.CABINET_SUBMIT_DATA);
            const deleteAttempt = apiOutcome?.requestType === REQUESTS_TYPE.DELETE;

            if(deleteAttempt) stateUpdates.isDeleting = false;

            if(deleteAttempt && apiOutcome?.success) {
                nextProps.setCabinetContextItem(null);
                stateUpdates = {
                    selectedClient: null,
                    selectedSwatch: '',
                    cabinetLabel: 'Label',
                    selectedColor: '#cdcdcd',
                    isSavingCabinet: false,
                    cabinetContextMode: ACTION_BAR_MODES.create,
                }
            }
            nextProps.clearSpecimentData();
            nextProps.clearCabinetStates();
        }

        if (!prevState.isSavingCabinet && nextProps.cabinetContextItem) {
            return{
                cabinetContextMode: ACTION_BAR_MODES.edit
            }
        }

        return stateUpdates;
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.cabinetMode === ACTION_BAR_MODES.edit && !prevState.defaultWasSet && this.props.clientsList){
            /**
             * Need to wait until accounts are fetched before this can ever be called.
             */
            this.setDefaultData();
        }

        if (prevState.isDeleting && !this.props.cabinetContextItem) {
            this.props.showHideModal(false);
        }
    }

    setDefaultData() {
        const cabinetContextItem = this.props.cabinetContextItem;
        const formattedClients = !Array.isArray(this.props.clientsList) ? [] : this.props.clientsList.map((client) => {
            return this.composeClientSelectOption(client);
        });

        if( cabinetContextItem ) {
            const defaultClient = formattedClients.find(client => client.clientId === cabinetContextItem.client.id);

            this.setState({
                selectedClient: defaultClient,
                cabinetLabel: cabinetContextItem.label,
                selectedColor: cabinetContextItem.color,
                selectedSwatch: cabinetContextItem.color.replace('#', ''),
                defaultWasSet: true,
            });
        }
    }

    didSelectColorSwatch(id, color) {
        this.setState({
            selectedSwatch: id,
            selectedColor: color
        });
    }

    onLabelNameChange(evt) {
        const pattern = /<br>/gi;
        let value = this.labelFieldRef.current.innerHTML.replace(pattern, '').replace(/(<div><\/div>)/gi, '').replace(/&nbsp;/gi, '');

        const isEmpty = value === '';
        
        const stateUpdate = {
            formErrors: {
                ...this.state.formErrors,
                labelMissing: isEmpty,
            } 
        }
        if (evt.type === 'blur') {
            stateUpdate.cabinetLabel = value;
        }
        this.setState({
          ...stateUpdate
        });

        //Caret.setEndContenteditable(evt.target);
    }

    handleSwtichView(view) {
        this.setState({
            viewMode: view
        })
    }

    didSelectClientOption(client) {
        this.setState({
            selectedClient: client,
            viewMode: this.viewModes.cabinet,
            formErrors: {
                ...this.state.formErrors,
                clientMissing: false
            }
        })
    }

    composeClientSelectOption(account) {
        const { clientInfo } = account;
        
        return {
            ...account,
            id: account.id,
            label: clientInfo ? `${clientInfo.firstName} ${clientInfo.lastName}` : `${account.firstName} ${account.lastName}`,
            value: account.accountNo,
            firstName: clientInfo ? clientInfo.firstName : account.firstName,
            lastName:  clientInfo ? clientInfo.lastName : account.lastName
        }
    }

    submitData(){
        const formErrors = this.validateFields();
        if(!Object.blank(formErrors)){
            this.setState({
                formErrors: {
                    ...this.state.formErrors,
                    ...formErrors,
                }
            });
            return;
        }

        const cabinetContextModeMap = {
            [ACTION_BAR_MODES.create]: REQUESTS_TYPE.SUBMIT,
            [ACTION_BAR_MODES.edit]: REQUESTS_TYPE.UPDATE,
        }

        const formData = {
            label: this.state.cabinetLabel,
            color: this.state.selectedColor,
            client: this.state.selectedClient.clientId,
        }

        if(this.state.cabinetContextMode === ACTION_BAR_MODES.edit) {
            formData.cabinetId = this.props.cabinetContextItem.id
        }

        const formPayload = {
            requestType: cabinetContextModeMap[this.state.cabinetContextMode],
            data: formData,
        }

        this.props.apiRequestManager.queueRequest(actionTypes.CABINET_SUBMIT_DATA, formPayload);
        this.setState({
            isSavingCabinet: true,
        });
    }

    onDelete(event){
        event.preventDefault();

        this.setState({
            shouldShowConfirmModal: !this.state.shouldShowConfirmModal,
        });
    }

    confirmDelete(contextItem){
        this.submitDelete(contextItem);
    }

    submitDelete(cabinate){
        const formPayload = {
            requestType: REQUESTS_TYPE.DELETE,
            data: {
                cabinetId: cabinate.id
            },
        }

        this.props.apiRequestManager.queueRequest(actionTypes.CABINET_SUBMIT_DATA, formPayload);
        this.setState({
            isSavingCabinet: true,
            shouldShowConfirmModal: !this.state.shouldShowConfirmModal,
            isDeleting: true,
        });
    }

    //TODO: Change to use the delegate pattern
    validateFields() {
        const formErrors = {};

        if (!this.state.selectedClient) {
            formErrors.clientMissing = true;
        }

        if (!this.state.cabinetLabel) {
            formErrors.labelMissing = true;
        }

        return formErrors;
    }

    showHideConfimStatusDialog(){
        this.setState({
            shouldShowConfirmModal: !this.state.shouldShowConfirmModal,
        });
    }

    closeModal() {
        this.props.showHideModal(false)
        this.props.setCabinetContextItem(null);
    }

    getPromptMessage() {
        const apiOutcome = this.props.apiRequestManager.getApiOutcome(actionTypes.CABINET_SUBMIT_DATA, true) ?? {};
        return getPromptMessageProps('cabinet', apiOutcome);
    }

    render () {
        const clients = !Array.isArray(this.props.clientsList) ? [] : this.props.clientsList.map((client) => {
            return this.composeClientSelectOption(client);
        });

        const selectedClient = this.state.selectedClient;
        const isSavingCabinet = this.state.isSavingCabinet;
        const isEditing = this.props.cabinetContextItem ? true : false;
        const showLoader = isEditing && this.props.apiRequestManager.inProgress(actionTypes.FETCH_ACCOUNTS);

        const promptProps = this.getPromptMessage();

        return (
            <React.Fragment>
            <div 
            
            className="cbt-modal-wrap">
                <div className="cbt-modal-content">
                    {
                        promptProps.visible && <PromptMessage {...promptProps} />
                    }

                    <Section className='head-section'>
                    <div className="title-box">
                            <div className="title-area">
                                <h1>{ isEditing ? 'Edit ' : 'Create ' }Cabinet</h1>
                            </div>
                            <div className="slide-pane__close">
                            <Button className="close-btn"  
                            onClick={this.closeModal}>
                                <img src={getIconUrl('close')} alt=""/>
                            </Button>
                            </div>
                        </div>
                    </Section>
                    {showLoader ? 
                    <div className="modal__loader-container">
                        <Loader visible={showLoader} loadingText="Loading..." /> 
                    </div> :
                    <Section className='cabinet__body-section'>
                        
                        <div className='content-area'>
                            { this.state.viewMode === this.viewModes.client ?
                            <Section className="block select-client">
                            <h3 className="frm-sec-title">Clients</h3>
                            <div className="select-wrapper">
                                <div className="select-well">
                                    { 
                                    <ValidateField required>
                                    <ErrorFeedback 
                                        error
                                        show={this.state.formErrors.clientMissing}
                                        filter='valueMissing'
                                        message={<ErrorMessage message ='Please select a client' />} />
                                    <SimpleSelect
                                        components={{
                                            Indicator: Indicator,
                                            Option: Option,
                                            SelectedOption: SelectedOption
                                        }}
                                        ref={this.clientSelectRef}
                                        className="ip-ff-select"
                                        name="Client"
                                        title="Choose client"
                                        searchable={["Type Name or account No.", "No matching client"]}
                                        filterKeys={['firstName',
                                        'lastName', 'accountNo']}
                                        list={clients}
                                        onChange={this.didSelectClientOption}
                                        initialSelected={ this.state.selectedClient }
                                        disabled={false}
                                        />
                                        
                                    </ValidateField>
                                    }
                                    
                                </div>
                            </div>
                            <div className='return-lnk'>
                                <NavLink 
                                className="arrow-link" 
                                to={''}
                                onClick={(evt) => this.handleSwtichView(this.viewModes.cabinet)}
                                >
                                    <span className="arrow-indicator"></span>Go Back
                                </NavLink>
                            </div>
                            </Section>
                            :

                            <React.Fragment>

                            <Section className="block">
                                <div className='cabinet-label-warp'>
                                    <span 
                                    ref={this.labelFieldRef}
                                    onInput={this.onLabelNameChange}
                                    onBlur={this.onLabelNameChange}
                                    className='ip-text-field' contentEditable="true"
                                    suppressContentEditableWarning={true}>{this.state.cabinetLabel}</span>
                                </div>
                            </Section>
                            <Section className="block">
                                <div className='color-box'>
                                    <span>Colors</span>
                                    <div className='colors-swatches-wrap'>
                                        <ul className='color-swatches'>
                                            {COLORS.map((color, i) => (
                                                <ColorSwatch 
                                                key={i}
                                                id={color}
                                                color={color}
                                                selected={this.state.selectedSwatch === color.replace('#', '')}
                                                onClick={this.didSelectColorSwatch}/>)
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </Section>
                            <Section className="block">
                                <div className='cabinet-lnk-wrap'>
                                    <div className='cbt-box'>
                                        <div className='cbl-col cabinet-hld'>
                                            <div className='cbt-icon'>
                                                <CabinetIcon 
                                                    color={this.state.selectedColor}
                                                />
                                                <label>{this.state.cabinetLabel}</label>
                                            </div>
                                        </div>
                                        <div className='cbl-col lnk-hld'>
                                            <div className='lnk-icon'>
                                                <img src={getIconUrl('chainLink')} alt='' />
                                            </div>
                                        </div>
                                        <div className='cbl-col lnk-client-hld'>
                                            <div className='btn-wrap'>
                                                { selectedClient ? 
                                                (<div 
                                                    onClick={() => this.handleSwtichView(this.viewModes.client)}
                                                className='linked-client'>
                                                    <div className='client-item'>
                                                        <div className='initials'>
                                                            {pictureInitials(selectedClient.clientInfo.firstName, selectedClient.clientInfo.lastName)}
                                                        </div>
                                                        <Label className='client-name'>
                                                            {`${selectedClient.clientInfo.firstName} ${selectedClient.clientInfo.lastName}`}
                                                        </Label>
                                                        <span className='cleint-identifier'>{selectedClient.accountNo}</span>
                                                    </div>
                                                </div>)
                                                :
                                                (<Button
                                                onClick={() => this.handleSwtichView(this.viewModes.client)}
                                                className={`${this.state.formErrors.clientMissing ? '--error' : ''}`}>
                                                    <span className='icon'>
                                                        <img src={getIconUrl('plus')} alt='' />
                                                    </span>
                                                    <div>
                                                        Link Client
                                                    </div>
                                                </Button>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Section>
                            <Section className="block">
                                <p className='desc'>Linking a client to a cabinet organizies all the client's specimen records in one place.</p>
                            </Section>
                            <Section className='cabinet-action-wrapper'>
                                <Authority allowed='delete' entity='cabinet'>
                                <React.Fragment>
                                    { !isSavingCabinet && this.props.cabinetContextItem && (
                                    <Button onClick={this.onDelete} disabled={isSavingCabinet}  className="del-btn">
                                        <span className='icon'>
                                            <img src={getIconUrl('trash')} alt={''} />
                                        </span>
                                        Delete</Button>)}
                                </React.Fragment>
                                </Authority>
                                <Authority allowed='create' entity='cabinet'>
                                <React.Fragment>
                                    {!isSavingCabinet && <Button onClick={this.submitData} disabled={isSavingCabinet} className="save-btn">Save</Button>}
                                </React.Fragment>
                                </Authority>
                                <Loader inline visible={isSavingCabinet} loadingText={''} />
                            </Section>
                            </React.Fragment>
                            }
                        </div>
                    </Section>
                    }

                    {this.state.shouldShowConfirmModal && <ConfirmModal 
                        contextName='Cabinet'
                        contextItem={this.props.cabinetContextItem} 
                        isDelete={true}
                        cancelCallback={this.showHideConfimStatusDialog}
                        confirmCallback={this.confirmDelete}
                    />}
                </div>
            </div>
            </React.Fragment>
        );
    }
}

const CabinetModalView = (props) => {
    return (
        <CabinetModal {...props } />
    );
};

let ConfigCabinetModalView = setupPage(CabinetModal.pageOption)(CabinetModalView);
ConfigCabinetModalView =  connect(mapStateToProps, mapDispatchToProps)(ConfigCabinetModalView);

export default ConfigCabinetModalView ;