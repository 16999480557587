import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
    NavLink as Link,
} from './../../../components';

import { PermissionEnforcer } from '../../../utility/PermissionEnforcer';
import { getIconUrl } from '../../../common/images-catalogue';

const RoleItem = (props) => {
    const { role, isAssigned, eventHanlder, disable } = props;
    const classnames = classNames('role-item', isAssigned ? 'selected': '');
    role.assigned = isAssigned;

    const willClick = () => {
        !disable && eventHanlder(role);
    };

    return  (role.type !== 'User' ? null : 
        <React.Fragment>
        <li>
            <Link onClick={ willClick }>
                <div className={ classnames }>
                    <div className="role-item-perm">
                        { isAssigned && !disable  ? 
                        <button className="remove-button">
                            <img src={ getIconUrl("close") } alt=''/>
                        </button>
                        : null
                        }

                        <span className="role-icon">
                            <img src={ getIconUrl("role") } alt=''/>
                            <p>{ role.role }</p>
                        </span>
                        
                        <span className="role-info">
                            is super role: { role.superRole ? 'YES': 'NO' }
                        </span>
                    </div>
                    {disable && 
                    <span className='role-lock lock-icon'>
                        <img src={getIconUrl('lock')} alt='' />
                    </span>
                    }
                </div>
            </Link>
        </li>
        </React.Fragment>
    );
}

RoleItem.propTypes = {
   eventHanlder: PropTypes.func,
   isAssigned: PropTypes.bool,
   disable: PropTypes.bool,
   //key: PropTypes.number
}

RoleItem.defaultProps = {
    isAssigned: false,
    disable: false,
    //key: null
 }

export default RoleItem;