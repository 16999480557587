import React from 'react';
import PropType from 'prop-types';
import { getIconUrl } from '../../../common/images-catalogue';

const TopClient = (props) => {
    const {data, clickHandler} = props;

    const type = data.clientInfo.type;

    const clientName = data.clientInfo.firstName + ' ' + data.clientInfo.lastName;
    const name = type === 'Doctor' ? clientName: data.clientInfo.officeName !== null ? data.clientInfo.officeName: clientName;
    
    const account = data.clientInfo.accountNo;
    const pictureSrc = data.picture ? data.picture.fileDownloadUri : getIconUrl('profile');

    return (<div className="client-widget" onClick={ () => clickHandler(data)}>
        <div className="inner">
            <div className="client-picture">
                <img src={pictureSrc} alt='' />
            </div>
            <div className="client-details">
                <h3 className="name">{name}</h3>
                <p className="type">{type}</p>
                <p className="actno">AC# {account}</p>
            </div>
        </div>
    </div>)
}

TopClient.propTypes = {
    data: PropType.object
};

export default TopClient;