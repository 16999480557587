import * as actionTypes from '../actionType';
import { isError } from '../../service/agent';
import { VALIDATOR_DELEGATE_KEY_GROUP } from '../../common/constant';

const initialState = {
    viewInit: false,
    employees: null,
    currentEmployee: null,
    payAdvices: { },

    employeeForm: {
        employeeNo: '',
        [VALIDATOR_DELEGATE_KEY_GROUP.formFields.firstName]: '',
        [VALIDATOR_DELEGATE_KEY_GROUP.formFields.lastName]: '',
        [VALIDATOR_DELEGATE_KEY_GROUP.formFields.middleName]: '',
        [VALIDATOR_DELEGATE_KEY_GROUP.formFields.email]: '',
        gender: '',
        [VALIDATOR_DELEGATE_KEY_GROUP.formFields.dateOfBirth]: '',
        [VALIDATOR_DELEGATE_KEY_GROUP.formFields.hiredDate]: '',
        [VALIDATOR_DELEGATE_KEY_GROUP.formFields.department]: null,
        user: null,
        profileImage: null,
        details: {
            payCycle: '',
            [VALIDATOR_DELEGATE_KEY_GROUP.formFields.nis]: '',
            [VALIDATOR_DELEGATE_KEY_GROUP.formFields.trn]: '',
            [VALIDATOR_DELEGATE_KEY_GROUP.formFields.phoneNumber]: '',
            [VALIDATOR_DELEGATE_KEY_GROUP.formFields.hourlyRate]: 0.0,
            [VALIDATOR_DELEGATE_KEY_GROUP.formFields.salary]: 0.0,
            [VALIDATOR_DELEGATE_KEY_GROUP.formFields.sickDays]: '',
            [VALIDATOR_DELEGATE_KEY_GROUP.formFields.vacationDays]: '',
            fixedSalary: false
        },
        address: { }
    }
 };

var error = null;
let emptyArray = [];

const employeeReducer = (state = initialState, action ) => { 

    switch(action.type) {
        case actionTypes.EMPLOYEES_VIEW_UNLOAD:
            return {
                ...initialState,
                viewInit: true,
                employee: state.employee ? state.employee : null
            }
        case actionTypes.EMPLOYEES_CREATE_VIEW_UNLOAD:
            return {
                ...initialState,
            }
        case actionTypes.FETCHED_EMPLOYEES_RESULT: 
            error = isError(action.payload)
            return {
                ...state,
                employees: error ? emptyArray : action.payload
            };
        case actionTypes.FETCHED_EMPLOYEE_RESULT: 
            error = isError(action.payload)
            return {
                ...state,
                currentEmployee: error ? null : action.payload
            };
        case actionTypes.SET_SELECTED_EMPLOYEE:
            return {
                ...state,
                currentEmployee: action.payload
            }
        case actionTypes.EMPLOYEE_EDIT_SETDATA:
            return {
                ...state,
                ...action.payload
            }
        case actionTypes.EMPLOYEE_FIELD_CHANGED :
            return {
                ...state,
                employeeForm: {
                ...state.employeeForm,
                ...action.payload
            }
        }
        case actionTypes.FETCH_USERS_RESULTS :
            error = isError(action.payload);
            return {
                ...state,
            users: error ? emptyArray : action.payload
        }
        case actionTypes.FETCHED_DEPARTMENTS_RESULTS :
            error = isError(action.payload);
            return {
                ...state,
            departments: error ? emptyArray : action.payload
        }
        case actionTypes.FETCHED_EMPLOYEE_PAYADVICE_RESULT :
            error = isError(action.payload);
            
            const payadvice = error ? state.payAdvices : action.payload.empty() ? emptyArray : {
                ...state.payAdvices,
                [action.payload[0].employee]: action.payload
            }
            
            return {
                ...state,
                payAdvices: payadvice
        }
        case actionTypes.EMPLOYEE_SUBMITED_RESULTS :
            error = isError(action.payload);
            if (state.requestType === 'delete') {
                let filteredEmplyees = error && !action.payload.deletedId ? state.employees : state.employees.filter((item) => item.id !== action.payload.deletedId);

                return {
                    ...state,
                    employees: filteredEmplyees
                }
            }else {
                return {
                    ...state,
                    employee: error ? state.employee : action.payload
                }
            }
            
        case actionTypes.EMPLOYEE_SUBMIT_DATA :
            return {
                ...state,
            requestType: action.payload.requestType
        }

        case actionTypes.TERMINATE_EMPLOYEE_RESULT:
            error = isError(action.payload);
            return {
                ...state,
                employees: error ? state.employees : state.employees.map(employee => {
                    if(employee.id === action.payload.employeeId){
                        return {
                            ...employee,
                            terminated: true,
                        }
                    }
                    
                    return employee;
                })
            }
            
        default:
            return state;
    }
};

export default employeeReducer;