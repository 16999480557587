import * as actionTypes from '../../actionType';
import { isError } from '../../../service/agent';

export const codeFindingState = {
    codeFindingSetting : {
        codeFindingsData: null
    }
}
    
var error = null;
let empty = {};
let emptyArray = [];

const codeFindingReducer = (state, action ) => { 
    switch(action.type) {
        case actionTypes.SET_CODEFINDINGS_DATA:
            return {
                ...state,
                codeFindingSetting: {
                    ...state.codeFindingSetting,
                    codeFindingsData: action.payload
                }
            }
        case actionTypes.FETCHED_CODE_FINDINGS_RESULTS:
            error = isError(action.payload);
            return {
                ...state,
                codeFindingSetting: {
                    ...state.codeFindingSetting,
                    codeFindingsData: error ? emptyArray : action.payload.map((item, i) => {
                        return {
                            index: i,
                            ...item
                        }
                    })
                }
            }
        case actionTypes.CODEFINDING_SUBMIT_DATA:
            return {
                ...state,
                requestType: action.payload.requestType,
                isSaving: true
            }
        case actionTypes.CODEFINDING_SUBMIT_DATA_RESULT:
            error = isError(action.payload);
            if (state.requestType === 'delete') {
                const filteredFindings =  error ? state.codeFindingSetting.codeFindingsData  : state.codeFindingSetting.codeFindingsData.filter((item) => item.id !== action.payload.deletedId);
                return {
                    ...state,
                    isSaving: false,
                    codeFindingSetting: {
                        ...state.codeFindingSetting,
                        codeFindingsData: filteredFindings
                    }
                }
            }else {
                return {
                    ...state,
                    isSaving: false,
                    codeFindingSetting: {
                        ...state.codeFindingSetting,
                        codeFindingsData: error ? state.labcodeSetting.codeFindingsData : action.payload.map((item, i) => {
                            return {
                                index: i,
                                ...item
                            }
                        })
                    }
                }
            }
        default:
            return state;
    }
};

export default codeFindingReducer;
