
import {ofType } from 'redux-observable';
import { catchError, map, switchMap, mergeMap, takeUntil } from 'rxjs/operators';
import *  as actionTypes from '../actionType';
import { patientDialect, checkReponse } from '../../service/agent';
import { from, defer, of } from 'rxjs';
import { apiResponseActionCreator } from '../actions';

export const getAllPatientsEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.FETCH_PATIENTS),
        
        switchMap((action) => {
            const request$ =  defer(() => from(patientDialect.fetchAllPatients(action.payload)));
            return request$.pipe(
               map(response => {
                    const responseData = checkReponse(response, action.type);
                    return apiResponseActionCreator(actionTypes.FETCHED_PATIENTS_RESULT, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.FETCH_PATIENTS + actionTypes.ABORT_API_REQUEST))),
                catchError(error => {
                    console.log("ERROR", error);
                    //requestEnded(actionTypes.FETCH_PATIENTS);
                    return of(apiResponseActionCreator(actionTypes.FETCHED_PATIENTS_RESULT, error));
               })
            );
        })
    )};
export const getPatientEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.FETCH_PATIENT),
        
        switchMap((action) => {
            return patientDialect.fetchPatient(action.payload).pipe(
                map(response => {
                    const responseData = checkReponse(response, action.type);
                    return apiResponseActionCreator(actionTypes.FETCHED_PATIENT_RESULT, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
                catchError(error => {
                    console.log("ERROR", error);
                    //requestEnded(actionTypes.FETCH_PATIENTS);
                    return of(apiResponseActionCreator(actionTypes.FETCHED_PATIENT_RESULT, error));
                })
            );
        })
    )};

export const submitPatientEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.PATIENT_SUBMIT_DATA),
        
        mergeMap((action) => {
          const apiCalls = {
            'delete': patientDialect.deletePatient,
            'create': patientDialect.submitPatient,
            'update': patientDialect.updatePatient
          }
            return apiCalls[action.payload.requestType](action.payload).pipe(
               map(response => {
                    const responseData = checkReponse(response, action.type, action.payload.requestType);
                    return apiResponseActionCreator(actionTypes.PATIENT_SUBMITED_RESULTS, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
               catchError(error => {
                    console.log("ERROR", error);
                    return of(apiResponseActionCreator(actionTypes.PATIENT_SUBMITED_RESULTS, error));
               })
            );
        })
  )};

export const getClientPatientsEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.FETCH_CLIENT_PATIENTS),
        
        switchMap((action) => {
            return patientDialect.fetchClientPatients(action.payload).pipe(
            map(response => {
                    const responseData = checkReponse(response, action.type);
                    return apiResponseActionCreator(actionTypes.FETCHED_CLIENT_PATIENTS_RESULTS, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
            catchError(error => {
                    console.log("ERROR", error);
                    //requestEnded(actionTypes.FETCH_PATIENTS);
                    return of(apiResponseActionCreator(actionTypes.FETCHED_CLIENT_PATIENTS_RESULTS, error));
            })
            );
        })
    )};

export const searchPatientsEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.FETCH_SEARCH_PATIENTS),
        
        switchMap((action) => {
            return patientDialect.searchPatients(action.payload).pipe(
            map(response => {
                    const responseData = checkReponse(response, action.type);
                    return apiResponseActionCreator(actionTypes.FETCHED_SEARCH_PATIENTS_RESULTS, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
            catchError(error => {
                    console.log("ERROR", error);
                    //requestEnded(actionTypes.FETCH_PATIENTS);
                    return of(apiResponseActionCreator(actionTypes.FETCHED_SEARCH_PATIENTS_RESULTS, error));
            })
            );
        })
    )};