import React from 'react';
import {
    SimpleSelect,
    FormGroup,
    Input,
    Label
} from './../../../components';

import { FINDING_NORMALITY } from './../../../common/constant';

const Filter_RP02 = (props) => {
    const {reportForm, actionType, reportFieldChange, codeSheets, codeFindings } = props;
    
    const didSelectCodeSheet = (codeSheet) => {
        const fieldSet = {
            options: {
                ...reportForm.options,
                codeSheet: codeSheet.value
            }
        }
        reportFieldChange(actionType, fieldSet);
    }
    const didSelectCodeFinding = (finding) => {
        const fieldSet = {
            options: {
                ...reportForm.options,
                codeFinding: finding.value.trim()
            }
        }
        reportFieldChange(actionType, fieldSet);
    }

    const handlePillSwitches = (evt) => {

        let fieldSet = {
            options: {
                ...reportForm.options,
                normality: evt.target.value === FINDING_NORMALITY.Abnormal ? true : false
            }
        }
        reportFieldChange(actionType, fieldSet);
    }

    const codeSheetsList = codeSheets.map((codeSheet, i) => ({
        value: codeSheet.abbreviation,
        label: `${codeSheet.abbreviation} - ${codeSheet.description}`
    }));

    const codeFindingsList = codeFindings.map((finding, i) => ({
        value: finding.abbreviation,
        label: `${finding.abbreviation} - ${finding.description}`
    }));

    
    const normality = reportForm.options.normality !== null ? reportForm.options.normality: null;

    const selectedCodeSheet=  !reportForm.options.codeSheet ? null : codeSheetsList.find((item) => item.value === reportForm.options.codeSheet);

    const selectedCodeFinding=  !reportForm.options.codeFinding ? null : codeFindingsList.find((item) => item.value === reportForm.options.codeFinding);
  
    return <div className="opts-wrap">
        {!props.isClientDashboard &&
        <FormGroup>
            <Label>Code Sheet</Label>
                <SimpleSelect
                id={'codeSheet'}
                className="ip-sml-select"
                name="codeSheet"
                title="By CodeSheet"
                list={codeSheetsList}
                deselect={true}
                initialSelected={selectedCodeSheet}
                onChange={didSelectCodeSheet}
                />
        </FormGroup>
        }
        <FormGroup className="half">
            <Label>Code Finding</Label>
                <SimpleSelect
                id={'finding'}
                className="ip-sml-select"
                name="finding"
                title="By Code Finding"
                list={codeFindingsList}
                initialSelected={selectedCodeFinding}
                deselect={true}
                onChange={didSelectCodeFinding}
                searchable={["Filter", "No matching"]}
                filterKeys={['label']}
                />
        </FormGroup>
        <FormGroup className="hdn">
            <Label>Normality</Label>
            <div className="no-warp">
            <Label className="ip-radio-pill">
            <Input 
             onChange={handlePillSwitches } 
             type="radio"
             name="normality" value={FINDING_NORMALITY.Normal} 
             checked={normality != null && !normality  ? true : false}/>{' '}
                <span className="radioselect"></span>
                <span className="text">{FINDING_NORMALITY.Normal}</span>
            </Label>

            <Label className="ip-radio-pill">
            <Input onChange={handlePillSwitches } 
            type="radio" name="normality" 
            value={FINDING_NORMALITY.Abnormal}
            checked={normality != null && normality ? true : false}
             />{' '}
                <span className="radioselect"></span>
                <span className="text">{FINDING_NORMALITY.Abnormal}</span>
            </Label>
            </div>
        </FormGroup>
    </div>
}

export default Filter_RP02;