import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import {PermissionEnforcer } from '../../../utility/PermissionEnforcer';
import { 
    Nav, 
    NavItem,
    NavLink,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    ExtendedDropdown,
    ListGroup,
    ListGroupItem,
    Authority
} from '../../../components';
import { subMenuNavItems, hasVisibleItem } from './menuStructure';
import { FEATURE_FLAG } from "../../../common/constant";
import  FEATURES  from "../../../config/featureFlags";
import { getIconUrl } from '../../../common/images-catalogue';


const NotificationMenu = () => {
    const permissionEnforcer = PermissionEnforcer.getInstance(PermissionEnforcer);
    const showMore = hasVisibleItem(permissionEnforcer, subMenuNavItems);

    return (
        <div className="navigation-bar">   
            <Nav>
                <NavItem>
                    <NavLink tag={ Link } to="/dashboard">
                        <span className='menu-item'>
                            <i className="nav-item-icon fa fa-fw fa-home d-md-inline">
                                <img src={ getIconUrl('dashboard') } alt=''/>
                            </i>
                            <span className="d-md">
                                Dashboard
                            </span>
                        </span>
                    </NavLink>
                </NavItem>
                <Authority allowed="view" entity="record">
                <NavItem>
                    <NavLink tag={ Link } to="/specimens">
                        <span className='menu-item'>
                            <i className="nav-item-icon fa fa-fw fa-home d-md-inline">
                                <img src={ getIconUrl('samples') } alt=''/>
                            </i>
                            <span className="d-md">
                                Samples
                            </span>
                        </span>
                    </NavLink>
                </NavItem>
                </Authority>
                <Authority allowed="view" entity="user">
                <NavItem>
                    <NavLink tag={ Link } to="/users">
                        <span className='menu-item'>
                            <i className="nav-item-icon fa fa-fw fa-home d-md-inline">
                                <img src={ getIconUrl('users') } alt=''/>
                            </i>
                            <span className="d-md">
                                Users
                            </span>
                        </span>
                    </NavLink>
                </NavItem>
                </Authority>
                <Authority allowed="view" entity="client">
                <NavItem>
                    <NavLink tag={ Link } to="/labs">
                        <span className='menu-item'>
                            <i className="nav-item-icon fa fa-fw fa-home d-md-inline">
                                <img src={ getIconUrl('labs') } alt=''/>
                            </i>
                            <span className="d-md">
                                Labs
                            </span>
                        </span>
                    </NavLink>
                </NavItem>
                </Authority>
                <Authority allowed="view" entity="client">
                <NavItem>
                    <NavLink tag={ Link } to="/doctors">
                        <span className='menu-item'>
                            <i className="nav-item-icon fa fa-fw fa-home d-md-inline">
                                <img src={ getIconUrl('doctors') } alt=''/>
                            </i>
                            <span className="d-md">
                                Doctors
                            </span>
                        </span>
                    </NavLink>
                </NavItem>
                </Authority>
                <Authority allowed="view" entity="patient">
                <NavItem>
                    <NavLink tag={ Link } to="/patients">
                        <span className='menu-item'>
                            <i className="nav-item-icon fa fa-fw fa-home d-md-inline">
                                <img src={ getIconUrl('patient') } alt=''/>
                            </i>
                            <span className="d-md">
                                Patients
                            </span>
                        </span>
                    </NavLink>
                </NavItem>
                </Authority>
                { showMore && 
                <UncontrolledDropdown  inNavbar className="more-menu-trigger">
                    <DropdownToggle nav className="options-trigger">
                        <span >
                            <Button><img src={ getIconUrl('more') } alt=''/></Button>
                        </span>
                    </DropdownToggle>
                    <ExtendedDropdown center={'true'} className="more-option-dropdown">
                        <ListGroup className="grid-group">
                            {
                                subMenuNavItems.map((navItemData, index)=>{
                                    return (
                                    <Authority key={index} allowed={navItemData.allowed} entity={navItemData.entity}>
                                    <li className="list-item-grid">
                                        <ListGroupItem tag={ ExtendedDropdown.Link } to={navItemData.toUrl} action>
                                            <span className="icon">
                                                <img src={getIconUrl(navItemData.iconName)} alt=''/>
                                            </span>
                                            <span className="label">{navItemData.navItemName}</span>
                                        </ListGroupItem>
                                    </li> 
                                    </Authority>)
                                })
                            }
                            
                            { FEATURES.Loans === FEATURE_FLAG.ON && 
                            <li className="list-item-grid">
                                <ListGroupItem tag={ ExtendedDropdown.Link } to="/apps/email-details" action>
                                    <span className="icon">
                                        <img src={getIconUrl('billing')} alt=''/>
                                    </span>
                                    <span className="label">Loans</span>
                                </ListGroupItem>
                            </li> 
                            }                         
                        </ListGroup>
                    </ExtendedDropdown>
                </UncontrolledDropdown>
                }
            </Nav>
        </div>
    );
};

export default NotificationMenu;