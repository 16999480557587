import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { Comparator, dateFilter } from 'react-bootstrap-table2-filter'
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import PropType  from 'prop-types';
import { PaginationPanel, PaginationTotal } from '.';
import { 
    InputField, 
    FormGroup, NavLink, List} from '../index';

const generateRow = (data) => ({
    id: data.id,
    employees: data.payees,
    taxes: data,
    payrollDate: data,
    grossPayout: data
});

const employeesDef = (data) => {
    const payeesData = data;
    const displayEmployees = [];
    const count = 5;
    const remainder =  payeesData.length > count ? data.length - count : 0;

    for(var i = 0; i < payeesData.length; i++) {
        if (i < count) {
            displayEmployees.push(payeesData[i]);
        }
    }

    return (
        <ContextMenuTrigger id="users_table_context_menu">
        <div className="employee-list">
            <div className="employee-list-holder">
                <div className="employee-list-content">
                    <List className="payee-list">
                        { displayEmployees.map((employee, i) => {
                            return <li key={i} className="payee">{employee.firstName}</li>
                        })}

                        { remainder > 0 && 
                            <li className="payee">({remainder}) more...</li>
                        }
                    </List>
                </div>
            </div>
        </div>
        </ContextMenuTrigger>
    );
}

const taxesDef = (data) => {
    let taxesTotal = 0.0;
    data.taxSummary.map((item) => taxesTotal += item.taxTotal );
    return (
        <ContextMenuTrigger id="users_table_context_menu">
        <div className="taxes-info">
            <div className="taxes-info-holder">
                <div className="taxes-info-content">
                    <div className="fields-group">
                        <p>${taxesTotal.toCurrency()}</p>
                    </div>
                </div>
            </div>
        </div>
        </ContextMenuTrigger>
    );
}

const dateDef = (data) => {
    return (
        <ContextMenuTrigger id="users_table_context_menu">
        <div className="date-info">
            <div className="date-holder">
                <div className="date-content">
                    <div className="fields-group">
                        <p>{data.payrollDate}</p>
                        <span className={`status --${data.status.toLowerCase()}`}>{data.status}</span>
                    </div>
                </div>
            </div>
        </div>
        </ContextMenuTrigger>
    );
}

const payrollDeatils = (actions) => {
    //return function with cell content
    return (data) => (
        <div className="gross-pay">
            <ContextMenuTrigger id="users_table_context_menu">
            
            <div className="gross-pay-holder">
                <div className="gross-pay-content">
                    <p className="gross-pay">${data.totals.grossPayoutAmount.toCurrency()}</p>
                    <div className="fields-group">
                        <NavLink onClick={(evt) => actions.handleShowSummary(data)} className="arrow-link"><span className="arrow-indicator">
                        </span>{ data.isApproved ? 'View Details' : 'Awaiting Approval'}</NavLink>
                    </div>
                </div>
            </div>
            </ContextMenuTrigger>
        </div>
    );
}

class PayrollTable extends React.Component { 
    static propsTypes = {
        tableData: PropType.array.require,
        actionResponsers: PropType.object,
    }

    static defaultProps = {
        actionResponsers: {
            editButton: null,
        }
    }

    constructor(props) {
        super(props);
        
        this.state = {
            selected: []
        };
        
        this.willEnterRow = this.willEnterRow.bind(this);
        this.didContextClick = this.didContextClick.bind(this);
        this.didClickRow = this.didClickRow.bind(this);
    }

    createColumnDefinitions(quickActions) { 
        return [
            {
                dataField: 'employees',
                text: "Employees",
                headerAttrs: {
                    hidden: false,
                    className: 'th-employee-cell',
                },
                classes: 'id-employee-cell td-cell',
                formatter: employeesDef
            },
            {
                dataField: 'taxes',
                text: "Taxes",
                headerAttrs: {
                    hidden: false,
                    className: 'th-taxes-cell',
                },
                classes: 'id-taxes-cell td-cell',
                formatter: taxesDef
            },
            {
                dataField: 'payrollDate',
                text: "Payroll Date",
                headerAttrs: {
                    hidden: false,
                    className: 'th-date-cell',
                },
                classes: 'id-date-cell td-cell',
                formatter: dateDef
            },
            {
                dataField: 'grossPayout',
                text: "Amount",
                headerAttrs: {
                    hidden: false,
                    className: 'th-gross-pay-cell',
                },
                classes: 'id-gross-paycell td-cell',
                formatter: payrollDeatils( quickActions )
            }
        ];
    }

    handleSelect() { }

    handleSelectAll() { }

    willEnterRow (evt, row, rowIndex) { }

    didClickRow(evt, row, index) {
        const rowId = evt.target.getAttribute('rowid');
        const actionTag = evt.target.getAttribute('actiontag');
        if (rowId && actionTag && actionTag === 'view') {
            this.props.quickActions.viewDetails(row.details);
        }
    }  

    didContextClick (evt, row, rowIndex) {
        evt.preventDefault();
    }

    render () {
        const columnDefs = this.createColumnDefinitions(this.props.quickActions);
        const paginationDef = paginationFactory({
            paginationSize: 5,
            showTotal: true,
            pageListRenderer: (props) => (
                <PaginationPanel { ...props } size="sm" className="ml-md-auto mt-2 mt-md-0" />
            ),
            paginationTotalRenderer: (from, to, size) => (
                <PaginationTotal { ...{ from, to, size } } />
            )
        
        });
        
        const rowEventsHandlers = {
            onContextMenu: this.didContextClick,
            onMouseEnter: this.willEnterRow,
            onClick: this.didClickRow
        };

        var options = {
            noDataMessage: () => 'No payroll found in journal.'
        };

        const payrollData =  this.props.tableData.map(payroll => generateRow(payroll));
        //const payrollData = this.props.tableData.map(client => generateRow(client));
        
        return (
            <ToolkitProvider
                keyField="id"
                data={ payrollData }
                columns={columnDefs} >
                {
                    props => (
                        <React.Fragment>
                            <BootstrapTable
                            classes="table-responsive payroll-table"
                            pagination={ paginationDef }
                            filter={ filterFactory() }
                            bordered={ false }
                            responsive
                            { ...props.baseProps }
                            rowEvents={ rowEventsHandlers }
                            loading={ true } 
                            noDataIndication={options.noDataMessage}
                        />
                        </React.Fragment>
                    )
                }
            </ToolkitProvider>
        );
    }
}

export { PayrollTable }