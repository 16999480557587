import * as actionTypes from '../actionType';

export const setProfilePaneAction = (activePane) => {
  return {
    type: actionTypes.SET_ACTIVE_PROFILE_PANE,
    payload: activePane,
  };
};

export const profileFieldChangeAction = (fieldValue) => {
  return {
    type: actionTypes.PROFILE_FIELD_CHANGED,
    payload: { ...fieldValue },
  };
};

export const profileFieldOnClickEditAction = (initFieldValue) => {
  return {
    type: actionTypes.CLICKED_EDIT_PROFILE_FIELD,
    payload: { ...initFieldValue },
  };
};

export const resetProfileFormAction = (user) => {
  return {
    type: actionTypes.RESET_PROFILE_FORM,
    payload: user
  };
};

export const getProfileDataAction = (profileId) => {
  return {
    type: actionTypes.FETCH_PROFILE_DATA,
    payload: { profileId },
  };
};

export const submitProfileDataAction = (payload) => {
  return {
    type: actionTypes.PROFILE_SUBMIT_DATA,
    payload,
  };
};
