// import { applyMiddleware } from 'redux';
// import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from 'redux-logger';
import { routerMiddleware  } from 'connected-react-router';
import { createEpicMiddleware } from 'redux-observable';
import createSagaMiddleware, { END } from 'redux-saga';
import { createBrowserHistory as createHistory } from 'history';
import reducer from './reducers';
import rootEpic from './epics';
import rootSaga from './sagas';
import { 
  appMiddleware,
  jwtStorageMiddleware
} from './middleware';

export const history = createHistory({basename: process.env.REACT_APP_ROUTER_BASE || ''});

// Build the middleware for intercepting and dispatching navigation actions
const appRouterMiddleware = routerMiddleware(history);
const epicMiddleware = createEpicMiddleware();
const sagaMiddleware = createSagaMiddleware();

const getMiddleware = () => {
    if (process.env.NODE_ENV === 'production') {
      return [appRouterMiddleware, appMiddleware, epicMiddleware, sagaMiddleware, jwtStorageMiddleware];
    } else {
      // Enable additional logging in non-production environments.
      return [appRouterMiddleware, appMiddleware, epicMiddleware, sagaMiddleware, jwtStorageMiddleware, createLogger()];
    }
  };

const store = configureStore({
  reducer:  reducer(history),
  middleware: getMiddleware()
});
//Run observable middleware
sagaMiddleware.run(rootSaga);
epicMiddleware.run(rootEpic);
store.close = () => store.dispatch(END);
export  { store }