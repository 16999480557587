import { useState, useEffect } from 'react';
import PropType from 'prop-types';
import { 
  Section, 
  Button, 
  Col,
  ListGroup,
  ListGroupItem
 } from '../../components';
import { Loader } from '../components/partials/LoadingIndicator';
import { getIconUrl } from '../../common/images-catalogue';
import * as actionTypes from '../../store/actionType';

export default function BillPayments(props){
  const [isFetchingPayments, setIsFetchingPayments] = useState(props.apiRequestManager.inProgress(actionTypes.FETCH_BILL_PAYMENTS));
  const {closeModal, paymentsData, apiRequestManager, bill} = props;

  useEffect(() => {

    if (!apiRequestManager.inProgress(actionTypes.FETCH_BILL_PAYMENTS) && bill?.id !== null) {
      apiRequestManager.queueRequest(actionTypes.FETCH_BILL_PAYMENTS, bill.id);
      setIsFetchingPayments(true);
    }
  }, []);

  useEffect(() => {
    if (isFetchingPayments && paymentsData.length !== 0) {
      setIsFetchingPayments(false);
    }
  }, [isFetchingPayments, paymentsData]);

  return(
    <>
      <div className='rqt-modal-wrap'>
        <div className='rqt-modal-content'>
          <Section className='head-section'>
            <div className='title-box view-payments__title-wrapper'>
              <div className='title-area'>
                <h1>Payments</h1>
              </div>
              <div className='slide-pane__close modal__close-wrapper'>
                <Button className='close-btn'  
                onClick={closeModal}>
                  <img src={getIconUrl('close')} alt=''/>
                </Button>
              </div>
            </div>
          </Section>
          {isFetchingPayments ?
            <div className='bill__loader-wrap'>
              <Loader visible={isFetchingPayments} loadingText="Fetching bill payments..." />
            </div>
            :
            <Section className='bill-payments'>
              <Col>
                <ListGroup className='bill-payments__list'>
                  {paymentsData.map((payment, i) => (
                    <ListGroupItem key={i} className='bill-payments__payment-wrapper'>
                      <div className='bill-payment__status-wrapper'>
                        <div className='bill-payment__icon-wrapper'>
                          <img src={getIconUrl('paymentIcon')} alt='' />
                        </div>
                        <div className='status-info-content'>
                          <span className='status'>{payment.paymentMethod}</span>
                        </div>
                      </div>
                      <div className='bill-payment__detail-wrapper'>
                        <p className='payment-detail__sm-text payment-detail__title'>Payee</p>
                        <h5 className='payment-detail__primary-text payment-detail__client-name'>{payment.accountOwner}</h5>
                        <p className='payment-detail__sm-text'>AC# {payment.accountNo}</p>
                      </div>
                      <div className='bill-payment__detail-wrapper'>
                        <p className='payment-detail__sm-text payment-detail__title'>Payment Info</p>
                        <h6 className='payment-detail__primary-text payment-detail__ref-text'>REF# {payment.paymentRef}</h6>
                        <p className='payment-detail__med-text'>Date: {payment.datePaid}</p>
                      </div>
                      <div className={`bill-payment__detail-wrapper bill-payment--amount-wrapper ${!payment.isVerified ? '--unverified' :''}`}>
                        <p className='payment-detail__sm-text payment-detail__title'>Amount Paid</p>
                        <h2 className='payment-detail__primary-text'>${Number(payment.amount).toCurrency()}</h2>
                        {!payment.isVerified && 
                          <div className='payment-details__unverified'>Unverified</div>
                        }
                      </div>
                    </ListGroupItem>)
                  )}
                </ListGroup>
              </Col>
          </Section>}
        </div>
      </div>
    </>
  )
}

BillPayments.defaultProps = {
  paymentsData: [],
}

BillPayments.propTypes = {
  closeModal: PropType.func.isRequired,
  paymentsData: PropType.arrayOf(PropType.shape({
    amount: PropType.number,
    billNo: PropType.string,
    paymentRef: PropType.string,
    datePaid: PropType.string,
    status: PropType.string,
    paymentMethod: PropType.string,
    accountNo: PropType.string,
    accountOwner: PropType.string
  })),
}