import React from 'react';
import { connect } from 'react-redux';
import { setupPage } from '../../components/Layout/setupPage';
import { 
    BillTable,
    Authority
} from '../../components';

import * as actionTypes from '../../store/actionType';
import  * as actions  from '../../store/actions';
import { PAGE_SIZE } from '../../config/pagination';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
import './Billing.style.scss';


const mapStateToProps = state => ({
    apiRequestManager: ApiRequestManager.getInstance(ApiRequestManager),
});

const mapDispatchToProps = dispatch => ({
    onLoad: (clentType) => dispatch(actions.clientsViewLoadedAction(clentType)),
    unLoad: () => dispatch(actions.clientsViewUnloadAction()),
});

class Overview extends React.Component {

    static pageOption = { 
        pageTitle: 'Patients',
        disableBodyScroll: true,
    };

    constructor(props) {
        super(props);
        this.state = {
            pageSize: PAGE_SIZE,
            currentPage: 0
        };

        this.handleTableChange = this.handleTableChange.bind(this);
    }

    componentDidMount() {}

    componentWillUnmount() {}

    static getDerivedStateFromProps(props, state) {
        // Return null to indicate no change to state.
        if (!props.billsData && !props.apiRequestManager.inProgress(actionTypes.FETCH_BILLS) ) {
            const requestParams = {
                clientId:  props.account ? props.account.owner : null,
                pageSize: state.pageSize,
                page: state.currentPage
            }

            props.apiRequestManager.queueRequest(actionTypes.FETCH_BILLS, requestParams);
            props.apiRequestManager.queueRequest(actionTypes.FETCH_BILLS_SUMMARY, requestParams.clientId);
        }

        return null;
    }

    handleTableChange(tableOpt) {

        const pageNumber = !tableOpt.page || tableOpt.page - 1;
        const pageSize = tableOpt.sizePerPage;

        const requestParams = {
            clientId: this.props.useClientLayout ? this.props.account.owner : null,
            page: pageNumber,
            pageSize: pageSize
        };

        this.props.apiRequestManager.queueRequest(actionTypes.FETCH_BILLS, requestParams);

        this.setState({
            currentPage: pageNumber,
            pageSize: pageSize
        });
    }

    render () {

        let {pageData, analytics, ...tableMetadata} = this.props.billsData || {};
        const tableData = Array.isArray(pageData) ? pageData : [];

        tableMetadata = {
            ...tableMetadata,
            remote: true,
            pageSize: this.state.pageSize
        };

        return (
            <React.Fragment>                
                <div className="table-container billing-table-coontainer">
                    <BillTable 
                    //ref={this.patientsTable}
                    id="billTable" 
                    hoverOverRowAction={this.props.willHandleTableRowMouseOver}
                    quickActions={this.props.quickActionCallback} 
                    tableData={tableData}
                    metaData={tableMetadata}
                    onTableChange={this.handleTableChange}
                    />
                </div>

            </React.Fragment>
        );
    }
}

const OverviewView = (props) => {
    return (
        <Overview {...props } />
    );
};

let ConfigOverviewView = setupPage(Overview.pageOption)(OverviewView);
ConfigOverviewView =  connect(mapStateToProps, mapDispatchToProps)(ConfigOverviewView);

export { ConfigOverviewView };