import React from 'react';


const Placeholder = (props) => {
    const { children, className } = props;
    const classNames = className ? className : '';
    return (<div className={`select-placeholder ${classNames}`}>
        {children}
    </div>)
}

export default Placeholder;