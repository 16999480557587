import React from 'react';
import OptionList from './OptionList';
const svgIcon = (props) => {
    return (<svg><path class="cls-1" d="M59,1.67H18.41V9H59Zm0,17H18.37v7.24H59ZM18.43,35.53v7.26H58.94V35.53ZM10.47,9V1.67H1.22V9Zm0,9.63H1.2v7.25h9.27ZM1.19,35.56v7.26h9.27V35.56Z"/><path class="cls-1" d="M59,1.67V9H18.41V1.67Z"/><path class="cls-1" d="M59,18.63v7.24H18.37V18.63Z"/><path class="cls-1" d="M18.43,35.53H58.94v7.26H18.43Z"/><path class="cls-1" d="M10.47,9H1.22V1.67h9.25Z"/><path class="cls-1" d="M10.47,18.66v7.25H1.2V18.66Z"/><path class="cls-1" d="M1.19,35.56h9.27v7.26H1.19Z"/></svg>);
}


class Select extends React.Component {
 
    render () {

        return (
        <div className="ip-ff-select">
            <div className="select-container">
                <div className="ip-select-controls">
                    <div className="ip-chosen-option">
                        <div className="option-template">
                            <div className="template-content">
                                <div className="patient-picture">
                                        <span>
                                            RW
                                        </span>
                                </div>
                                 <div className="patient-info">
                                    <p className="name">Ricardo Walford</p>
                                    <p className="regist-no">Registration#:<span>2986234</span></p>
                                 </div>
                                 <div  className="patient-detils">
                                    <p className="info">Gender:<span>Male</span></p>
                                    <p className="info">Age:<span>29</span></p>
                                 </div>
                            </div>
                        </div> 
                        <div className="select-option">

                        </div>
                    </div>
                    <div className="ip-select-indicator">
                        <span className="icon">
                        { svgIcon() }
                        </span>
                    </div>
                </div>
            </div>
            <OptionList />
        </div>)
    }
}

export default Select