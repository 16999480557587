import React from 'react';

import { 
    Button
 } from '../../../components';
import { getIconUrl } from '../../../common/images-catalogue';

const CloseButton = (props) => (
    <Button className="close-btn">
        <img src={getIconUrl('close')} alt=''/>
    </Button>
);

const AddButton = (props) => {
    const {label, actionHandler } = props;
    const text = label ? label : 'Button Label';
    const didClick = (evt) => {
        actionHandler && actionHandler(evt);
    } 
    return <Button onClick={didClick} className="add-btn-item">
        <span className="icon">
            <img src={getIconUrl('plus')} alt=''/>
        </span>
        {text}
    </Button>
}

export { CloseButton , AddButton};