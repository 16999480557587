import React from "react"
import {
    List,
    Row,
    Col,
    NavLink,
} from '../../components';

const getIconUrl = (name) => {
    const icon = require(`./../../assets/images/${name}`);
    return icon.default;
}

const ReportItem = (props) => {
    const {data, onRun, onCustomize} =  props;

    const didClickRun = (evt) => {
        onRun(evt, data);
    }
    const didClickCustomize = (evt) => {
        onCustomize(evt, data);
    }
    return (

        <div className="report-item">
            { data.icon && 
            <div className="icon">
                <img src={getIconUrl(data.icon)}/>
            </div>
            }
            <div className="details">
                <h3 className="title">{data.title}</h3>
                <p className="desc">{data.description}</p>
                <div className="link-grp"><NavLink onClick={didClickRun}>Run</NavLink>
                {data.filters && 
                    <span className="vline"></span> 
                }
                {data.filters &&
                    <NavLink onClick={didClickCustomize}>Customize</NavLink>
                }
                </div>
            </div>
            
        </div>
    );
}

export const PaneContent =  (props) => {
    const { reportList, actions, clientDashboard } = props;

    const onRun = (evt, data) => {
        evt.preventDefault();
        actions.showReporting(data);
    }
    const onCustomize = (evt, data) => {
        evt.preventDefault();
        actions.showReporting(data, true);
    }
    return <div className="pane-content">
            <Row className="report-list">
            {reportList.map((item, i) => {

                return (clientDashboard && !item.forClient) ? null : (
                    <Col key={i} md={6} className="report-item-wrap">
                        <ReportItem 
                        data={item} 
                        onRun={onRun}
                        onCustomize={onCustomize}
                        />
                    </Col>
                );
            }) }
            </Row>
    </div>
}