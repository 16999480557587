import React from 'react';
import {
    Label,

} from '../../../components';

const UsersSummary = (props) => {
    const {label, count, key} = props;

    const numberOfUser = count ?  count : 0;
    return <React.Fragment>
        <li className="user-widget-item">
            <div className="widget-content">
                <div className="widget-detail">
                    <div className="count">{numberOfUser}</div>
                    <span className="text">Total</span>
                </div>
                <div className="label-wrap">
                    <Label>{label}</Label>
                </div>
            </div>
        </li>
    </React.Fragment>
}

export default UsersSummary;