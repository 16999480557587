import fetch from 'unfetch';
import _superagent from 'superagent';

import { from as observable } from 'rxjs';
import { ERROR_CODES } from '../common/constant';
import * as permissionDialect from './permissionRoleDialect';
import * as authDialect from './authDialect';
import * as usersDialect from './usersDialect';
import * as accountDialect from './accountDialect';
import * as patientDialect from './patientDialect';
import * as specimenDialect from './specimenDialect';
import * as commonDialect from './commonDialect';
import * as clientDialect from './clientDialect';
import * as employeeDialect from './employeeDialect';
import * as paymentDialect from './paymentDialect';
import * as departmentDialect from './departmentDialect';
import * as billsDialect from './billingDialect';
import * as serviceDialect from './serviceDialect';
import * as payrollDialect from './payrollDialect';
import * as messageDialect from './messageDialect';
import * as settingsDialect from './settingsDialect';
import * as profileDialect from './profileDialect';

import { requestEnded } from './agentDialect';
import { REQUESTS_TYPE } from '../common/constant';
import NetworkError from '../utility/error/NetworkError';
import ApiError from '../utility/error/ApiError';

export {
  commonDialect,
  permissionDialect,
  authDialect,
  usersDialect,
  accountDialect,
  patientDialect,
  specimenDialect,
  clientDialect,
  employeeDialect,
  paymentDialect,
  departmentDialect,
  billsDialect,
  payrollDialect,
  serviceDialect,
  messageDialect,
  settingsDialect,
  profileDialect,
};

const apiServer = process.env.REACT_APP_API_SERVER;

export let apiOutcome = {
  initiator: '',
  error: null,
  success: false,
  requestType: '',
};

export const checkReponse = (
  response,
  actionType,
  requestType = REQUESTS_TYPE.FETCH
) => {
  // Request as ended
  apiOutcome.initiator = actionType;
  apiOutcome.error = null;
  apiOutcome.requestType = requestType;

  if (response.type !== 'error' && response.error === undefined) {
    apiOutcome.success = true;
    requestEnded(apiOutcome);
    return (response = response.data);
  } else {
    let error = null;
    //Check for Api Error
    if (response.error) {
      apiOutcome.success = false;
      error = new ApiError(
        response.error.code,
        response.error.debugMessage,
        response.error.type,
        response.error.message,
        response.error.subErrors
      );
      error.details = response.error.subErrors;
    } else {
      //Network connection error
      error = new NetworkError(
        505,
        ERROR_CODES.ERR_SERVER_COMMUNICATION,
        'ERR_SERVER_COMMUNICATION',
        []
      );
      error.details = ERROR_CODES.ERR_SERVER_COMMUNICATION;
      apiOutcome.success = false;
    }
    apiOutcome.error = error;
    requestEnded(apiOutcome);
    return error;
  }
};

export const isError = (payload) =>
  payload instanceof ApiError ||
  payload instanceof NetworkError ||
  payload instanceof TypeError ||
  payload instanceof ProgressEvent;

export const pingServer = () => {
  let pingRequest = fetch(`${apiServer}`, {
    method: 'GET',
    headers: {
      //'Authorization': `Bearer ${jwToken}`,
    },
  }).then((response) => response.json());

  return observable(pingRequest);
};
