import React from 'react';
import TemplateFooter from './TemplateFooter';

class Report_RP06 extends React.PureComponent  {
    
    constructor(props) {
        super(props);
        this.state = {
            rowGroup : {}
        }

        this.collapse = this.collapse.bind(this);
    }

    collapse(evt) {
    
        const rowId = evt.currentTarget.getAttribute('rowgroup');
        const rowSet = {
            rowGroup: {
                ...this.state.rowGroup,
                [rowId]: this.state.rowGroup[rowId] ? false : true
            }
        }
        this.setState({
            ...rowSet
        });
    }

    render () {


        const DEDUCTION_TYPE = {
            Pay: 'Pay',
            Tax: 'Tax'
        }

        const {ReportHeader, ReportFooter, ReportNotes} = this.props;
        const reportData = Array.isArray(this.props.reportResults) && this.props.reportResults.length ? this.props.reportResults[0] : {};

        const dataKeys = Object.keys(reportData);

        return <React.Fragment>
            <div  className="print-template">
                { ReportHeader }
                <div className="report-body">

                    <table className="default">
                        <thead>
                            <tr>
                                <th></th>
                                <th className="number">Earnings</th>
                                <th  className="number">Deductions</th>
                                <th  className="number">Taxes</th>
                                <th  className="number">Basic Wage</th>
                                <th className="number">Gross Pay</th>
                            </tr>
                        </thead>
                        <tbody>
                        { dataKeys.map((key, g) => {

                            var rowGroup = [<tr key={g} onClick={this.collapse} className={`collapsible-row ${this.state.rowGroup[g] ? '--folded' : ''}`} rowgroup={g}>
                            <td>{key} - {reportData[key][0].employeeInfo.firstName} {reportData[key][0].employeeInfo.lastName}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className="number">{reportData[key].length}</td>
                            </tr>]

                            reportData[key].map((payadvice, i) => {
                                
                                let totalEarning = 0.0;
                                let totalDedutions = 0.0;
                                let totalTaxes = 0.0;
                                let baseWage = 0.0;

                                payadvice.deductions.map((entry) => {
                                    if (entry.type === DEDUCTION_TYPE.Tax) {
                                        totalTaxes += entry.amount;
                                    }else {
                                        totalDedutions += entry.amount;
                                    }
                                });

                                payadvice.earnings.map((entry) => {
                                    if (entry.desc !== 'BaseWage') {
                                        totalEarning += entry.amount;
                                    }else {
                                        baseWage= entry.amount
                                    }
                                });

                                rowGroup.push(<tr key={payadvice.id}  className={`smpl-row row-divider no-padding ${this.state.rowGroup[g] ? '--hide' : ''}`} groupid={g}>
                                <td className="grp">
                                    <span className="bl bold">{payadvice.employeeInfo.firstName} {payadvice.employeeInfo.lastName}</span>
                                    <span className="bl highlight">${payadvice.netPay.toCurrency()}</span>
                                    <span className="bl">PR# {payadvice.payrollNo}</span>
                                    <span className="bl">{payadvice.payrollDate}</span>
                                </td>
                                <td className="number">{totalEarning.toCurrency()}</td>
                                <td className="number"> -{totalDedutions.toCurrency()}</td>
                                <td className="number"> -{totalTaxes.toCurrency()}</td>
                                <td className="number">{baseWage.toCurrency()}</td>
                                <td className="number">{payadvice.grossPay.toCurrency()}</td>
                                </tr>)
                            });

                            return rowGroup;
                            })}
                        </tbody>
                    </table>
                </div>
                { <TemplateFooter 
                    ReportFooter={ReportFooter}  
                    ReportNotes={ReportNotes}
                    /> 
                }
            </div>
           
        </React.Fragment> 
    }  
}

export default Report_RP06;