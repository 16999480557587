import { requests } from './agentDialect';

export const getClientAccount = (userId) => (
    requests.get(`/account/?user=${userId}`) 
    );
export const getWorkspaceDetails = () => (
    requests.get(`/workspace/details`)
);

export const getOwnerAccount = (accountOwner) => (
    requests.get(`/account/owner/?owner=${accountOwner}`) 
);

export const fetchAllAccounts = () => requests.get(`/accounts`);
export const fetchAllClientsAccounts = () => requests.get(`/accounts/clients`);