import { ConfigSpecimenView as Specimen } from './Specimen';

import  Overview  from './Overview';
import  Recent  from './Recent';
import  Authorize  from './Authorize';
import  Requisition  from './Requisition/Requisition';
import  {ConfigResultSheetView as ResultSheet}   from './ResultSheet';
import  {ConfigViewer as Viewer}   from './Viewer';
import { ConfigCreateUpdateView as CreateUpdateSpecimen} from './CreateUpdate';
import ResultSheetPrintTemplate from './ResultSheetPrintTemplate';

export {
    Overview,
    Recent,
    Authorize,
    ResultSheet,
    Requisition,
    CreateUpdateSpecimen,
    Viewer,
    ResultSheetPrintTemplate
}
export default Specimen;