import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  mergeMap,
  takeUntil,
} from 'rxjs/operators';
import * as actionTypes from '../actionType';
import { profileDialect, checkReponse } from '../../service/agent';
import { of } from 'rxjs';
import { apiResponseActionCreator } from '../actions';

export const getProfileEpic = (action$) => {
  return action$.pipe(
    ofType(actionTypes.FETCH_PROFILE_DATA),

    switchMap((action) => {
      return profileDialect.fetchProfile(action.payload.profileId).pipe(
        map((response) => {
          const responseData = checkReponse(response, action.type);
          return apiResponseActionCreator(
            actionTypes.FETCHED_PROFILE_DATA_RESULT,
            responseData
          );
        }),
        takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
        catchError((error) => {
          return of(
            apiResponseActionCreator(
              actionTypes.FETCHED_PROFILE_DATA_RESULT,
              error
            )
          );
        })
      );
    })
  );
};

export const submitProfileEpic = (action$) => {
  return action$.pipe(
    ofType(actionTypes.PROFILE_SUBMIT_DATA),

    mergeMap((action) => {
      const apiCalls = {
        update: profileDialect.updateProfile,
      };

      return apiCalls[action.payload.requestType](action.payload).pipe(
        map((response) => {
          const responseData = checkReponse(
            response,
            action.type,
            action.payload.requestType
          );
          return apiResponseActionCreator(
            actionTypes.PROFILE_SUBMIT_DATA_RESULT,
            responseData
          );
        }),
        takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
        catchError((error) => {
          console.log('ERROR', error);
          return of(
            apiResponseActionCreator(
              actionTypes.PROFILE_SUBMIT_DATA_RESULT,
              error
            )
          );
        })
      );
    })
  );
};
