import React from 'react';
import { connect } from 'react-redux';
import { setupPage } from '../../components/Layout/setupPage';
import classnames from 'classnames';
import * as urls from '../../config/urls';
import * as actionTypes from '../../store/actionType';
import  * as actions  from '../../store/actions';
import  { PermissionEnforcer }  from '../../utility/PermissionEnforcer';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
import { ACTION_BAR_MODES } from '../../common/constant';
import SlidingPane from "react-sliding-pane";
import { CloseButton } from '../components/partials/Buttons';

import {
    Overview,
    Payments
} from './';

import {Receipt, Pay } from './';

import './Payment.style.scss';

import { 
    Nav,
    NavItem,
    NavLink,
    Button,
    Authority,
} from '../../components';
import { getIconUrl } from '../../common/images-catalogue';

const mapStateToProps = state => ({
    apiRequestManager: ApiRequestManager.getInstance(ApiRequestManager),
    useClientLayout: state.global.useClientDashboard,
    mode:  state.global.actionBarMode,
    workspace: state.global.workspace,
    account: state.global.account,
    ...state.payment
});

const mapDispatchToProps = dispatch => ({
    onLoad: () => dispatch(actions.paymentViewLoadedAction()),
    unLoad: () => dispatch(actions.paymentViewUnloadAction()),
    getAccounts: () => dispatch(actions.getAllAccountsAction()),
    setPaymentData: (data) => dispatch(actions.setPaymentDataAction(data)),
    rowDidMouseOver: (contextData) => dispatch(actions.tableRowMouseOverAction(contextData)),
    contextMenuEvent: (inContext) => dispatch(actions.contextMenuEventAction(inContext)),
});

function offset(el) {
    let rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

class Payment extends React.Component {

    static pageOption = {
        pageTitle: 'Client',
        disableBodyScroll: true
    };

    modalViews = {
        payment: 'payment',
        receipt: 'receipt'
    }
    
    constructor(props) {
        super(props);
        
        props.apiRequestManager.register(actionTypes.FETCH_ACCOUNTS, props.getAccounts);

        this.state = {
            activeView: this.props.useClientLayout ? 'payments' : 'overview',
            modalView: 'payment',
            isPaneOpen: false,
        }
        this.switchView = this.switchView.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.onCreateBill = this.onCreateBill.bind(this);
        this.showPaymentModal = this.showPaymentModal.bind(this);
        this.showReceiptModal = this.showReceiptModal.bind(this);
        this.closeSlidingPane = this.closeSlidingPane.bind(this);
        
        //props.apiRequestManager.queueRequest(actionTypes.FETCH_ACCOUNTS);
    }

    componentDidMount() {
        this.props.onLoad();
        const contentEl =  document.querySelector('.pymt-Ly-ct');
        contentEl.addEventListener('scroll', this.handleScroll);
        
        //document.body.classList.add('diable-scroll'); 
    }

    static getDerivedStateFromProps(props, state) {
        // Return null to indicate no change to state.
        return null;
    }
    
    componentWillUnmount() {
        const contentEl =  document.querySelector('.pymt-Ly-ct');
        contentEl.removeEventListener('scroll', this.handleScroll);
        this.props.unLoad();
    }

    willHandleTableRowMouseOver(rowData) {
        (this.props.rowBillContext === undefined || !this.props.rowBillContext) && this.props.rowDidMouseOver(rowData);
    }
    /* Context Menu Actions */
    viewPaymentAction(evt) {
        this.handlePaymentClick(this.props.rowPayment);
    }
    /* ----End of Context Menu Actions--- */

    contextMenuWillAppear(evt) {
        //this.props.contextMenuEvent(true);
    }

    contextMenuDismissed(evt) {
        //this.props.contextMenuEvent(false);
    }
    
    handleScroll(event) {
        return;
    }

    switchView(evt) {
        evt.preventDefault();
        const view = evt.target.getAttribute("to");

        if (this.state.activeView !== view) {
            this.setState({ activeView: view });
        }
    }

    contextMenuOptClick(evt, data) {
        switch(data.opt){
            case 'view':
                return this.viewPaymentAction(evt);
            case 'resultSheet':
                return this.addResultSheetAction(evt);
            case 'changeStatus':
                return this.handlerChangeStatusAction(evt, data);
            default:
        }
    }

    handlePaymentClick = (data) => {
        this.props.setMode(ACTION_BAR_MODES.edit);
        this.props.setPaymentData({payment: data});
        if (this.props.useClientLayout) {
            this.props.history.push(urls.billViewUri);
        }else {
            this.props.history.push(urls.updateBillUri);
        }
    }
    
    onCreateBill(evt) {
        this.props.history.push(urls.createBillUri);
    }

    showPaymentModal(evt) {
        this.setState({ isPaneOpen: true, modalView: 'payment' });
    }

     showReceiptModal(data) {
        this.props.setPaymentData({payment: data});
        this.setState({ isPaneOpen: true, modalView: 'receipt' });
    }

    closeSlidingPane(){
        this.setState({ isPaneOpen: false });
        this.props.setPaymentData({ payment: null });
    }

    render () {

        const quickActionCallback = {
            'viewAction' : this.showReceiptModal,
        };
        
        const contextMenuOptClick = this.contextMenuOptClick;
        const modalTitle = this.modalViews.payment === this.state.modalView ? 'Payment' : 'Payment Receipt';

        return (
            <div className={`content-layout-wrap payments--${this.state.activeView}`}>
               <div className="sidebar">
                    <div className="button-wrap">
                        <Authority  allowed="create" entity="payment" >
                            <Button className="default-btn add-btn" onClick={this.showPaymentModal}>
                                <span className="icon">
                                    <img src={getIconUrl("payment")} alt="" /></span>
                                New Payment
                            </Button>
                        </Authority>
                    </div>
                    <div className="nav-container">
                        
                        <nav className="sidebar-nav">
                           
                        <Nav>
                            { !this.props.useClientLayout &&
                            <React.Fragment>
                            <NavItem>
                                <NavLink to={"overview"} onClick={this.switchView} className={classnames({ active: this.state.activeView === 'overview' }) } ><span className="icon"><img src={getIconUrl("recent")} alt='' /></span>Overview</NavLink>
                            </NavItem>
                            </React.Fragment>
                            }
                            <Authority allowed='view' entity='payment'>
                            <NavItem>
                               <NavLink to={"payments"} onClick={this.switchView} className={classnames({ active: this.state.activeView === 'payments' })}><span className="icon"><img src={getIconUrl("payments")} alt='' /></span>Payments</NavLink>
                            </NavItem>
                            </Authority>
                        </Nav>
                        
                        </nav>
                    </div>
               </div>
               <div className="v-line"></div>
               <div className="view-layout-content container">
                   <div className="pymt-Ly-ct">

                        { ( this.state.activeView === 'overview' && 
                            <Overview {...this.props} /> )
                        }

                       { ( this.state.activeView === 'payments' && 
                            <Payments 
                            quickActions={quickActionCallback}
                            willHandleTableRowMouseOver={this.willHandleTableRowMouseOver}
                            {...this.props} /> 
                       )}

                   </div>
               </div>

               <SlidingPane
                    closeIcon={<CloseButton />}
                    isOpen={this.state.isPaneOpen}
                    title={modalTitle}
                    from="bottom"
                    width="100%"
                    onRequestClose={this.closeSlidingPane}
                >
                    { this.state.modalView === this.modalViews.payment && Object.blank(this.props.payment) ?
                        <Pay viewContext={this.state.activeView}/> 
                        :
                        <Receipt viewContext={this.state.activeView} payment={this.props.payment}/> 
                    }
                </SlidingPane>
            </div>
        );
    }
}

const PaymentView = (props) => {
    return (
        <Payment {...props } />
    );
};

let ConfigPaymentView = setupPage(Payment.pageOption)(PaymentView);
ConfigPaymentView =  connect(mapStateToProps, mapDispatchToProps)(ConfigPaymentView);

export { ConfigPaymentView };