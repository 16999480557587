import React from 'react';
import { connect } from 'react-redux';
import { setupPage } from '../../components/Layout/setupPage';
import { 
    Container,
    Row,
    Col,
    Section,
} from '../../components';

import  * as actions  from '../../store/actions';
import { Authority } from '../../components';
import * as urls from '../../config/urls';
import  { PermissionEnforcer }  from '../../utility/PermissionEnforcer';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
import ActionBar from '../components/partials/ActionBar';

import './Payroll.style.scss';
import { TAX_CODE } from '../../common/constant';

const DEDUCTION_TYPE = {
    Pay: 'Pay',
    Tax: 'Tax'
}

const mapStateToProps = state => ({
    apiRequestManager: ApiRequestManager.getInstance(ApiRequestManager),
    useClientLayout: state.global.useClientDashboard,
    mode:  state.global.actionBarMode,
    ...state.payroll
});

const mapDispatchToProps = dispatch => ({
    onLoad: () => dispatch(actions.payrollCreateViewLoadedAction()),
    unLoad: () => dispatch(actions.payrollCreateViewUnloadAction())
});


const PayeeRowItem = (props) => {
    const {payAdvice, showPayslip} = props;
    return (
        <tr>
            <td>
                {payAdvice.employee.firstName} {payAdvice.employee.lastName}
            </td>
            <td>
                ${payAdvice.totalEarnings.toCurrency()}
            </td>
            <td>
                ${payAdvice.totalDedutions.toCurrency()}
            </td>
            <td>
                ${payAdvice.grossPay.toCurrency()}
            </td>
            <td>
                ${payAdvice.totalTaxes.toCurrency()}
            </td>
            <td>
            <Authority allowed="view" entity="payadvice">
                <a href onClick={(evt) => showPayslip(payAdvice)} className="arrow-link">
                    <span className="arrow-indicator"></span>${payAdvice.netPay.toCurrency()}
                </a>
            </Authority>
            </td>
        </tr>
    );
}

class Details extends React.Component {

    static pageOption = { 
        pageTitle: 'Pay Slips',
        disableBodyScroll: true
    };

    payslipUri = '/payroll/payslip/';

    constructor(props) {
        super(props);
        this.state = {
            showPayAdvices: false,
            showTaxes: true
        };

        (!this.props.payrollData && this.props.history.push(urls.payrollUri));

        this.willShowPayslip = this.willShowPayslip.bind(this);
        this.showPaySummary = this.showPaySummary.bind(this);
        this.showTaxesSummary = this.showTaxesSummary.bind(this);
        this.handleShowPayslips = this.handleShowPayslips.bind(this);
        this.backToHandler = this.backToHandler.bind(this);
    }

    componentDidMount() {}

    componentWillUnmount() { 
        this.props.unLoad();
    }

    static getDerivedStateFromProps(props, state) {
        return null;
    }

    willShowPayslip(payAdvice) {
       window.open(`${this.payslipUri}${this.props.payrollData.id}?payadvice_f=`+ payAdvice.id, "_blank");
    }

    handleShowPayslips() {
        window.open(`${this.payslipUri}${this.props.payrollData.id}`, "_blank");
    }

    showPaySummary(evt) {
        this.setState({showPayAdvices: !this.state.showPayAdvices});
    }

    showTaxesSummary(evt) {
        this.setState({showTaxes: !this.state.showTaxes});
    }

    backToHandler() {
        this.props.history.push(urls.payrollUri);
    }

    render () {
        if (!this.props.payrollData) return null;

        const actionBarCallback = { 
            'back': this.backToHandler
        };

        const payroll = this.props.payrollData;
        const payAdvicesData = payroll.payAdvices.map((item) => {
            const employee = payroll.payees.filter((emp) => item.employee == emp.id);

            let totalEarning = 0.0;
            let totalDedutions = 0.0;
            let totalTaxes = 0.0;

            item.deductions.forEach((entry) => {
                if (entry.type === DEDUCTION_TYPE.Tax) {
                    totalTaxes += entry.amount;
                }else {
                    totalDedutions += entry.amount;
                }
            });

            item.earnings.forEach((entry) => {
                if (entry.desc !== 'BaseWage') {
                    totalEarning += entry.amount;
                }
            });

            return {
                ...item,
                employee: employee[0],
                totalDedutions : totalDedutions,
                totalEarnings : totalEarning,
                totalTaxes : totalTaxes
            }
        })
        const taxesData = this.props.payrollData.taxSummary ? this.props.payrollData.taxSummary : [];
        let taxesTotal = 0.0;
        taxesData.map((item) => taxesTotal += item.taxTotal);

        const copyRightYear = new Date().getFullYear();
      
        return (
            <React.Fragment>
                <ActionBar title={ "" }  entityContext={'payroll'} actionResponsers={ actionBarCallback } permissionContext={ '' } delete={false} />

            <div className='content-layout-wrap payroll-detail-view'>
                <div className="view-layout-content">
                   <div className="default-Ly-ct">
                       <Container className="payroll-overview">
                            <div className="title-box">
                                <h1 className="title">Payroll Report</h1>
                            </div>
                            
                            <div className="sumry-title">
                                <h3>Payroll Summary | {payroll.payrollDate}</h3>
                            </div>
                    
                           <Row>
                            <Col md={7}>
                                <div className="content-wrap">
                                   
                                    <div className="receipt-box">
                                        <div className="payroll-receipt">
                                            <div className="inner-content">
                                                <Section className="top">
                                                    <div className="title-flex">
                                                        <h4>Payroll Receipt</h4>
                                                        <span># {payroll.id}</span>
                                                    </div>
                                                    
                                                    <div className="perforation"></div>
                                                </Section>
                                                <Section className="middle">
                                                    <div className="meta-data-details">
                                                        <div className="meta-data-grp">
                                                            <p className="meta-data-line">
                                                                <span className="label">
                                                                    Employees Paid: 
                                                                </span>
                                                                <span className="value">
                                                                    {payAdvicesData.length} 
                                                                </span>
                                                            </p>
                                                            <p className="meta-data-line">
                                                                <span className="label">
                                                                    Payroll Period: 
                                                                </span>
                                                                <span className="value">
                                                                    {payroll.payrollDate}
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <div className="meta-data-grp">
                                                            <p className="meta-data-line">
                                                            For government taxes <b>${taxesTotal.toCurrency()}</b>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="payroll-totals">
                                                        <div className="total sub">
                                                            <span className="label">Net Payout</span>
                                                            <span className="amount">${payroll.totals.netPayoutAmount.toCurrency()}</span>
                                                        </div>
                                                        <div className="total lrg">
                                                            <span className="label">Payout Total</span>
                                                            <span className="amount">${payroll.totals.grossPayoutAmount.toCurrency()}</span>
                                                        </div>
                                                    </div>
                                                </Section>
                                                <Section className="bottom">
                                                    <div className="info-box">
                                                        <p className="info-text">
                                                        These amounts may not represent all monies due to you from government tax authorities, and do not include any amounts transmitted outside the CytoLabs platform.
                                                        </p>
                                                        <p className="info-text">
                                                        © {copyRightYear} CytoLabs
                                                        Kingstion 10, Jamaica, W.I | (876) 616-2671
                                                        CytoLabs Payroll, is not a licensed money transmitter. For more about CytoLabs's licenses and your state-specific rights to request information, submit complaints, dispute errors, or cancel transactions.
                                                        </p>
                                                    </div>
                                                </Section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={5}>
                                <Section className="cards-sec">
                                    <div className="meta-data-card">
                                        <div className="inner-content">
                                            <h4 className="title">Payroll Meta Data</h4>
                                            <div className="meta-data">
                                                <span className="label">Integrity</span>
                                                <p className={payroll.integrity.toLowerCase()}>{payroll.integrity}</p>
                                            </div>
                                            <div className="meta-data">
                                                <span className="label">Created On</span>
                                                <p>{payroll.dateCrated}</p>
                                            </div>
                                            <div className="meta-data">
                                                <span className="label">Approved Date</span>
                                                <p>{payroll.approvedDate}</p>
                                            </div>
                                            <div className="meta-data">
                                                <span className="label">Payroll Status</span>
                                                <p>{payroll.status}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="meta-data-card">
                                        <div className="inner-content">
                                            <h4 className="title">Approved By</h4>
                                            <div className="meta-data">
                                                <span className="label">{payroll.approvedDate}</span>
                                                <p><b>{payroll.approver}</b> Adjustments include reversing, correcting under or overpayments, changing a payment methods, etc.</p>
                                            </div>
                                        </div>
                                    </div>
                                </Section>
                            </Col>
                           </Row>
                           <Row>
                               <Col>

                               <Section className="payroll-meta-details">
                                    <div className="action-links">

                                        <div className="taxes-summary">
                                            <a href onClick={this.showTaxesSummary} className="arrow-link"><span className="arrow-indicator"></span>View Tax Collection Summary</a>
                                        
                                            <div className="summary-details">
                                            { this.state.showTaxes && 
                                                <table className="smry-table">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                Tax Code
                                                            </th>
                                                            <th>
                                                                Percent
                                                            </th>
                                                            <th>
                                                                Amount
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        { taxesData.map((tax, i) => {
                                                        return <tr key={i}>
                                                            <td>
                                                                {tax.taxCode}
                                                            </td>
                                                            <td>
                                                                {tax.taxCode === TAX_CODE.PAYE ? '-' : `${tax.percent.toFixed(1)}%`}
                                                            </td>
                                                            <td>
                                                                ${tax.taxTotal.toCurrency()}
                                                            </td>
                                                        </tr>
                                                        })}
                                                        <tr className="totals-row">
                                                            <td>
                                                                Totals
                                                            </td>
                                                            <td>
                                                            </td>
                                                            <td>
                                                                ${taxesTotal.toCurrency()}
                                                            </td>
                                                        </tr>                                              
                                                    </tbody>
                                                </table>
                                                }
                                            </div>
                                        </div>
                                    
                                        <div className="pay-advice-summary">
                                            <a href onClick={this.showPaySummary} className="arrow-link"><span className="arrow-indicator"></span>View Employees Pay Advice Summary</a>

                                            <div className="summary-details">
                                                { this.state.showPayAdvices && 
                                                <table className="smry-table">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                Employee
                                                            </th>
                                                            <th>
                                                                Added Earnings
                                                            </th>
                                                            <th>
                                                                Deductions
                                                            </th>
                                                            <th>
                                                                GrossPay
                                                            </th>
                                                            <th>
                                                                Taxes
                                                            </th>
                                                            <th>
                                                                NetPay
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        { payAdvicesData.map((payAdvice, i) => {
                                                        return <PayeeRowItem 
                                                        key={i} 
                                                        payAdvice={payAdvice}
                                                        showPayslip={this.willShowPayslip}/>
                                                        })}
                                                    </tbody>
                                                </table>
                                                }
                                            </div>
                                        </div>
                                        <Authority allowed="view" entity="payadvice">
                                            <a href onClick={this.handleShowPayslips} className="arrow-link"><span className="arrow-indicator"></span>View Pay Advice Slips</a>
                                        </Authority>
                                    </div>
                                </Section>
                                
                               </Col>
                           </Row>
                        </Container>
                   </div>
                </div>
            </div>
            </React.Fragment>
        );
    }
}

const DetailsView = (props) => {
    return (
        <Details {...props } />
    );
};

let ConfigDetailsView = setupPage(Details.pageOption)(DetailsView);
ConfigDetailsView =  connect(mapStateToProps, mapDispatchToProps)(ConfigDetailsView);

export { ConfigDetailsView };