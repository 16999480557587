import React from 'react';
import TemplateFooter from './TemplateFooter';

class Report_RP09 extends React.PureComponent  {
    
    
    render () {

        const {ReportHeader, ReportFooter, ReportNotes} = this.props;
        const reportData = Array.isArray(this.props.reportResults) ? this.props.reportResults : [];

        return <React.Fragment>
            <div  className="print-template">
                { ReportHeader }
                <div className="report-body">

                    <table className="default">
                        <thead>
                            <tr>
                                <th>Account#</th>
                                <th>Owner</th>
                                <th>Status</th>
                                <th>Created</th>
                                <th className="number">Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            { reportData.map((account, i) => (
                                <tr key={i} className="smpl-row row-divider">
                                <td>{account.accountNo}</td>
                                <td>
                                    <div className="info-line">{account.owner}</div>
                                    <div className="info-line">{account.clientInfo.officeName ? account.clientInfo.officeName: `${ account.clientInfo.firstName} ${ account.clientInfo.lastName}`}</div>
                                    <div className="info-line">{account.clientInfo.type}</div>
                                </td>
                                <td>{account.active ? 'Active' : 'InActive'}</td>
                                <td>{account.dateCreated}</td>
                                <td className="number">${account.balance.toCurrency()}</td>
                                </tr>
                            ))}
                            
                        </tbody>
                    </table>
                </div>
                { <TemplateFooter 
                    ReportFooter={ReportFooter}  
                    ReportNotes={ReportNotes}
                    /> 
                }
            </div>
           
        </React.Fragment> 
    }  
}

export default Report_RP09;