import React from 'react';

const NotificationBadge = (props) => {
    const {count} = props;
    const showBadge = count > 0 ? true : false;
    return(
        <div className="notification-badge">
            { showBadge &&
                <div className="nf-badge">
                    <span className="badge-count">{count <= 9 ? count : '9+'}</span>
                </div>
            }
        </div>
    );
}
export default NotificationBadge;