let CATALOGUE = {
  signature: require('./../assets/images/signature-placeholder.svg'),
  approvedWhite: require('./../assets/images/approved-icon-w.svg'),
  logo: require('./../assets/images/dashboard-logo.svg'),
  view: require('./../assets/images/view-icon.svg'),
  bell: require('./../assets/images/bell-icon.svg'),
  filter: require('./../assets/images/filter-icon.svg'),
  shortArrow: require('./../assets/images/short-arrow-icon.svg'),
  key: require('./../assets/images/key-icon.svg'),
  // Specimen View
  recent: require('./../assets/images/recent-icon.svg'),
  overview: require('./../assets/images/overview-icon.svg'),
  authorize: require('./../assets/images/authorize-icon.svg'),
  sample: require('./../assets/images/sample-icon.svg'),
  slide: require('./../assets/images/slide-icon.svg'),
  samples: require('./../assets/images/sample-multi-icon.svg'),
  requisition: require('./../assets/images/requisition-icon.svg'),
  addUser: require('./../assets/images/add-user-icon.svg'),
  option: require('./../assets/images/option-icon.svg'),
  //Navigation Icons
  dashboard: require('./../assets/images/dashboard-icon.svg'),
  users: require('./../assets/images/users-icon.svg'),
  appointment: require('./../assets/images/appointment-icon.svg'),
  specimens: require('./../assets/images/sample-single-icon.svg'),
  patient: require('./../assets/images/patient-icon.svg'),
  addPatient: require('./../assets/images/add-patient-icon.svg'),
  more: require('./../assets/images/more-icon.svg'),
  account: require('./../assets/images/account-icon.svg'),
  employee: require('./../assets/images/employee-icon.svg'),
  billing: require('./../assets/images/billing-icon.svg'),
  paymentIcon: require('./../assets/images/payment-icon-2.svg'),
  report: require('./../assets/images/report-icon.svg'),
  // Billing Icons
  bill: require('./../assets/images/add-bill-icon.svg'),
  bills: require('./../assets/images/bills-icon.svg'),
  results: require('./../assets/images/results-icon.svg'),
  unpaid: require('./../assets/images/unpaid-icon.svg'),
  // Client Icons
  profile: require('./../assets/images/profile-image-placeholder.svg'),
  //Dashboard &  Main layout icon Icons
  specimen: require('./../assets/images/dashboard-specimen-icon.svg'),
  dashboardPatient: require('./../assets/images/dashboard-patient-icon.svg'),
  lab: require('./../assets/images/dashboard-lab-icon.svg'),
  labs: require('./../assets/images/labs-icon.svg'),
  doctor: require('./../assets/images/dashboard-doctor-icon.svg'),
  doctors: require('./../assets/images/doctors-icon.svg'),
  //Employee Icons
  role: require('./../assets/images/role-icon.svg'),
  plusIcon: require('./../assets/images/plus-icon.svg'),
  // Client layout icons
  dashboardSpecimen: require('./../assets/images/dashboard-specimen-icon.svg'),
  dashboardusers: require('./../assets/images/dashboard-users-icon.svg'),
  male: require('./../assets/images/dashboard-male-icon.svg'),
  female: require('./../assets/images/dashboard-female-icon.svg'),
  // Login icons
  addAuthUser: require('./../assets/images/add-auth-user-icon.svg'),
  removeUser: require('./../assets/images/remove-auth-user-icon.svg'),
  eye: require('./../assets/images/eye-icon.svg'),
  // Message icons
  inbox: require('./../assets/images/inbox-icon.svg'),
  sent: require('./../assets/images/sent-icon.svg'),
  reply: require('./../assets/images/reply-icon.svg'),
  message: require('./../assets/images/message-icon.svg'),
  newMessage: require('./../assets/images/new-message-icon.svg'),
  trash: require('./../assets/images/trash-icon.svg'),
  read: require('./../assets/images/read-receipt-icon.svg'),
  // Patient icons
  close: require('./../assets/images/remove-icon.svg'),
  singleUser: require('./../assets/images/single-user-icon.svg'),
  femaleProfile: require('./../assets/images/female-profile-icon.svg'),
  // Payment icons
  payment: require('./../assets/images/add-payment-icon.svg'),
  payments: require('./../assets/images/payments-icon.svg'),
  //payroll icons
  approvedGreen: require('./../assets/images/approved-icon-g.svg'),
  profileIconTwo: require('./../assets/images/profile-icon.svg'),
  payrollBlue: require('./../assets/images/pay-advice-blue-icon.svg'),
  payroll: require('./../assets/images/pay-advice-icon.svg'),
  date: require('./../assets/images/date-icon.svg'),
  //permission
  plus: require('./../assets/images/plus-icon.svg'),
  blackRoleIcon: require('./../assets/images/role-black-icon.svg'),
  lock: require('./../assets/images/lock-icon.svg'),
  longArrow: require('./../assets/images/long-arrow-icon.svg'),
  info: require('./../assets/images/info-icon.svg'),
  userRoleType: require('./../assets/images/user-role-type-icon.svg'),
  workspaceRoleType: require('./../assets/images/workspace-role-type-icon.svg'),
  //Reports
  edit: require('./../assets/images/edit-icon.svg'),
  reset: require('./../assets/images/reset-icon.svg'),
  search: require('./../assets/images/search-icon.svg'),
  // Settings
  setting: require('./../assets/images/setting-icon.svg'),
  settings: require('./../assets/images/settings-icon.svg'),
  power: require('./../assets/images/power-icon.svg'),
  logout: require('./../assets/images/logout-icon.svg'),
  help: require('./../assets/images/question-mark-icon.svg'),
  labcode: require('./../assets/images/labcode-icon.svg'),
  rolePerm: require('./../assets/images/role-permission-icon.svg'),
  form: require('./../assets/images/form-icon.svg'),
  service: require('./../assets/images/services-icon.svg'),
  textField: require('./../assets/images/text-field-icon.svg'),
  checkField: require('./../assets/images/check-field-icon.svg'),
  //Profile
  profileSettings: require('./../assets/images/profile-settings.svg'),
  changePassword: require('./../assets/images/change-password.svg'),
  passwordStar: require('./../assets/images/password-star.svg'),
  // Specimen
  specimenTwo: require('./../assets/images/specimen-two-icon.svg'),
  submit: require('./../assets/images/submit-icon.svg'),
  gyncForm: require('./../assets/images/gync-form-icon.svg'),
  nonGyncForm: require('./../assets/images/non-gync-form-icon.svg'),
  attachment: require('./../assets/images/attachment-icon.svg'),
  link: require('./../assets/images/requisition-link-icon.svg'),
  // Requistion
  create: require('./../assets/images/add-bill-icon.svg'),
  tick: require('./../assets/images/approved-icon-g.svg'),
  // Users
  multiUser: require('./../assets/images/multi-user-icon.svg'),
  authorizerUser: require('./../assets/images/authorizer-icon.svg'),
  blockedUser: require('./../assets/images/blocked-user-icon.svg'),
  //Action bar
  save: require('./../assets/images/save-icon.svg'),
  back: require('./../assets/images/back-icon.svg'),
  //Notification
  empty: require('./../assets/images/no-notification-icon.svg'),
  clear: require('./../assets/images/clear-all-icon.svg'),
  //Print
  print: require('./../assets/images/print-icon.svg'),
  doc: require('./../assets/images/doc-icon.svg'),
  //
  folder: require('./../assets/images/folder-icon.svg'),
  fileCabinet: require('./../assets/images/file-cabinet-icon.svg'),
  chainLink: require('./../assets/images/chain-link-icon.svg'),
};

export const getIconUrl = (name) => {
  return CATALOGUE[name].default;
};
