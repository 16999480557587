import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { Comparator, dateFilter } from 'react-bootstrap-table2-filter'
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import PropType  from 'prop-types';

import { InputField, List , NavLink} from '../index';
import {pictureInitials, stripHtmlTags} from './../../utility/helpers';

import { PaginationPanel, PaginationTotal } from './';
import { PAGE_SIZE } from '../../config/pagination';
import { getIconUrl } from '../../common/images-catalogue';

const generateRow = (data) => ({
    id: data.id,
    picture: data,
    username: data,
    details: data,
    date: data
});

const isUnread = (message) => {
    return (!message.isRead && message.tag === 'Inbound');
}
const userPicture = (data) => {
    const firstName = data.tag === 'Inbound' ? 
            data.headerFrames.first()?.sender?.firstName || '' :
            data.headerFrames.first()?.recipient?.firstName || '';

    const lastName  = data.tag === 'Inbound' ? 
    data.headerFrames.first()?.sender?.lastName || '' :
    data.headerFrames.first()?.recipient?.lastName || '';

    return (
        <ContextMenuTrigger id="users_table_context_menu">
        <div className="user-picture">
            <div className="picture-holder">
                <div className="picture-content">
                { pictureInitials(firstName, lastName) }
                </div>
            </div>
        </div>
        </ContextMenuTrigger>
    );
}

const userNameInfo = (data) => {
    const firstName = data.tag === 'Inbound' ? 
            data.headerFrames.first()?.sender?.firstName :
            data.headerFrames.first()?.recipient?.firstName;

    const lastName  = data.tag === 'Inbound' ? 
    data.headerFrames.first()?.sender?.lastName :
    data.headerFrames.first()?.recipient?.lastName;

    const name = firstName + ' ' + lastName;
    const unread = isUnread(data) ? '--unread' : '';

    return (
        <ContextMenuTrigger id="users_table_context_menu">
        <div className={`user-info ${unread}`}>
            <div className="user-info-holder">
                <div className="user-info-content">
                    <h3>{name}</h3>
                </div>
            </div>
        </div>
        </ContextMenuTrigger>
    );
}

const messageDetails = (data) => {
    //return function with cell content
    const subject = data.headerFrames.first()?.subject || '';
    const body = data.body;
    const unread = isUnread(data) ? '--unread' : '';
    const showNewBadge = isUnread(data)  ? true : false;

    return (
        <div className="message-details">
            <ContextMenuTrigger id="users_table_context_menu">
            <div className={`message-details-holder ${unread}`}>
                <div className="message-details-content">
                    { showNewBadge && 
                        <span className="new-msg">New</span>
                    }
                    <div className="msg-prv">
                        <div className='prv-txt'>
                            <div className='sub-txt'>
                                {stripHtmlTags(subject)}
                            </div>
                            <div className="msg-txt">
                                <span> - </span>
                               {stripHtmlTags(body).replace(/(&nbsp;)/gi, '')}
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
            </ContextMenuTrigger>
        </div>
    );
}

const dateDetails = (actions) => {
    //return function with cell content
    return (data) => {
        const unread = isUnread(data) ? '--unread' : '';
        
    return (<div className={`date-details ${unread}`}>
            <ContextMenuTrigger id="date_table_context_menu">
            <div className="toolbar">
                <List className="action-menu">
                    <li className="action-item">
                        <NavLink onClick={ () => {} }>
                            <span className="icon"><img className="icon" src={getIconUrl("view")} alt='' /></span>
                        </NavLink>
                    </li>
                    <li className="action-item">
                        <NavLink>
                            <span className="icon"><img className="icon" src={getIconUrl("option")} alt='' /></span>
                        </NavLink>
                    </li>
                </List>
            </div>
            
            <div className="date-details-holder --unread">
                <div className="date-details-content">
                    <span className="date">{data.sendDate}</span>
                </div>
            </div>
            </ContextMenuTrigger>
        </div>
    )};
}

class MessagesTable extends React.Component { 
    static propsTypes = {
        tableData: PropType.array.require,
        actionResponsers: PropType.object,
    }

    static defaultProps = {
        actionResponsers: {
            editButton: null,
        }
    }

    constructor(props) {
        super(props);
        
        this.state = {
            selected: []
        };
        
        this.willEnterRow = this.willEnterRow.bind(this);
        this.willClickRow = this.willClickRow.bind(this);
        this.didContextClick = this.didContextClick.bind(this);
    }

    createColumnDefinitions(quickActions) { 
        return [
            {
                dataField: 'picture',
                text: "Picture",
                headerAttrs: {
                    hidden: true
                },
                classes: 'id-picture-cell td-cell',
                formatter: userPicture
            },
            {
                dataField: 'username',
                text: "Username",
                headerAttrs: {
                    hidden: true
                },
                classes: 'id-user-cell td-cell',
                formatter: userNameInfo
            },
            {
                dataField: 'details',
                text: "Details",
                headerAttrs: {
                    hidden: true
                },
                classes: 'id-details-cell td-cell',
                formatter: messageDetails
            },
            {
                dataField: 'date',
                text: "Date",
                headerAttrs: {
                    hidden: true
                },
                classes: 'id-date-cell td-cell',
                formatter: dateDetails( quickActions )
            }
        ];
    }

    handleSelect() { }

    handleSelectAll() { }

    willEnterRow (evt, row, rowIndex) { }

    willClickRow (evt, row, rowIndex) {
        this.props.quickActions.viewThreadAction(evt, row.details);
    }

    didContextClick (evt, row, rowIndex) {
        evt.preventDefault();
    }

    render () {
        const columnDefs = this.createColumnDefinitions(this.props.quickActions);
        const paginationDef = paginationFactory({
            paginationSize: 1,
            showTotal: true,
            sizePerPage: PAGE_SIZE,
            pageListRenderer: (props) => (
                <PaginationPanel { ...props } size="sm" className="ml-md-auto mt-2 mt-md-0" />
            ),
            paginationTotalRenderer: (from, to, size) => (
                <PaginationTotal { ...{ from, to, size } } />
            )
        
        });
        const selectRowConfig = {
            mode: 'checkbox',
            selected: this.state.selected,
            onSelect: this.handleSelect.bind(this),
            onSelectAll: this.handleSelectAll.bind(this),
            selectionRenderer: ({ mode, checked, disabled }) => (
                <InputField id="" type={ mode } checked={ checked } disabled={ disabled } />
            ),
            selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
                <InputField id="" type={ mode } checked={ checked } innerRef={el => el && (el.indeterminate = indeterminate)} />
            )
        };

        const rowEventsHandlers = {
            onContextMenu: this.didContextClick,
            onMouseEnter: this.willEnterRow,
            onClick: this.willClickRow
        };

        var options = {
            noDataMessage: () => 'No messages found.'
        };

        const messagesData = this.props.tableData.map(message => generateRow(message));

        return (
            <ToolkitProvider
                keyField="id"
                data={ messagesData }
                columns={columnDefs} >
                {
                    props => (
                        <React.Fragment>
                            <BootstrapTable
                            classes="table-responsive messages"
                            pagination={ paginationDef }
                            filter={ filterFactory() }
                            selectRow={ selectRowConfig }
                            bordered={ false }
                            responsive
                            { ...props.baseProps }
                            rowEvents={ rowEventsHandlers }
                            loading={ true } 
                            noDataIndication={options.noDataMessage}
                        />
                        </React.Fragment>
                    )
                }
            </ToolkitProvider>
        );
    }
}

export { MessagesTable }