import { requests } from './agentDialect';

export const fetchAllPayrolls = () => {
    let uri =  "/payrolls";
    return requests.get(uri);
};
export const fetchAllPayAdvice = (payrollId, payAdvice) => {
    let uri =  payAdvice ===  undefined || !payAdvice ? `/payroll/payadvice/${payrollId}` : `/payroll/payadvice/${payrollId}?_f=${payAdvice}`;
    return requests.get(uri);
};

export const submitPayroll = (data) => {
    let uri = "/payroll";
    return requests.post(uri, data);
};

export const updatePayroll = (data) => {
    let uri = `/payroll/approve/${data.payrollId}?user=${data.user}`;
    return requests.put(uri, data);
};