import * as actionTypes from '../actionType';
import { isError } from '../../service/agent';

const initialState = {
    viewInit: false,
    preserveState: false,
    inboxMessages: null,
    sentMessages: null,
    messageThread: null,
    unread: []
 };

var error = null;
let emptyArray = [];

const processThreadViewContext = (state, message) => {
    const newState = processlistViewContext(state, message);
    
    if (newState.messageThread.id === message.messageThread) {
        return {
            ...newState,
            messageThread : {
                ...newState.messageThread,
                messages: [
                    ...newState.messageThread.messages,
                    message
                ]
            } 
        }
    }
    return {
        ...newState
    }  
} 

const processlistViewContext = (state, message) => {
    //[1] Update inbox message list
    var inboundMessages = null;
    
    if (!message) {
        return {...state}
    }
    
    if ( message.headerFrames[0].type === 'Reply' &&  message.tag === 'Inbound') { 
        inboundMessages =  !state.inboxMessages ? state.inboxMessages :
        state.inboxMessages.filter((msg) => {
            return msg.messageThread !== message.messageThread;
        });
    }
        
    inboundMessages = message.tag === 'Inbound' && inboundMessages ? 
        [message, ...inboundMessages] : inboundMessages;
    
    //[2] Update inbox message list
    var outboundMessages = null;

    if (message.headerFrames[0].type === 'Reply' &&  message.tag == 'Outbound') {
        outboundMessages = !state.sentMessages ? state.sentMessages : state.sentMessages.map((msg) => {
            if (msg.messageThread !== message.messageThread) {
                msg = message;
            }
            return msg;
        });
    }else {
        outboundMessages = message.tag === 'Outbound' && state.sentMessages !== null  ? 
        [message, ...state.sentMessages] : state.sentMessages;
    }
    //[3] Determine to sent unrent message
    var unread =  message.tag === 'Inbound' ?  [...state.unread, {[message.id]: message.isRead}] : state.unread;
    
    return {
        ...state,
        inboxMessages: inboundMessages,
        sentMessages: outboundMessages,
        unread: [
            ...unread
        ]
    };
} 

const messageReducer = (state = initialState, action ) => { 
    
    switch(action.type) {
        case actionTypes.MESSAGES_VIEW_LOADED :
            return {
                ...state,
                viewInit: true
            }
        case actionTypes.MESSAGE_THREAD_VIEW_LOADED :
            return {
                ...state,
            }
        case actionTypes.MESSAGE_THREAD_VIEW_UNLOAD:
            if (state.preserveState) {
                return {
                    ...state,
                    messageThread: null,
                    preserveState: false
                }
            }
            return {
               ...initialState,
               unread: state.unread
            }
        
        case actionTypes.MESSAGES_VIEW_UNLOAD:
            return state.preserveState ? { ...state } : 
            { 
                ...initialState,
                unread: state.unread
            }
            
        case actionTypes.SET_MESSAGE_VIEW:
            const {viewContext, activeView} = action.payload;
            if (viewContext !== undefined) {
                return {
                    ...state,
                    viewContext: viewContext,
                    activeView: activeView
                }
            }else {
                return {
                    ...state,
                    activeView: activeView,
                }
            }
        case actionTypes.FETCHED_MESSAGES_RESULTS:
            error = isError(action.payload);
            if (state.activeView === 'inbox') {
                return {
                    ...state,
                    inboxMessages: error ? emptyArray : action.payload
                }
            }else {
                return {
                    ...state,
                    sentMessages: error ? emptyArray : action.payload
                }
            }
        case actionTypes.FETCH_MESSAGE_THREAD:
            return {
                ...state,
                messageThread:  {messages: []}
            }   
        case actionTypes.FETCHED_MESSAGE_THREAD_RESULT:
            error = isError(action.payload);
            return {
                ...state,
                messageThread: error ? null : action.payload
            }
        case actionTypes.RECEIVED_MESSAGE:
            if (state.viewContext === 'thread') {
                return processThreadViewContext(state, action.payload);
            }else  {
                return processlistViewContext(state, action.payload);
            }
        
        case actionTypes.FETCHED_GLOBAL_DATA_RESULTS:
            error = isError(action.payload.unreadMessages);
            return {
                ...state,
                unread: error ? null : action.payload.unreadMessages
            }
        
        case actionTypes.SEND_MESSAGE_READ_RESULT:
            error = isError(action.payload);
            var unreads = state.unread && state.unread.empty() ? state.unread : initialState.unread;

            if ( !error && unreads !== null && !unreads.empty() ) {
                var readList = action.payload;

                unreads = unreads.filter((item) => {
                    let unreadMsg = parseInt(Object.keys(item)[0]);
                    var isMatch = false;
                    for(var i = 0; i < readList.length; i++) {
                        if (readList[i] === unreadMsg) {
                            readList.splice(i, 1);
                            isMatch = true;
                            break;
                        }
                    }
                    if (!isMatch) 
                        return item;
                });
            }
            return {
                ...state,
                unread: unreads,
                inboxMessages: null
            }
        case actionTypes.DELETE_MESSAGE_RESULT:
            error = isError(action.payload);

            if ( error ) {
                return {
                    ...state
                }
            }else {
                if (action.payload.hasOwnProperty('message')) {
                    var filteredMessages = state.messageThread.messages.filter((msg) => msg.id !== action.payload.message);
                    return {
                        ...state,
                        messageThread: {
                            ...state.messageThread,
                            messages : filteredMessages
                        }
                    }
                }else {

                    const clearMessages = state.activeView === 'index' ? { inboxMessages: null } : {sentMessages: null };

                    return {
                        ...state,
                        messageThread: null,
                        redirectTo: '/messages',
                        ...clearMessages
                    }
                }
            }
        case actionTypes.MESSAGES_PRESERVE_STATE:
            return { ...state, preserveState: action.payload };
        case actionTypes.REDIRECT:
            return { ...state, redirectTo: null };
             
        default:
            return state;
    }
};

export default messageReducer;