import React from 'react';

class TemplateFooter extends React.PureComponent  {

    render () {
        const { ReportFooter, ReportNotes } = this.props;
        
        return (
            <div  className="print-template-footer">
                { ReportNotes }
                { ReportFooter }
            </div>   
        ) 
    }  
}

export default TemplateFooter;