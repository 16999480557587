import React from 'react';
import { Caret } from './../../../utility/Caret';

const BloodVial = (props) => {
    const { bloodType, specimenLabel, cork, clickHandler, onTypedHandler, data, index, editable} = props;
    var corkColor = '';
    const pattern = /^(A|B|AB|O)[+-]$/i;

    switch(cork) {
        case 1:
            corkColor = 'crk--c1';
            break;
        case 2:
            corkColor = 'crk--c2';
            break;
        case 3:
            corkColor = 'crk--c3';
            break;
        case 4:
            corkColor = 'crk--c4';
            break;
        case 5:
            corkColor = 'crk--c5';
            break;
        default:
    }
    const label = specimenLabel ? specimenLabel.split('.') : '';
    const willClick = (evt) => {
        clickHandler && clickHandler(evt, specimenLabel, index)
    }

    const didType = (evt) => {
        const value = evt.target.innerHTML.replace('<br>', '');

        var charCode = (evt.charCode) ? evt.charCode : ((evt.keyCode) ? evt.keyCode :
  ((evt.which) ? evt.which : 0));
        
        if (charCode !== 45 && charCode !== 43 && charCode !== 79 && charCode !== 111 && charCode !== 97 && charCode !== 65 &&  charCode !== 66 && charCode !== 98) {
            ///onTypedHandler && onTypedHandler(bloodType, index); 
            evt.preventDefault();
            return false;
        }
        if ( value.length > 2) {
            evt.preventDefault();
        }
        onTypedHandler && onTypedHandler(value.toUpperCase(), index);  
        Caret.setEndContenteditable(evt.target);
    }

    const willBlur = (evt) => {
        const value = evt.target.innerHTML;
        if (!pattern.test(value)) {
            onTypedHandler && onTypedHandler('', index); 
        }else {
            onTypedHandler && onTypedHandler(value.toUpperCase(), index); 
        }
    }
    
    return(
        <div className="vial-wrap">
        <div className="vial" onClick={willClick}>
            <div className={`vial-cork ${corkColor}`}></div>
            <div className="vial-bottle">
                <div className="v-inner">
                    <div className="vial-label">
                    <span className="blood-grp-text">
                        {label[0]}<br/>
                        {label[1]}
                    </span>
                    </div>
                    <div className="blood">
                        <div className="blood-wave">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path d="M0,64L60,96C120,128,240,192,360,218.7C480,245,600,235,720,208C840,181,960,139,1080,122.7C1200,107,1320,117,1380,122.7L1440,128L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
                        </div>
                        <div className="blood-body">
                        <span onBlur={willBlur} onKeyPress={didType} onKeyUp={didType}
                        contentEditable={editable}
                        suppressContentEditableWarning={true}
                        aria-multiline="false" className="blood-grp-text single-line">
                            {bloodType}
                        </span>
                        </div>
                    </div>
                </div>
                <span className="sheen"></span>
            </div>
        </div>
    </div>
    );
}



export default BloodVial