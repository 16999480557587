import * as actionTypes from '../actionType';
import { isError } from '../../service/agent';

const initialState = {
    viewInit: false,
    loadingData: false,
    billsHistory: null,
    paymentsHistory: null,
    accounts: null,
    currentAccount: null
 };

var error = null;
let empty = {}
let emptyArray = []
const accountReducer = (state = initialState, action ) => { 

    switch(action.type) {
        case actionTypes.EMPLOYEES_VIEW_UNLOAD:
            return {
                ...initialState
            }
        case actionTypes.FETCHED_ACCOUNTS_RESULT:
            error = isError(action.payload);
            return {
                accounts: error ? emptyArray : action.payload
            }
        case actionTypes.SET_SELECTED_ACCOUNT:
            return {
                ...state,
                currentAccount: action.payload
            }
        case actionTypes.FETCH_BILLS:
        case actionTypes.FETCH_PAYMENTS:
            return {
                ...state,
                loadingData: true,
                billsHistory: emptyArray,
                paymentsHistory: emptyArray
            }
        case actionTypes.FETCHED_BILLS_RESULTS:
            error = isError(action.payload);
            return {
                ...state,
                billsHistory: error ? emptyArray : action.payload,
                loadingData: false
            }
        case actionTypes.FETCHED_PAYMENTS_RESULTS:
            error = isError(action.payload);
            return {
                ...state,
                paymentsHistory: error ? emptyArray : action.payload,
                loadingData: false
            }
        case actionTypes.RESET_ACCOUNT_HISTORY:
            return {
                ...state,
                billsHistory: emptyArray,
                paymentsHistory: emptyArray
            }
        case actionTypes.LOGOUT:
            return { 
                ...initialState
            };

        default:
            return state;
    }
};

export default accountReducer;