import { VALIDATOR_DELEGATE_KEY_GROUP } from "../common/constant";

const delegate = (validator, key, value) => {
  const results = {};

  switch(key){
      case VALIDATOR_DELEGATE_KEY_GROUP.formFields.bank:
        results.bankMissing = false;

        if(validator.isValueMissing(value)) results.bankMissing = true
        break;

      case VALIDATOR_DELEGATE_KEY_GROUP.formFields.chequeNumber:
        results.chequeNumberMissing = false;
        //results.chequeNumberInvalid = false;

        if(validator.isValueMissing(value)) results.chequeNumberMissing = true
        break;

      case VALIDATOR_DELEGATE_KEY_GROUP.formFields.account:
        results.accountMissing = false;

        if(value === null || value === 'null') results.accountMissing = true;
        break;

      default:
          break;
  }

  return results;
}
export default delegate;