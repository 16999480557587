import React from 'react';
import { 
    Authority,
    Section,
    List,
    Row,
    Col,
    Button,
    FormGroup,
    SimpleSelect,
    Label,
    Input,
    NavLink,
    ValidateField,
    ErrorFeedback,
} from '../../../components';
import * as actionTypes from '../../../store/actionType';
import { FORM_TYPE, REQUESTS_TYPE, STATUS } from '../../../common/constant';
import { Loader } from '../../components/partials/LoadingIndicator';
import { store } from '../../../store/store';
import * as actions from '../../../store/actions';
import * as urls from '../../../config/urls';
import { generatePath } from "react-router";
import { push } from 'connected-react-router';
import ConfirmModal from '../../components/partials/ConfirmModal';
import { PromptMessage, getPromptMessageProps } from '../../components/partials/PromptMessage';
import DateTimePicker from 'react-datetime-picker';
import { ErrorMessage } from '../../components/partials/ErrorMessage';
import SlidingPane from "react-sliding-pane";
import { Reporting } from '../../Reports';
import { CloseButton } from '../../components/partials/Buttons';
import {getDateInTimeZoneString} from '../../../utility/helpers';
import { getIconUrl } from '../../../common/images-catalogue';


const RequisitionLine = (props) => {
    const { data, createHandler, removeHandler, costDidChange, noteDidChnage, formTypeDidChange, urgentDidChange, viewSpecimenRecord } = props;
    const rowId = data.index;
    
    const onRemove = () => {
        //delete an item from server.
        if(data.id){
            removeHandler(data);
        }else{
            removeHandler(rowId);
        }
    }

    const onCreate = () => {
        createHandler(data);
    }

    const showCreateButton = data.id  ? true : false;
    const showDeleteButton = !data.id || !data.specimenRecordId;
    const isEditable = data.id ? true : false;
    const isProcessed = data.specimenRecordRef ? true : false;

    return (<div className="rqt-entry rqt-line">
            <div className="form-type">
                <Label className="ip-radio-pill">
                    <Input onChange={ (evt) => formTypeDidChange(evt, rowId) } type="radio" 
                    name={`formType-${rowId}`}
                    value={ FORM_TYPE.Gynec }
                    disabled={isEditable}
                    checked={data.formType === FORM_TYPE.Gynec ? true : false} />{' '}
                    <span className="radioselect"></span>
                    <span className="text">{FORM_TYPE.Gynec}</span>
                </Label>
                <Label className="ip-radio-pill">
                <Input onChange={(evt) => formTypeDidChange(evt, rowId) } 
                type="radio" 
                name={`formType-${rowId}` }
                disabled={isEditable} 
                checked={data.formType === FORM_TYPE.NonGynec ? true : false}
                value={FORM_TYPE.NonGynec}
                    />{' '}
                    <span className="radioselect"></span>
                    <span className="text">{FORM_TYPE.NonGynec}</span>
                </Label>
            </div>
            <div className="urgent">
                <label className="ip-checkbox">Submit has Urgent?
                    <input  type="checkbox" 
                    onChange={(evt) => urgentDidChange(evt, rowId)} 
                    checked={data.urgent}
                    value={data.urgent}
                    disabled={isEditable} />
                    <span className="checkmark round"></span>
                </label>
            </div>
            <div>
                <Input className="ip-text" 
                type='text' value={data.note}
                name='note'
                placeholder='Type a note'
                onChange={(evt) => noteDidChnage(evt, rowId) }
                disabled={isEditable} />
            </div>
            <div className="cost">
            <div className="quickaction">
                <List className="action-menu">
                    { (showCreateButton && !isProcessed) && 
                    <li className="action-item">
                        <NavLink onClick={onCreate} >
                            <span className="icon">
                                <img className="icon" src={getIconUrl("create")} alt="" />
                            </span>
                        </NavLink>
                    </li>
                    }
                    
                    { showDeleteButton && 
                    <li className="action-item">
                        <Authority  allowed={ 'delete' } entity="requisitionline">
                            <NavLink onClick={onRemove} >
                                <span className="icon">
                                    <img className="icon" src={getIconUrl("trash")} alt=""/></span>
                            </NavLink>
                        </Authority>
                    </li>
                    }
                    
                </List>
            </div>
            <div>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                $
                <Input className="ip-text" type='number' 
                name='cost' 
                value={data.cost}
                min={0}
                placeholder={0.0}
                onChange={(evt) => costDidChange(evt, rowId)}
                disabled={isEditable}
                 />

                </div>
            </div>
                {isProcessed && 
                    <NavLink className="sr-link" onClick={ () => viewSpecimenRecord(data) } >
                        <img  className="icon" src={getIconUrl('link')} alt="" />
                        <span>{data.specimenRecordRef}</span>
                    </NavLink>
                }
            </div>
            { isProcessed &&
                <span className="completed-icon">
                    <img src={getIconUrl('tick')} alt="" />
                </span>
            }
        </div>
    );
}

class CreateRequisition extends React.Component {

    constructor(props) {
        super(props);

        this.props.apiRequestManager.register(actionTypes.FETCH_SPECIMEN_RECORD, this.forwordRecord);

        this.state = { 
            requisitionDataSet: false,
            deleteDialogVisible: false,
            deleleContextItem: null,
            contextName: '',
            itemsCount: !Object.blank(this.props.requisition)  ? this.props.requisition.items.length : 0,
            totalAmount:0,

            isPaneOpen:false,
            customize:false,
            reportIdentifier:'Report_RP11',

            reportData: [],
            formErrors: {},
            goToSpecimenForm: null,
            isFetchingRequisitions: false,
            apiRequestSucess: false
        };

        this.clientSelectRef = React.createRef();

        this.addNewItem = this.addNewItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.createSpecimenFromRequisition = this.createSpecimenFromRequisition.bind(this);
        this.costFieldChange = this.costFieldChange.bind(this);
        this.formTypeFieldChange = this.formTypeFieldChange.bind(this);
        this.onDateChanage = this.onDateChanage.bind(this);
        this.urgentFieldChange = this.urgentFieldChange.bind(this);
        this.notesFieldChange = this.notesFieldChange.bind(this);
        this.didSelectClientOption = this.didSelectClientOption.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.viewLinkSpecimenRecord = this.viewLinkSpecimenRecord.bind(this);
        this.handleAmount = this.handleAmount.bind(this);
        this.handleDeleteRequisition = this.handleDeleteRequisition.bind(this);
        this.submitDeleteRequisition = this.submitDeleteRequisition.bind(this);
        this.onConfirmDelete = this.onConfirmDelete.bind(this);
        this.showHideDeleteDialog = this.showHideDeleteDialog.bind(this);
        this.setContextName = this.setContextName.bind(this);
        this.onCountChange = this.onCountChange.bind(this);
        this.handleDeleteRequisitionItem = this.handleDeleteRequisitionItem.bind(this);
        this.submitDeleteRequisitionItem = this.submitDeleteRequisitionItem.bind(this);
        this.getPromptMessage = this.getPromptMessage.bind(this);
        this.getCompletedSpecimens = this.getCompletedSpecimens.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.showModalWithRequisition = this.showModalWithRequisition.bind(this);
        this.showReport = this.showReport.bind(this);
        this.closeReportingModal = this.closeReportingModal.bind(this);
       
    }

    forwordRecord = (recordId) => store.dispatch(actions.getSpecimenRecordAction(recordId));
  
    componentDidMount() {
        this.setFormDefaults();
        this.setState({goToSpecimenForm: false});
    }
    
    componentWillUnmount() {
        /**
         * If we are unmounting for any reason other than to go to the create specimen form.
         */
         if(this.state.goToSpecimenForm === false){
            this.props.setRequisitionItem(null);
        }
        this.props.apiRequestManager.abortRequest(actionTypes.FETCH_ACCOUNTS);
    }

    static getDerivedStateFromProps(props, state) {
        let newState = null;
        if (!props.useClientLayout && !props.clientsList && !props.apiRequestManager.inProgress(actionTypes.FETCH_ACCOUNTS)) {
            props.apiRequestManager.queueRequest(actionTypes.FETCH_ACCOUNTS)
        }
      
        if(props.requisition !== null && Array.isArray(props.requisition.items) && props.requisition.items.length !== state.itemsCount){
            newState = {
                itemsCount: props.requisition.items.length,
            }
        }
        
        if ( props.requisitionId ) {
            newState = {
                itemsCount: props.requisitionForm.items.length
            };
        }

        /**
         *  ---- BLOCK DETAILS ----
         * apiRequestSucess attribute will be use to reload requisition list if create is a success
         */
        const apiOutcome = props.apiRequestManager.getApiOutcome( actionTypes.REQUISITION_SUBMIT_DATA, false) ?? {};
        
        if ( apiOutcome?.requestType !== REQUESTS_TYPE.FETCH &&  apiOutcome?.success ) {
            newState = {
                ...newState,
                apiRequestSucess: apiOutcome.success
            };
        }
        /** ---- END BLOCK --- */

        return newState;
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.apiRequestManager.inProgress(actionTypes.FETCH_REQUISITION) !== this.state.isFetchingRequisitions){
            /**
             * this is to add a slight delay before turning the loader off(better ux), 
             * while making sure the loader is turned on a soon as possible.
             */
            if(this.props.apiRequestManager.inProgress(actionTypes.FETCH_REQUISITION)){
                //turn on.
                this.setState({
                    isFetchingRequisitions: this.props.apiRequestManager.inProgress(actionTypes.FETCH_REQUISITION)
                })
            }else{
                //turn off.
                setTimeout(() => {
                    this.setState({
                        isFetchingRequisitions: this.props.apiRequestManager.inProgress(actionTypes.FETCH_REQUISITION)
                    })
                }, 100);
            }
        }
    }

    shouldComponentUpdate(nextProps, state) {
        if ( nextProps.requisition !== null && !state.requisitionDataSet ) {
            this.setFormData(nextProps.requisition);
            this.setState( {
                requisitionDataSet: true
            });
        }

        return true;
    }

    setFormDefaults() {
        if (this.props.requisition === null) {
            this.props.requisitionForm.items.empty() && this.defaultRow();
            this.props.fieldDidChange({dateReceived: new Date()});
        }else {
            this.setFormData(this.props.requisition);
        }
    }

    setFormData(requisition) {
        //const pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        const dateParts = requisition.dateReceived?.split('/');
        const formData = {
            client: this.composeClientSelectOption(requisition.account),
            items: requisition?.items?.map((item, i) => {
                return {
                    ...item,
                    index: i
                }
            }),
            dateReceived: dateParts ? new Date(dateParts[2], dateParts[1] - 1, dateParts[0]) : null,
            totalAmount : requisition.totalAmount
        }
        this.props.fieldDidChange(formData);
    }

    defaultRow() {        
        this.addNewItem();
        this.setState({itemsCount: 1});
    }

    newItemLine() {
        const nextIndex = this.props.requisitionForm.items.length;

        const itemLine = {
            index: nextIndex,
            formType: FORM_TYPE.Gynec,
            note: '',
            urgent: false,
            cost: 0
        }
        return itemLine;
    }

    addNewItem() {
        this.setState({
            itemsCount: this.state.itemsCount + 1
        })
        const newItemLine = this.newItemLine();
        const newItems = [
            ...this.props.requisitionForm.items,
            newItemLine
        ];
        this.props.fieldDidChange({items: newItems});
    }

    removeItem(index) {
        //used to delete a single requisition item from server. Index here would be the reqItem.
        if(typeof index === 'object' && index){
            this.handleDeleteRequisitionItem(index)
        }else{
            this.setState({
                itemsCount: this.state.itemsCount ? this.state.itemsCount-1 : 0
            })
            var removedList = this.props.requisitionForm.items.filter((item) => {
                if (item.index !== index) {
                    return item;
                }
            });
            
            removedList = removedList.map((row, index) => {
                return  {
                    ...row,
                    index: index
                }   
            });
            this.props.fieldDidChange({items: removedList});
            this.calculateTotal (removedList);
        }

    }

    costFieldChange(evt, index) {
        const changedItems = this.props.requisitionForm.items.map((item) => {
            if (item.index === index) {
                item.cost = evt.target.value.empty() ? evt.target.value : parseInt(evt.target.value);
            }
            return item;
        });
        this.props.fieldDidChange({items: changedItems});
        this.calculateTotal(changedItems); 
    }

    formTypeFieldChange(evt, index) {
        const changedItems = this.props.requisitionForm.items.map((item) => {
            if (item.index === index) {
                item.formType = evt.target.value;
            }
            return item;
        });
        this.props.fieldDidChange({items: changedItems});
    }

    urgentFieldChange(evt, index) {
        const changedItems = this.props.requisitionForm.items.map((item) => {
            if (item.index === index) {
                item.urgent = evt.target.checked;
            }
            return item;
        });
        this.props.fieldDidChange({items: changedItems});
    }

    notesFieldChange(evt, index) {
        const changedItems = this.props.requisitionForm.items.map((item) => {
            if (item.index === index) {
                item.note = evt.target.value;
            }
            return item;
        });
        this.props.fieldDidChange({items: changedItems});
    }

    didSelectClientOption(data) {
        this.props.fieldDidChange({client: data});
        this.setState({formErrors: {client: false}});
    }

    composeClientSelectOption(account) {
        return {
            ...account,
            id: account?.id,
            label: `${account?.clientInfo.firstName} ${account?.clientInfo.lastName} - ${account?.clientInfo.email}`,
            value: account?.accountNo,
            firstName: account?.clientInfo.firstName,
            lastName: account?.clientInfo.lastName,
            email: account?.clientInfo.email
        }
    }

    calculateTotal (items) {
        const totalReducer = (accumulator, item) => accumulator + item.cost;
        const total = items.reduce(totalReducer, 0)
        this.props.fieldDidChange({totalAmount: total});
        this.setState({
            totalAmount:total
        })
    }

    submitForm() {
        const  { client, dateReceived, ...otherFields } = this.props.requisitionForm;
        const formPayload  = {
            requestType: REQUESTS_TYPE.CREATE,
            workspace: client ? client.workspace.id : null,
            ...otherFields,
            dateReceived: getDateInTimeZoneString({date:dateReceived})
        };
        
        const formErrors = this.validateFields(formPayload);
        
        if (Object.blank(formErrors) ) {
            if (formPayload.workspace && formPayload.items.length) {
                this.props.apiRequestManager.queueRequest(actionTypes.REQUISITION_SUBMIT_DATA, formPayload);
            }
            this.setState( {
                requisitionDataSet: false
            });
        }else {
            this.setState({
                formErrors: formErrors
            });
        }
    }

    createSpecimenFromRequisition(requisitionItem) {
        requisitionItem.client = this.props.requisition.account;

        const currentPage =  !Object.blank(this.props.requisitionsList) ? this.props.requisitionsList.currentPage : 0;

        this.props.setRequisitionItem(requisitionItem);
        this.props.setRequisitionPageNumber(currentPage);
        this.setState({goToSpecimenForm: true});

        /**
        * need to delay the change in url so the local state can be updated.
        * This is to determine whether or not to clear reqLinkItem.
        */
        setTimeout(() =>this.props.history.push(urls.specimensCreateUri) ,0);
    }

    viewLinkSpecimenRecord(requisitionItem) {
        var targetUri = urls.specimensUpdateUri;
        this.props.setFromView('formView');
        this.props.apiRequestManager.queueRequest(actionTypes.FETCH_SPECIMEN_RECORD, requisitionItem.specimenRecordId);


        const currentPage =  !Object.blank(this.props.requisitionsList) ? this.props.requisitionsList.currentPage : 0;

        requisitionItem.client = this.props.requisition.account;
        this.props.setRequisitionItem(requisitionItem);
        this.props.setRequisitionPageNumber(currentPage);
        this.setState({goToSpecimenForm: true});

        setTimeout(()=> store.dispatch(push(generatePath(targetUri, { id: requisitionItem.specimenRecordId }))),0)
    }

    submitDeleteRequisitionItem(requisitionItem){
        const formPayload = {
            requestType: REQUESTS_TYPE.DELETE,
            itemId: requisitionItem.id,
        }

        if(formPayload.itemId){
            this.props.apiRequestManager.queueRequest(actionTypes.REQUISITION_ITEM_SUBMIT_DATA, formPayload);
        }
    }

    submitDeleteRequisition(requisition) {
    
        const formPayload  = {
            requestType: REQUESTS_TYPE.DELETE,
            requisitionId: requisition.id
        };
        
        if (formPayload.requisitionId) {
            this.props.apiRequestManager.queueRequest(actionTypes.REQUISITION_SUBMIT_DATA, formPayload);
        }
    }

    handleDeleteRequisitionItem(item){
        const contextItem = {
            data: item,
            type: 'requisitionItem'
        }

        this.showHideDeleteDialog(contextItem);
        this.setContextName('Requisition Item');
    }

    handleDeleteRequisition() {
        const contextItem = {
            data: this.props.requisition
        }
        this.showHideDeleteDialog(contextItem);
        this.setContextName('Requisition');
    }

    onDateChanage(date) {
        this.props.fieldDidChange({dateReceived: date})
    }
    onConfirmDelete(contextItem) {

        if(contextItem.type && contextItem.type === 'requisitionItem'){
            //dipatch delete req item action
            this.submitDeleteRequisitionItem(contextItem.data);
            this.showHideDeleteDialog();
        }else{
            this.submitDeleteRequisition(contextItem.data);
            this.showHideDeleteDialog();
        }
       
    }

    setContextName(contextName){
        this.setState({contextName});
    }

    showHideDeleteDialog(contextItem) {
        this.setState({
            deleteDialogVisible: !this.state.deleteDialogVisible,
            deleleContextItem: contextItem || null
        });
    }

    onCountChange(evt) {
        let value =  parseInt(evt.target.value);
        const itemsListCount = this.props.requisitionForm.items.length;
        const newItems = this.props.requisitionForm.items;
        newItems.splice(0,newItems.length);
      
        if (itemsListCount !== value ) {
            for ( var i = 0; i <value ; i++ ) {
            newItems.push(this.newItemLine());

            }
            const changedItems = newItems.map((item) => {
                item.cost = evt.target.value.empty() ?  parseInt(this.props.requisitionForm.totalAmount / newItems.length): parseInt(this.props.requisitionForm.totalAmount / newItems.length);
                return item;
            });
        
            this.props.fieldDidChange({items:changedItems});
            if(value !== 0){
                this.calculateTotal(changedItems);
            }
            this.setState({itemsCount: value > 0 ? value : 0});
        }
    }

    handleAmount(evt,index){
       
        const totalAmount = evt.target.value.empty()? 0 : parseInt(evt.target.value);
        let length = this.props.requisitionForm.items.length;
        
        const changedItems = this.props.requisitionForm.items.map((item) => {
                item.cost = evt.target.value.empty() ? parseInt(0) : parseInt(totalAmount / length);
            return item;
        });
        this.props.fieldDidChange({items: changedItems, totalAmount: totalAmount});
    }
   
    getCompletedSpecimens() {
        this.props.apiRequestManager.queueRequest(actionTypes.FETCH_REQUISITION_SPECIMENS, this.props.requisition.id)
    }
    showModal() {
        this.setState({showCreateModal: true});
    }

    showReport = () => {
        const samples = [];
        this.setState({ isPaneOpen: true, customize: false, reportData: samples });
    }

    hideModal() {
        this.setState({showCreateModal: false});
        const doUnmount = this.state.apiRequestSucess;
        
        this.props.fieldDidChange({
            client: null,
            dateReceived: new Date(),
            items: [],
            totalAmount: 0
        });

        this.props.setRequisitionData(null);
        this.props.closeModal(doUnmount);
    }

    closeReportingModal(evt) {
        this.setState({ isPaneOpen: false, customize: false });
    }

    showModalWithRequisition(data) {
        this.props.setRequisitionData(data);
        this.showModal();
    }

    validateFields(formData) {
        var validations = {};

        if (this.props.validator.isValueMissing(formData.workspace)) {
            validations.client = true;
        }
    
        return  validations;  
    }

    getPromptMessage(){
        const apiOutcome = this.props.apiRequestManager.getApiOutcome( actionTypes.REQUISITION_SUBMIT_DATA || actionTypes.REQUISITION_ITEM_SUBMIT_DATA , true) ?? {};
        return getPromptMessageProps('requisition' || 'requisitionItemText', apiOutcome);
    }

    render() {
        
        const itemsList = this.props.requisitionForm.items;
        const clients = !Array.isArray(this.props.clientsList) ? [] : this.props.clientsList?.map((client) => {
            return this.composeClientSelectOption(client);
        });

        const requisitionData = this.props.requisition;
        const totalAmount = parseInt(this.props.requisitionForm.totalAmount);
        const initalSeletedClient = this.props.requisitionForm.client;
        
        const inProgress  = this.props.apiRequestManager.inProgress(actionTypes.REQUISITION_SUBMIT_DATA);

        const isEditable = requisitionData && requisitionData.id ? true : false;
        const showPrintButton = requisitionData && requisitionData.completedItems > 0 ? true : false;

        const promptProps = this.getPromptMessage();

        return <React.Fragment>
            <div className="rqt-modal-wrap">
                <div className="rqt-modal-content">
                    <Section className="head-section">
                        {promptProps.visible && 
                            <PromptMessage {...promptProps} />
                        } 
                    
                        <div className="title-box">
                            <div className="title-area">
                                <span className="icon">
                                    <img src={getIconUrl('bill')} alt="" />
                                </span>
                                <h1>Create Requisition {isEditable ? `#${requisitionData.id}`: ''}</h1>
                            </div>
                            <div className="slide-pane__close">
                            <Button className="close-btn"  
                            onClick={this.hideModal}>
                                <img src={getIconUrl('close')} alt=""/>
                            </Button>
                            </div>
                        </div>
                    </Section>

                    {/**Modal body*/}
                    {this.state.isFetchingRequisitions ? 
                    <div className="requisition__loader-container">
                        <Loader visible={this.state.isFetchingRequisitions} loadingText="Fetching Requisition..." /> 
                    </div> :
                    <div className="requisition__body-container">
                        <Section className="client-sec">
                            <div className="form-fields">
                                <Row>
                                    <Col sm={6}>
                                        <div className="billed-to">
                                            <FormGroup>
                                                <h3>Client</h3>

                                                <ValidateField required>
                                                    <ErrorFeedback 
                                                        error
                                                        show={this.state.formErrors.client}
                                                        filter='valueMissing'
                                                        message={<ErrorMessage message ='Please select a client' />} />
                                                <SimpleSelect
                                                id={'client'}
                                                ref={this.clientSelectRef}
                                                className="ip-sml-select"
                                                name="Client"
                                                title="Choose client"
                                                list={clients}
                                                onChange={this.didSelectClientOption}
                                                initialSelected={initalSeletedClient}
                                                searchable={["Type Name or Email", "No matching client"]}
                                                filterKeys={['label',
                                                            'firstName',
                                                            'lastName',
                                                            'email', 
                                                            'accountNo']}
                                                disabled={isEditable}
                                            />
                                            </ValidateField>
                                            </FormGroup>
                                        </div>
                                        <div className='date-receive'>
                                        <FormGroup >
                                            <Label>Date Received</Label>
                                            <div className="ip-date date-field"> 
                                            <DateTimePicker 
                                            
                                            className="date-picker"
                                            clearIcon={null}
                                            disableClock
                                            time={false}
                                            format={"dd-MM-yyyy"}
                                            onChange={this.onDateChanage}
                                            value={this.props.requisitionForm.dateReceived}
                                            disabled={isEditable} />
                                            </div>
                                            
                                        </FormGroup>
                                    </div>
                                    </Col>
                                    <Col sm={6}>
                                        <div className="items-count">
                                            <Input 
                                            className={'count'} 
                                            type="text" name="amount" value={this.state.itemsCount} onChange={this.onCountChange}
                                            disabled={isEditable} />
                                            <span className="txt">Items</span> 
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Section>

                        <Section className="items-sec">
                            <div className="list-item-wrap form-fields">
                                <FormGroup>
                                { !itemsList?.length  && 
                                    <ErrorMessage message ='Please add a requisition item.' />
                                }
                                <div className="lst-head">
                                    <h3>Items</h3>
                                    { showPrintButton && 
                                        <NavLink className="prtn-cmple-btn"
                                        onClick={this.showReport}>
                                            <span className="icon">
                                                <img src={getIconUrl('requisition')} alt="" />
                                            </span>Print Completed
                                        </NavLink>
                                    }
                                </div>
                                <List className="rqt-list">
                                    <li className="rqt-item lead head">
                                        <div className="rqt-entry">
                                            <div className="form-type">Form</div>
                                            <div>Is Urgent</div>
                                            <div>Notes</div>
                                            <div className="cost">Cost</div>
                                        </div>
                                    </li>
                                    { itemsList?.map((item, i) => (
                                        <li className="rqt-item" key={i}>
                                        <RequisitionLine
                                            data={item}
                                            removeHandler={this.removeItem}
                                            createHandler={this.createSpecimenFromRequisition}
                                            costDidChange={this.costFieldChange}
                                            noteDidChnage={this.notesFieldChange}
                                            formTypeDidChange={this.formTypeFieldChange}
                                            urgentDidChange={this.urgentFieldChange}
                                            viewSpecimenRecord={this.viewLinkSpecimenRecord}
                                        />
                                    </li>
                                    ))}
                                </List>
                                </FormGroup>
                            </div>
                            { !isEditable &&
                            <div>
                                <Authority allowed={'create'} entity="requisitionline" >
                                    <Button 
                                    onClick={this.addNewItem}
                                    disabled={isEditable} className="add-btn-item bill-line-btn">
                                        <span className="icon">
                                            <img src={getIconUrl('plusIcon')} alt="" />
                                        </span>
                                        New Item Line 
                                    </Button>
                                </Authority>
                            </div>
                            }
                        </Section>
                        
                        <Section className='total-section'>
                        <Row>
                            <Col md={8}>
                            </Col>
                            <Col md={4}>
                                <div className='total-box'>
                                <p className="total-line total"><label>Amount:</label>
                                <span className="value" style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                                $<input 
                                className="input-amount" 
                                type="text" 
                                value={totalAmount} 
                                onChange={this.handleAmount}
                                disabled={isEditable}
                                />
                                {/* ${totalAmount.toCurrency()} */}
                                </span>
                                </p>
                                </div>
                            </Col>
                        </Row>
                        </Section>
                        
                        <Section className="bottom-section">
                        { (!requisitionData) && ( this.state.itemsCount > 0) &&
                            <React.Fragment>
                                <Loader inline visible={inProgress} loadingText={''} />
                                <Authority  allowed={ 'create' } entity="requisition">
                                    <Button onClick={this.submitForm} className="save-btn">Save</Button>
                                </Authority>
                            </React.Fragment>
                            
                        }
                            { (requisitionData && requisitionData.status === STATUS.Pending) && 
                            <React.Fragment>
                                <Loader inline visible={inProgress} loadingText={''} />
                                <Authority  allowed={ 'delete' } entity="requisition">
                                    <Button onClick={this.handleDeleteRequisition} className="del-btn">Delete</Button>
                                </Authority>
                            </React.Fragment>
                            }
                        </Section>
                    </div>}
                </div>
            </div>
            { this.state.deleteDialogVisible && 
                <ConfirmModal 
                    contextName={this.state.contextName}
                    contextItem={this.state.deleleContextItem} 
                    cancelCallback={this.showHideDeleteDialog}
                    confirmCallback={this.onConfirmDelete}
                />
            }

            <SlidingPane
                closeIcon={<CloseButton />}
                isOpen={this.state.isPaneOpen}
                title={ "Completed Requisition Report"}
                from="bottom"
                width="100%"
                className="reporting-modal"
                onRequestClose={this.closeReportingModal}
            >
                <Reporting 
                    { ...this.props } 
                    customize={this.state.customize}
                    reportIdentifier={this.state.reportIdentifier } 
                    runContextAction={this.getCompletedSpecimens}
                    restBackground hideOptions />
            </SlidingPane>
            
        </React.Fragment>
    }
}

export default CreateRequisition;