import { requests, File} from './agentDialect';

import { httpRequests } from './httpRequestDialect';
import {unreadMessagesArraySchema, notificationsArraySchema} from './schemas';

export const fetchAllCodeSheets = () => {
    let uri = "/codesheets";
    return requests.get(uri);
};
export const fetchAllCodeFindings = () => {
    let uri = "/codefindings";
    return requests.get(uri);
};

export const submitCodeSheets = (payload) => {
    let uri = "/codesheets";
    return requests.post(uri, payload.data);
}
export const submitCodeFindings = (payload) => {
    let uri = "/codefindings";
    return requests.post(uri, payload.data);
}

export const deleteCodeSheet = (data) => {
    let uri = `/codesheets/delete/${data.codeSheetId}`;
    return requests.delete(uri, data);
};
export const deleteCodeFinding = (data) => {
    let uri = `/codefindings/delete/${data.codeFindingId}`;
    return requests.delete(uri, data);
};
//Labcode
export const fetchAllLabCodes = () => {
    let uri = "/labcodes";
    return requests.get(uri);
}
export const submitLabCode = (payload) => {
    let uri = "/labcodes";
    return requests.post(uri, payload.data);
}
export const deleteLabCode = (data) => {
    let uri = `/labcodes/delete/${data.labCodeId}`;
    return requests.delete(uri, data);
};
//Taxes
export const fetchAllTaxes = () => {
    let uri = "/taxes";
    return requests.get(uri);
}
export const fetchTaxesByType = (taxType) => {
    let uri = `/taxes/type?typeCode=${taxType}`;
    return requests.get(uri);
}
export const submitTaxes = (payload) => {
    let uri = "/taxes";
    return requests.post(uri, payload.data);
}
export const deleteTax = (data) => {
    let uri = `/taxes/delete/${data.taxId}`;
    return requests.delete(uri, data);
};
//Services
export const fetchAllServices = () => {
    let uri = "/services";
    return requests.get(uri);
}
export const submitServices = (payload) => {
    let uri = "/services";
    return requests.post(uri, payload.data);
}
export const deleteService = (data) => {
    let uri = `/services/delete/${data.serviceId}`;
    return requests.delete(uri, data);
};
//Files
export const submitFileUpload = (data) => {
    let uri = "/file/upload";
    return File.upload(uri, data);
};
export const fetchDownloadFile = (fileName, callback) => {
    let uri = `/file/download/${fileName}`;
    return File.download(uri, callback);
};
//Message and Notification
export const fetchUnreadMessages = (payload) => {
    let uri = `/messages/unread?user=${payload.authUser}`;
    return requests.get(uri);
};
export const fetchNotifications = (payload) => {
    let uri = `/notifications?workspace=${payload.workspaceId}`;
    return requests.get(uri);
};
export const dismissNotification = (data) => {
    let uri = `/notifications/dismiss`;
    return requests.delete(uri, data);
};
export const fetchDashboardData = (workspace) => {
    let uri = `/dashboard?workspace=${workspace}`;
    return requests.get(uri);
}
export const searchRequest = (payload) => {
    let uri = `/search?q=${payload.term}&context=${payload.context}&workspace=${payload.workspace}`;
    return requests.get(uri, payload);
}
//Reporting
export const runReportRequest = (payload) => {
    let uri = `/reports`;
    return requests.post(uri, payload);
}
export const fetchReportsSummary = (workspaceId) => {
    let uri = `/reports/summary?workspace=${workspaceId}`;
    return requests.get(uri);
}

// New Request api calls
export const getNotifications = (payload) => {
    let uri = `/notifications?workspace=${payload.workspaceId}`;
    return httpRequests.get(uri, notificationsArraySchema);
};

export const getUnreadMessages = (payload) => {
    let uri = `/messages/unread?user=${payload.authUser}`;
    return httpRequests.get(uri, unreadMessagesArraySchema);
};

export const updateWorkspaceName = (payload) => {
    const uri =`/workspaceName/update`;
    return requests.post(uri, payload);
}