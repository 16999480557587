import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { setupPage } from '../../components/Layout/setupPage';
import { 
    Container,
    Row,
    Col,
    Button,
    Section,
    TabPane,
    TabContent,
    Nav,
    NavItem,
    NavLink,
} from '../../components';

import * as actionTypes from '../../store/actionType';
import  * as actions  from '../../store/actions';
import  { PermissionEnforcer }  from '../../utility/PermissionEnforcer';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
import {EarningsPane, TaxesPane, ReviewPane ,CompletePane} from './';
import { TAX_TYPE } from '../../common/constant';
import './Payroll.style.scss';


const mapStateToProps = state => ({
    apiRequestManager: ApiRequestManager.getInstance(ApiRequestManager),
    useClientLayout: state.global.useClientDashboard,
    mode:  state.global.actionBarMode,
    currentUser: state.global.authorizeUser,
    ...state.payroll

});

const mapDispatchToProps = dispatch => ({
    onLoad: (clentType) => dispatch(actions.payrollCreateViewLoadedAction(clentType)),
    unLoad: () => dispatch(actions.payrollCreateViewUnloadAction()),
    getEmployees: (isEmployed) => dispatch(actions.getAllEmployeesAction(isEmployed)),
    getPayrollTaxes: (type) => dispatch(actions.getTaxByTypeAction(type)),
    payrollWageSalaryChange: (fieldWithValue) => dispatch(actions.payrollWageSalryFieldChangeAction(fieldWithValue)),
    payrollFieldDidChange : (fieldSet) => dispatch(actions.payrollFieldChangeAction(fieldSet)),
    payrollFormSetDefaults: (field) => dispatch(actions.payrollSetFormDefaultsAction(field)),
    submit: (payload) => dispatch(actions.submitPayrollAction(payload))
});

class PayrollWizard extends React.Component {

    static pageOption = { 
        pageTitle: 'Pay Slips',
        disableBodyScroll: true
    };

    classnames = {
        previous: '--previous',
        disabled: '--disabled',
        final: '--final'
    }

    tabs = {
        one: '1',
        two: '2',
        three: '3',
        four: '4'
    }

    constructor(props) {
        super(props);

        this.state = {
            viewInit: false,
            activeTab: '1',
            currentStep: 1,
            steps: [
                {step: 1, className: ''},
                {step: 2, className: ''},
                {step: 3, className: ''},
                {step: 4, className: ''}
            ]
        };

        props.apiRequestManager.register(actionTypes.FETCH_EMPLOYEES, props.getEmployees);
        props.apiRequestManager.register(actionTypes.FETCH_TAXES_BY_TYPE, props.getPayrollTaxes);
        props.apiRequestManager.register(actionTypes.PAYROLL_SUBMIT_DATA, props.submit);

        this.toggle = this.toggle.bind(this);
        this.onPreviousStep = this.onPreviousStep.bind(this);
        this.onNextStep = this.onNextStep.bind(this);
    }

    componentDidMount() {
        !this.props.viewInit && this.props.onLoad();
        const steps = this.props.payrollData ? this.initWizard(this.state.steps.length) : 
        this.initWizard(this.state.currentStep);
        const activeStep = this.props.payrollData ? this.state.steps.length : this.state.activeTab;

        this.setState({
            steps: steps, 
            activeTab: `${activeStep}`,
            viewInit: true
        });
    }

    componentWillUnmount() {
        this.state.viewInit && this.props.unLoad();
    }
    
    static getDerivedStateFromProps(props, state) {

        if ( !props.payrollData && !props.employees && !props.apiRequestManager.inProgress(actionTypes.FETCH_EMPLOYEES) ) {
           props.apiRequestManager.queueRequest(actionTypes.FETCH_EMPLOYEES, {terminated: false});
        }

        if ( !props.payrollData && !props.taxes && !props.apiRequestManager.inProgress(actionTypes.FETCH_TAXES_BY_TYPE) ) {
            props.apiRequestManager.queueRequest(actionTypes.FETCH_TAXES_BY_TYPE, TAX_TYPE.payroll);
        }

        return null;
    }

    toggle(tab) {
        
        if (this.state.activeTab !== tab && this.props.payrollData === null) {
          const steps = this.initWizard(parseInt(tab));
          this.setState({ activeTab: tab, 
            currentStep: parseInt(tab),
            steps: steps
            });
        }
    }

    initWizard(currentStep) {
        //[1] set previous state
        let steps = this.state.steps.map((item, i) => {
            let tempItem = item;
            if (item.step < currentStep) {
                tempItem = {
                    ...item,
                    className: this.classnames.previous
                }
            } else {
                tempItem.className = "";
            }
            if (parseInt(currentStep) === this.state.steps.length) {
                tempItem.className += ` ${this.classnames.final}`;
            }
            return tempItem;
        });

        //[2] set disabled state
        let c = currentStep - 1;
        let range = (steps.length - 1);

        for (let i = range; i > c; i--) {
            let tempItem = {
                ...steps[i],
                className: this.classnames.disabled
            }
            steps[i] = tempItem; 
        }
        return steps;
    }

    onNextStep() {
        let currentStep = this.state.currentStep;
        if (currentStep < this.state.steps.length) {
            currentStep += 1;
            const steps = this.initWizard(parseInt(currentStep));
            this.setState({ activeTab: `${currentStep}`, 
                currentStep: currentStep,
                steps: steps
            });
        }
    }
    onPreviousStep() {
        let currentStep = this.state.currentStep;
        if (currentStep > 1) {
            currentStep -= 1;
            this.toggle(`${currentStep}`);
        }
    }

    getSalaryByPayPeriod  (salary, payCycle)  {
        let weeksInYear = 52; //52.1429;
        
        switch(payCycle) {
            case 'Weekly':
                return salary / weeksInYear;
            case 'BiWeekly':
                return salary / (weeksInYear / 2);
            case 'Monthly':
                return salary / 12;
            default:
                return salary
        }
    }

    render () {
        
        const paneProps = {
            nextStep: this.onNextStep,
            fetchingEmployees:  this.props.apiRequestManager.inProgress(actionTypes.FETCH_EMPLOYEES),
            previousStep: this.onPreviousStep,
            getSalaryByPayPeriod: this.getSalaryByPayPeriod,
            BackButton: <Button onClick={this.onPreviousStep}>
                Back
            </Button>
        }

        const employeesData = this.props.employees ?? [];
        
        return (
            <React.Fragment>
            <div className='content-layout-wrap payroll-wizard'>
                <div className="view-layout-content">
                   <div className="default-Ly-ct">
                       <Container>
                            <Row>
                                <Col>
                                    <Section>
                                        <div className="title-box">
                                            <h1 className="title">Payroll Wizard</h1>
                                        </div>
                                        <div className="payroll-steps-nav-wrap">
                                            <Nav tabs className="nav-steps">
                                                <NavItem className={`steps ${
                                                        this.state.steps[0].className}`}>
                                                    <NavLink
                                                        className={classnames({ active: this.state.activeTab === '1' })}

                                                        onClick={() => { this.toggle('1'); }}
                                                    >
                                                        Earnings
                                                    </NavLink>
                                                    <span className="cut-out"></span>
                                                </NavItem>
                                            
                                                <NavItem className={`steps ${
                                                        this.state.steps[1].className}`}>
                                                    <NavLink
                                                        className={classnames({ active: this.state.activeTab === '2' })}
                                                        onClick={() => { this.toggle('2'); }}
                                                    >
                                                    Taxes
                                                    </NavLink>
                                                    <span className="cut-out"></span>
                                                </NavItem>
                                                <NavItem className={`steps ${
                                                        this.state.steps[2].className}`}>
                                                    <NavLink
                                                        className={classnames({ active: this.state.activeTab === '3' })}
                                                        onClick={() => { this.toggle('3'); }}
                                                    >
                                                    Review Payroll
                                                    </NavLink>
                                                    <span className="cut-out"></span>
                                                </NavItem>
                                                <NavItem className={`steps ${
                                                        this.state.steps[3].className}`}>
                                                    <NavLink
                                                        className={classnames({ active: this.state.activeTab === '4' })}
                                                        onClick={() => { this.toggle('4'); }}
                                                    >
                                                    Finish & Approved
                                                    </NavLink>
                                                    <span className="cut-out"></span>
                                                </NavItem>        
                                            </Nav>
                                        </div>
                                    </Section>
                                    <Section className="wizard-content">
                                    <TabContent activeTab={this.state.activeTab}>
                                        { this.state.activeTab === this.tabs.one &&
                                        <TabPane tabId="1">
                                            <EarningsPane 
                                            employeesData={employeesData}
                                            {...paneProps } 
                                            {...this.props} />
                                        </TabPane>
                                        }
                                         { this.state.activeTab === this.tabs.two  &&
                                        <TabPane tabId="2">
                                            <TaxesPane {...paneProps } {...this.props}/>
                                        </TabPane>
                                        }
                                         { this.state.activeTab === this.tabs.three &&
                                        <TabPane tabId="3">
                                            <ReviewPane {...paneProps } {...this.props}/>
                                        </TabPane>
                                         }
                                        { this.state.activeTab === this.tabs.four &&
                                        <TabPane tabId="4">
                                            <CompletePane {...paneProps } {...this.props} />
                                        </TabPane>}
                                    </TabContent>
                                    </Section>
                                </Col>
                            </Row>
                        </Container>
                   </div>
                </div>
            </div>
            </React.Fragment>
        );
    }
}

const PayrollWizardView = (props) => {
    return (
        <PayrollWizard {...props } />
    );
};

let ConfigPayrollWizardView = setupPage(PayrollWizard.pageOption)(PayrollWizardView);
ConfigPayrollWizardView =  connect(mapStateToProps, mapDispatchToProps)(ConfigPayrollWizardView);

export { ConfigPayrollWizardView };