import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { setupPage } from '../../components/Layout/setupPage';
import {
    Row,
    Col,
    List,
    Nav,
    Section,
    NavItem,
    TabContent,
    TabPane,
    NavLink,
    PatientTable,
    SpecimenTable,
    Authority,
    FormGroup,
    FormText,
    Input,
    Label
} from '../../components';
import {CLIENT_TYPE, FEATURE_FLAG, ACTION_BAR_MODES} from './../../common/constant';
import ActionBar from '../components/partials/ActionBar';
import  * as actions  from '../../store/actions';
import  * as actionTypes  from '../../store/actionType';
import * as urls from './../../config/urls';
import  { PermissionEnforcer }  from '../../utility/PermissionEnforcer';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
import {SEARCH_SETTINGS } from '../../config/searchable';
import { PAGE_SIZE } from '../../config/pagination';
import  FEATURES  from "../../config/featureFlags";
import {calculatePercent} from '../../utility/helpers';
import {Loader } from '../components/partials/LoadingIndicator';
import { getIconUrl } from '../../common/images-catalogue';
import './Client.style.scss';


const mapStateToProps = state => ({
    ...state.client,
    apiRequestManager: ApiRequestManager.getInstance(ApiRequestManager),
    client: state.client.currentClient,
    clientType: state.client.clientView,
});

const mapDispatchToProps = dispatch => ({
    onLoad: () => dispatch(actions.clientCreateViewLoadedAction()),
    unLoad: () => dispatch(actions.clientsViewUnloadAction()),
    redirectTo: (to) => dispatch(actions.redirectToAction(to)),
    setMode: (mode) => dispatch(actions.actionBarModeAction(mode)),
    getClientSpecimens: (payload) => dispatch(actions.getClientSpecimenRecordsAction(payload)),
    getClientPatients: (payload) => dispatch(actions.getClientPatientsListAction(payload)),
    getClient: (clientId) => dispatch(actions.getClientAction(clientId)),
    getAccount: (owner) => dispatch(actions.getAccountByOwnerAction(owner)),
    setClient: (client) => dispatch(actions.setClientAction(client)),
    setUpdateSpecimenData: (data) => dispatch(actions.setUpdateSpecimenDataAction(data)),
    getOverviewData: (workspace) => dispatch(actions.getClientOverviewDataAction(workspace)), 
});

class Overview extends React.Component {

    static pageOption = { 
        pageTitle: 'Overview',
        disableBodyScroll: true,
        searchContext: SEARCH_SETTINGS.entities.client.name
        //grayBackground: true
    };

    constructor(props) {
        super(props);

        props.apiRequestManager.register(actionTypes.FETCH_CLIENT_SPECIMENS_HISTORY, props.getClientSpecimens);

        props.apiRequestManager.register(actionTypes.FETCH_CLIENT_PATIENTS, props.getClientPatients);
        props.apiRequestManager.register(actionTypes.FETCH_CLIENT, props.getClient);
        props.apiRequestManager.register(actionTypes.FETCH_OWNER_ACCOUNT, props.getAccount);

        props.apiRequestManager.register(actionTypes.FETCH_CLIENT_OVERVIEW_DATA, props.getOverviewData);

        this.state = {
            activeTab: '1',
            pageSize: PAGE_SIZE
        };
        
        const idPresent = this.props.match.params.id;
        if (!this.props.client) {
            const uri = props.clientType === CLIENT_TYPE.Doctor ? urls.doctorsUri :  urls.labsUri;
            (!props.client && !idPresent) && props.redirectTo(uri);
        } 

        this.willHandleRecordRowMouseOver = this.willHandleRecordRowMouseOver.bind(this);
        this.willHandleClientRowMouseOver =  this.willHandleClientRowMouseOver.bind(this);
        this.backHandler = this.backHandler.bind(this);
        this.onEditHandler = this.onEditHandler.bind(this);
        this.onViewSpecimenHandler = this.onViewSpecimenHandler.bind(this);
        this.handlePatientsTableChange = this.handlePatientsTableChange.bind(this);
        this.handleRecordsTableChange = this.handleRecordsTableChange.bind(this);

        if (idPresent) {
            this.props.unLoad();
            props.apiRequestManager.queueRequest(actionTypes.FETCH_CLIENT, idPresent);
        }
    }

    componentDidMount() { 
        this.unlistenAbort = this.props.history.listen(() => { 
            this.props.apiRequestManager.abortRequest(actionTypes.FETCH_CLIENT);
        });
    }

    componentWillUnmount() {
        this.props.unLoad();
        this.unlistenAbort();
    }

    static getDerivedStateFromProps(props, state) {
        // Return null to indicate no change to state.
        if ( props.client && !props.patientsList  && !props.apiRequestManager.inProgress(actionTypes.FETCH_CLIENT_PATIENTS)) {
            const params = {
                clientId: props.client.clientInfo.id
            }
            props.apiRequestManager.queueRequest(actionTypes.FETCH_CLIENT_PATIENTS,params );
        }

        if ( props.client && !props.recordsList && !props.apiRequestManager.inProgress(actionTypes.FETCH_CLIENT_SPECIMENS_HISTORY) ) {
            const params = {
                clientId: props.client.clientInfo.id
            }
            props.apiRequestManager.queueRequest(actionTypes.FETCH_CLIENT_SPECIMENS_HISTORY, params);
        }

        if ( props.client && !props.accountDetails && !props.apiRequestManager.inProgress(actionTypes.FETCH_OWNER_ACCOUNT) ) {
            props.apiRequestManager.queueRequest(actionTypes.FETCH_OWNER_ACCOUNT, props.client.username);
        }
        
        if ( props.client && !props.insightsData && !props.apiRequestManager.inProgress(actionTypes.FETCH_CLIENT_OVERVIEW_DATA) ) {
            props.apiRequestManager.queueRequest(actionTypes.FETCH_CLIENT_OVERVIEW_DATA, props.client.clientInfo.workspaceId);
        }
        return null;
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({ activeTab: tab });
        }
    }

    willHandleRecordRowMouseOver(rowData) {
        //(this.props.rowSpecimenContext === undefined || !this.props.rowSpecimenContext) && this.props.rowDidMouseOver(rowData);
    }

    willHandleClientRowMouseOver(rowData) {
        //(this.props.rowSpecimenContext === undefined || !this.props.rowSpecimenContext) && this.props.rowDidMouseOver(rowData);
    }

    onEditHandler() {
        if (this.props.client) {
            this.props.setClient(this.props.client);
            this.props.history.push(urls.clientUpdateUri);
        }
    }

    backHandler() {
        this.props.clientType === CLIENT_TYPE.Doctor ?  this.props.history.push(urls.doctorsUri) : this.props.history.push(urls.labsUri);
    }

    onViewSpecimenHandler(evt) {
        const specimenRecordId = parseInt(evt.target.getAttribute('data-specimenid'));
        let specimenRecord =  this.props.recordsList.pageData.find((specimenRecord) => 
            specimenRecord.id === specimenRecordId);

        this.props.setMode(ACTION_BAR_MODES.edit);
        this.props.setUpdateSpecimenData({specimen: specimenRecord});
        this.props.history.push(urls.specimensUpdateUri);
    }

    getTableData(response) {
        let {pageData, ...metaData } = response || [];
        return {
            pageData,
            metaData : {
                ...metaData,
                remote: true,
                pageSize: this.state.pageSize
            }
        }
    }

    handlePatientsTableChange(tableOpt) {
        const pageNumber = !tableOpt.page || tableOpt.page - 1;
        const pageSize = tableOpt.sizePerPage;
   
        const requestParams = {
            clientId: this.props.client.clientInfo.id || null,
            page: pageNumber,
            pageSize: pageSize
        };

        this.props.apiRequestManager.queueRequest(actionTypes.FETCH_CLIENT_PATIENTS, requestParams);

        this.setState({
            pageSize: pageSize
        });
    }

    handleRecordsTableChange(tableOpt) {
        const pageNumber = !tableOpt.page || tableOpt.page - 1;
        const pageSize = tableOpt.sizePerPage;
        const requestParams = {
            clientId: this.props.client.clientInfo.id || null,
            page: pageNumber,
            pageSize: pageSize
        };

        this.props.apiRequestManager.queueRequest(actionTypes.FETCH_CLIENT_SPECIMENS_HISTORY, requestParams);

        this.setState({
            pageSize: pageSize
        });
    }

    render () { 
        
        const idPresent = this.props.match.params.id;

        const getAmount = (value) => {
            return value && value !== undefined ? value.toCurrency() : 0.0
        }

        if (!this.props.client && idPresent) {
            return <div className="client-loader"><Loader  visible={true} loadingText={'Please wait...'} /></div>;
        }

        const actionBarCallback = { 
            'edit': this.onEditHandler,
            'back': this.backHandler,
        };

        const  actionBarMode = 'view';
        const  saveSufixText = actionBarMode === 'edit'? '' : 'Client';

        const quickActionCallback = {
            'editAction' : this.onViewSpecimenHandler,
            'viewDetails' : () => {}
        }
        
        const recordsDataWithMeta = this.getTableData(this.props.recordsList);
        const patientsDataWithMeta = this.getTableData(this.props.patientsList);

        const recordsTableData = Array.isArray(recordsDataWithMeta?.pageData) ? recordsDataWithMeta.pageData : [];
        const patientsTableData = Array.isArray(patientsDataWithMeta?.pageData) ? patientsDataWithMeta.pageData : [];

        const client = this.props.client;
        const accountDetails = this.props.accountDetails;
        const pictureSrc = client.picture && client.picture !== undefined ? client.picture.fileDownloadUri : getIconUrl('profile');

        const insightsData = this.props.insightsData;

        const getValue = (amount) => amount > 0 ? amount : 0.0

        const billInsight = {
            totalPaid: !Object.blank(insightsData) ? getValue(insightsData?.billInsight?.totalPaid) : 0.0,
            totalUnpaid: !Object.blank(insightsData) ? getValue(insightsData?.billInsight?.totalUnPaid) : 0.0,

            totalAmount: !Object.blank(insightsData) ? getValue(insightsData?.billInsight?.totalAmount) : 0.0,

            paidRatio: !Object.blank(insightsData) ? calculatePercent(getValue(insightsData?.billInsight?.totalPaid), insightsData?.billInsight?.totalAmount) : 0.0,

            unPaidBillsCount: !Object.blank(insightsData) ? getValue(insightsData?.billInsight?.unPaidCount) : 0,

            paidBillsCount: !Object.blank(insightsData) ? getValue(insightsData?.billInsight?.partialPaidCount) +  getValue(insightsData?.billInsight?.fullPaidCount) : 0
        };

        const paymentsInsight = {
            totalPayments: !Object.blank(insightsData) && getValue(insightsData?.paymentsInsight?.totalPayments) ?  getValue(insightsData?.paymentsInsight?.totalPayments) : 0.0,

            totalUncollected: !Object.blank(insightsData) && insightsData?.paymentsInsight?.outStandingPayment ? getValue(insightsData?.paymentsInsight?.outStandingPayment) : 0.0,

            totalCollected: !Object.blank(insightsData) && insightsData?.paymentsInsight?.collectedPayments ? getValue(insightsData?.paymentsInsight?.collectedPayments) : 0.0,

            collectedRatio: !Object.blank(insightsData) ? calculatePercent(getValue(insightsData?.paymentsInsight?.collectedPayments), getValue(insightsData?.paymentsInsight?.totalPayments)) : 0.0,

            paymentsCount: !Object.blank(insightsData) ? insightsData?.recentPayments?.length : 0,
        };

        const countTally = {
            patientsCount: patientsDataWithMeta.metaData?.totalItems || 0,
            recordsCount: recordsDataWithMeta.metaData?.totalItems || 0,
            paymentsCount: 0,
            billsCount: 0
        }
        

        return (
            <React.Fragment>

            <ActionBar title={ "Client" } saveTextSufix={ saveSufixText } entityContext={'client'} actionResponsers={ actionBarCallback } permissionContext={ actionBarMode } />
            
            <div className='content-layout-wrap client-list'>
                <div className="view-layout-content">
                   <div className="default-Ly-ct">
                       <div className="overview-containter">
                           <Row>
                               <Col sm={12} lg={4} className="side-content">
                                   <div className="details-sec-container">
                                        <Section className="pat-details">
                                            <div className="patient-card">
                                                <div className="inner">
                                                    <div className="main-info">
                                                        <div className="picture">
                                                            <img src={pictureSrc} alt='' />
                                                        </div>
                                                        <h3 className="name">{client.firstName} {client.lastName}</h3>
                                                        <p className="info">{client.clientInfo.type}</p>
                                                    </div>
                                                    <div className="details">
                                                        <div className="sec personal">
                                                            <p className="info-line"><Label>Email</Label><span>{client.email}</span></p>
                                                            <p className="info-line"><Label>Username</Label><span>{client.username}</span></p>
                                                            <p className="info-line"><Label>Office</Label><span>{client.clientInfo.officeName}</span></p>
                                                            <p className="info-line"><Label>Phone Number</Label><span>{client.clientInfo.phoneNumber}</span></p>
                                                            <p className="info-line"><Label>Office Number</Label><span>{client.clientInfo.officeNumber.empty() ? '--' : client.clientInfo.officeNumber}</span></p>
                                                            <p className="info-line"><Label>Client Role</Label><span>{client.role.role}</span></p>
                                                        </div>
                                                        <div className="sec attributes">
                                                        <p className="info-line"><Label>Labcode</Label><span className="attr">{client.clientInfo.labCode.code}</span></p>
                                                        <p className="info-line"><Label>Active</Label><span className="attr">{client.active ? 'Yes': 'No'}</span></p>
                                                        <p className="info-line"><Label>Blocked</Label><span className="attr">{client.blocked ? 'Yes': 'No'}</span></p>
                                                        <p className="info-line"><Label>Two Factor Auth</Label><span className="attr">{client.twoFactorAuth ? 'Yes': 'No'}</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Section>
                                        { (FEATURES.ClientAddress === FEATURE_FLAG.ON) &&
                                        
                                        client.clientInfo.address && 
                                        <Section className="address">
                                            <div className="address-card">
                                                <Label>
                                                    Address
                                                </Label>
                                                <address >
                                                <p>Street: <span>{client.clientInfo.address.street}</span></p>
                                                <p>Address Line 2: <span>{client.clientInfo.address.streetTwo}</span></p>
                                                <p>City: <span>{client.clientInfo.address.city}</span></p>
                                                <p>Parish: <span>{client.clientInfo.address.state}, {client.clientInfo.address.postalCode}</span></p>
                                                <p>Country: <span>{client.clientInfo.address.country}</span></p>
                                                </address>
                                            </div>
                                            
                                        </Section>
                                        }
                                   </div>
                               </Col>
                               <Col sm={12} lg={8} className="main-content">
                                   <div className="tab-content-area">
                                       <div className="tab-nav">
                                        <Nav tabs>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: this.state.activeTab === '1' })}
                                                    onClick={() => { this.toggle('1'); }}
                                                >
                                                Overview
                                                </NavLink>
                                            </NavItem>
                                            <Authority allowed="view" entity="patient">
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: this.state.activeTab === '2' })}
                                                    onClick={() => { this.toggle('2'); }}
                                                >
                                                Patients
                                                </NavLink>
                                            </NavItem>
                                            </Authority>
                                            <Authority allowed="view" entity="record">
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: this.state.activeTab === '3' })}
                                                    onClick={() => { this.toggle('3'); }}
                                                >
                                                Records
                                                </NavLink>
                                            </NavItem>
                                            </Authority>
                                        </Nav>
                                        </div>
                                        <div className="content-pane">
                                        <TabContent activeTab={this.state.activeTab}>
                                            <TabPane tabId='1'>
                                                <Section className="bill-section">
                                                    <div className="account-balc">
                                                    <div className="ratio-widget balc">
                                                    <div className="ratio-holder">
                                                        <label>Bills Total ${billInsight.totalAmount.toCurrency()}</label>
                                                        <div className="ratio-count">
                                                            <span className="left-count">${billInsight.totalPaid.toCurrency()}</span>
                                                            <span className="right-count">${billInsight.totalUnpaid.toCurrency()}</span>
                                                        </div>
                                                        <div className="ratio-line orange">
                                                            <div className="line-bar">
                                                            <div className="inner-bar" 
                                                            style={{
                                                                width: `${billInsight.paidRatio}%`
                                                            }}></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    <div className="ratio-widget paymt">
                                                    <div className="ratio-holder">
                                                        <label>Payment Total ${paymentsInsight.totalPayments.toCurrency()}</label>
                                                        <div className="ratio-count">
                                                            <span className="left-count">${paymentsInsight.totalCollected.toCurrency()}</span>
                                                            <span className="right-count">${paymentsInsight.totalUncollected.toCurrency()}</span>
                                                        </div>
                                                        <div className="ratio-line">
                                                            <div className="line-bar">
                                                            <div className="inner-bar" 
                                                            style={{
                                                                width: `${paymentsInsight.collectedRatio}%`
                                                            }}></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    </div>
                                                </Section>
                                                <Section className="acnt-section">
                                                    <h2 className="">Account Info</h2>
                                                    <div className="sec-content">
                                                        <List className="detls-info-list">
                                                            <li className="info-line">
                                                               <span className="label">AccountNo</span>
                                                               <span className="value">{accountDetails?.accountNo}</span>
                                                            </li>
                                                            <li className="info-line">
                                                               <span className="label">Status</span>
                                                               <span className="value">{accountDetails?.status || '--'}</span>
                                                            </li>
                                                            <li className="info-line">
                                                               <span className="label">Active</span>
                                                               <span className="value">{accountDetails?.active ? 'ACTIVE' : 'BLOCK'}</span>
                                                            </li>
                                                            <li className="info-line">
                                                               <span className="label">Billing Cycle</span>
                                                               <span className="value">{accountDetails?.billCycle}</span>
                                                            </li>
                                                            <li className="info-line">
                                                               <span className="label">Balance</span>
                                                               <span className="value">${getAmount(accountDetails?.balance)}</span>
                                                            </li>
                                                            <li className="info-line">
                                                               <span className="label">Previous Balance</span>
                                                               <span className="value">${accountDetails?.previousBalance.toCurrency()}</span>
                                                            </li>
                                                        </List>
                                                    </div>
                                                </Section>
                                                <Section className="wkspc-section">
                                                    <h2 className="">Workspace Info</h2>
                                                    <div className="sec-content">
                                                        <List className="detls-info-list">
                                                            <li className="info-line">
                                                               <span className="label">Name</span>
                                                               <span className="value">{accountDetails?.workspace.name}</span>
                                                            </li>
                                                            <li className="info-line">
                                                               <span className="label">Identifier</span>
                                                               <span className="value"> {accountDetails?.workspace.identifier}</span>
                                                            </li>
                                                            <li className="info-line">
                                                               <span className="label">Workspace Role</span>
                                                               <span className="value">{accountDetails?.workspace.constraints.role}</span>
                                                            </li>
                                                        </List>
                                                    </div>
                                                </Section>
                                                <Section className="rec-section">
                                                    <div className="sec-content">
                                                    <List className="sumry-list">
                                                        <li className="sumry-item">
                                                            <span className="count">{countTally.patientsCount}</span>
                                                            <h4>Patients</h4>
                                                        </li>
                                                        <li className="sumry-item">
                                                            <span className="count">{countTally.recordsCount}</span>
                                                            <h4>Records</h4>
                                                        </li>
                                                        <li className="sumry-item">
                                                            <span className="count">{countTally.billsCount}</span>
                                                            <h4>Unpaid Bills</h4>
                                                        </li>
                                                        <li className="sumry-item">
                                                            <span className="count">{countTally.paymentsCount}</span>
                                                            <h4>Recent Payments</h4>
                                                        </li>
                                                    </List>
                                                    </div>
                                                </Section>
                                                <Section className="act-section">
                                                    <h2 className="">Account Controls</h2>
                                                    <div className="sec-content">
                                                        <FormGroup >
                                                            <div className="switch-group">
                                                                <Label className="inline">Account Active</Label>
                                                                <span className="switch-pill inline">
                                                                    <Input onChange={ () => {} } type="checkbox" id="active" checked={ accountDetails?.active }/>
                                                                    <Label for="active" id="active">Off</Label>
                                                                </span>
                                                            </div>
                                                            <FormText>This will block account </FormText>
                                                        </FormGroup>
                                                        <FormGroup >
                                                            <div className="switch-group">
                                                                <Label className="inline">Limit Account</Label>
                                                                <span className="switch-pill inline">
                                                                    <Input onChange={ () => {} } type="checkbox" id="active" checked={ accountDetails?.status }/>
                                                                    <Label for="active" id="active">Off</Label>
                                                                </span>
                                                            </div>
                                                            <FormText>This will limit account functionality.</FormText>
                                                        </FormGroup>
                                                    </div>
                                                </Section>
                                            </TabPane>

                                            <Authority allowed="view" entity="patient">
                                                <TabPane tabId='2'>
                                                    <Section>
                                                    <PatientTable id="patientTable" 
                                                    hoverOverRowAction={this.willHandleClientRowMouseOver}
                                                    quickActions={quickActionCallback} 
                                                    tableData={patientsTableData}
                                                    metaData={patientsDataWithMeta.metaData}
                                                    onTableChange={this.handlePatientsTableChange}
                                                    />
                                                    </Section>
                                                </TabPane>
                                            </Authority>

                                            <Authority allowed="view" entity="record">
                                                <TabPane tabId='3'>
                                                    <Section >
                                                    <div className="table">
                                                        <SpecimenTable 
                                                        hoverOverRowAction={this.willHandleRecordRowMouseOver}
                                                        quickActions={quickActionCallback} 
                                                        tableData={recordsTableData}
                                                        pagable={true}
                                                        metaData={recordsDataWithMeta.metaData}
                                                        onTableChange={this.handleRecordsTableChange}
                                                        />
                                                    </div>
                                                    </Section>
                                                </TabPane>
                                            </Authority>
                                            
                                        </TabContent>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                   </div>
                </div>
            </div>
            </React.Fragment>
        );
    }
}

const OverviewView= (props) => {
    return (
        <Overview {...props } />
    );
};

let ConfigOverviewView = setupPage(Overview.pageOption)(OverviewView);
ConfigOverviewView =  connect(mapStateToProps, mapDispatchToProps)(ConfigOverviewView);

export { ConfigOverviewView };