import React from 'react';
import { connect } from 'react-redux';
import { setupPage } from '../../components/Layout/setupPage';
import { 
    BillTable,
} from '../../components';

import * as actionTypes from '../../store/actionType';
import  * as actions  from '../../store/actions';
import { Authority } from '../../components';
import * as urls from '../../config/urls';
import { PAGE_SIZE } from '../../config/pagination';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
import './Billing.style.scss';

const mapStateToProps = state => ({
    apiRequestManager: ApiRequestManager.getInstance(ApiRequestManager),
    useClientLayout: state.global.useClientDashboard,
    mode:  state.global.actionBarMode,
    bill: state.billing.bill
});

const mapDispatchToProps = dispatch => ({
    onLoad: (clentType) => dispatch(actions.clientsViewLoadedAction(clentType)),
    unLoad: () => dispatch(actions.clientsViewUnloadAction()),
});

class UnpaidBills extends React.Component {

    static pageOption = { 
        pageTitle: 'Patients',
        disableBodyScroll: true,
    };

    constructor(props) {
        super(props);
        this.state = {
            pageSize: PAGE_SIZE,
            currentPage: 0
        };
    }

    componentDidMount() {}

    componentWillUnmount() {}

    static getDerivedStateFromProps(props, state) {
        if ( !props.useClientLayout && !props.unpaidBillsData && !props.apiRequestManager.inProgress(actionTypes.FETCH_UNPAID_BILLS) ) {
            const requestParams = {
                clientId: null,
                pageSize: state.pageSize,
                page: state.currentPage
            };

            props.apiRequestManager.queueRequest(actionTypes.FETCH_UNPAID_BILLS, requestParams);
        }

        if ( props.useClientLayout && props.account && !props.unpaidBillsData && !props.apiRequestManager.inProgress(actionTypes.FETCH_UNPAID_BILLS) ) {
            const requestParams = {
                clientId: props.account.owner,
                pageSize: state.pageSize,
                page: state.currentPage
            }
            props.apiRequestManager.queueRequest(actionTypes.FETCH_UNPAID_BILLS, requestParams);
        }
        return null;
    }

    handleTableChange (tableOpt) {
        const pageNumber = !tableOpt.page || tableOpt.page - 1;
        const pageSize = tableOpt.sizePerPage;

        const requestParams = {
            clientId: this.props.account.owner,
            page: pageNumber,
            pageSize: pageSize
        };

        this.props.apiRequestManager.queueRequest(actionTypes.FETCH_UNPAID_BILLS, requestParams);

        this.setState({
            currentPage: pageNumber,
            pageSize: pageSize
        });
    }

    render () {
        let {pageData, analytics, ...tableMetadata} = this.props.unpaidBillsData || {};
        const tableData = Array.isArray(pageData) ? pageData : [];

        tableMetadata = {
            ...tableMetadata,
            remote: true,
            pageSize: this.state.pageSize
        };

        return (
            <React.Fragment>                
                <div className="table-container billing-table-coontainer">
                    <BillTable 
                        //ref={this.patientsTable}
                        id="billTable" 
                        hoverOverRowAction={this.props.willHandleTableRowMouseOver}
                        quickActions={this.props.quickActionCallback} 
                        tableData={tableData}
                        metaData={tableMetadata}
                        onTableChange={this.handleTableChange.bind(this)}
                    />
                </div>

            </React.Fragment>
        );
    }
}

const UnpaidBillsView = (props) => {
    return (
        <UnpaidBills {...props } />
    );
};

let ConfigUnpaidBillsView = setupPage(UnpaidBills.pageOption)(UnpaidBillsView);
ConfigUnpaidBillsView =  connect(mapStateToProps, mapDispatchToProps)(ConfigUnpaidBillsView);

export { ConfigUnpaidBillsView };