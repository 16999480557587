import React from 'react';

import { NavLink, Button } from '../../components';

export default function DetailSection({
  sectionTitle,
  detailLines = [],
  renderDetailLine,
  editLabel = 'Edit',
  onClickEdit,
  onCancelEdit,
  isBeingEdited,
  identifier,
  disableEdit,
  delegateLineAction,
}) {

  if (typeof renderDetailLine !== 'function') {
    throw new Error(
      `renderDetailLine must be a function. Typeof ç${typeof renderDetailLine} was found instead.`
    );
  }

  const editSectionHandler = (event) => {
    event.preventDefault();
    onClickEdit(identifier);
  };

  const hasEditableLines = detailLines.find((line) => line.isEditable);

  return (
    <div className='detail-section'>
      {/* Title container */}
      <div className='detail-section__title-container'>
        <p className='detail-section__title'>{sectionTitle}</p>
      </div>
      { (!isBeingEdited && hasEditableLines)  && 
        <NavLink className='detail-edit' onClick={editSectionHandler} disabled={disableEdit}>{editLabel}</NavLink>
      }
      {/* Detail lines container */}
      <div className='detail-section__lines-container'>
        { detailLines.map((line, index) => (
          <React.Fragment key={`${index}_${line.lineLabel}`}>{renderDetailLine({
            currentlyEditing: isBeingEdited,
            ...line
          })}</React.Fragment>
        )) }

        {/* Show Cancel button if this line is being edited */}
        { !delegateLineAction && isBeingEdited && (
          <div className='detail-line__container'>
            <Button className='detail-line__cancel-edit' onClick={onCancelEdit}>
              Cancel
            </Button>
          </div>
        )}
      </div>
      
    </div>
  );
}
