import React from 'react';
import TemplateFooter from './TemplateFooter';

class Report_RP05 extends React.PureComponent  {
    
    render () {

        const {ReportHeader, ReportFooter, ReportNotes} = this.props;
        const reportData = Array.isArray(this.props.reportResults) ? this.props.reportResults : [];

        return <React.Fragment>
            <div  className="print-template">
                { ReportHeader }
                <div className="report-body">

                    <table className="default">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Registration#</th>
                                <th>DOB</th>
                                <th>Age</th>
                                <th>Gender</th>
                                <th>Doctors</th>
                                <th>Date Registered</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reportData.map((patient, i) => (
                                <tr key={i} className="smpl-row">
                                <td>{patient.firstName} {patient.lastName}</td>
                                <td>{patient.registrationNo}</td>
                                <td >{patient.dateOfBirth}</td>
                                <td >{patient.age}</td>
                                <td >{patient.gender}</td>
                                <td >
                                    {patient.clients.map((client, i) => (
                                        <div key={i} className="info-line">
                                            {client.firstName} {client.lastName}
                                        </div>
                                    ))}

                                </td>
                                <td >{patient.dateRegistered}</td>
                            </tr>
                            ))}
                            
                        </tbody>
                    </table>
                </div>
                { <TemplateFooter 
                    ReportFooter={ReportFooter}  
                    ReportNotes={ReportNotes}
                    /> 
                }
            </div>
           
        </React.Fragment> 
    }  
}

export default Report_RP05;