import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { setupPage } from '../../components/Layout/setupPage';
import { 
    Nav,
    NavItem,
    NavLink,
    Button,
    Authority,
    MessagesTable,
    ThemeConsumer,
} from '../../components';
import {Compose} from './';

import  { PermissionEnforcer }  from '../../utility/PermissionEnforcer';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
import SocketCommunicator from './../../utility/SocketCommunicator';

import { MESSAGE_CODES } from '../../common/constant';
import  * as actions  from '../../store/actions';
import * as actionTypes from '../../store/actionType';
import * as urls from '../../config/urls';

import './Message.style.scss';
import { getIconUrl } from '../../common/images-catalogue';
 
const mapStateToProps = state => ({
    apiRequestManager: ApiRequestManager.getInstance(ApiRequestManager),
    socketCommunicator: SocketCommunicator.getInstance(SocketCommunicator),
    currentUser: state.global.authorizeUser,
    ...state.message
});

const mapDispatchToProps = dispatch => ({
    onLoad: () => dispatch(actions.messagesViewLoadedAction()),
    unLoad: () => dispatch(actions.messagesViewUnloadAction()),
    getMessages: (payload) => dispatch(actions.getAllMessagesAction(payload)),
    setViewContext: (payload) => dispatch(actions.setMessageViewAction(payload)),
    setPreserveState: (yesOrNo) => dispatch(actions.messagesPreserveStateAction(yesOrNo))
});

class Message extends React.Component {

    static pageOption = {
        pageTitle: 'Client',
        disableBodyScroll: true
    };

    constructor(props) {
        super(props);

        this.state = {
            initialView: 'inbox',
            showCompose: false,
            minimize: false
        }

        props.apiRequestManager.register(actionTypes.FETCH_MESSAGES, props.getMessages);

        this.onShowCompose = this.onShowCompose.bind(this);
        this.onHideCompose = this.onHideCompose.bind(this);
        this.viewMessageThread = this.viewMessageThread.bind(this);
        this.onMinimize = this.onMinimize.bind(this);
        this.onSendMessage = this.onSendMessage.bind(this);
        this.changeView = this.changeView.bind(this);
    }

    componentDidMount() {
        this.props.setViewContext({
            activeView: this.props.activeView === undefined ? this.state.initialView : this.props.activeView,
            viewContext: 'list'
        });
        this.props.onLoad();
    }

    componentWillUnmount() {
        this.props.viewInit && this.props.unLoad();
    }

    static getDerivedStateFromProps(props, state) {
        // Return null to indicate no change to state.
        if (  Message.loadInboxMessages(props) || Message.loadSentMessages(props) ) {
            props.apiRequestManager.queueRequest(actionTypes.FETCH_MESSAGES, {
                user: props.currentUser.username,
                tag: props.activeView === 'inbox' ? 'Inbound' : 'Outbound'
            });
        }
        return null;
    }

    static loadInboxMessages(props) {
        return (props.activeView === 'inbox' && props.inboxMessages === null && !Object.blank(props.currentUser)  && !props.apiRequestManager.inProgress(actionTypes.FETCH_MESSAGES)
        )
    }

    static loadSentMessages(props) {
        return (props.activeView === 'sent' && props.sentMessages === null && !Object.blank(props.currentUser)  && !props.apiRequestManager.inProgress(actionTypes.FETCH_MESSAGES)
        )
    }

    onShowCompose(evt) {
        this.setState({ showCompose: true });
    }

    onHideCompose(evt) {
        this.setState({ showCompose: false, minimize: false });
    }

    onMinimize(evt) {
        this.setState({ minimize: !this.state.minimize });
    }

    viewMessageThread(evt, data) {
        this.props.setPreserveState(true);
        this.props.history.push('/messages/thread/' + data.messageThread );
    }

    onSendMessage(data, isReply) {
        data['sender'] = this.props.currentUser.username;
        data['type'] =  MESSAGE_CODES.NEW;
        this.props.socketCommunicator.sendMessage(data);
    }

    changeView(evt) {
        const view  = evt.target.getAttribute('to');
        this.props.setViewContext({
            activeView: view,
        });    
    }

    render () {

        const quickActionCallback = {
            'viewThreadAction' : this.viewMessageThread
        };

        const inboxMessagesData = !Object.blank(this.props.inboxMessages) ? this.props.inboxMessages : [];
        const sentMessagesData =  !Object.blank(this.props.sentMessages) ? this.props.sentMessages : [];

        const unreadCount = Array.isArray(this.props.unread) && this.props.unread.length > 0 ? `(${this.props.unread.length})`: '';
        const messagesData =  this.props.activeView === 'inbox'? inboxMessagesData : sentMessagesData;
        
        const showComposeClass = this.state.showCompose ? 'show' : '';
        const minimizeClass = this.state.minimize ? '--minimize' : '';

        const composeActions = {
            close: this.onHideCompose,
            minimize: this.onMinimize,
            sendMessage: this.onSendMessage
        }
        const activeView = this.props.activeView !== undefined ? this.props.activeView : this.state.initialView;
        
        return (
            <React.Fragment>
            <div className='content-layout-wrap specimen-list messages-list'>
               <div className="sidebar">
                    <div className="button-wrap">
                        <Authority  allowed="create" entity="record" >
                            <Button className="default-btn add-btn" onClick={this.onShowCompose}>
                                <span className="icon"><img src={getIconUrl("newMessage")} alt=''/></span>
                                Compose
                            </Button>
                        </Authority>
                    </div>
                    <div className="nav-container">
                        <nav className="sidebar-nav">
                            <Nav>
                                <NavItem>
                                    <NavLink to={"inbox"} onClick={this.changeView} className={classnames({ active: activeView === 'inbox' })}><span className="icon"><img src={getIconUrl("inbox")} alt='' /></span>Inbox {unreadCount}</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink to={"sent"} onClick={this.changeView} className={classnames({ active: activeView === 'sent' })}><span className="icon"><img src={getIconUrl("sent")} alt='' /></span>Sent</NavLink>
                                </NavItem>
                            </Nav> 
                
                        </nav>
                    </div>
               </div>
               <div className="v-line"></div>
               <div className="view-layout-content container">
                    <div className="spmt-Ly-ct-inner">
                        <div className="spmt-title-d">
                            <h3 className="spmt-title">Messages</h3>
                        </div>
                    </div>

                    <div className="spmt-Ly-ct">
                            <div className="spmt-v-lay-ct">
                                <div className="content-well">
                                    <MessagesTable
                                    tableData={messagesData}
                                    quickActions={quickActionCallback} />
                                </div>
                            </div>   
                        </div>
                    
                </div>
            </div>
            <div className="cmpe-lyr">
                    <div className="cL cmpe-oL">
                        <div className="cL cmpe-iL">
                            <div className={`cmpe-bL ${minimizeClass}`}>
                                <div className="cL fL cmpe-wL">
                                    <div className={`cL cmpe-oN ${showComposeClass}`}>
                                        <div className="cL fL cmpe-wL">
                                            <div className="cL cmpe-ED">
                                                <Compose 
                                                    actions= {
                                                        composeActions
                                                    }
                                                    label={'Send'}
                                                    inline={false}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const MessageView = (props) => {
    return (
        <Message {...props } />
    );
};

let ConfigMessageView = setupPage(Message.pageOption)(MessageView);
ConfigMessageView =  connect(mapStateToProps, mapDispatchToProps)(ConfigMessageView);

export { ConfigMessageView };