import * as actionTypes from '../actionType';
import {action} from './actionCreator';
import {createRequestTypes, REQUEST, SUCCESS, FAILURE} from './../../service/httpRequestDialect';

export const patientsViewLoadedAction =  (clientType) => ({
    type: actionTypes.PATIENTS_VIEW_LOADED,
    payload: clientType
}); 

export const patientsViewUnloadAction =  () => ({
    type: actionTypes.PATIENTS_VIEW_UNLOAD
}); 

export const patientsCreateViewLoadedAction =  (clientType) => ({
    type: actionTypes.PATIENTS_CREATE_VIEW_LOADED,
    payload: clientType
}); 

export const patientsCreateViewUnloadAction =  () => ({
    type: actionTypes.PATIENTS_CREATE_VIEW_UNLOAD
}); 

export const getClientPatientsAction =  (payload) => ({
    type: actionTypes.FETCH_PATIENTS,
    payload
}); 

export const getPatientAction = (clientId) => ({
    type: actionTypes.FETCH_PATIENT,
    payload: clientId
});


export const setSplitGenderAction =  (payload) => ({
    type: actionTypes.SET_SPLIT_PATIENTS,
    payload
}); 
export const setPatientAction = (patient) =>({
    type: actionTypes.SET_PATIENT_DATA,
    payload: patient
});

export const patientFieldChangeAction =  (field) => ({
    type: actionTypes.PATIENT_FIELD_CHANGED,
    payload: {
       ...field
    }
}); 

export const setUpdatePatientDataAction =  (fieldsData) => ({
    type: actionTypes.PATIENT_EDIT_SETDATA,
    payload: {
       ...fieldsData
    }
}); 

export const submitPatientAction = (data) => ({
    type: actionTypes.PATIENT_SUBMIT_DATA,
    payload: data
});

export const getClientPatientsListAction = (payload) => ({
    type: actionTypes.FETCH_CLIENT_PATIENTS,
    payload
});

export const searchPatientsAction = (payload) => ({
    type: actionTypes.FETCH_SEARCH_PATIENTS,
    payload: payload
});

export const FETCH_PATIENT = createRequestTypes('FETCH_PATIENT');

export const patient = {
    request: () => action(FETCH_PATIENT[REQUEST]),
    success: (payload) => action(FETCH_PATIENT[SUCCESS], {payload}),
    failure: (error) => action(FETCH_PATIENT[FAILURE], {error}),
};
