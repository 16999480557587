import * as actionTypes from '../actionType';

export const paymentViewLoadedAction =  () => ({
    type: actionTypes.PAYMENTS_VIEW_LOADED,
}); 

export const paymentViewUnloadAction =  () => ({
    type: actionTypes.PAYMENTS_VIEW_UNLOAD
}); 
export const paymentModelViewLoadedAction =  () => ({
    type: actionTypes.PAYMENT_MODAL_VIEW_LOADED,
}); 

export const paymentModelViewUnloadAction =  () => ({
    type: actionTypes.PAYMENT_MODAL_VIEW_UNLOAD
}); 

export const getAllPaymentsAction =  (payload) => ({
    type: actionTypes.FETCH_PAYMENTS,
    payload: payload
});

export const getPaymentsSummaryAction = (client) => ({
    type: actionTypes.FETCH_PAYMENTS_SUMMARY,
    payload: client
});

export const paymentFieldChangeAction =  (field) => ({
    type: actionTypes.PAYMENT_FIELD_CHANGED,
    payload: {
       ...field
    }
}); 
export const submitPaymentAction = (data) => ({
    type: actionTypes.PAYMENT_SUBMIT_DATA,
    payload: data
}); 

export const setAccountBalance = (amount) => ({
    type: actionTypes.SET_ACCOUNT_BALANCE,
    payload: amount
}); 
export const setPaymentDataAction = (payment) => ({
    type: actionTypes.SET_PAYMENT_DATA,
    payload: payment
});
