import React from 'react';

const Check = <svg width="15" height="12" viewBox="0 0 15 12" fill="none">
<path d="M5.6 11.3L0.5 6L2.5 4.1L5.6 7.4L13 0L15 2L5.6 11.3Z" fill="black"/>
</svg>;

const Option = (props) => {

    const { children, item, className, cx, getStyles, ...otherProps } = props;
    const selected = item.value === props.selectedItemValue ? '--selected' : '';
   
    return (<div className={className}>
        <div
        className={`template-option ${props.id} ${selected}`}
        style={props.listItem}
        key={item.value}
        onClick={() => props.selectHandler(item)}
      >
        { children } 
        {' '}
        {item.value === props.selectedItemValue && (
          <span className="selected-marker" style={props.styles.checkIcon}>
            {props.checkIcon || Check}
          </span>
        )}
    </div>
    </div>)

}

export default Option;