import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import * as actionTypes from '../../store/actionType';

import { tagPropType } from '../../utility/component'
import { PermissionEnforcer } from './../../utility/PermissionEnforcer';

const mapStateToProps = state => ({
    //<--- here you will get permissions for your user from Redux store
    userPermissions:  state.global.permissions !== undefined ? state.global.permissions : [] ,
    authUser: state.global.authorizeUser
});

const mapDispatchToProps = dispatch => ({
    onRedirect: () => dispatch({ type: actionTypes.REDIRECT }),
    toSafezone: (uri) => dispatch(push(uri))
});

const AlternateComponent = ( props ) => {
    const message = props.message !== undefined && props.message !== '' ? props.message : `Privileges to ${props.perm} a ${props.entity} is not granted.`;
    return (
        <div className="access-deny-view">
            <p className="text">{message}</p>
        </div>
    );
}

class  Authority extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            isPermitted: false,
        }
    }

    static getDerivedStateFromProps(props, state) { 
        const { rights, allowed, entity } = props;

        let enforcer = PermissionEnforcer.getInstance(PermissionEnforcer);
        enforcer.user = props.authUser;
        
        var isPermitted =  false;
       
        if (rights) {
            isPermitted = enforcer.hasRights(rights, entity);
            return {
                isPermitted: isPermitted
            };
        
        } else if (allowed) {
            isPermitted = enforcer.isPermitted(entity, allowed);
            return {
                isPermitted:isPermitted
            };
        }
    }

    static securityCheck(props) {}
    
    componentDidMount() {

        const { rights, allowed, entity } = this.props;

        let enforcer = PermissionEnforcer.getInstance(PermissionEnforcer);
        enforcer.user = this.props.authUser;
        
        var isPermitted =  false;
       
        if (rights) {
            isPermitted = enforcer.hasRights(rights, entity);
            this.setState({
                isPermitted: isPermitted
            });
            if (!isPermitted) {
                this.toSafezone(this.props.redirectTo);
            }
        
        } else if (allowed) {
            isPermitted = enforcer.isPermitted(entity, allowed);
            this.setState({
                isPermitted:isPermitted
            });

            if( !isPermitted && this.props.redirectTo ) { 
                this.toSafezone(this.props.redirectTo);
            }
        }
    }

    toSafezone(uri) {
        this.props.toSafezone(uri);
        this.props.onRedirect();
    }

    render () {
        const { children, ...otherProps } = this.props;
        const RestrictedComponent = children ? children : this.props.component;
        return ( this.state.isPermitted ) ? RestrictedComponent : this.props.alternateView ? <AlternateComponent entity={this.props.entity} perm={this.props.allowed} message={this.props.message}></AlternateComponent> : null;
    }
}

Authority.defaultProps = {
    allowed: ''
}
Authority.propTypes = {
    children: tagPropType,
    component: tagPropType,
    allowed: PropTypes.string.isRequired,
    rights: PropTypes.array,
    entity: PropTypes.string.isRequired,
    userPermissions: PropTypes.array.isRequired,
    redirectTo: PropTypes.string,
    alternateView: PropTypes.bool,
    message: PropTypes.string,
};

const AuthorityWithState = connect(mapStateToProps, mapDispatchToProps)(Authority);

const RouteGuard = (RestrictedComponent, options) => {
    return class extends React.Component {
      render() {
          // Wraps the input component in a container, without mutating it. Good!
        return <AuthorityWithState {...this.props} {...options} 
        component={ <RestrictedComponent {...this.props} /> } />;
      }
    }
  }
export { AuthorityWithState, RouteGuard };