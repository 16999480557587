import React from 'react';

export const ErrorToast = (props) => {
    return (
        <div className="error-toast">
            <span className="warn warning"></span>
            <span className="text">{props.message}</span>
        </div>
    );
}
    