import React from 'react';
import { store } from '../../../store/store';
import * as actionTypes from '../../../store/actionType';
import * as actions from '../../../store/actions';
import { push } from 'connected-react-router';
import { generatePath } from "react-router";

import {
    List,
    InputField
} from '../../../components';

import { withPageConfig } from '../../../components/Layout';
import {SEARCH_SETTINGS } from '../../../config/searchable';
import { getIconUrl } from '../../../common/images-catalogue';

const sampleIcon = <svg viewBox="0 0 39.22 41"><g id="_3sDedG.tif" data-name="3sDedG.tif"><path d="M26.47,39.32a12.66,12.66,0,0,1-1.79.31,5.34,5.34,0,0,1-5.14-2.9c-1.57-2.91-3-5.87-4.54-8.8L8.45,15.12c-.11-.23-.21-.35-.52-.3a1.72,1.72,0,0,1-1.85-.94Q4.64,11.14,3.25,8.37a1.89,1.89,0,0,1,.51-2.3L13.9.88a1.94,1.94,0,0,1,1.88.56,2.31,2.31,0,0,1,.37.55c.88,1.72,1.77,3.44,2.64,5.17a1.79,1.79,0,0,1-.32,2.31c-.19.2-.07.32,0,.47L22,16.75c2.48,4.84,4.92,9.69,7.44,14.5a5.71,5.71,0,0,1-1.72,7.38.54.54,0,0,0-.09.12ZM19.13,32.1,26,28.58l-6.3-12.32-6.89,3.52ZM12.49,10.64C14,9.87,15.5,9.09,17,8.33c.25-.12.31-.23.17-.5-.85-1.63-1.69-3.28-2.52-4.93-.14-.28-.28-.29-.54-.15q-4.48,2.31-9,4.6c-.26.13-.33.24-.18.53q1.27,2.43,2.5,4.89c.16.3.29.31.57.16C9.5,12.16,11,11.4,12.49,10.64Z"/><path d="M19,25.35c-.35.18-.65.34-.95.48a.87.87,0,1,1-.79-1.55l.94-.49-.47-.91a.89.89,0,0,1,.33-1.25.9.9,0,0,1,1.24.46l.46.91c.33-.17.63-.33.93-.47a.86.86,0,0,1,1.23.34.88.88,0,0,1-.44,1.21l-.93.47c.16.31.31.59.45.88a.9.9,0,0,1-.33,1.3.89.89,0,0,1-1.22-.5Z"/><path d="M7.3,9.77c-.21-.42-.43-.83-.63-1.25a.87.87,0,1,1,1.54-.8c.44.83.87,1.66,1.28,2.49a.85.85,0,0,1-.36,1.2A.87.87,0,0,1,7.92,11L7.3,9.77Z"/><path d="M14.81,5.9c.21.42.44.85.65,1.28a.86.86,0,0,1-.35,1.17A.84.84,0,0,1,13.92,8c-.45-.84-.89-1.7-1.31-2.56A.86.86,0,0,1,13,4.26a.88.88,0,0,1,1.17.39C14.39,5.06,14.59,5.48,14.81,5.9Z"/><path d="M10.27,8.22c-.2-.4-.42-.8-.62-1.21A.88.88,0,0,1,10,5.8a.87.87,0,0,1,1.21.42q.64,1.23,1.26,2.46a.87.87,0,1,1-1.56.78C10.69,9.05,10.49,8.64,10.27,8.22Z"/></g></svg>;

const PersonResultItem = (props) => {
    const {data, onSelect} = props;
    const primeInfoSufix = data.registrationNo || data.username || data.employeeNo || data.accountNo;
    const subInfo = data.email || `TRN: ${data.trn}`;
    
    return (
        <div className="entry" onClick={(evt) => onSelect(evt, data)}>
            <div className="icon-box">
                <div className="user-picture">
                    <div className="picture-holder">
                        <div className="picture-content">
                        {data.firstName[0]}{data.lastName[0]}
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-wrap">
                <div className="grp">
                    { data.companyName ? 
                        <span className="prime-info">{data.companyName}</span>
                    :
                        <React.Fragment>
                            <span className="prime-info">{data.firstName}</span>
                            <span className="prime-info">{data.lastName}</span>
                        </React.Fragment>
                    }
                    <span className="sec-info">{primeInfoSufix}</span>
                </div>
                <div className="sec-info nline">
                    <span className="sec-info">{subInfo}</span>
                    { data.clientType && 
                        <span className="sec-info">{data.clientType}</span>
                    }

                    { data.trn && 
                        <span className="sec-info">phone#: {data.phoneNumber}</span>
                    }
                </div>
            </div>
        </div>
    )
};

const RecordResultItem = (props) => {
    const {data, onSelect} = props;
    const prefix = data.formType;
    const statusClass = data.status ? `--${data.status.toLowerCase()}` : '';

    return (
        <div className="entry record" onClick={(evt) => onSelect(evt, data)}>
            <div className="icon-box">
                <div className="icon">
                    {sampleIcon}
                </div>
            </div>

            <div className="content-wrap">
                <div className="grp">
                    <span className="prime-info">{prefix} - {data.labNo ? data.labNo : data.identifier}</span>
                    <span className={`prime-info status-indicator ${statusClass}`}>{data.status}</span>
                </div>
                <div className="sec-info nline">
                    <span className="sec-info">C: {data.client}</span>
                    <span className="sec-info">P: {data.patient}</span>
                </div>
            </div>
        </div>
    )
};

const BillResultItem = (props) => {
    const {data, onSelect} = props;
    const prefix = data.referenceNo;
    const statusClass = `--${data.status.toLowerCase()}`;

    return (
        <div className="entry bill" onClick={(evt) => onSelect(evt, data)}>
            <div className="icon-box">
                <div className="icon">
                    <img src={getIconUrl('bill')} alt=''/>
                </div>
            </div>

            <div className="content-wrap">
                <div className="grp">
                    <span className="prime-info">Bill# {prefix}  </span>
                    <span className="prime-info"> ${ parseFloat(data.amount).toCurrency() }</span>
                    <span className={`sec-info status-indicator ${statusClass}`}>{data.status}</span>
                </div>
                <div className="sec-info nline">
                    <span className="sec-info">C: {data.client}</span>
                    <span className="sec-info">AC#: {data.account}</span>
                </div>
            </div>
        </div>
    )
};

const RequisitionResultItem = (props) => {
    const {data, onSelect} = props;
  
    return (
        <div className="entry record" onClick={(evt) => onSelect(evt, data)}>
            <div className="icon-box">
                <div className="icon">
                    <img src={getIconUrl('requisition')} alt='' />
                </div>
            </div>

            <div className="content-wrap">
                <div className="grp">
                    <span className="prime-info">{data.client}</span>
                    <span className={`prime-info status-indicator`}>{data.status}</span>
                </div>
                <div className="sec-info nline">
                    <span className="sec-info">ID: {data.id}</span>
                    <span className="sec-info">date: {data.dateCreated}</span>
                </div>
            </div>
        </div>
    )
};

class SearchBar extends React.Component {
    
    resultViews = { };

    forwordPatient = (patientId) => store.dispatch(actions.getPatientAction(patientId));
    forwordRecord = (recordId) => store.dispatch(actions.getSpecimenRecordAction(recordId));
    forwordUser = (userId) => store.dispatch(actions.getUserAction(userId));
    forwordClient = (clientId) => store.dispatch(actions.getClientAction(clientId));
    forwordEmployee = (employeeId) => store.dispatch(actions.getEmployeeAction(employeeId));
    forwordBill = (billId) => store.dispatch(actions.getBillAction(billId));
    forwordRequisition = (requisitionId) => store.dispatch(actions.getRequisitionAction(requisitionId));
    
    constructor(props) {
        super(props);
        props.apiRequestManager.register(actionTypes.CONTEXT_SEARCH, props.performSearch);
        props.apiRequestManager.register(actionTypes.FETCH_PATIENT, this.forwordPatient);
        props.apiRequestManager.register(actionTypes.FETCH_SPECIMEN_RECORD, this.forwordRecord);
        props.apiRequestManager.register(actionTypes.FETCH_USER, this.forwordUser);
        props.apiRequestManager.register(actionTypes.FETCH_CLIENT, this.forwordClient);
        props.apiRequestManager.register(actionTypes.FETCH_EMPLOYEE, this.forwordEmployee);
        props.apiRequestManager.register(actionTypes.FETCH_BILL, this.forwordBill);
        props.apiRequestManager.register(actionTypes.FETCH_REQUISITION, this.forwordRequisition);

        this.state = {
            showSearchResult: false,
            searchEntity: '',
            defaultContext: SEARCH_SETTINGS.defaultContext
        };

        this.didTypeKeyword = this.didTypeKeyword.bind(this);
        this.didSelectResult = this.didSelectResult.bind(this);
        this.onSearchBarBur = this.onSearchBarBur.bind(this);
        this.willEnterSearchBar = this.willEnterSearchBar.bind(this);
        this.search = this.search.bind(this);

        this.resultBoxRef = React.createRef();

        this.resultViews[SEARCH_SETTINGS.entities.patient.name] = PersonResultItem;
        this.resultViews[SEARCH_SETTINGS.entities.record.name] = RecordResultItem;
        this.resultViews[SEARCH_SETTINGS.entities.user.name] = PersonResultItem;
        this.resultViews[SEARCH_SETTINGS.entities.client.name] = PersonResultItem;
        this.resultViews[SEARCH_SETTINGS.entities.employee.name] = PersonResultItem;
        this.resultViews[SEARCH_SETTINGS.entities.bill.name] = BillResultItem;
        this.resultViews[SEARCH_SETTINGS.entities.requisition.name] = RequisitionResultItem;
    }

    componentDidMount () {
        document.body.addEventListener('click', this.onSearchBarBur);
    }

    componentWillUnmount () {
        document.body.removeEventListener('click', this.onSearchBarBur);
    }

    static getDerivedStateFromProps (props, state) {
        return {
            searchEntity: props.pageConfig.searchContext || state.defaultContext
        }
    }

    didTypeKeyword(evt) {
        const value = evt.target.value;
        const charCount = value.length;
        const show = charCount > 0 ?  true : false;
        this.setState({showSearchResult: show});
        this.props.searchFieldChanage({term: value});
        this.search(value.trim());
    }
    
    onSearchBarBur(evt) {
  
        if (this.resultBoxRef.current) {
            const isClickInside = evt.composedPath().includes(this.resultBoxRef.current);
            //this.resultBoxRef.current.contains(evt.target);
            if (!isClickInside) {
               this.setState({
                   showSearchResult: false
               });
            }
        }
        
    }

    willEnterSearchBar(evt) {
        const value = evt.target.value;
        if (value.length > 0) {
            this.search(value);
            this.setState({
                showSearchResult: true
            });
        }
    }

    didSelectResult(evt, data) {
        evt.stopPropagation();
	
        const context = this.state.searchEntity.toLowerCase();
        const targetUri = SEARCH_SETTINGS.entities[context].target !== null && SEARCH_SETTINGS.entities[context].target;
        
        switch (context) {
            case SEARCH_SETTINGS.entities.patient.name.toLowerCase():
                this.props.apiRequestManager.queueRequest(actionTypes.FETCH_PATIENT, data.id);
                store.dispatch(actions.setUpdateClientDataAction(null));
                break;
            case SEARCH_SETTINGS.entities.user.name.toLowerCase():
                this.props.apiRequestManager.queueRequest(actionTypes.FETCH_USER, data.id);
                break
            case SEARCH_SETTINGS.entities.client.name.toLowerCase():
                this.props.apiRequestManager.queueRequest(actionTypes.FETCH_CLIENT, data.id);
                break
            case SEARCH_SETTINGS.entities.employee.name.toLowerCase():
                this.props.apiRequestManager.queueRequest(actionTypes.FETCH_EMPLOYEE, data.id);
                break
            case SEARCH_SETTINGS.entities.bill.name.toLowerCase():
                this.props.apiRequestManager.queueRequest(actionTypes.FETCH_BILL, data.id);
                break
            case SEARCH_SETTINGS.entities.record.name.toLowerCase():
                store.dispatch(actions.specimenFormViewAction('formView'));
                this.props.apiRequestManager.queueRequest(actionTypes.FETCH_SPECIMEN_RECORD, data.id);
                break
            case SEARCH_SETTINGS.entities.requisition.name.toLowerCase():
                this.props.apiRequestManager.queueRequest(actionTypes.FETCH_REQUISITION, data.id);
                store.dispatch(actions.showRequisitionModalAction(true));
                break
            default:
        }

        this.setState({
            showSearchResult: null
          }, () => {
            this.props.searchFieldChanage({term: '', clear: true});
            targetUri && store.dispatch(push(generatePath(targetUri, { id: data.id })));
          });
    }

    search(searchTerm) {
        const payload = {
            term: searchTerm,
            context: this.state.searchEntity.toLowerCase(),
            workspace: this.props.workspace.id
        }
        this.props.apiRequestManager.showProgressIndicator = false;
        this.props.apiRequestManager.queueRequest(actionTypes.CONTEXT_SEARCH, payload);
    }

    render () {
        const inputId='ip-search';
        const expandClassname = this.state.showSearchResult ? '--expand' : '';

        const searchResults =  Array.isArray(this.props.searchResults) && !this.props.searchResults.empty() ? this.props.searchResults  : [];
        const fieldValue = this.props.searchTerm;

        const getListView = (item) => {
            const props = {
                data: item,
                onSelect: this.didSelectResult,
            }
            return React.createElement( this.resultViews[this.state.searchEntity], (props)); 
        }
       
        return (
            <React.Fragment>
            <div  ref={this.resultBoxRef} className={`search-main ${expandClassname}`}>
                <div className="search-box">
                    <span className="search-icon">
                        <img src={ getIconUrl('search') } className="icon" alt='' /> 
                    </span>   
                    <InputField value={fieldValue} autoComplete="off" onChange={this.didTypeKeyword} onFocus={this.willEnterSearchBar} type="text" id={ inputId } className="ip-text-field search-field" placeholder="Search" />
                </div>
                { this.state.showSearchResult &&
                    <div className="search-result-wrap">
                        <div className="search-results">
                        <span className="context-tag">{this.state.searchEntity}</span>
                            <div className="results-cont">
                                <List className="results-list">
                                     { searchResults.length === 0 ?
                                        <li className="no-result">
                                            No search results.
                                        </li>
                                     :
                                    searchResults.map((item, i) => {
                                        return (
                                            <li key={i} className="result-item">
                                                {getListView(item)}
                                                
                                            </li>
                                        );
                                    })}
                                </List>
                            </div>
                        </div>
                    </div>
                }
            </div>
            </React.Fragment>
        );
    }
};

export default withPageConfig(SearchBar);