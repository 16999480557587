import * as actionTypes from '../actionType';


export const getAllPayrollsAction = () => ({
    type: actionTypes.FETCH_PAYROLLS
}); 
export const getPayAdviceAction = (payload) => ({
    type: actionTypes.FETCH_PAYADVICES,
    payload: payload
}); 

export const payrollCreateViewLoadedAction =  () => ({
    type: actionTypes.PAYROLL_CREATE_VIEW_LOADED,
}); 

export const payrollCreateViewUnloadAction =  () => ({
    type: actionTypes.PAYROLL_CREATE_VIEW_UNLOAD
}); 

export const payrollViewLoadedAction =  () => ({
    type: actionTypes.PAYROLL_VIEW_LOADED,
}); 

export const payrollViewUnloadAction =  () => ({
    type: actionTypes.PAYROLL_VIEW_UNLOAD
}); 

export const payrollFieldChangeAction =  (fieldSet) => ({
    type: actionTypes.PAYROLL_FIELD_CHANGED,
    payload: fieldSet
}); 

export const payrollWageSalryFieldChangeAction =  (fieldSet) => ({
    type: actionTypes.PAYROLL_WAGE_SALARY_FIELD,
    payload: fieldSet
}); 

export const payrollSetFormDefaultsAction =  (fieldSet) => ({
    type: actionTypes.PAYROLL_SET_FORM_DEFAULTS,
    payload: fieldSet
}); 

export const submitPayrollAction = (data) => ({
    type: actionTypes.PAYROLL_SUBMIT_DATA,
    payload: data
});

export const setPayrollDataAction = (payroll) => ({
    type: actionTypes.SET_PAYROLL_DATA,
    payload: payroll
});