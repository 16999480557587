import React from 'react';
import { Children } from 'react';
import PropType from 'prop-types';

const ListItem = (props) => {
    const {children, data, index, selectedKey, clickHandler, ...otherProps} = props;
    const slectedClass = index === selectedKey ? '--selected' : '';
    return (<div className={`list-item ${slectedClass}`} onClick={() => clickHandler(data, index)}>
        {children}
    </div>)
}

ListItem.propTypes = {
    index: PropType.number
};

export default ListItem;