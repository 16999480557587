
const deleteItem = {
    key: 'delete',
    title: null,
    subtitle: null,
    callbackFunc: 'showHideConfimStatusDialog'
};

const viewItem = {
    key: 'view',
    title: null,
    subtitle: null,
    callbackFunc: 'updateUserAction'
};

const editItem = {
    key: 'edit',
    title: null,
    subtitle: null,
    callbackFunc: 'updateUserAction'
};

const blockItem = {
    key: 'blocked',
    title: ':block User',
    subtitle:  'You are about to :block :user',
    callbackFunc: 'showHideConfimStatusDialog'
};

const twoFactorAuthItem = {
    key: 'twoFactorAuth',
    title: null,
    subtitle: null,
    callbackFunc: 'showHideConfimStatusDialog'
};


const contextMenuList =  [
    viewItem,
    editItem,
    deleteItem,
    blockItem,
    twoFactorAuthItem
];
export default contextMenuList