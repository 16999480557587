import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { Comparator, dateFilter } from 'react-bootstrap-table2-filter'
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import PropType  from 'prop-types';

import { InputField, List , NavLink} from '../index';
import {pictureInitials} from './../../utility/helpers';
import { PaginationPanel, PaginationTotal } from './';
import { PAGE_SIZE } from '../../config/pagination';
import { getIconUrl } from '../../common/images-catalogue';

const generateRow = (data) => ({
    id: data.id,
    clientinfo: data,
    userinfo: data,
    labcode: data.clientInfo,
    details: data
});

const userNameInfo = (data) => {
    const name = `${data.clientInfo.firstName} ${data.clientInfo.lastName}`;
    const picture = data.picture ? data.picture.fileDownloadUri : pictureInitials(data.clientInfo.firstName, data.clientInfo.lastName);
    const hasPicture = data.picture ? true : false;

    return (
        <ContextMenuTrigger id="users_table_context_menu">
        <div className="user-info">
            <div className="picture-holder">
                { hasPicture ? 
                <div className="picture-content">
                    <img className="picture" src={picture} alt='' />
                </div>
                :
                <div className="picture-content">
                    { picture }
                </div>
                }
            </div>
            <div className="user-info-holder">
                <div className="user-info-content">
                    <h3>{name}</h3>
                    <p className="auth-info">{data.username}</p>
                </div>
            </div>
        </div>
        </ContextMenuTrigger>
    );
}

const clientInfo = (data) => {
    return (
        <ContextMenuTrigger id="users_table_context_menu">
        <div className="client-info">
            <div className="client-info-holder">
                <div className="client-info-content">
                    { !data.clientInfo.officeName ? null : <span>{data.clientInfo.officeName}</span> }
                    <p className="info">{data.clientInfo.accountNo}</p>
                </div>
            </div>
        </div>
        </ContextMenuTrigger>
    );
}

const labCode = (data) => {

    return (
        <ContextMenuTrigger id="users_table_context_menu">
        <div className="labcode">
            <div className="labcode-holder">
                <div className="labcode-content">
                    {data.labCode.code}
                </div>
            </div>
        </div>
        </ContextMenuTrigger>
    );
}

const clientDeatils = (actions) => {
    //return function with cell content
    return (data) => (
        <div className="user-details">
            <ContextMenuTrigger id="users_table_context_menu">
            <div className="toolbar">
                <List className="action-menu">
                    <li className="action-item">
                        <NavLink key={data.id} rowid={data.id} actiontag="view">
                            <span className="icon"><img className="icon" src={getIconUrl("edit")} alt='' /></span>
                        </NavLink>
                    </li>
                    <li className="action-item">
                        <NavLink>
                            <span className="icon"><img className="icon" src={getIconUrl("option")} alt='' /></span>
                        </NavLink>
                    </li>
                </List>
            </div>
            
            <div className="user-details-holder">
                <div className="user-details-content">
                    <ul className="snippet-set">
                        <li><span className="auth-snippet role"><img className="icon" src={getIconUrl("role")}alt='' /> <p>{data.role.role}</p>, </span></li>
                        <li><span className="auth-snippet email">{data.email}</span></li>
                    </ul>
                    
                </div>
            </div>
            </ContextMenuTrigger>
        </div>
    );
}

class ClientTable extends React.Component { 
    static propsTypes = {
        tableData: PropType.array.require,
        actionResponsers: PropType.object,
    }

    static defaultProps = {
        actionResponsers: {
            editButton: null,
        }
    }

    constructor(props) {
        super(props);
        
        this.state = {
            selected: []
        };
        
        this.willEnterRow = this.willEnterRow.bind(this);
        this.didContextClick = this.didContextClick.bind(this);
        this.didClickRow = this.didClickRow.bind(this);
    }

    createColumnDefinitions(quickActions) { 
        return [
            {
                dataField: 'userinfo',
                text: "User info",
                headerAttrs: {
                    hidden: false,
                    className: 'th-userinfo-cell',
                },
                classes: 'id-userinfo-cell td-cell',
                formatter: userNameInfo
            },
            {
                dataField: 'clientinfo',
                text: "Client Info",
                headerAttrs: {
                    hidden: false,
                    className: 'th-clientinfo-cell',
                },
                classes: 'id-clientinfo-cell td-cell',
                formatter: clientInfo
            },
            {
                dataField: 'labcode',
                text: "Labcode",
                headerAttrs: {
                    hidden: false,
                    className: 'th-labcode-cell',
                },
                classes: 'id-labcode-cell td-cell',
                formatter: labCode
            },
            {
                dataField: 'details',
                text: "Details",
                headerAttrs: {
                    hidden: false,
                    className: 'th-details-cell',
                },
                classes: 'id-details-cell td-cell',
                formatter: clientDeatils( quickActions )
            }
        ];
    }

    handleSelect() { }

    handleSelectAll() { }

    handleOnChange() {}

    willEnterRow (evt, row, rowIndex) {
    
    }

    didClickRow(evt, row, index) {
        const rowId = evt.target.getAttribute('rowid');
        const actionTag = evt.target.getAttribute('actiontag');
        if (rowId && actionTag && actionTag === 'view') {
            this.props.quickActions.viewDetails(row.details);
        }
    }  

    didContextClick (evt, row, rowIndex) {
        evt.preventDefault();
    }

    render () {
        const columnDefs = this.createColumnDefinitions(this.props.quickActions);
        const paginationDef = paginationFactory({
            paginationSize: 5,
            sizePerPage: PAGE_SIZE,
            showTotal: true,
            pageListRenderer: (props) => (
                <PaginationPanel { ...props } size="sm" className="ml-md-auto mt-2 mt-md-0" />
            ),
            paginationTotalRenderer: (from, to, size) => (
                <PaginationTotal { ...{ from, to, size } } />
            )
        
        });
        const selectRowConfig = {
            mode: 'checkbox',
            selected: this.state.selected,
            onSelect: this.handleSelect.bind(this),
            onSelectAll: this.handleSelectAll.bind(this),
            selectionRenderer: ({ mode, checked, disabled }) => (
                <InputField id="" onChange={this.handleOnChange} type={ mode } checked={ checked } disabled={ disabled } />
            ),
            selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
                <InputField id="" onChange={this.handleOnChange} type={ mode } checked={ checked } innerRef={el => el && (el.indeterminate = indeterminate)} />
            )
        };

        const rowEventsHandlers = {
            onContextMenu: this.didContextClick,
            onMouseEnter: this.willEnterRow,
            onClick: this.didClickRow
        };

        var options = {
            noDataMessage: () => 'No data found.'
        };

        const clientsData = this.props.tableData.map(client => generateRow(client));
        
        return (
            <ToolkitProvider
                keyField="id"
                data={ clientsData }
                columns={columnDefs} >
                {
                    props => (
                        <React.Fragment>
                            <BootstrapTable
                            classes="table-responsive clients-table"
                            pagination={ paginationDef }
                            filter={ filterFactory() }
                            selectRow={ selectRowConfig }
                            bordered={ false }
                            responsive
                            { ...props.baseProps }
                            rowEvents={ rowEventsHandlers }
                            loading={ true } 
                            noDataIndication={options.noDataMessage}
                        />
                        </React.Fragment>
                    )
                }
            </ToolkitProvider>
        );
    }
}

export { ClientTable }