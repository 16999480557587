import { requests } from './agentDialect';
import { objectToQueryString } from '../utility/helpers';

export const fetchAllBills = (payload) => {
    const {clientId, ...queryParams} = payload;
    queryParams['client'] = clientId;
    let uri = "/bills" ;
    uri = objectToQueryString(uri, queryParams);
    return requests.get(uri);
};
export const fetchAllBilledBills = (forClient) => {
    let uri = !forClient ? "/bills/billed" : `/bills/billed?client=${forClient}`;
    return requests.get(uri);
};
export const fetchAllPaidBills = (payload) => {
    const {clientId, ...queryParams} = payload;
    queryParams['client'] = clientId;
    let uri = "/bills/paid" ;
    uri = objectToQueryString(uri, queryParams);
    return requests.get(uri);
};
export const fetchAllUnpaidBills = (payload) => {
    const {clientId, ...queryParams} = payload;
    queryParams['client'] = clientId;
    let uri = "/bills/unpaid" ;
    uri = objectToQueryString(uri, queryParams);
    return requests.get(uri);
};
export const fetchBillsSummary = (client) => {
    let uri = !client || client === undefined ? "/bills/summary" : `/bills/summary?client=${client}`;
    return requests.get(uri);
}; 
export const submitBill = (data) => {
    let uri = "/bill/create";
    return requests.post(uri, data);
};
export const fetchBill = (billId) => {
    let uri = `/bill/${billId}`;
    return requests.get(uri);
};

export const fetchBillPayments = (billId) => {
    let uri = `/bill/payments/${billId}`;
    return requests.get(uri);
}

export const updateBill = (data) => {
    let uri = `/bill/update/${data.billId}`;
    return requests.put(uri, data);
};
export const billViewed = (data) => {
    let uri = `/bill/viewed/${data.billId}`;
    return requests.put(uri, data);
};

export const billClient = (data) => {
    let uri = `/bill/billed/${data.billId}`;
    return requests.put(uri, data);
};
export const deleteBill = (data) => {
    let uri = `/bill/delete/${data.bill}`;
    return requests.delete(uri, data);
};