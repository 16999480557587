import * as actionTypes from '../../actionType';
import { isError } from '../../../service/agent';

export const taxesState = {
    taxesSetting : {
        taxesData: null
    }
}
    
var error = null;
let empty = {}
let emptyArray = [];

const taxesReducer = (state, action ) => { 
    switch(action.type) {
        case actionTypes.SET_TAX_DATA:
            return {
                ...state,
                taxesSetting: {
                    ...state.taxesSetting,
                    taxesData: action.payload
                }
            }
        case actionTypes.FETCHED_TAXES_RESULTS:
            error = isError(action.payload);
            return {
                ...state,
                taxesSetting: {
                    ...state.taxesSetting,
                    taxesData: error ? emptyArray : action.payload.map((item, i) => {
                        return {
                            index: i,
                            ...item
                        }
                    })
                }
            }
        case actionTypes.TAX_SUBMIT_DATA:
            return {
                ...state,
                requestType: action.payload.requestType,
                isSaving: true
            }
        case actionTypes.TAX_SUBMIT_DATA_RESULT:
            error = isError(action.payload);
            if (state.requestType === 'delete') {
                const filteredTaxes =  error ? state.taxesSetting.taxesData  : state.taxesSetting.taxesData.filter((item) => item.id !== action.payload.deletedId);
                return {
                    ...state,
                    isSaving: false,
                    taxesSetting: {
                        ...state.taxesSetting,
                        taxesData: filteredTaxes
                    }
                }
            }else {
                return {
                    ...state,
                    isSaving: false,
                    taxesSetting: {
                        ...state.taxesSetting,
                        taxesData: error ? state.taxesSetting.taxesData : action.payload.map((item, i) => {
                            return {
                                index: i,
                                ...item
                            }
                        })
                    }
                }
            }

        default:
            return state;
    }
};

export default taxesReducer;
