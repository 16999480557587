import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import {
    SimpleSelect,
    InputField,
    List , NavLink
} from './../index'
import { getIconUrl } from '../../common/images-catalogue';

const numberFieldCell = (actions, fieldName) => {
    return (data) => ( <div>
        <InputField 
            className="clear-ip-field"
            id={`${fieldName}-${data.id}`} 
            type="number"
            min="0"
            value={data.value}
            disabled={data.disabled}
            onChange={ (evt) => actions.cellTextFiledChange(data.id, evt.target, fieldName ) } />
    </div>)
}

const itemSelectField = (actions) => {
    
    return (data) => {
        const composeOption = (record, index) => {
            return {
                value: record.id,
                label: `${index + 1} - ${record.formType} (${record.labNumber && !record.labNumber.empty() ? record.labNumber : record.identifier})`,
                urgent: record.urgent,
            }
        }

        const initialSelected = (data.value.item && 'identifier' in data.value.item) ? composeOption(data.value.item, data.id) : data.value.item;
      
        const itemsList = data.value.itemsList?.map((record, index) => {
            return composeOption(record, index);
        }) || [];
        
        return <div>
            <SimpleSelect
                id={'item'}
                className="ip-sml-select items"
                name="item"
                title="Choose item"
                list={itemsList}
                disabled={data.value.disabled}
                onChange={(option) => actions.didSelectOption(option, data.id, option.value) }
                initialSelected={initialSelected}
                searchable={["Type Identifier"]}
                filterKeys={['label']}
            />
        </div>}
}

const serviceSelectField = (actions) => {
    
    return (data) => {
        const composeOption = (service, index) => {
            return {
                value: service.id,
                label: `${service.name}`,
                price: service.price,
            }
        }
        const initialSelected = (data.value.service && 'name' in data.value.service) ? composeOption(data.value.service, data.id) : data.value.service;
      
        const itemsList = data.value.serviceList.map((record, index) => {
            return composeOption(record, index);
        });

        return <div>
            <SimpleSelect
                id={'item'}
                className="ip-sml-select items"
                name="item"
                title="Choose service"
                list={itemsList}
                disabled={data.value.disabled}
                onChange={(option) => actions.didSelectService(option, data.id, option.value) }
                initialSelected={initialSelected}
                searchable={["Service name"]}
                filterKeys={['label']}
            />
        </div>}
}


const amountHtml = (actions) => {
   
    return (data) => (<div>
            { !data.disabled && 
            <div className="toolbar">
                <List className="action-menu">
                    <li className="action-item">
                        <NavLink onClick={(evt) => actions.removeRowHandler(data.id, data.value.item) } key={data.id} >
                            <span className="icon"><img className="icon" src={getIconUrl("trash")} alt='' /></span>
                        </NavLink>
                    </li>
                </List>
            </div>
            }
            <div className="amount">
                ${data.value.amount.toCurrency()}
            </div>
        </div>)
}

class BillItemsTable extends React.Component { 

    constructor(props) {
        super(props);
        this.state = {}
    }

    createColumnDefinitions(actions) {
        const columns = [
            {
                dataField: 'id',
                text: '#',
                headerAttrs: {
                    className: 'th-number-cell',
                },
                classes: 'id-number-cell td-c',
            }, {
                dataField: 'item',
                text: 'Item',
                headerAttrs: {
                    className: 'th-item-cell',
                },
                classes: 'id-item-cell td-c',
                formatter: itemSelectField(actions)
            }, 
            {
                dataField: 'service',
                text: 'Service',
                headerAttrs: {
                    className: 'th-service-cell',
                },
                classes: 'id-service-cell td-c',
                formatter: serviceSelectField(actions, 'service')
            }
            , {
                dataField: 'unitprice',
                text: 'Unit Price',
                headerAttrs: {
                    className: 'th-unitprice-cell',
                },
                classes: 'id-unitprice-cell td-c',
                formatter: numberFieldCell(actions, 'unitPrice')
            }
            , {
                dataField: 'expressCost',
                text: 'Express Cost',
                headerAttrs: {
                    className: 'th-express-cell',
                },
                classes: 'id-express-cell td-c',
                formatter: numberFieldCell(actions, 'expressCost')
            }
            , {
                dataField: 'fee',
                text: 'Added Fee',
                headerAttrs: {
                    className: 'th-fee-cell',
                },
                classes: 'id-fee-cell td-c',
                formatter: numberFieldCell(actions, 'fee')
            }
            , {
                dataField: 'amount',
                text: 'Net Amount',
                headerAttrs: {
                    className: 'th-amount-cell',
                },
                classes: 'id-amount-cell td-c',
                formatter: amountHtml(actions)
            }
        ];
        return columns;
    }

    render() {

        const columnDefs = this.createColumnDefinitions(this.props.actionHandlers);
        const itemsList = this.props.billableItems;
        const servicesList = this.props.servicesList;
        const disabled = this.props.disabled;

        const rowData = (key, data, disabled) => ({
            id: key,
            value: data,
            disabled: disabled
        })
        var generateRow =  (data) => ({
            id: data.id + 1,
            item: rowData(data.id, { item: data.item, itemsList: itemsList, disabled: disabled }),
            unitprice: rowData(data.id, data.unitPrice, disabled),
            service: rowData(data.id, { item: data.item, service: data.service, serviceList: servicesList, disabled: disabled }),
            expressCost: rowData(data.id, data.expressCost, disabled),
            fee: rowData(data.id, data.fee, disabled),
            amount: rowData(data.id, { item: data.item, amount: data.amount }, disabled)
        });
        
        const tableData = this.props.tableItems.map((row) => generateRow(row));
        
        return <React.Fragment>
            <h6 className="mt-0">
            </h6>
            <BootstrapTable
                classes='table-responsive-sm bill-items-table'
                keyField='id'
                data={ tableData }
                columns={ columnDefs }
                bordered
            />
        </React.Fragment>
    }
}
export { BillItemsTable };