/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import ArrowDown from './assets/arrowDown.svg';
import ArrowUp from './assets/arrowUp.svg';


import Placeholder from './elements/Placeholder';
import Search from './elements/Search';
import NoMatch from './elements/NoMatch';
import Option from './elements/Option';
import SelectedOption from './elements/SelectedOption';
import Indicator from './elements/Indicator';

import './styles/global.sass';

const Check = <svg width="15" height="12" viewBox="0 0 15 12" fill="none">
<path d="M5.6 11.3L0.5 6L2.5 4.1L5.6 7.4L13 0L15 2L5.6 11.3Z" fill="black"/>
</svg>;

class SimpleSelectMultiple extends Component {
  
  constructor(props) {
    super(props);

    const { title, list } = this.props;

    this.state = {
      isListOpen: false,
      title,
      keyword: '',
      selectedItems: [],
      initialSelected: false,
      list,
    };

    this.searchField = React.createRef();
  
  }

  componentDidMount() {
    
  }

  shouldComponentUpdate(props, state) {
    
    return true;
  }

  componentDidUpdate(_, prevState) {
    const { isListOpen } = this.state;

    setTimeout(() => {
      if (isListOpen) {
        window.addEventListener('click', this.close);
      } else {
        window.removeEventListener('click', this.close);
      }
    }, 0);


    const { select } = this.props;

    if (this.props.list.length && Array.isArray(select) && this.state.selectedItems.length === 0 && !this.state.initialSelected ) {
        this.setState(() => ({
          initialSelected: true,
        }), () => { 
          this.selectMultipleItems(select)
        });
    }else{

      const propSelects = select ? select.map(option => option.value) : [];
      const stateSelects = prevState.selectedItems ? prevState.selectedItems.map(option => option.value): [];

      //if for some reason the prop selects are different from state selects, prioritize prop selects.
      if(propSelects.length !== stateSelects.length) {
        this.selectMultipleItems(select)
      }else{
        propSelects.sort();
        stateSelects.sort();
        for(let i = 0; i < propSelects.length; i++){
          if(propSelects[i] !== stateSelects[i]){
            this.selectMultipleItems(select)
            break;
          }
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.close);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { list } = nextProps;
    if (JSON.stringify(list).length !== JSON.stringify(prevState.list)) {
      return { list };
    }

    return null;
  }

  close = () => {
    this.setState({
      isListOpen: false,
    });
  }

  selectAll = () => {
    const { name, onChange } = this.props;

    this.setState((prevState) => ({
      selectedItems: prevState.list,
    }), () => {
      this.handleTitle();
      onChange(this.state.selectedItems, name);
    });
  }

  deselectAll = () => {
    const { name, onChange } = this.props;

    this.setState({
      selectedItems: [],
    }, () => {
      this.handleTitle();
      onChange(this.state.selectedItems, name);
    });
  }

  selectMultipleItems = (items) => {
    const { list } = this.props;

    list.length && items.forEach((item) => {
      let selectedItem = list.find((i) => i.value === item.value);
      setTimeout(() => {
        this.selectItem(selectedItem, true);
      });
    });
  }

  selectItem = (item, noCloseOnSelection = false) => {
    const { closeOnSelection } = this.props;

    this.setState({
      isListOpen: (!noCloseOnSelection && !closeOnSelection) || false,
    }, () => this.handleSelection(item, this.state.selectedItems));
    
  }

  handleSelection = (item, selectedItems) => {    
    const { name, onChange } = this.props;
    const index = selectedItems.findIndex((i) => i.value === item.value);
   
    if (index !== -1) {
      const selectedItemsCopy = [...selectedItems];
      selectedItemsCopy.splice(index, 1);
      this.setState(() => ({
        selectedItems: selectedItemsCopy,
      }), () => {
        onChange(this.state.selectedItems, name);
        this.handleTitle();
      });
    } else {

      this.setState((prevState) => ({
        selectedItems: [...prevState.selectedItems, item],
      }), () => {
        onChange(this.state.selectedItems, name);
        this.handleTitle();
      });
      
    }
  }

  handleTitle = () => {
    const { selectedItems } = this.state;
    const { title, titleSingular, titlePlural, displayField } = this.props;

    const { length } = selectedItems;

    if (!length) {
      this.setState({
        title,
      });
    } else if (length === 1) {
      
      this.setState({
        title: this.props.displayField ? this.state.selectedItems[0][this.props.displayField] : `${length} ${titleSingular}`,
      });
    } else if (titlePlural) {

      var titleList = '';
      this.state.selectedItems.map((item, i) => {
          if ((this.state.selectedItems.length - 1) === i) {
              titleList += ` ${item[displayField]}`
          }else {
              titleList += `${item[displayField]}, `
          }
      })
      this.setState({
        title:  this.props.displayField ? titleList : `${length} ${titlePlural}`
      });
    } else {
      var  titleList = '';
      this.state.selectedItems.map((item, i) => {
        if ((this.state.selectedItems.length - 1) === i) {
            titleList += ` ${item[displayField]}`
        }else {
            titleList += `${item[displayField]}, `
        }
      })

      const pluralizedTitle = pluralize(titleSingular, length);
      this.setState({
        title: this.props.displayField ? titleList : `${length} ${pluralizedTitle}`,
      });
    }
  }

  toggleList = () => {
    this.setState((prevState) => ({
      isListOpen: !prevState.isListOpen,
    }), () => {
      if (this.state.isListOpen && this.searchField.current) {
        this.searchField.current.focus();
        this.setState({
          keyword: '',
        });
      }
    });
  }

  filterList = (e) => {
    this.setState({
      keyword: e.target.value.toLowerCase(),
    });
  }

  listItems = () => {
    const {
      id,
      searchable,
      styles,
    } = this.props;
    const { listItem, listItemNoResult } = styles;
    const { keyword, list, selectedItems } = this.state;
   
    let tempList = [...list];

    if (keyword.length) {
      tempList = list.filter((item) => item.label.toLowerCase().includes(keyword.toLowerCase()));
    }

    if (Array.isArray(tempList)) {
      return (
        tempList.map((item, i) => (
          !selectedItems.some((i) => i.value === item.value) ? 
          <button
            type="button"
            className={`default-option ${item.id}`}
            style={listItem}
            key={item.value}
            onClick={() => this.selectItem(item)}
          >
            {item.label}
            {' '}          
          </button>
          : 
          <button
            type="button"
            className={`default-option ${item.id} --select`}
            style={listItem}
            key={item.value}
            onClick={() => this.selectItem(item)}
          >
            {item.label}
            {' '}
              <span className="selected-marker" style={styles.checkIcon}>
              { Check}
              </span>
            
          </button>
        ))
      );
    }

    return (
      <div
        className={`default-option no-result ${id}`}
        style={listItemNoResult}
      >
        {searchable[1]}
      </div>
    );
  }

  render() {
    const {
      id,
      searchable,
      styles,
    } = this.props;
    const { isListOpen, title } = this.state;

    const {
      wrapper,
      header,
      headerTitle,
      list,
      listSearchBar,
      indicatorIcon,
      scrollList,
    } = styles;

    return (
      <div
        className={`select-container --multi ${id}`}
        style={wrapper}
        name={id}
      >
        <button
          type="button"
          className={`select-control ${id}`}
          style={header}
          onClick={this.toggleList}
        >
          <div
            className={`select-control --multi ${id}`}
            style={headerTitle}
          >
            {title}
          </div>

          <Indicator className={indicatorIcon} inFocus={isListOpen}/>
          
        </button>
        {isListOpen && (
          <div
            role="list"
            type="button"
            className={`options--default ${searchable ? ' searchable' : ''} ${id}`}
            style={list}
            onClick={(e) => e.stopPropagation()}
          >
            {searchable
            && (
              <div className="search-component">
                <input
                  ref={this.searchField}
                  className={`search-filter-field ${id}`}
                  style={listSearchBar}
                  placeholder={searchable[0]}
                  onChange={(e) => this.filterList(e)}
                />
              </div> 
              )}
            <div
              className={`select_options--default ${id}`}
              style={scrollList}
            >
              {this.listItems()}
            </div>
          </div>
        )}
      </div>
    );
  }
}

SimpleSelectMultiple.defaultProps = {
  id: '',
  select: [],
  closeOnSelection: false,
  titlePlural: undefined,
  searchable: undefined,
  styles: {},
  arrowUpIcon: null,
  arrowDownIcon: null,
  checkIcon: null,
  displayField: null
};

SimpleSelectMultiple.propTypes = {
  id: PropTypes.string,
  displayField: PropTypes.string,
  styles: PropTypes.shape({
    wrapper: PropTypes.string,
    header: PropTypes.string,
    headerTitle: PropTypes.string,
    headerArrowUpIcon: PropTypes.string,
    headerArrowDownIcon: PropTypes.string,
    checkIcon: PropTypes.string,
    list: PropTypes.string,
    listSearchBar: PropTypes.string,
    scrollList: PropTypes.string,
    listItem: PropTypes.string,
    listItemNoResult: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
  titleSingular: PropTypes.string.isRequired,
  titlePlural: PropTypes.string,
  list: PropTypes.shape([{ value: PropTypes.string, label: PropTypes.string }]).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  closeOnSelection: PropTypes.bool,
  searchable: PropTypes.shape([PropTypes.string, PropTypes.string]),
  select: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
  })),
  checkIcon: PropTypes.elementType,
  arrowUpIcon: PropTypes.elementType,
  arrowDownIcon: PropTypes.elementType,
};

export default SimpleSelectMultiple;
