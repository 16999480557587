import Option from './Option';
import SelectedOption from './SelectedOption';

import Indicator from './Indicator';
import Placeholder from './Placeholder';

export const elements = {
    Option: Option,
    SelectedOption: SelectedOption,
    Indicator: Indicator,
    Placeholder: Placeholder
}