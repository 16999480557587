import React from 'react';
import { setupPage } from '../../../../components/Layout/setupPage';
import {
    List, 
    Input,
    FormGroup,
    Label,
    FormText,
    Section,
} from '../../../../components';

import { getIconUrl } from '../../../../common/images-catalogue';
const Company = (props) => {

    return (
        <div className="pane company-pane">
            <Section className="grp-sec">
                <div className="title-wrap">
                    <h4 className="sec-title">Grop name</h4>
                    <a className="edit-tgr">
                        <img src={getIconUrl('edit') } alt="" />
                    </a>
                </div>
                <div className="section-cont">
                    <div className="field-set">
                        <div className="label-w">
                            <Label>Filed name</Label>
                        </div>
                        <div className="field-w">
                            <div className="switch-group">
                                <Label className="inline"></Label>
                                <span className="switch-pill inline">
                                    <Input onChange={ () => console.log('test') } type="checkbox" id="active" />
                                    <Label for="active" id="active">Off</Label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="field-set">
                        <div className="label-w">
                            <Label>Filed name</Label>
                        </div>
                        <div className="field-w">
                            <div className="switch-group">
                                <Label className="inline"></Label>
                                <span className="switch-pill inline">
                                    <Input onChange={ () => console.log('test') } type="checkbox" id="active" />
                                    <Label for="active" id="active">Off</Label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="field-set">
                        <div className="label-w">
                            <Label>Filed name</Label>
                        </div>
                        <div className="field-w">
                            <Input type="text"/>
                        </div>
                    </div>
                    <div className="field-set">
                        <div className="label-w">
                            <Label>Filed name</Label>
                        </div>
                        <div className="field-w">
                            <Input type="text"/>
                        </div>
                    </div>
                </div>
            </Section>
            <Section className="grp-sec">
                <div className="title-wrap">
                    <h4 className="sec-title">Grop name</h4>
                    <a className="edit-tgr">
                        <img src={getIconUrl('edit') } alt="" />
                    </a>
                </div>
                <div className="section-cont">
                    <div className="field-set">
                        <div className="label-w">
                            <Label>Filed name</Label>
                        </div>
                        <div className="field-w">
                            <Input type="text"/>
                        </div>
                    </div>
                    <div className="field-set">
                        <div className="label-w">
                            <Label>Filed name</Label>
                        </div>
                        <div className="field-w">
                            <Input type="text"/>
                        </div>
                    </div>
                    <div className="field-set">
                        <div className="label-w">
                            <Label>Filed name</Label>
                        </div>
                        <div className="field-w">
                            <Input type="text"/>
                        </div>
                    </div>
                </div>
            </Section>
        </div>
    );
}

Company.defaultProps = {
    disableBodyScroll: true
};

const  CompanyPane = setupPage(Company.defaultProps)(Company);
export default CompanyPane;