import React from 'react';
import TemplateFooter from './TemplateFooter';

class Report_RP10 extends React.PureComponent  {
    
    
    render () {

        const {ReportHeader, ReportFooter, ReportNotes} = this.props;
        const reportData = Array.isArray(this.props.reportResults) ? this.props.reportResults : [];

        return <React.Fragment>
            <div  className="print-template">
                { ReportHeader }
                <div className="report-body">

                    <table className="default">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Employee#</th>
                                <th>State</th>
                                <th>Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reportData.map((employee, i) => (
                                <tr key={i} className="smpl-row row-divider">
                                <td>
                                    <span className="l">{employee.firstName} {employee.lastName}</span>
                                    { employee.user && 
                                    <span className="l">user: {employee.user || '--'}</span>
                                    }
                                </td>
                                <td>{employee.employeeNo}</td>
                                <td>
                                <div className="info-line">Hired: {employee.dateHired}</div>
                                
                                <div className="info-line">Status: {!employee.terminted ? 'Employeed' : 'Terminated'}</div>
                                <div className="info-line">Dept: {employee.department}</div>

                                {
                                    employee.terminted &&
                                    <div className="info-line">Fired: {employee.dateTerminated || '--'}</div>
                                }
                                
                                </td>
                                <td>
                                    <div className="info-line">TRN: {employee.details.trn}</div>
                                    <div className="info-line">NIS: {employee.details.nis}</div>
                                    <div className="info-line">Pay: {employee.details.payCycle}</div>
                                    <div className="info-line">Wage: {employee.details.isFixedSalary ? employee.details.salary.toCurrency() : employee.details.hourlyRate.toCurrency()}</div>
                                </td>
                            </tr>

                            ))}
                            
                           
                        </tbody>
                    </table>
                </div>
                { <TemplateFooter 
                    ReportFooter={ReportFooter}  
                    ReportNotes={ReportNotes}
                    /> 
                }
            </div>
           
        </React.Fragment> 
    }  
}

export default Report_RP10;