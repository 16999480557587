import React from 'react';
import * as actionTypes from '../../store/actionType';
import { 
    Button,
    Section,
    Authority
} from '../../components';
import {Loader } from '../components/partials/LoadingIndicator';
import { getIconUrl } from '../../common/images-catalogue';
import { TAX_CODE, REQUESTS_TYPE } from '../../common/constant';

import './Payroll.style.scss';

class CompletePane extends React.Component {

    static pageOption = { 
        pageTitle: 'Pay Slips',
        disableBodyScroll: true
    };

    constructor(props) {
        super(props);
        this.state = {
            showTaxes: false,
            showPayAdvices: false
         };

         this.showPaySummary = this.showPaySummary.bind(this);
         this.showTaxesSummary = this.showTaxesSummary.bind(this);
         this.approvePayroll = this.approvePayroll.bind(this);
         this.handleShowPayslips = this.handleShowPayslips.bind(this);
    }

    componentDidMount() {}

    componentWillUnmount() {
        this.props.unLoad();
    }

    static getDerivedStateFromProps(props, state) {
        return null;
    }

    showPaySummary(evt) {
        this.setState({showPayAdvices: !this.state.showPayAdvices});
    }

    showTaxesSummary(evt) {
        this.setState({showTaxes: !this.state.showTaxes});
    }

    approvePayroll(evt) {
      
        const formPayload = {
            requestType: REQUESTS_TYPE.UPDATE,
            approvalRequest: true,
            user: this.props.currentUser.id,
            payrollId: this.props.payrollData.id
        }

        this.props.apiRequestManager.queueRequest(actionTypes.PAYROLL_SUBMIT_DATA, formPayload);
    }

    handleShowPayslips() {
        window.open(`/payroll/payslip/${this.props.payrollData.id}`, "_blank");
    }

    render () {
        
        const payrollObj = this.props.payrollData;

        const payAdvicesData = payrollObj.payAdvices.map((item) => {
            const employee = payrollObj.payees.filter((emp) => item.employee === emp.id);
            return {
                ...item,
                employee: employee[0]
            }
        }) || []

        const processing = this.props.processing;
        const taxesData = payrollObj.taxSummary ? this.props.payrollData.taxSummary : [];

        let taxesTotal = 0.0;
        taxesData.map((item) => taxesTotal += item.taxTotal);

        return (
            <React.Fragment>
            <div className="review-pane approve">
                <div className="title-info sucess">
                    <h2>Your payroll has been completed!</h2>
                    <p>Here's a quick summary to review, To pay staff press the submit payroll button.</p>
                </div>
                <div className="payroll-summary">
                    <div className="summary-box">
                        <div className="payout-details">
                            <h3>Payout cash</h3>
                            <h1><span className="amount">${payrollObj.totals.grossPayoutAmount.toCurrency()}</span></h1>
                            <p><span className="count">{payrollObj.payees.length}</span> employees to be paid this pay period</p>
                        </div>
                        <div className="date-details">
                            <h3>Payroll Date</h3>
                            <h1>{payrollObj.payrollDate}</h1>
                            <p>Date payroll generated</p>
                        </div>
                    </div>
                </div>
                <Section className="payroll-meta-data">
                    <div className="action-links">
                       
                        <div className="pay-advice-summary">
                            <a href onClick={this.showPaySummary} className="arrow-link"><span className="arrow-indicator"></span>View Pay Advice Summary</a>

                            <div className="summary-details">
                                { this.state.showPayAdvices && 
                                <table className="smry-table">
                                    <thead>
                                        <tr>
                                            <th>
                                                Employee
                                            </th>
                                            <th>
                                                GrossPay
                                            </th>
                                            <th>
                                                Deductions
                                            </th>
                                            <th>
                                                NetPay
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { payAdvicesData.map((payAdvice, i) => {
                                        return <tr key={i}>
                                            <td>
                                                {payAdvice.employee.firstName} {payAdvice.employee.lastName}
                                            </td>
                                            <td>
                                                ${payAdvice.grossPay.toCurrency()}
                                            </td>
                                            <td>
                                                ${(payAdvice.grossPay - payAdvice.netPay).toCurrency()}
                                            </td>
                                            <td>
                                                ${payAdvice.netPay.toCurrency()}
                                            </td>
                                        </tr>
                                        })}
                                    </tbody>
                                </table>
                                }

                            </div>
                        </div>

                        <a href onClick={this.showTaxesSummary} className="arrow-link"><span className="arrow-indicator"></span>Taxes Collected</a>

                        <div className="taxes-summary">
                            <div className="summary-details">
                            { this.state.showTaxes && 
                                <table className="smry-table">
                                    <thead>
                                        <tr>
                                            <th>
                                                Tax Code
                                            </th>
                                            <th>
                                                Percent
                                            </th>
                                            <th>
                                                Amount
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { taxesData.map((tax, i) => {
                                        return <tr key={i}>
                                            <td>
                                                {tax.taxCode}
                                            </td>
                                            <td>
                                            {tax.taxCode === TAX_CODE.PAYE ? '-' : `${tax.percent.toFixed(1)}%`}
                                            </td>
                                            <td>
                                                ${tax.taxTotal.toCurrency()}
                                            </td>
                                        </tr>
                                        })} 

                                        <tr className="totals-row">
                                            <td>
                                                Totals
                                            </td>
                                            <td>
                                            </td>
                                            <td>
                                                ${taxesTotal.toCurrency()}
                                            </td>
                                        </tr>                                      
                                    </tbody>
                                </table>
                                }
                            </div>
                        </div>
                        
                            { this.props.payrollData.isApproved &&
                                <Authority allowed="view" entity="payadvice">
                                <a href onClick={this.handleShowPayslips} className="arrow-link"><span className="arrow-indicator"></span>View Pay Advice Slips</a>
                                </Authority>
                            }    
                        
                    </div>
                    
                    <div className="approve-info">
                        { this.props.payrollData.isApproved &&
                        <div className="">
                            <label>Appoved by</label>
                            <h2>{this.props.payrollData.approver}</h2>
                            <p>On {this.props.payrollData.approvedDate}</p>
                            
                            <div className="apprv-hldr">
                            <div className="approved"><h3>Approved</h3><img src={getIconUrl('approvedGreen')} alt=''/></div>
                            </div>
                        </div>
                        
                        
                        }
                        
                    </div>
                </Section>
            </div>
            <Section className="actions-section">
               
                { !processing && !this.props.payrollData.isApproved? 
                    <div className="btns-wrap">
                        <Button onClick={this.approvePayroll} className="submit-btn">
                    Approve</Button>
                    </div>
                    :
                    <div className="loader-modal">
                        <Loader visible={processing} loadingText={'Please wait...'} />
                    </div>
                }

               
             </Section>
             </React.Fragment>
        );
    }
}

export { CompletePane };