import React from 'react';
import { connect } from 'react-redux';
import { setupPage } from '../../components/Layout/setupPage';
import _ from 'lodash';
import { 
    Col,
    Card,
    PaymentTable,
    Authority
} from '../../components';

import * as actionTypes from '../../store/actionType';
import  * as actions  from '../../store/actions';
import  { PermissionEnforcer }  from '../../utility/PermissionEnforcer';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
import './Payment.style.scss';
import { PAGE_SIZE } from '../../config/pagination';

const mapStateToProps = state => ({
    apiRequestManager: ApiRequestManager.getInstance(ApiRequestManager),
    useClientDashboard: state.global.useClientDashboard,
    mode:  state.global.actionBarMode,
    account: state.global.account,
    ...state.payment
});

const mapDispatchToProps = dispatch => ({
    unLoad: () => dispatch(actions.paymentViewUnloadAction()),
    getPayments: (payload) => dispatch(actions.getAllPaymentsAction(payload)),
    getPaymentsSummary: (accountOwner) => dispatch(actions.getPaymentsSummaryAction(accountOwner))
});

class Payments extends React.Component {

    static pageOption = {
        pageTitle: 'Payments',
        disableBodyScroll: true
    };

    constructor(props) {
        super(props);

        this.state = { 
            pageSize: PAGE_SIZE,
            currentPage: 0
        };

        this.props.apiRequestManager.register(actionTypes.FETCH_PAYMENTS, this.props.getPayments);
        this.props.apiRequestManager.register(actionTypes.FETCH_PAYMENTS_SUMMARY, this.props.getPaymentsSummary);
        
        this.handleTableChange = this.handleTableChange.bind(this);
    }

    componentDidMount() { }

    componentWillUnmount() {
        this.props.unLoad();
    }

    static getDerivedStateFromProps(props, state) {
        if ( !props.useClientDashboard && !props.paymentsData && !props.apiRequestManager.inProgress(actionTypes.FETCH_PAYMENTS) ) {
            const requestData = {
                clientId: null,
                page: state.currentPage,
                pageSize: state.pageSize
            };
            props.apiRequestManager.queueRequest(actionTypes.FETCH_PAYMENTS, requestData);
            props.apiRequestManager.queueRequest(actionTypes.FETCH_PAYMENTS_SUMMARY);
        }

        if ( props.useClientDashboard && props.account && !props.paymentsData && !props.apiRequestManager.inProgress(actionTypes.FETCH_PAYMENTS) ) {
            const requestData = {
                clientId: props.account.owner,
                page: state.currentPage,
                pageSize: state.pageSize
            };
            props.apiRequestManager.queueRequest(actionTypes.FETCH_PAYMENTS, requestData);
            props.apiRequestManager.queueRequest(actionTypes.FETCH_PAYMENTS_SUMMARY, props.account.owner);
        }
        return null;
    }

    getValue(value) {
        return value || 0.0;
    }

    handleTableChange(tableOpt) {
        const pageNumber = !tableOpt.page || tableOpt.page - 1;
        const pageSize = tableOpt.sizePerPage;

        const requestParams = {
            clientId: this.props.account.owner,
            page: pageNumber,
            pageSize: pageSize
        };

        this.props.apiRequestManager.queueRequest(actionTypes.FETCH_PAYMENTS, requestParams);
        this.setState({ pageSize: pageSize });
    }

    render () {

        const quickActionCallback = {
            ...this.props.quickActions
        };
        
        let { pageData, ...tableMetadata } = this.props.paymentsData || [];
        const tableData = pageData || [];
    
        const clientLayoutClass = this.props.useClientLayout ? '--client-view' : '';
        const paymentsInsight = !Object.blank(this.props.paymentsInsight) ? this.props.paymentsInsight  : {};

        const paymentSummaryInsights = {
            days: paymentsInsight ? this.getValue(paymentsInsight?.paymentSummary?.days) : 0,
            totalRevenue: !Object.blank(paymentsInsight) ? this.getValue(paymentsInsight?.paymentSummary?.totalRevenue) : 0.0,
            totalPayments: !Object.blank(paymentsInsight) ? this.getValue(paymentsInsight?.paymentSummary?.totalPayments) : 0.0,
            outStandingPayment: !Object.blank(paymentsInsight) ? this.getValue(paymentsInsight?.paymentSummary?.outStandingPayment) : 0.0,
            collectedBills: !Object.blank(paymentsInsight) ? this.getValue(paymentsInsight?.paymentSummary?.collectedBills) : 0.0,
            uncollectedBills: !Object.blank(paymentsInsight) ? this.getValue(paymentsInsight?.paymentSummary?.uncollectedBills) : 0.0,
            uncollectedPayments: !Object.blank(paymentsInsight) ? this.getValue(paymentsInsight?.paymentSummary?.uncollectedPayments) : 0.0,
            collectedPayments: !Object.blank(paymentsInsight) ? this.getValue(paymentsInsight?.paymentSummary?.collectedPayments) : 0.0,
        };

        const days = paymentSummaryInsights?.days;

        const paymentSummaryLables = {
            payments: this.props.useClientLayout ? `Last ${days} Days` : `Collected Last ${days} Days`,
            paymentsUnpaid: this.props.useClientLayout ? `Unverified Payments` : `Unverified`,
        };

        tableMetadata = {
            ...tableMetadata,
            remote: true,
            pageSize: this.state.pageSize
        };

        return (
            <React.Fragment>
            <div className='content-layout-wrap'>
                <div className="view-layout-content container">
                   <div className="default-Ly-ct">
                    <Col lg={ 12 }>
                        <div className={`payment-summary-overview ${clientLayoutClass}`}>
                            <h2 className="title">Payments</h2>
                            <div className="payment-summary">
                                { !this.props.useClientLayout &&
                                <div className="activity --revenue">
                                    <Card>
                                        <div className="card-top">
                                            <h3 className="title">Revenue</h3>
                                            <div className="body">
                                                <h2>${paymentSummaryInsights.totalPayments.toCurrency()}</h2>
                                                <p>Collected</p>
                                            </div>
                                        </div>
                                        <div className="card-bottom">
                                            <h2>${paymentSummaryInsights.uncollectedPayments.toCurrency()}</h2>
                                            <p>Uncollected</p>
                                        </div>
                                    </Card>
                                </div>
                                 }
                                <div className="activity --payment">
                                    <Card>
                                        <div className="card-top">
                                            <h3 className="title">Payment</h3>
                                            <div className="body">
                                                <h2>${paymentSummaryInsights.collectedPayments.toCurrency()}</h2>
                                                <p>{paymentSummaryLables.payments}</p>
                                            </div>
                                        </div>
                                        <div className="card-bottom">
                                            <h2>${paymentSummaryInsights.outStandingPayment.toCurrency()}</h2>
                                            <p>{paymentSummaryLables.paymentsUnpaid}</p>
                                        </div>
                                    </Card>
                                </div>  
                                <div className="activity --balance">
                                    <Card>
                                        <div className="card-top">
                                            <h3 className="title">Bills Payments</h3>
                                            <div className="body">
                                                <h2>${paymentSummaryInsights.collectedBills.toCurrency()}</h2>
                                                <p>Collected</p>
                                            </div>
                                        </div>
                                        <div className="card-bottom">
                                            <h2>${paymentSummaryInsights.uncollectedBills.toCurrency()}</h2>
                                            <p>Uncollected</p>
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        </div>
                        <div className="table-container billing-table-coontainer">
                            <PaymentTable 
                            //ref={this.patientsTable}
                                id="billTable" 
                                hoverOverRowAction={this.willHandleTableRowMouseOver}
                                quickActions={quickActionCallback} 
                                tableData={tableData}
                                metaData={tableMetadata}
                                onTableChange={this.handleTableChange}
                            />
                        </div>
                    </Col>
                   </div>
                </div>
            </div>
            </React.Fragment>
        );
    }
}

const PaymentsView = (props) => {
    return (
        <Payments {...props } />
    );
};

let ConfigPaymentsView = setupPage(Payments.pageOption)(PaymentsView);
ConfigPaymentsView =  connect(mapStateToProps, mapDispatchToProps)(ConfigPaymentsView);

export { ConfigPaymentsView };