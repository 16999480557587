import React from 'react';
import { setupPage } from '../../components/Layout/setupPage';
import DateTimePicker from 'react-datetime-picker';
import { connect } from 'react-redux';
import {
    Row,
    Container,
    Col,
    Button,
    Section,
    Form, 
    FormGroup, 
    Label, 
    Input, 
    InputField,
    FormText,
    SimpleSelectMultiple,
    ValidateField,
    ErrorFeedback

} from '../../components';
import * as actions from '../../store/actions';
import * as helpers from '../../utility/helpers';
import * as actionTypes from '../../store/actionType';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
import patientValidatorDelegate from '../../forms/patientValidator.delegate'
import { FormValidator } from '../../utility/FormValidator';
import FileUploader from '../../utility/FileUploader';
import { PromptMessage, getPromptMessageProps } from '../components/partials/PromptMessage';
import { getDateInTimeZoneString } from '../../utility/helpers';
import { ErrorMessage } from '../components/partials/ErrorMessage';
import { VALIDATOR_DELEGATE_TYPES, VALIDATOR_DELEGATE_KEY_GROUP } from '../../common/constant';
import './Patient.style.scss';
import { getIconUrl } from '../../common/images-catalogue';

const mapStateToProps = (state) => ({
    apiRequestManager : ApiRequestManager.getInstance(ApiRequestManager),
    validator: FormValidator.getInstance(FormValidator),
    workspace: state.global.workspace,
    account: state.global.account,
    clientDashboard: state.global.useClientDashboard,
    clients: state.specimen.samplesState.clientsList,
    ...state.patient,
});

const mapDispatchToProps = (dispatch) => ({
    onLoad: () => dispatch(actions.patientsCreateViewLoadedAction()),
    unLoad: () => dispatch(actions.patientsCreateViewUnloadAction()),
    patientFieldDidChange: (fieldWithValue) => dispatch(actions.patientFieldChangeAction(fieldWithValue)),
    submitData : (data) => dispatch(actions.submitPatientAction(data)),
    setPatientFormData: (data) => dispatch(actions.setUpdatePatientDataAction(data)),
}); 

class CreateModal extends React.Component {

    static pageOption = {
        grayBackground: true,
    };

    gender = {
        male: 'Male',
        female: 'Female'
    }

    constructor(props) {
        super(props);

        props.apiRequestManager.register(actionTypes.PATIENT_SUBMIT_DATA, props.submitData);

        this.state = {
            date: new Date(),
            patientCreated: false,
            formErrors: {}
        };

        this.datePicker = React.createRef();
        this.profileImageRef = React.createRef();
        this.clientSelectRef = React.createRef();

        this.didSelectClientOption = this.didSelectClientOption.bind(this);
        this.handlePillSwitches = this.handlePillSwitches.bind(this);
        this.handleTextField = this.handleTextField.bind(this);
        this.dateChange = this.dateChange.bind(this);
        this.previewImage = this.previewImage.bind(this);
        this.submitFormData = this.submitFormData.bind(this);
        this.willHandleDone = this.willHandleDone.bind(this);
        this.getPromptMessage = this.getPromptMessage.bind(this);
    }

    componentDidMount() {
        this.props.onLoad();
        this.setFormDefaults();
        this.props.validator.addDelegate(VALIDATOR_DELEGATE_TYPES.patient, patientValidatorDelegate);
    }

    componentWillUnmount() {
        this.props.unLoad();
        this.props.pageConfig.disableBodyScroll(true);
    }

    setFormDefaults() {
        !this.props.currentPatient && this.generateRegistrationNo();
        const fieldset = {
            'gender' : this.gender.male,
            [VALIDATOR_DELEGATE_KEY_GROUP.formFields.dateOfBirth] : this.state.date
        };
 
        if (this.props.account) {
            fieldset['selectedClients'] = [this.props.account]
        }
        this.props.patientFieldDidChange(fieldset);
    }

    static getDerivedStateFromProps(props, state) {
        return null;
    }

    shouldComponentUpdate(nextProp, nextState) {
        if (nextProp.currentPatient && !nextState.patientCreated) {
            this.setState({patientCreated: true}, () => {
                this.props.callSpecimenFieldDidChange({patient: this.props.composePatientSelectOption(nextProp.currentPatient)});
            });
        }
        return true;
    }

    handlePillSwitches  (event) {
        let fieldSet = {
            'gender' : event.target.value 
        };
        this.props.patientFieldDidChange(fieldSet);
    }

    composeSelectOption(client) {
        return {
            ...client,
            name: `${client.clientInfo.firstName} ${client.clientInfo.lastName}`,
            value: client.clientInfo ? client.clientInfo.id :  client.id,
            label: `${client.clientInfo.firstName} ${client.clientInfo.lastName} - ${client.clientInfo.email}`
        }
    }

    didSelectClientOption(clients) {
        this.props.patientFieldDidChange( { 'selectedClients' : clients });

        this.setState({
            formErrors: {
                ...this.state.formErrors,
                ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.patient,VALIDATOR_DELEGATE_KEY_GROUP.formFields.client,clients)
            }
        });
    }

    handleTextField  (event) {
        let finalFieldValue;

        if (event.target.name === 'age') {
            const value = parseInt(event.target.value) > 0 ? parseInt(event.target.value) : 0;  
            const age = !event.target.value.empty() ? value : 0;
            const birthMonth = this.props.patientForm.dateOfBirth.getMonth();
            const birthDay = this.props.patientForm.dateOfBirth.getDate();

            const birthYear = helpers.calculateYearOfBirth(age, birthDay, birthMonth);
            const dateOfBirth = new Date(birthYear, birthMonth, birthDay);

            const MAX_AGE_LIMIT = 1000;
            finalFieldValue = Math.min(age, MAX_AGE_LIMIT);
            this.props.patientFieldDidChange( { 'age' : finalFieldValue});
            this.props.patientFieldDidChange( { [VALIDATOR_DELEGATE_KEY_GROUP.formFields.dateOfBirth] : dateOfBirth});
            
        } else if(event.target.name === VALIDATOR_DELEGATE_KEY_GROUP.formFields.phoneNumber) {
            const phoneNumber = event.target.value;
            finalFieldValue = helpers.formatPhoneNumber(phoneNumber);
            const formatted = {
                [event.target.name] : finalFieldValue
            }
            this.props.patientFieldDidChange(formatted);
        } else {
            finalFieldValue = event.target.value;
            let fieldSet = {
                [event.target.name] : finalFieldValue
            };
            this.props.patientFieldDidChange(fieldSet);
        }

        this.setState({
            formErrors: {
                ...this.state.formErrors,
                ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.patient,event.target.name,finalFieldValue),
            }
        });
    }

    dateChange(date) {
        const age = helpers.calculateAge(date);
        this.props.patientFieldDidChange( { 'age' : age});
        this.props.patientFieldDidChange( { [VALIDATOR_DELEGATE_KEY_GROUP.formFields.dateOfBirth] : date});
    }

    generateRegistrationNo() {
        const fieldSet = { 'registrationNo' : helpers.generateDigits(8) };
        this.props.patientFieldDidChange(fieldSet);
    }

    previewImage(event, imageRef) {
        let reader = new FileReader();
        let image = imageRef ? imageRef : this.profileImageRef;

        reader.onload = function() {
            let output = image.current; //document.getElementById('imagePreview');
            output.src = reader.result;
        }
        reader.readAsDataURL(event.target.files[0]);
        let file = event.target.files[0];
        const fieldSet = { 'profileImage' : file };
        this.props.patientFieldDidChange(fieldSet); 
    }

    submitFormData(evt) {
  
        const uploader = FileUploader.getInstance(FileUploader);
        const {profileImage, address, dateOfBirth, selectedClients, ...otherFields} = this.props.patientForm;
   
        const clientsIds = selectedClients ? selectedClients.map((item) => this.props.clientDashboard ? item.clientId : item.id) : [];

        let formData = new FormData();
        formData.append(VALIDATOR_DELEGATE_KEY_GROUP.formFields.dateOfBirth, getDateInTimeZoneString({date: dateOfBirth}));
        
        formData.append('workspace', this.props.workspace ? this.props.workspace.id : '');
        formData.append('clients', clientsIds);
      
        let formKeys = Object.keys(otherFields);

        for (const fieldName of formKeys) {
            formData.append(fieldName, this.props.patientForm[fieldName]);
        }

        if (profileImage) {
            const fileName =  uploader.generateFileName(profileImage);
            formData.append("profileImage", profileImage, fileName);
        }

        let formPayload = {
            requestType:  'create' ,
            form: formData
        }

        const formErrors = this.validateFields(formPayload.form);
       
        if (!this.props.validator.hasActiveFormError(formErrors)) {
            this.props.apiRequestManager.queueRequest(actionTypes.PATIENT_SUBMIT_DATA, formPayload);  
        }else {
            this.setState({
                formErrors: formErrors
            });
        }
    }

    validateFields(formData) {
        const formFields = VALIDATOR_DELEGATE_KEY_GROUP.formFields;

        let validations = {
            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.patient,formFields.email,formData.get(formFields.email)),

            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.patient,formFields.client,formData.get('clients')),

            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.patient,formFields.firstName,formData.get(formFields.firstName)),

            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.patient,formFields.lastName,formData.get(formFields.lastName)),

            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.patient,formFields.phoneNumber,formData.get(formFields.phoneNumber)),

            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.patient,formFields.middleName,formData.get(formFields.middleName)),

            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.patient,formFields.motherMaidenName,formData.get(formFields.motherMaidenName)),
        };
        
        if(formData.get('age') < 0){
            validations.age = true;
        }

        return  validations;  
    }

    willHandleDone() {
        this.props.currentPatient && this.props.preSelectNewPatient(this.props.currentPatient);
        this.props.closeModal();
    }

    getPromptMessage(){
        
        const apiOutcome = this.props.apiRequestManager.getApiOutcome(actionTypes.PATIENT_SUBMIT_DATA, true) ?? {};
        return getPromptMessageProps('patient', apiOutcome);
    }

    render() {
        
        const signatureSrc = this.props.patientForm.profileImage ? URL.createObjectURL(this.props.patientForm.profileImage) :  this.props.currentPatient && this.props.currentPatient.picture ? this.props.currentPatient.picture.fileDownloadUri : getIconUrl('profile');

        const defaultGender = this.props.patientForm.gender === '' ? true : this.props.patientForm.gender === this.gender.male ? true: false;

        const clientList = !Object.blank(this.props.clients) ? !Array.isArray(this.props.clients) ? [] : this.props.clients.map((client) => {
            return this.composeSelectOption(client);
        }) : [];

        const initialSelectedClient = this.props.clientDashboard ? null :  this.props.patientForm.selectedClients && this.props.patientForm.selectedClients.map((client, i) => {
            return this.composeSelectOption(client);
        });
        
        const showDone = this.props.currentPatient ? true : false;
        const dialogProps = this.getPromptMessage();
  
        const formFields = VALIDATOR_DELEGATE_KEY_GROUP.formFields;

        return <React.Fragment>
            <div className="rqt-modal-wrap">
                <div className="rqt-modal-content patient-modal">
                    <Section className="head-section">
                        { dialogProps.visible && 
                            <PromptMessage {...dialogProps} />
                        } 
                        <div className="title-box">
                            <div className="title-area">
                                <span className="icon">
                                    <img src={getIconUrl('patient')} alt="" />
                                </span>
                                <h1>Create Patient</h1>
                            </div>
                            <div className="slide-pane__close">
                            <Button className="close-btn"  
                                onClick={this.props.closeModal}>
                                <img src={getIconUrl('close')} alt=""/>
                            </Button>
                            </div>
                        </div>
                    </Section>
                    <Section>
                        <Container>
                            <Col md={12}>
                                <div className="content-section patient">
                                    <Row>
                                        <Form onSubmit={(event) => event.preventDefault()}>
                                            <Section className="patient">
                                                <div className="section-head simple">
                                                    <h3>Patient Info</h3>
                                                </div>
                                            </Section>
                                            <Section className="patient-info">
                                                <div className="section-body">
                                                <FormGroup className="section-inner">
                                                    <div className="image-thumbnail-box">
                                                        <div className="image-selector">
                                                            <img ref={this.profileImageRef} src={signatureSrc} alt=""/>
                                                            <Button><span>Edit</span></Button>
                                                            <div className="input-field">
                                                                <Input onChange={this.previewImage} type="file" name="profile-image" accept="image/*" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="gender-group">
                                                    <Label className="ip-radio-pill">
                                                        <Input onChange={this.handlePillSwitches } type="radio" name="gender" value={this.gender.male } checked={ defaultGender } />{' '}
                                                        <span className="radioselect"></span>
                                                        <span className="text">Male</span>
                                                    </Label>
                                                    <Label className="ip-radio-pill">
                                                      
                                                    <Input onChange={this.handlePillSwitches } type="radio" name="gender"value={this.gender.female }
                                                     checked={this.props.patientForm.gender === this.gender.female? true: false}/>{' '}
                                                        <span className="radioselect"></span>
                                                        <span className="text">Female</span>
                                                    </Label>
                                                </FormGroup>
                                                { !this.props.clientDashboard && 
                                                <FormGroup className="section-inner">

                                                    <ValidateField>
                                                    <SimpleSelectMultiple
                                                        id={'client'}
                                                        ref={this.clientSelectRef}
                                                        displayField={'name'}
                                                        className="ip-select-field"
                                                        name="client"
                                                        title="Choose client"
                                                        list={clientList}
                                                        titleSingular={'Client'}
                                                        titlePlural={'Clients'}
                                                        onChange={this.didSelectClientOption }
                                                        searchable={["Type Name or Email", "No matching client"]}
                                                        filterKeys={['name',
                                                                     'email']}

                                                        select={initialSelectedClient}
                                                        />
                                                        {/* <ErrorFeedback 
                                                            error
                                                            show={this.state.formErrors.clientMissing}
                                                            filter='valueMissing'
                                                            message={<ErrorMessage message={'Please choose a client'} /> }
                                                        /> */}
                                                    </ValidateField>
                                                    </FormGroup>
                                                }
                                                    <FormGroup className="section-inner username-field">
                                                    <InputField id="username" type="text" className="ip-text-default"  name={"username"} placeholder="Registration No." disabled/>
                                                    <span><i>Genterated </i> { this.props.patientForm.registrationNo }</span>
                                                </FormGroup>

                                                <FormGroup className="section-inner">
                                                    <Label>First Name</Label>
                                                    <ValidateField required>

                                                    <InputField onKeyUp={this.composeUserName} onChange={ this.handleTextField } id={formFields.firstName} type="text" name={formFields.firstName} className="ip-text-default" placeholder="John" value={this.props.patientForm.firstName } />
                                                    <ErrorFeedback 
                                                            error
                                                            show={this.state.formErrors.firstNameMissing}
                                                            filter='valueMissing'
                                                            message={<ErrorMessage message ='First name is required' />}
                                                        />

                                                    <ErrorFeedback 
                                                        error
                                                        show={this.state.formErrors.firstNameInvalid}
                                                        filter='letterInput'
                                                        message={<ErrorMessage message='Name should contain only letters'/>} />
                                                     </ValidateField>
                                                    
                                                </FormGroup>

                                                <FormGroup className="section-inner">
                                                    <Label>Last Name</Label>
                                                    <ValidateField required>

                                                    <InputField onKeyUp={this.composeUserName} onChange={ this.handleTextField } id={formFields.lastName} type="text" name={formFields.lastName} className="ip-text-default" placeholder="Brown" value={this.props.patientForm.lastName } />

                                                    <ErrorFeedback 
                                                            error
                                                            show={this.state.formErrors.lastNameMissing}
                                                            filter='valueMissing'
                                                            message={<ErrorMessage message ='Last name is required' />}
                                                        />

                                                    <ErrorFeedback 
                                                        error
                                                        show={this.state.formErrors.lastNameInvalid}
                                                        filter='letterInput'
                                                        message={<ErrorMessage message='Name should contain only letters'/>} />
                                                     </ValidateField>
                                                </FormGroup>
                                                
                                                <FormGroup className="section-inner">
                                                    <Label>Middle Name</Label>
                                                    <ValidateField>
                                                        <InputField onKeyUp={this.composeUserName} onChange={ this.handleTextField } id={formFields.middleName} type="text" name={formFields.middleName}className="ip-text-default" placeholder="John" value={this.props.patientForm.middleName } />

                                                        <ErrorFeedback 
                                                        show={this.state.formErrors.middleNameInvalid}
                                                        error
                                                        filter='letterInput'
                                                        message={<ErrorMessage message ='Name should contain only letters' />} />
                                                    </ValidateField>
                                                    
                                                </FormGroup>
                                                <FormGroup className="section-inner">
                                                    <Label>Mother's Name</Label>
                                                    <ValidateField>
                                                    <InputField onKeyUp={this.composeUserName} onChange={ this.handleTextField } id={formFields.motherMaidenName} type="text" name={formFields.motherMaidenName}className="ip-text-default" placeholder="Mother's Name" value={this.props.patientForm.motherMaidenName } />
                                                    <FormText>Mother's name before marriage (Maiden Name).</FormText>

                                                    <ErrorFeedback 
                                                        show={this.state.formErrors.motherMaidenNameInvalid}
                                                        error
                                                        filter='letterInput'
                                                        message={<ErrorMessage message ='Name should contain only letters' />} />

                                                    </ValidateField>
                                                </FormGroup>
                                                
                                                <FormGroup className="section-inner">
                                                    <Label>Email</Label>
                                                    <ValidateField>
                                                        <InputField onChange={ this.handleTextField } id={formFields.email} type="text" name={formFields.email}className="ip-text-default" placeholder="example@mail.com"
                                                        value={ this.props.patientForm.email } />

                                                        <ErrorFeedback 
                                                        show={this.state.formErrors.emailMissing}
                                                        warning
                                                        filter='valueMissing'
                                                        message={<ErrorMessage message ='Email is required' />} />

                                                        <ErrorFeedback 
                                                        show={this.state.formErrors.emailInvalid}
                                                        error
                                                        filter='emailMismatch'
                                                        message={<ErrorMessage message ='Not a valid email address' />} />
                                                    </ValidateField>
                                                </FormGroup>

                                                <FormGroup className="section-inner">
                                                    <Label>Phone Number</Label>

                                                    <ValidateField>
                                                    <InputField onChange={ this.handleTextField } id={formFields.phoneNumber} type="text" name={formFields.phoneNumber} className="ip-text-default" placeholder="888-888-8888" value={this.props.patientForm.phoneNumber } />

                                                    <ErrorFeedback 
                                                            error
                                                            show={this.state.formErrors.phoneNumberMissing}
                                                            filter='valueMissing'
                                                            message={<ErrorMessage message ='Please add a Phone number' />}
                                                        />
                                                    <ErrorFeedback 
                                                            error
                                                            show={this.state.formErrors.phoneNumberInvalid}
                                                            filter='phoneNumber'
                                                            message={<ErrorMessage message ='Phone number
                                                            not valid' />}
                                                        />
                                                     </ValidateField>
                                                    
                                                </FormGroup>
                                                </div>
                                            </Section>
                                            <Section className="attr-info">
                                                <div className="section-head">
                                                    <h3>Attributes</h3>
                                                </div>
                                                <div className="section-body">
                                                
                                                <FormGroup className="section-inner">
                                                    <Label>Date of Birth</Label>
                                                    <div className="ip-date date-field"> 
                                                    <DateTimePicker 
                                                    ref={this.datePicker}
                                                    maxDate={this.state.date}
                                                    className="date-picker"
                                                    clearIcon={null}
                                                    disableClock
                                                    time={false}
                                                    format={"MM-dd-y"}
                                                    onChange={this.dateChange}
                                                    value={this.props.patientForm.dateOfBirth} />
                                                    </div>
                                                    
                                                </FormGroup>
                                                </div>
                                                <FormGroup className="section-inner">
                                                    <Label>Age</Label>
                                                    <InputField  onChange={ this.handleTextField } id="age" type="age" name={"age"}className="ip-text-default" placeholder="29"
                                                    value={this.props.patientForm.age }/>
                                                    
                                                </FormGroup>
                                            </Section>
                                        </Form>
                                    </Row>
                                </div>
                            </Col>
                        </Container>
                    </Section>
                    <Section className="bottom-section">
                        { !showDone && this.props.patientForm.client !==null  && 
                            <Button onClick={this.submitFormData} className="save-btn">Save</Button>
                        }
                        { showDone && 
                        <Button onClick={this.willHandleDone} className="save-btn">Done</Button>
                        }
                    </Section>
                </div>
            </div>
        </React.Fragment>
    }
}

const CreateModalView = (props) => {
    return (
        <CreateModal {...props } />
    );
};

let ConfigCreateModalView = setupPage( CreateModal.pageOption )(CreateModalView);
ConfigCreateModalView = connect(mapStateToProps, mapDispatchToProps)(ConfigCreateModalView);
export {ConfigCreateModalView} 
