import * as actionTypes from '../actionType';
import {action} from './actionCreator';
import {createRequestTypes, REQUEST, SUCCESS, FAILURE} from './../../service/httpRequestDialect';

export const appLoadedAction =  (payload) => ({
    type: actionTypes.APP_LOADED,
    payload
}); 

export const appUnloadAction =  (payload) => ({
    type: actionTypes.APP_UNLOAD,
    payload
});
export const appRouteChangeAction =  (route) => ({
    type: actionTypes.APP_ROUTE_CHANGE,
    payload: route
}); 

export const apiSyncAction =  (initiator) => ({
    type: actionTypes.ASYNC_START,
    payload: {
        initiator
    }
}); 

export const networkStatusAction =  (status) => ({
    type: actionTypes.NETWORK_STATUS,
    payload: status
}); 

export const setLazyLoadingAction = (type, YesOrNo) => ({
    type: type,
    payload: YesOrNo
}); 

export const apiResponseActionCreator = (type, data) => ({
    type: type,
    payload: data
}); 

export const apiSyncEndAction =  (apiOutcome) => ({
    type: actionTypes.ASYNC_ENDED,
    payload: { ...apiOutcome }
}); 
export const apiSyncAbortAction =  (actionPrefix) => ({
    type: actionPrefix + actionTypes.ABORT_API_REQUEST,
}); 
export const redirectToAction =  (to) => ({
    type: actionTypes.REDIRECT_TO,
    payload:to
}); 

export const apiSyncCompletedAction =  () => ({
    type: actionTypes.ASYNC_COMPLETED
}); 

export const apiOutcomeAction = (type, payload) => ({
    type,
    payload
});

export const pingServerAction = () => ({
    type: actionTypes.PING_SERVER,
})

export const pingServerDoneAction = (payload) => ({
    type: actionTypes.PING_SERVER_DONE,
    payload: payload
})
export const getCodeSheetsAction = () => ({
    type: actionTypes.FETCH_CODE_SHEETS
});
export const getCodeFindingsAction = () => ({
    type: actionTypes.FETCH_CODE_FINDINGS
});
export const getAllLabcodesAction = () => ({
    type: actionTypes.FETCH_LABCODES
});
export const getAllTaxesAction = () => ({
    type: actionTypes.FETCH_TAXES
});
export const actionBarModeAction = (mode) => ({
    type: actionTypes.ACTION_BAR_MODE,
    payload: mode
});
export const dashboadViewLoadedAction = () => ({
    type: actionTypes.DASHBOARD_VIEW_LOADED,
});
export const dashboadViewUnloadAction = () => ({
    type: actionTypes.DASHBOARD_VIEW_UNLOAD,
});
export const dashboadLayoutAction = (isClientLayout) => ({
    type: actionTypes.DASHBAORD_LAYOUT,
    payload: isClientLayout
});
export const tableRowMouseOverAction = (rowData) => ({
    type: actionTypes.TALBE_ROW_HOVER_EVENT,
    payload: {
        ...rowData
    }
}); 

export const contextMenuEventAction = (inContext) => ({
    type: actionTypes.CONTEXT_MENU_EVENT,
    payload: inContext
});

export const fileUploadAction = (file) => ({
    type: actionTypes.UPLOAD_FILE,
    payload: file
}); 

export const fileDownloadAction = (file) => ({
    type: actionTypes.DOWNLOAD_FILE,
    payload: file
}); 

export const getGlobalAppDataAction = (user) => ({
    type: actionTypes.FETCH_GLOBAL_DATA,
    payload: user
}); 
export const dismissNotificationAction = (payload) => ({
    type: actionTypes.DISMISS_NOTIFICATION,
    payload: payload
}); 

export const receivedNotificationAction =  (payload) => ({
    type: actionTypes.RECEIVED_NOTIFICATION,
    payload: payload
}); 

export const getTaxByTypeAction = (type) => ({
    type: actionTypes.FETCH_TAXES_BY_TYPE,
    payload: type
});

export const getDashboardDataAction = (workspace) => ({
    type: actionTypes.FETCH_DASHBOARD_DATA,
    payload: workspace
});

export const contextSearchAction = (payload) => ({
    type: actionTypes.CONTEXT_SEARCH,
    payload: payload
});

export const searchTermChangeAction = (values) =>({
    type: actionTypes.SEARCH_FIELD_CHANGE,
    payload: values
});

export const FETCH_NOTIFICATIONS = createRequestTypes('FETCH_NOTIFICATIONS');

export const notifications = {
    request: () => action(FETCH_NOTIFICATIONS[REQUEST]),
    success: (payload) => action(FETCH_NOTIFICATIONS[SUCCESS], {payload}),
    failure: (error) => action(FETCH_NOTIFICATIONS[FAILURE], {error}),
};

export const updateWorkspaceName = (payload) =>({
    type: actionTypes.SUBMIT_WORKSPACE_NAME,
    payload : payload
})