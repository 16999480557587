import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setupPage } from '../../components/Layout/setupPage';
import { 
    Container,
    Row,
    Col
} from '../../components';

import * as actionTypes from '../../store/actionType';
import  * as actions  from '../../store/actions';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
import { Loader } from '../components/partials/LoadingIndicator';
import PrintablePayslip from '../components/partials/PrintablePayslip';
import ReactToPrint,  { PrintContextConsumer } from 'react-to-print';
import {PrintBar} from './../components/partials/PrintBar';
import './Payroll.style.scss';

const mapStateToProps = state => ({
    apiRequestManager: ApiRequestManager.getInstance(ApiRequestManager),
    useClientLayout: state.global.useClientDashboard,
    mode:  state.global.actionBarMode,
    ...state.payroll
});

const mapDispatchToProps = dispatch => ({
    onLoad: () => dispatch(actions.clientsViewLoadedAction()),
    unLoad: () => dispatch(actions.clientsViewUnloadAction()),
    getPayAdvice: (payload) => dispatch(actions.getPayAdviceAction(payload))
});

class Payslip extends React.Component {

    static pageOption = { 
        pageTitle: 'Pay Slips',
        disableBodyScroll: true
    };

    constructor(props) {
        super(props);
        this.state = { };

        props.apiRequestManager.register(actionTypes.FETCH_PAYADVICES, props.getPayAdvice);   
    }

    componentDidMount() { 
        this.setup(); 
    }

    componentWillUnmount() { }

    static getDerivedStateFromProps(props, state) {
        return null;
    }

    setup() {
     
        const payrollId = this.props.match.params.payroll_id;
        const query = new URLSearchParams(this.props.location.search);
        const payAdviceId = query.get('payadvice_f');
        const requestParams = {
            payrollId: payrollId,
            payAdviceId: payAdviceId,
            processing: true
        }

        const fetchRequest = this.props.payAdvices.empty() && !this.props.apiRequestManager.inProgress(actionTypes.FETCH_PAYADVICES);

        fetchRequest && this.props.apiRequestManager.queueRequest(actionTypes.FETCH_PAYADVICES,requestParams);
    }

    render () {
        
        const loading = this.props.apiRequestManager.inProgress(actionTypes.FETCH_PAYADVICES); // this.props.processing;
        const payslipsData = this.props.payAdvices ? this.props.payAdvices : [];
        const titlePrefix = payslipsData.length > 1? 'Employees' : 'Employee';

        return (
            <React.Fragment>
            <div className='content-layout-wrap payroll-detail-view payslip'>
                <div className="view-layout-content">
                   <div className="default-Ly-ct">
                       <Container className="payroll-overview">
                            <div className="title-box">
                                <h1 className="title">{titlePrefix} Payslip</h1>
                            </div>

                           <Row>
                            <Col md={12}>
                                { loading &&
                                    <div className="loader-modal">
                                        <Loader visible={ loading } loadingText={'Generating...'} />
                                    </div>
                                }
                               
                                <div className="content-wrap">
                                    { !loading &&
                                    <React.Fragment>
                                        <div className="pay-period">
                                            <h3>Pay period: Apr 17, 2021</h3>
                                        </div>
                                        <ReactToPrint content={() => this.componentRef}>
                                        <PrintContextConsumer>
                                            {({ handlePrint }) => (
                                                <PrintBar handlePrint={handlePrint} />
                                            )}
                                        </PrintContextConsumer>
                                        
                                        <div className="printable-area" ref={el => (this.componentRef = el)}>
                                        { payslipsData.map((item, i) => {
                                            return <PrintablePayslip 
                                            key={i}
                                            payAdvice={item} />
                                        })}
                                    </div>
                                        
                                    </ReactToPrint>
                                   </React.Fragment>
                                }
                                </div>
                               </Col>
                           </Row>
                        </Container>
                   </div>
                </div>
            </div>
            </React.Fragment>
        );
    }
}

const PayslipView = (props) => {
    return (
        <Payslip {...props } />
    );
};

let ConfigPayslipView = setupPage(Payslip.pageOption)(PayslipView);
ConfigPayslipView =  connect(mapStateToProps, mapDispatchToProps)(ConfigPayslipView);

export { ConfigPayslipView };