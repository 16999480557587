import React from 'react';
import classnames from 'classnames';
import { setupPage } from '../../components/Layout/setupPage';
import {
    Row,
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    Button,
    List,
    TabPane,
    UsersTable,
    Label,
    Authority,

} from '../../components';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/actionType';
import * as actions from '../../store/actions';
import * as urls from '../../config/urls';

import { ContextMenu, MenuItem } from "react-contextmenu";
import { REQUESTS_TYPE } from '../../common/constant';
import UsersSummary from './../components/partials/UsersSummary';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
import {SEARCH_SETTINGS } from '../../config/searchable';
import { getIconUrl } from '../../common/images-catalogue';
import ConfirmModal from '../components/partials/ConfirmModal';
import contextMenuItems from './contextMenuItems';
import { PAGE_SIZE } from '../../config/pagination';

import {
    PromptMessage,
    getPromptMessageProps
  } from '../components/partials/PromptMessage';

const mapStateToProps = (state) => ({
    apiRequestManager : ApiRequestManager.getInstance(ApiRequestManager),
    useClientLayout: state.global.useClientDashboard,
    mode:  state.global.actionBarMode,
    workspace: state.global.workspace,
    ...state.users 
});

const mapDispatchToProps = (dispatch) => ({
    onLoad: () => dispatch(actions.usersViewLoadedAction()),
    unLoad: () => dispatch(actions.usersViewUnloadAction()),
    getUsers: (payload) => dispatch(actions.getAllUsersActionCreator(payload)),
    setMode: (mode) => dispatch(actions.actionBarModeAction(mode)),
    rowDidMouseOver: (contextData) => dispatch(actions.tableRowMouseOverAction(contextData)),
    contextMenuEvent: (inContext) => dispatch(actions.contextMenuEventAction(inContext)),
    singleSuperuserPresent: (isTrue) => dispatch(actions.setSingleSuperuser(isTrue)),
    submitAuthAccess: (payload) => dispatch(actions.submitAuthAccessAction(payload)),
    submitData : (data) => dispatch(actions.submitUserAction(data)),
}); 

class Users extends React.Component {

    static pageOption = {
        pageTitle: 'Client',
        disableBodyScroll: true,
        searchContext: SEARCH_SETTINGS.entities.user.name
    };

    contextMenuOptions = { }

    constructor(props) {
        super(props);
        
        props.apiRequestManager.register(actionTypes.FETCH_USERS, props.getUsers);
        props.apiRequestManager.register(actionTypes.AUTH_ACCESS_SUBMIT_DATA, props.submitAuthAccess);
        props.apiRequestManager.register(actionTypes.USER_SUBMIT_DATA, props.submitData);

        this.state = {
            activeTab: '1',
            confirmDialogVisible: false,
            pageSize: PAGE_SIZE,
            currentPage: 0
          };

        this.contextMenuRef = React.createRef();
        //Add event listeners
        this.toggle = this.toggle.bind(this);
        this.onAddUser = this.onAddUser.bind(this);
        this.updateUserAction = this.updateUserAction.bind(this);
        this.handleBlockUnblockUser = this.handleBlockUnblockUser.bind(this);
        this.willHandleTableRowMouseOver = this.willHandleTableRowMouseOver.bind(this);
        this.contextMenuWillAppear = this.contextMenuWillAppear.bind(this);
        this.contextMenuDismissed = this.contextMenuDismissed.bind(this);
        this.contextMenuOptClick = this.contextMenuOptClick.bind(this);
        this.getAuthAccessMessage = this.getAuthAccessMessage.bind(this);

        this.onConfirmDialog = this.onConfirmDialog.bind(this);
        this.showHideConfimStatusDialog = this.showHideConfimStatusDialog.bind(this);
        this.submitDeleteUser = this.submitDeleteUser.bind(this);
        this.handleTableChange = this.handleTableChange.bind(this);

        this.initialize();
    }

    componentDidMount() {
        if ( !this.props.usersData && this.props.workspace ) {
            //this.props.useClientLayout ? this.props.apiRequestManager.queueRequest(actionTypes.FETCH_USERS, this.props.workspace.id) : this.props.apiRequestManager.queueRequest(actionTypes.FETCH_USERS);
        }

       this.props.onLoad();
       //this.props.workspace && this.props.useClientDashboard ? this.props.getUsers(this.props.workspace.id) : this.props.getUsers();
       window.addEventListener('REACT_CONTEXTMENU_SHOW', this.contextMenuWillAppear);
       window.addEventListener('REACT_CONTEXTMENU_HIDE', this.contextMenuDismissed);
       this.unlistenAbort = this.props.history.listen(() => { 
            this.props.apiRequestManager.abortRequest(actionTypes.FETCH_USERS);
        });
    }

    initialize() {
        contextMenuItems.forEach((menuItem, i) => {
            this.contextMenuOptions[menuItem.key] = {
                ...menuItem,
                callbackFunc: this[menuItem.callbackFunc]
            };
        });
    }

    componentWillUnmount() {
        window.removeEventListener('REACT_CONTEXTMENU_SHOW', this.contextMenuWillAppear);
        window.removeEventListener('REACT_CONTEXTMENU_HIDE', this.contextMenuDismissed);
        this.props.unLoad();
        this.unlistenAbort();
    }

    static getDerivedStateFromProps(props, state) {
        // Return null to indicate no change to state.
        if ( (!props.usersData && !Object.blank(props.workspace))) {

            const requestParams = {
                workspaceId: props.useClientLayout ? props.workspace.id : null,
                page: state.currentPage,
                pageSize: state.pageSize
            };
            props.apiRequestManager.queueRequest(actionTypes.FETCH_USERS, requestParams);
        }
        return null;
    }

    /*getSnapshotBeforeUpdate(prevProps, prevState) {
        console.log('------getSnapshotBeforeUpdate------')
        console.log(prevProps);
        return null;
    }*/

    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({ activeTab: tab, currentPage: 0});
        }
    }

    onAddUser(evt) {
        this.props.history.push({ pathname: urls.createUserUri});
    }

    updateUserAction(evt) {
        this.props.singleSuperuserPresent(this.checkForSingleSuperuser());
        this.props.setMode('edit');
        this.props.history.push({ pathname: urls.updateUserUri});
    }

    handleBlockUnblockUser(data) {
        const user = data;
        
        const requestPayload = {
            requestType: REQUESTS_TYPE.UPDATE,
            userId: user.id,
            [!user.blocked ? 'BLOCK' : 'UNBLOCK'] : true
        }

        this.props.apiRequestManager.queueRequest(actionTypes.AUTH_ACCESS_SUBMIT_DATA, requestPayload);
    }

    submitDeleteUser(user) {
        var data = {
            requestType:  'delete',
            userId: user.id
        }
        
        this.props.apiRequestManager.queueRequest(actionTypes.USER_SUBMIT_DATA, data);
    }

    checkForSingleSuperuser() {

        const {standard, authorizers} = this.props.usersData;
        console.log(this.props.usersData);

        const userslist = [
            ...standard?.pageData,
            ...[authorizers ?? [] ]
        ]
        const superusers = userslist.filter((user) => {
            if (user.role?.superRole) {
                return user;
            }
        });
        return superusers.length === 1;
    }

    contextMenuWillAppear(evt) {
        this.props.contextMenuEvent(true);
    }
    
    contextMenuDismissed(evt) {
        this.props.contextMenuEvent(false);
    }

    contextMenuOptClick(evt, data) {
        this.contextMenuOptions[data.opt].callbackFunc(data.opt)
    }

    willHandleTableRowMouseOver(rowData) {
        (this.props.rowUserContext === undefined || !this.props.rowUserContext) && this.props.rowDidMouseOver(rowData);
    }

    getAuthAccessMessage() {
        let apiOutcome = this.props.apiRequestManager.getApiOutcome(actionTypes.AUTH_ACCESS_SUBMIT_DATA, true);

        let promptProps = {
          error: apiOutcome?.error ? true : false,
          visible: apiOutcome?.success || (apiOutcome?.error) || false,
          message: this.props.authAccessRequest.message || '',
          sticky: true
        }; 

        if (!apiOutcome) {
            apiOutcome = this.props.apiRequestManager.getApiOutcome(actionTypes.USER_SUBMIT_DATA, true) ?? {};
            promptProps = getPromptMessageProps('user', apiOutcome);
        }

        if (apiOutcome?.success && !this.props.apiRequestManager.inProgress(actionTypes.FETCH_USERS)) {
            const requestParams = {
                workspaceId: this.props.useClientLayout ? this.props.workspace.id : null,
                page: this.state.currentPage,
                pageSize: this.state.pageSize
            };
            //this.props.apiRequestManager.queueRequest(actionTypes.FETCH_USERS, requestParams);
        }
    
        return promptProps;
    }

    onConfirmDialog(contextUser) {
        switch(this.state.contextMenuAction) {
            case 'blocked':
                this.handleBlockUnblockUser(contextUser);
                break;
            case 'delete':
                this.submitDeleteUser(contextUser);
                break
            default:
        }
        this.showHideConfimStatusDialog();
    }

    showHideConfimStatusDialog(action) {
        this.setState ({
            confirmDialogVisible: !this.state.confirmDialogVisible,
            contextMenuAction: action
        });
    }

    handleTableChange(tableOpt) {
        const pageNumber = !tableOpt.page || tableOpt.page - 1;
        const pageSize = tableOpt.sizePerPage;

        const requestParams = {
            workspaceId: this.props.useClientLayout ? this.props.workspace.id : null,
            page: pageNumber,
            pageSize: pageSize
        };

        this.props.apiRequestManager.queueRequest(actionTypes.FETCH_USERS, requestParams);

        this.setState({
            currentPage: pageNumber,
            pageSize: pageSize
        });     
    }

    renderContextMenu(options) {

        return (<ContextMenu ref={this.contextMenuRef}id="users_table_context_menu">
            <MenuItem data={{opt: 'view'}} onClick={options.contextMenuOptClick}>
                View Details
            </MenuItem>
            <MenuItem data={{opt: 'edit'}} onClick={options.contextMenuOptClick}>
                Edit
            </MenuItem>
            <MenuItem divider />
            <MenuItem disabled data={{opt: 'twoFactorAuth'}} onClick={options.contextMenuOptClick}>
                Two factor Authentication
            </MenuItem>
            <MenuItem data={{opt: 'blocked'}} onClick={options.contextMenuOptClick}>
                { options.currentUser?.blocked ? 'Unblock' : 'Block' }
            </MenuItem>
            <MenuItem data={{opt: 'delete'}} onClick={options.contextMenuOptClick}>
                Delete
            </MenuItem>
        </ContextMenu>); 
    }

    getTableMetaData(usersData, key) {
        let metaData = usersData ? usersData[key] : {};
        if (metaData) {
            const {pageData, analytics, ...tableInfo} = metaData;
            metaData = tableInfo;
        }

        const metadata = { 
            remote: true, 
            pageSize: this.state.pageSize,
            ...metaData
        };

        return metadata;
    }

    render() {
       
        const contextMenuOptClick = this.contextMenuOptClick;

        var usersTableData = Object.blank(this.props.usersData?.standard) ? [] : Array.isArray(this.props.usersData.standard.pageData) ? this.props.usersData.standard.pageData : []; 

        var authorizersTableData = Object.blank(this.props.usersData?.authorizers) ? [] : Array.isArray(this.props.usersData.authorizers.pageData) ? this.props.usersData.authorizers.pageData : []; 

        var staffsTableData =  Object.blank(this.props.usersData?.secondaryUsers) ? [] : Array.isArray(this.props.usersData.secondaryUsers.pageData) ? this.props.usersData.secondaryUsers.pageData : []; 

        var blockedUsersTableData = Object.blank(this.props.usersData?.blockedUsers) ? [] : Array.isArray(this.props.usersData.blockedUsers.pageData) ? this.props.usersData.blockedUsers.pageData : []; 

        const usersTableMetadata = this.getTableMetaData(this.props.usersData, 'standard');
        const authorizersTableMetadata = this.getTableMetaData(this.props.usersData, 'authorizers');
        const staffsTableMetadata = this.getTableMetaData(this.props.usersData, 'secondaryUsers');
        const blockedUsersTableMetadata = this.getTableMetaData(this.props.usersData, 'blockedUsers');

        const quickActionCallback = {
            'editAction' : this.updateUserAction
        };
        
        const { rowUserContext } = this.props;

        const contextMenuProps = {
            contextMenuOptClick,
            activeTab: this.state.activeTab,
            currentUser: this.props.currentUser
        };

        const getContextOptItem = (contextItemKey) => {
            return this.contextMenuOptions[contextItemKey]
        }

        const confirmDialogProps = {
            isDelete: this.state.contextMenuAction === this.contextMenuOptions.delete.key,
            title: getContextOptItem(this.state.contextMenuAction)?.title && getContextOptItem(this.state.contextMenuAction)?.title.replace(':block', this.props.currentUser.blocked ? 'Unblock' : 'Block'),

            subTitle: getContextOptItem(this.state.contextMenuAction)?.subtitle ?getContextOptItem(this.state.contextMenuAction)?.subtitle.replace(':block', this.props.currentUser.blocked ? 'unblock' : 'block').replace(':user', this.props.currentUser.username) : null
        };

        const promptProps = this.getAuthAccessMessage();

        return (
            <React.Fragment>
                <Container className="users">
                    <Row>
                        <Col lg={ 3 }>
                            <div className="widget-section">
                                <div className="button-wrap">
                                    <Authority allowed="create" entity="user" >
                                        <Button className="default-btn add-btn" onClick={this.onAddUser}>
                                            <span className="icon">
                                                <img src={getIconUrl("addUser")} alt='' />
                                            </span>
                                            Add User
                                        </Button>
                                    </Authority>
                                </div>
                                <div className="users-widgets-list">
                                    <List>
                                        <UsersSummary label={'Standard'} count={usersTableMetadata?.totalItems || 0}/>
                                        
                                        { this.props.useClientLayout ? null : 
                                        <React.Fragment>
                                       
                                        <UsersSummary label={'Authorizers'} count={authorizersTableMetadata?.totalItems || 0}/>
                                        </React.Fragment>
                                        }

                                        <UsersSummary label={'Blocked'} count={blockedUsersTableMetadata?.totalItems || 0}/>

                                        
                                    </List>
                                </div>
                            </div>
                        </Col>
                        <Col lg={ 9 }>
                        <div className="content-section">
                            <PromptMessage {...promptProps} />
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.toggle('1'); }}
                                    >
                                        <span className="icon"><img src={getIconUrl("singleUser")} alt='' /></span>
                                        Standard
                                    </NavLink>
                                </NavItem>
                                { this.props.useClientLayout ? null : 
                                <React.Fragment>
                                <Authority allowed="view" entity="authorizer"> 
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '2' })}
                                            onClick={() => { this.toggle('2'); }}
                                        >
                                        <span className="icon"><img src={getIconUrl("authorizerUser")} alt='' /></span>
                                        Authorizer
                                        </NavLink>
                                    </NavItem>
                                </Authority>  
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: this.state.activeTab === '3' })}
                                        onClick={() => { this.toggle('3'); }}
                                    >
                                    <span className="icon"><img src={getIconUrl("multiUser")} alt='' /></span>
                                    Staff
                                    </NavLink>
                                </NavItem>
                                </React.Fragment>
                                }
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: this.state.activeTab === '4' })}
                                        onClick={() => { this.toggle('4'); }}
                                    >
                                    <span className="icon"><img src={getIconUrl("blockedUser")} alt=''/></span>
                                    Blocked
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                <Row>
                                    <Col sm="12">
                                        <div className="table-container"> 
                                            <UsersTable 
                                            rowUserContext={rowUserContext} hoverOverRowAction={this.willHandleTableRowMouseOver} 
                                            quickActions={quickActionCallback} 
                                            tableData={usersTableData}
                                            metaData={usersTableMetadata}
                                            onTableChange={this.handleTableChange}/>
                                        </div>
                                    </Col>
                                    
                                </Row>
                                </TabPane>
                                { this.props.useClientLayout ? null : 
                                <React.Fragment>
                                <Authority allowed="view" entity="authorizer"> 
                                    <TabPane tabId="2">
                                        <Row>
                                            <Col lg="12">
                                            <div className="table-container"> 
                                                <UsersTable 
                                                rowUserContext={rowUserContext} hoverOverRowAction={this.willHandleTableRowMouseOver} 
                                                quickActions={quickActionCallback} tableData={authorizersTableData}
                                                metaData={authorizersTableMetadata}
                                                onTableChange={this.handleTableChange}/>
                                            </div>
                                            </Col>
                                        </Row>
                                       
                                    </TabPane>
                                </Authority>
                                <TabPane tabId="3">
                                <Row>
                                    <Col lg="12">
                                    <div className="table-container"> 
                                        <UsersTable 
                                        rowUserContext={rowUserContext} hoverOverRowAction={this.willHandleTableRowMouseOver} 
                                        quickActions={quickActionCallback} 
                                        tableData={staffsTableData}
                                        metaData={staffsTableMetadata}
                                        onTableChange={this.handleTableChange}/>
                                    </div>
                                    </Col>
                                    
                                </Row>
                                </TabPane>
                                </React.Fragment>
                                }
                                <TabPane tabId="4">
                                <Row>
                                    <Col lg="12">
                                    <div className="table-container"> 
                                        <UsersTable rowUserContext={rowUserContext} hoverOverRowAction={this.willHandleTableRowMouseOver} quickActions={quickActionCallback} tableData={blockedUsersTableData}
                                        metaData={blockedUsersTableMetadata}
                                        onTableChange={this.handleTableChange}/>
                                    </div>
                                    </Col>
                                    
                                </Row>
                                </TabPane>
                                
                                {  this.renderContextMenu(contextMenuProps) }

                            </TabContent>
                            </div>
                        </Col>
                    </Row>

                    {
                    this.state.confirmDialogVisible && 
                    <ConfirmModal 
                        contextName={'user'}
                        contextItem={this.props.currentUser} 
                        isDelete={confirmDialogProps.isDelete}
                        title={confirmDialogProps.title}
                        subtitle={confirmDialogProps.subTitle}
                        cancelCallback={this.showHideConfimStatusDialog}
                        confirmCallback={this.onConfirmDialog}
                    />
                    }
                
                </Container>
            </React.Fragment>
        );
    }
}

const UsersView = (props) => {
    return (
        <Users {...props } />
    );
};

const ConfigUsersView =  setupPage(Users.pageOption)(UsersView);
export default connect(mapStateToProps, mapDispatchToProps)(ConfigUsersView);