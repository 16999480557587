
import {ofType } from 'redux-observable';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import *  as actionTypes from '../actionType';
import { accountDialect, checkReponse } from '../../service/agent';
import { of } from 'rxjs';
import * as actions from '../actions';

export const getClientAccountEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.FETCH_CIENT_ACCOUNT),
        switchMap((action) => {
            return accountDialect.getClientAccount(action.payload).pipe(
               map(response => {
                    const responseData = checkReponse(response, action.type);
                    return  actions.apiResponseActionCreator(actionTypes.FETCHED_CIENT_ACCOUNT_RESULT, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.FETCH_CIENT_ACCOUNT + actionTypes.ABORT_API_REQUEST))),
               catchError(error => {
                    console.log("ERROR", error);
                    return of( actions.apiResponseActionCreator(actionTypes.FETCHED_CIENT_ACCOUNT_RESULT, error));
               })
            );
        })
    )
}



export const getWorkspaceDetailsEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.FETCH_WORKSPACE_DETAILS),
        switchMap((action) => {
            return accountDialect.getWorkspaceDetails(action.payload).pipe(
               map(response => {
                    const responseData = checkReponse(response, action.type);
                    return  actions.apiResponseActionCreator(actionTypes.FETCHED_CIENT_ACCOUNT_RESULT, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.FETCH_CIENT_ACCOUNT + actionTypes.ABORT_API_REQUEST))),
               catchError(error => {
                    console.log("ERROR", error);
                    return of( actions.apiResponseActionCreator(actionTypes.FETCHED_CIENT_ACCOUNT_RESULT, error));
               })
            );
        })
    )
}

export const getOwnerAccountEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.FETCH_OWNER_ACCOUNT),
        switchMap((action) => {
            return accountDialect.getOwnerAccount(action.payload).pipe(
               map(response => {
                    const responseData = checkReponse(response, action.type);
                    return  actions.apiResponseActionCreator(actionTypes.FETCHED_OWNER_ACCOUNT_RESULT, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.FETCH_OWNER_ACCOUNT + actionTypes.ABORT_API_REQUEST))),
               catchError(error => {
                    console.log("ERROR", error);
                    return of( actions.apiResponseActionCreator(actionTypes.FETCHED_OWNER_ACCOUNT_RESULT, error));
               })
            );
        })
    )
}

export const getAllAccountsEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.FETCH_ACCOUNTS),
        switchMap((action) => {
            return accountDialect.fetchAllAccounts(action.payload).pipe(
               map(response => {
                    const responseData = checkReponse(response, action.type);
                    return  actions.apiResponseActionCreator(actionTypes.FETCHED_ACCOUNTS_RESULT, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.FETCH_ACCOUNTS + actionTypes.ABORT_API_REQUEST))),
                catchError(error => {
                    console.log("ERROR", error);
                    return of( actions.apiResponseActionCreator(actionTypes.FETCHED_ACCOUNTS_RESULT, error));
               })
            );
        })
    )
}