import _ from 'lodash';

import * as colors from './colors.scss';

const colours = {
    blue:    '#1EB7FF',
    indigo:  '#6610f2',
    purple:  '#CA8EFF',
    pink:    '#e83e8c',
    red:     '#ED1C24',
    orange:  '#F27212',
    yellow:  '#F7BF47', 
    green:   '#1BB934', 
    teal:    '#20c997', 
    cyan:    '#33AE9A',
}

const colorKeys = _
    .chain(colours)
    .keys()
    .filter((colorKey) => {
        return (
            colorKey.indexOf('bg-') === -1 &&
            colorKey.indexOf('fg-') === -1
        )}
    )
    .value();

export default _.pick(colours, colorKeys);