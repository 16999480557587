import React from 'react';
import { connect } from 'react-redux';
import { setupPage } from '../../components/Layout/setupPage';

import classnames from 'classnames';
import * as urls from '../../config/urls';
import { ContextMenu, MenuItem } from "react-contextmenu";

import * as actionTypes from '../../store/actionType';
import  * as actions  from '../../store/actions';
import  { PermissionEnforcer }  from '../../utility/PermissionEnforcer';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
import { ACTION_BAR_MODES } from '../../common/constant';
import {SpecimenSupervisor } from '../../utility/SpecimenSupervisor';

import {Overview, PaidBillView, UnpaidBillView, BillableView} from './index';
import {SEARCH_SETTINGS } from '../../config/searchable';
import './Billing.style.scss';

import { 
    Nav,
    NavItem,
    NavLink,
    Button,
    Authority,
} from '../../components';

import { getIconUrl } from '../../common/images-catalogue';

const mapStateToProps = state => ({
    apiRequestManager: ApiRequestManager.getInstance(ApiRequestManager),
    specimenSupervisor: SpecimenSupervisor.getInstance(SpecimenSupervisor),
    useClientLayout: state.global.useClientDashboard,
    mode:  state.global.actionBarMode,
    workspace: state.global.workspace,
    account: state.global.account,
    ...state.billing
});

const mapDispatchToProps = dispatch => ({
    onLoad: () => dispatch(actions.billsViewLoadedAction()),
    unLoad: () => dispatch(actions.billsViewUnloadAction()),
    setMode: (mode) => dispatch(actions.actionBarModeAction(mode)),
    getBills: (payload) => dispatch(actions.getAllBillsAction(payload)),
    getPaidBills: (payload) => dispatch(actions.getAllPaidBillsAction(payload)),
    getUnpaidBills: (payload) => dispatch(actions.getAllUnpaidBillsAction(payload)),
    setUpdateBillData: (data) => dispatch(actions.setUpdateBillDataAction(data)),
    rowDidMouseOver: (contextData) => dispatch(actions.tableRowMouseOverAction(contextData)),
    contextMenuEvent: (inContext) => dispatch(actions.contextMenuEventAction(inContext)),
    getBillsSummary: (accountOwner) => dispatch(actions.getBillsSummaryAction(accountOwner)),
});

function offset(el) {
    const rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

class Billing extends React.Component {

    static pageOption = {
        pageTitle: 'Client',
        disableBodyScroll: true,
        searchContext: SEARCH_SETTINGS.entities.bill.name
    };

    views = {
        overview: 'overview',
        paid: 'paid',
        unpaid: 'unpaid',
        billable: 'billable'
    };

    titles = {
        overview: 'Billing Insights',
        paid: 'Paid Bills',
        billable: 'Billable Samples',
        unpaid: 'Unpaid Bills'
    };
    
    constructor(props) {
        super(props);

        props.apiRequestManager.register(actionTypes.FETCH_BILLS, props.getBills);
        props.apiRequestManager.register(actionTypes.FETCH_PAID_BILLS, props.getPaidBills);
        props.apiRequestManager.register(actionTypes.FETCH_UNPAID_BILLS, props.getUnpaidBills);

        this.props.apiRequestManager.register(actionTypes.FETCH_BILLS_SUMMARY, this.props.getBillsSummary);

        this.state = {
            activeView: 'overview',
            isPaneOpen: false,
        }
        this.switchView = this.switchView.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.onCreateBill = this.onCreateBill.bind(this);
        this.contextMenuWillAppear = this.contextMenuWillAppear.bind(this);
        this.contextMenuDismissed = this.contextMenuDismissed.bind(this);
        this.contextMenuOptClick = this.contextMenuOptClick.bind(this);
        this.editBillAction = this.editBillAction.bind(this);
        this.willHandleTableRowMouseOver = this.willHandleTableRowMouseOver.bind(this);

        !this.props.viewInit && window.addEventListener('REACT_CONTEXTMENU_SHOW', this.contextMenuWillAppear);
        !this.props.viewInit && window.addEventListener('REACT_CONTEXTMENU_HIDE', this.contextMenuDismissed);
        
    }

    componentDidMount() {
        this.props.onLoad();
        const contentEl =  document.querySelector('.spmt-Ly-ct');
        contentEl.addEventListener('scroll', this.handleScroll);
        //document.body.classList.add('diable-scroll');  
    }

    static getDerivedStateFromProps(props, state) {
        // Return null to indicate no change to state.
        return null;
    }
    
    componentWillUnmount() {
        window.removeEventListener('REACT_CONTEXTMENU_SHOW', this.contextMenuWillAppear);
        window.removeEventListener('REACT_CONTEXTMENU_HIDE', this.contextMenuDismissed);
        const contentEl =  document.querySelector('.spmt-Ly-ct');
        contentEl.removeEventListener('scroll', this.handleScroll);
        this.props.unLoad();
    }


    willHandleTableRowMouseOver(rowData) {
        (this.props.rowBillContext === undefined || !this.props.rowBillContext) && this.props.rowDidMouseOver(rowData);
    }

    /* Context Menu Actions */
    editBillAction(evt) {
        this.handleBillClick(this.props.rowBill);
    }
    /* ----End of Context Menu Actions--- */

    contextMenuWillAppear(evt) {
        console.log(evt);
        //this.props.contextMenuEvent(true);
    }
    contextMenuDismissed(evt) {
        //this.props.contextMenuEvent(false);
    }
    
    handleScroll(event) {
        return;
        //let scrollTop = event.srcElement.body.scrollTop,
        const workspaceBar = document.querySelector('.workspace-bar');
        let barHeight = workspaceBar ? workspaceBar.offsetHeight: 0; //Math.min(0, scrollTop/3 - 60);
        
        if (this.state.activeView !== 'overview' 
        && this.state.activeView !== 'recent' && this.state.activeView !== 'authorize')
            return;

        let scrollTop = event.srcElement.scrollTop;
        let scollElementPosY = (offset(event.target).top  ) - 3 ;
        //console.log(scrollTop);
    
        const container = document.getElementById('mainTable');
        //.querySelector('table.table-responsive');
        const tableHead = document.querySelector('table#mainTable thead');
        const scrollYPos = container.getBoundingClientRect().top - scollElementPosY;

        scrollYPos <= -1 ? tableHead.classList.add('detach'): tableHead.classList.remove('detach');
        //const docElementHeight = document.documentElement.scrollHeight;
        const scrollElementHeight = Math.round(event.srcElement.scrollHeight + scollElementPosY);

        if ((scrollTop  + window.innerHeight + barHeight) >= scrollElementHeight) {
            console.log("you're at the bottom of the page");
        }
        /*if ((window.scrollY + window.innerHeight) >= (docElementHeight)) {
            console.log("you're at the bottom of the page");
        }*/
        //console(scrollTop);
    }

    switchView(evt) {
        evt.preventDefault();
        const view = evt.target.getAttribute("to");

        if (this.state.activeView !== view) {
            this.setState({ activeView: view });
        }
    }

    contextMenuOptClick(evt, data) {
        switch(data.opt){
            case 'edit':
                return this.editBillAction(evt);
            case 'resultSheet':
                return this.addResultSheetAction(evt);
            case 'changeStatus':
                return this.handlerChangeStatusAction(evt, data);
            default:
                return this.editBillAction(evt);
        }
    }

    handleBillClick = (data) => {
        this.props.setMode(ACTION_BAR_MODES.edit);
        this.props.setUpdateBillData({bill: data});
        if (this.props.useClientLayout) {
            this.props.history.push(urls.billViewUri);
        }else {
            this.props.history.push(urls.updateBillUri);
        }
    }
    
    onCreateBill(evt) {
        this.props.history.push(urls.createBillUri);
    }

    render () {
        const specimenSupervisor = this.props.specimenSupervisor;
        specimenSupervisor.specimen = this.props.rowSpeciment;

        const quickActionCallback = {
            'editAction' : this.editBillAction,
        };
        
        const contextMenuOptClick = this.contextMenuOptClick;

        const permissionEnforcer = PermissionEnforcer.getInstance(PermissionEnforcer);
        const clientLayoutClass = this.props.useClientLayout ? '--client-view' : '';

        const props = {
            ...this.props,
            willHandleTableRowMouseOver: this.willHandleTableRowMouseOver,
            quickActionCallback: quickActionCallback
        };

        const billsInsightSummary = {
            ...this.props.billsInsight
        };

        return (
            <div className='content-layout-wrap specimen-list'>
               <div className="sidebar">
                    <div className="button-wrap">
                        <Authority  allowed="create" entity="bill" >
                            <Button className="default-btn add-btn" onClick={this.onCreateBill}>
                                <span className="icon"><img src={getIconUrl("bill")} alt='' /></span>
                                Create Bill
                            </Button>
                        </Authority>
                    </div>
                    <div className="nav-container">
                        
                        <nav className="sidebar-nav">
                           
                        <Nav>
                            <NavItem>
                               <NavLink to={"overview"} onClick={this.switchView} className={classnames({ active: this.state.activeView === 'overview' })}><span className="icon"><img src={getIconUrl("bills")} alt=''/></span>Bills</NavLink>
                            </NavItem>
                            { !this.props.useClientLayout &&
                            <React.Fragment>
                            <NavItem>
                                <NavLink to={"billable"} onClick={this.switchView} className={classnames({ active: this.state.activeView === 'billable' }) } ><span className="icon"><img src={getIconUrl("recent")} alt=''/></span>Billable Samples</NavLink>
                            </NavItem>
                            </React.Fragment>
                            }
                            <NavItem>
                                <NavLink to={"paid"} onClick={this.switchView} className={classnames({ active: this.state.activeView === 'paid' })}><span className="icon"><img src={getIconUrl("results")} alt=''/></span>Paid</NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink to={"unpaid"} onClick={this.switchView} className={classnames({ active: this.state.activeView === 'unpaid' })}><span className="icon"><img src={getIconUrl("unpaid")} alt=''/></span>Unpaid</NavLink>
                            </NavItem>
                        </Nav>
                        
                        </nav>
                    </div>
               </div>
               <div className="v-line"></div>
               <div className="view-layout-content container">
                   <div className="spmt-Ly-ct">
                        <div className={`bill-summary-overview ${clientLayoutClass}`}>
                            <h2 className="title">{this.titles[this.state.activeView]} 
                                {this.state.activeView === 'overview' && billsInsightSummary.quarter > 0 ? <span className='quarter-badge'>Q{billsInsightSummary.quarter}</span>: ''}
                            </h2>
                            <div className="bill-summary">
                                { (!this.props.useClientLayout && this.state.activeView === this.views.overview) &&
                                <div className="activity --unbilled">
                                    <div className="label-box" >
                                        <div className="lbl left">
                                        <label>Bills {billsInsightSummary?.totalBillsCount}</label>
                                        </div>
                                        <div className="lbl right">
                                            <label>Last {billsInsightSummary?.days}</label>
                                        </div>
                                    </div>
                                    <div className="box">
                                    <div className="grp estimate">
                                    <p className="am">${billsInsightSummary?.totalRevenue?.toCurrency() || 0}</p>
                                        <span className="label">Estimate Revenue</span>
                                    </div>
                                    <div className="grp unbilled">
                                        <p className="am">${billsInsightSummary?.draftedBills?.toCurrency() || 0}</p>
                                            <span className="label">{billsInsightSummary?.totalDraftedBillsCount || 0} Drafted bills</span>

                                    </div>
                                    </div>
                                </div>
                                }
                                { this.state.activeView === this.views.overview &&
                                <div className="activity --unpaid">
                                     <label>Q{billsInsightSummary?.quarter || 0} Unpaid</label>
                                    <div className="box">
                                    
                                    <div className="grp overdue">
                                        <p className="am">${billsInsightSummary?.outStandingBills?.toCurrency() || 0}</p>
                                        <span className="label">{billsInsightSummary?.totalOverdueCount || 0} Overdue</span>

                                    </div>
                                    <div className="grp openbills">
                                        <p className="am">${billsInsightSummary?.uncollectedBills?.toCurrency() || 0}</p>
                                        <span className="label">{billsInsightSummary?.totalUncollectedBillsCount || 0} Uncollected</span>

                                    </div>
                                    </div>
                                </div>
                                }
                                { this.state.activeView === this.views.overview &&
                                <div className="activity --paid">
                                    <label>Paid</label>
                                    <div className="box">
                                    <div className="grp paid">
                                        <p className="am">${billsInsightSummary?.totalPaidBills?.toCurrency() || 0}</p>
                                        <span className="label">{billsInsightSummary?.totalPaidBillsCount || 0} Paid for Q{billsInsightSummary?.quarter || 0}</span>

                                    </div>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>

                        <div className="table-container billing-table-coontainer">
                            { this.state.activeView === this.views.overview && <Overview {...props} />}

                            { this.state.activeView === this.views.paid && <PaidBillView {...props} />}

                            { this.state.activeView === this.views.unpaid && <UnpaidBillView {...props} />}

                            { this.state.activeView === this.views.billable && <BillableView {...props} />}
                        </div>
                   </div>

                    <ContextMenu ref={this.contextMenuRef}id="bills_table_context_menu">
                        <MenuItem data={{opt: 'view'}} onClick={contextMenuOptClick}>
                            View Details
                        </MenuItem>
                        <MenuItem data={{opt: 'edit'}} onClick={contextMenuOptClick}>
                            Edit
                        </MenuItem>
                        <MenuItem divider />
                        <MenuItem data={{opt: 'twoFactorAuth'}} onClick={contextMenuOptClick}>
                            Two factor Authentication
                        </MenuItem>
                        <MenuItem data={{opt: 'blocked'}} onClick={contextMenuOptClick}>
                            Blocked
                        </MenuItem>
                        <MenuItem data={{opt: 'delete'}} onClick={contextMenuOptClick}>
                            Delete
                        </MenuItem>
                    </ContextMenu>
               </div>
            </div>
        );
    }
}

const BillingView = (props) => {
    return (
        <Billing {...props } />
    );
};

let ConfigBillingView = setupPage(Billing.pageOption)(BillingView);
ConfigBillingView =  connect(mapStateToProps, mapDispatchToProps)(ConfigBillingView);

export { ConfigBillingView };