import React from 'react';
import { connect } from 'react-redux';
import { setupPage } from '../../components/Layout/setupPage';
import {
	Button,
	Section,
	Authority
} from '../../components';

import { LogoThemed } from './../components/partials/ThemeLogo';
import './Receipt.style.scss';
import  * as actions  from '../../store/actions';
import * as actionTypes from '../../store/actionType';
import  { PermissionEnforcer }  from '../../utility/PermissionEnforcer';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
import { PAYMENT_TYPE, REQUESTS_TYPE } from '../../common/constant';
import { Loader } from '../components/partials/LoadingIndicator';
import { PromptMessage, getPromptMessageProps } from '../components/partials/PromptMessage';

const mapStateToProps = state => ({
	apiRequestManager: ApiRequestManager.getInstance(ApiRequestManager),
    ...state.payment,
	clientDashboard: state.global.useClientDashboard
});

const mapDispatchToProps = dispatch => ({
	unLoad: () => dispatch(actions.paymentModelViewUnloadAction()),
	submitData: (data) => dispatch(actions.submitPaymentAction(data)),
});

class Receipt extends React.Component {

  	static pageOption = { pageTitle: 'Client' };

	constructor(props) {
		super(props);

		props.apiRequestManager.register(actionTypes.PAYMENT_SUBMIT_DATA, props.submitData);

		this.state = {
			isProcessingPayment: false,
		};

		this.submitFormData = this.submitFormData.bind(this);
		this.getDialogMessage = this.getDialogMessage.bind(this);
		this.syncProgress = this.syncProgress.bind(this);
	}

	componentDidMount() {
		document.body.classList.add('payment-modal');
	}

	componentWillUnmount() {
		document.body.classList.remove('payment-modal');
		this.props.pageConfig.disableBodyScroll(true);
		this.props.viewContext === 'overview' && this.props.pageConfig.useGrayBackground(true);
		this.props.unLoad();
	}

	componentDidUpdate(){
		this.syncProgress(this.props.apiRequestManager.inProgress(actionTypes.PAYMENT_SUBMIT_DATA), this.state.isProcessingPayment, (globalProgressValue) => this.setState({isProcessingPayment: globalProgressValue}));
	}

	syncProgress(globalProgressBool, localProgressBool, updateLocalProgressFn){
		if (typeof updateLocalProgressFn !== 'function') throw new Error(`updateLocalProgressFn must be a function, not of type ${typeof updateLocalProgressFn}`);

		if (globalProgressBool !== localProgressBool) {
			if (globalProgressBool) {
				updateLocalProgressFn(globalProgressBool);
			}else {
				setTimeout(() => {
					updateLocalProgressFn(globalProgressBool);
				}, 1000);
			}
		}
	}

	submitFormData(evt) {
		let data = {
			requestType: REQUESTS_TYPE.UPDATE ,
			paymentId: this.props.payment.id
    	}

		if (this.props.payment) {
				this.props.apiRequestManager.queueRequest(actionTypes.PAYMENT_SUBMIT_DATA, data);
		}
	}

	getDialogMessage() {
		const apiOutcome = this.props.apiRequestManager.getApiOutcome(actionTypes.PAYMENT_SUBMIT_DATA, true) ?? {};
		return getPromptMessageProps('payment', apiOutcome);
	}

	render () {
		
		if (!this.props.payment) return null;
		const dialogProps = this.getDialogMessage();

		const payment = this.props.payment;

		return (
			<React.Fragment>
				<div id="receipt-POS">
					<div className='payment__dialog-container'>
						{ dialogProps.visible && 
							<PromptMessage {...dialogProps} />
						} 
					</div>
					<div id="inner">
						<div className="date-bar">
							<div className="left">No. {payment.paymentNo}</div>
							<div className="right">
								<p>{payment.date}</p>
								<p className="state">{payment.state}</p>
							</div>
						</div>
						<center id="top">
							<div className="logo">
								<LogoThemed  className="logo" style="virtial" height="80" />
							</div>
							<div className="info"> 
								<h2>Cytolab Inc</h2>
							</div>
						</center>
						<div className="perforation"></div>
						<Section id="mid" >
							<div className="customer info">
								<h2>{payment.account.clientInfo.firstName} {payment.account.clientInfo.lastName}</h2>
								<div className="info-line"> 
									<p>Acct #: {payment.account.accountNo}</p>
									<p>Email: {payment.account.clientInfo.email}</p>
									<p>Payment Tems: {payment.account.billCycle}</p>
								</div>
							</div>

							<div className="payment-type info">
								<h2>Payment by</h2>
								<div className="info-line"> 
									<p>Method: {payment.type} </p>
									{ payment.type === PAYMENT_TYPE.Cheque &&
									<React.Fragment>
										<p>Bank: {payment.bank}</p>
										<p>Cheque #: {payment.chequeNumber}</p>
									</React.Fragment>
									}
								</div>
							</div>
						</Section>
						<Section id="bot">
							<div id="table">
								{ !payment.paymentLines.empty() &&
									<table>
										<thead>
											<tr className="tabletitle">
												<th className="item"><h2>Bill</h2></th>
												<th className="date"><h2>Date</h2></th>
												<th className="amount"><h2>Amount</h2></th>
											</tr>
										</thead>
										<tbody>
											
											{  payment.paymentLines.map((line, index) => 
												<tr key={index} className="service">
													<td className="tableitem item"><p className="itemtext"># {line.bill.referenceNo}</p></td>
													<td className="tableitem date"><p className="itemtext">{line.datePaid}</p></td>
													<td className="tableitem amount"><p className="itemtext">${line.amount.toCurrency()}</p></td>
												</tr>
										)}

										</tbody>
									</table>
								}
							</div>
							<div id="total" className="tabletitle">
								<div className="label">Total</div>
								<div className="payment">${payment.amount.toCurrency()}</div>
							</div>
							<div id="legalcopy">
								<p className="legal"><strong>Thank you for your business!</strong>  Payment is expected within 31 days; please process this invoice within that time. There will be a 5% interest charge per month on late invoices. 
								</p>
							</div>

						</Section>
						<Section className="actions-sec">
							<div className="btn-group">
								{ (payment && !payment.isVerified && !this.props.clientDashboard) &&
									<Authority allowed='change' entity='payment'>
										{
											this.state.isProcessingPayment ? <Loader visible={this.state.isProcessingPayment} /> : null
										}
										<Button 
										onClick={this.submitFormData}
										disabled={this.state.isProcessingPayment}
										className="pay-btn">
											<span	span className="icon"></span>Verify
										</Button>
									</Authority>
								}
								{ (payment && !payment.isVerified && this.props.clientDashboard) &&
									<span>Payment is Unverified</span>
								}
							</div>
						</Section>
					</div>
				</div>

			</React.Fragment>
		);
	}
}

const ReceiptView = (props) => {
    return (
        <Receipt {...props } />
    );
};

let ConfigReceiptView = setupPage(Receipt.pageOption)(ReceiptView);
ConfigReceiptView =  connect(mapStateToProps, mapDispatchToProps)(ConfigReceiptView);

export { ConfigReceiptView };