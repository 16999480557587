import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { setupPage } from '../../components/Layout/setupPage';
import { 
    Row,
    Col,
    List,
    Nav,
    Section,
    NavItem,
    TabContent,
    TabPane,
    NavLink,
    SpecimenTable,
    Label,
    Authority
} from '../../components';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
import ActionBar from '../components/partials/ActionBar';
import  * as actions  from '../../store/actions';
import  * as actionTypes  from '../../store/actionType';
import * as urls from './../../config/urls';
import {Loader } from '../components/partials/LoadingIndicator';
import { FEATURE_FLAG, ACTION_BAR_MODES, STATUS } from "../../common/constant";
import  FEATURES  from "../../config/featureFlags";
import './Patient.style.scss';
import { getIconUrl } from '../../common/images-catalogue';
import {calculatePercent} from '../../utility/helpers';
import { PAGE_SIZE } from '../../config/pagination';

const mapStateToProps = state => ({
    apiRequestManager: ApiRequestManager.getInstance(ApiRequestManager),
    ...state.patient,
    patient: state.patient.currentPatient,
    account: state.global.account,
    useClientDashboard: state.global.useClientDashboard,
    actionBarMode: state.global.actionBarMode,
    rowSpecimen: state.specimen.samplesState.rowSpecimen,
});

const mapDispatchToProps = dispatch => ({
    redirectTo: (to) => dispatch(actions.redirectToAction(to)),
    getPatientRecords: (payload) => dispatch(actions.getPatientSpecimenRecordsAction(payload)),
    getPatient: (paitentId) => dispatch(actions.getPatientAction(paitentId)),
    setMode: (mode) => dispatch(actions.actionBarModeAction(mode)),
    handleRowHover: (contextData) => dispatch(actions.tableRowMouseOverAction(contextData)),
    setUpdateSpecimenData: (data) => dispatch(actions.setUpdateSpecimenDataAction(data)),
    setSpecimenPatientLink: (value) => dispatch(actions.setSpecimenPatientLink(value)),
});

class Overview extends React.Component {

    static pageOption = { 
        pageTitle: 'Overview',
        disableBodyScroll: true,
        searchContext: 'Patient'
        //grayBackground: true
    };

    constructor(props) {
        super(props);

        props.apiRequestManager.register(actionTypes.FETCH_PATIENT_SPECIMENS_HISTORY, props.getPatientRecords);
        props.apiRequestManager.register(actionTypes.FETCH_PATIENT, props.getPatient);

        this.state = {
            activeTab: '1',
            editSpecimenClicked: null,
            pageSize: PAGE_SIZE,
            currentPage: 0
        };
        
        const idPresent = this.props.match.params.id;

        (!this.props.patient && !idPresent) && this.props.redirectTo(urls.patientsUri);
        this.willHandleRecordRowMouseOver = this.willHandleRecordRowMouseOver.bind(this);
        this.backHandler = this.backHandler.bind(this);
        this.onEditHandler = this.onEditHandler.bind(this);
        this.handleSpecimenEditClick = this.handleSpecimenEditClick.bind(this);
        this.handleTableChange = this.handleTableChange.bind(this);

        if (idPresent) {
            this.props.setMode('view');
            props.apiRequestManager.queueRequest(actionTypes.FETCH_PATIENT, idPresent);
        }
    }

    componentDidMount() { }

    static getDerivedStateFromProps(props, state) {
        // Return null to indicate no change to state.
        console.log(props.patient && !props.recordsList && Object.blank(props.account) && !props.apiRequestManager.inProgress(actionTypes.FETCH_PATIENT_SPECIMENS_HISTORY))
        if ( props.patient && !props.recordsList && Object.blank(props.account) && !props.apiRequestManager.inProgress(actionTypes.FETCH_PATIENT_SPECIMENS_HISTORY) ) {
            const requestData = {
                patientId: props.patient.id,
                page: state.currentPage,
                pageSize: state.pageSize
            };
            props.apiRequestManager.queueRequest(actionTypes.FETCH_PATIENT_SPECIMENS_HISTORY, requestData);
        }

        if ( props.patient && !props.recordsList && !Object.blank(props.account) && !props.apiRequestManager.inProgress(actionTypes.FETCH_PATIENT_SPECIMENS_HISTORY) ) {
            const requestData = {
                patientId: props.patient.id,
                client: props.account.owner,
                page: state.currentPage,
                pageSize: state.pageSize
            };
            props.apiRequestManager.queueRequest(actionTypes.FETCH_PATIENT_SPECIMENS_HISTORY, requestData);
        }
        return null;
    }

    componentWillUnmount(){
        /**
         * if unmounting for any reason other than to go to the specimen form.
         */
        if(this.state.editSpecimenClicked === false){
            this.props.setSpecimenPatientLink(false);
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({ activeTab: tab });
        }
    }

    willHandleRecordRowMouseOver(rowData) {
        // (this.props.rowSpecimenContext === undefined || !this.props.rowSpecimenContext) && this.props.rowDidMouseOver(rowData);
        this.props.handleRowHover(rowData);
    }

    onEditHandler() {
        this.props.history.push(urls.patientUpdateUri);
    }
    
    backHandler() {
        this.props.history.push(urls.patientsUri);
    }

    handleSpecimenEditClick(event){
        this.props.setMode(ACTION_BAR_MODES.edit);
        this.props.setUpdateSpecimenData({specimen: this.props.rowSpecimen});
        this.props.setSpecimenPatientLink(true);
        this.setState({editSpecimenClicked: true});
        /**
         * need to delay the change in url so the local state can be updated.
         * This is to determine whether or not to clear patientLink.
         */
        setTimeout(() => this.props.history.push(urls.specimensUpdateUri), 0);
    }

    getRecordStats( recordsData) {
        let processRecordsCount = 0;
        let unprocessRecordsCount = 0;

        recordsData.forEach((item) => {
            if (item.statuses.last().status !== STATUS.Approved) {
                unprocessRecordsCount = unprocessRecordsCount + 1;
            }
            if (item.statuses.last().status === STATUS.Approved) {
                processRecordsCount = processRecordsCount + 1;
            }
        });

        return {
            processRecordsCount,
            unprocessRecordsCount,
            processRatio: calculatePercent(processRecordsCount, recordsData.length)
        }
    }

    handleTableChange(tableOpt) {
        const pageNumber = !tableOpt.page || tableOpt.page - 1;
        const pageSize = tableOpt.sizePerPage;

        const requestParams = {
            patientId: this.props.patient.id,
            client: this.props.account.owner,
            page: pageNumber,
            pageSize: pageSize
        };

        this.props.apiRequestManager.queueRequest(actionTypes.FETCH_PATIENT_SPECIMENS_HISTORY, requestParams);

        this.setState({
            pageSize: pageSize
        });
    }

    render () {
        
        const idPresent = this.props.match.params.id;

        if (!this.props.patient && idPresent) {
            return <div className="client-loader"><Loader  visible={true} loadingText={'Please wait...'} /></div>;
        }
        const actionBarCallback = {
            'edit': this.onEditHandler,
            'back': this.backHandler,
         };
    
        const  actionBarMode = this.props.actionBarMode;
        const  saveSufixText = actionBarMode === 'edit'? '' : 'Patient';
        const patientPicture = this.props.patient && this.props.patient?.picture ? this.props.patient?.picture.fileDownloadUri : getIconUrl('profile');
        const patient = this.props.patient || {};

        const quickActionCallback = {
            editAction: this.handleSpecimenEditClick
        }

        let {pageData, ...tableMetadata} = this.props.recordsList || [];
        
        const recordsTableData = Array.isArray(pageData) ? pageData : [];

        const recentRecords =  recordsTableData.length > 0 ? recordsTableData.slice(0, 4) : [];

        const recordsStats = this.getRecordStats(recordsTableData);

        tableMetadata = {
            ...tableMetadata,
            remote: true,
            pageSize: this.state.pageSize
        };
        
        return (
            <React.Fragment>
            <ActionBar title={ "Patient" } saveTextSufix={ saveSufixText } entityContext={'patient'} actionResponsers={ actionBarCallback } permissionContext={ actionBarMode } />
            <div className='content-layout-wrap client-list'>
                <div className="view-layout-content">
                   <div className="default-Ly-ct">
                       <div className="overview-containter">
                           <Row>
                               <Col sm={12} lg={4} className="side-content">
                                   <div className="details-sec-container">
                                        <Section className="pat-details">
                                            <div className="patient-card">
                                                <div className="inner">
                                                    <div className="main-info">
                                                        <div className="picture">
                                                            <img src={patientPicture} alt='' />
                                                        </div>
                                                        <h3 className="name">{patient.firstName} {patient.lastName}</h3>
                                                        <p className="info">{patient.gender}, Age: {patient?.age || '-'}</p>
                                                    </div>
                                                    <div className="details">
                                                        <div className="sec personal">
                                                            <p className="info-line"><Label>Reg. No</Label><span>{patient.registrationNo}</span></p>
                                                            <p className="info-line"><Label>DOB</Label><span>{patient?.dateOfBirth || '-'}</span></p>
                                                            <p className="info-line"><Label>Age</Label>
                                                            <span className='age-info'>{patient.ageDetails?.years || '0'}<i>years</i></span>
                                                            <span  className='age-info'>{patient.ageDetails?.months || '0'}<i>months</i></span>
                                                            <span  className='age-info'>{patient.ageDetails?.days || '0'}<i>days</i></span>
                                                            </p>
                                                            <p className="info-line"><Label>Age Group</Label><span>{patient.ageDetails?.maturity}</span></p>
                                                            <p className="info-line"><Label>Phone</Label><span>{patient.phoneNumber || '--'}</span></p>
                                                            <p className="info-line"><Label>Email</Label><span>{patient.email}</span></p>
                                                        </div>
                                                        <div className="sec attributes">
                                                        <p className="info-line"><Label>Height</Label><span className="attr">{patient.height}m</span></p>
                                                        <p className="info-line"><Label>Weight</Label><span className="attr">{patient.weight}lbs</span></p>
                                                        <p className="info-line"><Label>Blood Group</Label><span className="attr">{patient.bloodGroup || '--'}</span></p>
                                                        <p className="info-line"><Label>Date Registered</Label><span>{patient.dateRegistered}</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Section>
                                        { patient.address && 
                                        <Section className="address">
                                            <div className="address-card">
                                                <Label>
                                                    Address
                                                </Label>
                                                <address >
                                                <p>Street: <span>{patient.address.street}</span></p>
                                                <p>Address Line 2: <span>{patient.address.streetTwo}</span></p>
                                                <p>City: <span>{patient.address.city}</span></p>
                                                <p>Parish: <span>{patient.address.state}, {patient.address.postalCode}</span></p>
                                                <p>Country: <span>{patient.address.country}</span></p>
                                                </address>
                                            </div>
                                            
                                        </Section>
                                        }
                                   </div>
                               </Col>
                               <Col sm={12} lg={8} className="main-content">
                                   <div className="tab-content-area">
                                       <div className="tab-nav">
                                        <Nav tabs>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: this.state.activeTab === '1' })}
                                                    onClick={() => { this.toggle('1'); }}
                                                >
                                                Overview
                                                </NavLink>
                                            </NavItem>
                                            { FEATURES.Appointment === FEATURE_FLAG.ON && 
                                            <Authority allowed="view" entity="appointment">
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: this.state.activeTab === '2' })}
                                                    onClick={() => { this.toggle('2'); }}
                                                >
                                                Appointments
                                                </NavLink>
                                            </NavItem>
                                            </ Authority>
                                            }
                                            <Authority allowed="view" entity="record">
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: this.state.activeTab === '3' })}
                                                    onClick={() => { this.toggle('3'); }}
                                                >
                                                Records
                                                </NavLink>
                                            </NavItem>
                                            </ Authority>
                                        </Nav>
                                        </div>
                                        <div className="content-pane">
                                        <TabContent activeTab={this.state.activeTab}>
                                            <TabPane tabId='1'>
                                                <Section className="bill-section">
                                                    <div className="ratio-widget">
                                                    <div className="ratio-holder">
                                                        <div className="ratio-count">
                                                            <span className="left-count">{recordsStats.processRecordsCount} Samples to date</span>
                                                            <span className="right-count">{recordsStats.unprocessRecordsCount}</span>
                                                        </div>
                                                        <div className="ratio-line">
                                                            <div className="line-bar">
                                                            <div className="inner-bar" 
                                                            style={{
                                                                width: `${recordsStats.processRatio}%`
                                                            }}></div>
                                                            </div>
                                                        </div>
                                                        <div className="bar-label">
                                                            <span>Processed</span>
                                                            <span>Incomplete</span>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </Section>
                                                <Section className="apptm-section">
                                                    <h2 className="">Doctors and Labs {patient.firstName}'s is registered with.</h2>
                                                    <div className="sec-content">
                                                        <List className="apptmt-list">
                                                            { patient?.clients.map((client, i) => {
                                                                const firstClient = i === 0 ? '--confirm' : '' ; 
                                                                
                                                                const name = !client.officeName.empty() ? client.officeName : `${client.firtName} ${client.lastName}`;

                                                            return (
                                                            <li key={client.id} className={`apptmt-item-box ${firstClient}`}>
                                                                <div className="apptmt-item">
                                                                    <span className="status-bar"></span>
                                                                    <div className="apptmt-cont">
                                                                        <span className="status">{name}</span>
                                                                        <span className="date">{client.accountNo}</span>
                                                                    </div>
                                                                </div>
                                                            </li>)})
                                                            }
                                                        </List>
                                                    </div>
                                                </Section>
                                                <Section className="rec-section">
                                                    <h2 className="">Recent Records</h2>
                                                    <div className="sec-content">
                                                    <List className="rect-rec-list">
                                                        { recentRecords.map((item) => 
                                                        <li key={item.id} className="rec-item">
                                                            <div className="record">
                                                            <div className="picture-holder">
                                                                <div className="picture-content">
                                                                    {item.client.firstName[0]}{item.client.lastName[0]}
                                                                </div>
                                                            </div>
                                                                <div className="doctor-info">
                                                                    <p>{item.client.firstName} {item.client.lastName}</p>
                                                                </div>
                                                                <div className="details">
                                                                    <p>{item.formType} ({item.identifier})</p>
                                                                </div>
                                                                <div className="status">
                                                                    <p>{item.statuses[item.statuses.length - 1].status}</p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        ) }
                                                    </List>
                                                    </div>
                                                </Section>
                                            </TabPane>
                                            <TabPane tabId='2'>
                                                <Section>
                                                    Appointments
                                                </Section>
                                            </TabPane>
                                            <Authority allowed="view" entity="record">
                                                <TabPane tabId='3'>
                                                    <Section>
                                                    <div className='table'>
                                                        <SpecimenTable 
                                                        ref={this.patientsTable}
                                                        hoverOverRowAction={this.willHandleRecordRowMouseOver}
                                                        quickActions={quickActionCallback} 
                                                        tableData={recordsTableData}
                                                        pagable={true}
                                                        metaData={tableMetadata}
                                                        onTableChange={this.handleTableChange}
                                                        />
                                                    </div>
                                                    </Section>
                                                </TabPane>
                                            </Authority>
                                        </TabContent>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                   </div>
                </div>
            </div>
            </React.Fragment>
        );
    }
}

const OverviewView= (props) => {
    return (
        <Overview {...props } />
    );
};

let ConfigOverviewView = setupPage(Overview.pageOption)(OverviewView);
ConfigOverviewView =  connect(mapStateToProps, mapDispatchToProps)(ConfigOverviewView);

export { ConfigOverviewView };