import React from 'react';
import { connect } from 'react-redux';
import { setupPage } from '../../components/Layout/setupPage';
import { 
    Container,
    Row,
    Col,
    Button,
    Section,
    PayrollTable,
    NavLink,
    Authority
} from '../../components';

import * as actionTypes from '../../store/actionType';
import  * as actions  from '../../store/actions';
import * as urls from '../../config/urls';
import  { PermissionEnforcer }  from '../../utility/PermissionEnforcer';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
import { formatDate } from '../../utility/helpers';

import './Payroll.style.scss';
import { getIconUrl } from '../../common/images-catalogue';


const mapStateToProps = state => ({
    apiRequestManager: ApiRequestManager.getInstance(ApiRequestManager),
    useClientLayout: state.global.useClientDashboard,
    mode:  state.global.actionBarMode,
    ...state.payroll
});

const mapDispatchToProps = dispatch => ({
    onLoad: () => dispatch(actions.payrollViewLoadedAction()),
    unLoad: () => dispatch(actions.payrollViewUnloadAction()),
    getPayrolls: () => dispatch(actions.getAllPayrollsAction()),
    setPayroll: (payroll) => dispatch(actions.setPayrollDataAction(payroll)) 
});


const RecentPayroll = (props) => {
    const {payrollData, handleShowSummary} = props;
    const linkText = payrollData.isApproved ? 'View Details' : 'Awaiting Approval';
    return (
        <div className="last-payroll">
            <div className="jouranl-item">
                <div className="details">
                    <h2>Payroll ran on, ({payrollData.payrollDate})</h2>
                    <h3 className="amt-info">Payout Total: ${payrollData.totals.grossPayoutAmount.toCurrency()}</h3>
                    <p className="pr-info">{payrollData.payees.length} employees where  paid, NET Payout Cash <b>${payrollData.totals.netPayoutAmount.toCurrency()}</b></p>
                    <p className="pr-info"></p>
                </div>
                 <div>
                    <NavLink to={''} onClick={(evt) => handleShowSummary(payrollData)} className="arrow-link"><span className="arrow-indicator"></span>{linkText}</NavLink>
                </div>
            </div>
        </div>
    );
}

class Payroll extends React.Component {

    static pageOption = { 
        pageTitle: 'Pay Slips',
        disableBodyScroll: true
    };

    constructor(props) {
        super(props);
        this.state = {
           
        };
        props.apiRequestManager.register(actionTypes.FETCH_PAYROLLS, props.getPayrolls);
        this.runPayrollWizard = this.runPayrollWizard.bind(this);
        this.onShowSummary = this.onShowSummary.bind(this);
    }

    componentDidMount() {}

    componentWillUnmount() { 
        //this.props.unLoad();
    }

    static getDerivedStateFromProps(props, state) {
        if (props.payrolls === null && !props.apiRequestManager.inProgress(actionTypes.FETCH_PAYROLLS)) {
            props.apiRequestManager.queueRequest(actionTypes.FETCH_PAYROLLS);
        }
        return null;
    }

    runPayrollWizard(evt) {
        this.props.history.push(urls.payrollWizardUri);
    }

    onShowSummary(payroll) {
        this.props.setPayroll(payroll);
        ( payroll.isApproved ? this.props.history.push(urls.payrollDetailsUri) : this.props.history.push(urls.payrollWizardUri) );
    }

    render () {
        
        const tableData = this.props.payrolls ? this.props.payrolls : []; 
        const currentDate = formatDate(new Date());
        const recentPayroll = tableData.length > 0 ? tableData[0] : null;

        const actionHandlers = {
            handleShowSummary : this.onShowSummary
        };

        const canRunPayroll = recentPayroll  && recentPayroll.dateCrated === currentDate  ? false : true;
        const prefixTex = canRunPayroll ? 'Run Payroll for': 'Payroll already submited for';
        
        return (
            <React.Fragment>
            <div className='content-layout-wrap payroll-view'>
                <div className="view-layout-content">
                   <div className="default-Ly-ct">
                       <Container className="payroll-overview">
                           <Row>
                            <Col>
                           <div className="title-box">
                               <h1 className="title">Payroll Center</h1>
                           </div>
                           <Section className="start-payroll">
                                <div className="start-conent">
                                    <div className="icon">
                                        <img src={getIconUrl('payroll')} alt='' />
                                    </div>
                                    <h1>
                                       {prefixTex} ({currentDate})
                                    </h1>
                                    <p>Please run payroll by 6:00pm EST on Wednesday, April 28th to pay your employees for their hard work. They’ll receive their funds on Friday, April 30th.</p>
                                    { canRunPayroll && 
                                    <Authority allowed="create" entity="payroll">
                                        <Button className="run-btn" onClick={this.runPayrollWizard}>
                                            Run Salary Payroll
                                        </Button>
                                    </Authority>
                                    }
                                </div>
                           </Section>
                           <Section className="lastest-section">
                               <h2 className="label">Most Recent Payroll</h2>
                               { recentPayroll !== null ? 
                               <RecentPayroll 
                               payrollData={recentPayroll}
                               handleShowSummary={this.onShowSummary} />
                               : 
                                <div><p>No recent payroll found.</p></div>
                               }
                           </Section>
                           <Section className="payroll-journal">
                             <h2 className="label">Payroll Journal</h2>
                             <PayrollTable 
                                tableData={tableData}
                                quickActions={actionHandlers}
                                />
                           </Section>
                           </Col>
                           </Row>
                        </Container>
                   </div>
                </div>
            </div>
            </React.Fragment>
        );
    }
}

const PayrollView = (props) => {
    return (
        <Payroll {...props } />
    );
};

let ConfigPayrollView = setupPage(Payroll.pageOption)(PayrollView);
ConfigPayrollView =  connect(mapStateToProps, mapDispatchToProps)(ConfigPayrollView);

export { ConfigPayrollView };