import fetch from 'unfetch';
import { from as observable } from 'rxjs';
import NetworkStats from './../utility/NetworkStats';
import { apiSyncAction, apiSyncEndAction, apiSyncCompletedAction, apiOutcomeAction, networkStatusAction} from '../store/actions';
import { store } from '../store/store';
import { ApiRequestManager } from '../utility/ApiRequestManager';
import * as ENV from '../.env.json'
// Temporary
import { setToken as setJwttoken, setWorkspaceContextId } from './httpRequestDialect';

const baseUri = ENV.REACT_APP_API_URI;
export const apiServer = ENV.REACT_APP_API_SERVER;
const wsHost = ENV.REACT_APP_WS_HOST;
const API_ROOT = `${apiServer}${baseUri}`;

let jwtToken = null;
let workspaceId = null;

const timeout = 30000;
let timeoutId = null;

// process.env
let addHeaders = (headers) => (defaultHeader) => {
    return {
        ...defaultHeader,
        ...headers
    }
};

let tokenProvider = () => {
    if (jwtToken) {
        return  {
            'Authorization': `Bearer ${jwtToken}`,
            'Authorization-Context':`${workspaceId}`,
        };
    }else {
        return {};
    }
};

export const requestStart = () => store.dispatch(apiSyncAction()); 
export const requestEnded = (apiOutcome) => store.dispatch(apiSyncEndAction(apiOutcome)); 
export const apiRequestCompletedAction = () => store.dispatch(apiSyncCompletedAction());
export const apiResponseOutcome = (type, errorPayload) => store.dispatch(apiOutcomeAction(type, errorPayload));
export const dispatchNetworkStatusAction = (status) => store.dispatch(networkStatusAction(status));  

const responseBody = (response) => {
    return response.json();
}; 

export const rowGet = (url) => fetch(`${API_ROOT}${url}`, {
    method: 'GET',
}).then(responseBody);

const controller = new AbortController();

function timeoutHandler() {
    const apiRequestManager = ApiRequestManager.getInstance(ApiRequestManager);
    apiRequestManager.timeoutAboutRequest();
    controller.abort();
}

function timeoutFetch(url, options = {}) {
    /*
     * fetches a request like the normal fetch function 
     * but with a timeout argument added.
     */
       
    let timeout = options.timeout || 30000;
    let timeoutError = {
        ok: false,
        status: 408
    };
    return new Promise((resolve, reject) => {
    
        fetch(url, options).then(resolve, reject);
        setTimeout(reject.bind(null, timeoutError), timeout);
    });
  }

const getRequest = (url) => {
    //Start api request
    // Abort request using setTimeout
    setTimeout(timeoutHandler, timeout);

    let request = fetch(`${API_ROOT}${url}`, {
        method: 'GET',
        headers: addHeaders({
            'Content-Type': 'application/json'
        })(tokenProvider()),
        signal: controller.signal
    }).then(responseBody);
    return observable(request);
};



const postRequest = (url, body) => {
    //Start api request
    // Abort request using setTimeout
    setTimeout(timeoutHandler, timeout);
    let request = fetch(`${API_ROOT}${url}`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: addHeaders({
            'Content-Type': 'application/json'
        })(tokenProvider()),
        signal: controller.signal
    }).then(responseBody);

    return observable(request);
};

const putRequest = (url, body) => {
    //Start api request
    // Abort request using setTimeout
    setTimeout(timeoutHandler, timeout);
    let request = fetch(`${API_ROOT}${url}`, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: addHeaders({
            'Content-Type': 'application/json'
        })(tokenProvider()),
        signal: controller.signal
    }).then(responseBody);

    return observable(request);
};

const patchRequest = (url, body) => {
    //Start api request
    // Abort request using setTimeout
    setTimeout(timeoutHandler, timeout);
    let request = fetch(`${API_ROOT}${url}`, {
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: addHeaders({
            'Content-Type': 'application/json'
        })(tokenProvider()),
        signal: controller.signal
    }).then(responseBody);

    return observable(request);
};

const deleteRequest = (url, body) => {
    //Start api request
    // Abort request using setTimeout
    setTimeout(timeoutHandler, timeout);
    let request = fetch(`${API_ROOT}${url}`, {
        method: 'DELETE',
        body: JSON.stringify(body),
        headers: addHeaders({
            'Content-Type': 'application/json'
        })(tokenProvider()),
        signal: controller.signal
    }).then(responseBody);

    return observable(request);
};

export const requests = {
    get: (url, headers) => getRequest(url, headers),
    put: (url, body) => putRequest(url, body),
    post: (url, body) => postRequest(url, body),
    delete: (url, body) => deleteRequest(url, body),
    patch: (url, body) => patchRequest(url, body),
    formPost: (url, formData) => formPostRequest(url, formData),
    formPut: (url, formData) => formPutRequest(url, formData),
    rowGet:  rowGet
};

const authRequest = (url, body) => {
    //Start api request
    // Abort request using setTimeout
    setTimeout(timeoutHandler, timeout);
    let request = fetch(`${API_ROOT}${url}`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json'
        },
    }).then(responseBody);

    return observable(request);
};

const getAuthUserRequest = (url) => {
    //Start api request
    // Abort request using setTimeout
    setTimeout(timeoutHandler, timeout);
    let request = fetch(`${API_ROOT}${url}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    }).then(responseBody);

    return observable(request);
};

const formPutRequest = (url, data) => {
    //Start api request
    // Abort request using setTimeout
    setTimeout(timeoutHandler, timeout);
    let request = fetch(`${API_ROOT}${url}`, {
        method: 'PUT',
        body: data,
        headers: addHeaders({
            'http-equiv':'Content-Type',
            'content': 'multipart/form-data; charset=utf-8' //'application/x-www-form-urlencoded'
        })(tokenProvider()),
        signal: controller.signal
    }).then(responseBody);

    return observable(request);
};

const formPostRequest = (url, data) => {
    //Start api request
    // Abort request using setTimeout
    setTimeout(timeoutHandler, timeout);
    let request = fetch(`${API_ROOT}${url}`, {
        method: 'POST',
        body: data,
        headers: addHeaders({
            'http-equiv':'Content-Type',
            'content': 'multipart/form-data; charset=utf-8' //'application/'x-www-form-urlencoded'
        })(tokenProvider()),
        signal: controller.signal
    }).then(responseBody);

    return observable(request);
};

const downloadFileRequest = (url) => {
    //Start api request
    let request = fetch(`${API_ROOT}${url}`, {
        method: 'GET',
        headers: addHeaders({
           
        })(tokenProvider()),
    }).then(responseBody);

    return observable(request);
};

const refreshTokenRequest = (url) => {
    // Abort request using setTimeout
    setTimeout(timeoutHandler, timeout);
    let request = fetch(`${API_ROOT}${url}`, {
        method: 'GET',
        headers: addHeaders({
            'Content-Type': 'application/json',
            'X-Refresh-Token': 'grant-new'
        })(tokenProvider()),
        signal: controller.signal
    }).then(responseBody);
    return observable(request);
};

export const Auth = {
    login: (url, body) => authRequest(url, body),
    refreshToken: (url) => refreshTokenRequest(url),
    getAuthUser: (url) => getAuthUserRequest(url)
};

export const File = {
    upload: (url, data) => formPostRequest(url, data),
    download: (url , callback) => {
        //Parameter to be passed
        var data = 'reportid=R3823&isSQL=1&filter=[]';
        var xhr = new XMLHttpRequest();
        xhr.open("GET", `${API_ROOT}${url}`); //url.It can pdf file path
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        xhr.setRequestHeader('Authorization',`Bearer ${jwtToken}`);
        xhr.responseType = "blob";
        xhr.onload = function () {
            if (this.status === 200) {
                var blob = new Blob([xhr.response], { type: 'application/pdf;base64' });
                var url = URL.createObjectURL(blob);
                //window.open(url);
                callback(url);
            }
        };
        xhr.send();
    }
};

export const getToken = () => { return jwtToken; };
export const setToken = (token) => { jwtToken = token; setJwttoken(token); };
export const setWorkspaceId = (workspace) => { workspaceId = workspace; setWorkspaceContextId(workspace); };
export const getApiRoot = () => apiServer;
export const getWebSocketHost = () => wsHost;
export const getEnvironment = () => ENV.environment;