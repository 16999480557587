import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { 
    Line, 
    CartesianGrid, 
    XAxis, 
    YAxis, 
    Tooltip, 
    ResponsiveContainer,
    Legend, 
    LineChart,
    Dot
} from './../../../components/recharts';

import colors from './../../../assets/colors';

// eslint-disable-next-line react/prop-types
const generateDot = ({stroke, ...other}) => (
    <Dot
        { ...other }
        r={ 5 }
        fill={ stroke }
        stroke={ 'white'}
        strokeWidth={ 3 }
    />
);

const generateActiveDot = (props) => (
    <Dot
        { ...props }
        stroke={ 'white' }
        r={ 5 }
    />
);

const SimpleLineChart = ({height, className, data}) => {
    
    var dataArray = [];
    if ( !Object.blank(data) ) {
        var leadData = null;

        if (Object.keys(data.gynecologyData).length > Object.keys(data.nonGynecologyData).length) {
            leadData = data.gynecologyData;
        }else {
            leadData = data.nonGynecologyData;
        }

        dataArray = Object.keys(leadData).map((key) => {

            const item = {
                name: key,
                 ngyne: data.nonGynecologyData.hasOwnProperty(key)? data.nonGynecologyData[key] : 0 , 
                 gyne: data.gynecologyData.hasOwnProperty(key)? data.gynecologyData[key] : 0, 
                 amt: data.gynecologyData.hasOwnProperty(key) && data.nonGynecologyData.hasOwnProperty(key)? data.gynecologyData[key] > data.nonGynecologyData[key]?   data.gynecologyData[key] : data.nonGynecologyData[key] : 0 
            }
            return item;
        });
    }
    var height = 250;
    return(
    
    <ResponsiveContainer
        width='100%'
        className={ className }
        {...(!_.isUndefined(height) ? {
            height
        } : {
            aspect: 1 / 0.65
        })}
    >
        <LineChart data={dataArray}
            margin={{top: 0, right: 0, left: 0, bottom: 0}}>
           <XAxis dataKey="name" stroke="#808080" tick={{ fill: '#808080' }}/>
           <YAxis stroke="#808080" tick={{ fill: '#808080' }} />
           <CartesianGrid stroke="#eee"  strokeDasharray="6 6"/>
           <Tooltip/>
           <Legend layout="horizontal" verticalAlign="top" align="center" />
           <Line  dataKey="gyne" stroke={ '#30AA1F' } strokeWidth={3} dot={generateDot} />
           <Line  dataKey="ngyne" stroke={ '#41C489' } strokeWidth={3}  dot={generateDot}  />
      </LineChart>
    </ResponsiveContainer>
)};
SimpleLineChart.propTypes = {
    height: PropTypes.number,
    className: PropTypes.string
}

export { SimpleLineChart };
