import React from 'react';

const LoadingIndicator = (props) => {
    const { visible, white } = props;
    const whiteClassname = white ? 'white-bar' : '';
    const visibleClassname = visible ? '' : '--hidden';

    return (  ! visible ? null:  
            <div className={`progress-line ${whiteClassname} ${visibleClassname}`}>
                <div className="line"></div>
                <div className="subline inc"></div>
                <div className="subline dec"></div>
            </div>);
}

export const Loader = (props) => {
    const { visible, loadingText, fixed, inline } = props;
    const visibleClass = visible ? '--visible': '';
    const visibleFixed = fixed ? '--fixed' : inline ? '--inline' : '';
   
    return (  ! visible ? null:  
            <div className={`loader-wrap ${visibleClass} ${visibleFixed}`}>
                <div className="lds-roller">
                    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                </div>
                <p className="text">{loadingText}</p>
            </div>);
}

export default LoadingIndicator;