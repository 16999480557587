export default function composeDetailLine(lineLabel) {
  const dataObj = {
    lineLabel: lineLabel,
    storedValue: '',
    fieldValue: '',
    fieldType: 'text',
    lineName: '',
    validationErrors: [],
    isEditable: true,
  };

  /**
   * @param {*} storedValue The value that is from the server
   * @param {*} fieldValue The value that comes from the form field
   */
  function setValues(storedValue, fieldValue) {
    dataObj.storedValue = storedValue;
    dataObj.fieldValue = fieldValue;
    return this;
  }

  /**
   * @param {*} fieldType A field type from ``INPUT_FIELD_TYPES`` or html input type const
   */
  function setFieldType(fieldType) {
    dataObj.fieldType = fieldType;
    return this;
  }

  /**
   * @param {*} lineId Must be a valid field in the form object on profilePane reducer
   */
  function setLineName(lineName) {
    dataObj.lineName = lineName;
    return this;
  }

  /**
   * @param {*} errorMessage The message to display for errors
   * @param {*} filterType Must be a valid ``<ErrorFeedback />`` filter.
   */
  function addValidationRuleToRules(errorMessage, filterType) {
    dataObj.validationErrors.push({ errorMessage, errorFilter: filterType });
    return this;
  }

  /**
   * @param {*} isEditable if true, then this line can be edited.
   */
  function isEditable(isEditable) {
    dataObj.isEditable = isEditable;
    return this;
  }

  /**
   * @returns The final object containing the data.
   */
  function build() {
    return dataObj;
  }

  return {
    setValues,
    setFieldType,
    setLineName,
    addValidationRuleToRules,
    isEditable,
    build,
  };
}
