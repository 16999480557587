import React from 'react';
import { connect } from 'react-redux';
import { setupPage } from '../../components/Layout/setupPage';
import { 
    Button,
    List,
    NavLink,
    Section,
    FormGroup,
    AccountTable,
    BillTable,
    PaymentTable,
    Authority
} from '../../components';

import * as actionTypes from '../../store/actionType';
import  * as actions  from '../../store/actions';
import * as urls from '../../config/urls';
import  { PermissionEnforcer }  from '../../utility/PermissionEnforcer';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
import SlidingPane from "react-sliding-pane";
import { CloseButton } from '../components/partials/Buttons';
import { Loader } from '../components/partials/LoadingIndicator';

import './Account.style.scss';

import { getIconUrl } from '../../common/images-catalogue';

const mapStateToProps = state => ({
    apiRequestManager: ApiRequestManager.getInstance(ApiRequestManager),
    useClientLayout: state.global.useClientDashboard,
    mode:  state.global.actionBarMode,
    ...state.account
});

const mapDispatchToProps = dispatch => ({
    onLoad: (clentType) => dispatch(actions.accountsViewLoadedAction(clentType)),
    unLoad: () => dispatch(actions.accountsViewUnloadAction()),
    getAccounts: () => dispatch(actions.getAllAccountsAction()),
    setSelectedAccount: (account) => dispatch(actions.setSelectedAccountAction(account)),
    getBills: (client) => dispatch(actions.getAllBillsAction(client)),
    getPayments: (accountOwner) => dispatch(actions.getAllPaymentsAction(accountOwner)),
    clearData: () => dispatch(actions.restHistoryDataAction())
});

class Account extends React.Component {

    static pageOption = { 
        pageTitle: 'Patients',
        disableBodyScroll: true
    };

    modalViews = {
        payment: 'payment',
        bills: 'bills'
    }

    constructor(props) {
        super(props);
        this.state = {
            isPaneOpen: false,
            modalView: ''
        };

        props.apiRequestManager.register(actionTypes.FETCH_BILLS, props.getBills);
        this.props.apiRequestManager.register(actionTypes.FETCH_PAYMENTS, this.props.getPayments);

        props.apiRequestManager.register(actionTypes.FETCH_ACCOUNTS, props.getAccounts);

        this.viewAccountDetails = this.viewAccountDetails.bind(this);
        this.willShowModal = this.willShowModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    componentDidMount() {}

    componentWillUnmount() {
        this.props.unLoad();
    }

    static getDerivedStateFromProps(props, state) {
        if ( !props.accounts && !props.apiRequestManager.inProgress(actionTypes.FETCH_ACCOUNTS)) {
            props.apiRequestManager.queueRequest(actionTypes.FETCH_ACCOUNTS);
        }
        return null;
    }

    viewAccountDetails(data) {
        this.props.setSelectedAccount(data);
    }

    willShowModal(evt) {
        evt.preventDefault();
        const view = evt.target.getAttribute('modal');
        if (view === this.modalViews.payment) {
            this.props.apiRequestManager.queueRequest(actionTypes.FETCH_PAYMENTS, {clientId: this.props.currentAccount.owner});
        } else {
            this.props.apiRequestManager.queueRequest(actionTypes.FETCH_BILLS, {clientId: this.props.currentAccount.owner});
        }
        this.setState({isPaneOpen: true, modalView: view});
    }

    willHandleTableRowMouseOver() {}

    onCloseModal() {
        this.props.clearData();
        this.setState({ isPaneOpen: false });
    }

    getPageMetaData(pagedData) {
        let  {pageData, ...metadata} = pagedData ?? {};
        return {
            ...metadata,
            remote: true,
            pageSize: 25
        }; 
    }

    render () {
        const quickActionCallback = {
            viewDetails: this.viewAccountDetails,
        }
        const tableData = this.props.accounts ? this.props.accounts : [];
        const selectedAccount = this.props.currentAccount;

        let modalTitle = `${this.state.modalView.capitalize()} History`
        const titleSufix = selectedAccount ? ` - ${selectedAccount.accountNo}` : null;
        modalTitle +=  titleSufix || '';

        const billsData = Array.isArray(this.props.billsHistory?.pageData)? this.props.billsHistory.pageData : [];
        const paymentsData = Array.isArray(this.props.paymentsHistory?.pageData )? this.props.paymentsHistory.pageData :[];

        const tableQuickActionCallback = {
            editAction : () => {},
            viewAction: () => {}
        };

        const paymentTableMetadata = this.getPageMetaData(this.props.paymentsHistory);
        const billTableMetadata = this.getPageMetaData(this.props.billsHistory);
    
        return (
            <React.Fragment>
            <div className='content-layout-wrap master-detail account'>
                <div className="aside-list">
                    <div className="action-section">
                    <div className="button-wrap">
                        <Authority allowed="create" entity="accounts"  >
                            <Button className="default-btn add-btn" onClick={this.onAddClient}>
                            <span className="icon"><img src={getIconUrl("addUser")} alt='' /></span>
                                Add Account
                            </Button>
                        </Authority>
                        </div>
                    </div>
                    <div className="list-view">
                        <div className="table-container">
                            <AccountTable 
                            ref={this.patientsTable}
                            id="accountTable" 
                            hoverOverRowAction={this.willHandleTableRowMouseOver}
                            quickActions={quickActionCallback} 
                            tableData={tableData}/>
                        </div>
                    </div>
                </div>
                <div className="view-layout-content detail-view acct-summary">
                    <div className="detail-ly-ct">
                        { !selectedAccount ? 
                            <p className="no-selected">No account data</p>
                        :
                        <div className="cnt-inner">
                            <h1 className="title">Account Summary {titleSufix}</h1>
                            <Section>
                                <div className="ratio-widget balc">
                                    <div className="ratio-holder">
                                        <label>Balance</label>
                                        <div className="ratio-count">
                                            <span className="left-count">${selectedAccount.balance.toCurrency()}</span>
                                            <span className="right-count">${selectedAccount.previousBalance.toCurrency()}</span>
                                        </div>
                                        <div className="ratio-line">
                                            <div className="left-line bar"></div>
                                            <div className="right-line bar"></div>
                                        </div>
                                    </div>
                                </div>
                            </Section>
                            <Section>
                                <h2 className="">Account Info</h2>
                                    <div className="sec-content">
                                    <List className="detls-info-list">
                                        <li className="info-line">
                                            <span className="label">Name</span>
                                            <span className="value">
                                            { selectedAccount.clientInfo.officeName ? 
                                                selectedAccount.clientInfo.officeName
                                                :
                                                selectedAccount.clientInfo.firstName + ' ' + selectedAccount.clientInfo.lastName
                                            }
                                            </span>
                                        </li>
                                        <li className="info-line">
                                            <span className="label">Indentifier</span>
                                            <span className="value"> client1-wksp</span>
                                        </li>
                                        <li className="info-line">
                                            <span className="label">AccountNo</span>
                                            <span className="value">{selectedAccount.accountNo}</span>
                                        </li>
                                        <li className="info-line">
                                            <span className="label">Billing Cycle</span>
                                            <span className="value">{selectedAccount.billCycle}</span>
                                        </li>
                                        <li className="info-line">
                                            <span className="label">Ower</span>
                                            <span className="value">{selectedAccount.owner}</span>
                                        </li>
                                        <li className="info-line">
                                            <span className="label">Active</span>
                                            <span className="value">{selectedAccount.active ? 'YES' : 'NO'}</span>
                                        </li>
                                        <li className="info-line">
                                            <span className="label">Status</span>
                                            <span className="value">{selectedAccount.status ? selectedAccount.status : '--'}</span>
                                        </li>
                                    </List>
                                </div>
                            </Section>
                            <Section>
                                <h2 className="">Workspace Info</h2>
                                    <div className="sec-content">
                                    <List className="detls-info-list">
                                        <li className="info-line">
                                            <span className="label">Name</span>
                                            <span className="value">{selectedAccount.workspace.name}</span>
                                        </li>
                                        <li className="info-line">
                                            <span className="label">Indentifier</span>
                                            <span className="value">{selectedAccount.workspace.identifier}</span>
                                        </li>
                                        <li className="info-line">
                                            <span className="label">Domain</span>
                                            <span className="value">{selectedAccount.workspace.domain}</span>
                                        </li>
                                        <li className="info-line">
                                            <span className="label">Role</span>
                                            <span className="value">{selectedAccount.workspace.constraints.role}</span>
                                        </li>
                                        <li className="info-line">
                                            <span className="label">Client Type</span>
                                            <span className="value">{selectedAccount.clientType}</span>
                                        </li>
                                    </List>
                                </div>
                            </Section>
                            <Section>
                                <FormGroup>
                                    <NavLink onClick={this.willShowModal} modal={this.modalViews.payment} to={''} className="arrow-link">
                                        <span className="arrow-indicator"></span>Payment History
                                    </NavLink>
                                </FormGroup>
                                <FormGroup>
                                    <NavLink  modal={this.modalViews.bills} onClick={this.willShowModal} to={''} className="arrow-link">
                                        <span className="arrow-indicator"></span>Billing History
                                    </NavLink>
                                </FormGroup>

                                <FormGroup>
                                    <NavLink className="arrow-link close-act">
                                        <span className="arrow-indicator"></span>Close Account
                                    </NavLink>
                                </FormGroup>
                            </Section>
                        </div>
                        }
                    </div>
                </div>
            </div>
                <SlidingPane
                    closeIcon={<CloseButton />}
                    isOpen={this.state.isPaneOpen}
                    title={modalTitle}
                    from="bottom"
                    width="100%"
                    onRequestClose={this.onCloseModal}
                >
                
                    <div className="account content-wrap">
                        { this.props.loadingData ? 
                            <div className="no-data">
                                <div className="loader-modal">
                                    <Loader visible={this.props.loadingData} loadingText={'Please wait...'} />
                                </div>
                            </div>
                        :
                        <React.Fragment>
                            { this.state.modalView === this.modalViews.payment ? 
                                <PaymentTable
                                    quickActions={tableQuickActionCallback} 
                                    tableData={paymentsData}
                                    metaData={paymentTableMetadata}
                                />
                                :
                                <BillTable 
                                    hoverOverRowAction={this.willHandleTableRowMouseOver}
                                    quickActions={tableQuickActionCallback}
                                    tableData={billsData}
                                    metaData={billTableMetadata}
                                />
                            }
                        </React.Fragment>
                         }   
                        
                    </div>
                </SlidingPane>
            </React.Fragment>
        );
    }
}

const AccountView = (props) => {
    return (
        <Account {...props } />
    );
};

let ConfigAccountView = setupPage(Account.pageOption)(AccountView);
ConfigAccountView =  connect(mapStateToProps, mapDispatchToProps)(ConfigAccountView);

export { ConfigAccountView };