import { VALIDATOR_DELEGATE_KEY_GROUP } from "../common/constant";

const delegate = (validator, key, value, value2 = '') => {
  const results = {};

  switch(key){
      case VALIDATOR_DELEGATE_KEY_GROUP.formFields.email:
        results.emailMissing = false;
        results.emailInvalid = false;

        if(validator.isValueMissing(value)) results.emailMissing = true
        if(!validator.isEmailValue(value)) results.emailInvalid = true;
        break;

      case VALIDATOR_DELEGATE_KEY_GROUP.formFields.firstName:
        results.firstNameMissing = false;
        results.firstNameInvalid = false;

        if(validator.isValueMissing(value)) results.firstNameMissing = true;
        if(!validator.isTextValue(value)) results.firstNameInvalid = true;
        break;
      
      case VALIDATOR_DELEGATE_KEY_GROUP.formFields.lastName:
        results.lastNameMissing = false;
        results.lastNameInvalid = false;

        if(validator.isValueMissing(value)) results.lastNameMissing = true;
        if(!validator.isTextValue(value)) results.lastNameInvalid = true;
        break;

      case VALIDATOR_DELEGATE_KEY_GROUP.formFields.currentPassword:
        results.currentPasswordMissing = false;
        if(validator.isValueMissing(value)) results.currentPasswordMissing = true;
        break;
        
      case VALIDATOR_DELEGATE_KEY_GROUP.formFields.password:
        results.passwordMissing = false;
        results.passwordsDifferent = false;

        if(validator.isValueMissing(value)) results.passwordMissing = true;

        //where value2 is passwordTwo
        if(!validator.isEqual(value, value2)) results.passwordsDifferent = true;
        break;

      case VALIDATOR_DELEGATE_KEY_GROUP.formFields.passwordTwo:
        results.passwordsDifferent = false;

        //where value2 is passwordTwo
        if(!validator.isEqual(value, value2)) results.passwordsDifferent = true;
        break;

      default:
          break;
  }

  return results;
}
export default delegate;