import React from 'react';
import PropTypes from 'prop-types';
import { ERROR_FILTERS, INPUT_FIELD_TYPES } from '../../../common/constant';
import DetailSection from '../DetailSection';
import {
  Button,
  ErrorFeedback,
  Input,
  ValidateField,
} from '../../../components';
import { ErrorMessage } from '../../components/partials/ErrorMessage';
import { getIconUrl } from '../../../common/images-catalogue';

import Base64 from '../../../utility/Base64Encoder';
import { Loader } from '../../components/partials/LoadingIndicator';
import { ApiRequestManager } from '../../../utility/ApiRequestManager';
import * as actionTypes from '../../../store/actionType';

import {
  PromptMessage
} from '../../components/partials/PromptMessage';

function TwoFactorSection({
  currentlyEditing,
  onCancelEdit,
  lineName,
}) {

  const isBeingEdited = currentlyEditing === lineName;

  return (
    <div className='detail-line__container'>
      <div className='detail-line__title-container'>
        {!isBeingEdited && (
          <span className='detail-line__label'>
            Protect your account by adding an extra layer of security.
          </span>
        )}
      </div>
      {/* using the div added space */}
      {!isBeingEdited && <div className='password-asterisk__container' />}

      {/* Show Nevermind button if this line is being edited */}
      {isBeingEdited && (
        <Button className='detail-line__cancel-edit' onClick={onCancelEdit}>
          Nevermind
        </Button>
      )}
    </div>
  );
}

function PasswordSection({
  formData,
  lineNames,
  currentlyEditing,
  onCancelEdit,
  onChangePassword,
  onChange,
  errorMessages,
}) {

  const isBeingEdited = currentlyEditing;

  const apiRequestManager = ApiRequestManager.getInstance(ApiRequestManager)
  const requestInProgress = apiRequestManager.inProgress(actionTypes.PASSWORD_CHANGE_SUBMIT_DATA);

  return (
    <div className='detail-line__container'>
      <div className='detail-line__title-container'>
        {!isBeingEdited && (
          <span className='detail-line__label'>
            Create a password or modify your existing one.
          </span>
        )}
      </div>
      {/* Render password asterisks */}
      {!isBeingEdited && (
        <div className='password-asterisk__container'>
          {Array(8)
            .fill(1)
            .map((_, index) => (
              <span key={index} className='password-asterisk'>
                <img src={getIconUrl('passwordStar')} alt='' />
              </span>
            ))}
        </div>
      )}

      {/* Render Password edit block if isBeingEdited*/}
      {isBeingEdited && (
        <div className='password__edit-block'>
          <ValidateField>
            <span className='detail-line__label--grey'>Current Password</span>
            <Input
              className='detail-line__editable-field'
              onChange={(event) => onChange(lineNames.currentPassword, event)}
              type={INPUT_FIELD_TYPES.password}
              value={Base64.decode(formData.currentPassword)}
              name={lineNames.currentPassword}
              //disable chrome autocomplete
              autoComplete='new-password'
            />
            <ErrorFeedback
              error
              show={errorMessages.currentPasswordMissing.trim() !== ''}
              filter={ERROR_FILTERS.valueMissing}
              message={
                <ErrorMessage message={errorMessages.currentPasswordMissing} />
              }
            />
          </ValidateField>

          <ValidateField pattern={'[a-zA-Z0-9@#$%^&+-]{6,}?'}>
            <span className='detail-line__label--grey'>New Password</span>
            <Input
              className='detail-line__editable-field'
              onChange={(event) => onChange(lineNames.password, event)}
              type={INPUT_FIELD_TYPES.password}
              value={Base64.decode(formData.password)}
              name={lineNames.password}
            />
            <ErrorFeedback
              error
              show={errorMessages.passwordMissing.trim() !== ''}
              filter={ERROR_FILTERS.valueMissing}
              message={<ErrorMessage message={errorMessages.passwordMissing} />}
            />
            <ErrorFeedback
              error
              filter={ERROR_FILTERS.patternMismatch}
              message={
                <ErrorMessage
                  message={'Use 6 characters or more for your password'}
                />
              }
            />
          </ValidateField>

          <ValidateField matchField={'password'}>
            <span className='detail-line__label--grey'>Re-Enter</span>
            <Input
              className='detail-line__editable-field'
              onChange={(event) => onChange(lineNames.passwordTwo, event)}
              type={INPUT_FIELD_TYPES.password}
              value={Base64.decode(formData.passwordTwo)}
              name={lineNames.passwordTwo}
            />
            <ErrorFeedback
              error
              show={errorMessages.passwordMismatch.trim() !== ''}
              filter={ERROR_FILTERS.matchEqual}
              message={
                <ErrorMessage message={'Those passwords didn’t match'} />
              }
            />
          </ValidateField>
        </div>
      )}

      {/* Show Nevermind button if this line is being edited */}
      {isBeingEdited && (
        <React.Fragment>
        <div className='detail-sec-action'>
          
          { !requestInProgress ? 
          <Button
            className='detail-line default-btn'
            onClick={onChangePassword}
          >
            Change
          </Button>
          :
            <>
              <div className='detail-line-loader'>
                <Loader inline visible={true} loadingText={''} />
              </div>
            </>
          }

          <Button
            className='detail-line__cancel-edit password-nvm'
            onClick={onCancelEdit}
          >
            Cancel
          </Button>
        </div>  

        </React.Fragment>
      )}
    </div>
  );
}

class SecurityPane extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getChangePasswordOutcome = this.getChangePasswordOutcome.bind(this);
    this.willHandleEdit = this.willHandleEdit.bind(this);
  }

  getChangePasswordOutcome() {
    const apiOutcome = this.props.apiRequestManager.getApiOutcome(actionTypes.PASSWORD_CHANGE_SUBMIT_DATA, true);

    let promptProps = {
      error: apiOutcome?.error ? true : false,
      success: apiOutcome?.success || false,
      visible: apiOutcome?.success || (apiOutcome?.error && !apiOutcome?.success) || false,
      message: this.props.changePasswordSlice.message || ''
    }; 
    return promptProps;
  }

  willHandleEdit(evt) {
    this.props.onClickEdit(evt);
    this.props.onDisableSave();
  }

  render() {
    const lineNames = this.props.lineNames;
    const promptProps =  this.getChangePasswordOutcome();
    const sectionIdentifiers = {
      password: 'section_pwd',
      twoFactorAuth: 'section_2fa'
    }

    return (
      <React.Fragment>

      <PromptMessage {...promptProps} />
      
      <div className='profile-pane'>
        <h2 className='profile__pane-title pane-title--margin'>
          Security & Signin
        </h2>

        {/* Display the sections */}
        <div className='detail-section__list'>
          <DetailSection
            sectionTitle='Password'
            identifier={sectionIdentifiers.password}
            disableEdit={this.props.shouldDisableEdit(sectionIdentifiers.password)}
            //this is just so that the renderDetailLine function gets called.
            detailLines={[{isEditable: true}]}
            onClickEdit={this.willHandleEdit}
            delegateLineAction
            isBeingEdited={sectionIdentifiers.password === this.props.currentlyEditing}
            renderDetailLine={(line) => (
              <PasswordSection
                lineNames={lineNames}
                errorMessages={this.props.validationErrors}
                formData={this.props.profileForm}
                currentlyEditing={line.currentlyEditing}
                onChangePassword={this.props.onChangePassword}
                onChange={this.props.onFieldChange}
                onCancelEdit={this.props.onCancelEdit}
              />
            )}
          />

          <DetailSection
            editLabel={'Setup'}
            sectionTitle='2 setup verification'
            identifier={sectionIdentifiers.twoFactorAuth}
            isBeingEdited={sectionIdentifiers.twoFactorAuth === this.props.currentlyEditing}
            detailLines={[{isEditable: true}]}
            onClickEdit={this.props.onClickEdit}
            onCancelEdit={this.props.onCancelEdit}
            disableEdit={this.props.shouldDisableEdit(sectionIdentifiers.twoFactorAuth)}
            renderDetailLine={(line) => (
              <TwoFactorSection
                lineName={lineNames.twoFactor}
                currentlyEditing={line.currentlyEditing}
              />
            )}
          />
        </div>
      </div>
      </React.Fragment>
    );
  }
}

SecurityPane.propTypes = {
  lineNames: PropTypes.object.isRequired,
  shouldDisableEdit: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onCancelEdit: PropTypes.func.isRequired,
};

export default SecurityPane;
