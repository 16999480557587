import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setupPage } from '../../components/Layout/setupPage';
import { 
    Authority,
    SpecimenTable,
} from '../../components';

import * as actionTypes from '../../store/actionType';
import  * as actions  from '../../store/actions';
import * as urls from '../../config/urls';
import  { PermissionEnforcer }  from '../../utility/PermissionEnforcer';
import { PAGE_SIZE } from '../../config/pagination';
import { ApiRequestManager } from '../../utility/ApiRequestManager';

import './Billing.style.scss';

const icons = {
    profile: require('./../../assets/images/profile-icon.svg'),
};

const getIconUrl = (name) => {
    return icons[name].default;
};

const mapStateToProps = state => ({
    apiRequestManager: ApiRequestManager.getInstance(ApiRequestManager),
    useClientLayout: state.global.useClientDashboard,
    mode:  state.global.actionBarMode,
    bill: state.billing.bill
});

const mapDispatchToProps = dispatch => ({
    onLoad: (clentType) => dispatch(actions.clientsViewLoadedAction(clentType)),
    unLoad: () => dispatch(actions.clientsViewUnloadAction()),
    getBillableRecords: (payload) => dispatch(actions.getClientSpecimenRecords(payload)),
});

class Billable extends React.Component {

    static pageOption = { 
        pageTitle: 'Patients',
        disableBodyScroll: true,
    };

    constructor(props) {
        super(props);

        props.apiRequestManager.register(actionTypes.FETCH_CLIENT_SPECIMEN_RECORDS, props.getBillableRecords);

        this.state = {
            initData: false,
            pageSize: PAGE_SIZE,
            currentPage: 0
        };

        this.handleTableChange = this.handleTableChange.bind(this);
    }

    componentDidMount() { }

    componentWillUnmount() { }

    static getDerivedStateFromProps(props, state) {
        
        if(!state.initData && props.billableRecords.empty() && !props.apiRequestManager.inProgress(actionTypes.FETCH_CLIENT_SPECIMEN_RECORDS)) {
            const requestParams = {
                clientId: null,
                pageSize: state.pageSize,
                page: state.currentPage
            }

            props.apiRequestManager.queueRequest(actionTypes.FETCH_CLIENT_SPECIMEN_RECORDS, requestParams);

            return {
                initData: true
            }
        }
        return null;
    }

    handleTableChange(tableOpt) {

        const pageNumber = !tableOpt.page || tableOpt.page - 1;
        const pageSize = tableOpt.sizePerPage;

        const requestParams = {
            clientId: null,
            page: pageNumber,
            pageSize: pageSize
        };

        this.props.apiRequestManager.queueRequest(actionTypes.FETCH_CLIENT_SPECIMEN_RECORDS, requestParams);

        this.setState({
            currentPage: pageNumber,
            pageSize: pageSize
        });
    }

    render () {
    
        let {pageData, analytics, ...tableMetadata} = this.props.billableRecords || {};
        const tableData = Array.isArray(pageData) ? pageData : [];

        tableMetadata = {
            ...tableMetadata,
            remote: true,
            pageSize: this.state.pageSize
        };
        
        return (
            <React.Fragment>                
                <div className="table-container billing-table-coontainer">
                    <div className='table'>
                    <SpecimenTable 
                    //ref={this.patientsTable}
                    hoverOverRowAction={this.props.willHandleTableRowMouseOver}
                    quickActions={this.props.quickActionCallback} 
                    tableData={tableData}
                    pagable
                    metaData={tableMetadata}
                    onTableChange={this.handleTableChange}
                    />
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

const BillableView = (props) => {
    return (
        <Billable {...props } />
    );
};

let ConfigBillableView = setupPage(Billable.pageOption)(BillableView);
ConfigBillableView =  connect(mapStateToProps, mapDispatchToProps)(ConfigBillableView);

export { ConfigBillableView };