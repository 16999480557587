import React, {useCallback, useState} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setupPage } from '../../../../components/Layout/setupPage';
import {
    List, 
    Input,
    FormGroup,
    Label,
    Section,
} from '../../../../components';

import  * as actions  from '../../../../store/actions';
import * as actionTypes from '../../../../store/actionType';
import { AddButton } from '../../../components/partials/Buttons';

import { ErrorMessage } from '../../../components/partials/ErrorMessage';
import text from '../../../../common/strings';
import { REQUESTS_TYPE, TAX_CODE, VALIDATOR_DELEGATE_KEY_GROUP, VALIDATOR_DELEGATE_TYPES  } from '../../../../common/constant';
import { PromptMessage, getPromptMessageProps } from '../../../components/partials/PromptMessage';
import settingsValidatorDelegate from '../../../../forms/settingsValidator.delegate';
import { objListsAreEqual } from '../../../../utility/helpers';
import { TAX_TYPE } from '../../../../common/constant';
import { getIconUrl } from '../../../../common/images-catalogue';
const mapStateToProps = state => ({
    ...state.settings.taxesSetting
});

const mapDispatchToProps = dispatch => ({
    getAllTaxes: () => dispatch(actions.getAllTaxesAction()),
    setTaxes: (type, data) => dispatch(actions.setSettingDataAction(type, data)),
    submitData: (data) => dispatch(actions.submitTaxesAction(data))
});

const TaxItem = (props) => {
    const {data, actions} = props;
    const [percentInFocus, setPercentInFocus] = useState(false)
  
    const fieldChange = (evt) => {
        var values = {
            index: data.index,
            ...data,
            [evt.target.name]: evt.target.name !== 'taxCode' ? evt.target.value : evt.target.value.toUpperCase()
        };

        if (evt.target.name !== 'taxClass' && data.taxClass && !data.taxClass.empty()) {
            values.taxClass = data.taxClass;
        }
        // Set Tax Code as Tax name for Non PAYE income
        if (values.taxClass === TAX_TYPE.payroll && values.taxCode !== TAX_CODE.PAYE && !data.taxClass.empty()) {
            values.taxName = values.taxCode;
        }if (data.taxClass !== values.taxClass) {
            values.taxName = '';
        }

        actions.addOrChange(evt, data.index, values);
    };
    const onCheckboxChange = (evt) => {
        const evtValue = {
            target : {
                value: evt.target.checked,
                name: evt.target.name
            }
        }
        actions.removePreviousDefault();
        fieldChange(evtValue);
    };

    const onIncomeCheckboxChange = (evt) => {
        const evtValue = {
            target : {
                value: evt.target.checked,
                name: evt.target.name
            }
        }
        fieldChange(evtValue);
    };

    const didFocusPercentField = useCallback((evt) => {
        setPercentInFocus(true)
    }, []);

    const willMouseOut = useCallback((evt) => {
        setPercentInFocus(false)
    }, []);
    
    const isNewClass = !data.id ? '--new' : '';
    const text = !data.id ? 'Remove' : 'Delete';
    const isServiceTax = data.taxClass === TAX_TYPE.service;
    const isPAYE = data.taxClass === TAX_TYPE.payroll && data.taxCode === TAX_CODE.PAYE;
 
    return (
        <li className={`tax-code-item ${isNewClass}`} type={data.taxClass}>
            <div className="tax" onMouseLeave={willMouseOut}>
                
                <FormGroup>
                    <Label>Tax Name</Label>
                    {(data.taxName || data.taxName !== '' ? null : <ErrorMessage message='Enter tax name'/>)}
                    <Input disabled={!isServiceTax && !isPAYE} onChange={fieldChange} name='taxName' type="text" placeholder="Name"  value={data.taxName} />
                </FormGroup>

                <FormGroup>
                    <Label>Code</Label>
                    {(data.taxCode ? '' : <ErrorMessage message='Please Select Tax code'/>)}
                    <Input 
                    id={0}
                    className="ip-basic-select tax-type"
                    type="select"
                    name="taxCode"
                    value={data.taxCode}
                    onChange={ fieldChange }>
                    {!isServiceTax ? 
                        <React.Fragment>
                            <option value="">Tax code</option>
                            <option value="NIS">N.I.S</option>
                            <option value="NHT">N.H.T</option>
                            <option value="EDUTAX">ED. TAX</option>
                            <option value="PAYE">PAYE</option>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <option value="">Tax code</option>
                            <option value="GCT">GCT</option>
                        </React.Fragment>
                    }
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label>Percentge %</Label>
                    {(data.percent || data.percent === 0 ? '' : <ErrorMessage message='Enter a Percentage Rate'/>)}
                    <Input  name='percent' type="number" placeholder="%" min={0}  value={data.percent}
                    onChange={fieldChange}
                    onFocus={didFocusPercentField} />
                    {(isPAYE && !percentInFocus) && 
                        <span className='threshold-details'>@ ${data?.thresholdAmount.toCurrency() || 0.0} /yr</span>
                    }
                </FormGroup>
                {(isPAYE && percentInFocus )&&
                <FormGroup>
                    <Label>Threshold Amount Yr</Label>
                    {(data.thresholdAmount || data.thresholdAmount === 0 ? '' : <ErrorMessage message='Enter threshold amount'/>)}
                    <Input onChange={fieldChange} name='thresholdAmount' type="number" placeholder="$0.0" min={0}  value={data.thresholdAmount} />
                </FormGroup>
                }
                <FormGroup>
                    <Label>Tax Class</Label>
                    <Input 
                    id={0}
                    className="ip-basic-select tax-type"
                    type="select"
                    name="taxClass"
                    value={data.taxClass}
                    onChange={ fieldChange }>
                    <option value={TAX_TYPE.payroll}>Payroll</option>
                    <option value={TAX_TYPE.service}>Services</option>
                    </Input>
                </FormGroup>
                {isServiceTax &&
                    <label className="ip-checkbox">Default?
                        <Input onChange={onCheckboxChange} 
                         checked={data.isDefault}
                        type="radio" 
                        value={''} 
                        name="isDefault" />
                        <span className="checkmark"></span>
                    </label>
                }
                {isPAYE &&
                    <label className="ip-checkbox">Income Tax?
                        <Input onChange={onIncomeCheckboxChange} 
                         checked={data.isIncomeTax}
                         type="checkbox" 
                         value={''} 
                        name="isIncomeTax" />
                        <span className="checkmark"></span>
                    </label>
                }
            </div>
            <div className="tax-ft">
                <Link onClick={(evt) => actions.remove(evt, data)} to={'#'} className="remove"><img className={'icon'} src={getIconUrl('trash')} alt='' /> {text}</Link>
            </div>
        </li>
    );
}

class Taxes extends React.Component {

    static pageOption = {
        pageTitle: 'Client',
        disableBodyScroll: true
    };

    constructor(props) {
        super(props);
        this.state = {};

        props.apiRequestManager.register(actionTypes.FETCH_TAXES, props.getAllTaxes);
        props.apiRequestManager.register(actionTypes.TAX_SUBMIT_DATA, props.submitData);

        this.addOrReplaceTax = this.addOrReplaceTax.bind(this);
        this.removeTax = this.removeTax.bind(this);
        this.composeTax = this.composeTax.bind(this);
        this.onSaveHanlder = this.onSaveHanlder.bind(this);
        this.removePreviousDefault = this.removePreviousDefault.bind(this);
        this.getPromptMessage = this.getPromptMessage.bind(this);
        this.validation = this.validation.bind(this);
        
        props.innerRef.saveHanlder = this.onSaveHanlder;
        props.innerRef.confirmDeleteCallback = this.onConfirmDelete.bind(this);
    }

    componentDidMount() {
        this.props.validator.addDelegate(VALIDATOR_DELEGATE_TYPES.settings, settingsValidatorDelegate)
     }

    static getDerivedStateFromProps(props, state) {
        if ( !props.taxesData && !props.apiRequestManager.inProgress(actionTypes.FETCH_TAXES)) {
            props.apiRequestManager.queueRequest(actionTypes.FETCH_TAXES);
        }
        return null;
    }

    shouldComponentUpdate(nextProps){
        /**
         * This runs the validation if something changed in taxes list.
         */
        const prevTaxes = this.props.taxesData;
        const nextTaxes = nextProps.taxesData;

        if (Array.isArray(prevTaxes) && Array.isArray(nextTaxes) && !objListsAreEqual(prevTaxes, nextTaxes,'index')){
            const taxesErrors = this.validation(nextTaxes);
            
            if(this.props.validator.hasActiveFormError(taxesErrors)){
                this.props.actions.enableSave(false);
            }else{
                this.props.actions.enableSave();
            }
        }

        return true;
    }

  getPromptMessage(){
      const apiOutcome = this.props.apiRequestManager.getApiOutcome(actionTypes.TAX_SUBMIT_DATA, true) ?? {};
      return getPromptMessageProps('taxItem', apiOutcome);
  }

    onConfirmDelete(deleteItem) {
        const payload = {
            requestType: 'delete',
            taxId: deleteItem.id
        };
        
        this.props.apiRequestManager.queueRequest(actionTypes.TAX_SUBMIT_DATA, payload);
    }

    removeTax(evt, tax) {
        evt.preventDefault();
        if (tax.id) {
            this.props.showDeleteDialog('tax', tax);
        }else {
            const taxes = this.props.taxesData.filter((item) => tax.index !== item.index);
            this.props.setTaxes(actionTypes.SET_TAX_DATA, taxes);
        }
    }

    addOrReplaceTax(type, itemIndex, tax) {
        var taxes = [];
        
        if (itemIndex === undefined) {
            let taxesWithNewEntry = [this.composeTax(), ...this.props.taxesData];
            taxesWithNewEntry.sortArrayObjectByKey('index')

            taxes = !this.props.taxesData.length? [this.composeTax()] : taxesWithNewEntry;
            this.props.setTaxes(actionTypes.SET_TAX_DATA, taxes);
        }else {
            taxes = this.props.taxesData.map((item) => {
                if (item.index === itemIndex) {
                    item = {
                        ...tax
                    }
                }
                return item;
            });
            this.props.setTaxes(actionTypes.SET_TAX_DATA, taxes);
        }
    }

    validation(taxes){
        return {
            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.settings, VALIDATOR_DELEGATE_KEY_GROUP.settings.taxes, taxes)
        }
    }

    onSaveHanlder() {
        const data = this.props.taxesData.map((item) => {
            return {
                ...item,
                percent: parseFloat(item.percent)
            }
        });
        const payload = {
            requestType: 'submit',
            data: data
        }
        this.props.apiRequestManager.queueRequest(actionTypes.TAX_SUBMIT_DATA, payload);
    }
    removePreviousDefault() {
        const taxes = this.props.taxesData.map((item) => {
            if (item.isDefault) 
                item.isDefault = false;
            return item;
        });
        this.props.setTaxes(actionTypes.SET_TAX_DATA, taxes);
    }
    composeTax(type) {
        
        const labcode = {
            index: 0,
            taxName: '',
            taxCode: '',
            percent: 0.0,
            taxClass: TAX_TYPE.service,
            thresholdAmount: 0,
            isDefault: false,
            isIncomeTax: null
        }
        return labcode;
    }

    render () {

        const taxes = !Array.isArray(this.props.taxesData) ? [] : this.props.taxesData;

        const actions = {
            remove: this.removeTax,
            addOrChange: this.addOrReplaceTax,
            removePreviousDefault: this.removePreviousDefault
        };

        const dialogProps = this.getPromptMessage();
                
        return (
            <React.Fragment>
                { dialogProps.visible && 
                    <PromptMessage {...dialogProps} />
                } 
                <div className='pane-layout-wrap pane tax-setting-pane'>
                    <div className="pane-cont">
                        <div className="setting-info">
                            <p>Service taxes are utilized in the Billing section, whereas Payroll taxes are used in the Payroll section. Multiple taxes with the same tax code can be created by giving each tax a unique tax name.</p>
                            <p>The service tax marked as <b>Default</b> will be the value selected where applicable; however, you will still be allowed to select other taxes. In order for a <b>PAYE</b> tax to be applied, it must be checked as income tax and a salary at which the tax is applied -- <b>threshold</b> -- is required. </p>
                        </div>
                        
                        <div className="actions-btn">
                            <AddButton actionHandler={(evt) => this.addOrReplaceTax()} label={'Add Tax Item'} />
                        </div>
                           
                        <Section className="taxes-list">
                            <List>
                                { taxes.map((item, i) => {
                                    return <TaxItem 
                                        key={i} 
                                        data={item} 
                                        actions={actions}/>
                                    })
                                }
                            </List>
                        </Section>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const TaxesView = (props) => {
    return (
        <Taxes {...props } />
    );
};

let ConfigTaxesView = setupPage(Taxes.pageOption)(TaxesView);
ConfigTaxesView =  connect(mapStateToProps, mapDispatchToProps)(ConfigTaxesView);

export { ConfigTaxesView };