import React from 'react';
import { connect } from 'react-redux';
import ActionBar from '../components/partials/ActionBar';
import { setupPage } from '../../components/Layout/setupPage';
import PropType  from 'prop-types';
import { FormValidator } from '../../utility/FormValidator';
import {
    Row,
    Container,
    Col,
    Button,
    Form, 
    FormGroup, 
    Label, 
    Input, 
    List,
    NavLink as Link,
    FormText,
    Authority,
    ValidateField,
    ErrorFeedback
} from '../../components';
import { REQUESTS_TYPE } from '../../common/constant';
import { PermissionSelector } from '../components/partials/PermissionSelector';
import "./Permission.styles.scss";

import { PermissionEnforcer } from '../../utility/PermissionEnforcer';
import { ApiRequestManager } from '../../utility/ApiRequestManager';

//import { setChangeListener } from '../../utility/helpers';
import * as actionTypes from '../../store/actionType';
import * as actions from '../../store/actions';
import Role  from '../components/partials/Role';
import { ErrorMessage } from '../components/partials/ErrorMessage';
import { PromptMessage, getPromptMessageProps } from '../components/partials/PromptMessage';
import text from '../../common/strings';
import { getIconUrl } from '../../common/images-catalogue';

const mapStateToProps = state => ({
    apiRequestManager : ApiRequestManager.getInstance(ApiRequestManager),
    ...state.permissionsRoles,
    mode: state.global.actionBarMode,
    systemPermissions: state.global.systemPermissions,
    syncInProgress: state.global.syncInProgress,
    authorizeUser: state.global.authorizeUser,
    validator: FormValidator.getInstance(FormValidator),
    clientDashboard: state.global.useClientDashboard,
    workspace: state.global.workspace,
});

const mapDispatchToProps = dispatch => ({
    onLoad: () => dispatch(actions.permissionsRolesLoadedAction()),
    unLoad: () => dispatch(actions.permissionsViewUnLoadAction()),
    getRolesPermissions: (workspace) => dispatch(actions.getRolesPermissionsConcurrentAction(workspace)),
    setView: (view) => dispatch(actions.permissionRoleViewAction(view)),
    setMode: (mode) => dispatch(actions.actionBarModeAction(mode)),
    setRoleType: (type) => dispatch(actions.selectRoleTypeAction(type)),
    didChooseRole: (role) => dispatch(actions.selectedRoleAction(role)),
    setChosenPermissions: (permissions) => dispatch(actions.chosenPermissionsAction(permissions)),
    seletedSuperuserRole: (isSuperRole) => dispatch(actions.selectedSuperuserRoleAction(isSuperRole)),
    roleNameChanded : (value) => dispatch(actions.roleNameFieldChangedAction(value)),
    submitRolePermission: (data) => dispatch(actions.submitRolePermissionsAction(data)),
    toPreviousView: (view) => dispatch(actions.backToPreviousViewAction(view))
});

const RoleChooser = (props) => {

    const infoText = {
        userRole: 'User Roles are use to assign permissions for a user',
        workspaceRole: 'Workspace Roles provide clients with permissions to assign to their users.'
    }

    const textRef = React.createRef();
    const clientLayout = props.clientLayout;
    const showRoleInfo = (evt) => {
        textRef.current.innerHTML = infoText[evt.currentTarget.id];
    }
    return (
        <Authority allowed="create" entity="role" alternateView>
            <div className="role-chooser-wrap">
                <div className="role-chooser-content">
                    <h1 className="title">Choose Role Type</h1>
                    <div className="role-chooser">
                        <div className="inner">
                            <List className="roles-type-selection">
                                <li key={1} className="role-type">
                                    <Link id="userRole" onMouseEnter={showRoleInfo} onClick={props.callback} className="trigger">
                                        <div className="icon-holder">
                                            <span className="icon">
                                                <img src={getIconUrl('userRoleType')}  alt=''/>
                                            </span>
                                        </div>
                                        <label>User</label>
                                    </Link>
                                </li>
                                { clientLayout ? null : 
                                <React.Fragment>
                                <li key={2}  ><strong className="or-text">OR</strong></li>
                                <li key={3} className="role-type">
                                    <Link id="workspaceRole" onMouseEnter={showRoleInfo} onClick={props.callback} className="trigger">
                                        <div className="icon-holder">
                                            <span className="icon">
                                                <img src={getIconUrl('workspaceRoleType')} alt='' />
                                            </span>
                                        </div>
                                        <label>Workspace</label>
                                    </Link>
                                </li>
                                </React.Fragment>
                                }
                            </List>
                            <div className="info-well">
                                <p ref={textRef} className="info-text">{ infoText.userRole }</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Authority>
    );
}

class PermissionRole extends React.Component {

    static pageOption = {
        pageTitle: 'Role | Permission',
        disableBodyScroll: true
    };

    enforcer;

    static defaultProps = {
        mode: 'view'
    }

    roleType = {
        userRole: 'userRole',
        workspaceRole: 'workspaceRole'
    }

    static propTypes = {
        mode: PropType.string,
    }

    constructor(props) {
        super(props);
        
        props.apiRequestManager.register(actionTypes.FETCH_ROLES_PERMISSIONS, props.getRolesPermissions);
        props.apiRequestManager.register(actionTypes.PERMISSIONS_ROLE_SUBMIT_DATA, props.submitRolePermission);

        this.state = {
            roleName: "Name",
            initialView: 'roleChooser',
            userRole: "user",
            workspaceRole: "workspace",
            chosenPermissionsSet: [],
            removalPermiisonsSet: [],
            singleSuperuser: false,
            formErrors :{}
        } 

        this.nameInputFiledRef = React.createRef();

        this.enforcer = PermissionEnforcer.getInstance(PermissionEnforcer);
        this.enforcer.permissions = [];
        this.roleNameDidChange = this.roleNameDidChange.bind(this);
        this.addPermission = this.addPermission.bind(this);
        this.removePermission = this.removePermission.bind(this);
        this.willHandleSelection = this.willHandleSelection.bind(this);
        this.willHandleRemoval = this.willHandleRemoval.bind(this);
        this.changeMode = this.changeMode.bind(this);
        this.showRoleChooseView = this.showRoleChooseView.bind(this);
        this.didSelectRole = this.didSelectRole.bind(this);
        this.didSelectRoleType = this.didSelectRoleType.bind(this);
        this.didSelectSuperuserRole = this.didSelectSuperuserRole.bind(this);
        this.enableEditingMode = this.enableEditingMode.bind(this);
        this.submitData = this.submitData.bind(this);
        this.handlerBack = this.handlerBack.bind(this);
        this.getPromptMessage = this.getPromptMessage.bind(this);
      
    }

    componentDidMount() {
        //
        this.props.onLoad();
        this.props.setView(this.state.initialView);
        this.props.setMode('');
        
        if (this.props.workspace && this.props.clientDashboard && Object.blank(this.props.systemPermissions)) {
            //this.props.apiRequestManager.queueRequest(actionTypes.FETCH_ROLES_PERMISSIONS, this.props.workspace.id);
            
        } else if (!this.props.clientDashboard && Object.blank(this.props.systemPermissions)) {
            //this.props.apiRequestManager.queueRequest(actionTypes.FETCH_ROLES_PERMISSIONS);
        }
    }
    
    shouldComponentUpdate() {
        if ( this.props.chosenPermissions ) {
            this.enforcer.chosenPermissions = this.props.chosenPermissions !== undefined ? this.props.chosenPermissions : [];  
            return true;
        }
        return false;
    }
    
    static getDerivedStateFromProps(props, state) {
        // Return null to indicate no change to state.
        if (props.workspace && props.clientDashboard && Object.blank(props.systemPermissions) && !props.apiRequestManager.inProgress(actionTypes.FETCH_ROLES_PERMISSIONS)) {
            props.apiRequestManager.queueRequest(actionTypes.FETCH_ROLES_PERMISSIONS, props.workspace.id);
        
        }else if (!props.clientDashboard && Object.blank(props.systemPermissions) && !props.apiRequestManager.inProgress(actionTypes.FETCH_ROLES_PERMISSIONS)) {
            props.apiRequestManager.queueRequest(actionTypes.FETCH_ROLES_PERMISSIONS);
        }
        return null;
    }

    componentWillUnmount() { 
        this.props.unLoad();
    }

    addPermission(evt) {
       
        if ( this.props.mode !== 'create' && this.props.mode !== 'edit') 
            return
  
        !evt.all && evt.preventDefault();
        let filterPermissions = [];
        let filteredItems = [];

        this.state.chosenPermissionsSet.map(item => {
             this.enforcer.permissions.map( (permission) => {
                permission.id === item && filteredItems.push(permission);
             } );
        });

        filterPermissions = this.enforcer.permissions.filter(item => !filteredItems.includes(item));
        
        if (evt.all) {
            this.enforcer.chosenPermissions = this.enforcer.chosenPermissions.merge(filterPermissions);
            this.enforcer.permissions = [];
        } else {
            this.enforcer.chosenPermissions = this.enforcer.chosenPermissions.merge(filteredItems);
            this.enforcer.permissions = filterPermissions;
        }
        
        this.setState({chosenPermissionsSet: [] });
        this.props.setChosenPermissions(this.enforcer.chosenPermissions);
        // this.forceUpdate();
    }

    removePermission(evt) {
        if ( this.props.mode !== 'create' && this.props.mode !== 'edit') 
            return

        !evt.all && evt.preventDefault();
        let filterPermissions = [];
        let removalItems = [];
        
        this.state.removalPermiisonsSet.map(item => {
             this.enforcer.chosenPermissions.map( (permission) => {
                permission.id === item && removalItems.push(permission);
             } );
        });

        filterPermissions = this.enforcer.chosenPermissions.filter(item => !removalItems.includes(item));
        if (evt.all) {
            this.enforcer.permissions = this.enforcer.permissions.merge(filterPermissions);
            this.enforcer.chosenPermissions = [];
        }else {
            this.enforcer.chosenPermissions = filterPermissions;
            let merger = this.enforcer.permissions.merge(removalItems);
            this.enforcer.permissions = merger;
        }

        this.setState({removalPermiisonsSet: [] });
        this.props.setChosenPermissions(this.enforcer.chosenPermissions);
    }

    willHandleSelection(selections, delegate = false) {
        this.setState({chosenPermissionsSet: selections});
        delegate && this.addPermission({'all': true});
    }

    willHandleRemoval(selections, delegate = false) {
        this.setState({removalPermiisonsSet: selections });
        delegate && this.removePermission({'all': true});
    }

    changeMode(evt) {
        this.props.setRoleType(this.roleType[evt.currentTarget.id]);
        this.props.setView('createRole');
        this.props.setMode('create');
    }

    roleNameDidChange(evt) {
        evt.type === 'blur' && this.props.roleNameChanded(evt.target.innerHTML);
        this.setState({
            formErrors: {
                ...this.state.formErrors,
                roleName: evt.target.innerHTML.empty() ? true : false
            }
        });
    }

    didSelectRoleType(evt) {
        this.props.setRoleType(this.roleType[evt.currentTarget.getAttribute('roletype')]);
        if (this.enforcer.isPermitted('role', 'change') || this.enforcer.isPermitted('role', 'create')) { 
        }
    }

    showRoleChooseView() {
        this.props.didChooseRole(null);
        this.props.seletedSuperuserRole(false);
        this.props.setChosenPermissions([]);
        this.props.setView('roleChooser');
        this.props.setMode('');
        this.setState({singleSuperuser: false});
    }

    didSelectRole(role) { 
        const permissionSet = this.filterAssignedPermssions(this.props.systemPermissions, role.permissions);
        this.enforcer.chosenPermissions = permissionSet.chosenPermissions;
        this.enforcer.permissions = permissionSet.availablePermissions;
        this.props.didChooseRole(role);
        this.props.roleNameChanded(role.role);
        this.props.setRoleType(`${role.type.toLowerCase()}Role`);
        this.props.setChosenPermissions(this.enforcer.chosenPermissions);
        this.props.setView('createRole');

        if ( !role.superRole ) {
            this.props.setMode('view');
            this.setState({singleSuperuser: false});
        } else {
            const superRoles = this.props.roles.filter((role) => {
                if ( role.superRole && role.type === 'User' ) {
                    return role;
                }
            });
            superRoles.length === 1 && this.props.setMode('');
            superRoles.length === 1? this.setState({singleSuperuser: true}) : this.setState({singleSuperuser: false});
        }
    }

    didSelectSuperuserRole(evt) {
        this.props.seletedSuperuserRole(evt.target.checked);
    } 

    enableEditingMode(){
        this.props.setMode('edit');
    }

    submitData(evt) {
        
        let formPayload = {};
        if ( this.props.mode === 'create' && evt.action === 'save' ) {
            formPayload = {
                requestType: REQUESTS_TYPE.CREATE, 
                roleName: this.props.roleName,
                type: this.props.roleType.replace(/Role/, '').capitalize(),
                superRole: this.props.superRole,
                workspace: this.props.workspace.id,
                permissions: this.extractPermissionIds(this.props.chosenPermissions)
            }
        }

        if ( evt.action === 'save' && this.props.mode === 'edit') {
            formPayload = {
                requestType: REQUESTS_TYPE.UPDATE, 
                id: this.props.selectedRole.id,
                roleName: this.props.roleName,
                type: this.props.roleType.replace(/Role/, '').capitalize(),
                superRole: this.props.superRole,
                permissions: this.extractPermissionIds(this.props.chosenPermissions)
            }
        }

        if (evt.action === 'delete') {
            formPayload = {
                requestType:  REQUESTS_TYPE.DELETE,
                role: this.props.selectedRole.id,
            }
            this.props.apiRequestManager.queueRequest(actionTypes.PERMISSIONS_ROLE_SUBMIT_DATA, formPayload);
        }

        const formErrors = this.validateFields(formPayload);
    
        if (Object.blank(formErrors) ) {
            if (formPayload.roleName !== 'Name') {
                this.props.apiRequestManager.queueRequest(actionTypes.PERMISSIONS_ROLE_SUBMIT_DATA, formPayload);
            }
        } else {
            this.setState({
                formErrors: formErrors
            });
        }
    }

    validateFields(formData) {
        let validations = {};

        if (this.props.validator.isValueMissing(formData.roleName)) {
            validations.roleName = true;
        }
        return  validations;  
    }

    handlerBack(evt) {
        this.props.toPreviousView (this.props.view);
    }

    extractPermissionIds(permissions) {
        const idList = []
        permissions.map((item) => idList.push(item.id));
        return idList;
    }
    filterAssignedPermssions(systemPermissions, rolesPermissions){
        let filterPermissions = [];
        let filteredItems = [];

        rolesPermissions.map(item => {
            systemPermissions.map( (permission) => {
                permission.id === item.id && filteredItems.push(permission);
             } );
        });

        filterPermissions = systemPermissions.filter(item => !filteredItems.includes(item));
        return {
            chosenPermissions: filteredItems,
            availablePermissions: filterPermissions 
        }
    }

    getPromptMessage() {
        const apiOutcome = this.props.apiRequestManager.getApiOutcome(actionTypes.PERMISSIONS_ROLE_SUBMIT_DATA, true) ?? {};
        return getPromptMessageProps('permission', apiOutcome);
    }
    
    render() {
        const selectWorkspace = this.props.roleType === 'workspaceRole' ? {'checked': true } : '';
        const selectUser = this.props.roleType === 'userRole' ?   true  : false;
        const selectedRole = this.props.selectedRole !== undefined ? this.props.selectedRole : null;

        if ( !selectedRole && this.props.view === 'createRole'  ) {
            const filterAgainsPermissions = this.props.chosenPermissions !== undefined ? this.props.chosenPermissions : [];
            const permissionsSet = this.filterAssignedPermssions(this.props.systemPermissions, filterAgainsPermissions);

            this.enforcer.permissions =  permissionsSet.availablePermissions;   
            this.enforcer.chosenPermissions = permissionsSet.chosenPermissions;
        } else if (selectedRole)  {
            
            const filterAgainsPermissions = this.props.chosenPermissions !== undefined ? this.props.chosenPermissions : selectedRole.permissions;
            const permissionsSet = this.filterAssignedPermssions(this.props.systemPermissions, filterAgainsPermissions);
            
            this.enforcer.permissions = permissionsSet.availablePermissions;
            this.enforcer.chosenPermissions = permissionsSet.chosenPermissions;
        }

        let availablePermissions = this.enforcer.permissions ;
        let chosenPermissions = this.enforcer.chosenPermissions;
        let availableRoles = this.props.roles !== undefined ? this.props.roles : [];
        
        const textValue = this.props.view === 'roleChosser'  || !selectedRole ? this.state.roleName : this.props.roleName !== undefined ? this.props.roleName :  selectedRole.role ;
        
        const attributes = {
            disabledPerms : false,
            superRole:  selectedRole && (this.props.mode === 'view'| this.props.mode === '') ? selectedRole.superRole :  this.props.mode === 'edit' && this.props.superRole === undefined && selectedRole ? selectedRole.superRole : this.props.superRole ,
            mode: this.props.mode
        }  
    
        const actionBarCallback = {
            'edit': this.enableEditingMode,
            'save': this.submitData,
            'delete': this.submitData,
            'back': this.handlerBack
        };

        const showSuperuserRoleField = true; // this.props.roleType == this.roleType.userRole ? true: false;
        const disableRoleFields = this.props.mode === 'view' || this.props.mode === '' || !this.enforcer.isPermitted('role', 'change') ? { 'disabled': true } : '' ;
        const disableNameFields = this.props.mode === 'view' || this.props.mode === '' || !this.enforcer.isPermitted('role', 'change') ?  false  : true ;

        const saveTextSufix =this.props.mode === 'create' ? 'Role' : '';
        const dialogProps = this.getPromptMessage();
       
        return (
            <React.Fragment>
                <ActionBar entityContext={'permission'} actionResponsers={ actionBarCallback } saveTextSufix={saveTextSufix} title={'Roles & Permissions'} permissionContext={ this.props.mode } />
                <div className="content-layout-wrap">
                    { dialogProps.visible && 
                        <PromptMessage {...dialogProps} />
                    } 
                    <div className="view-layout-content">
                    <div className="default-Ly-ct">
                <Container>
                    <Row>
                        <Col lg={4}>
                            <div className="roles-section">
                            <p className="title">Roles list</p>
                            <div className="roles-inner">
                                <Authority allowed="create" entity="role">
                                    <div className="role-create">
                                        <Button onClick={this.showRoleChooseView}>
                                            <span className="icon"><img src={ getIconUrl("plus") } alt="icon"/></span>
                                            Create Role
                                        </Button>
                                    </div>
                                </Authority>
                                <div className="roles-wrapper">
                                    <Authority allowed="view" entity="role">
                                    <List className="roles-list">
                                        {
                                            availableRoles.map((role, i) => {
                                                return <Role 
                                                key={i} rowIndex={i} role={role} clickHandler={this.didSelectRole}
                                                selected={selectedRole && selectedRole.id === role.id ? true : false} />
                                                ;
                                            })
                                        }
                                    </List>
                                    </Authority>
                                </div>     
                            </div>
                            </div>
                        </Col>
                        <Col lg={8}>
                            {
                                (this.props.view === this.state.initialView) ? <RoleChooser clientLayout={this.props.clientDashboard} callback={this.changeMode} />  :
                            
                            <React.Fragment >
                                <Authority entity={'permission'} allowed={'create'} alternateView > 
                                <div className="vertical-divider"></div>
                                <div className="roles-content">
                                    { this.state.singleSuperuser &&
                                        <span className='lock-icon'>
                                            <img src={getIconUrl('lock')} alt='' />
                                        </span>
                                    }
                                    <Form onSubmit={(event) => event.preventDefault()}>
                                        <FormGroup>
                                          <ValidateField required>
                                            <span {...disableRoleFields } ref={this.nameInputFiledRef} contentEditable={disableNameFields} id="role-name" className="name-field" onInput={this.roleNameDidChange} onBlur={this.roleNameDidChange} suppressContentEditableWarning={true}>{ textValue  }</span>
                                            <ErrorFeedback 
                                                show={this.state.formErrors.roleName}
                                                warning
                                                filter='valueMissing'
                                                message={<ErrorMessage message ='Role  Name is Required' />} />
                                            </ValidateField>
                                        </FormGroup>
                                        <FormGroup>
                                            <h3 className="form-group-label">Role</h3>
                                            <Label className="ip-radio-pill">
                                                <Input {...disableRoleFields } roletype={this.roleType.userRole} type="radio" name="roleType" onChange={this.didSelectRoleType} value={undefined}
                                                checked={selectUser} />{' '}
                                                <span className="radioselect"></span>
                                                <span className="text">User</span>
                                            </Label>
                                            { !this.state.singleSuperuser && !this.props.clientDashboard  && 
                                            <Label className="ip-radio-pill">
                                                <Input {...disableRoleFields }  roletype={this.roleType.workspaceRole}type="radio" name="roleType" onChange={this.didSelectRoleType} value={undefined}
                                                checked={!selectUser}/>{' '}
                                                    <span className="radioselect"></span>
                                                    <span className="text">Workspace</span>
                                            </Label>
                                            }       
                                        </FormGroup>
                                        
                                        <FormGroup>
                                        { showSuperuserRoleField && !this.props.clientDashboard ? 
                                            <React.Fragment>
                                            <label className="ip-checkbox">Superuser Role privilege?
                                                <input {...disableRoleFields } checked={ attributes.superRole } onChange={ this.didSelectSuperuserRole } type="checkbox" value={undefined} />
                                                <span className="checkmark"></span>
                                            </label>
                                            <FormText>
                                            Designates that this role has all permissions without explicitly assigning them.
                                            </FormText>
                                            </React.Fragment>
                                             : null
                                        }
                                        </FormGroup>
                                       
                                        <FormGroup>
                                            <h3 className="form-group-label">Permissions</h3>
                                        </FormGroup>

                                        <FormGroup>
                                            <div className="permission-section">
                                                <div className="permission-selector-widget">
                                                    <PermissionSelector  attributes={
                                                        attributes
                                                    } handler={this.willHandleSelection } optionsData={availablePermissions}titlePrefix="Available"  />
                                                    
                                                    <List className="selector-chooser">
                                                        <li>
                                                            <Button onClick={ this.addPermission} className="selector-button add"><img src={ getIconUrl("longArrow") } alt="icon"/></Button>
                                                        </li>
                                                        <li>
                                                            <Button onClick={ this.removePermission} className="selector-button remove"><img src={ getIconUrl("longArrow") } alt="icon"/></Button>
                                                        </li>
                                                    </List>
                                                    <PermissionSelector attributes={
                                                        attributes
                                                    } handler={this.willHandleRemoval } optionsData={chosenPermissions} titlePrefix="Chosen" chosen/>
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </Form>
                                    <p className="info-text">Specify  multiple permissions by  Holding down “Control”, or “Command” on a Mac, to select more than one.</p>
                                </div>
                                </Authority>
                            </React.Fragment>
                            } 
                        </Col>
                    </Row>
                </Container>
                </div>
                </div>
                </div>         
            </React.Fragment>
        );
    }
}

const PermissionRoleView = (props) => {
    return (
        <PermissionRole {...props } />
    );
};

let ConfigPermissionRoleView = setupPage(PermissionRole.pageOption)(PermissionRoleView);
ConfigPermissionRoleView =  connect(mapStateToProps, mapDispatchToProps)(ConfigPermissionRoleView)
export {  ConfigPermissionRoleView };