import React from 'react';

import {
    Section,
} from '../../components';
import { FORM_TYPE, STATUS } from '../../common/constant';
import './ResultSheet.style.scss';
import { toResultDateFormat, capitializeSentence, getInitials } from '../../utility/helpers';
import { getIconUrl } from '../../common/images-catalogue';

export class ResultSheetPrintTemplate extends React.PureComponent {

    constructor(props) {
        super(props);
        
        this.getValueFromClinicalFeatures = this.getValueFromClinicalFeatures.bind(this);
        this.standardizeLabel = this.standardizeLabel.bind(this);
    }

    getValueFromClinicalFeatures(specimenRecord, matchingLabel) {
        const value = specimenRecord.clinicalFeatures.filter(item =>(
            this.standardizeLabel(item.label) === this.standardizeLabel(matchingLabel)
        ))[0]?.value;

        if(Array.isArray(value) || value === undefined || value === null) return 'N/A';

        return value;
    }

    standardizeLabel(label) {
        return String(label).toLocaleLowerCase().replace(/[^\w\s]/gi,'')
    }

    buildDiagnosisText(diagnosisArray) {
        const mapResult =  diagnosisArray.map(result => {
            const findings = result.findings;
            return result.description + (findings ? `, ${findings}. ` : '.');
        });

        return mapResult.join(' ');
    }

    buildPrintGroup(clinicalFeatures, printGroups = []) {
        const renderGroup = [];

        /**
         * This work around is to ensure the order of the print group is followed. For some reason using
         * objects to store the groupId as keys end up 'sorting' the object, destroying the order.
        */

        printGroups = this.props.specimenRecord.formType === FORM_TYPE.NonGynec ? printGroups.reverse() : printGroups;
        printGroups.forEach(group => renderGroup.push({ id: group.id , groupName: group.name, groupItems: [] }));

        for (const feature of clinicalFeatures) {
            const clinicalItem = feature;

            const group = renderGroup.find(group => group.id === clinicalItem.printGroupId);
            if (group) {
                group.groupItems.unshift(clinicalItem);
            }
        }

        return renderGroup;
    }

    render () {

        const specimenRecord = this.props.specimenRecord;
        const specimenSupervisor = this.props.specimenSupervisor;        
        const isGynecology = specimenRecord.formType === FORM_TYPE.Gynec ? true : false;
        
        const dateReceived = specimenRecord.requisitionItem ? specimenRecord.requisitionItem.dateCreated : '--';
        const dateProcessed = specimenRecord.statuses.find((item) => item.status === STATUS.Processing);
        const firstSpecimen = specimenRecord.specimens[0];

        const referenceDoctorName = specimenRecord.doctor ? specimenRecord.doctor.split(' ') : null
        const clientName = !specimenRecord.client.officeName.empty() ? specimenRecord.client.officeName :  `${specimenRecord.client.firstName} ${specimenRecord.client.lastName}`;

        const clientAddress = !Object.blank(specimenRecord.client.address) ? specimenRecord.client.address :
        null;

        let diagnosis = [];
        const resultEntries = specimenRecord.resultSheet?.resultEntries || [];
        for (let i = 0;  i < resultEntries.length ; i++ ) {
            diagnosis = diagnosis.concat(specimenRecord.resultSheet.resultEntries[i].resultLines);
        }

        const reports = specimenRecord.resultSheet?.reports || [];
        const isProvisional = reports.length === 0;
        const segmentClinicalFeaturesBlocks = this.buildPrintGroup(specimenRecord.clinicalFeatures, this.props.printGroups || []);

        const patientDateOfBirth = specimenRecord.patient.dateOfBirth !== specimenRecord.patient.dateRegistered ? specimenRecord.patient.dateOfBirth : '--'
        
        return (
        <div id="print-frame">
            <div className="resultsheet print-container">
                <div className="printable-area ">
                    <Section className="header-sec">
                        <table>
                            <tbody>
                            <tr>
                            <td className="logo-td">
                                <div className="cmpy-logo">
                                    <img src={getIconUrl('logo')} alt='' />
                                </div>
                            </td>
                            <td>
                                <div className="company-info">
                                    <h1 className="heading">CytoLabs Associates Ltd</h1>
                                    <h3 className="sub-heading"> </h3>
                                    <p className="heading-info">Telephone: 876-616-2671</p>
                                    <p className="heading-info">Email: cytolabs.ja@gmail.com</p>
                                </div>
                            </td>
                            <td>
                                <address>
                                    <p className="address-line">Shop #12, 139 Maxfield Avenue</p>
                                    <p className="address-line">Kingston 10</p>
                                    <p className="address-line">Jamaica</p>
                                </address>
                            </td>
                            </tr>
                            </tbody>
                        </table>
                    </Section>
                    
                    <Section className="client-patient-sec">
                        <div className="tables-ly">
                            <div className="table-col1">
                                <table className="print-table">
                                    <thead>
                                        <tr>
                                        <th className="col-size cl4"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="main-td">
                                            <td>Labno <span className="strong">{specimenRecord.labNumber || '--'}</span></td>
                                        </tr>
                                        <tr>
                                            <td>{referenceDoctorName ?  getInitials(referenceDoctorName.join(' ')) : '--'}</td>
                                        </tr>
                                        <tr>
                                            <td>{clientName}</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <address>
                                                    <p className="address-line">{clientAddress?.street || ''}</p>
                                                    <p className="address-line">{clientAddress?.streetTwo || ''}</p>
                                                    <p className="address-line">{clientAddress?.city || ''}</p>
                                                    <p className="address-line">{clientAddress?.state || ''}</p>
                                                    <p className="address-line">{clientAddress?.country || ''}</p>
                                                </address>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="table-col2">
                                <table className="print-table pt-info">
                                    <thead>
                                        <tr>
                                        <th className="col-size cl3" ></th>
                                        <th className="col-size cl5"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="main-td">
                                            <td>Patient</td>
                                            <td className="strong">{specimenRecord.patient.lastName}, {specimenRecord.patient.firstName}</td>
                                        </tr>
                                        <tr>
                                            <td>Tel No</td>
                                            <td>(C) {specimenRecord.patient.phoneNumber || '--'}</td>
                                        </tr>
                                        <tr>
                                            <td>Age (Sex)</td>
                                            <td>{specimenRecord.patient.age}y ({specimenRecord.patient.gender[0]}) DoB {patientDateOfBirth}</td>
                                        </tr>
                                        <tr>
                                            <td>Ref Dr</td>
                                            <td>{ referenceDoctorName ?  `${referenceDoctorName.join(' ')}` : '--'}</td>
                                        </tr>
                                        <tr>
                                            <td>Clinic</td>
                                            <td className="string">{ !specimenRecord.client.officeName.empty() ? specimenRecord.client.officeName : '--'}</td>
                                        </tr>
                                        <tr>
                                            <td>Med Rec #</td>
                                            <td className="string">{specimenRecord.patient.registrationNo}</td>
                                        </tr>
                                        <tr>
                                            <td>Coll/Sent</td>
                                            <td>{firstSpecimen.dateReceived && toResultDateFormat(firstSpecimen.dateReceived)} (*) <br />(*) =Collection time not stated</td>
                                        </tr>
                                        <tr>
                                            <td>Rec`d/Reg`d</td>
                                            <td>{dateReceived} / {toResultDateFormat(dateProcessed.date)}</td>
                                        </tr>
                                        <tr>
                                            <td>Report</td>
                                            <td>{toResultDateFormat(specimenSupervisor.getAppovedDate())}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Section>
                    
                    <Section className="result-sec">
                        <div className="report-title">
                            <h2 className="title">Laboratory Report</h2>
                        </div>
                        <div className="report-block">
                            <div className="specimen-line">
                                <div className="label">Specimen (s)</div>
                                <div className="value">{ specimenRecord.formType === FORM_TYPE.Gynec ? 'Pap Smear' : `${capitializeSentence(firstSpecimen.type)}`}</div>
                            </div>
                        </div>
                        
                        { (!isGynecology && isProvisional) ? null : segmentClinicalFeaturesBlocks.map((printGroup, id) => {
                            return (<React.Fragment  key={id} >
                               { /* Render Section  */ }
                               <div className="report-block details">

                                <h3 className="block-title uppercase">{printGroup.groupName?.toUpperCase()} {isProvisional ? '*Provisional*' : ''}</h3>

                                { /* Clinical feature Items  */
                                
                                printGroup.groupItems.map((clinicalItem, i) => {
                                    /* Render Individual Clinical Items */
                                    return (!clinicalItem.isPrintable || clinicalItem.value.empty()) ? <div key={i}></div> 
                                    : 
                                    <div className='items-block' key={i}>
                                        <div key={i} className="report-line">
                                            <div className="label"><span className="mask">{ clinicalItem.label }</span>
                                            </div>
                                            <div className="value">{ clinicalItem.value }</div>
                                        </div>
                                     </div>
                                    }
                                )}
                                </div>
                            </React.Fragment>)     
                        })}

                        {!isProvisional && <div className="report-block">
                            <h3 className="block-title uppercase">{!isGynecology ? 'NON-' : ''}GYNAECOLOGICAL CYTOLOGY</h3>
                            <div className="diagnosis">
                                <div className="report-line">
                                    <div className="label">{isGynecology ? 'Diagnosis:': <h3 className="block-title uppercase">CYTOLOGICAL FINDINGS <br/></h3>}</div>
                                </div>
                                <div className="report-line">
                                    <div className="label">{this.buildDiagnosisText(diagnosis)}</div>
                                </div>
                            </div>
                        </div>}

                        <div className="report-block">
                        { reports.map(
                            
                            (report, i) => {
                                const fullname = report.writtenBy;
                                const isCytologist = report.medicalEntry === 'Cytologist';
 
                                return (
                                <React.Fragment key={i}>
                                    <h3 className="block-title uppercase">{isCytologist ? <span>CYTOTECHNOLOGIST: {fullname}</span> : <span style={{textTransform: 'none', fontSize: '1rem'}}>Pathologist(s): </span>}</h3>
                                    {!isGynecology ? <div className="margin--left">{fullname}</div> : ''}
                                    <div className="report-line">
                                        <div className="content"><span className="block-title">Comments</span> : {report.content || '-'}</div>
                                    </div>
                                    <div className="report-line">
                                        <div className="content">Authorised by : {fullname} ({report.medicalEntry}) {toResultDateFormat(report.dateUpdated)}</div>
                                    </div>
                                    <div className="report-line">
                                        <div className="content">Signature : {report.signature ? <img className='report--signature' src={report.signature.fileDownloadUri} alt='' /> : report.digitalSignature}</div>
                                    </div>
                                </React.Fragment>)}
                            )}
                        </div>
                    </Section>
                    <div className="report-footer">
                        <p>--- End of Laboratory Report ---</p>
                    </div>
                </div>
            </div>
        </div>
      );
    }
  }

export default ResultSheetPrintTemplate;