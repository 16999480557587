import * as actionTypes from '../actionType';

export const loginActionCreator = (username, password) => ({
    type: actionTypes.LOGIN,
    payload: {
        username,
        password
    }
}); 
export const setLoginViewAction =  (view) => ({
    type: actionTypes.SET_LOGIN_VIEW,
    payload: view
});
export const authResultActionCreator = (responseBody) => ({
    type: actionTypes.AUTH_RESULTS,
    payload: responseBody
});
export const logoutActionCreator = () => ({
    type: actionTypes.LOGOUT,
}); 

export const userDetailsActionCreator = (userDetails) => ({
    type: actionTypes.USER_DETAILS,
    payload: userDetails
}); 

export const authUserRequestedActionCreator = () => ({
    type: actionTypes.REQEUST_AUTH_USER
});

export const authRefreshTokenAction = () => ({
    type: actionTypes.REFRESH_JWT_TOKEN
}); 
