import  * as actions  from '../store/actions';
import { ApiRequestManager } from './ApiRequestManager';
import { store } from '../store/store';
import * as actionTypes from '../store/actionType';
import { v4 as uuidv4 } from 'uuid';

import { commonDialect } from '../service/agent';


class FileUploader {
    
    supportedExtension = {
        png: 'img/png',
        jpg: 'jpg',
        jpeg: 'jpeg',
        pdf: 'pdf'
    };

    static _instance = null;
    requestManager = null;
    
    constructor() {
        this.requestManager = ApiRequestManager.getInstance(ApiRequestManager);
        this.requestManager.register(actionTypes.UPLOAD_FILE, this.uploadRequest); 
        this.requestManager.register(actionTypes.DOWNLOAD_FILE, this.downloadRequest);        
     }

    uploadRequest = (file) => store.dispatch(actions.fileUploadAction(file));
    downloadRequest = (file) => store.dispatch(actions.fileDownloadAction(file));
    downloadResponse = (file) => store.dispatch({
        type: actionTypes.DOWNLOAD_FILE_RESULT, 
        payload: file
    });

    upload(file, type) {
        if ( file?.name ) return;
        let formData = this.composeFormPayload(file, type);
        this.requestManager.queueRequest(actionTypes.UPLOAD_FILE, formData);
    }

    downloadFile(fileName) {
        commonDialect.fetchDownloadFile(fileName, this.downloadResponse);
        //this.requestManager.queueRequest(actionTypes.DOWNLOAD_FILE, fileName);
    }

    composeFormPayload(file, type) {

        var formData = new FormData();
        var uuid = uuidv4();
        var fileName = this.generateFileName(file);
        //formdata.append('file', file, 'hello.txt');
        formData.append("file", file, fileName);
        formData.append("docType", type);
        formData.append("fuuid", uuid);

        return formData;
    }

    generateFileName(file) {
        var uuid = uuidv4();
        var fileName = uuid + '.' + this.getFileExtension(file.name);
        return fileName;
    }

    getFileExtension(filename) {
        return filename.split('.').pop();
    }

    isFileSupported(file) {
        let ext = this.supportedExtension[this.getFileExtension(file)];
        return !ext || ext === undefined ? false : true;
    }

    static getInstance = (classInstance) => FileUploader._instance ? FileUploader._instance : (FileUploader._instance = new classInstance());
};

export default FileUploader;