import NetworkError  from "./NetworkError";
import strings from "../../common/strings";
class ApiError extends NetworkError {

    errorCode;
    errorResponse;

    constructor(
        errorCode = 1,
        errorMessage,
        type,
        detail,
        ...params
    ) {
        super(errorCode,
            errorMessage,
            type,
            detail,
            params);

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ApiError)
        }

        this.name = 'ApiError'
        this.message = errorMessage === null ? strings.defaultMessage : errorMessage 

        this.errorCode = errorCode

        this.errorResponse = {
            detail,
            type,
            message: (errorMessage === null || errorMessage === '') ? strings.defaultMessage : errorMessage ,
        }
    }
}

export default ApiError;