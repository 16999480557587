import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import { 
    ThemeConsumer,
} from '../../components';
import { setupPage } from '../../components/Layout/setupPage';
import './Dashboard.style.scss';
import { logoutActionCreator } from '../../store/actions/authenticate';
import * as actionTypes from '../../store/actionType';
import  * as actions  from '../../store/actions';
import { 
    FloatGrid as Grid,
    Container,
    Input,
    Label,
    Authority 

} from '../../components';

import { ClientLayout } from './ClientLayout';
import { MainLayout } from "./MainLayout";

import { ApiRequestManager } from '../../utility/ApiRequestManager';
import { PermissionEnforcer } from '../../utility/PermissionEnforcer';

const LAYOUT = {
    'count-specimens': { h: 7, md: 4 },
    'count-doctors': { h: 7, md: 4},
    'count-labs': { h: 7, md: 4 },
    'count-patients': { h: 7, md: 4 },
    
    'metric-v-target-users': { h: 7, md: 4 },
    'metric-v-target-sessions': { h: 7, md: 4 },
    'metric-v-target-pageviews': { h: 7, md: 4 },
}

const mapStateToProps = state => ({
    permissionEnforcer: PermissionEnforcer.getInstance(PermissionEnforcer),
    apiRequestManager : ApiRequestManager.getInstance(ApiRequestManager),
    authorizeUser: state.global.authorizeUser,
    clientDashboard: state.global.useClientDashboard,
    workspace: state.global.workspace,
    dashboardData: state.global.dashboardData
});
const mapDispatchToProps = dispatch => ({
    unLoad: () => dispatch(actions.dashboadViewUnloadAction()),
    getDashbaordData: (workspace) => dispatch(actions.getDashboardDataAction(workspace)), 
    onLogout: () => dispatch( logoutActionCreator() )
});

class Dashboard extends React.Component {

    static pageOption = {
        pageTitle: 'Role | Permission',
        grayBackground: true,
        disableBodyScroll: true
    };
    enforcer;

    state = {
        layouts: _.clone(LAYOUT)
    }

    constructor (props) {
        super(props);
        this.state = { };
        props.apiRequestManager.register(actionTypes.FETCH_DASHBOARD_DATA, props.getDashbaordData);
    }

    static getDerivedStateFromProps(props, state) {
        if ( !Object.blank(props.workspace) && !props.dashboardData && !props.apiRequestManager.inProgress(actionTypes.FETCH_DASHBOARD_DATA)) {
            props.apiRequestManager.queueRequest(actionTypes.FETCH_DASHBOARD_DATA, props.workspace.id);
        }
        return null;
    }

    resetLayout = () => {
        this.setState({
            layouts: _.clone(LAYOUT)
        })
    }
    
    componentDidMount() {
        this.props.onChangeTheme({
            color: 'light',
            style: 'primary',
            overrideStyle: false
        });
        document.title = "Dashboard";

        this.unlistenAbort = this.props.history.listen(() => { 
            this.props.apiRequestManager.abortRequest(actionTypes.FETCH_DASHBOARD_DATA);
        });
    }

    componentWillUnmount() {
        this.unlistenAbort();
        this.props.unLoad();
    }

    handleLogout = (event) => {
        this.props.onLogout()
    };

    render () {
        const dashboardTitle = this.props.clientDashboard ?'Dashboard' : 'Global Workspace  Dashboard';

        const dataPresent = this.props.dashboardData ? true : false;
        
        return (
            <div className='dashboard-content content-layout-wrap'>
               <div className="view-layout-content">
                   <div className="default-Ly-ct">
                        <Container fluid={false}>
                        <div className="navbar-brand">
                            <h1 className="dashboard-title">{ dashboardTitle } </h1>
                        </div>
                        
                        <ul className="nav navbar-nav pull-xs-right">
                            <li className="nav-item">
                                <div className="switch-group">
                                    <Label className="inline">Make Private</Label>
                                    <span className="switch-pill inline">
                                        <Input onChange={ () => console.log('test') } type="checkbox" id="active" />
                                        <Label for="active" id="active">Off</Label>
                                    </span>
                                </div>
                            </li>
                        </ul>
                        
                        { this.props.clientDashboard ? 
                            <ClientLayout {...this.props} />
                            :
                            <MainLayout {...this.props} />
                        }
                        
                        </Container>
                    </div>
                </div>
            </div>
        );
    }
}

const DashboardContextTheme = (props) =>
    <ThemeConsumer>
        {
            (themeState) => <Dashboard { ...themeState } { ...props } />
        }
</ThemeConsumer>


let ConfigDashboardContextThemeView = setupPage(Dashboard.pageOption)(DashboardContextTheme);
ConfigDashboardContextThemeView =  connect(mapStateToProps, mapDispatchToProps)(ConfigDashboardContextThemeView)

export default connect(mapStateToProps, mapDispatchToProps)(ConfigDashboardContextThemeView);
//export default connect(mapStateToProps, mapDispatchToProps)(DashboardContextTheme);