import { requests } from './agentDialect';

import { httpRequests } from './httpRequestDialect';
import {patientsArraySchema} from './schemas';
import { objectToQueryString } from '../utility/helpers';

export const fetchAllPatients = (payload) => {
    const {workspaceId, ...queryParams} = payload;
    queryParams['workspace'] = workspaceId;
    let uri = "/patients" ;
    uri = objectToQueryString(uri, queryParams);
    return requests.get(uri);
};
export const fetchPatient = (clientId) => {
    let uri =  `/patient/${clientId}`;
    return requests.get(uri);
};
export const fetchClientPatients = (payload) => {
    const {clientId, ...queryParams} = payload;
    queryParams['clientId'] = clientId;
    let uri =  '/patients/client';
    uri = objectToQueryString(uri, queryParams);
    return requests.get(uri);
};
export const submitPatient = (data) => {
    let uri = "/patient";
    return requests.formPost(uri, data.form);
};
export const updatePatient = (data) => {
    let uri = `/patient/update/${data.patientId}`;
    return requests.formPut(uri, data.form);
};
export const deletePatient = (data) => {
    let uri = `/patient/delete/${data.patientId}?workspace=${data.workspaceId}`;
    return requests.delete(uri);
};

export const getAllPatients = (forWorkspace) => {
    let uri = !forWorkspace ? "/patients" : `/patients?workspace=${forWorkspace}`;
    return httpRequests.get(uri, patientsArraySchema);
};

export const searchPatients = (payload) => {
    let uri = `/patients/search?q=${payload.searchTerm}`;
    uri += payload?.workspaceId ? `&workspace=${payload.workspaceId}` : '';
    return requests.get(uri);
};