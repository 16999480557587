
import {ofType } from 'redux-observable';
import { catchError, map, switchMap, mergeMap, takeUntil } from 'rxjs/operators';
import *  as actionTypes from '../actionType';
import { payrollDialect, checkReponse } from '../../service/agent';
import { of } from 'rxjs';
import * as actions from '../actions';
import { apiResponseActionCreator } from '../actions';


export const getAllPayrollsEpic = action$ => {
  return action$.pipe(
      ofType(actionTypes.FETCH_PAYROLLS),
      
      switchMap((action) => {
          return payrollDialect.fetchAllPayrolls().pipe(
             map(response => {
                  const responseData = checkReponse(response, action.type);
                  return apiResponseActionCreator(actionTypes.FETCHED_PAYROLLS_RESULTS, responseData);
              }),
              takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
             catchError(error => {
                  console.log("ERROR", error);
                  return of(apiResponseActionCreator(actionTypes.FETCHED_PAYROLLS_RESULTS, error));
             })
          );
      })
)};

export const getPayAdviceEpic = action$ => {
  return action$.pipe(
      ofType(actionTypes.FETCH_PAYADVICES),
      
      switchMap((action) => {
          return payrollDialect.fetchAllPayAdvice(action.payload.payrollId, action.payload.payAdviceId).pipe(
             map(response => {
                  const responseData = checkReponse(response, action.type);
                  return apiResponseActionCreator(actionTypes.FETCHED_PAYADVICES_RESULTS, responseData);
              }),
              takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
             catchError(error => {
                  console.log("ERROR", error);
                  return of(apiResponseActionCreator(actionTypes.FETCHED_PAYADVICES_RESULTS, error));
             })
          );
      })
)};

export const submitPayrollEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.PAYROLL_SUBMIT_DATA),
        
        mergeMap((action) => {
          const apiCalls = {
            'submit': payrollDialect.submitPayroll,
            'update': payrollDialect.updatePayroll
          }
            return apiCalls[action.payload.requestType](action.payload).pipe(
               map(response => {
                    const responseData = checkReponse(response, action.type, action.payload.requestType);
                    return apiResponseActionCreator(actionTypes.PAYROLL_SUBMITED_RESULTS, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
               catchError(error => {
                    console.log("ERROR", error);
                    return of(apiResponseActionCreator(actionTypes.PAYROLL_SUBMITED_RESULTS, error));
               }),
               
            );
        })
  )};