import React, { useRef } from 'react';
import classnames from 'classnames';
import { withPageConfig } from '../../components/Layout';
import { setupPage } from '../../components/Layout/setupPage';
import ActionBar from '../components/partials/ActionBar';
import Base64 from '../../utility/Base64Encoder';
import { FormValidator } from '../../utility/FormValidator';

import {
    Row,
    Container,
    Nav,
    Col,
    NavItem,
    NavLink as Link,
    TabContent,
    Button,
    TabPane,
    Section,
    Form, 
    FormGroup, 
    Label, 
    Input, 
    InputField,
    List,
    ValidateField,
    ErrorFeedback

} from '../../components';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import RoleItem from '../components/partials/RoleItem';
import { ErrorMessage } from '../components/partials/ErrorMessage';
import * as urls from '../../config/urls';
import * as helpers from '../../utility/helpers';
import * as actionTypes from '../../store/actionType';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
import  { PermissionEnforcer }  from '../../utility/PermissionEnforcer';
import FileUploader from '../../utility/FileUploader';
import './Users.style.scss';
import ConfirmModal from '../components/partials/ConfirmModal';
import { SEARCH_SETTINGS } from '../../config/searchable';
import { REQUESTS_TYPE, FEATURE_FLAG, VALIDATOR_DELEGATE_TYPES, VALIDATOR_DELEGATE_KEY_GROUP } from '../../common/constant';
import FEATURES  from '../../config/featureFlags';
import { PromptMessage, getPromptMessageProps } from '../components/partials/PromptMessage';
import text from '../../common/strings';
import userValidatorDelegate from '../../forms/userValidator.delegate';
import { getIconUrl } from '../../common/images-catalogue';

const mapStateToProps = (state) => ({
    permissionEnforcer : PermissionEnforcer.getInstance(PermissionEnforcer),
    apiRequestManager : ApiRequestManager.getInstance(ApiRequestManager),
    roles: state.users.roles ? state.users.roles : [],
    workspace: state.global.workspace,
    validator: FormValidator.getInstance(FormValidator),
    isClientLayout: state.global.useClientDashboard,
    mode: state.global.actionBarMode,
    ...state.users,
});

const mapDispatchToProps = (dispatch) => ({
    onLoad: (mode) => dispatch(actions.userCreateViewLoadedAction(mode)),
    unLoad: () => dispatch(actions.userCreateViewUnloadAction()),
    getRoles: (workspace) => dispatch(actions.getAllRolesActionCreator(workspace)),
    setMode: (mode) => dispatch(actions.actionBarModeAction(mode)),
    setAssignedRole: (filteredRoleSet) => dispatch(actions.userAssignedRoleAction(filteredRoleSet)),
    userFieldDidChange: (fieldWithValue) => dispatch(actions.userFieldChangeAction(fieldWithValue)),
    submitData : (data) => dispatch(actions.submitUserAction(data)),
    setUserFormData: (data) => dispatch(actions.setUpdateUserDataAction(data))
}); 

const AuthorizerTabPane = (props) => {
    const {previewImage, userFieldDidChange, formData} = props;
    let imageRef = useRef<HTMLInputElement>(null);

    const authorizerTypes ={
        cytologist: 'Cytologist',
        pathologist: 'Pathologist'
    };

    const gender ={
        male: 'Male',
        female: 'Female'
    };

    const signatureChange = (evt) => {  
        imageRef = {
            current: imageRef
        };
        previewImage(evt, imageRef);
    };
    const authorizerPillSwitches = (event) => {
        const fieldSet = {
            authorizerType : event.target.value 
        };
        userFieldDidChange(fieldSet);
    }
    const genderPillSwitches = (event) => {
        const fieldSet = {
            gender : event.target.value 
        };
        userFieldDidChange(fieldSet);
    }

    const didType = (event) => {
        const fieldSet = {
            [event.target.name] : event.target.value 
        };
        userFieldDidChange(fieldSet);
    }
    
    const selectedType = formData.authorizerType === '' ? true : formData.authorizerType === authorizerTypes.cytologist ? true : false;

    const selectedGender = formData.gender === '' ? true : formData.gender === gender.male ? true : false;

    const signatureSrc = formData.signature ? typeof formData.signature === 'string'? formData.signature :  URL.createObjectURL(formData.signature) : getIconUrl('signature');

    return (
        <React.Fragment>
            <div>
                <Row>
                    <Col>  
                    <Section className="type">
                        <div className="section-head simple">
                            <h3>Type</h3>
                        </div>
                        <div className="section-body">
                            <FormGroup className="section-inner">
                                <Label className="ip-radio-pill">
                                    <Input onChange={ authorizerPillSwitches } type="radio" name="authorizerType" value={authorizerTypes.cytologist} checked={selectedType} />{' '}
                                    <span className="radioselect"></span>
                                    <span className="text">Cytologist</span>
                                </Label>
                                <Label className="ip-radio-pill">
                                <Input onChange={ authorizerPillSwitches } type="radio" name="authorizerType"value={authorizerTypes.pathologist} checked={!selectedType}/>{' '}
                                    <span className="radioselect"></span>
                                    <span className="text">Pathologist</span>
                                </Label>
                            </FormGroup>
                        </div>
                        <div className="section-body">
                            <FormGroup className="section-inner">
                                <Label className="ip-radio-pill">
                                    <Input onChange={genderPillSwitches} type="radio" name="gender" value={gender.male} checked={ selectedGender } />{' '}
                                    <span className="radioselect"></span>
                                    <span className="text">Male</span>
                                </Label>
                                <Label className="ip-radio-pill">
                                <Input onChange={genderPillSwitches} type="radio" name="gender" value={gender.female} checked={ !selectedGender }/>{' '}
                                    <span className="radioselect"></span>
                                    <span className="text">Female</span>
                                </Label>
                            </FormGroup>
                        </div>
                    </Section>
                    <Section className="">
                        <FormGroup className="section-inner">
                            <Label>Phone Number</Label>
                            <InputField onChange={ didType } id="phoneNumber" type="text" name={"phoneNumber"} className="ip-text-default" placeholder="888-888-8888" value={formData.phoneNumber} />
                        </FormGroup>
                        <FormGroup className="section-inner">
                            <Label>Digital Signature</Label>
                            <InputField onChange={ didType } id="digitalSignature" type="text" name={"digitalSignature"} className="ip-text-default" placeholder="J. Brown" value={formData.digitalSignature} />
                        </FormGroup>

                        <FormGroup className="section-inner">
                            <Label>Signature</Label>
                            <div className="image-thumbnail-box signature">
                                <div className="image-selector">
                                    <img 
                                    ref={node => { imageRef = node; }}
                                    id="signaturePreview"
                                    src={signatureSrc}
                                    alt=''></img>
                                    <Button><span>Edit</span></Button>
                                    <div className="input-field">
                                        <Input type="file"
                                        onChange={signatureChange}
                                            name="signature-image" accept="image/*" />
                                    </div>
                                </div>
                            </div>
                        </FormGroup>

                    </Section>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
}
 
class CrateUpdate extends React.Component {
    
    static pageOption = {
        grayBackground: true,
        disableBodyScroll: true,
        searchContext: SEARCH_SETTINGS.entities.user.name
    };

    constructor(props) {
        super(props);
        
        props.apiRequestManager.register(actionTypes.FETCH_ROLES, props.getRoles);
        props.apiRequestManager.register(actionTypes.USER_SUBMIT_DATA, props.submitData);

        this.state = {
            activeTab: '1',
            deleteDialogVisible: false,
            updatePassword: false,
            deleteContextName: 'item',
            deleleContextItem: {},
            formErrors: {}
        };

        this.userType = {
            'authorizer': 'authorizer',
            'standard': 'standard',
            'staff': 'staff'
        }

        //References
        this.assignedRoleWell = React.createRef();
        this.profileImage = React.createRef();

        //Add event listeners
        this.toggle = this.toggle.bind(this);
        this.didSelectRole = this.didSelectRole.bind(this); 
        this.handlerBack = this.handlerBack.bind(this);
        this.handleTextField = this.handleTextField.bind(this);
        this.handlePillSwitches = this.handlePillSwitches.bind(this);
        this.handleToggleSwitches = this.handleToggleSwitches.bind(this);
        this.composeUserName = this.composeUserName.bind(this);
        this.submitFormData = this.submitFormData.bind(this);
        this.updateUserData = this.updateUserData.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.previewImage = this.previewImage.bind(this);
        this.showDeleteDialog = this.showDeleteDialog.bind(this);
        this.onConfirmDelete = this.onConfirmDelete.bind(this);
        this.cancelDelete = this.cancelDelete.bind(this);
        this.submitDelete = this.submitDelete.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.willUpdatePassword = this.willUpdatePassword.bind(this);
    }

    componentDidMount() {
        this.props.onLoad();
        const mode = this.props.currentUser ? 'edit' : 'create'; 
        this.props.setMode(mode);
        this.setFormDefaults();
        this.unlistenAbort = this.props.history.listen(() => { 
            this.props.apiRequestManager.abortRequest(actionTypes.FETCH_ROLES);
        });

        this.props.validator.addDelegate(VALIDATOR_DELEGATE_TYPES.user, userValidatorDelegate);
    }

    shouldComponentUpdate(props, state) { 

        if (props.currentUser && props.userForm.userId === null && props.roles 
            && !props.roles.empty()) {
            const formData = this.composeFormData(props.currentUser);
            props.userFieldDidChange(formData);
            this.didSelectRole(props.currentUser.role, props.roles);
        }
        return true;
    }

    static getDerivedStateFromProps(props, state) {
        if ( !props.roles && !props.apiRequestManager.inProgress(actionTypes.FETCH_ROLES) && props.workspace) {
            props.apiRequestManager.queueRequest(actionTypes.FETCH_ROLES, props.workspace.id);
        }
        return null;
    }
    componentWillUnmount() {
        this.props.apiRequestManager.terminateAll();
        this.unlistenAbort();
        this.props.unLoad();
    }

    setFormDefaults() {
        const fieldset = {
            userType : this.props.isClientLayout ? this.userType.staff : this.userType.standard,
            active: true,
            authorizerType: 'Cytologist',
            gender: 'Male',
            secondary: this.props.isClientLayout ? true : false     
        }
        this.props.userFieldDidChange(fieldset);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({ activeTab: tab });
        }
    }

    didSelectRole(role, allRoles) {
        var filterRolesSet =  null;
        if ( role.assigned ) {
            role.assigned = false;
            let roles = this.props.availableRoles;
            roles.push(role);
            filterRolesSet =  {
                chosenRole: null,
                availableRoles: roles
            };
            this.assignedRoleWell.current.classList.add('removed');
        } else {
            filterRolesSet =  this.filterAssignedRoles(allRoles !== undefined ? allRoles : this.props.roles, role);
            this.assignedRoleWell.current.classList.remove('removed');
        }
        this.props.setAssignedRole(filterRolesSet);
    }

    filterAssignedRoles(availableRoles, selectedRole) {
        //this.props.assignedRole && availableRoles.push(this.props.assignedRole);
        var filteredRoles = availableRoles.filter(role => role.id !== selectedRole.id);
        selectedRole.assigned = true;
        return {
            chosenRole: selectedRole,
            availableRoles: filteredRoles 
        }
    }

    handleTextField  (event) {
        let fieldSet = {
           [event.target.name] : event.target.value 
         };

        if (event.target.name === 'password'|| event.target.name === 'passwordTwo') {
            fieldSet = {
                [event.target.name] : Base64.encode(event.target.value)
            };
        }

        const key = event.target.name;

        //handle password checks
        let passwordErrors = {};
        if(String(key).includes('password')){
            let value2 = ''
            if(key === VALIDATOR_DELEGATE_KEY_GROUP.formFields.password){
                value2 = this.props.userForm.passwordTwo;
            }else{
                value2 = this.props.userForm.password;
            }

            passwordErrors = {
                ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.user,VALIDATOR_DELEGATE_KEY_GROUP.formFields[key], fieldSet[key], value2)
            }
        }

        this.setState({
            formErrors: {
                ...this.state.formErrors,
                ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.user,VALIDATOR_DELEGATE_KEY_GROUP.formFields[key], event.target.value),
                ...passwordErrors,
            }
        });

        this.props.userFieldDidChange(fieldSet);
    }
    handlePillSwitches  (event) {
        var fieldSet = {
            userType : event.target.value 
        };
        this.props.userFieldDidChange(fieldSet);
    }
    handleToggleSwitches (event) {
        var fieldSet = {};

        switch(event.currentTarget.id) {
            case 'active':
                fieldSet = {
                    'active' : event.target.checked 
                };
                break;
            case 'secondary':
                fieldSet = {
                    'secondary' : this.props.isClientLayout ? true : event.target.checked 
                };
                break;
            case 'blocked':
                fieldSet = {
                    'blocked' : event.target.checked 
                };
                break;
            case 'twoFactorAuth':
                fieldSet = {
                    'twoFactorAuth' : event.target.checked 
                };
                break; 
            default: 
        }
        this.props.userFieldDidChange(fieldSet);
    }
    composeUserName(event) {
        if (this.props.currentUser) return;
        
        event.type !== 'keydown' && event.preventDefault();
        var firstName = event.target.name === 'firstName ' ? event.target.value.substr(0, 1) : this.props.userForm.firstName.substr(0, 1);

        var lastName = event.target.name === 'lastName ' ? event.target.value : this.props.userForm.lastName;
        var useCode = lastName === '' ?  '' : helpers.generateDigits(3);
        var userName = `${ firstName.capitalize() }${ lastName.replace(/\s/g, 'X').capitalize() }${ useCode }`;

        const fieldSet = { 'username' : userName };
        this.props.userFieldDidChange(fieldSet);
        return userName;
    }

    willUpdatePassword() {
        this.setState({updatePassword: true});
    }

    submitFormData(evt) {
        var isUpdate = this.props.mode === 'edit' ? true : false;
        const uploader = FileUploader.getInstance(FileUploader);

        let {profileImage, signature, ...otherFields} = this.props.userForm;
        var formData = new FormData();
        formData.append('role', this.props.assignedRole ? this.props.assignedRole.id : null);
        formData.append('workspace', this.props.workspace ? this.props.workspace.id : null);
        // remove password field from payload if not set to update
       
        if (isUpdate && !this.state.updatePassword) {
            delete otherFields.password;
            delete otherFields.passwordTwo;
        }

        var formKeys = Object.keys(otherFields);
        for(var i = 0; i < formKeys.length; i++) {
            formData.append(formKeys[i], this.props.userForm[formKeys[i]]);
        }
       
        if (otherFields.userType === this.userType.authorizer && signature ) {
            if (signature instanceof File) {
                const fileName =  uploader.generateFileName(signature);
                formData.append("signature", signature, fileName);
            }
        }

        var formPayload = {
            requestType: isUpdate ? REQUESTS_TYPE.UPDATE : REQUESTS_TYPE.CREATE ,
            form: formData
        }
        
        if (this.props.userForm.profileImage) {
            const fileName =  uploader.generateFileName(profileImage);
            formData.append("profileImage", profileImage, fileName);
        }
        
        if (isUpdate) {
            formPayload['userId'] = this.props.currentUser !== undefined ? this.props.currentUser.id : null
        }

        const formErrors = this.validateFields(formPayload.form);

        if (!this.props.validator.hasActiveFormError(formErrors)) {
            if (evt.action === 'save'){
                this.props.apiRequestManager.queueRequest(actionTypes.USER_SUBMIT_DATA, formPayload);
            }
        }else {
            this.setState({
                formErrors: formErrors
            });
        }
    }

    validateFields(formData) {
        const formFields = VALIDATOR_DELEGATE_KEY_GROUP.formFields;

        const passwordValidation = this.props.mode === 'create' || this.state.updatePassword ? {
            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.user,formFields.password, formData.get(formFields.password), formData.get(formFields.passwordTwo))
        } : {}

        return {
            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.user,formFields.firstName, formData.get(formFields.firstName)),

            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.user,formFields.lastName, formData.get(formFields.lastName)),

            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.user,formFields.email, formData.get(formFields.email)),

            ...passwordValidation,
        
            missingRole: this.props.assignedRole ? false : true,
        }
    }

    updateUserData(evt) {
        this.submitFormData(evt);
    }

    submitDelete(user) {
        var data = {
            requestType:  'delete',
            userId: user.id
        }
        
        this.props.apiRequestManager.queueRequest(actionTypes.USER_SUBMIT_DATA, data);
    }

    handleDelete(evt) {
        this.showDeleteDialog('user', this.props.currentUser);
    }

    handlerBack(evt) {
        this.props.history.push(urls.usersUri);
    }

    composeFormData(user) { 
        var formData =  {
            userId: user.id,
            userType: this.userType[user.type],
            firstName: user.firstName,
            lastName: user.lastName,
            username: user.username,
            email: user.email,
            password: '',
            passwordTwo: '',
            active: user.active,
            secondary: user.secondary,
            blocked: user.blocked,
            twoFactorAuth: user.twoFactorAuth,
            role: user.role
        }

        if (this.userType[user.type] === this.userType.authorizer) {
            formData['phoneNumber'] = user.phoneNumber;
            formData['gender'] = user.gender;
            formData['digitalSignature'] = user.digitalSignature;
            formData['authorizerType'] = user.profession;
            formData['signature'] = user.imageSignature?.fileDownloadUri;
        }

        return formData
    }
    
    previewImage(event, imageRef) {
        var reader = new FileReader();
        let image = imageRef ? imageRef : this.profileImage;

        reader.onload = function() {
            var output = image.current; //document.getElementById('imagePreview');
            output.src = reader.result;
        }
        reader.readAsDataURL(event.target.files[0]);
        
        var file = event.target.files[0];
        
        if (event.target.name === 'signature-image' ) {
            const fieldSet = { 'signature' : file };
            this.props.userFieldDidChange(fieldSet);
        }else {
            const fieldSet = { 'profileImage' : file };
            this.props.userFieldDidChange(fieldSet);
        }    
    }

    showDeleteDialog(contextName, deleleItem) {
        this.setState({deleteDialogVisible: true, 
            deleteContextName: contextName, 
            deleleContextItem: deleleItem });
    } 
    onConfirmDelete(deleteItem) {
        this.submitDelete(deleteItem);
        this.cancelDelete();
    } 
    cancelDelete() {
        this.setState({deleteDialogVisible: false, deleteContextName: ""});
    }

    getPromptMessage(){
        const apiOutcome = this.props.apiRequestManager.getApiOutcome(actionTypes.USER_SUBMIT_DATA, true) ?? {};
        return getPromptMessageProps('user', apiOutcome);
    }
    render() {
        const selectedRole = this.props.assignedRole ? this.props.assignedRole : null;
        const actionBarCallback = {
            'save': this.submitFormData,
            'back': this.handlerBack,
            'delete': this.handleDelete
        };
        const defaultUserType = this.props.userForm.userType === '' ? true : this.props.userForm.userType === this.userType.standard ? true : false;

        const detailsTabPrefix = this.props.userForm.userType === this.userType.authorizer ? this.userType.authorizer : ''
       
        const showDetailsTab = this.props.userForm.userType !== '' && this.props.userForm.userType !== this.userType.standard && !this.props.isClientLayout ? true: false;

        //Updating context values
        const  actionBarMode = this.props.mode
        const  saveSufixText = actionBarMode === 'edit'? '' : 'User';
     
        const pictureSrc = this.props.userForm.profileImage ? URL.createObjectURL(this.props.userForm.profileImage) :  this.props.currentUser && this.props.currentUser.picture ? this.props.currentUser.picture.fileDownloadUri : getIconUrl('profile');

        const assignableRoles = this.props.availableRoles.filter((role) => {
            if (!this.props.singleSuperuser) {
                return role;
            }else if (this.props.singleSuperuser && role.superRole) {
                return role;
            }
        })

        const isCurrentUserSuper = this.props.currentUser && this.props.currentUser.role &&
        this.props.currentUser.role.superRole;

        const formFields = VALIDATOR_DELEGATE_KEY_GROUP.formFields;
        const dialogProps = this.getPromptMessage();

        const shouldUpdatePassword = actionBarMode === 'create' ? true : this.state.updatePassword && this.props.currentUser && this.props.permissionEnforcer.isPermitted('user', 'change');

        return (
            <React.Fragment>
                <ActionBar title={ "User" } saveTextSufix={ saveSufixText } entityContext={'user'} actionResponsers={ actionBarCallback } permissionContext={ actionBarMode } deleteButton={
                !(this.props.singleSuperuser && isCurrentUserSuper)} />
                <div className="content-layout-wrap">
                    { dialogProps.visible && 
                        <PromptMessage {...dialogProps} />
                    } 
                    <div className="view-layout-content">
                    <div className="default-Ly-ct">
                <Container>
                    <Row>
                    <Col></Col>
                    <Col sm="12" md={7}>
                    <div className="content-section">
                            <Nav tabs>
                                <NavItem>
                                <Link
                                    className={classnames({ active: this.state.activeTab === '1' })}
                                    onClick={() => { this.toggle('1'); }}
                                >
                                    User Info
                                </Link>
                                </NavItem>
                                <NavItem>
                                <Link
                                    className={classnames({ active: this.state.activeTab === '2' })}
                                    onClick={() => { this.toggle('2'); }}
                                >
                                    { (!selectedRole && this.state.activeTab != '2') && <ErrorMessage message=''/>}
                                   Role
                                </Link>
                                </NavItem>
                                { showDetailsTab ? 
                                <NavItem>
                                <Link
                                    className={classnames({ active: this.state.activeTab === '3' })}
                                    onClick={() => { this.toggle('3'); }}
                                >
                                    Details
                                </Link>
                                </NavItem>

                                : null }
                            </Nav>
                            <TabContent className="create-user" activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                <Row>
                                    <Col sm="12">
                                    <Form onSubmit={(event) => event.preventDefault()}>
                                        { this.props.singleSuperuser &&
                                        <span className='user-lock lock-icon'>
                                            <img src={getIconUrl('lock')} alt='' />
                                        </span>
                                         }
                                        <Section className="user">
                                            <div className="section-head simple">
                                                <h3>User</h3>
                                            </div>
                                            <div className="section-body">
                                                <FormGroup>
                                                    { this.props.isClientLayout ||(this.props.currentUser && this.props.currentUser.type === this.userType.staff) ?
                                                    
                                                    <Label className="ip-radio-pill">
                                                        <Input onChange={this.handlePillSwitches } type="radio" name="userType" value={this.userType.staff } checked={ true } />{' '}
                                                        <span className="radioselect"></span>
                                                        <span className="text">Staff</span>
                                                    </Label>
                                                    :
                                                    <React.Fragment>
                                                    <Label className="ip-radio-pill">
                                                        <Input onChange={this.handlePillSwitches } type="radio" name="userType" value={this.userType.standard } checked={ defaultUserType } />{' '}
                                                        <span className="radioselect"></span>
                                                        <span className="text">Standard</span>
                                                    </Label>
                                                    { !this.props.singleSuperuser &&
                                                    <Label className="ip-radio-pill">
                                                    <Input onChange={this.handlePillSwitches } type="radio" name="userType"value={this.userType.authorizer } checked={this.props.userForm.userType === this.userType.authorizer? true: false}/>{' '}
                                                        <span className="radioselect"></span>
                                                        <span className="text">Authorizer</span>
                                                    </Label>
                                                    }
                                                    </React.Fragment>
                                                    
                                                    }
                                                </FormGroup>
                                            </div>
                                        </Section>
                                        <Section className="user-info">
                                            <div className="section-head">
                                                <h3>Attributes</h3>
                                            </div>
                                            <div className="section-body">
                                                <FormGroup className="section-inner">
                                                    <div className="image-thumbnail-box">
                                                        <div className="image-selector">
                                                            <img 
                                                            id="profilePreview"
                                                            ref={this.profileImage}
                                                            src={pictureSrc} alt=''/>
                                                            <Button><span>Edit</span></Button>
                                                            <div className="input-field">
                                                                <Input type="file"
                                                                onChange={this.previewImage}
                                                                 name="profile-image" accept="image/*" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="section-inner">
                                                    <div className="switch-group">
                                                        <Label className="inline">Active</Label>
                                                        <span className="switch-pill inline">
                                                            <Input onChange={ this.handleToggleSwitches } type="checkbox" id="active" checked={ this.props.userForm.active }
                                                            disabled={this.props.singleSuperuser}/>
                                                            <Label for="active" id="active">Off</Label>
                                                        </span>
                                                    </div>
                                                </FormGroup>
                                               { (this.props.isClientLayout ) &&
                                                <FormGroup className="section-inner">
                                                    <div className="switch-group">
                                                        <Label className="inline">Secondary</Label>
                                                        <span className={`switch-pill inline ${ this.props.isClientLayout && '--disabled'}`}>
                                                            <Input onChange={this.handleToggleSwitches } type="checkbox" id="secondary" checked={ this.props.userForm.secondary }
                                                            disabled={true}
                                                            />
                                                            <Label for="secondary" id="dxy">Off</Label>
                                                        </span>
                                                    </div>
                                                </FormGroup>
                                                }
                                                <FormGroup className="section-inner">
                                                    <div className="switch-group">
                                                        <Label className="inline">Blocked</Label>
                                                        <span className="switch-pill inline">
                                                            <Input onChange={this.handleToggleSwitches } type="checkbox" id="blocked" checked={ this.props.userForm.blocked }
                                                            disabled={this.props.singleSuperuser} />
                                                            <Label for="blocked" id="blocked">Off</Label>
                                                        </span>
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="section-inner">
                                                    <Label>First name</Label>
                                                    <ValidateField required >                       
                                                    <InputField onKeyUp={this.composeUserName} onChange={ this.handleTextField } id={formFields.firstName} type="text" name={formFields.firstName}className="ip-text-default" placeholder="John" value={this.props.userForm.firstName } />
                                                        <ErrorFeedback 
                                                         show={this.state.formErrors.firstNameMissing}
                                                         warning
                                                         filter='valueMissing'
                                                         message={<ErrorMessage message ='First name is required' />} />
                                                        <ErrorFeedback 
                                                        show={this.state.formErrors.firstNameInvalid}
                                                        error
                                                        filter='letterInput'
                                                        message={<ErrorMessage message ='Name should contain only letters' />} />
                                                    </ValidateField>
                                                </FormGroup>

                                                <FormGroup className="section-inner">
                                                    <Label>Last name</Label>
                                                    <ValidateField required >                       
                                                    <InputField onKeyUp={this.composeUserName} onChange={ this.handleTextField } id={formFields.lastName} type="text" name={formFields.lastName}className="ip-text-default" placeholder="Brown" value={this.props.userForm.lastName } />
                                                    <ErrorFeedback 
                                                       show={this.state.formErrors.lastNameMissing}
                                                       warning
                                                       filter='valueMissing'
                                                       message={<ErrorMessage message ='Last name is required' />} />
                                                    <ErrorFeedback 
                                                        show={this.state.formErrors.lastNameInvalid}
                                                        error
                                                        filter='letterInput'
                                                        message={<ErrorMessage message ='Name should contain only letters' />} />
                                                    </ValidateField>
                                                </FormGroup>
                                            </div>
                                        </Section>
                                        <Section className="auth">
                                            <div className="section-head">
                                                <h3>Auth Information</h3>
                                            </div>
                                            <div className="section-body">
                                                <FormGroup className="section-inner username-field">
                                                    <InputField id="username" type="text" className="ip-text-default"  name={"username"} placeholder="Username" disabled/>
                                                    <span><i>Genterated </i> { this.props.userForm.username }</span>
                                                </FormGroup>
                                                <FormGroup className="section-inner">
                                                    <Label>Email</Label>
                                                    
                                                    <ValidateField required >                       
                                                    <InputField onChange={ this.handleTextField } id={formFields.email} type="text" name={formFields.email}className="ip-text-default" placeholder="example@mail.com"
                                                    value={ this.props.userForm.email } />
                                                    <ErrorFeedback 
                                                        show={this.state.formErrors.emailMissing}
                                                        warning
                                                        filter='valueMissing'
                                                        message={<ErrorMessage message ='Email is required' />} />
                                                    <ErrorFeedback 
                                                        show={this.state.formErrors.emailInvalid}
                                                        error
                                                        filter='emailMismatch'
                                                        message={<ErrorMessage message ='Email is invalid' />} />
                                                    </ValidateField>
                                                </FormGroup>
                                                { !shouldUpdatePassword ?
                                                <FormGroup className="section-inner">
                                                    <a className="arrow-link" onClick={this.willUpdatePassword}>
                                                        <span className="arrow-indicator"></span>Update Password
                                                    </a>
                                                </FormGroup>
                                                :
                                                <React.Fragment>
                                                <FormGroup className="section-inner">
                                                    <Label>Password</Label>
                                                    <ValidateField pattern={'[a-zA-Z0-9@#$%^&+-]{6,}?'} required >                       
                                                    <InputField  onChange={ this.handleTextField } id={formFields.password} type="password" name={formFields.password} className="ip-text-default" placeholder="* * * * * *"/>

                                                    <ErrorFeedback 
                                                       show={this.state.formErrors.passwordMissing}
                                                       warning
                                                       filter='valueMissing'
                                                       message={<ErrorMessage message ='Password is required' />} />
                                                    <ErrorFeedback 
                                                        error
                                                        filter='patternMismatch'
                                                        message={<ErrorMessage message={'Use 6 characters or more for your password'} />} />
                                                    </ValidateField>
                                                </FormGroup>
                                                <FormGroup className="section-inner">
                                                    <Label>Re-Enter</Label>
                                                    <ValidateField matchField={'password'} >                       
                                                    <InputField  onChange={ this.handleTextField } id={formFields.passwordTwo} type="password" name={formFields.passwordTwo}className="ip-text-default" placeholder="* * * * * *"/>
                                                    <ErrorFeedback 
                                                        show={this.state.formErrors.passwordsDifferent}
                                                        error
                                                        filter='matchEqual'
                                                        message={<ErrorMessage message={'Those passwords didn’t match'} />} />
                                                    </ValidateField>
                                                </FormGroup>
                                                </React.Fragment>
                                                }

                                                { FEATURES.TwoFactorAuthentication === FEATURE_FLAG.ON &&
                                                <FormGroup className="section-inner">
                                                    <div className="switch-group">
                                                        <Label>Two factor athentication</Label>
                                                        <span className="switch-pill">
                                                            <Input type="checkbox" id="twoFactorAuth" onChange={ this.handleToggleSwitches } checked={this.props.userForm.twoFactorAuth } />
                                                            <Label for="twoFactorAuth" id="twoFactorAuth">Off</Label>
                                                        </span>
                                                    </div>
                                                </FormGroup>
                                                }
                                            </div>
                                        </Section>
                                    </Form>
                                    </Col>
                                </Row>
                                </TabPane>
                                <TabPane tabId="2" id="roles-pane">
                                <Row>
                                    <Col sm="12">
                                        <Section>
                                            <div className="section-head simple">
                                                <h3>Assigned Role</h3>
                                            </div>
                                            <div className="section-body">
                                                
                                                <div className="role-well">
                                                    { !selectedRole && <ErrorMessage message='Please select a role'/>}

                                                    <div ref={this.assignedRoleWell} className="role-well-container holder">
                                                        <List>
                                                        { !selectedRole ? 
                                                        <li><p className="unassign">No Role Selected</p></li>
                                                        :
                                                            <RoleItem  role={ selectedRole } 
                                                            isAssigned 
                                                            disable={this.props.singleSuperuser}
                                                            eventHanlder={this.didSelectRole}/>
                                                        }
                                                        </List>
                                                    </div>
                                                </div>
                                            </div>
                                        </Section>
                                        <Section>
                                            <div className="section-head simple">
                                                <h3>Available Roles</h3>
                                            </div>
                                            <div className="section-body">
                                                <div className="role-well">
                                                    <div className="role-well-container">
                                                        
                                                        <List>
                                                            {
                                                            this.props.roles && this.props.roles.empty() &&
                                                            <li>
                                                                <div className="no-roles"><p>Add Roles in settings.</p>
                                                                </div>
                                                            </li>
                                                            }
                                                            
                                                            {
                                                                assignableRoles.empty() && this.props.assignedRole ?
                                                                <li>
                                                                    <div className="no-roles"><p>No assignable role available.</p>
                                                                    </div>
                                                                </li> : 
                                                            
                                                            assignableRoles.map((role, i) => {
                                                              
                                                            return <RoleItem key={i} role={role } eventHanlder={this.didSelectRole} />
                                                          })  
                                                        }
                                                        </List>
                                                    </div>
                                                </div>
                                            </div>
                                        </Section>
                                    </Col>
                                </Row>
                                </TabPane>
                                {
                                    !showDetailsTab && !this.props.isClientLayout ? null:
                                    <TabPane tabId="3" id="details-pane">
                                        <AuthorizerTabPane 
                                            formData={this.props.userForm}
                                            userFieldDidChange={this.props.userFieldDidChange}
                                            previewImage={this.previewImage}
                                            updateUser={this.props.currentUser}
                                        />
                                    </TabPane>
                                }
                            </TabContent>
                            </div>
                    </Col>
                    <Col></Col>
                    </Row>
                </Container>
                </div>
                </div>
                </div>

                { this.state.deleteDialogVisible && 
                    <ConfirmModal 
                    contextName={this.state.deleteContextName} 
                    contextItem={this.state.deleleContextItem} 
                    cancelCallback={this.cancelDelete}
                    confirmCallback={this.onConfirmDelete}
                    />
                }
            </React.Fragment>
        );
    }
}

const CrateUpdateView = (props) => {
    return (
        <CrateUpdate {...props } />
    );
};

const ConfigCrateUpdateUserView = setupPage( CrateUpdate.pageOption )(CrateUpdateView);


export default connect(mapStateToProps, mapDispatchToProps)(ConfigCrateUpdateUserView);