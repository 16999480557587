import React from 'react';
import * as actionTypes from '../../../store/actionType'
import {
    UncontrolledDropdown,
    DropdownToggle,
    ExtendedDropdown,
    ListGroup,
    ListGroupItem,
} from './../../../components';

import NotificationBadge  from './../navbar_elements/NotificationBadge';
import { ApiRequestManager } from '../../../utility/ApiRequestManager';
import { PermissionEnforcer } from '../../../utility/PermissionEnforcer';
import { getIconUrl } from '../../../common/images-catalogue';

const NotificationItem = (props) => {
    const { notification, onDismiss } = props;

    const permissionEnforcer = PermissionEnforcer.getInstance(PermissionEnforcer);
    const apiRequestManager = ApiRequestManager.getInstance(ApiRequestManager);
    apiRequestManager.register(actionTypes.DISMISS_NOTIFICATION, onDismiss);
    const canDismiss = permissionEnforcer.isPermitted('notification', 'delete');
    const expandClassname = canDismiss ? 'expd' : '';
    
    const willDismiss = (evt) => {
        const payload = { deleteItems: [notification.id] } ;
        apiRequestManager.queueRequest(actionTypes.DISMISS_NOTIFICATION, payload)
    };

    return (
        <ListGroupItem className={`notification ${expandClassname}`} action>
            <div className="notification-cnt">
                <div className="top d-flex justify-content-between align-items-center">
                    <div className="typ">
                        <span></span> {notification.type} 
                    </div>
                    <div className="time-elapse">
                        {notification.dateCreated}
                    </div>
                </div>
                <div className="body">
                    <div className="title">
                        <span> {notification.title} </span>
                    </div>
                    <div className="content">
                        <p className="ntf-text">
                            {notification.content} 
                        </p>
                    </div>
                </div>
            </div>
            { canDismiss &&
                <div className="dismiss-ovrly">
                    <a onClick={willDismiss}>Dismiss</a>
                </div>
            }
        </ListGroupItem>
    );
}

const NotificationTray = (props) => {
    const {data, onDismiss, ...otherPorps} = props;

    const permissionEnforcer = PermissionEnforcer.getInstance(PermissionEnforcer);
    const canDismiss = permissionEnforcer.isPermitted('notification', 'delete');

    const count = data ? data.length : 0;
    const notifications = Array.isArray(data) && data.empty() ? [] : data;
    const bottomText = count === 0? "No Notifications" : "Clear all Notifications";
    const dismissAll = () => {
        const items = notifications.map(item=> item.id);
        onDismiss({deleteItems : items});
    };

    return (
    <UncontrolledDropdown className="notification-trigger" { ...otherPorps }>
        <DropdownToggle className="drop-down-trigger" >
            <div className="icon-button notify-btn">
                <img src={ getIconUrl('bell') } alt='' />
                <NotificationBadge count={count} />
            </div>
        </DropdownToggle>
        <ExtendedDropdown className={"notification-tray"} right>

            <ExtendedDropdown.Section className="nft-top d-flex justify-content-between align-items-center">
                <h6 className="mb-0">Notification Tray</h6>
                { count > 0 && 
                    <span className="nft-count">{count}</span>
                }
            </ExtendedDropdown.Section>

            <ExtendedDropdown.Section list>
                { notifications.length > 0 ?
                    <ListGroup className="notification-list">
                        { notifications.map((item, i) => {
                            return (<NotificationItem 
                                    key={i} 
                                    onDismiss={onDismiss}
                                    notification={item} />);
                        }) }
                    </ListGroup>
                    :
                    <div className="ntf-empty">
                        <span className="icon"><img src={getIconUrl("empty")} alt='' /></span>
                        <p>You're all caught up!</p>
                    </div>
                }
            </ExtendedDropdown.Section>

            <ExtendedDropdown.Section className="text-center tray-footer" to="/apps/widgets">
                {bottomText}
                { (canDismiss && notifications.length > 0) &&
                    <a onClick={dismissAll} className="clr-all-icon" >
                        <img src={getIconUrl('clear')} alt='' />
                    </a>
                }
            </ExtendedDropdown.Section>
        </ExtendedDropdown>

    </UncontrolledDropdown>
    )
};

export default NotificationTray;