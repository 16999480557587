import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setupPage } from '../../../../components/Layout/setupPage';
import { 
    List,
    Input,
    FormGroup,
    Label,
    Section,
} from '../../../../components';

import { AddButton } from '../../../components/partials/Buttons';

import  * as actions  from '../../../../store/actions';
import * as actionTypes from '../../../../store/actionType';
import { ErrorMessage } from '../../../components/partials/ErrorMessage';
import text from '../../../../common/strings';
import { REQUESTS_TYPE, VALIDATOR_DELEGATE_KEY_GROUP, VALIDATOR_DELEGATE_TYPES } from '../../../../common/constant';
import { PromptMessage, getPromptMessageProps } from '../../../components/partials/PromptMessage';
import settingsValidatorDelegate from '../../../../forms/settingsValidator.delegate';
import { objListsAreEqual } from '../../../../utility/helpers';
import { getIconUrl } from '../../../../common/images-catalogue';

const mapStateToProps = state => ({
    ...state.settings.codeSheetSetting
});

const mapDispatchToProps = dispatch => ({
    setCodeSheets: (type, data) => dispatch(actions.setSettingDataAction(type, data)),
    getAllCodeSheets: () => dispatch(actions.getCodeSheetsAction()),
    submitData: (payload) => dispatch(actions.submitCodeSheetAction(payload))  
});

const CodeSheetItem = (props) => {
    const {data, actions} = props;
  
    const fieldChange = (evt) => {
        const values = {
            index: data.index,
            ...data,
            [evt.target.name]: evt.target.value
        };
        actions.addOrChange(evt, data.index, values);
    };
    
    const isNewClass = !data.id ? '--new' : '';
    const text = !data.id ? 'Remove' : 'Delete';

    return (
        <li className={`code-sheet-item ${isNewClass}`}>
            <div className="code-sheet">
                <div className="ip-group">
                    <FormGroup>
                        <Label>Abbreviation</Label>
                        {(data.abbreviation ?  '' : <ErrorMessage message='Please Enter Abbreviation'/>)}

                        <Input  onChange={fieldChange} type='text'name={'abbreviation'} value={data.abbreviation} />
                    </FormGroup>
                </div>
                <FormGroup>
                    <Label>Description</Label>
                    {(data.description ? '': <ErrorMessage message ='Please Enter Description'/>)}
                    <Input className='ip-textarea' 
                    onChange={fieldChange} type='textarea' 
                    name={'description'} value={data.description}
                    />
                </FormGroup>
                <Link onClick={(evt) => actions.remove(evt, data)} to={'#'} className="remove"><img className={'icon'} src={getIconUrl('trash')} alt='' /> {text}</Link>
            </div> 
        </li>
    );
}
class CodeSheet extends React.Component {

    static pageOption = { 
        pageTitle: 'Client',
        disableBodyScroll: true
    };

    constructor (props) {
        super(props);

        props.apiRequestManager.register(actionTypes.FETCH_CODE_SHEETS, props.getAllCodeSheets);
        props.apiRequestManager.register(actionTypes.CODESHEET_SUBMIT_DATA, props.submitData);

        this.state = { };

        this.removeCodeSheet = this.removeCodeSheet.bind(this);
        this.addOrReplaceCodeSheet = this.addOrReplaceCodeSheet.bind(this);
        this.composeCodeSheet = this.composeCodeSheet.bind(this);
        this.onSaveHanlder = this.onSaveHanlder.bind(this);
        this.getPromptMessage = this.getPromptMessage.bind(this);
        this.validation = this.validation.bind(this);

        props.innerRef.saveHanlder = this.onSaveHanlder;
        props.innerRef.confirmDeleteCallback = this.onConfirmDelete.bind(this);
    }

    componentDidMount() {
        this.props.validator.addDelegate(VALIDATOR_DELEGATE_TYPES.settings, settingsValidatorDelegate)
    }

    static getDerivedStateFromProps(props, state) {
        if ( !props.codeSheetsData && !props.apiRequestManager.inProgress(actionTypes.FETCH_CODE_SHEETS)) {
            props.apiRequestManager.queueRequest(actionTypes.FETCH_CODE_SHEETS);
        }
        return null;
    }

    shouldComponentUpdate(nextProps){
        /**
         * This runs the validation if something changed in codeSheets list.
         */
        const prevList = this.props.codeSheetsData;
        const nextList = nextProps.codeSheetsData;

        if (Array.isArray(prevList) && Array.isArray(nextList) && !objListsAreEqual(prevList, nextList,'index')) {
            const errors = this.validation(nextList);
            
            if (this.props.validator.hasActiveFormError(errors)) {
                this.props.actions.enableSave(false);
            }else {
                this.props.actions.enableSave();
            }
        }

        return true;
    }

    removeCodeSheet(evt, codeSheet) {
        evt.preventDefault();
        if (codeSheet.id) {
            this.props.showDeleteDialog('codesheet', codeSheet);
        }else {
            const codeSheets = this.props.codeSheetsData.filter((item) => codeSheet.index !== item.index);
            this.props.setCodeSheets(actionTypes.SET_CODESHEETS_DATA, codeSheets);
        }
    }

    onConfirmDelete(deleteItem) {
        const payload = {
            requestType: REQUESTS_TYPE.DELETE,
            codeSheetId: deleteItem.id
        };
        
        this.props.apiRequestManager.queueRequest(actionTypes.CODESHEET_SUBMIT_DATA, payload);
    }
      
    addOrReplaceCodeSheet(evt, itemIndex, codeSheet) {
        let codeSheets = [];
        if (itemIndex === undefined) {
            let codeSheetsWithNewEntry = [this.composeCodeSheet(), ...this.props.codeSheetsData];
            codeSheetsWithNewEntry.sortArrayObjectByKey('index')
            codeSheets = !this.props.codeSheetsData.length? [this.composeCodeSheet()] : codeSheetsWithNewEntry;
            this.props.setCodeSheets(actionTypes.SET_CODESHEETS_DATA, codeSheets);
        } else {
            codeSheets = this.props.codeSheetsData.map((item) => {
                if (item.index === itemIndex) {
                    item = {
                        ...codeSheet
                    }
                }
                return item;
            });
            this.props.setCodeSheets(actionTypes.SET_CODESHEETS_DATA, codeSheets);
        }
    }

    validation(codeSheets) {
        return {
            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.settings, VALIDATOR_DELEGATE_KEY_GROUP.settings.codeSheet, codeSheets)
        }
    }

    onSaveHanlder() {
        const payload = {
            requestType: REQUESTS_TYPE.SUBMIT,
            data: this.props.codeSheetsData,
        };
        this.props.apiRequestManager.queueRequest(actionTypes.CODESHEET_SUBMIT_DATA, payload);
    }
    
    composeCodeSheet() {
        return {
            index: 0,
            abbreviation: '',
            description: ''
        };
    }

    getPromptMessage() {
        const apiOutcome = this.props.apiRequestManager.getApiOutcome(actionTypes.CODESHEET_SUBMIT_DATA, true) ?? {};
        return getPromptMessageProps('codeSheet', apiOutcome);
    }

    render() {

        const actions = {
            remove: this.removeCodeSheet,
            addOrChange: this.addOrReplaceCodeSheet
        }
   
        const codesheets = Array.isArray(this.props.codeSheetsData) ? this.props.codeSheetsData : [];
        const dialogProps = this.getPromptMessage();

        return (
            <React.Fragment>
                { dialogProps.visible && 
                    <PromptMessage {...dialogProps} />
                } 
                <div className='pane-layout-wrap pane code-sheet-pane'>
                    <div className="pane-cont">
                        <div className="setting-info">
                            <p>The code sheet items that are created here are used when creating or modifying a <b>Result Sheet</b>.</p>
                        </div>
                        <div className="actions-btn">
                            <AddButton actionHandler={this.addOrReplaceCodeSheet} label={'Add Code Sheet'} />
                        </div>
                        <Section className="code-sheet-list">
                            <List>
                                { codesheets.map((item, i) => {
                                    return  <CodeSheetItem key={i} data={item} actions={actions}/>
                                })}
                               
                            </List>
                        </Section>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const CodeSheetView = (props) => {
    return (
        <CodeSheet {...props } />
    );
};

let ConfigCodeSheetView = setupPage(CodeSheet.pageOption)(CodeSheetView);
ConfigCodeSheetView =  connect(mapStateToProps, mapDispatchToProps)(ConfigCodeSheetView);

export { ConfigCodeSheetView };