import React from 'react';
import PropTypes from 'prop-types';

import {
  NavLink
} from './../../../components';

function NoItemFound(props) {
  const { messageComponent, onClick, itemClasses } = props;
  
  return (
    <NavLink href onClick={onClick ? onClick : () => {}}>
      <li className={`no-item-found-wrapper ${itemClasses}`}>
          {messageComponent}
      </li>
    </NavLink>
  );
}

NoItemFound.defaultProps = {
  messageComponent: '',
  itemClasses: ''
}

NoItemFound.propTypes = {
  messageComponent: PropTypes.element.isRequired,
  onClick: PropTypes.func,
  itemClasses: PropTypes.string,
}

const NotFound = {};

NotFound.Items = NoItemFound;

export { NotFound }