import React from 'react';
import * as actionTypes from '../../store/actionType';
import {
    Button,
    Section,
    NavLink
} from '../../components';
import {Loader } from '../components/partials/LoadingIndicator';
import { TAX_CODE, REQUESTS_TYPE } from '../../common/constant';
import './Payroll.style.scss';
import { formatDate, getDateInTimeZoneString } from '../../utility/helpers';
class ReviewPane extends React.Component {

    static pageOption = { 
        pageTitle: 'Pay Slips',
        disableBodyScroll: true
    };

    constructor(props) {
        super(props);
        this.state = {
           processing: false,
           showTaxes: false
        };

        this.submitPayrollData = this.submitPayrollData.bind(this);
        this.showHideTaxes = this.showHideTaxes.bind(this);
        this.payrollTableData = [];
        this.taxesTableData = [];
        this.tableTotals = {};
    }

    componentDidMount() {}

    componentWillUnmount() {
        this.setState({processing: false});
    }

    static getDerivedStateFromProps(props, state) {
        if (props.payrollData) {
            props.nextStep();
        }
        return null;
    }

    showHideTaxes() {
        this.setState({showTaxes: !this.state.showTaxes});
    }

    submitPayrollData(evt) {
        this.setState({processing: true});

        let formPayload = {
            requestType: REQUESTS_TYPE.SUBMIT,
            taxes: this.props.payrollForm.taxes.map((tax) => {
                return {
                    taxCode: tax.taxCode,
                    percent: parseFloat(tax.percent),
                    thresholdAmount: parseFloat(tax.thresholdAmount),
                    isIncomeTax: tax.isIncomeTax
                }
            }),
            payrollDate: getDateInTimeZoneString({date: this.props.payrollForm.payrollDate}),
        }

        formPayload.payAdvices = this.payrollTableData.map((item) => {
            let payAdvice = {
                grossPay   : item.grossPay,
                netPay    : item.netPay,
                baseWage   : item.baseWage,
                employee   : item.employee.id,
                earnings   : {
                    bonus: parseFloat(item.employee.earningsData.addedEarnings.value.bonus.amount),
                    commission: parseFloat(item.employee.earningsData.addedEarnings.value.commission.amount),
                },
                deductions : {
                    pension: parseFloat(item.employee.earningsData.grossPay.value.pension.amount),
                    reimbursement: parseFloat(item.employee.earningsData.grossPay.value.reimbursement.amount),
                },
                wageSalary: {
                    basicRate: parseFloat(item.employee.earningsData.wageSalary.value.basicRate),
                    salary: parseFloat(item.employee.earningsData.wageSalary.value.salary),
                    hours: item.employee.earningsData.wageSalary.value.hours,
                    isFixedSalary: item.employee.earningsData.wageSalary.value.isFixedSalary,
                    payCycle: item.employee.earningsData.wageSalary.value.payCycle,
                }
            }
            return payAdvice;
        });
       
        formPayload.taxSummary = this.taxesTableData.map((item) => {
            return {
                taxCode: item.taxCode,
                percent: parseFloat(item.taxPercent),
                taxTotal: parseFloat(item.taxTotal)
            };
        })

        formPayload.totals = {
            grossPay :  parseFloat(this.tableTotals.grossPay),
            netPay   :  parseFloat(this.tableTotals.netPay)
        }
        
        this.props.apiRequestManager.queueRequest(actionTypes.PAYROLL_SUBMIT_DATA, formPayload);
    }

    calcEmployeeTaxes(grossPay, taxes, pension, payCycle) {
        const DECIMAL_PLACES = 2;

        const grossIncomeTaxes = [TAX_CODE.NIS, TAX_CODE.NHT];
        const statutoryIncomeTaxes = [TAX_CODE.EDTAX];
        let PAYE_TAXES = taxes.filter(tax => tax.isIncomeTax);
        const amountPerTaxCode = {};

        //statutoryIncome = grossPay - (NIS + pension)
        let statutoryIncome = grossPay - pension;

        //taxes applied to gross income: NIS, NHT
        taxes.filter((tax) => grossIncomeTaxes.includes(tax.taxCode))
        .forEach((grossIncomeTax) => {
            const taxAmount = (parseFloat(grossIncomeTax.percent) / 100) * parseFloat(grossPay);
            amountPerTaxCode[grossIncomeTax.taxCode] = Number(taxAmount.toFixed(DECIMAL_PLACES));

            if(grossIncomeTax.taxCode === TAX_CODE.NIS) statutoryIncome -= taxAmount;
        });

        //taxes applied to statutory income: EDUTAX
        taxes.filter((tax) => statutoryIncomeTaxes.includes(tax.taxCode))
        .forEach((statutoryIncomeTax) => {
            const taxAmount = (parseFloat(statutoryIncomeTax.percent) / 100) * parseFloat(statutoryIncome);
            amountPerTaxCode[statutoryIncomeTax.taxCode] = Number(taxAmount.toFixed(DECIMAL_PLACES));
        });

        //PAYE(income tax) = (statutoryIncome - threshold) * PAYE%
       
        if (!PAYE_TAXES.empty()) {
            let PAYE_APPLIED = false;
            let percentage = 0;
            let threshold = 0;

            // use slice() to copy the array and not just make a reference
            const SORTED_PAYE_TAXES = PAYE_TAXES.slice(0);

            /* Sort PAYE income tax by largest threshold amount
            *  This ensures that only ony PAYE is used for calculating income tax
            */
            SORTED_PAYE_TAXES.sort(function(a, b) {
                return b.thresholdAmount - a.thresholdAmount;
            });

            SORTED_PAYE_TAXES.forEach((PAYE) => {
                if (PAYE_APPLIED)
                    return
                if(grossPay >= this.props.getSalaryByPayPeriod(PAYE.thresholdAmount, payCycle) ) {
                    percentage = PAYE.percent / 100;
                    threshold = this.props.getSalaryByPayPeriod(PAYE.thresholdAmount, payCycle);
                    PAYE_APPLIED = true;
                }
            });
            
            const taxAmount = (statutoryIncome - threshold) * percentage;
            amountPerTaxCode[TAX_CODE.PAYE] = Number(taxAmount.toFixed(DECIMAL_PLACES));
        }
        
        return amountPerTaxCode;
    }

    getTotalEmployeeTaxAmount(amountPerTaxCodeMap) {
        if (typeof amountPerTaxCodeMap === 'number') return amountPerTaxCodeMap;
        if (!amountPerTaxCodeMap || typeof amountPerTaxCodeMap !== 'object' || amountPerTaxCodeMap instanceof Array) return 0;

        return Object.keys(amountPerTaxCodeMap).reduce((total, taxCode) => total + amountPerTaxCodeMap[taxCode], 0);
    }

    calcTaxTableData(taxes, calcTableData) {
        const taxesData = {};

        calcTableData.forEach(earning => {
            Object.keys(earning.amountPerTaxCode).forEach(taxCode => {
                const taxCodeAmount = parseFloat(earning.amountPerTaxCode[taxCode]);

                if(!taxesData[taxCode]) {
                    const tax = taxes.find(tax => tax.taxCode === taxCode);
                    taxesData[taxCode] = {
                        taxCode,
                        taxPercent: tax ? tax.percent : 0.0,
                        taxTotal: Number(taxCodeAmount.toFixed(2))
                    }
                }else {
                    taxesData[taxCode].taxTotal = Number((taxesData[taxCode].taxTotal + taxCodeAmount).toFixed(2));
                }
            });
        });

        return Object.values(taxesData);
    }

    calculateTableData(tableData) {
        let taxes = [];
        let tableTotals = {
            baseWage: 0.0,
            bonus: 0.0,
            deductions: 0.0,
            taxes: 0.0,
            incomeTax: 0.0,
            grossPay: 0.0,
            netPay: 0.0
        }
        let calcTableData = tableData.map((data) => {

            ///const basicRate = this.props.getSalaryByPayPeriod()
            const bonus = data.addedEarnings.value.bonus.amount + data.addedEarnings.value.commission.amount;
            const basicWage = data.wageSalary.value.isFixedSalary ? 
                    this.props.getSalaryByPayPeriod(parseFloat(data.wageSalary.value.salary), data.employee.value.details.payCycle) : 
                    parseFloat(data.wageSalary.value.basicRate) * data.wageSalary.value.hours;
            
            let grossPay = parseFloat(data.grossPay.value.grossPay);
            const pensions = parseFloat(data.grossPay.value.pension.amount);
            const deductions = parseFloat(data.grossPay.value.reimbursement.amount) + pensions;
            
            const amountPerTaxCode = this.calcEmployeeTaxes(data.grossPay.value.grossPay, this.props.payrollForm.taxes, pensions, data.employee.value.details.payCycle);
            const empTaxes = this.getTotalEmployeeTaxAmount(amountPerTaxCode);

            const netPay = (grossPay - deductions) - empTaxes;
            const incomeTax = amountPerTaxCode[TAX_CODE.PAYE] ?? 0.0;

            const rowData = {
                employee: {
                    id: data.employee.value.id,
                    name: `${data.employee.value.firstName} ${data.employee.value.lastName}`,
                    earningsData: data
                },
                baseWage: basicWage,
                bonus: parseFloat(bonus),
                deductions: parseFloat(deductions),
                taxes: parseFloat(empTaxes),
                incomeTax: incomeTax,
                grossPay: parseFloat(grossPay),
                netPay: parseFloat(netPay),
                amountPerTaxCode,
            }
            //Table Totals
            tableTotals = {
                baseWage: tableTotals.baseWage + rowData.baseWage,
                bonus: tableTotals.bonus + rowData.bonus,
                deductions: tableTotals.deductions + rowData.deductions,
                taxes: tableTotals.taxes + rowData.taxes,
                incomeTax: tableTotals.incomeTax + rowData.incomeTax,
                grossPay: tableTotals.grossPay + rowData.grossPay,
                netPay: tableTotals.netPay + rowData.netPay
            }
            return rowData;
        });

        return {
            tableData: calcTableData,
            taxes: taxes,
            totalData: tableTotals
        }
    }

    render () {

        let tableData = this.props.payrollForm.earningsSelected.length === 0 ? this.props.payrollForm.earnings : this.props.payrollForm.earningsSelected.map((item) => {
            return this.props.payrollForm.earnings[item];
        });
        
        const calcData = this.calculateTableData(tableData);
        tableData = calcData.tableData;
        const totals = calcData.totalData;
        const taxTableData = this.calcTaxTableData(this.props.payrollForm.taxes, calcData.tableData);
        let taxTotals = taxTableData.reduce((total, item) => total + item.taxTotal, 0);

        const date = formatDate(this.props.payrollForm.payrollDate);
        const processing = this.state.processing;
        const payoutTotal = calcData.totalData.netPay;

        this.taxesTableData = taxTableData;
        this.payrollTableData = tableData;
        this.tableTotals = totals;

        return (
            <React.Fragment>
            <div className="review-pane">
                <div className="title-info">
                    <h2>Review and Submit</h2>
                    <p>Here's a quick summary to review, To pay staff press the submit payroll button.</p>
                </div>
                <div className="payroll-summary">
                    <div className="summary-box">
                        <div className="payout-details">
                            <h3>Payroll cash required <b>${totals.grossPay.toCurrency()}</b></h3>
                            <h1>Payout cash: <span className="amount">${payoutTotal.toCurrency()}</span></h1>
                            <p><span className="count">{tableData.length}</span> employees to be paid this pay period</p>
                        </div>
                        <div className="date-details">
                            <h3>Payroll Date</h3>
                            <h1>{date}</h1>
                            <p>Date payroll generated</p>
                        </div>
                    </div>
                </div>
                <div className="tables-content">
                    <Section>
                    <NavLink onClick={this.showHideTaxes} className="arrow-link"><span className="arrow-indicator"></span>Taxes Collection <b>${taxTotals.toCurrency()}</b></NavLink>
                        { this.state.showTaxes && 
                        <table className="smry-table --odd-rows">
                            <thead>
                                <tr>
                                    <th>
                                        Tax Code
                                    </th>
                                    <th>
                                        Percent
                                    </th>
                                    <th>
                                        Amount
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                { taxTableData.map((item, i) => {
                                 return <tr key={i}>
                                    <td>
                                        {item.taxCode}
                                    </td>
                                    <td>
                                        {item.taxCode === TAX_CODE.PAYE ? '-' : `${item.taxPercent}%`}
                                    </td>
                                    <td>
                                        ${item.taxTotal.toCurrency()}
                                    </td>
                                </tr>
                                })}
                                <tr className='totals-row'>
                                    <td>Totals</td>
                                    <td></td>
                                    <td>${taxTotals.toCurrency()}</td>
                                </tr>
                                
                            </tbody>
                        </table>
                        }
                    </Section>
                    <Section className='earnings-tables'>
                        <a href><span className="arrow-indicator"></span>Pay advice breakdown</a>
                        <table  className="smry-table pay-advice-table --odd-rows">
                            <thead>
                                <tr>
                                    <th>
                                        Employee
                                    </th>
                                    <th>
                                        Base Salary
                                    </th>
                                    <th>
                                        Bonus
                                    </th>
                                    <th>
                                        Deductions
                                    </th>
                                    <th>
                                        Income Tax
                                    </th>
                                    <th>
                                        Taxes
                                    </th>
                                    <th>
                                        Gross Pay
                                    </th>
                                    <th>
                                        Net Pay
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                { tableData.map((row, i) => {
                                return <tr key={i}>
                                    <td>
                                        {row.employee.name}
                                    </td>
                                    <td>
                                        ${row.baseWage.toCurrency()}
                                    </td>
                                    <td>
                                        ${row.bonus.toCurrency()}
                                    </td>
                                    <td>
                                        - ${row.deductions.toCurrency()}
                                    </td>
                                    <td>
                                       - ${row.incomeTax.toCurrency()}
                                    </td>
                                    <td>
                                       - ${row.taxes.toCurrency()}
                                    </td>
                                    <td>
                                        ${row.grossPay.toCurrency()}
                                    </td>
                                    <td>
                                        ${row.netPay.toCurrency()}
                                    </td>
                                </tr>

                                })}
                                <tr className="totals-row">
                                    <td>
                                        Totals
                                    </td>
                                    <td>
                                        ${totals.baseWage.toCurrency()}
                                    </td>
                                    <td>
                                        ${totals.bonus.toCurrency()}
                                    </td>
                                    <td>
                                       - ${totals.deductions.toCurrency()}
                                    </td>
                                    <td>
                                       - ${totals.incomeTax.toCurrency()}
                                    </td>
                                    <td>
                                       - ${totals.taxes.toCurrency()}
                                    </td>
                                    <td>
                                        ${totals.grossPay.toCurrency()}
                                    </td>
                                    <td>
                                        ${totals.netPay.toCurrency()}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Section>
                </div>
            </div>
            
            <Section className="actions-section">
                { !processing ? 
                <div className="btns-wrap">
                 {this.props.BackButton}
                 <Button className="submit-btn" onClick={ this.submitPayrollData}>
                 Run Payroll</Button>
                </div>
                :
                <div className="loader-modal">
                    <Loader visible={processing} loadingText={'Please wait...'} />
                </div>
                }
             </Section>
            </React.Fragment>
        );
    }
}

export { ReviewPane };