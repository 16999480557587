import { combineEpics } from 'redux-observable';

import {
  pingServerEpic,
  refreshJwtTokenEpic,
  fetchCodeSheets,
  fetchConcurrentCodeSheetResultEntryEpic,
  submitFileUploadEpic,
  requestFileDownloadEpic,
  //fetchConcurrentGlobalDataEpic,
  dismissNotificationEpic,
  fetchLabCodesEpic,
  fetchCodeFindings,
  fetchServicesEpic,
  fetchTaxesEpic,
  submitCodeSheetEpic,
  submitCodeFindingEpic,
  submitLabCodeEpic,
  submitServicesEpic,
  submitTaxesEpic,
  getTaxesByTypeEpic,
  getDashboardDataEpic,
  contextSearchEpic,
  runReportEpic,
  getReportsSummaryEpic,
  submitWorkspaceNameEpic,
} from './commonEpic';

import {
  authenticateEpic,
  authUserRequestEpic,
  getAllUsersEpic,
  getUserEpic,
  findAuthUserRequestEpic,
  submitUserPermissionsEpic,
  changeUserPasswordEpic,
  userAuthAccessRequestEpic
} from './userEpic';

import { getProfileEpic, submitProfileEpic } from './profileEpic';

import {
  getSystemPermissionsEpic,
  getAllRolesEpic,
  //fetchConcurrentRolesPermissionsEpic,
  submitRolePermissionsEpic,
} from './rolesPermissionsEpic';

import {
  getClientAccountEpic,
  getAllAccountsEpic,
  getOwnerAccountEpic,
  getWorkspaceDetailsEpic,
} from './accountEpic';

import {
  getAllClientsEpic,
  submitClientEpic,
  fetchConcurrentUserWorkspaceRolesEpic,
  getClientEpic,
  getClientOverviewDataEpic,
} from './clientEpic';
import {
  getAllPatientsEpic,
  getPatientEpic,
  submitPatientEpic,
  getClientPatientsEpic,
  searchPatientsEpic
} from './patientEpic';

import {
  //fetchConcurrentPatientsFormDetialsEpic,
  getAllSpecimenRecordsEpic,
  getAllSpecimenRecordsApprovedEpic,
  submitSpecimenRecordEpic,
  submitResultSheetEpic,
  addSpecimentStatusEpic,
  getAllClientSpecimenRecordsEpic,
  specimenAttachFileEpic,
  getRecentSpecimenRecordsEpic,
  getClientSpecimentRecordsEpic,
  getPatientSpecimentRecordsEpic,
  getSpecimentRecordEpic,
  submitRequisitionEpic,
  getAllRequisitionsEpic,
  getRequisitionSpecimenRecordsEpic,
  getRequisitionEpic,
  submitRequisitionItemEpic,
  getSpecimensForCabinetEpic,
  getCabinetsEpic,
  submitCabinetDataEpic,
  getClientRequisitionsEpic,
} from './specimenEpic';

import {
  getAllEmployeesEpic,
  getEmployeeEpic,
  submitEmployeeEpic,
  getEmployeePayAdviceEpic,
  terminateEmployeeEpic,
} from './employeeEpic';

import {
  getAllBillsEpic,
  submitBillEpic,
  fetchConcurrentClientsServicesEpic,
  getAllBilledBillsEpic,
  getAllPaidBillsEpic,
  getAllUnpaidBillsEpic,
  getBillEpic,
  getBillsSummaryEpic,
  getBillPaymentsEpic,
} from './billingEpic';

import {
  getAllPaymentsEpic,
  submitPaymentEpic,
  getPaymentsSummaryEpic,
} from './paymentEpic';

import { getAllDepartmentsEpic, submitDepartmentEpic } from './departmentEpic';

import {
  getAllPayrollsEpic,
  submitPayrollEpic,
  getPayAdviceEpic,
} from './payrollEpic';

import {
  getUserMessagesEpic,
  getMessageThreadEpic,
  sendReadMessagesEpic,
  deleteMessagesEpic,
} from './messageEpic';

import {
  getFormItemGroupEpic,
  submitFormGroupEpic,
  getAppPreferencesEpic,
  submitChangePreferencesEpic,
  fetchPrintGroupsEpic,
  submitPrintGroupEpic,
} from './settingsEpic';

const rootEpic = combineEpics(
  pingServerEpic,
  authenticateEpic,
  authUserRequestEpic,
  refreshJwtTokenEpic,
  submitUserPermissionsEpic,
  findAuthUserRequestEpic,
  submitFileUploadEpic,
  requestFileDownloadEpic,
  //fetchConcurrentGlobalDataEpic,
  dismissNotificationEpic,
  fetchLabCodesEpic,
  fetchCodeFindings,
  fetchServicesEpic,
  fetchTaxesEpic,
  submitCodeSheetEpic,
  submitCodeFindingEpic,
  submitLabCodeEpic,
  submitServicesEpic,
  submitTaxesEpic,
  getTaxesByTypeEpic,
  getDashboardDataEpic,
  contextSearchEpic,
  runReportEpic,
  getReportsSummaryEpic,
  submitWorkspaceNameEpic,
  getWorkspaceDetailsEpic,

  fetchCodeSheets,
  fetchConcurrentCodeSheetResultEntryEpic,
  fetchConcurrentUserWorkspaceRolesEpic,
  //Permissions
  getSystemPermissionsEpic,
  //fetchConcurrentRolesPermissionsEpic,
  getAllRolesEpic,
  submitRolePermissionsEpic,

  //User andn Account
  getAllUsersEpic,
  getUserEpic,
  getClientAccountEpic,
  getAllAccountsEpic,
  getOwnerAccountEpic,
  changeUserPasswordEpic,
  userAuthAccessRequestEpic,
  
  //Profile
  getProfileEpic,
  submitProfileEpic,

  //Patient
  getAllPatientsEpic,
  getPatientEpic,
  submitPatientEpic,
  getClientPatientsEpic,
  searchPatientsEpic,

  //Specimen
  //fetchConcurrentPatientsFormDetialsEpic,
  getAllSpecimenRecordsEpic,
  getAllSpecimenRecordsApprovedEpic,
  submitSpecimenRecordEpic,
  submitResultSheetEpic,
  addSpecimentStatusEpic,
  getAllClientSpecimenRecordsEpic,
  specimenAttachFileEpic,
  getRecentSpecimenRecordsEpic,
  getClientSpecimentRecordsEpic,
  getPatientSpecimentRecordsEpic,
  getSpecimentRecordEpic,
  submitRequisitionEpic,
  getAllRequisitionsEpic,
  getRequisitionSpecimenRecordsEpic,
  getRequisitionEpic,
  submitRequisitionItemEpic,
  getSpecimensForCabinetEpic,
  getCabinetsEpic,
  submitCabinetDataEpic,
  getClientRequisitionsEpic,

  //Client,
  getAllClientsEpic,
  submitClientEpic,
  getClientEpic,
  getClientOverviewDataEpic,

  //Employee
  getAllEmployeesEpic,
  getEmployeeEpic,
  submitEmployeeEpic,
  getEmployeePayAdviceEpic,
  terminateEmployeeEpic,

  //Bill
  getAllBillsEpic,
  submitBillEpic,
  getAllBilledBillsEpic,
  fetchConcurrentClientsServicesEpic,
  getAllPaidBillsEpic,
  getAllUnpaidBillsEpic,
  getBillEpic,
  getBillPaymentsEpic,
  getBillsSummaryEpic,
  //Payment
  getAllPaymentsEpic,
  submitPaymentEpic,
  getPaymentsSummaryEpic,
  //Department
  getAllDepartmentsEpic,
  submitDepartmentEpic,
  //Payroll
  getAllPayrollsEpic,
  submitPayrollEpic,
  getPayAdviceEpic,
  //Messages
  getUserMessagesEpic,
  getMessageThreadEpic,
  sendReadMessagesEpic,
  deleteMessagesEpic,

  //Settings
  getFormItemGroupEpic,
  submitFormGroupEpic,
  getAppPreferencesEpic,
  submitChangePreferencesEpic,
  fetchPrintGroupsEpic,
  submitPrintGroupEpic
);

export default rootEpic;
