
import {ofType } from 'redux-observable';
import { catchError, map, switchMap, mergeMap, takeUntil } from 'rxjs/operators';
import *  as actionTypes from '../actionType';
import { employeeDialect, checkReponse } from '../../service/agent';
import { of } from 'rxjs';
import * as actions from '../actions';
import { apiResponseActionCreator } from '../actions';
import { REQUESTS_TYPE } from '../../common/constant';

export const getAllEmployeesEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.FETCH_EMPLOYEES),
        switchMap((action) => {
            return employeeDialect.fetchAllEmployees(action.payload).pipe(
               map(response => {
                    const responseData = checkReponse(response, action.type);
                    return  actions.apiResponseActionCreator(actionTypes.FETCHED_EMPLOYEES_RESULT, responseData);
                }),
               catchError(error => {
                    console.log("ERROR", error);
                    return of( actions.apiResponseActionCreator(actionTypes.FETCHED_EMPLOYEES_RESULT, error));
               })
            );
        })
    )
}

export const getEmployeeEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.FETCH_EMPLOYEE),
        switchMap((action) => {
            return employeeDialect.fetchEmployee(action.payload).pipe(
               map(response => {
                    const responseData = checkReponse(response, action.type);
                    return  actions.apiResponseActionCreator(actionTypes.FETCHED_EMPLOYEE_RESULT, responseData);
                }),
               catchError(error => {
                    console.log("ERROR", error);
                    return of( actions.apiResponseActionCreator(actionTypes.FETCHED_EMPLOYEE_RESULT, error));
               })
            );
        })
    )
}

export const submitEmployeeEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.EMPLOYEE_SUBMIT_DATA),
        
        mergeMap((action) => {
          const apiCalls = {
            'delete': employeeDialect.deleteEmployee,
            'create': employeeDialect.submitEmployee,
            'update': employeeDialect.updateEmployee
          }
            return apiCalls[action.payload.requestType](action.payload).pipe(
               map(response => {
                    const responseData = checkReponse(response, action.type, action.payload.requestType);
                    return apiResponseActionCreator(actionTypes.EMPLOYEE_SUBMITED_RESULTS, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
               catchError(error => {
                    console.log("ERROR", error);
                    return of(apiResponseActionCreator(actionTypes.EMPLOYEE_SUBMITED_RESULTS, error));
               })
            );
        })
  )};

export const getEmployeePayAdviceEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.FETCH_EMPLOYEE_PAYADVICE),
        switchMap((action) => {
            return employeeDialect.fetchEmployeePayAdvice(action.payload).pipe(
                map(response => {
                    const responseData = checkReponse(response, action.type);
                    return  actions.apiResponseActionCreator(actionTypes.FETCHED_EMPLOYEE_PAYADVICE_RESULT, responseData);
                }),
                catchError(error => {
                    console.log("ERROR", error);
                    return of( actions.apiResponseActionCreator(actionTypes.FETCHED_EMPLOYEE_PAYADVICE_RESULT, error));
                })
            );
        })
    )
}

export const terminateEmployeeEpic = (action$) => {
    return action$.pipe(
        ofType(actionTypes.TERMINATE_EMPLOYEE),

        mergeMap((action) => {
            return employeeDialect.terminateEmployee(action.payload).pipe(
                map((response) => {
                    const responseData = checkReponse(response, action.type, REQUESTS_TYPE.UPDATE);
                    return apiResponseActionCreator(actionTypes.TERMINATE_EMPLOYEE_RESULT, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
                catchError((error) => {
                    console.log('ERROR', error);
                    return of(apiResponseActionCreator(actionTypes.TERMINATE_EMPLOYEE_RESULT, error));
                })
            )
        })
    );
}