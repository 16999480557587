import * as actionTypes from '../../actionType';
import { isError } from '../../../service/agent';
import { ApiRequestManager } from '../../../utility/ApiRequestManager';
import { REQUESTS_TYPE } from '../../../common/constant'

import labcodeReducer, { labcodeState } from './labcode';
import codeSheetReducer, { codeSheetState } from './codesheet';
import codeFindingReducer, { codeFindingState } from './codefinding';
import taxesReducer, { taxesState } from './taxes';
import serviceReducer, { serviceState } from './services';

const initialState = {
    viewInit: false,
    isSaving: false,
    requestType: null,
    formSetting: {
        formGroupsList: null,
        featureGroupData: null,
        printGroups: null,
        formData: {
            name: '',
            formType: null,
            items: [ ],
        }
    },

    preferences: null,

    ...labcodeState,
    ...codeSheetState,
    ...codeFindingState,
    ...taxesState,
    ...serviceState
 };

var error = null;
let empty = {}
let emptyArray = [];
let apiOutcome = null;
let requestManager = null;

const settingsReducer = (state = initialState, action ) => { 

    switch(action.type) {
        case actionTypes.FORM_GROUP_SET_DATA:
            return {
                ...state,
                formSetting: {
                    ...state.formSetting,
                    featureGroupData:  action.payload,
                    printGroups: action.payload ? action.payload.printGroups : []
                }
            }
        case actionTypes.FORM_GROUP_FIELD_CHANGED:
            return {
                ...state,
                formSetting: {
                    ...state.formSetting,
                    formData:  {
                        ...state.formSetting.formData,
                        ...action.payload
                    }
                }
            }
        case actionTypes.FETCHED_FORM_ITEM_GROUP_RESULTS:
            error  = isError(action.payload);

            return {
                ...state,
                formSetting: {
                    ...state.formSetting,
                   formGroupsList: error ? emptyArray : action.payload,
                }
            }
        case actionTypes.FORM_GROUP_SUBMIT_DATA:
            return {
                ...state,
                requestType: action.payload.requestType
            }
        case actionTypes.FORM_GROUP_SUBMITED_RESULTS:
            error  = isError(action.payload);

            const {formGroupId} = action.payload;
            
            if ( state.requestType === 'delete' && formGroupId !== undefined ) {
                const removedList = state.formSetting.formGroupsList.filter((item) => item.id != formGroupId);
                return {
                    ...state,
                    formSetting: {
                        ...state.formSetting,
                        featureGroupData: null,
                        formData: {
                            ...initialState.formSetting.formData
                        },
                        formGroupsList: removedList, 
                    },
                    redirectTo: '/settings/form',
                }
            }
            var updatedList = state.formSetting.formGroupsList;
           
            if (state.requestType === 'create') {
                updatedList = error ? state.formSetting.formGroupsList : [...state.formSetting.formGroupsList, action.payload];

            }else if (state.requestType === 'update') {
                updatedList = error ? state.formSetting.formGroupsList : state.formSetting.formGroupsList.map((item) => {
                    if (item.id === action.payload.id) {
                        item = {
                            ...action.payload
                        }
                    }
                    return item;
                });
            }

            return {
                ...state,
                formSetting: {
                    ...state.formSetting,
                    featureGroupData: error ? state.formSetting.featureGroupData : action.payload,
                    formGroupsList: updatedList,
                }
            }
            case actionTypes.REDIRECT:
                return { ...state, 
                    redirectTo: null 
                };
            case actionTypes.FETCHED_APP_PREFERENCES_RESULTS:
                error  = isError(action.payload);
                return {
                    ...state,
                    preferences : error ? empty : action.payload
                }
            case actionTypes.SETTINGS_OPTION_CHANGED:
                
                if (action.payload.slice === 'group') {
                    const selectedGroup = [
                        ...state.preferences.prefsGroup[action.payload.data.identifier].preferences
                    ];
                    
                    const updatedPrefs = selectedGroup.map((item) => {
                    
                        if ( action.payload.data.pref.prefId === item.id) {
                            item = {
                                ...item,
                                valueSet: {
                                    ...item.valueSet,
                                [action.payload.data.pref.valueKey]: action.payload.data.pref.value
                                }
                            }
                        }
                        return item;
                    });
                    
                    const updatePreferences = {
                        ...state.preferences,
                        prefsGroup: {
                            ...state.preferences.prefsGroup,
                            [action.payload.data.identifier]: {
                                ...state.preferences.prefsGroup[action.payload.data.identifier],
                                preferences : updatedPrefs
                            } 
                        }
                    }
                    
                    return {
                        ...state,
                        preferences: {
                        ...updatePreferences
                        }
                    }
                }
                return {
                    ...state
                }
            case actionTypes.SUBMIT_CHANGED_PREFERENCES:
                return {
                    ...state,
                    isSaving: true
                }
            case actionTypes.SUBMIT_CHANGED_PREFERENCES_RESULT:
                error = isError(action.payload)
                return {
                    ...state,
                    isSaving: false,
                    updateSuccess: isError ? false : action.payload.sucess
                }
            //Sub reducers
            case actionTypes.FETCHED_LABCODES_RESULTS:
            case actionTypes.SET_LABCODE_DATA:
            case actionTypes.LABCODE_SUBMIT_DATA:
            case actionTypes.LABCODE_SUBMIT_DATA_RESULT:
                return labcodeReducer(state, action);
            
            case actionTypes.SET_CODESHEETS_DATA:
            case actionTypes.CODESHEET_SUBMIT_DATA:
            case actionTypes.CODESHEET_SUBMIT_DATA_RESULT:
            case actionTypes.FETCHED_CODE_SHEETS_RESULTS:
                return codeSheetReducer(state, action);

            case actionTypes.SET_CODEFINDINGS_DATA:
                case actionTypes.CODEFINDING_SUBMIT_DATA:
                case actionTypes.CODEFINDING_SUBMIT_DATA_RESULT:
                case actionTypes.FETCHED_CODE_FINDINGS_RESULTS:
                    return codeFindingReducer(state, action);

            case actionTypes.SET_TAX_DATA:
            case actionTypes.FETCHED_TAXES_RESULTS:
            case actionTypes.TAX_SUBMIT_DATA:
            case actionTypes.TAX_SUBMIT_DATA_RESULT:
                return taxesReducer(state, action);

            case actionTypes.SET_SERVICES_DATA:
            case actionTypes.SERVICE_SUBMIT_DATA:
            case actionTypes.SERVICE_SUBMIT_DATA_RESULT:
            case actionTypes.FETCHED_SERVICES_RESULT:
                return serviceReducer(state, action);

        case actionTypes.FETCHED_PRINT_GROUPS_RESULTS:
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.FETCH_PRINT_GROUPS) || {};

            return {
                ...state,
                formSetting: {
                    ...state.formSetting,
                    printGroups: apiOutcome.success ? action.payload : emptyArray
                }
            }

        case actionTypes.SPECIMEN_RESULTSHEET_VIEW_UNLOADED:
            return {
            ...state,
            formSetting: {
                ...state.formSetting,
                printGroups: null
            }
        }

        case actionTypes.PRINT_GROUP_SUBMIT_RESULTS:
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.PRINT_GROUP_SUBMIT_DATA) || {};
            const printGroups = state.formSetting.printGroups;

            if(apiOutcome.requestType === REQUESTS_TYPE.DELETE){
                return {
                    ...state,
                    formSetting: {
                        ...state.formSetting,
                        printGroups: apiOutcome.error ? printGroups : printGroups.filter(group => group.id !== action.payload.formPrintGroupId)
                    }
                }
            }
            if(apiOutcome.requestType === REQUESTS_TYPE.CREATE){
                return {
                    ...state,
                    formSetting: {
                        ...state.formSetting,
                        printGroups: apiOutcome.error ? printGroups : [...printGroups, action.payload]
                    }
                }
            }
            if(apiOutcome.requestType  === REQUESTS_TYPE.UPDATE){
                return {
                    ...state,
                    formSetting: {
                        ...state.formSetting,
                        printGroups: apiOutcome.error ? printGroups : printGroups.map(group => {
                            if(group.id === action.payload.id){
                                return action.payload;
                            }

                            return group;
                        })
                    }
                }
            }
            return state;

        default:
            return state;
    }
};

export default settingsReducer;