import React from 'react';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import { setupPage } from '../../components/Layout/setupPage';
import * as actionTypes from '../../store/actionType';
import  * as actions  from '../../store/actions';
import * as urls from '../../config/urls';
import {Loader } from '../components/partials/LoadingIndicator';
import FileUploader from '../../utility/FileUploader';

import './ResultSheet.style.scss';

import { 
    Button,
} from '../../components';


class Viewer extends React.Component {

    static pageOption = {
        pageTitle: 'Viwer',
        disableBodyScroll: true
    };

    constructor(props) {
        super(props);
       this.state = {}
       this.uploader = FileUploader.getInstance(FileUploader);
    }

    componentDidMount() {
        if (this.props.file) {

            (this.isPdfFile() && this.uploader.downloadFile(this.props.file.fileName));
        }
    }
    componentWillUnmount() {
        this.props.pageConfig.disableBodyScroll(true);
        document.body.classList.remove('result-sheet');
    }
    static getDerivedStateFromProps(props, state) {
    
        return null;
    }

    isPdfFile() {
        const extension = this.uploader.getFileExtension(this.props.file.fileName);
        const isPdf = extension === 'pdf' ? true : false;
        return isPdf;
    }
    
    render() {
        const file = this.props.file;
        const loading = false;
        const extension = this.uploader.getFileExtension(file.fileName);  
        const isPdf = this.isPdfFile();

        return <Fragment>
            <div className="viewer-modal">
                <div className='viewer-layout-wrap'>
                    <div className="nav-bar">
                        <div className="action-sec">
                            <Button className="back-button" onClick={this.props.showHideView}>
                                 <span className="arrow-left"></span>   
                            </Button>
                            <div className="fNme">
                                <span className="ext">{extension.toUpperCase()}</span>
                                    <span className="name">
                                        { file.fileName }
                                    </span>
                            </div>
                        </div>
                    </div>
                    
                    <div className="content-area">
                        <div className="loader-modal">
                            <Loader visible={loading} loadingText={''} />
                        </div>
                        { isPdf ? 
                        <div className="pdf-view">
                            <embed src={`${this.props.fileDownload}#toolbar=0&navpanes=0`} width="100%" height="100%" 
                            type="application/pdf"></embed>
                        </div>
                        :
                        <div className="img-view">
                            <img className="image" src={file.fileDownloadUri} />
                        </div>
                        }
                    </div>
                </div>
            </div>
            </Fragment>
    }
};



const ViewerModal = (props) => {
    return (
        <Viewer {...props } />
    );
};

let ConfigViewer = setupPage(Viewer.pageOption)(ViewerModal);

export  { ConfigViewer };