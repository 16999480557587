import {ofType } from 'redux-observable';
import { catchError,map, switchMap, mergeMap, takeUntil } from 'rxjs/operators';
import *  as actionTypes from '../actionType';
import { specimenDialect, checkReponse} from '../../service/agent';
import { from, defer,of, forkJoin } from 'rxjs';
import {apiResponseActionCreator} from '../actions';
import { REQUESTS_TYPE } from '../../common/constant';
import  { requestEnded } from '../../service/agentDialect';

export const fetchConcurrentPatientsFormDetialsEpic = (action$, store) =>
    action$.pipe(
    ofType(actionTypes.FETCH_FORM_DETAILS),
    mergeMap(action => {
      return forkJoin(
        [
          specimenDialect.fetchFromDetails(action.payload.form)
      ])
        .pipe(
        map(([formDetailsResponse]) => {

          let allPayload = {
            formDetails: checkReponse(formDetailsResponse, action.type)
          }
          return {type: actionTypes.FETCHED_FORM_DETAILS_RESULTS, payload: allPayload}
        }),
        catchError(err => {
          console.log(err);
          requestEnded(actionTypes.FETCH_FORM_DETAILS);
          return of({type: 'error', payload: err })})
        
      )
    })
 );

 /*
 .subscribe({
        next: (response) => {
          console.log(response);
        },
        error: (error) => {
          console.log(error);
        }
      })*/

export const getAllSpecimenRecordsApprovedEpic = action$ => {
  return action$.pipe(
      ofType(actionTypes.FETCH_SPECIMEN_RECORDS_APPROVED),
      
      mergeMap((action) => {
          return specimenDialect.fetchAllApprovedSpecimenRecords(action.payload).pipe(
             map(response => {
                  const responseData = checkReponse(response, action.type);
                  return apiResponseActionCreator(actionTypes.FETCHED_SPECIMEN_RECORDS_APPROVED_RESULTS, responseData);
              }),
              takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
             catchError(error => {
                  console.log("ERROR", error);
                  return of(apiResponseActionCreator(actionTypes.FETCHED_SPECIMEN_RECORDS_APPROVED_RESULTS, error));
             })
          );
      })
)};

export const getAllSpecimenRecordsEpic = action$ => {
  return action$.pipe(
      ofType(actionTypes.FETCH_SPECIMEN_RECORDS),
      
      switchMap((action) => {
        const response$ = defer(() => from(specimenDialect.fetchAllSpecimenRecords(action.payload)));
          return response$.pipe(
             map(response => {
                  const responseData = checkReponse(response, action.type);
                  return apiResponseActionCreator(actionTypes.FETCHED_SPECIMEN_RECORDS_RESULTS, responseData);
              }),
              takeUntil(action$.pipe(ofType(actionTypes.FETCH_SPECIMEN_RECORDS + actionTypes.ABORT_API_REQUEST))),
              catchError(error => {
                  console.log("ERROR", error);
                  return of(apiResponseActionCreator(actionTypes.FETCHED_SPECIMEN_RECORDS_RESULTS, error));
             })
          );
      })
)};

 export const submitSpecimenRecordEpic = action$ => {
  return action$.pipe(
      ofType(actionTypes.SPECIMEN_SUBMIT_DATA),
      
      mergeMap((action) => {
        const apiCalls = {
          'delete': specimenDialect.deleteSpecimen,
          'create': specimenDialect.createSpeciment,
          'submit': specimenDialect.submitSpeciment,
          'update': specimenDialect.updateSpeciment
        }
          return apiCalls[action.payload.requestType](action.payload).pipe(
             map(response => {
                  const responseData = checkReponse(response, action.type, action.payload.requestType);
                  return apiResponseActionCreator(actionTypes.SPECIMEN_SUBMITED_RESULTS, responseData);
              }),
              takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
             catchError(error => {
                  console.log("ERROR", error);
                  return of(apiResponseActionCreator(actionTypes.SPECIMEN_SUBMITED_RESULTS, error));
             })
          );
      })
)};

export const submitResultSheetEpic = action$ => {
  return action$.pipe(
      ofType(actionTypes.RESULT_SHEET_SUBMIT),
      
      switchMap((action) => {
        const apiCalls = {
          'create': specimenDialect.submitResultSheet,
          'update': specimenDialect.updateResultSheet,
          'report': specimenDialect.resultSheetReports,
          'approve': specimenDialect.approveResultSheet,
        }
          return apiCalls[action.payload.requestType](action.payload).pipe(
             map(response => {
                  const responseData = checkReponse(response, action.type, action.payload.requestType);
                  return apiResponseActionCreator(actionTypes.RESULT_SHEET_SUBMITED_RESULTS, responseData);
              }),
              takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
             catchError(error => {
                  console.log("ERROR", error);
                  return of(apiResponseActionCreator(actionTypes.RESULT_SHEET_SUBMITED_RESULTS, error));
             })
          );
      })
)};

export const addSpecimentStatusEpic = action$ => {
  return action$.pipe(
      ofType(actionTypes.SPECIMEN_ADD_STATUS),
      
      switchMap((action) => {
          return specimenDialect.addSpecimenStatus(action.payload).pipe(
             map(response => {
                  const responseData = checkReponse(response, action.type);
                  return apiResponseActionCreator(actionTypes.SPECIMEN_ADD_STATUS_RESULT, responseData);
              }),
              takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
             catchError(error => {
                  console.log("ERROR", error);
                  return of(apiResponseActionCreator(actionTypes.SPECIMEN_ADD_STATUS_RESULT, error));
             })
          );
      })
)};

export const getAllClientSpecimenRecordsEpic = action$ => {
  return action$.pipe(
      ofType(actionTypes.FETCH_CLIENT_SPECIMEN_RECORDS),
      
      switchMap((action) => {
          return specimenDialect.fetchAllClientSpecimenRecords(action.payload).pipe(
             map(response => {
                  const responseData = checkReponse(response, action.type);
                  return apiResponseActionCreator(actionTypes.FETCHED_CLIENT_SPECIMEN_RECORDS_RESULTS, responseData);
              }),
              takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
             catchError(error => {
                  console.log("ERROR", error);
                  return of(apiResponseActionCreator(actionTypes.FETCHED_CLIENT_SPECIMEN_RECORDS_RESULTS, error));
             })
          );
      })
)};

export const specimenAttachFileEpic = action$ => {
  return action$.pipe(
      ofType(actionTypes.SPECIMEN_ATTACH_FILE),
      mergeMap((action) => {
          return specimenDialect.attachFileUpload(action.payload).pipe(
             map(response => {
                  const responseData = checkReponse(response, action.type);
                  return apiResponseActionCreator(actionTypes.SPECIMEN_ATTACH_FILE_RESULTS, responseData);
              }),
              takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
             catchError(error => {
                  console.log("ERROR", error);
                  return of(apiResponseActionCreator(actionTypes.SPECIMEN_ATTACH_FILE_RESULTS, error));
             })
          );
      })
)};

export const getRecentSpecimenRecordsEpic = action$ => {
  return action$.pipe(
      ofType(actionTypes.FETCH_RECENT_SPECIMEN_RECORDS),
      
      switchMap((action) => {
          return specimenDialect.fetchAllRecentSpecimenRecords(action.payload).pipe(
             map(response => {
                  const responseData = checkReponse(response, action.type);
                  return apiResponseActionCreator(actionTypes.FETCHED_RECENT_SPECIMEN_RECORDS_RESULTS, responseData);
              }),
              takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
             catchError(error => {
                  console.log("ERROR", error);
                  return of(apiResponseActionCreator(actionTypes.FETCHED_RECENT_SPECIMEN_RECORDS_RESULTS, error));
             })
          );
      })
)};

export const getClientSpecimentRecordsEpic = action$ => {
  return action$.pipe(
      ofType(actionTypes.FETCH_CLIENT_SPECIMENS_HISTORY),
      switchMap((action) => {
          return specimenDialect.fetchClientSpecimenRecords(action.payload).pipe(
             map(response => {
                  const responseData = checkReponse(response, action.type);
                  return apiResponseActionCreator(actionTypes.FETCHED_CLIENT_SPECIMENS_HISTORY_RESULTS, responseData);
              }),
             catchError(error => {
                  console.log("ERROR", error);
                  return of(apiResponseActionCreator(actionTypes.FETCHED_CLIENT_SPECIMENS_HISTORY_RESULTS, error));
             })
          );
      })
  )
}
export const getPatientSpecimentRecordsEpic = action$ => {
  return action$.pipe(
      ofType(actionTypes.FETCH_PATIENT_SPECIMENS_HISTORY),
      switchMap((action) => {
          return specimenDialect.fetchPatientSpecimenRecords(action.payload).pipe(
             map(response => {
                  const responseData = checkReponse(response, action.type);
                  return apiResponseActionCreator(actionTypes.FETCHED_PATIENT_SPECIMENS_HISTORY_RESULTS, responseData);
              }),
             catchError(error => {
                  console.log("ERROR", error);
                  return of(apiResponseActionCreator(actionTypes.FETCHED_PATIENT_SPECIMENS_HISTORY_RESULTS, error));
             })
          );
      })
  )
}

export const getSpecimentRecordEpic = action$ => {
  return action$.pipe(
      ofType(actionTypes.FETCH_SPECIMEN_RECORD),
      switchMap((action) => {
          return specimenDialect.fetchSpecimenRecord(action.payload).pipe(
             map(response => {
                  const responseData = checkReponse(response, action.type);
                  return apiResponseActionCreator(actionTypes.FETCHED_SPECIMEN_RECORD_RUSULT, responseData);
              }),
             catchError(error => {
                  console.log("ERROR", error);
                  return of(apiResponseActionCreator(actionTypes.FETCHED_SPECIMEN_RECORD_RUSULT, error));
             })
          );
      })
  )
}

export const getAllRequisitionsEpic = action$ => {
  return action$.pipe(
      ofType(actionTypes.FETCH_REQUISITIONS),
      
      switchMap((action) => {
          const request$ = defer(() => from( specimenDialect.fetchAllRequisitions(action.payload)));
          return request$.pipe(
             map(response => {
                  const responseData = checkReponse(response, action.type);
                  return apiResponseActionCreator(actionTypes.FETCHED_REQUISITIONS_RESULTS, responseData);
              }),
              takeUntil(action$.pipe(ofType(actionTypes.FETCH_REQUISITIONS + actionTypes.ABORT_API_REQUEST))),
              catchError(error => {
                  console.log("ERROR", error);
                  return of(apiResponseActionCreator(actionTypes.FETCHED_REQUISITIONS_RESULTS, error));
             })
          );
      })
)};

export const getClientRequisitionsEpic = action$ =>{
  return action$.pipe(
    ofType(actionTypes.FETCH_CLIENT_REQUISITIONS),

    switchMap(
      (action) => {
        return specimenDialect.fetchClientRequisitions(action.payload).pipe(
          map(response => {
            const responseData = checkReponse(response, action.type, REQUESTS_TYPE.FETCH);
            return apiResponseActionCreator(actionTypes.FETCHED_CLIENT_REQUISITIONS_RESULTS, responseData);
          }),
          takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
          catchError((error) => {
            console.log("ERROR: ", error);
            return of(apiResponseActionCreator(actionTypes.FETCHED_CLIENT_REQUISITIONS_RESULTS, error));
          })
        )
      }
    )
  )
}

export const submitRequisitionEpic = action$ => {
  return action$.pipe(
      ofType(actionTypes.REQUISITION_SUBMIT_DATA),
      
      mergeMap((action) => {
        const apiCalls = {
          [REQUESTS_TYPE.CREATE]: specimenDialect.createRequisition,
          [REQUESTS_TYPE.DELETE]: specimenDialect.deleteRequistion,
        }
          return apiCalls[action.payload.requestType](action.payload).pipe(
             map(response => {
                  const responseData = checkReponse(response, action.type, action.payload.requestType);
                  return apiResponseActionCreator(actionTypes.REQUISITION_SUBMITED_RESULTS, responseData);
              }),
              takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
             catchError(error => {
                  console.log("ERROR", error);
                  return of(apiResponseActionCreator(actionTypes.REQUISITION_SUBMITED_RESULTS, error));
             })
          );
      })
)};

export const submitRequisitionItemEpic = action$ =>{
  return action$.pipe(
    ofType(actionTypes.REQUISITION_ITEM_SUBMIT_DATA),

    mergeMap(action =>{
      const apiCalls = {
        [REQUESTS_TYPE.DELETE]: specimenDialect.deleteRequistionItem,
      }

      return apiCalls[action.payload.requestType](action.payload).pipe(
        map(response =>{
        const responseData = checkReponse(response, action.type, action.payload.requestType);
        return apiResponseActionCreator(actionTypes.REQUISITION_ITEM_SUBMITTED_RESULTS, responseData);
        }),
        takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
        catchError(error=>{
          console.log('ERROR');
          return of(apiResponseActionCreator(actionTypes.REQUISITION_ITEM_SUBMITTED_RESULTS, error));
        })
      );
    })
)};

export const getRequisitionSpecimenRecordsEpic = action$ => {
  return action$.pipe(
      ofType(actionTypes.FETCH_REQUISITION_SPECIMENS),
      
      switchMap((action) => {
          const reqeust$ =  defer(() => from(specimenDialect.fetchRequisitionSpecimenRecords(action.payload)));
          return reqeust$.pipe(
             map(response => {
                  const responseData = checkReponse(response, action.type);
                  return apiResponseActionCreator(actionTypes.FETCHED_REQUISITION_SPECIMENS_RESULTS, responseData);
              }),
              takeUntil(action$.pipe(ofType(actionTypes.FETCH_REQUISITION_SPECIMENS + actionTypes.ABORT_API_REQUEST))),
              catchError(error => {
                  console.log("ERROR", error);
                  return of(apiResponseActionCreator(actionTypes.FETCHED_REQUISITION_SPECIMENS_RESULTS, error));
             })
          );
      })
)};


export const getRequisitionEpic = action$ => {
  return action$.pipe(
      ofType(actionTypes.FETCH_REQUISITION),
      switchMap((action) => {
          return specimenDialect.fetchRequisition(action.payload).pipe(
             map(response => {
                  const responseData = checkReponse(response, action.type);
                  return apiResponseActionCreator(actionTypes.FETCHED_REQUISITION_RUSULT, responseData);
              }),
              takeUntil(action$.pipe(ofType(actionTypes.FETCH_REQUISITION + actionTypes.ABORT_API_REQUEST))),
              catchError(error => {
                  console.log("ERROR", error);
                  return of(apiResponseActionCreator(actionTypes.FETCHED_REQUISITION_RUSULT, error));
             })
          );
      })
  )
}

export const getSpecimensForCabinetEpic = (action$) => {
  return action$.pipe(
    ofType(actionTypes.FETCH_SPECIMENS_FOR_CABINET),

    switchMap((action) => {
      return specimenDialect.getSpecimensForCabinet(action.payload).pipe(
        map(response => {
          const responseData = checkReponse(response, action.type);
          return apiResponseActionCreator(actionTypes.FETCHED_SPECIMENS_FOR_CABINET_RESULTS, responseData);
        }),
        takeUntil(action$.pipe(ofType(actionTypes.FETCH_SPECIMENS_FOR_CABINET + actionTypes.ABORT_API_REQUEST))),
        catchError(error => {
          console.log('ERROR ', error);
          return of(apiResponseActionCreator(actionTypes.FETCHED_SPECIMENS_FOR_CABINET_RESULTS, error))
        })
      )
    })
  )
}

export const getCabinetsEpic = (action$) => {
  return action$.pipe(
    ofType(actionTypes.FETCH_CABINETS),

    switchMap((action) => {
      return specimenDialect.getCabinets(action.payload).pipe(
        map(response => {
          const responseData = checkReponse(response, action.type);
          return apiResponseActionCreator(actionTypes.FETCHED_CABINETS_RESULT, responseData);
        }),
        takeUntil(action$.pipe(ofType(actionTypes.FETCH_CABINETS + actionTypes.ABORT_API_REQUEST))),
        catchError(error => {
          console.log('ERROR ', error);
          return of(apiResponseActionCreator(actionTypes.FETCHED_CABINETS_RESULT, error))
        })
      )
    })
  )
}

export const submitCabinetDataEpic = (action$) => {
  return action$.pipe(
    ofType(actionTypes.CABINET_SUBMIT_DATA),

    mergeMap((action) => {
      const apiCalls = {
        [REQUESTS_TYPE.SUBMIT]: specimenDialect.createCabinet,
        [REQUESTS_TYPE.UPDATE]: specimenDialect.updateCabinet,
        [REQUESTS_TYPE.DELETE]: specimenDialect.deleteCabinet,
      };

      return apiCalls[action.payload.requestType](action.payload.data).pipe(
        map(response => {
          const responseData = checkReponse(response, action.type, action.payload.requestType);
          return apiResponseActionCreator(actionTypes.CABINET_SUBMIT_DATA_RESULTS, responseData);
        }),
        takeUntil(action$.pipe(ofType(actionTypes.CABINET_SUBMIT_DATA + actionTypes.ABORT_API_REQUEST))),
        catchError((error) => {
          console.log('ERROR ', error);
          return of(apiResponseActionCreator(actionTypes.CABINET_SUBMIT_DATA_RESULTS, error))
        })
      )
    })
  )
}