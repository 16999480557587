import React from 'react';
//import { DefaultEditor } from 'react-simple-wysiwyg';
import { Caret } from './../../utility/Caret';
import { 
    Button,
    InputField
 } from '../../components';

 import { 
    DefaultEditor
 } from '../../components/SimpleEditor';

 import {stripHtmlTags} from './../../utility/helpers';
import { getIconUrl } from '../../common/images-catalogue';

const RecipientItem = (props) => {
    const {user} = props;
    const remove = () => {
        props.removeRecipient(user);
    }
    return (
        <div className="sender-Itm">
            <span>{user}</span> <Button onClick={remove}>X</Button>
        </div>
    );
};
const Compose = (props) => {
    const { label, inline, actions, contextMessage } = props;
    const [html, setHtml] = React.useState('');
    const [mini, setMini] = React.useState(false);
    const [subject, setSubject] = React.useState('');
    const [recipients, setRecipients] = React.useState([]);
    const [recipientsList, setRecipientsList] = React.useState('');
    const recipientField = React.createRef();

    const recipientFirstName = contextMessage? contextMessage.tag === "Inbound" ? contextMessage.headerFrames[0].sender.firstName : contextMessage.headerFrames[0].recipient.firstName : '';
    const recipientLastName = contextMessage? contextMessage.tag === "Inbound" ? contextMessage.headerFrames[0].sender.lastName : contextMessage.headerFrames[0].recipient.lastName : '';

    const clear = () => {
        setHtml('');
        if (!inline) {
            setSubject('')
            setRecipients([]);
            setRecipientsList('');
        }
    }
    const onClose = () => {
        clear();
        actions.close();
    }
    const onMinimize = (evt) => {
        setMini(!mini);
        actions.minimize(mini);
    }
    const didType = (evt) => {
        setHtml(evt.target.value.trim());
    }
    const onFocus = (evt) => {
        const text = recipients.join(', ');
        setRecipients([]);
        setRecipientsList(text);
        Caret.setEndContenteditable(evt.target);
    }
    const onBlur = (evt) => {
        let list = evt.target.value.replace(/[^a-zA-Z0-9\,]/g, "").split(',');
        list = list.map((item) => item.trim());

        let userList = [];
        list.map((item) => {
            if (!userList.includes(item)) {
                userList.push(item);
            }
        });

        let filteredList = recipients.filter(item => {
            userList.includes(item);
            if (userList.includes(item)) {
                userList.splice(userList.indexOf(item), 1);
                return item; 
            }else {
                return item;
            }
        });

        userList = filteredList.merge(userList);

        userList = userList.filter((user) => {
            if (user !== '') {
                return user;
            }
        });
               
        userList.length > 0 && setRecipients(userList);
        setRecipientsList('');
    }

    const sendMessage = () => {
        const messagePayload = {
            subject: subject.trim(),
            recipients: recipients,
            body: html
        }
        actions.sendMessage(messagePayload, inline);
        inline ? clear() : onClose();
    }

    const onRecipient = (evt) => {
        setRecipientsList(evt.target.value);
    }

    const onSubject = (evt) => {
        setSubject(stripHtmlTags(evt.target.value));
    }

    const removeRecipient = (user) => {
        let list = recipients.filter((item) => {
            item = item.trim();
            if (item !== user) {
                return user;
            }
        });
        setRecipients(list);
    }

    return (<div className={`compose-wrp`}>
                <div className="CBx Mi">
                    <div className="cCt-inner">
                        { !inline &&
                        <div className="Ac-bar">
                            <div className="Wi-Nme">
                                <span>New Message</span>
                            </div>
                            <div className="Wi-act">
                                <Button onClick={onMinimize}>
                                    <span className="dash icon"></span>
                                </Button>
                                <Button onClick={onClose}>
                                    <div className="xIcon icon">
                                        <span className="HLine"></span>
                                        <span  className="VLine"></span>
                                    </div>
                                </Button>
                            </div>
                        </div>
                        }
                    <div className="cmpe-Els">
                        <div className="eL-inner">
                            <table>
                                <tbody>
                                    { inline ? 
                                    <tr className="tr-El">
                                        <td>
                                            <div className="reply-to">
                                                <div className="icon">
                                                    <img src={getIconUrl('reply')} />
                                                </div>
                                                <div className="recipient">
                                                    <div className="user-picture">
                                                                <div className="picture-holder">
                                                                    <div className="picture-content">
                                                                    {recipientFirstName[0]}{recipientLastName[0]}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    <span className="rpt-text">
                                                        {recipientFirstName} {recipientLastName}
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    :
                                    <React.Fragment>
                                    <tr className="tr-El to">
                                        <td>
                                            <span>To</span>
                                        </td>
                                        <td>
                                            <div className="sender-El">
                                                { recipients.map((user, i) => {
                                                    return <RecipientItem 
                                                    key={i} 
                                                    removeRecipient={removeRecipient}
                                                    user={user}/>
                                                }) }
                                                <textarea 
                                                ref={recipientField}
                                                onChange={onRecipient} 
                                                onFocus={onFocus}
                                                onBlur={onBlur} value={recipientsList}/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="tr-El">
                                        <td>
                                            <span>
                                                Subject
                                            </span>
                                        </td>
                                        <td>
                                            <div className="subj-El">
                                                <InputField
                                                    id='subj-field'
                                                    type='text'
                                                    value={subject}
                                                    onChange={onSubject}
                                                />
                                            </div>
                                         </td>
                                    </tr>
                                    </React.Fragment>
                                    }
                                </tbody>
                            </table>
                        <div className="el-tr body-El">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="msg-body">
                                                <div className="body-inner">

                                                <DefaultEditor 
                                                id={'editor'}
                                                value={html} 
                                                onChange={didType} />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="fotr-actions">
                            <div className="act-wrap">
                                <span>
                                    <Button onClick={sendMessage} className="send-btn">
                                        <span className="icon">
                                        <img src={getIconUrl('sent')} /></span>
                                        { label }
                                    </Button>
                                </span>
                                <span>
                                    <Button className="discard-btn" onClick={onClose}>
                                        <img src={getIconUrl('trash')} />
                                    </Button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)};

export { Compose };
/*
<Editor
    id={'editor'}
    initialValue="Type your message . . ."
    init={{
        selector: 'textarea#premiumskinsandicons-naked',
        skin: 'naked',
        icons: 'small',
        toolbar_location: 'bottom',
        plugins: 'lists code table codesample link',
        toolbar: 'formatselect | bold italic underline strikethrough bullist link',
        menubar: false,
        statusbar: false
    }}
    onChange={()=> {}}
/>

<div 
        className="body-text"
        aria-multiline="true"
        contentEditable="true"
        placeholder="Type your message here. . .">
    </div>

*/