import React from "react";
import Modal from "../../components/partials/Modal";
import PropType from "prop-types";
import { 
  Button,
  FormGroup,
  Authority,
  Section,
  SimpleSelect,
  ValidateField,
  ErrorFeedback,
  List,
  ListGroupItem,
  FormText,
  Input
} from "../../../components";
import { getIconUrl } from "../../../common/images-catalogue";
import { Loader } from "../../components/partials/LoadingIndicator";
import { ErrorMessage } from "../../components/partials/ErrorMessage";
import * as actionTypes from "../../../store/actionType";
import { getPromptMessageProps, PromptMessage } from "../../components/partials/PromptMessage";
import './Requisition.style.scss';
import { STATUS } from "../../../common/constant";

class RequisitionLinkModal extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      isFetchingRequisitions: false,
      isSavingRecord: false,
      formErrors: {},
      selectedRequisition: null,
      selectedReqItem: null,
    }

    this.submitData = this.submitData.bind(this);
    this.getPromptMessage = this.getPromptMessage.bind(this);
    this.didSelectRequisitionOption = this.didSelectRequisitionOption.bind(this);
    this.composeRequisitionOptions = this.composeRequisitionOptions.bind(this);
    this.composeRequisitionItemOptions = this.composeRequisitionItemOptions.bind(this);
    this.validateFields = this.validateFields.bind(this);
    this.composeReqItemPayload = this.composeReqItemPayload.bind(this);
    
    this.requisitionRef = React.createRef();
  }

  componentDidMount(){
    if(!this.props.apiRequestManager.inProgress(actionTypes.FETCH_CLIENT_REQUISITIONS) && this.props.clientId !== null){
      this.props.apiRequestManager.queueRequest(actionTypes.FETCH_CLIENT_REQUISITIONS, this.props.clientId);
      this.setState({
        isFetchingRequisitions: true,
      });
    }
  }

  componentDidUpdate(prevProps, prevState){
    const inProgress = this.props.apiRequestManager.inProgress(actionTypes.FETCH_CLIENT_REQUISITIONS);
    if(inProgress !== prevState.isFetchingRequisitions){
      this.setState({
        isFetchingRequisitions: inProgress,
      });
    }
  }

  didSelectRequisitionOption(selectedRequisition){
    this.setState((prevState) => {
      return {
        selectedRequisition,
        selectedReqItem: null,
        formErrors: {
          ...prevState.formErrors,
          requisitionMissing: false,
        }
      }
    })
  }

  didSelectRequisitionItem(selectedReqItem){
    this.setState((prevState) => {
      return {
        selectedReqItem,
        formErrors: {
          ...prevState.formErrors,
          reqItemMissing: false,
        }
      }
    })
  }

  composeRequisitionOptions(requisitions){
    if(Object.blank(requisitions) || !Array.isArray(requisitions)) return [];

    function buildOption(requisition){
      return {
        id: requisition.id,
        batchNumber: `${requisition.id}`,
        label: `Requisition #${requisition.id}`,
        value: requisition.id,
        ...requisition,
      }
    }

    return requisitions.map((requisition) => buildOption(requisition));
  }

  composeRequisitionItemOptions(selectedRequisition){
    if(Object.blank(selectedRequisition) || !Array.isArray(selectedRequisition.items)) return [];

    function buildOption(reqItem){
      return {
        id: reqItem.id,
        urgent: reqItem.urgent,
        cost: reqItem.cost,
        dateCreated: reqItem.dateCreated,
        ...reqItem,
      }
    }

    //filter items by the current form type of the record & select only those that aren't linked to a record already
    let items = selectedRequisition.items.filter(item => item.specimenRecordId === null && item.formType === this.props.formType);

    const specimenStatus = this.props.specimenSupervisor.getStatus();
    if(specimenStatus === STATUS.Submitted){
      //filter by urgency if specimen has been submitted
      items = items.filter((reqItem) => this.props.specimenRecord.urgent === reqItem.urgent);
    }

    return items.map(reqItem => buildOption(reqItem));
  }

  composeReqItemPayload(){
    return {
      ...this.state.selectedReqItem,
      specimenRecordRef: this.props.specimenRecord.identifier,
      specimenRecordId: this.props.specimenRecord.id,
    }
  }

  submitData(){
    const formErrors = this.validateFields();
    const onSubmit = this.props.onSubmit;

    if(Object.blank(formErrors)){
      const finalReqItem = this.composeReqItemPayload();
      
      if(typeof onSubmit === 'function'){
        onSubmit(finalReqItem);
      }
    }else{
      this.setState({
        formErrors,
      });
    }
  }

  validateFields(){
    const validationErrors = {};

    //check if requisition batch was selected
    if(Object.blank(this.state.selectedRequisition)){
      validationErrors.requisitionMissing = true;
    }

    //check if requisition item was selected.
    if(Object.blank(this.state.selectedReqItem)){
      validationErrors.reqItemMissing = true;
    }

    return validationErrors;
  }

  getPromptMessage() {
    const apiOutcome = this.props.apiRequestManager.getApiOutcome(actionTypes.SPECIMEN_SUBMIT_DATA, true) ?? {};
    return getPromptMessageProps('specimen', apiOutcome);
  }

  render() {
    const requisitionOptions = this.composeRequisitionOptions(this.props.clientRequisitions);
    const reqItemOptions = this.composeRequisitionItemOptions(this.state.selectedRequisition);

    const dialogProps = this.getPromptMessage();
    const hasSelectedItem = this.state.selectedReqItem;

    return (
      <Modal>
        { dialogProps.visible && 
          <PromptMessage {...dialogProps} />
        } 
        <Section className="head-section">
          <div className="title-box">
            <div className="title-area">
              <h1>Link Record To Requisition</h1>
            </div>
            <div className="slide-pane__close">
            <Button className="close-btn"  
            onClick={this.props.hideRequisitionLinkModal}>
              <img src={getIconUrl('close')} alt=""/>
            </Button>
            </div>
          </div>
        </Section>
        {this.state.isFetchingRequisitions ? 
        <div style={{height: '80%', display: "flex", alignItems: "center", justifyContent: "center"}}>
          <Loader visible={this.state.isFetchingRequisitions} loadingText="Fetching Requisitions..." />
        </div>
        : 
        <Section className="cabinet__body-section">
          {requisitionOptions.empty() ?
          <div className="req__link-empty-list req__link-no-reqs">
            No Available Requisitions To Choose From. <b>Create A New Requisition.</b>
          </div>
          :
          <div className="content-area">
              <FormGroup>
                <h6>Select a Requisition</h6>
                <ValidateField required>
                  <ErrorFeedback 
                    error
                    show={this.state.formErrors.requisitionMissing}
                    filter='valueMissing'
                    message={<ErrorMessage message ='Please select a Requisition' />} 
                  />
                  <SimpleSelect
                    id={'requisition'}
                    ref={this.requisitionRef}
                    className="ip-sml-select"
                    name="Requisition"
                    title="Choose a Requisition"
                    list={requisitionOptions}
                    onChange={this.didSelectRequisitionOption}
                    initialSelected={this.state.selectedRequisition}
                    searchable={["Type Batch Number", "No matching Requisition"]}
                    filterKeys={['label','batchNumber']}
                    disabled={false}
                  />
              </ValidateField>
            </FormGroup>

            <div className="req__link-items-container">
              <h6>Select an Item</h6>
              <ValidateField>
                <ErrorFeedback 
                  error
                  show={this.state.formErrors.reqItemMissing}
                  filter='valueMissing'
                  message={<ErrorMessage message ='Please select an item.' />} 
                />
                <div>
                  <div className="rqt-item lead head req__link-item req__link-head">
                    <div/>
                    <div>Id</div>
                    <div>Urgency</div>
                    <div>Cost</div>
                    <div>Date created</div>
                  </div>
                  {reqItemOptions.empty() ?  
                  <ListGroupItem className="req__link-empty-list">
                    Select a Requisition to see available items.
                  </ListGroupItem>
                  : <List className="req__link-item-list">
                    {
                      reqItemOptions.map((reqItemOption) => {
                        const urgentClass = reqItemOption.urgent ? 'urgent-badge' : 'urgent-badge not-urgent';
                        const isChecked = reqItemOption.id === this.state.selectedReqItem?.id;

                        return (
                          <li key={reqItemOption.id} className={`req__link-item ${isChecked ? '--selected': ''}`}
                          onClick={() => this.didSelectRequisitionItem(reqItemOption)}>

                            <label  className="ip-checkbox">
                              <Input checked={isChecked}
                              onChange={() => this.didSelectRequisitionItem(reqItemOption)}
                              type="checkbox" name={"check"}  />
                              <span className="checkmark round"></span>
                            </label>

                            <div><b>{reqItemOption.id}</b></div>

                            <span style={{position: 'relative', maxWidth: 'max-content'}} 
                            className={urgentClass}>
                              {reqItemOption.urgent ? 'Urgent' : 'Not Urgent'}
                            </span>
                            <div>${reqItemOption.cost}</div>
                            <div>{reqItemOption.dateCreated}</div>
                          </li>
                        )
                      })
                    }
                  </List>}
                </div>
              </ValidateField>
              { hasSelectedItem && 
                <FormText>Make sure you selected the correct item, after you linked an item it can't be change.</FormText>
              }
            </div>

            <Section className='cabinet-action-wrapper'>
              <Authority allowed='change' entity='record'>
                {!this.state.isSavingRecord && <Button onClick={this.submitData} disabled={!hasSelectedItem || this.state.isSavingRecord} className="save-btn">Link</Button>}
              </Authority>
              <Loader inline visible={this.state.isSavingRecord} loadingText={''} />
            </Section>
          </div>}
        </Section>
        }
        </Modal>
    )
  }
}

RequisitionLinkModal.propTypes = {
  hideRequisitionLinkModal: PropType.func.isRequired,
  onSubmit: PropType.func.isRequired,
  clientId: PropType.number.isRequired,
  formType: PropType.string.isRequired,
}

export default RequisitionLinkModal;