import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { Comparator, dateFilter } from 'react-bootstrap-table2-filter'
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import PropType  from 'prop-types';

import { InputField, List , NavLink} from '../index';
import {pictureInitials} from './../../utility/helpers';
import { PaginationPanel, PaginationTotal } from './';
import { PAGE_SIZE } from '../../config/pagination';
import { getIconUrl } from '../../common/images-catalogue';

const generateRow = (data) => ({
    id: data.id,
    bill: data,
    client: data,
    dueDate: data,
    details: data,
    status: data
});

const billInfo = (data) => {
   
    return (
        <ContextMenuTrigger id="bills_table_context_menu">
        <div className="bill-info">
            <div className="bill-info-holder">
                <div className="bill-info-content">
                    <h3>{data.paymentNo}</h3>
                </div>
            </div>
        </div>
        </ContextMenuTrigger>
    );
}

const clientsInfo = (data) => {
    const clientName = data.account.clientInfo.officeName ? data.account.clientInfo.officeName  : `${data.account.clientInfo.firstName} ${data.account.clientInfo.lastName}`;
    return (
        <ContextMenuTrigger id="bills_table_context_menu">
        <div className="client-info">
            <div className="client-info-holder">
                <div className="client-info-content">
                    <h3>{data.account.accountNo}</h3>
                    <p className="info">{clientName}</p>
                </div>
            </div>
        </div>
        </ContextMenuTrigger>
    );
}

const dueCell = (data) => {
    //const overdueClassname = data.billInsight.isPastDue && data.billInsight.dueDays < 0 ? '--overdue' : '';
    return (
        <ContextMenuTrigger id="bills_table_context_menu">
        <div className="due-info">
            <div className="due-info-holder">
                <div className="due-info-content">
                    <h3 className="">{data.date}</h3>
                </div>
            </div>
        </div>
        </ContextMenuTrigger>
    );
}

const billDetails = (data) => {
    //return function with cell content
    return (
        <div className="bill-details">
            <ContextMenuTrigger id="bills_table_context_menu">
            <div className="bill-details-holder">
                <div className="bill-details-content">
                    <p className="info">Amount: <span className="highlight">${data.amount.toCurrency()}</span></p>
                    <p className="info">Method:  <span className="">{data.type}</span></p>
                </div>
            </div>
            </ContextMenuTrigger>
        </div>
    );
}

const statusInfo = (actions) => {
    
    return (data) => {
        const statusClassname = data.state === 'Paid' ? 'sent' : data.state.toLowerCase();
        return (
        <ContextMenuTrigger id="bills_table_context_menu">
        <div className="client-info">
            <div className="toolbar">
                <List className="action-menu">
                    <li className="action-item">
                        <NavLink actiontag="view" onClick={() => actions.viewAction(data)}>
                            <span className="icon"><img className="icon" src={getIconUrl("view")} alt='' /></span>
                        </NavLink>
                    </li>
                    <li className="action-item">
                        <NavLink >
                            <span className="icon"><img className="icon" src={getIconUrl("option")} alt='' /></span>
                        </NavLink>
                    </li>
                </List>
            </div>
            <div className="status-info-holder">
                <div className="status-info-content">
                    <span className={`status --${statusClassname}`}>{data.state}</span>
                </div>
            </div>
        </div>
        </ContextMenuTrigger>
    )};
}

class PaymentTable extends React.Component { 
    static propsTypes = {
        tableData: PropType.array.require,
        actionResponsers: PropType.object,
    }

    static defaultProps = {
        actionResponsers: {
            editButton: null,
        }
    }

    constructor(props) {
        super(props);
        
        this.state = {
            selected: [],
            rowInFocus: null
        };
        this.table = React.createRef();
        this.didClickRow = this.didClickRow.bind(this);
        this.onTableChange = this.onTableChange.bind(this);
    }

    createColumnDefinitions(quickActions) { 
        return [
            {
                dataField: 'bill',
                text: "Payment #",
                headerAttrs: {
                    hidden: false,
                    className: 'th-patient-cell',
                },
                classes: 'id-patient-cell td-cell',
                formatter: billInfo
            },
            {
                dataField: 'client',
                text: "Client",
                headerAttrs: {
                    hidden: false,
                    className: 'th-client-cell',
                },
                classes: 'id-client-cell td-cell',
                formatter: clientsInfo
            },
            {
                dataField: 'dueDate',
                text: "Payment Date",
                headerAttrs: {
                    hidden: false,
                    className: 'th-workspace-cell',
                },
                classes: 'id-workspace-cell td-cell',
                formatter: dueCell
            },
            {
                dataField: 'details',
                text: "Details",
                headerAttrs: {
                    hidden: false,
                    className: 'th-details-cell',
                },
                classes: 'id-details-cell td-cell',
                formatter: billDetails
            },
            {
                dataField: 'status',
                text: "State",
                headerAttrs: {
                    hidden: false,
                    className: 'th-status-cell',
                },
                classes: 'id-status-cell td-cell',
                formatter: statusInfo(quickActions)
            },
        ];
    }

    handleSelect() { }

    handleSelectAll() { }
    handleOnChange() {}

    didClickRow(evt, row, index) {
        const rowId = evt.target.getAttribute('rowid');
        const actionTag = evt.target.getAttribute('actiontag');
        if (rowId && actionTag && actionTag === 'view') {
            this.props.quickActions.viewDetails(row.bill);
        }
    }  

    didContextClick (evt, row, rowIndex) {
        evt.preventDefault();
    }

    onTableChange(evt, opts) {
        this.props.onTableChange(opts);
    }

    render () {
        const columnDefs = this.createColumnDefinitions(this.props.quickActions);
        const paginationDef = paginationFactory({
            paginationSize: 5,
            sizePerPage: this.props.metaData?.pageSize || PAGE_SIZE,
            showTotal: true,
            totalSize: this.props.metaData?.totalItems || 0,
            page: this.props.metaData?.currentPage + 1 || 0,

            pageListRenderer: (props) => (
                <PaginationPanel { ...props } size="sm" className="ml-md-auto mt-2 mt-md-0" />
            ),
            paginationTotalRenderer: (from, to, size) => (
                <PaginationTotal { ...{ from, to, size } } />
            )
        
        });
        const selectRowConfig = {
            mode: 'checkbox',
            selected: this.state.selected,
            onSelect: this.handleSelect.bind(this),
            onSelectAll: this.handleSelectAll.bind(this),
            selectionRenderer: ({ mode, checked, disabled }) => (
                <InputField id=""  onChange={this.handleOnChange} type={ mode } checked={ checked } disabled={ disabled } />
            ),
            selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
                <InputField id=""  onChange={this.handleOnChange} type={ mode } checked={ checked } innerRef={el => el && (el.indeterminate = indeterminate)} />
            )
        };

        const rowEventsHandlers = {
            onClick: this.didClickRow
        };

        var options = {
            noDataMessage: () => 'No payments found.'
        };

        const paymentsData = this.props.tableData.map(bill => generateRow(bill));
        
        return (
            <ToolkitProvider
                keyField="id"
                data={ paymentsData }
                columns={columnDefs} >
                {
                    props => (
                        <React.Fragment>
                            
                            <BootstrapTable
                                remote={{ pagination: this.props.metaData?.remote }}
                                ref={this.table}
                                classes="table-responsive bills-table"
                                pagination={ paginationDef }
                                filter={ filterFactory() }
                                selectRow={ selectRowConfig }
                                bordered={ false }
                                responsive
                                { ...props.baseProps }
                                rowEvents={ rowEventsHandlers }
                                loading={ true } 
                                noDataIndication={options.noDataMessage}
                                onTableChange={this.onTableChange}
                            />
                        </React.Fragment>
                    )
                }
            </ToolkitProvider>
        );
    }
}

export { PaymentTable }