import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setupPage } from '../../../../components/Layout/setupPage';
import {
    List, 
    Input,
    FormGroup,
    Label,
    Section,
} from '../../../../components';


import  * as actions  from '../../../../store/actions';
import * as actionTypes from '../../../../store/actionType';
import { AddButton } from '../../../components/partials/Buttons';
import { ErrorMessage } from '../../../components/partials/ErrorMessage';
import text from '../../../../common/strings';
import { REQUESTS_TYPE, VALIDATOR_DELEGATE_KEY_GROUP, VALIDATOR_DELEGATE_TYPES } from '../../../../common/constant';
import { PromptMessage , getPromptMessageProps } from '../../../components/partials/PromptMessage';
import settingsValidatorDelegate from '../../../../forms/settingsValidator.delegate';
import { objListsAreEqual } from '../../../../utility/helpers';

import { getIconUrl } from '../../../../common/images-catalogue';

const mapStateToProps = state => ({
    ...state.settings.labcodeSetting
});

const mapDispatchToProps = dispatch => ({
    getLabCodes: () => dispatch(actions.getAllLabcodesAction()),
    setLabCodes: (type, data) => dispatch(actions.setSettingDataAction(type, data)),
    submitData: (payload) => dispatch(actions.submitLabCodeAction(payload))  
});

const LabCodeItem = (props) => {
    const {data, actions} = props;
  
    const fieldChange = (evt) => {
        const values = {
            index: data.index,
            ...data,
            [evt.target.name]: evt.target.name !== 'code' ? evt.target.value : evt.target.value.toUpperCase()
        };
        actions.addOrChange(evt, data.index, values);
    };
    
    const isNewClass = !data.id ? '--new' : '';
    const text = !data.id ? 'Remove' : 'Delete';
    
    return (
        <li className={`lab-code-item ${isNewClass}`}>
            <div className="lab-code" >
                <FormGroup>
                    <Label>Code</Label>
                    {(data.code ? '' : <ErrorMessage message='Enter a Code'/>)}
                    <Input onChange={fieldChange} type="text" name="code" placeholder="CODE" value={data.code} />
                </FormGroup>
                <FormGroup>
                    <Label>Region</Label>
                    
                    <Input onChange={fieldChange} type="text" name="region" placeholder="Kingstion" value={data.region}/>
                </FormGroup>
            </div>
            <div className="lab-code-ft">
                <Link to={'#'} onClick={(evt) => actions.remove(evt, data)} className="remove"><img className={'icon'} src={getIconUrl('trash')} alt='' /> {text}</Link>
            </div>
        </li>
    );
}

class LabCode extends React.Component {

    static pageOption = {
        pageTitle: 'Client',
        disableBodyScroll: true
    };

    constructor (props) {
        super(props);
        this.state = {};
        
        props.apiRequestManager.register(actionTypes.FETCH_LABCODES, props.getLabCodes);
        this.props.apiRequestManager.register(actionTypes.LABCODE_SUBMIT_DATA, props.submitData);

        this.composeLabCode = this.composeLabCode.bind(this);
        this.addOrReplaceLabCode = this.addOrReplaceLabCode.bind(this);
        this.removeLabCode = this.removeLabCode.bind(this);
        this.onSaveHanlder = this.onSaveHanlder.bind(this);
        this.getPromptMessage = this.getPromptMessage.bind(this);
        this.validation = this.validation.bind(this);

        props.innerRef.saveHanlder = this.onSaveHanlder;
        props.innerRef.confirmDeleteCallback = this.onConfirmDelete.bind(this);
    }
    
    componentDidMount() {
        this.props.validator.addDelegate(VALIDATOR_DELEGATE_TYPES.settings, settingsValidatorDelegate)
     }
    
    static getDerivedStateFromProps(props, state) {
    
        if ( !props.labCodesData && !props.apiRequestManager.inProgress(actionTypes.FETCH_LABCODES)) {
            props.apiRequestManager.queueRequest(actionTypes.FETCH_LABCODES);
        }
        return null;
    }

    shouldComponentUpdate(nextProps){
        /**
         * This runs the validation if something changed in labCodes list.
         */
        const prevList = this.props.labCodesData;
        const nextList = nextProps.labCodesData;

        if (Array.isArray(prevList) && Array.isArray(nextList) && !objListsAreEqual(prevList, nextList,'index')){
            const errors = this.validation(nextList);
            
            if(this.props.validator.hasActiveFormError(errors)){
                this.props.actions.enableSave(false);
            }else{
                this.props.actions.enableSave();
            }
        }

        return true;
    }

getPromptMessage(){
    const apiOutcome = this.props.apiRequestManager.getApiOutcome(actionTypes.LABCODE_SUBMIT_DATA, true) ?? {};
    return getPromptMessageProps('labCode', apiOutcome);
}

    removeLabCode(evt, labCode) {
        evt.preventDefault();
        if (labCode.id) {
            this.props.showDeleteDialog('labcode', labCode);
        }else {
            const labcodes = this.props.labCodesData.filter((item) => labCode.index !== item.index);
            this.props.setLabCodes(actionTypes.SET_LABCODE_DATA, labcodes);
        }
    }
    onConfirmDelete(deleteItem) {
        const payload = {
            requestType: 'delete',
            labCodeId: deleteItem.id
        };
        
        this.props.apiRequestManager.queueRequest(actionTypes.LABCODE_SUBMIT_DATA, payload);
    }
    addOrReplaceLabCode(evt, itemIndex, labCode) {
        var labCodes = [];

        if (itemIndex === undefined) {
            let labCodesWithNewEntry = [this.composeLabCode(), ...this.props.labCodesData];
            labCodesWithNewEntry.sortArrayObjectByKey('index')

            labCodes = !this.props.labCodesData.length? [this.composeLabCode()] : labCodesWithNewEntry;
            this.props.setLabCodes(actionTypes.SET_LABCODE_DATA, labCodes);
        }else {
            labCodes = this.props.labCodesData.map((item) => {
                if (item.index === itemIndex) {
                    item = {
                        ...labCode
                    }
                }
                return item;
            });
            this.props.setLabCodes(actionTypes.SET_LABCODE_DATA, labCodes);
        }
    }

    validation(labCodes){
        return {
            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.settings, VALIDATOR_DELEGATE_KEY_GROUP.settings.labCodes, labCodes)
        }
    }

    onSaveHanlder() {
        const payload = {
            requestType: 'submit',
            data: this.props.labCodesData,
        }
        this.props.apiRequestManager.queueRequest(actionTypes.LABCODE_SUBMIT_DATA, payload);
    }
    
    composeLabCode() {
        const labcode = {
            index: 0,
            code: '',
            region: ''
        }
        return labcode;
    }

    render () {
        
        const labcodes = Array.isArray(this.props.labCodesData) ? this.props.labCodesData :  [];

        const actions = {
            remove: this.removeLabCode,
            addOrChange: this.addOrReplaceLabCode
        }

        const dialogProps = this.getPromptMessage();
        
        return (
            <React.Fragment>
                { dialogProps.visible && 
                        <PromptMessage {...dialogProps} />
                } 
                <div className='pane-layout-wrap pane lab-code-pane'>
                    <div className="pane-cont">
                        <div className="setting-info">
                            <p>Lab Codes created in this section are used when creating or modifying a <b>Client</b>.</p>
                        </div>
                        <div className="actions-btn">
                            <AddButton actionHandler={this.addOrReplaceLabCode} label={'Add Lab Code'} />
                        </div>
                        <Section className="lab-code-list">
                            <List>
                                { labcodes.map((item, i) => {
                                    return <LabCodeItem 
                                    key={i} 
                                    data={item}
                                    actions={actions} />
                                }) }
                            </List>
                        </Section>
                    </div>
                </div>
                
            </React.Fragment>
        );
    }
}

const LabCodeView = (props) => {
    return (
        <LabCode {...props } />
    );
};

let ConfigLabCodeView = setupPage(LabCode.pageOption)(LabCodeView);
ConfigLabCodeView =  connect(mapStateToProps, mapDispatchToProps)(ConfigLabCodeView);

export { ConfigLabCodeView };