import React from 'react';
import './Profile.style.scss';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { setupPage } from '../../components/Layout';
import { Nav, NavItem, NavLink } from '../../components';
import { PROFILE_PANES, REQUESTS_TYPE, VALIDATOR_DELEGATE_TYPES, VALIDATOR_DELEGATE_KEY_GROUP } from '../../common/constant';
import PersonalInfoPane from './Panes/PersonalInfoPane';
import classnames from 'classnames';
import { FormValidator } from '../../utility/FormValidator';
import composeDetailLine from './paneStructure';
import * as helpers from '../../utility/helpers';
import SecurityPane from './Panes/SecurityPane';
import Base64 from '../../utility/Base64Encoder';
import FileUploader from '../../utility/FileUploader';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
import { PermissionEnforcer } from '../../utility/PermissionEnforcer';
import * as actionTypes from '../../store/actionType';
import ActionBar from '../components/partials/ActionBar';
import * as urls from '../../config/urls';
import userValidatorDelegate from '../../forms/userValidator.delegate';

import {
  PromptMessage,
  getPromptMessageProps,
} from '../components/partials/PromptMessage';

function mapStateToProps(state) {
  return {
    validator: FormValidator.getInstance(FormValidator),
    apiRequestManager: ApiRequestManager.getInstance(ApiRequestManager),
    permissionEnforcer: PermissionEnforcer.getInstance(PermissionEnforcer),
    changePasswordSlice: state.profile.changePasswordSlice,
    currentUser: state.global.authorizeUser,
    ...state.profile.profileSlice
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setActiveProfilePane: (activePane) => dispatch(actions.setProfilePaneAction(activePane)),
    fieldDidChange: (fieldValue) => dispatch(actions.profileFieldChangeAction(fieldValue)),
    resetProfileForm: (user) => dispatch(actions.resetProfileFormAction(user)),
    initEditField: (fieldValue) => dispatch(actions.profileFieldOnClickEditAction(fieldValue)),
    submitData: (payload) => dispatch(actions.submitProfileDataAction(payload)),
    submitChangePassword: (payload) => dispatch(actions.submitChangePasswordAction(payload))
  };
}

class Profile extends React.Component {
  static pageOption = {
    pageTitle: 'Profile',
  };

  static sideNavOptions = [
    { to: PROFILE_PANES.personalInfo, label: 'Personal Info' },
    { to: PROFILE_PANES.security, label: 'Security & Signin' },
  ];

  PANE_MAP = {
    [PROFILE_PANES.personalInfo] : PersonalInfoPane,
    [PROFILE_PANES.security]: SecurityPane
  }

  //must be the same name as the fields in the profile reducer's form state
  static lineNames = {
    profileImage: 'profileImage',
    userType: 'userType',
    username: 'username',
    email: 'email',
    active: 'active',
    blocked: 'blocked',
    secondary: 'secondary',
    firstName: 'firstName',
    lastName: 'lastName',
    phoneNumber: 'phoneNumber',
    street: 'street',
    streetTwo: 'streetTwo',
    city: 'city',
    state: 'state',
    twoFactor: 'twoFactor',
    currentPassword: 'currentPassword',
    password: 'password',
    passwordTwo: 'passwordTwo',

    profession: 'profession',
    digitalSignature: 'digitalSignature',
  };

  static initialErrorState = {
    usernameMissing: '',
    emailMissing: '',
    emailInvalid: '',
    firstNameMissing: '',
    firstNameInvalid: '',
    lastNameMissing: '',
    lastNameInvalid: '',
    phoneNumberMissing: '',
    phoneNumberInvalid: '',
    currentPasswordMissing: '',
    passwordMissing: '',
    passwordMismatch: '',
  };

  constructor(props) {
    super(props);
    // If the user is not present redirect to users
    !props.permissionEnforcer.user && props.history.push(urls.usersUri);

    this.state = {
      //The name of the detail line that is currently being edited
      currentlyEditing: null,

      //used to show error message if field isn't an empty string
      validationErrors: {
        ...Profile.initialErrorState,
      },
      //use to show the action bar buttons
      contextMode: ''
    };

    this.profileImageRef = React.createRef();

    this.composeDetailSection = this.composeDetailSection.bind(this);
    this.changePaneView = this.changePaneView.bind(this);
    this.shouldDisableEdit = this.shouldDisableEdit.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.onCancelEdit = this.onCancelEdit.bind(this);
    this.onDisableSaveAction = this.onDisableSaveAction.bind(this);
    this.validateField = this.validateField.bind(this);
    this.hasValidationError = this.hasValidationError.bind(this);
    this.clearFormState = this.clearFormState.bind(this);
    this.submitFormData = this.submitFormData.bind(this);
    this.submitPasswordChange = this.submitPasswordChange.bind(this);
    this.previewImage = this.previewImage.bind(this);
    this.handlerBack = this.handlerBack.bind(this);
    this.getPromptMessage = this.getPromptMessage.bind(this);
    this.hasPasswordValidationError = this.hasPasswordValidationError.bind(this);
    
    props.apiRequestManager.register(
      actionTypes.PROFILE_SUBMIT_DATA,
      props.submitData
    );

    props.apiRequestManager.register(
      actionTypes.PASSWORD_CHANGE_SUBMIT_DATA,
      props.submitChangePassword
    );

    this.props.validator.addDelegate(VALIDATOR_DELEGATE_TYPES.user, userValidatorDelegate);
  }

  componentDidMount() {
    this.setFormDefaults();
    return null;
  }

  setFormDefaults(currentUser = null) {
    const user = currentUser ?? this.props.currentUser;

    const fieldset = {
      userType: user.type,
      role: user.role.id,
      workspace: user.workspaceBounds,
      username: user.username,
      firstName: user.firstName,
      lastName: user.lastName,
      profileImage: user.picture?.fileDownloadUri ?? null,
      gender: user.gender || null,
      email: user.email,

      authorizerId: user?.authorizerId,
      profession: user?.profession,
      digitalSignature: user?.digitalSignature,
    }
    this.props.fieldDidChange(fieldset);
  }

  composeDetailSection(sectionLabel, detailLines = []) {
    return {
      sectionLabel,
      detailLines,
    };
  }

  changePaneView(event) {
    event.preventDefault();

    const selectedPane = event.target.getAttribute('to');

    if (this.props.activePane !== selectedPane) {
      this.props.setActiveProfilePane(selectedPane);
      this.clearFormState();
      this.setState({ contextMode: '' });
    }
  }

  //allows detail lines(fields) to know if they should disable editing
  shouldDisableEdit(sectionId) {
    const currentlyEditing = this.state.currentlyEditing;
    return currentlyEditing && currentlyEditing !== sectionId;
  }

  clearFormState() {
    this.props.resetProfileForm(this.props.currentUser);
    
    this.setState({
      currentlyEditing: null,
      validationErrors: { ...Profile.initialErrorState },
    });
  }

  onClickEdit(sectionId, storedValue, changeMode = true)  {
    this.setState({ currentlyEditing: sectionId, contextMode: changeMode ? 'edit' : '' });
    //avoid calling init if there's no value
    if (!storedValue) return;
    this.props.initEditField({ [sectionId]: storedValue });
  }

  onDisableSaveAction() {
    this.setState({
      disableSave: true
    })
  }

  onCancelEdit(event) {
    event.preventDefault();

    this.clearFormState();
    this.setState({ contextMode: '' , disableSave: false});
  }

  onFieldChange(lineName, event) {
    let fieldValue = event.target.value;

    //Format phone number
    if (lineName === Profile.lineNames.phoneNumber) {
      fieldValue = helpers.formatPhoneNumber(fieldValue);
    }

    //Encode password values
    if (
      lineName === Profile.lineNames.currentPassword ||
      lineName === Profile.lineNames.password ||
      lineName === Profile.lineNames.passwordTwo
    ) {
      fieldValue = Base64.encode(fieldValue);
    }

    const validationErrors = this.validateField(fieldValue, lineName);

    this.setState({ validationErrors });

    this.props.fieldDidChange({
      [lineName]: fieldValue,
    });
  }

  //validate the field being edited
  validateField(fieldValue, lineName) {
    const lineNames = Profile.lineNames;
    const validator = this.props.validator;
    const requiredFieldMsg = 'This field is required';

    let validationErrors = {
      ...Profile.initialErrorState,
    };

    //password validation
    if (lineName === lineNames.currentPassword) {
      if (validator.isValueMissing(fieldValue)) {
        validationErrors.currentPasswordMissing = requiredFieldMsg;
      }
    }

    if (lineName === lineNames.password) {
      if (validator.isValueMissing(fieldValue)) {
        validationErrors.passwordMissing = requiredFieldMsg;
      }
      if (!validator.isEqual(fieldValue, this.props.profileForm.passwordTwo)) {
        validationErrors.passwordMismatch = "Passwords don't match";
      }
    }

    if (lineName === lineNames.passwordTwo) {
      if (!validator.isEqual(fieldValue, this.props.profileForm.password)) {
        validationErrors.passwordMismatch = "Passwords don't match";
      }
    }

    //username validation
    if (lineName === lineNames.username) {
      if (validator.isValueMissing(fieldValue)) {
        validationErrors.usernameMissing = requiredFieldMsg;
      }
    }

    //email validation
    if (lineName === lineNames.email) {
      if (validator.isValueMissing(fieldValue)) {
        validationErrors.emailMissing = requiredFieldMsg;
      }
      if (!validator.isEmailValue(fieldValue)) {
        validationErrors.emailInvalid = 'Invalid Email';
      }
    }

    //firstname validation
    if (lineName === lineNames.firstName) {
      if (validator.isValueMissing(fieldValue)) {
        validationErrors.firstNameMissing = requiredFieldMsg;
      }
      if (!validator.isTextValue(fieldValue)) {
        validationErrors.firstNameInvalid = 'Invalid Name';
      }
    }

    //lastname validation
    if (lineName === lineNames.lastName) {
      if (validator.isValueMissing(fieldValue)) {
        validationErrors.lastNameMissing = requiredFieldMsg;
      }
      if (!validator.isTextValue(fieldValue)) {
        validationErrors.lastNameInvalid = 'Invalid Name';
      }
    }

    //phone number validation
    if (lineName === lineNames.phoneNumber) {
      if (validator.isValueMissing(fieldValue)) {
        validationErrors.phoneNumberMissing = requiredFieldMsg;
      }
      if (!validator.isPhonenumber(fieldValue)) {
        validationErrors.phoneNumberInvalid = 'Invalid Phone Number';
      }
    }

    return validationErrors;
  }

  previewImage(event, imageRef) {
    var reader = new FileReader();
    let image = imageRef ? imageRef : this.profileImageRef;

    reader.onload = function () {
      var output = image.current; //document.getElementById('imagePreview');
      output.src = reader.result;
    };

    reader.readAsDataURL(event.target.files[0]);
    var file = event.target.files[0];
    const fieldSet = { [Profile.lineNames.profileImage]: file };
    this.props.fieldDidChange(fieldSet);
  }

  //TODO: complete functionality when api is ready
  submitFormData() {
    const uploader = FileUploader.getInstance(FileUploader);

    let { profileImage, blank, ...otherFields } = this.props.profileForm;
    let formData = new FormData();

    //only include the password fields if they are currently being edited.
    if (this.state.currentlyEditing !== Profile.lineNames.password) {
      delete otherFields.currentPassword;
      delete otherFields.password;
      delete otherFields.passwordTwo
    }

    const formKeys = Object.keys(otherFields);
    for (let i = 0; i < formKeys.length; i++) {
      formData.append(formKeys[i], this.props.profileForm[formKeys[i]]);
    }

    if (profileImage && profileImage instanceof File) {
      const fileName = uploader.generateFileName(profileImage);
      formData.append('profileImage', profileImage, fileName);
    }

    const profileId = otherFields?.id
      ? otherFields.id
      : this.props.currentUser.id;

    //if currently editing a field and there are no validation errors in state; allow submit
    if (this.state.currentlyEditing && !this.hasValidationError()) {
      this.props.apiRequestManager.queueRequest(
        actionTypes.PROFILE_SUBMIT_DATA,
        {
          requestType: REQUESTS_TYPE.UPDATE,
          form: formData,
          id: profileId,
        }
      );
    }
  }

  submitPasswordChange() {
    let { currentPassword, password, passwordTwo } = this.props.profileForm;
    
    const payload = {
      requestType: REQUESTS_TYPE.UPDATE,
      form: {
        currentPassword,
        password,
        passwordTwo,
      }
    };

    const formErrors = this.hasPasswordValidationError(payload.form);

    if (this.state.currentlyEditing && !this.props.validator.hasActiveFormError(formErrors)) {
      this.props.apiRequestManager.queueRequest(
        actionTypes.PASSWORD_CHANGE_SUBMIT_DATA,
        payload
      );
    } else {
        this.setState({
          validationErrors: {
            passwordMissing: formErrors.passwordMissing? 'Field is required': '',
            passwordMismatch : formErrors.passwordsDifferent ? "Passwords don't match" : '',
            currentPasswordMissing: formErrors.currentPasswordMissing ? 'Field is required': '',
          }
        });
    }
  }

  hasPasswordValidationError(fieldSet) {
  
    const formFields = VALIDATOR_DELEGATE_KEY_GROUP.formFields;

    let passwordErrors = {
      ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.user,formFields.currentPassword, fieldSet[formFields.currentPassword]),
      ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.user,formFields.password, fieldSet[formFields.password], fieldSet[formFields.passwordTwo])
    };

    let {passwordsDifferent,  ...otherErrors} = passwordErrors;

    otherErrors = {
      ...otherErrors,
      passwordMismatch : passwordsDifferent
    };

    return otherErrors;
  }

  //check if there are any active error messages in state
  hasValidationError() {
    const values = Object.values(this.state.validationErrors);

    for (let index = 0; index < values.length; index++) {
      const value = values[index];
      if (value.trim() !== '') {
        return true;
      }
    }

    return false;
  }

  handlerBack(evt) {
    this.props.history.push(urls.usersUri);
  }

  getPromptMessage() {
    const apiOutcome =
      this.props.apiRequestManager.getApiOutcome(
        actionTypes.PROFILE_SUBMIT_DATA,
        true
      ) ?? {};

    return getPromptMessageProps('profile', apiOutcome);
  }

  renderPane(activePane, props) {
    const DisplayPane = this.PANE_MAP[activePane]
    return <DisplayPane {...props}/>
  }

  render() {
    const apiOutcome = this.props.apiRequestManager.getApiOutcome(actionTypes.PROFILE_SUBMIT_DATA) ?? {};
    const activePane = this.props.activePane;

    const actionBarCallback = {
      save: this.submitFormData,
      back: this.handlerBack,
    };

    //to prevent users from saving if the data isn't correct, or not editing anything.For now don't enable save for twoFactor editing
    const disableSave =
      !this.state.currentlyEditing ||
      this.hasValidationError() ||
      this.state.disableSave ||
      this.state.currentlyEditing === Profile.lineNames.twoFactor;

    const paneProps = {
      apiOutcome: apiOutcome,
      currentlyEditing: this.state.currentlyEditing,
      validationErrors: this.state.validationErrors,
      lineNames: Profile.lineNames,
      profileImageRef: this.profileImageRef,
      composeDetailSection: this.composeDetailSection,
      composeDetailLine: composeDetailLine,
      shouldDisableEdit: this.shouldDisableEdit,
      onClickEdit: this.onClickEdit,
      onCancelEdit: this.onCancelEdit,
      onChangePassword: this.submitPasswordChange,
      onFieldChange: this.onFieldChange,
      previewImage: this.previewImage,
      onDisableSave: this.onDisableSaveAction,
      ...this.props,
    };

    const dialogProps = this.getPromptMessage();
    const actionBarMode = this.state.contextMode;

    return (
      <React.Fragment>
        <ActionBar
          title={'Profile'}
          saveTextSufix=''
          entityContext={'user'}
          actionResponsers={actionBarCallback}
          permissionContext={actionBarMode}
          deleteButton={false}
          disableSave={disableSave}
        />

        <div className='content-layout-wrap profile-page'>
          {dialogProps.visible && <PromptMessage {...dialogProps} />}

          {/* SideBar */}
          <div className='sidebar'>
            <div className='nav-container'>
              <nav className='sidebar-nav'>
                <Nav>
                  {Profile.sideNavOptions.map((option) => (
                    <NavItem key={option.to}>
                      <NavLink
                        to={option.to}
                        onClick={this.changePaneView}
                        className={classnames({
                          active: activePane === option.to,
                        })}
                      >
                        {option.label}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              </nav>
            </div>
          </div>

          {/* Panes */}
          <div className='view-layout-content container'>
            <div className='pymt-Ly-ct'>
              {/* Render the active pane */}
              { this.renderPane(activePane, paneProps) }
  
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const ProfileView = (props) => {
  return <Profile {...props} />;
};

let ConfigProfileView = setupPage(Profile.pageOption)(ProfileView);
ConfigProfileView = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfigProfileView);

export { ConfigProfileView };
