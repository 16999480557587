import * as actionTypes from '../actionType';
import { isError } from '../../service/agent';
import { ApiRequestManager } from '../../utility/ApiRequestManager';

const initialState = {
    viewInit: false,
    billsData: null,
    paidBillsData: null,
    unpaidBillsData: null,

    accounts: null,
    services: null,
    billableRecords: [],
    billableRecordsList: [],
    billPayments: [],
    serviceTaxes: [],

    billForm: {
        referenceNo: null,
        billDate: '',
        dueDate: '',
        carriedBalance: 0.0,
        client: null,
        service: null,
        expressCost: 0.0,
        message: '',
        tax: {
            taxCode: '',
            percent: 0.0
        },
        items: [

        ]
    },

    billSummary: {
        netTotal: 0.0,
        prevBalance: 0.0,
        tax: 0.0,
        paymentDue: 0.0
    },

    billsInsight: {
        collectedBills: 0,
        quarter: 0,
        draftedBills: 0,
        outStandingBills: 0,
        totalBillsCount: 0,
        totalUncollectedBillsCount: 0,
        totalOverdueCount: 0,
        totalPaidBills: 0,
        totalPaidBillsCount: 0,
        totalRevenue: 0,
        totalDraftedBillsCount: 0,
        uncollectedBills: 0,
    }
 };

var error = null;
let empty = {}
let emptyArray = [];
let requestManager = null;
let apiOutcome = null;

const billingReducer = (state = initialState, action ) => { 

    switch(action.type) {
        case actionTypes.BILLS_VIEW_LOADED :
            return {
                ...initialState,
                viewInit: true
            }
        case actionTypes.BILL_CREATE_VIEW_LOADED :
            return {
                ...initialState,
                viewInit: true
            }
        case actionTypes.BILLS_VIEW_UNLOAD:
        case actionTypes.BILL_CREATE_VIEW_UNLOAD :
            return {
                ...initialState,
                viewInit: false,
                bill: state.bill ?  state.bill : null,
            }
        case actionTypes.BILL_FIELD_CHANGED :
            return {
                ...state,
                billForm: {
                    ...state.billForm,
                    ...action.payload
            }
        }
        case actionTypes.FETCHED_BILLS_RESULTS:
            error = isError(action.payload);
            return {
                ...state,
                billsData: error ? emptyArray : action.payload,
            }
        case actionTypes.FETCHED_BILL_RESULT:
            error = isError(action.payload);
            return {
                ...state,
                bill: error ? null : action.payload,
            }
        case actionTypes.FETCHED_CLIENTS_SERVICES_RESULTS:
            error = isError(action.payload.accounts);
            const accounts = error? emptyArray : action.payload.accounts;
            error = isError(action.payload.services);
            const services = error? emptyArray : action.payload.services;

            return {
                ...state,
                accounts: accounts,
                services: services,
            }
        case actionTypes.FETCHED_CLIENT_SPECIMEN_RECORDS_RESULTS:
            error = isError(action.payload);
            return {
                ...state,
                billableRecords: error ? emptyArray : action.payload,
                billableRecordsList: error ? emptyArray : action.payload,
            }

        case actionTypes.FETCHED_TAXES_BY_TYPE_RESULT:
            error = isError(action.payload);
            const defaultPercent = !error && action.payload.find(tax => tax.isDefault);
            return {
                ...state,
                serviceTaxes: error ? emptyArray : action.payload,
                billForm: {
                    ...state.billForm,
                    tax: {
                        ...state.billForm.tax,
                        percent: defaultPercent ? defaultPercent.percent : 0.0
                    }
                }
            }

        case actionTypes.SET_BILL_SUMMARY:
            return {
                ...state,
                billSummary: {
                    ...state.billSummary,
                    ...action.payload
                }
            }
        case actionTypes.FETCHED_BILLS_SUMMARY_RESULTS:
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.FETCH_BILLS_SUMMARY) ?? {};
            return {
                ...state,
                billsInsight: apiOutcome?.success  ? action.payload : {...state.billsInsight},
            }
        case actionTypes.BILL_SUBMITED_RESULTS:
            error = isError(action.payload);
            return {
                ...state,
                bill: error ? empty : action.payload
            }
        case actionTypes.BILL_EDIT_SETDATA:
            const { bill, ...otherData } = action.payload
            const data  = {
                ...state,
                billForm: Object.blank(otherData) ? initialState.billForm : otherData.formData,
                bill: bill
            }
            return data;
        case actionTypes.TALBE_ROW_HOVER_EVENT:
            return {
            ...state,
            rowBill: action.payload.bill
        }
        case actionTypes.CONTEXT_MENU_EVENT:
            return {
            ...state,
            rowBillContext: action.payload,
        }
        case actionTypes.SET_BILLABLE_RECORDS:
            return {
                ...state,
                billableRecords: action.payload
            }
        case actionTypes.FETCHED_PAID_BILLS_RESULTS:
            error = isError(action.payload);
            return {
                ...state,
                paidBillsData: error ? emptyArray : action.payload,
            }
        case actionTypes.FETCHED_UNPAID_BILLS_RESULTS:
            error = isError(action.payload);
            return {
                ...state,
                unpaidBillsData: error ? emptyArray : action.payload,
            }

        case actionTypes.FETCHED_BILL_PAYMENTS_RESULTS:
            error = isError(action.payload);
            return{
                ...state,
                billPayments: error ? emptyArray : action.payload
            }
        default:
            return state;
    }
};

export default billingReducer;