import React from 'react';
import { connect } from 'react-redux';
import { setupPage } from '../../components/Layout/setupPage';
import { 
    Container,
    Row,
    Col,
    Button,
    List,
    Section,
    ClientTable,
} from '../../components';

import * as actionTypes from '../../store/actionType';
import  * as actions  from '../../store/actions';
import { Authority } from '../../components';
import * as urls from './../../config/urls';
import {CLIENT_TYPE} from './../../common/constant';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
import UsersSummary from './../components/partials/UsersSummary';
import TopClient from './../components/partials/TopClient';
import { SEARCH_SETTINGS } from '../../config/searchable';
import './Client.style.scss';
import { getIconUrl } from '../../common/images-catalogue'; 

const mapStateToProps = state => ({
    apiRequestManager: ApiRequestManager.getInstance(ApiRequestManager),
    useClientLayout: state.global.useClientDashboard,
    authorizeUser: state.global.authorizeUser,
    mode:  state.global.actionBarMode,
    ...state.client
});

const mapDispatchToProps = dispatch => ({
    onLoad: (clentType) => dispatch(actions.clientsViewLoadedAction(clentType)),
    unLoad: () => dispatch(actions.clientsViewUnloadAction()),
    getClients: (type) => dispatch(actions.getAllClientsAction(type)),
    setClient: (client) => dispatch(actions.setClientAction(client))
});

class Client extends React.Component {

    static pageOption = { 
        pageTitle: 'Client',
        disableBodyScroll: true,
        searchContext: SEARCH_SETTINGS.entities.client.name
    };

    constructor(props) {
        super(props);
        this.state = {
            clientType: this.getClientType(),
         };

        props.apiRequestManager.register(actionTypes.FETCH_CLIENTS, props.getClients);

        this.viewClientDetails = this.viewClientDetails.bind(this);
        this.onAddClient = this.onAddClient.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.state.clientType);
        this.unlistenAbort = this.props.history.listen(() => { 
            this.props.apiRequestManager.abortRequest(actionTypes.FETCH_CLIENTS);
        });
    }

    componentWillUnmount() {
        this.unlistenAbort();
        this.props.unLoad();
    }

    static getDerivedStateFromProps(props, state) {
        // Return null to indicate no change to state.
        if ( !props.clientsData || props.clientView !== state.clientType ) {
            props.apiRequestManager.queueRequest(actionTypes.FETCH_CLIENTS, state.clientType);
        }
        
        return null;
    }

    getClientType() {
        const path = this.props.location.pathname;
        switch(path) {
            case urls.labsUri:
                return CLIENT_TYPE.Laboratory;
            default:
                return CLIENT_TYPE.Doctor;
        }
    }
    willHandleTableRowMouseOver(rowData) { }

    viewClientDetails(clientData) {
        if (!clientData.empty()) {
            this.props.setClient(clientData);
            this.props.history.push(urls.clientOverviewUri);
        }
    }
    onAddClient(evt) {
        this.props.history.push(urls.clientCreateUri);
    }

    render () {
        const quickActionCallback = {
            viewDetails: this.viewClientDetails
        };

        const tableData = !Object.blank(this.props.clientsData) && Array.isArray(this.props.clientsData.clients) ? this.props.clientsData.clients : [];
        const topClients = !Object.blank(this.props.clientsData) && Array.isArray(this.props.clientsData.topClients) ? this.props.clientsData.topClients : [];
        const clientView = this.props.clientView === CLIENT_TYPE.Laboratory ? 'Lab' : this.props.clientView;
        
        return (
            <React.Fragment>
            <div className='content-layout-wrap client-list'>
                <div className="view-layout-content">
                   <div className="default-Ly-ct">
                       <Container>
                           <Row>
                               <Col sm={12} lg={3}>
                                    <div className="widget-section">
                                        <div className="button-wrap">
                                        <Authority allowed="create" entity="client" >
                                            <Button className="default-btn add-btn" onClick={this.onAddClient}>
                                                <span className="icon"><img src={getIconUrl("addUser")} alt=''/></span>
                                                Add {clientView}
                                            </Button>
                                            </Authority>
                                        </div>
                                    </div>
                                    <div className="users-widgets-list">
                                        <List>
                                            <UsersSummary 
                                                label={clientView} 
                                                count={tableData.length}/>
                                        </List>
                                    </div>
                               </Col>
                               <Col sm={12} lg={9}>
                                   <Section className="top-client-section">
                                   <div className="title-d">
                                       <h3 className="title">Top {clientView}s</h3>
                                   </div>
                                    <div className="top-clients-wrap">
                                        <div className="client-list-cont">
                                            <List className="client-list">
                                                { topClients.map((client) => {
                                                    return (
                                                        <li key={client.id} className="client-item">
                                                            <TopClient 
                                                                data={client}
                                                                clickHandler={this.viewClientDetails}
                                                            />
                                                        </li>
                                                    );
                                                })}
                                            </List>
                                        </div>
                                    </div>
                                   </Section>
                                   <Section className="table-section">
                                   <div className="table-container"> 
                                    <ClientTable id="mainTable" 
                                    hoverOverRowAction={this.willHandleTableRowMouseOver}
                                    quickActions={quickActionCallback} 
                                    tableData={tableData}/>
                                    </div>
                                    </Section>
                                </Col>
                            </Row>
                        </Container>
                   </div>
                </div>
            </div>
            </React.Fragment>
        );
    }
}

const ClientView = (props) => {
    return (
        <Client {...props } />
    );
};

let ConfigClientView = setupPage(Client.pageOption)(ClientView);
ConfigClientView =  connect(mapStateToProps, mapDispatchToProps)(ConfigClientView);

export { ConfigClientView };