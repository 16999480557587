import Immutable from 'immutable';
import { combineReducers } from 'redux'
import * as actionTypes from '../actionType';
// import { API_OUTCOME_SUFFIX } from '../../common/constant';
import { ApiRequestManager } from '../../utility/ApiRequestManager';

const testState = Immutable.Record({

})

const initialState = {
    viewInit: false,
    username: '',
    password: '',
    rememberMe: false,
    savedUsersList: {}
};

const initialFetchUserState = {
    user: null
};

var error = {
    error: null,
    success: false
};

const fetchUserReducer = (state = initialFetchUserState, action) => {
    switch (action.type) {
      case actionTypes.FETCHED_AUTH_USER_RESULT:
        const requestManager = ApiRequestManager.getInstance(ApiRequestManager);
        let apiOutcome = requestManager.getApiOutcome(actionTypes.FETCH_AUTH_USER);
      
        return {
            ...state,
            ...apiOutcome,
            user: apiOutcome?.success ? action.payload :  null
        }

        case actionTypes.SET_AUTH_USER:
            return { ...state, user: action.payload };

        case actionTypes.SET_LOGIN_VIEW:
            return {
                ...state,
                ...error
            }
        default:
            return state;
    }
}

const authReducer = (state = initialState, action ) => { 
    switch(action.type) {
        case actionTypes.LOGIN_VIEW_LOADED:
           return {
               ...state,
               viewInit: true
           };

        case actionTypes.SET_LOGIN_VIEW:
            return {
                ...state,
                ...error
            }

        case actionTypes.AUTH_RESULTS: 
            const requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            let apiOutcome = requestManager.getApiOutcome(actionTypes.LOGIN);
            return {
                ...state,
                ...apiOutcome,
            };

        case actionTypes.LOGIN_VIEW_UNLOAD:
           return initialState;

        case actionTypes.AUTH_FIELD_CHANGED:
            return {
                ...state,
                [action.payload.field]: action.payload.value,
                error: null
            }
        case actionTypes.SET_SAVED_USERS:
            return { ...state, savedUsersList: action.payload, password: '' };

        default:
            return state;
    }
};

export default combineReducers({
    loginState: authReducer,
    fetchUserState: fetchUserReducer
})