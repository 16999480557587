import React from 'react';
import _ from 'lodash';

import './Dashboard.style.scss';

import { 
    FloatGrid as Grid,
    Card,
    CardHeader,
    CardBody,
    List,
    Authority
} from '../../components';

import { applyColumn } from './../../components/FloatGrid';
import { getIconUrl } from '../../common/images-catalogue';
import { abbrNumber } from '../../utility/helpers';

const LAYOUT = {
    'count-specimens': { h: 7, md: 4 },
    'count-doctors': { h: 7, md: 4},
    'count-labs': { h: 7, md: 4 },
    'count-patients': { h: 7, md: 4 },
    
    'metric-v-target-users': { h: 7, md: 4 },
    'metric-v-target-sessions': { h: 7, md: 4 },
    'metric-v-target-pageviews': { h: 7, md: 4 },
}


class ClientLayout extends React.Component {

    static pageOption = {
        pageTitle: 'Role | Permission',
        grayBackground: true
    };
    enforcer;

    state = {
        layouts: _.clone(LAYOUT)
    }

    resetLayout = () => {
        this.setState({
            layouts: _.clone(LAYOUT)
        })
    }

    componentDidMount() {
        this.props.onChangeTheme({
            color: 'light',
            style: 'primary',
            overrideStyle: false
        });
        document.title = "Dashboard";
    }

    handleLogout = (event) => {
        this.props.onLogout()
    };

    getPercentageRatio(patientsData) {
        const ratio = {
            female : 0,
            male: 0
        };

        let count = (patientsData.female) + patientsData.male;
     
        let femalePercent = (patientsData.female / count) * 100;
        let malePercent = 100 - femalePercent;
        ratio.male = malePercent > 0 ? malePercent.toFixed(0) : 0;
        ratio.female = femalePercent> 0 ? femalePercent.toFixed(0): 0;
        return ratio;
    }

    render () {
        
        const { layouts } = this.state;



        const dashboardData = !Object.blank(this.props.dashboardData)? this.props.dashboardData : null;

        const samplesCardData = {
            pending: dashboardData ? dashboardData.pendingSamples : 0,
            submitted: dashboardData ? dashboardData.processedSamples : 0,
        };

        const countsCardData = {
            male: dashboardData ? dashboardData.malePatients : 0,
            female: dashboardData ? dashboardData.femalePatients : 0,
            users: dashboardData ? dashboardData.users : 0,
        };
        const maleFemalePatientRatio = this.getPercentageRatio(countsCardData);

        const paymentsData = dashboardData ? dashboardData.recentPayments : [];

        const calculatePercent = (divident, diviser) => {
            return (parseFloat(divident/diviser )) * 100
        }
        const billInsight = {
            totalPaid: dashboardData ? dashboardData.billInsight.totalPaid : 0.0,
            totalUnpaid: dashboardData ? dashboardData.billInsight.totalUnPaid : 0.0,
            totalAmount: dashboardData ? dashboardData.billInsight.totalAmount : 0.0,

            fullPaidCount: dashboardData ? dashboardData.billInsight.fullPaidCount : 0,
            unPaidCount: dashboardData ? dashboardData.billInsight.unPaidCount : 0,
            
            partialPaidCount: dashboardData ? dashboardData.billInsight.partialPaidCount : 0,

            paidRatio: dashboardData ? calculatePercent(dashboardData.billInsight.totalPaid, dashboardData.billInsight.totalAmount) : 0.0,

            unpaidRatio: dashboardData ?calculatePercent(dashboardData.billInsight.totalUnPaid, dashboardData.billInsight.totalAmount) : 0.0
        };

        

        return (
            <div className='client-layout'>
            
                <Grid >
                    <Grid.Row 
                        
                        isDraggable={true}
                        isResizable={ false}
                        onLayoutChange={ layouts => this.setState({ layouts }) }
                        columnSizes={ this.state.layouts }
                        rowHeight={ 55 }
                        >
                        <Grid.Col { ...(applyColumn('count-labs', layouts)) }>
                            <div>
                                <Card className="count-chart-card">
                                    <CardBody>
                                        <div className="count-chart single ">
                                            <div className="icon-box">
                                                <span className="icon"><img src={getIconUrl('specimen')}/></span>
                                            </div>
                                            <div className="details">
                                                <div className="count">{abbrNumber(samplesCardData.submitted, 1)}</div>
                                                <div className="main-info">Processed Samples</div>
                                                <div className="secondary-info">
                                                <span>{abbrNumber(samplesCardData.pending, 1)} Pending</span></div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                                <Card  className="count-chart-card">
                                    <CardBody>
                                        <div className="count-chart single ">
                                            <div className="icon-box">
                                                <span className="icon"><img src={getIconUrl('dashboardusers')}/></span>
                                            </div>
                                            <div className="details">
                                                <div className="count">{abbrNumber(countsCardData.users, 1)}</div>
                                                <div className="main-info">Users</div>
                                                <div className="secondary-info">
                                                <span>Active users</span></div>
                                            </div>
                                        </div>
                                    </CardBody>
                            </Card>
                            <Card  className="count-chart-card split">
                                <CardHeader>
                                    Patients
                                </CardHeader>
                             
                                    <CardBody>
                                        <div className="count-chart">
                                            <div className="details">
                                                <div className="s lside">
                                                    <div className="f">
                                                        <span className="icon">
                                                            <img src={getIconUrl('male')}/>
                                                        </span>
                                                        <span className="label">Male</span>
                                                    </div>
                                                </div>
                                                <div className="s rside">
                                                    <div className="f">
                                                        <span className="icon">
                                                            <img src={getIconUrl('female')}/>
                                                        </span>
                                                        <span className="label">Female</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bar">
                                                <div className="bar-1"
                                                style={
                                                    {width: `${maleFemalePatientRatio.male}%`}
                                                }>
                                                    <span className="count">{abbrNumber(countsCardData.male, 1)}</span>
                                                </div>
                                                <div className="bar-2"
                                                style={
                                                    {width: `${maleFemalePatientRatio.female}%`}
                                                }>
                                                <span className="count">{abbrNumber(countsCardData.female, 1)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                            </Card>
                            </div>
                            
                        </Grid.Col>
                        
                        <Grid.Col { ...(applyColumn('metric-v-target-sessions', layouts)) }>
                            <Card id="card-04" className="--center-chart">
                                <CardHeader>
                                    Billing
                                </CardHeader>
                                <CardBody>

                                    <div className="strip-chart-group">
                                        <div className="stripe-bar-chart color-green">
                                            <div className="chat">
                                                <div className="info">
                                                    <span>${billInsight.totalAmount.toCurrency()} <i>Last 30 days</i></span>
                                                </div>
                                                <div className="details">
                                                    <h4>${billInsight.totalPaid.toCurrency()}</h4>
                                                    <span>Paid amount</span>
                                                </div>
                                                <div className="chat-bar">
                                                    <div bar-color="#31aa1d" className="inner-bar"
                                                     style={
                                                        {width: `${billInsight.paidRatio}%`}
                                                    }></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="stripe-bar-chart color-orange" >
                                            <div className="chat">
                                                <div className="info">
                                                    <span>{billInsight.unPaidCount} Unpaid bills<i> 30 Days</i></span>
                                                </div>
                                                <div className="details">
                                                    <h4>${billInsight.totalUnpaid.toCurrency()}</h4>
                                                    <span>Due amount</span>
                                                </div>
                                                <div className="chat-bar">
                                                    <div  bar-color="#fb8c2c" className="inner-bar"
                                                    style={
                                                        {width: `${billInsight.unpaidRatio}%`}
                                                    }></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </CardBody>
                            </Card>

                        </Grid.Col>

                        <Grid.Col { ...(applyColumn('metric-v-target-pageviews', layouts)) }>
                        <Card  id="card-05" className="chart-list-items">
                                <CardHeader>
                                    Recent Payments
                                </CardHeader>
                                <CardBody>
                                    <List className="chart-list">
                                        { paymentsData.length === 0 ? 
                                        <li className="center no-entry">No data avaliable</li>
                                        : paymentsData.map((item, i) => 
                                        <li key={i} className="chart-item">
                                            <span className="pmt-date">{item.date}</span>
                                            <div className="item-info">
                                                <h4>{item.name}</h4>
                                                <span>AC: {item.account}</span>
                                            </div>
                                            <div className="info-lines">
                                                <p>
                                                    <span className="label">Amount</span> 
                                                    <span className="value">${item.amount.toCurrency()}</span>
                                                </p>
                                            </div>
                                        </li>
                                        ) }
                                    </List>
                                </CardBody>
                            </Card>
                        </Grid.Col>
                    </Grid.Row>

                </Grid>
            </div>
        );
    }
}

export { ClientLayout }

//export default connect(mapStateToProps, mapDispatchToProps)(DashboardContextTheme);