import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
    NavLink,
    FormGroup,
    InputField,
} from './../../../components';
import { PermissionEnforcer } from '../../../utility/PermissionEnforcer';
import { getIconUrl } from '../../../common/images-catalogue';

const PermissionSelector = (props) => {

    const inputRef = React.createRef();
    const selectRef = React.createRef();

    let { titlePrefix, chosen, handler, optionsData, attributes} = props;
    //var optionsData = props.optionsData;
    const [optionsList, setOptionsData] = useState(optionsData);
    const [keyword, setKeyword] = useState('');
    const isDataObj = optionsData instanceof Array;
    
    const permissionEnforcer = PermissionEnforcer.getInstance(PermissionEnforcer);
    const selectClassname = chosen ? "selector-chosen" : "selector-available";
    const indentifier = chosen ? "permissionSelectMulti-chosen" : "permissionSelectMulti-available";
    const  classnames = classNames('selector', selectClassname);
    var  delegateAction = false;

    const didSelectOption = (event) => {
        let  selections = [], opt;
        if (attributes.mode === 'view' || !permissionEnforcer.isPermitted('permission', 'change')) return;
        
        for (let i = 0, len = event.target.options.length; i < len; i++) {
            opt = event.target.options[i];
            opt.selected &&  selections.push(parseInt(opt.value));
            opt.removeAttribute('selected');
        }
        handler(selections, delegateAction);
    }

    const filter = (keyword, options) => {
        var filteredList = options.filter((item) => 
            item.desc.toLowerCase().indexOf(keyword.toLowerCase()) !== -1
        );
        return filteredList;
    }
    
    const handlerFilter = (evt) => {
        let keyword = evt.target.value;
        setOptionsData(filter(keyword, restructureData(optionsData)));
        setKeyword(keyword);
    }

    const deselectAll = () => {
        for (let i = 0, len = selectRef.current.options.length; i < len; i++) {
            selectRef.current.options[i].removeAttribute('selected');
        }
    }

    const restructureData = (options) => {
        options = options.slice(0).map((perm) => {
            const permCodes = perm.permissionCode.split('_');
            const desc = `Can ${permCodes.first()} ${permCodes.last()}` ;
                return {
                    ...perm,
                    desc: desc
                }
            }
        );
        return options;
    }

    useEffect(() => {
        
        const options = restructureData(optionsData);
        setOptionsData(filter(keyword, options));

    },[keyword, optionsData]);

    const chooseOrRemoveAll = (event) => {
        //Preventing action if user doesn't have the rights
        if (attributes.mode === 'view' || !permissionEnforcer.isPermitted('permission', 'change') ) return;
        if (selectRef.current.options.length === 0 ) return;
        let asArray = Array.from(selectRef.current.options);

        asArray.forEach((opt, i) => {
            selectRef.current.options[i].setAttribute('selected', '');
        });
        delegateAction = true;
        didSelectOption({target: selectRef.current});
    };
    const disableInteraction = attributes.disabledPerms || attributes.mode === 'view' || !permissionEnforcer.isPermitted('permission', 'change') ? {'disabled' : true } : '';
    const disabledRemoveAll = optionsData.empty() ? {'disabled' : true } : '';
    
    return (
        <div className={classnames}>
            <div className="head">
                <h2>{titlePrefix} permissions</h2>
            </div>
            <div className="selector-wrapper">
                {
                   !chosen ?
                    <div className="selector-filter">
                        <FormGroup className="section-inner">
                            <div className="filter-group">
                                <span className="icon"><img src={getIconUrl("filter")} alt='' /></span>
                                <InputField ref={inputRef} { ...disableInteraction } onChange={ handlerFilter }id="filter-field" type="text" autoComplete="off" className="ip-text-default filter" placeholder="Filter"
                                value={keyword}
                                />
                            </div>
                        </FormGroup>
                    </div>
                    : null 
                }
                <select { ...disableInteraction } ref={selectRef} onChange={ didSelectOption } onBlur={ deselectAll} type="select" name="selectMulti" id={indentifier} multiple>
                    { 
                    // had placed optionsData instead of options list on the array to filter
                    isDataObj && optionsList.map((perm) => {
                        const permCodes = perm.permissionCode.split('_');
                        return <option key={perm.id} value={perm.id}> { permCodes.last().capitalize() } | { permCodes.first() } -  { perm.desc } </option> 
                            }
                        ) 
                    } 
                </select>
            </div>
            <div className="action-links">
            <NavLink { ...disableInteraction } { ...disabledRemoveAll } onClick={chooseOrRemoveAll} id={ chosen ? "removeAll" : "chooseAll" } className={ chosen ? "remove-all" : "choose-all" }>{ chosen ? "Remove all" : "Choose all" }<span className="icon"><img src={getIconUrl("shortArrow")} alt='' /></span></NavLink>
            </div>
        </div>
    );
}

PermissionSelector.propTypes = {
    //optionsData: PropTypes.object.require,
    attributes: PropTypes.object
};

export { PermissionSelector }