import React from 'react'
import PropTypes from 'prop-types';
import { useState, useEffect } from "react";
import { REQUESTS_TYPE } from './../../../common/constant';
import stringLibrary from './../../../common/strings';

const InfoIcon = () => {

    return <i className="iv">
        <span className="vline lng"></span>
        <span className="vline dot"></span>
    </i>
}

const CheckMarkIcon = () => {
    return <i className="ic">
        <span className="check"></span>
    </i>
}
const PromptMessage = (props) => {
    const {error, success, message, visible, sticky} =  props;
    const [show, setShow] = useState(false);
    const [hideClass, setHideClass] = useState('');
    const delay = 6;
    const promptClass = error ? '--error' : success ? '--success' : '--info';
    const promptTitle = error ? 'Error' : success ? 'Success' : 'Info';

    useEffect(
        () => {
    
            setShow(visible);
            if (sticky) {
                return;
            }
            let hidePromptTimer = null;
            let fadeStartTimer = null;
            
            if (visible) {
                hidePromptTimer = setTimeout(() => {
                    setShow(false);
                    setHideClass('');
                }, delay * 1000);
               
                fadeStartTimer = setTimeout(() => setHideClass('--hide'), 1000);
            }
            // this will clear Timeout
            // when component unmount like in willComponentUnmount
            // and show will not change to true
            return () => {
                clearTimeout(hidePromptTimer);
                clearTimeout(fadeStartTimer);
                setShow(false);
                setHideClass('');
            };
        },
        [sticky, visible]
    );

    return !show ? null :<div className="prompt-wrap">
        <div className={`prompt ${promptClass} ${hideClass}`}>
            <span className="icon">
                { success ? <CheckMarkIcon /> : <InfoIcon /> }
            </span>
            <span className="text">
                <label className="title">{promptTitle}</label>
                <p className="msg">{message}</p>
            </span>
        </div>
    </div>
}
PromptMessage.defaultProps = {
    visible : false,
    sticky: false
}
;
PromptMessage.propTypes = {
    message: PropTypes.string.isRequired,
    success: PropTypes.bool,
    visible: PropTypes.bool,
    error: PropTypes.bool,
    sticky: PropTypes.bool.isRequired
};

export {PromptMessage};

export const PROMPT_TYPE = {
    Info: 'info',
    Success: 'success',
    Error: 'error'
}

const getMessage = (entity, requestType, apiOutcome) => {

    let textKey = '';
    let message = '';
    let suffix = '';

    if (apiOutcome.success) {
        suffix = 'Text';
        switch (requestType) {
            case REQUESTS_TYPE.BILLED:
                textKey =  `${requestType}${suffix}`;
                break;
            case REQUESTS_TYPE.SUBMIT:
                textKey = `${requestType}Success${suffix}`;
                break;
            default:
                textKey = `${requestType}d${suffix}`;
        }
    }else {
        suffix = 'UnableText';
        textKey = `${requestType}${suffix}`;
    }

    const entityText =  stringLibrary[`${entity}`] || stringLibrary[`${entity}Text`];
    message = entityText[textKey];

    return message;
}

export const getPromptMessageProps = (entity, apiOutcome) => {

    let props = {
        visible: false,
        message: ''
    };

    if (Object.blank(apiOutcome)) {
        return props;
    }
   
    const requestType = apiOutcome.requestType;

    if (apiOutcome.error) {
        props.message = getMessage(entity, requestType, apiOutcome);
        props.error = true;
        props.visible = true;
    }else if ((apiOutcome.success && requestType === REQUESTS_TYPE.CREATE) || 
    (apiOutcome.success && requestType === REQUESTS_TYPE.SUBMIT) || 
    (apiOutcome.success && requestType === REQUESTS_TYPE.BILLED) ) {
        
        props.message = getMessage(entity, requestType, apiOutcome);
        props.success = true;
        props.visible = true;
    }else if (apiOutcome.success) {
        props.message = getMessage(entity, requestType, apiOutcome);
        props.info = true;
        props.visible = true;
    }else {
        props.info = true;
    }

    return props;
}