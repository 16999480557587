import React, { useState }  from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BloodVial  from '../BloodVial';
import CellSlide from '../CellSlide';
import { PermissionEnforcer } from './../../../../utility/PermissionEnforcer';

import { 
    SimpleSelect,
    List,
    Input,
    Label
} from '../../../../components';

import { FORM_TYPE } from '../../../../common/constant';

const CodeFindingSelect = (props) => {
    const {rowIndex, index, findingListRef, findingsData, setShowFinding, findingsChange, specimenData } = props;

    const willSelect = (evt, data) => {
        var isClickInside = findingListRef.current.contains(evt.target); // evt.
        evt.preventDefault();
        isClickInside && findingsChange(`${data.abbreviation.toUpperCase()}-${data.description.toUpperCase()}`, index);
        isClickInside && setShowFinding(false);
    };
   
    return rowIndex !== index ? null : (<div className="code-findings" ref={findingListRef}>
        <List className="code-fndgs-list"  >
            { findingsData.map((finding, i) => {
                return ( <li key={i} className="code-fnds-itm" onClick={(evt) => willSelect(evt, finding)}>
                <span><strong>{finding.abbreviation}</strong> - {finding.description}</span>
            </li>);
            })}
        </List>
    </div>);
}
const CodeResultEntry = (props) => {
    const { selectMode,codeSheetItems, data, isEditable , specimen, clickHandler, didSelectOption, codeSheetsData, formType, findingsData, findingsChange, normalityChange} = props;
    const filterKeys = ['abbreviation'];

    const FINDING_NORMALITY = { 
        Normal: 'Normal',
        Abnormal: 'Abnormal'
    }

    const permissionEnforcer = PermissionEnforcer.getInstance(PermissionEnforcer);
    var inputRef = HTMLInputElement || null;
    var findingsRef = React.createRef();

    const [showFinding, setShowFinding] = useState(false);
    const [rowIndex, setRowIndex] = useState(-1);
    const [findingsList, setFindingsList] = useState(!findingsData ? [] : findingsData);

    const didClick = (evt, index) => {
        evt.stopPropagation();
        var isClickInside = evt.target.id ===  inputRef.props.id;
        var clickInsideFindings = !findingsRef.current ? false : findingsRef.current.contains(evt.target); 

        const radioInput = evt.target.parentNode;
        var clickPillSwitch = radioInput.className === 'ip-radio-pill' ? true : false ; 
      
        if (!isClickInside && !clickInsideFindings && !clickPillSwitch) {
            isEditable && clickHandler(false, index);
            setShowFinding(false);
        }  
    }

    const filter = (keyword) => {
        var filteredList = findingsData.filter(freight =>
            filterKeys.some(
              key =>
                freight[key]
                  .toLowerCase()
                  .indexOf(keyword.toLowerCase()) !== -1,
            ),
          );

        setFindingsList(filteredList);
    };

    const didType = (evt, index) => {
        findingsChange(evt.target.value.toUpperCase(), index);
        setShowFinding(evt.target.value.length ? true :false);
        var keyword = evt.target.value;
        filter(keyword);
    }

    const willEnter = (evt, index) => {
        setShowFinding(evt.target.value.length ? true :false);
        setRowIndex(index);
    }

    const handlePillSwitches = (evt, index) => {
        normalityChange(evt.target.value === FINDING_NORMALITY.Abnormal ? true : false, index);
    }

    var optionsList = !codeSheetsData ? [] :  codeSheetsData.map((item) => {
        return {
            ...item,
            value: item.abbreviation,
            label: `${item.abbreviation} : ${item.description}`
        }
    }) ;

    const FindingsSelect = React.forwardRef((props, ref) => (
        <CodeFindingSelect 
        findingsChange={findingsChange}
        setShowFinding={setShowFinding} 
        findingListRef={ref} {...props}
        findingsData={findingsList }/>
    ));

   
    return ( <div className="entry-cont">
    <div className="vial-hldr">
        { formType === FORM_TYPE.Gynec ? 
        <CellSlide 
        cells={ specimen.vialColour }
        specimenLabel={ specimen.specimenLabel}/>
        :
        <BloodVial 
        bloodType={specimen.bloodGroup}
        cork={specimen.vialColour} />
        }
    </div>
    <div className="spmt-details">
        <h4>Speciment Details</h4>
        <div className="">
        <p className="info-line">
            <span className="label">Type</span><span>{specimen.specimenLabel}</span>
        </p>
        <p className="info-line">
            <span className="label">BloodGroup</span><span>{specimen.bloodGroup !== ''? specimen.bloodGroup: '--'}</span>
        </p>
        <p className="info-line">
            <span className="label">Date</span><span>{specimen.dateCreated}</span>
        </p>
        </div>
    </div>

    <div className="code-sht-hldr">
        <div className="code-sht-details">
        <h4>Code Sheet Results</h4>
            { codeSheetItems.map((codeSheetItem, i) => {
                
                const index  = codeSheetItem.index;
                const normality = codeSheetItem.abnormalFinding;
    
                if (codeSheetItem.showSelect ) {
                    return <SimpleSelect
                        key={i}
                        id={'codeSheet'}
                        className="ip-smp-select"
                        name="Code Sheet"
                        title="Choose Code Sheet"
                        searchable={["Type Abbreviation of Code.", "No matching code"]}
                        filterKeys={['abbreviation']}
                        list={optionsList}
                        onChange={(choose) =>  didSelectOption({...choose, specimen: specimen.id}, index, false)}
                    /> 
                } else {
                    if (!permissionEnforcer.isPermitted('resultentry', 'view') ) {
                        return null;
                    }else {
                        return <div key={i} className={`code-sht-item ${isEditable ? '--editable': ''}`} onClick={(evt) => didClick(evt, index) }>
                            <span className="count">{i + 1}.</span>
                        <div className="code-entry"> 
                            <div className="code-sht-col abrv">
                                <h5>Code</h5>
                                <p>{codeSheetItem.abbreviation}</p>
                            </div>
                            <div className="code-sht-col desc">
                                <h5>Result</h5>
                                <p>{codeSheetItem.description}</p>
                            </div>
                        </div>
                        
                            <div className="code-sht-findings">
                                <span className="lble">Findings:</span><span className="vlu">
                                    <Input autoComplete="off" placeholder={"N/A"} disabled={!isEditable} id={'findings'} ref={node => { inputRef = node; }} name="findings" type="text" value={codeSheetItem.findings} onChange={(evt) => didType(evt, index)} onFocus={(evt) => willEnter(evt, index)} />
                                    {showFinding && 
                                        <FindingsSelect  
                                        ref={findingsRef}
                                        findingsData={findingsList}
                                        index={index} rowIndex={rowIndex}/>
                                    }
                                </span>
                                <span className="nmly">
                                    { (!isEditable && normality) ? null : !isEditable ? <Label className="ip-radio-pill"><span className="text">{FINDING_NORMALITY.Normal}</span></Label> :
                                     
                                        <Label className="ip-radio-pill">
                                        <Input 
                                        onChange={(evt) => handlePillSwitches(evt, index)} 
                                        type="radio"
                                        name={`normality-${index}`}  value={FINDING_NORMALITY.Normal} 
                                        checked={ !normality ? true : false }/>{' '}
                                            <span className="radioselect"></span>
                                            <span className="text">{FINDING_NORMALITY.Normal}</span>
                                        </Label>
                                    }

                                    { (!isEditable && !normality)  ? null : !isEditable ? <Label className="ip-radio-pill"><span className="text">{FINDING_NORMALITY.Abnormal}</span></Label> :
                                    <Label className="ip-radio-pill">
                                    <Input onChange={(evt) => handlePillSwitches(evt, index)} 
                                    type="radio" name={`normality-${index}`} 
                                    value={FINDING_NORMALITY.Abnormal}
                                    checked={normality ? true : false}
                                    />{' '}
                                        <span className="radioselect"></span>
                                        <span className="text">{FINDING_NORMALITY.Abnormal}</span>
                                    </Label>
                                    }
                                </span>
                            </div>
                    </div>
                    }
             }
             })
    
            }
            { (!selectMode && isEditable) &&
            <div className="code-sht-well">
                <button onClick={() => clickHandler(true)} className="add-btn">
                    <span>+</span>
                    Add Code Sheet Result
                </button>
            </div>
            }
        </div>
    </div>
</div>                                     
    );
};


const ResultEntry = (props) => {
    const { data, specimenData, codeSheetsData, findingsData, codeSheetsHandler , specimenSupervisor, formType} = props;

    const resultLines = data && data.resultLines.map((item, i) => ({
        index: i,
        ...item
    }));

    const [codeSheetItems, setCodeSheetItems] = useState(data ? resultLines: []);
    const [selectMode, setSelectMode] = useState(false);

    const codeSheetHandler = (newEntry, index) => {
        //setShowSelect(value);
        var codeItems = null;
        if (newEntry) {
           var emptyCodeItem = {
                index: codeSheetItems.length,
                showSelect: true,
                findings: '',
                abnormalFinding: false
            }
            codeItems = [...codeSheetItems, emptyCodeItem];

        }else {
            codeItems = codeSheetItems.map((item) => {
                if (item.index === index) {
                    item. showSelect = true;
                }else {
                    item.showSelect = false;
                }
                return item
            });
           
        }
        setCodeSheetItems(codeItems);
        setSelectMode(true);
    }

    const findingsChange = (value, index) => {
        const codeItems = codeSheetItems.map((item) => {
            if (item.index === index) {
                item.findings = value;
            }
            return item
        });
        setCodeSheetItems(codeItems);
        codeSheetsHandler({specimen: specimenData.id, resultLines: codeItems });
    }

    const normalityChange = (value, index) => {
        const codeItems = codeSheetItems.map((item) => {
            if (item.index === index) {
                item.abnormalFinding = value;
            }
            return item
        });
        setCodeSheetItems(codeItems);
        codeSheetsHandler({specimen: specimenData.id, resultLines: codeItems });
    }
    
    const codeSheetSelected = (option, index, hide) => {
        var codeItems = codeSheetItems.map((item) => {
            if (item.index === index) {
                item = {
                    ...item,
                    ...option,
                    showSelect: hide
                }
            }
            return item;
        } );

        setCodeSheetItems(codeItems);
        setSelectMode(false);

        //setCodeSheetItem(option);
        codeSheetsHandler({specimen: specimenData.id, resultLines: codeItems });
    }
   
    const options = {
        clickHandler: codeSheetHandler,
        didSelectOption: codeSheetSelected,
        specimen : specimenData,
        data: data,
        codeSheetItems: codeSheetItems,
        isEditable: specimenSupervisor.isReusltEntryEditable(),
        addAddReusltEntry: specimenSupervisor.canCreateResultEntry(),
        codeSheetsData: codeSheetsData,
        findingsData: findingsData,
        findingsChange: findingsChange,
        normalityChange: normalityChange,
        selectMode: selectMode,
        formType: formType
    };
    return <CodeResultEntry { ...options } />                                       
};

ResultEntry.propTypes = {
  
};

export { ResultEntry };