import React from 'react';
import _ from 'lodash';
import './Dashboard.style.scss';

import { 
    FloatGrid as Grid,
    Card,
    CardHeader,
    CardBody,
    List,
    Label,
    Authority
} from '../../components';

import {
    SimpleLineChart
} from './../components/charts/SimpleLineChart';
import {
    MetricVsTarget
} from './../components/charts/Dashboard/MetricVsTarget';

import {
    TinyDonutChartBig
} from './../components/charts/Dashboard/TinyDonutChartBig';

import { applyColumn } from './../../components/FloatGrid';
import { getIconUrl } from '../../common/images-catalogue';
import { abbrNumber } from '../../utility/helpers';


const LAYOUT = {
    'count-specimens': { h: 7, md: 4 },
    'count-doctors': { h: 7, md: 4},
    'count-labs': { h: 7, md: 4 },
    'count-patients': { h: 7, md: 4 },
    
    'metric-v-target-users': { h: 7, md: 4 },
    'metric-v-target-sessions': { h: 7, md: 4 },
    'metric-v-target-pageviews': { h: 7, md: 4 },
}

class MainLayout extends React.Component {

    static pageOption = {
        pageTitle: 'Role | Permission',
        grayBackground: true
    };
    enforcer;

    state = {
        layouts: _.clone(LAYOUT)
    }

    resetLayout = () => {
        this.setState({
            layouts: _.clone(LAYOUT)
        })
    }

    componentDidMount() {
        this.props.onChangeTheme({
            color: 'light',
            style: 'primary',
            overrideStyle: false
        });
        document.title = "Dashboard";
    }

    handleLogout = (event) => {
        this.props.onLogout()
    };

    getPercentageRatio(clientsData) {
        const ratio = {
            labs : 0,
            doctors: 0
        };

        let count = (clientsData.labs) + clientsData.doctors;
     
        let labsPercent = (clientsData.labs / count) * 100;
        let doctorsPercent = 100 - labsPercent;
        ratio.labs = labsPercent > 0 ? labsPercent.toFixed(0) : 0;
        ratio.doctors = doctorsPercent> 0 ? doctorsPercent.toFixed(0): 0;

        return ratio;
    }

    render () {
   
        const { layouts } = this.state;
        const dashboardData = !Object.blank(this.props.dashboardData)? this.props.dashboardData : null;

        const samplesCardData = {
            pending: dashboardData ? dashboardData.pendingSamples : 0,
            submitted: dashboardData ? dashboardData.processedSamples : 0,
        };

        const countsCardData = {
            doctors: dashboardData ? dashboardData.doctors : 0,
            labs: dashboardData ? dashboardData.labs : 0,
            patients: dashboardData ? dashboardData.patients : 0
        };
        const labsDoctorsRatio = this.getPercentageRatio(countsCardData);

        const paymentsData = dashboardData ? dashboardData.recentPayments : [];

        const calculatePercent = (divident, diviser) => {
            if (!divident && !diviser) return 0.0;
            return (parseFloat(divident/diviser )) * 100
        }

        const billInsight = {
            totalPaid: dashboardData ? dashboardData.billInsight.totalPaid : 0.0,
            totalUnpaid: dashboardData ? dashboardData.billInsight.totalUnPaid : 0.0,
            totalAmount: dashboardData ? dashboardData.billInsight.totalAmount : 0.0,

            fullPaidCount: dashboardData ? dashboardData.billInsight.fullPaidCount : 0,
            unPaidCount: dashboardData ? dashboardData.billInsight.unPaidCount : 0,
            partialPaidCount: dashboardData ? dashboardData.billInsight.partialPaidCount : 0,

            paidRatio: dashboardData ? calculatePercent(dashboardData.billInsight.totalPaid, dashboardData.billInsight.totalAmount) : 0.0,
            unpaidRatio: dashboardData ? calculatePercent(dashboardData.billInsight.totalUnPaid, dashboardData.billInsight.totalAmount) : 0.0
        };

        const paymentsInsight = {
            totalRevenue: dashboardData && dashboardData.paymentsInsight.totalRevenue ?  dashboardData.paymentsInsight.totalRevenue : 0.0,

            totalUncollected: dashboardData && dashboardData.paymentsInsight.outStandingPayment ? dashboardData.paymentsInsight.outStandingPayment : 0.0,

            totalCollected: dashboardData && dashboardData.paymentsInsight.collectedPayments ? dashboardData.paymentsInsight.collectedPayments : 0.0,

            collectedRevenue: dashboardData && dashboardData.paymentsInsight.collectedRevenue? dashboardData.paymentsInsight.collectedRevenue : 0.0,

            collectedRatio: dashboardData ? calculatePercent(dashboardData.paymentsInsight.collectedPayments, dashboardData.paymentsInsight.collectedRevenue) : 0.0,

            uncollectedRatio: dashboardData ? calculatePercent(dashboardData.paymentsInsight.outStandingPayment, dashboardData.paymentsInsight.collectedRevenue) : 0.0,            
        };

        const lineChartData = dashboardData ? dashboardData.lineChartData : {};
   
        var clientsChatData = !dashboardData ? [] : Object.keys(dashboardData.topClients);
        
        clientsChatData = clientsChatData.slice(0, 4).map((key, i) => {
            const item = {
                name: key,
                value: dashboardData.topClients.hasOwnProperty(key)? dashboardData.topClients[key] : 0 ,
                color: 'color'+ (i + 1)
            }
            return item;
        });

        const isUserAuthorizer = this.props.permissionEnforcer.isUserAuthorizer();
        
        return (
            <div className='client-layout'>
            
                <Grid >
                    <Grid.Row 
                        
                        isDraggable={true}
                        isResizable={ false}
                        onLayoutChange={ layouts => this.setState({ layouts }) }
                        columnSizes={ this.state.layouts }
                        rowHeight={ 55 }
                        >
                        
                        <Grid.Col { ...(applyColumn('metric-v-target-users', layouts)) }>
                            <Card id="card-03">
                                <CardHeader>
                                        Gynecology and Non-Gynecology
                                </CardHeader>
                                <CardBody>
                                    <div><h3>Volume by month</h3></div>
                                    <SimpleLineChart
                                    height={250} 
                                    data={lineChartData} />
                                </CardBody>
                            </Card>
                        </Grid.Col>
                
                        <Grid.Col { ...(applyColumn('count-doctors', layouts)) }>
                            <Card id="card-02">
                                <CardHeader>
                                    Doctors & Labs
                                </CardHeader>
                                <CardBody>
                                    <div><h3>Top clients last 30 Days</h3></div>
                                    <div className="donut-chart-group">
                                        <div className="chart-cont">
                                            <TinyDonutChartBig data={clientsChatData}/>
                                        </div>
                                        <div className="chart-legends">
                                            { clientsChatData.map((entry, i) => {

                                                return (<Label className={`${entry.color}`} key={i}>
                                                    <span className="value">{entry.name}</span>
                                                    <span className="lbl">
                                                        <strong>{abbrNumber(entry.value, 1)}</strong> Samples</span>
                                                </Label>);
                                            })}
                                        </div>
                                    </div>
                                   
                                </CardBody>
                            </Card>
                        </Grid.Col>

                        <Grid.Col { ...(applyColumn('count-labs', layouts)) }>
                            <div>
                                <Card className="count-chart-card">
                                    <CardBody>
                                        <div className="count-chart single ">
                                            <div className="icon-box">
                                                <span className="icon"><img src={getIconUrl('specimen')}/></span>
                                            </div>
                                            <div className="details">
                                                <div className="count">{ abbrNumber(samplesCardData.submitted, 1)}</div>
                                                <div className="main-info">Processed Samples</div>
                                                <div className="secondary-info">
                                                <span>{abbrNumber(samplesCardData.pending, 1)} Pending</span></div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                                <Card  className="count-chart-card">
                                    <CardBody>
                                        <div className="count-chart single ">
                                            <div className="icon-box">
                                                <span className="icon"><img src={getIconUrl('dashboardPatient')}/></span>
                                            </div>
                                            <div className="details">
                                                <div className="count">{abbrNumber(countsCardData.patients, 1)}</div>
                                                <div className="main-info">Registered Patients</div>
                                                <div className="secondary-info">
                                                <span></span></div>
                                            </div>
                                        </div>
                                    </CardBody>
                            </Card>
                            <Card  className="count-chart-card split">
                                <CardHeader>
                                    Clients
                                </CardHeader>
                             
                                    <CardBody>
                                        <div className="count-chart">
                                            <div className="details">
                                                <div className="s lside">
                                                    <div className="f">
                                                        <span className="icon">
                                                            <img src={getIconUrl('doctor')}/>
                                                        </span>
                                                        <span className="label">Doctors</span>
                                                    </div>
                                                </div>
                                                <div className="s rside">
                                                    <div className="f">
                                                        <span className="icon">
                                                            <img src={getIconUrl('lab')}/>
                                                        </span>
                                                        <span className="label">Labs</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bar">
                                                <div className="bar-1"
                                                    style={
                                                        {width: `${labsDoctorsRatio.doctors}%`}
                                                    }
                                                >
                                                    <span className="count">{countsCardData.doctors}</span>
                                                </div>
                                                <div className="bar-2"
                                                    style={
                                                        {width: `${labsDoctorsRatio.labs}%`}
                                                    }
                                                >
                                                <span className="count">{countsCardData.labs}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                            </Card>
                            </div>
                            
                        </Grid.Col>
                                         
                        <Grid.Col { ...(applyColumn('count-specimens', layouts)) }>
                            { !isUserAuthorizer &&  
                            <Card id="card-01">
                                <CardHeader>
                                    Payments
                                </CardHeader>

                                <CardBody>
                                    <div className="metric-group">
                                        <div className="info">
                                            <span>${paymentsInsight.collectedRevenue.toCurrency()} <i>Last 30 days</i></span>
                                        </div>
                                        <MetricVsTarget 
                                            title="Users"
                                            value= {`$${paymentsInsight.totalCollected.toCurrency()}`}
                                            lable={'Overdue'}
                                            progressbarColor="success"
                                            progressbarValue={paymentsInsight.collectedRatio}
                                            targetValue={paymentsInsight.collectedRevenue}
                                            striped
                                        />
                                    </div>
                                    <div className="metric-group">
                                        <div className="info">
                                            <span>${billInsight.totalAmount.toCurrency()} <i>Bills Last 30 days</i></span>
                                        </div>
                                        <MetricVsTarget 
                                            title="Users"
                                            value={`$${paymentsInsight.totalUncollected.toCurrency()}`}
                                            lable={'Overdue'}
                                            progressbarColor="info"
                                            progressbarValue={paymentsInsight.uncollectedRatio}
                                            targetValue={paymentsInsight.totalRevenue}
                                            striped
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            }
                        </Grid.Col>
                         
                        <Grid.Col { ...(applyColumn('metric-v-target-sessions', layouts)) }>
                        { !isUserAuthorizer &&  
                            <Card id="card-04" className="--center-chart">
                                <CardHeader>
                                    Billing
                                </CardHeader>
                                <CardBody>

                                <div className="strip-chart-group">
                                        <div className="stripe-bar-chart color-green">
                                            <div className="chat">
                                                <div className="info">
                                                    <span>${billInsight.totalAmount.toCurrency()} <i>Last 30 days</i></span>
                                                </div>
                                                <div className="details">
                                                    <h4>${billInsight.totalPaid.toCurrency()}</h4>
                                                    <span>{billInsight.fullPaidCount} Paid and {billInsight.partialPaidCount} Partially Paid amount</span>
                                                </div>
                                                <div className="chat-bar">
                                                    <div bar-color="#31aa1d" className="inner-bar"
                                                     style={
                                                        {width: `${billInsight.paidRatio}%`}
                                                    }></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="stripe-bar-chart color-orange" >
                                            <div className="chat">
                                                <div className="info">
                                                    <span>{billInsight.unPaidCount} Unpaid bills<i> 30 Days</i></span>
                                                </div>
                                                <div className="details">
                                                    <h4>${billInsight.totalUnpaid.toCurrency()}</h4>
                                                    <span>Due amount</span>
                                                </div>
                                                <div className="chat-bar">
                                                    <div  bar-color="#fb8c2c" className="inner-bar"
                                                    style={
                                                        {width: `${billInsight.unpaidRatio}%`}
                                                    }></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                            }
                        </Grid.Col>
                        
                        <Grid.Col { ...(applyColumn('metric-v-target-pageviews', layouts)) }>
                            { !isUserAuthorizer && 
                            <Authority allowed='view' entity='payment'>
                            <Card  id="card-05" className="chart-list-items">
                                <CardHeader>
                                    Recent Payments
                                </CardHeader>
                                <CardBody>
                                    <List className="chart-list">
                                        { paymentsData.length === 0 ? 
                                        <li className="center no-entry">No data avaliable</li>
                                        :
                                        paymentsData.map((item, i) => 
                                        <li key={i} className="chart-item">
                                            <span className="pmt-date">{item.date}</span>
                                            <div className="item-info">
                                                <h4>{item.name}</h4>
                                                <span>AC: {item.account}</span>
                                            </div>
                                            <div className="info-lines">
                                                <p>
                                                    <span className="label">Amount</span> 
                                                    <span className="value">${item.amount.toCurrency()}</span>
                                                </p>
                                            </div>
                                        </li>
                                        ) }
                                    </List>
                                </CardBody>
                            </Card>
                            </Authority>
                            }
                        </Grid.Col>
                        
                        
                    </Grid.Row>

                </Grid>
            </div>
        );
    }
}

export { MainLayout }

//export default connect(mapStateToProps, mapDispatchToProps)(DashboardContextTheme);