import {ofType } from 'redux-observable';
import { catchError, map, switchMap, mergeMap } from 'rxjs/operators';
import *  as actionTypes from '../actionType';
import { messageDialect, checkReponse } from '../../service/agent';
import { of } from 'rxjs';
import * as actions from '../actions';

export const getUserMessagesEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.FETCH_MESSAGES),
        switchMap((action) => {
            return messageDialect.fetchAllUserMessages(action.payload).pipe(
               map(response => {
                    const responseData = checkReponse(response, action.type);
                    return  actions.apiResponseActionCreator(actionTypes.FETCHED_MESSAGES_RESULTS, responseData);
                }),
               catchError(error => {
                    console.log("ERROR", error);
                    return of( actions.apiResponseActionCreator(actionTypes.FETCHED_MESSAGES_RESULTS, error));
               })
            );
        })
    )
}

export const getMessageThreadEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.FETCH_MESSAGE_THREAD),
        switchMap((action) => {
            return messageDialect.fetchAllMessageThread(action.payload).pipe(
               map(response => {
                    const responseData = checkReponse(response, action.type);
                    return  actions.apiResponseActionCreator(actionTypes.FETCHED_MESSAGE_THREAD_RESULT, responseData);
                }),
               catchError(error => {
                    console.log("ERROR", error);
                    return of( actions.apiResponseActionCreator(actionTypes.FETCHED_MESSAGE_THREAD_RESULT, error));
               })
            );
        })
    )
}

export const sendReadMessagesEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.SEND_MESSAGE_READ),
        switchMap((action) => {
            return messageDialect.markMessagesAsRead(action.payload).pipe(
               map(response => {
                    const responseData = checkReponse(response, action.type, action.payload.reqeustType);
                    return  actions.apiResponseActionCreator(actionTypes.SEND_MESSAGE_READ_RESULT, responseData);
                }),
               catchError(error => {
                    console.log("ERROR", error);
                    return of( actions.apiResponseActionCreator(actionTypes.SEND_MESSAGE_READ_RESULT, error));
               })
            );
        })
    )
}

export const deleteMessagesEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.DELETE_MESSAGE),
        switchMap((action) => {
            return messageDialect.deleteMessagesRequest(action.payload).pipe(
               map(response => {
                    const responseData = checkReponse(response, action.type, action.payload.reqeustType);
                    return  actions.apiResponseActionCreator(actionTypes.DELETE_MESSAGE_RESULT, responseData);
                }),
               catchError(error => {
                    console.log("ERROR", error);
                    return of( actions.apiResponseActionCreator(actionTypes.DELETE_MESSAGE_RESULT, error));
               })
            );
        })
    )
}
