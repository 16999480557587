import * as actionTypes from '../store/actionType';
import { JWT_COLLECTION_KEY } from '../common/constant';
import { getActiveUser } from '../utility/helpers';
import { isJwtTokenExpired } from '../utility/helpers';
import { setToken } from '../service/agentDialect';
import * as appActions from '../store/actions';
 class RefreshJwtSubroutine {
    
    sessionUser = null;
    sessionToken = null;
    _queuedActions = [];

    whiteListedAction = [
        actionTypes.ASYNC_START,
        actionTypes.ASYNC_ENDED,
        actionTypes.LOGOUT,
        actionTypes.ABORT_API_REQUEST,
        actionTypes.REFRESH_JWT_TOKEN,
        actionTypes.REFRESH_JWT_TOKEN_RESULT,
    ];

    store = null;
    next = null;
    isRenewingToken = false;

    isAllowedAction(actionType) {
        return this.whiteListedAction.find((item) => item === actionType);
    }

    initialize(store, next) {
        this.store = store;
        this.next = next;
        this.sessionUser = getActiveUser();
        const  jwtCollection = window.localStorage.getMap(JWT_COLLECTION_KEY);
        this.sessionToken = this.sessionUser ? jwtCollection[this.sessionUser.user] : null;
    }

    isTokenExpired() {
        return this.sessionToken ?  isJwtTokenExpired(this.sessionToken) : false;
    }

    performRefreshToken(action) {

        if ( !this.store.getState().global.refreshJwtToken && this.isTokenExpired()  ) {
            
            const expiredToken = this.store.getState().global.token ?? this.sessionToken; 
            setToken(expiredToken);
            this.addActionToQueue(action);
            this.next(appActions.authRefreshTokenAction());
            this.isRenewingToken = true;
            return this.isRenewingToken
        }

        return false;
    }

    get isRenewing() {
        return this.isRenewingToken;
    }

    completeRenewal(action) {
        if ( this._queuedActions.length  && this.isRenewingToken 
            && action.type === actionTypes.REFRESH_JWT_TOKEN_RESULT ) {
                this.isRenewingToken = false;
                this.next(action);
            return true;
        }
        return false;
    }

    dispatchQueuedActions() {
        if ( this._queuedActions.length && !this.isRenewingToken ) {
            while ( this._queuedActions.length ) {
                const dequeueAction = this._queuedActions.shift();
                this.next(dequeueAction);
            }
        }
    }

    addActionToQueue(action) {
        if ( !this._queuedActions.find((item) => item.type === action.type) ) {
            this._queuedActions.push(action);
        }
    }

    destructor() {
        this._instance = null;
    }
   
    static getInstance = (classInstance) => RefreshJwtSubroutine._instance ? RefreshJwtSubroutine._instance : (RefreshJwtSubroutine._instance = new classInstance());
};

export default RefreshJwtSubroutine;