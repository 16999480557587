import { requests } from './agentDialect';
import { objectToQueryString } from '../utility/helpers';

export const fetchAllUsers = (payload) => {
    const {workspaceId, ...queryParams} = payload;
    queryParams['workspace'] = workspaceId;
    let uri = "/users" ;
    uri = objectToQueryString(uri, queryParams);
    return requests.get(uri);
};
export const fetchUser = (userId) => {
    let uri = `/users/${userId}`;
    return requests.get(uri);
};
export const submitUser = (data) => {
    let uri = "/user/create";
    return requests.formPost(uri, data.form);
};

export const updateUser = (data) => {
    let uri = `/user/update/${data.userId}`;
    return requests.formPut(uri, data.form);
};

export const deleteUser = (data) => {
    let uri = `/user/delete/${data.userId}`;
    return requests.delete(uri, data);
};

export const changeUserPassword = (data) => {
    let uri = `/user/passwordChange`;
    return requests.put(uri, data.form);
};

export const changeUserAuthAccess = (data) => {
    const { userId, requestType, ...otherData } = data;
    let uri = `/user/authAccess/${userId}`;
    return requests.patch(uri, otherData);
};
