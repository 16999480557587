import React from 'react';
import PropTypes from 'prop-types';

const LayoutContent = (props) => {
    const {clientLayout, navBarHeight} = props;
    return(
        <div style={{height: `calc(100vh - ${navBarHeight}px)`}} className={`layout__content ${clientLayout ? 'layout__clientdashboard' : ''}`}>
            { props.children }
        </div>
    )
};


LayoutContent.defaultProps = {
    clientLayout: false
};

LayoutContent.propTypes = {
    children: PropTypes.node,
    clientLayout: PropTypes.bool
};
LayoutContent.layoutPartName = "content";

export {
    LayoutContent
};
