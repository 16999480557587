import React from 'react';
import { LogoThemed } from './ThemeLogo';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const HeaderAuth = (props) => (
    <div className="mb-4">
        <div className="mb-4 text-center brand-area">
            <LogoThemed title=""  className="logo" style="virtial" height="30" />
        </div>
        <h2 className="text-center mb-4 title">
            { props.title }
        </h2>
        <p className="text-center sub-text">
            { props.text }
        </p>
    </div>
);

HeaderAuth.propTypes = {
    icon: PropTypes.node,
    iconClassName: PropTypes.node,
    title: PropTypes.node,
    text: PropTypes.node,
};

HeaderAuth.defaultProps = {
    title: "Header Title",
    text: "",
    iconClassName: "text-theme"
};

export { HeaderAuth };