import React from  'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
    withPageConfig
} from './../../components/Layout';

class EmptyLayout extends React.Component {
    static propTypes = {
        pageConfig: PropTypes.object.isRequired,
        children: PropTypes.node.isRequired,
        className: PropTypes.string
    };

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render() {
        const emptyLayoutClass = classNames('fullwidth', this.props.className);
        
        return (
            <div className={emptyLayoutClass}>
                { this.props.children }
            </div>
        );
    }
}

const PageConfigEmptyLayout = withPageConfig(EmptyLayout);

export {  PageConfigEmptyLayout as EmptyLayout };