import React from 'react';


const NoMatch = (props) => {

    const { children, className, cx, getStyles, innerProps } = props;
    return (<div className={className}>
        {children}
    </div>)
}

export default NoMatch;