import { VALIDATOR_DELEGATE_KEY_GROUP } from "../common/constant";

const delegate = (validator, key, value) => {
  const results = {};

  switch(key){
      //validates all items in the lab codes pane.
      case VALIDATOR_DELEGATE_KEY_GROUP.settings.labCodes:
          results.labCodesInvalid = false;

          //Nothing to validate.
          if(!Array.isArray(value)) break;
          if(value.blank()) break;
          

          const labCodes = [...value];
          
          for (let index = 0; index < labCodes.length; index++) {
            const code = labCodes[index];

            if(validator.isValueMissing(code.code)){
              results.labCodesInvalid = true;
              break;
            }

          }

          break;

      case VALIDATOR_DELEGATE_KEY_GROUP.settings.codeSheet:
      case VALIDATOR_DELEGATE_KEY_GROUP.settings.codeFindings:
        results[`${key}Invalid`] = false;

        if(!Array.isArray(value)) break;

        for (let index = 0; index < value.length; index++) {
          const {abbreviation, description} = value[index];

          if(validator.isValueMissing(abbreviation) || validator.isValueMissing(description)){
            results[`${key}Invalid`] = true;
            break;
          } 
        }

        break;

      case VALIDATOR_DELEGATE_KEY_GROUP.settings.services:
        results.servicesInvalid = false;

        if(!Array.isArray(value)) break;

        for (let index = 0; index < value.length; index++) {
          const {price, name} = value[index];

          if(validator.isValueMissing(price) || validator.isValueMissing(name)){
            results.servicesInvalid = true;
            break;
          } 
        }
        break;
      
      case VALIDATOR_DELEGATE_KEY_GROUP.settings.taxes:
        results.taxesInvalid = false;

        if(!Array.isArray(value)) break;

        for (let index = 0; index < value.length; index++) {
          const {percent, taxCode} = value[index];

          if(validator.isValueMissing(percent) || validator.isValueMissing(taxCode)){
            results.taxesInvalid = true;
            break;
          } 
        }
        break;
      
        case VALIDATOR_DELEGATE_KEY_GROUP.settings.departments:
          results.departmentsInvalid = false;

          if(!Array.isArray(value)) break;

          for (let index = 0; index < value.length; index++) {
            const { name } = value[index];

            if(validator.isValueMissing(name)){
              results.taxesInvalid = true;
              break;
            } 
          }
          break;
      default:
          break;
  }

  return results;
}
export default delegate;