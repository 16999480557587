import React from 'react';
import {
    SimpleSelect,
    FormGroup,
    Input,
    Label
} from './../../../components';

import { CLIENTS } from './../../../common/constant';
const Filter_RP03 = (props) => {
    const {reportForm, actionType, reportFieldChange, labCodes } = props;
    
    const didSelectLabcode = (labcode) => {
        const fieldSet = {
            options: {
                ...reportForm.options,
                labCode: labcode.value
            }
        }
        reportFieldChange(actionType, fieldSet);
    }

    const handlePillSwitches = (evt) => {
        const fieldSet = {
            options: {
                ...reportForm.options,
                clientType: evt.target.value
            }
        }
        reportFieldChange(actionType, fieldSet);
    }

    const labcodes = labCodes.map((labcode, i) => ({
        value: labcode.id,
        label: labcode.code
    }));


    const clientType = reportForm.options.clientType !== null ? reportForm.options.clientType: null;

    const selectedLabCode =  !reportForm.options.labCode ? null : labcodes.find((labCode) => labCode.value === reportForm.options.labCode)

    return <div className="opts-wrap">
        <FormGroup>
            <Label>Labcode</Label>
                <SimpleSelect
                id={'labCode'}
                className="ip-sml-select"
                name="labCode"
                title="From Labcode"
                list={labcodes}
                deselect={true}
                initialSelected={selectedLabCode}
                onChange={didSelectLabcode}
                />
        </FormGroup>
        <FormGroup className="hdn">
            <Label>By Client</Label>
            <div className="no-warp">
            <Label className="ip-radio-pill">
            <Input 
             onChange={handlePillSwitches } 
             type="radio"
             name="clientType" value={CLIENTS.Doctor} 
             checked={clientType && clientType === CLIENTS.Doctor ? true : false}/>{' '}
                <span className="radioselect"></span>
                <span className="text">{CLIENTS.Doctor}</span>
            </Label>

            <Label className="ip-radio-pill">
            <Input onChange={handlePillSwitches } 
            type="radio" name="clientType" 
            value={CLIENTS.Lab}
            checked={clientType && clientType === CLIENTS.Lab? true : false}
             />{' '}
                <span className="radioselect"></span>
                <span className="text">{CLIENTS.Lab}</span>
            </Label>
            </div>
        </FormGroup>
    </div>
}

export default Filter_RP03;