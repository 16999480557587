
import {ofType } from 'redux-observable';
import { catchError, map, switchMap, mergeMap, takeUntil } from 'rxjs/operators';
import *  as actionTypes from '../actionType';
import { paymentDialect, checkReponse } from '../../service/agent';
import { of } from 'rxjs';
import * as actions from '../actions';
import { apiResponseActionCreator } from '../actions';

export const getAllPaymentsEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.FETCH_PAYMENTS),
        mergeMap((action) => {
            return paymentDialect.fetchAllPayments(action.payload).pipe(
               map(response => {
                    const responseData = checkReponse(response, action.type);
                    return  actions.apiResponseActionCreator(actionTypes.FETCHED_PAYMENTS_RESULTS, responseData);
                }),
               catchError(error => {
                    console.log("ERROR", error);
                    return of( actions.apiResponseActionCreator(actionTypes.FETCHED_PAYMENTS_RESULTS, error));
               })
            );
        })
    )
}

export const submitPaymentEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.PAYMENT_SUBMIT_DATA),
        
        mergeMap((action) => {
          const apiCalls = {
            'create': paymentDialect.submitPayment,
            'update': paymentDialect.updatePayment
          }
            return apiCalls[action.payload.requestType](action.payload).pipe(
               map(response => {
                    const responseData = checkReponse(response, action.type, action.payload.requestType);
                    return apiResponseActionCreator(actionTypes.PAYMENT_SUBMITED_RESULTS, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
               catchError(error => {
                    console.log("ERROR", error);
                    return of(apiResponseActionCreator(actionTypes.PAYMENT_SUBMITED_RESULTS, error));
               })
            );
        })
  )};

  export const getPaymentsSummaryEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.FETCH_PAYMENTS_SUMMARY),
        mergeMap((action) => {
            return paymentDialect.fetchPaymentsSummary(action.payload).pipe(
               map(response => {
                    const responseData = checkReponse(response, action.type);
                    return  actions.apiResponseActionCreator(actionTypes.FETCHED_PAYMENTS_SUMMARY_RESULTS, responseData);
                }),
               catchError(error => {
                    console.log("ERROR", error);
                    return of( actions.apiResponseActionCreator(actionTypes.FETCHED_PAYMENTS_SUMMARY_RESULTS, error));
               })
            );
        })
    )
}
