import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import authReducer from './auth';
import commonReducer from './common';
import permissionsRolesReducer from './permissionsRoles';
import usersReducer from './users';
import accountReducer from './account';
import patientReducer from './patient';
import specimenReducer from './specimen';
import clientReducer from './client';
import employeeReducer from './employee';
import paymentReducer from './payment';
import departmentReducer from './department';
import billingReducer from './billing';
import payrollReducer from './payroll';
import messageReducer from './message';
import settingsReducer from './settings/settings';
import reportsReducer from './reports';
import profileReducer from './profile';

import { clientCombineReducers, clientCreateViewReducer } from './client';

const reducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    global: commonReducer,
    auth: authReducer,
    permissionsRoles: permissionsRolesReducer,
    users: usersReducer,
    account: accountReducer,
    client: clientReducer,
    //clients: clientCombineReducers,
    clientCreateView: clientCreateViewReducer,
    patient: patientReducer,
    specimen: specimenReducer,
    employee: employeeReducer,
    payment: paymentReducer,
    department: departmentReducer,
    billing: billingReducer,
    payroll: payrollReducer,
    message: messageReducer,
    reports: reportsReducer,
    settings: settingsReducer,
    profile: profileReducer,
  });

export default reducer;
