import React from 'react';

import  * as actionTypes  from '../../../store/actionType';
import ReportPage from '../ReportPage'

class Report_RP011 extends React.PureComponent  {
    
    componentDidMount() {
        const customizeReport = this.props.customize;
        customizeReport && this.props.apiRequestManager.queueRequest(actionTypes.FETCH_CLIENTS);
    }

    paginate(reports, reportsPerPage = 10){
        const totalPages = Math.ceil(reports.length / reportsPerPage);
        const result = [];

        for(let i = 0; i < totalPages; i++){
            result.push(reports.slice(i * reportsPerPage, (i * reportsPerPage) + reportsPerPage));
        }

        return { pages: result, totalPages };
    }

    render () {

        const ReportHeader = this.props.ReportHeader;
        const ReportFooter = this.props.ReportFooter;
        const ReportNotes = this.props.ReportNotes;
        const hasNotes = ReportNotes ? true : false;

        const reportData = Array.isArray(this.props.reportResults) ? this.props.reportResults : [];

        /*Currently, going beyond 10 items per pages will cause items to bleed over onto the next page.
        Anything between 1 and 10 should work fine.
        */
        const {pages, totalPages} = this.paginate(reportData, 10);

        //Adding 1 because the notes page will be added at the end.
        const maxPages = hasNotes ? totalPages + 1 : totalPages;
        
        return <React.Fragment>
            {
                pages.map((page, index) => (
                    <ReportPage key={index} 
                    ReportHeader={ReportHeader} 
                    ReportFooter={ReportFooter}
                    currentPageNum={index + 1}
                    maxPages={maxPages}
                    >
                        <table className="default">
                            <thead>
                                <tr>
                                    <th>Client</th>
                                    <th>Patient</th>
                                    <th>Requisition</th>
                                    <th>Specimen Record</th>
                                </tr>
                            </thead>
                            <tbody>
                                { page.map((data, i) => {  
                                    const status = data.statuses[ data.statuses.length - 1];
                                    const patientName = `${data.patient.firstName} ${data.patient.lastName}`;
    
                                    const clientName = data.client.officeName ? data.client.officeName : `${data.client.firstName} ${data.client.firstName}`;
    
                                    return (<tr key={i} className="smpl-row row-divider">
                                        <td>
                                            <p>{clientName}</p>
                                            <p>AC# {data.client.accountNo}</p>
                                        </td>
                                        <td>
                                            <p>{patientName}</p>
                                            <p>Reg.# {data.patient.registrationNo}</p>
                                        </td>
                                        <td>
                                            <div className="info-line">Req ID: {data.requisitionItem.id}</div>
                                            <div className="info-line">Cost: ${data.requisitionItem.cost.toCurrency()}</div>
                                            <div className="info-line">{data.requisitionItem.dateCreated}</div>
                                        </td>
                                        <td>
                                            <div className="info-line">Ref# {data.identifier}</div>
                                            <div className="info-line">Lab#: { !data.labNumber.empty() ? data.labNumber : '--' }</div>
                                            <div className="info-line">Form: { data.formType }</div>
                                            <div className="info-line">Status: {status.status} , Urgent: {data.urgent ? 'YES' : 'NO'} </div>
                                        </td>
                                    </tr>);
                                })}
                            </tbody>
                        </table>
                    </ReportPage>
                ))
            }
            {
                hasNotes ? 
                <ReportPage
                    ReportHeader={ReportHeader}
                    ReportFooter={ReportFooter}
                    currentPageNum={maxPages}
                    maxPages={maxPages}
                >
                    {ReportNotes}
                </ReportPage>
                :
                null
            }
        </React.Fragment> 
    }  
}

export default Report_RP011;