import { ConfigSettingsView as Settings } from './Settings';
import { ConfigFormView as FormSetting } from './Form';

import { ConfigCodeSheetView as CodeSheetPane } from './Panes/Full/CodeSheet';
import { ConfigCodeFindingsView as CodeFindingsPane } from './Panes/Full/CodeFindings';
import { ConfigLabCodeView as LabCodePane } from './Panes/Full/LabCode';
import { ConfigTaxesView as TaxesPane } from './Panes/Full/Taxes';
import { ConfigServicesView as ServicesPane } from './Panes/Full/Services';
import {ConfigDepartmentView as DepartmentPane} from './Panes/Full/Department'

import CompanyPane from './Panes/Partial/CompanyPane';
import NotificationPane from './Panes/Partial/NotificationPane';

export default Settings;
export {
    FormSetting,
    CodeSheetPane,
    TaxesPane,
    LabCodePane,
    ServicesPane,
    CodeFindingsPane,
    DepartmentPane,
    
    CompanyPane,
    NotificationPane
};