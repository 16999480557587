export const rootUri = process.env.BASE_PATH || '/';
export const loginUri = '/auth/login';
export const homeUri = '/home';
export const dashboardUri = '/dashboard';
//Users Routes
export const usersUri = "/users";
export const deleteUserUri = "/roles/delete";

export const createUserUri = "/users/create";
export const updateUserUri = "/users/update";
export const permissionUri = "/permissions";
export const permissionsCreateUri = "/roles/create";
//Client
export const doctorsUri = "/doctors";
export const labsUri = "/labs";
export const clientOverviewUri = "/client/overview";
export const clientIdOverviewUri = "/client/:id(\\d+)/overview";
export const clientCreateUri = "/client/create";
export const clientUpdateUri = "/client/update";
//Speciment
export const specimensUri = "/specimens";
export const specimensCreateUri = "/specimens/create";
export const specimensUpdateUri = "/specimens/update";
export const specimensResultSheetUri = "/specimens/resultsheet";
export const resultSheetPrintUri = "/specimens/resultsheet/print/:resultsheet_id(\\d+)";
//Patient
export const patientsUri = "/patients/";
export const patientOverviewUri = "/patient/overview";
export const patientIdOverviewUri = "/patient/:id(\\d+)/overivew";
export const patientCreateUri = "/patient/create";
export const patientUpdateUri = "/patient/update";
//Account
export const accountsUri = "/accounts";
//Employee
export const employeesUri = "/employees";
export const employeeCreateUri = "/employee/create";
export const employeeUpdateUri = "/employee/update";
//Billing
export const billingUri = "/billing";
export const billViewUri = "/bill/view";
export const createBillUri = "/billing/create";
export const updateBillUri = "/billing/update";

export const paymentsUri = "/payments";

export const payrollUri = "/payroll";
export const payrollWizardUri = "/payroll/wizard";
export const payrollDetailsUri = "/payroll/details";
export const payrollPayslipUri= "/payroll/payslip/:payroll_id(\\d+)";
export const payrollPayslipSingleUri= "/payroll/payslip/:payroll_id(\\d+)\\?payadvice_f=:id(\\d+)";

export const messagesUri = "/messages";
export const messagesThreadUri= "/messages/thread/:thread_id(\\d+)";

export const settingsUri= "/settings";
export const settingsPaneUri= "/settings/:pane_id(\\w+)";
export const formSettingUri= "/setting/form"

export const reportsUri= "/reports"

//Profile
export const profileUri = "/profile";