import React from 'react';
import { Fragment } from 'react';
import * as actionTypes from '../../store/actionType';
import DelayRender from './../../utility/DelayRender';

import {timeDelayed } from '../../utility/helpers';
import { 
    Section,
    SpecimenTable,
 } from '../../components';

import SpecimenCard from './../components/partials/SpecimenCard';
import { STATUS } from '../../common/constant';

class Overview extends React.Component {
    
    RECENT_LIMIT = 4;

    constructor(props) {
        super(props);
        
        this.state = {
            activeView: 'overview',
        }
    }

    componentDidMount() {
        if ( !this.props.specimensData && this.props.workspace ) {
            //this.props.useClientLayout ? this.props.apiRequestManager.queueRequest(actionTypes.FETCH_SPECIMEN_RECORDS, this.props.workspace.id) : this.props.apiRequestManager.queueRequest(actionTypes.FETCH_SPECIMEN_RECORDS);
        }
        this.unlistenAbort = this.props.history.listen(() => { 
            this.props.apiRequestManager.abortRequest(actionTypes.FETCH_SPECIMEN_RECORDS);
        });
    }

    componentWillUnmount() {
        this.props.unLoad();
        this.unlistenAbort();
    }
    
    static getDerivedStateFromProps(props, state) {
        
        // Return null to indicate no change to state.
        if ( !props.specimensData && props.workspace ) {
            const requestParams = {
                workspace: props.useClientLayout && props.workspace.id,
                userType: props.authorizeUser.type
            }
            props.useClientLayout ? props.apiRequestManager.queueRequest(actionTypes.FETCH_SPECIMEN_RECORDS, requestParams) : 
            props.apiRequestManager.queueRequest(actionTypes.FETCH_SPECIMEN_RECORDS,requestParams);
        }

        if ( props.specimensData && props.workspace && props.lazyLoad ) {
            const requestParams = {
                workspace: props.useClientLayout && props.workspace.id,
                userType: props.authorizeUser.type,
                page: props.pageLoaded 
            }
            props.useClientLayout ? props.apiRequestManager.queueRequest(actionTypes.FETCH_SPECIMEN_RECORDS, requestParams) : 
            props.apiRequestManager.queueRequest(actionTypes.FETCH_SPECIMEN_RECORDS,requestParams);
        }

        return null;
    }

    mostRecentUrgentSamples(tableData) {
        let urgentSamples = [];
        var i = 0;
        while(i < tableData.length &&  urgentSamples.length < this.RECENT_LIMIT ) {
            if ( tableData[i].urgent === true  && tableData[i].statuses.last().status !== STATUS.Approved ) {
                urgentSamples.push(tableData[i]);
            }
            i++;
        }
        return urgentSamples
    }

    render() {
        const tableData = Array.isArray(this.props.specimensData) ? this.props.specimensData : [];
        const recentSpecimens = !this.props.useClientLayout ?  this.mostRecentUrgentSamples(tableData) : tableData.slice(0, this.RECENT_LIMIT);
        //tableData.slice(Math.max(tableData.length - 4, 0))
        var baseMs = 0;
        const display = (specimen, i) => {
            const waitTime  = baseMs = timeDelayed(i, 150, true, baseMs);
            return !specimen ? null : <DelayRender key={i} waitBeforeShow={timeDelayed(i, 200)} >
            
            <div key={specimen.id} className="sp-item-rec">
                <SpecimenCard 
                    data={specimen}  
                    eventHandler={this.props.handleSpecimentCardClick} 
                    bloodType={"B+"}
                    label={specimen.type} />
            </div>
            </DelayRender>
        }

        return <Fragment>
            <div className="spmt-Ly-ct-inner">
                <div className="spmt-title-d">
                    <h3 className="spmt-title">Overview</h3>
                </div>
           </div>
            <div className="spmt-v-lay-ct">
                <div className="content-well">
                    <Section className="recent-sec-l">
                        <div className="rc-title">
                            <h5>{ this.props.useClientLayout ? 'Recent Specimens' : 'Urgent Specimens' }</h5>
                        </div>
                        <div className="rc-content-wid">
                            <div id ="rcList" className="rc-content-list">
                                {
                                    recentSpecimens.map((specimen, i) => display(specimen, i))
                                }
                            </div>
                        </div>
                    </Section>

                    <Section className="recent-sec-t specimen-table">
                        <SpecimenTable id="mainTable" 
                        hoverOverRowAction={this.props.willHandleTableRowMouseOver}
                        quickActions={this.props.quickActionCallback} tableData={tableData}/>
                    </Section>
                </div>
            </div>
        </Fragment>
    }
};

export default Overview;