import React from 'react';
import { setupPage } from '../../components/Layout/setupPage';
import ActionBar from '../components/partials/ActionBar';
import DateTimePicker from 'react-datetime-picker';
import { FormValidator } from '../../utility/FormValidator';

import {
    Row,
    Container,
    Col,
    Button,
    Section,
    Form, 
    FormGroup, 
    Label, 
    Input, 
    InputField,
    SimpleSelect,
    ValidateField,
    ErrorFeedback
} from '../../components';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import * as urls from '../../config/urls';
import * as helpers from '../../utility/helpers';
import * as actionTypes from '../../store/actionType';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
import FileUploader from '../../utility/FileUploader';

import { objToFormData, getDateInTimeZoneString } from '../../utility/helpers';
import ConfirmModal from '../components/partials/ConfirmModal';
import { PromptMessage, getPromptMessageProps } from '../components/partials/PromptMessage';
import {SEARCH_SETTINGS } from '../../config/searchable';
import { REQUESTS_TYPE, VALIDATOR_DELEGATE_TYPES, VALIDATOR_DELEGATE_KEY_GROUP } from '../../common/constant';
import './Employee.style.scss';
import { ErrorMessage } from '../components/partials/ErrorMessage';
import { getIconUrl } from '../../common/images-catalogue';
import employeeValidatorDelegate from '../../forms/employeeValidator.delegate';

const mapStateToProps = (state) => ({
    apiRequestManager : ApiRequestManager.getInstance(ApiRequestManager),
    clientDashboard: state.global.useClientDashboard,
    mode:  state.global.actionBarMode,
    validator: FormValidator.getInstance(FormValidator),
    ...state.employee,
});

const mapDispatchToProps = (dispatch) => ({
    onLoad: () => dispatch(actions.employeesCreateViewLoadedAction()),
    unLoad: () => dispatch(actions.employeesCreateViewUnloadAction()),
    setMode: (mode) => dispatch(actions.actionBarModeAction(mode)),
    getUsers: (payload) => dispatch(actions.getAllUsersActionCreator(payload)),
    getDepartments: () => dispatch(actions.getAllDepartments()),
    employeeFieldDidChange: (fieldWithValue) => dispatch(actions.employeeFieldChangeAction(fieldWithValue)),
    submitData : (data) => dispatch(actions.submitEmployeeAction(data)),
    setEmployeeFormData: (data) => dispatch(actions.setUpdateEmployeeDataAction(data)),
}); 

class CrateUpdate extends React.Component {
    
    static pageOption = {
        grayBackground: true,
        disableBodyScroll: true,
        searchContext: SEARCH_SETTINGS.entities.employee.name
    };

    gender = {
        male: 'Male',
        female: 'Female'
    }

    payCycle = {
        weekly: 'weekly',
        biWeekly: 'biweekly',
        monthly: 'monthly'
    }

    constructor(props) {
        super(props);
        
        props.apiRequestManager.register(actionTypes.EMPLOYEE_SUBMIT_DATA, props.submitData);
        props.apiRequestManager.register(actionTypes.FETCH_DEPARTMENTS, props.getDepartments);
        props.apiRequestManager.register(actionTypes.FETCH_USERS, props.getUsers);

        this.state = {
            activeTab: '1',
            showAddressFields: false,
            showUserList: false,
            deleteDialogVisible: false,
            deleteContextName: 'item',
            deleleContextItem: {},
            date: new Date(),
            viewInit: false,
            formErrors: {}
        };

        this.datePicker = React.createRef();
        this.profileImage = React.createRef();
        this.selectDepartment = React.createRef();

        //Add event listeners
        this.handlerBack = this.handlerBack.bind(this);
        this.handleTextField = this.handleTextField.bind(this);
        this.handleAddressTextField = this.handleAddressTextField.bind(this);
        this.handleDetailsTextField = this.handleDetailsTextField.bind(this);
        this.handlePillSwitches = this.handlePillSwitches.bind(this);
        this.handleToggleSwitches = this.handleToggleSwitches.bind(this);
        this.submitFormData = this.submitFormData.bind(this);
        this.updateEmployeeData = this.updateEmployeeData.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.didSelectDepartmentOption = this.didSelectDepartmentOption.bind(this);
        this.didSelectUserOption = this.didSelectUserOption.bind(this);
        this.addAddressFields = this.addAddressFields.bind(this);
        this.showUsersListOption = this.showUsersListOption.bind(this);
        this.dateChange = this.dateChange.bind(this);
        this.hiredDateChange = this.hiredDateChange.bind(this);
        this.previewImage = this.previewImage.bind(this);
        this.showDeleteDialog = this.showDeleteDialog.bind(this);
        this.onConfirmDelete = this.onConfirmDelete.bind(this);
        this.cancelDelete = this.cancelDelete.bind(this);
        this.submitDelete = this.submitDelete.bind(this);
        this.composeFormData = this.composeFormData.bind(this);
        this.getPromptMessage = this.getPromptMessage.bind(this);
        //Fetching data need for view
        props.apiRequestManager.queueRequest(actionTypes.FETCH_USERS, {workspaceId: null});
        props.apiRequestManager.queueRequest(actionTypes.FETCH_DEPARTMENTS);
        
    }

    componentDidMount() {
        this.props.onLoad();
    
        const mode = !Object.blank(this.props.employee)? 'edit' : 'create';
        this.props.setMode(mode); 

        mode === 'create' && this.setFormDefaults();

        if (mode === 'edit' && this.props.employee) { 
           const formData = this.composeFormData(this.props.employee);
           this.props.employeeFieldDidChange(formData);

           const transformedFormData = objToFormData(formData);

           this.setState({
               formErrors: {
                   ...this.state.formErrors,
                   ...this.validateFields(transformedFormData),

                   ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.employee,VALIDATOR_DELEGATE_KEY_GROUP.formFields.hiredDate, formData[VALIDATOR_DELEGATE_KEY_GROUP.formFields.hiredDate]),

                   ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.employee,VALIDATOR_DELEGATE_KEY_GROUP.formFields.dateOfBirth, formData[VALIDATOR_DELEGATE_KEY_GROUP.formFields.dateOfBirth])
               }
           })
        }


        this.setState({viewInit: true});

        this.props.validator.addDelegate(VALIDATOR_DELEGATE_TYPES.employee, employeeValidatorDelegate);
    }
    componentWillUnmount() {
        this.props.apiRequestManager.terminateAll();
        this.state.viewInit && this.props.unLoad();
    }

    static getDerivedStateFromProps(props, state) {
        return null;
    }

    shouldComponentUpdate(props, state) {
        if ( props.employee && props.employee.user && props.users && !props.employeeForm.user ) {
            console.log(props.users)
            let user  = props.users.standard.pageData.filter((item) => item.username == props.employee.user);
            if (user.length > 0) {
                user = this.composeUserSelectOption(user[0]);
                props.employeeFieldDidChange({user: user.id});
            }
        }

        if ( props.employee && props.departments && !props.employeeForm.department ) {
            let department  = props.departments.filter((item) => item.name == props.employee.department);
            if (department.length > 0) {
                department = this.composeUserSelectOption(department[0]);
                props.employeeFieldDidChange({department: department.id});
            }
        }
        if (props.employee) {
            props.setMode('edit');
        }

        return true;
    }

    setFormDefaults() {
        //!this.props.clientDashboard && this.props.getUsers();
        !this.props.employee && this.employeeRegistrationNo();

        const fieldset = {
            gender : this.gender.male,
            [VALIDATOR_DELEGATE_KEY_GROUP.formFields.dateOfBirth] : this.state.date,
            [VALIDATOR_DELEGATE_KEY_GROUP.formFields.hiredDate] : this.state.date,
            details: {
                payCycle: this.payCycle.weekly,
                fixedSalary: false
            }
        };

        this.props.employeeFieldDidChange(fieldset);
    }

    handleTextField  (event) {
        let fieldSet = {
           [event.target.name] : event.target.value 
        };

        this.props.employeeFieldDidChange(fieldSet);
        const key = event.target.name;

        this.setState({
            formErrors: {
                ...this.state.formErrors,
                ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.employee,VALIDATOR_DELEGATE_KEY_GROUP.formFields[key], event.target.value)
            }
        });
    }
    handleAddressTextField(event) {
        let fieldSet = {
            address: {
                ...this.props.employeeForm.address,
                [event.target.name] : event.target.value 
            }
          };
         this.props.employeeFieldDidChange(fieldSet);
    }
    handleDetailsTextField(event) {
        let finalFieldValue = event.target.value;

        if(event.target.name === VALIDATOR_DELEGATE_KEY_GROUP.formFields.phoneNumber){
            const phoneNumber = event.target.value;
            finalFieldValue = helpers.formatPhoneNumber(phoneNumber);

        }else if(event.target.name === VALIDATOR_DELEGATE_KEY_GROUP.formFields.trn){
            const trn = helpers.clampString(helpers.removeTextCharacters(event.target.value), 9);
            finalFieldValue = helpers.formatString(trn, {3: '-', 7: '-'});

        }else if(event.target.name === VALIDATOR_DELEGATE_KEY_GROUP.formFields.nis){
            const nis = helpers.clampString(String(event.target.value), 10);
            finalFieldValue = nis;
        }

        let fieldSet = {
            details: {
                ...this.props.employeeForm.details,
                [event.target.name] : finalFieldValue,
            }
          };
         this.props.employeeFieldDidChange(fieldSet);

        const key = event.target.name;

        this.setState({
            formErrors: {
                ...this.state.formErrors,
                ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.employee,VALIDATOR_DELEGATE_KEY_GROUP.formFields[key], finalFieldValue)
            }
        });
    }
    handleToggleSwitches (event) {
        let fieldSet = {};

        switch (event.currentTarget.id) {
            case 'fixedSalary':
                fieldSet = {
                    details:{
                        ...this.props.employeeForm.details, 
                        fixedSalary : event.target.checked 
                    }
                };
                break;
            default:
        }
        this.props.employeeFieldDidChange(fieldSet);
    }
    handlePillSwitches  (event) {
        let fieldSet = {};

        if (event.target.name === 'gender' ) {
            fieldSet = {
                gender : event.target.value 
            };
        }else {
            fieldSet = {
                details: {
                    ...this.props.employeeForm.details,
                    payCycle : event.target.value 
                }
            };
        }
        this.props.employeeFieldDidChange(fieldSet);
    }
    
    employeeRegistrationNo() {
        const fieldSet = { 'employeeNo' : `E${helpers.generateDigits(5)}` };
        this.props.employeeFieldDidChange(fieldSet);
    }

    submitFormData(evt) {

        const isUpdate = this.props.mode === 'edit' ? true : false;
        const {profileImage, fixedSalary, user, dateOfBirth, dateHired, ...otherFields} = this.props.employeeForm;

        const uploader = FileUploader.getInstance(FileUploader);
        let formData = objToFormData(otherFields);
       
        formData.append(VALIDATOR_DELEGATE_KEY_GROUP.formFields.dateOfBirth, getDateInTimeZoneString({ date: this.props.employeeForm.dateOfBirth }));
        formData.append(VALIDATOR_DELEGATE_KEY_GROUP.formFields.hiredDate, getDateInTimeZoneString({date: this.props.employeeForm.hiredDate}));

        if (user) {
            formData.append('user', user);
        }
 
        let formPayload = {
            requestType: isUpdate ? REQUESTS_TYPE.UPDATE : REQUESTS_TYPE.CREATE ,
            form: formData
        }

        if (this.props.employeeForm.profileImage) {
            const fileName =  uploader.generateFileName(profileImage);
            formData.append("profileImage", profileImage, fileName);
        }

        //toLocaleDateString("en-US")
        if (isUpdate) {
            formPayload['employeeId'] = this.props.employee ? this.props.employee.id : null
        }

        const formErrors = this.validateFields(formPayload.form);
   
        if (!this.props.validator.hasActiveFormError(formErrors)) {
            this.props.apiRequestManager.queueRequest(actionTypes.EMPLOYEE_SUBMIT_DATA, formPayload); 
        }else {
            this.setState({
                formErrors: formErrors
            });
        }
    }

    validateFields(formData) {
        //this is needed because fields in the details section of the form payload are stored as the 
        //following -> details[nis]. So this is function allows us to actually access that value.
        function toDetailsKey(originalKey){
            return `details[${originalKey}]`;
        }

        const formFields = VALIDATOR_DELEGATE_KEY_GROUP.formFields;

        return {
            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.employee,formFields.department, formData.get(formFields.department)),

            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.employee,formFields.firstName, formData.get(formFields.firstName)),

            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.employee,formFields.lastName, formData.get(formFields.lastName)),

            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.employee,formFields.middleName, formData.get(formFields.middleName)),

            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.employee,formFields.email, formData.get(formFields.email)),

            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.employee,formFields.dateOfBirth, formData.get(formFields.dateOfBirth)),

            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.employee,formFields.hiredDate, formData.get(formFields.hiredDate)),

            /**Details section */
            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.employee,formFields.phoneNumber, formData.get(toDetailsKey(formFields.phoneNumber))),

            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.employee,formFields.trn, formData.get(toDetailsKey(formFields.trn))),

            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.employee,formFields.nis, formData.get(toDetailsKey(formFields.nis))),

            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.employee,formFields.hourlyRate, formData.get(toDetailsKey(formFields.hourlyRate))),

            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.employee,formFields.salary, formData.get(toDetailsKey(formFields.salary))),

            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.employee,formFields.sickDays, formData.get(toDetailsKey(formFields.sickDays))),

            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.employee,formFields.vacationDays, formData.get(toDetailsKey(formFields.vacationDays))),
        };
    }

    submitDelete(employee) {
        const data = {
            requestType:  'delete' ,
            employeeId: employee.id
        }

        this.props.apiRequestManager.queueRequest(actionTypes.EMPLOYEE_SUBMIT_DATA, data);
    }

    updateEmployeeData(evt) {
        this.submitFormData(evt);
    }

    handleDelete(evt) { 
        this.showDeleteDialog('employee', this.props.employee);
    }

    handlerBack(evt) {
        this.props.history.push(urls.employeesUri);
        this.props.unLoad();
    }

    dateChange(date) {
        this.props.employeeFieldDidChange( { 'dateOfBirth' : date});

        this.setState({
            formErrors: {
                ...this.state.formErrors,
                ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.employee,VALIDATOR_DELEGATE_KEY_GROUP.formFields.dateOfBirth, date)
            }
        });
    }

    hiredDateChange(date) {
        this.props.employeeFieldDidChange( { [VALIDATOR_DELEGATE_KEY_GROUP.formFields.hiredDate] : date});

        this.setState({
            formErrors: {
                ...this.state.formErrors,
                ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.employee,VALIDATOR_DELEGATE_KEY_GROUP.formFields.hiredDate, date)
            }
        });
    }

    didSelectDepartmentOption(department) {
        this.props.employeeFieldDidChange( { [VALIDATOR_DELEGATE_KEY_GROUP.formFields.department] : department.id });

        this.setState({
            formErrors: {
                ...this.state.formErrors,
                ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.employee,VALIDATOR_DELEGATE_KEY_GROUP.formFields.department, department)
            }
        });
    }

    didSelectUserOption(user) {
        this.props.employeeFieldDidChange( { 'user' : user.id });
    }

    addAddressFields(evt) {
        evt.preventDefault();
        this.setState({ showAddressFields: true });
    }

    showUsersListOption(evt) {
        evt.preventDefault();
        this.setState({ showUserList : true });
    }

    composeFormData(employee) {
        
        let {address, isFixedSalary, ...otherDetails} = employee.details;

        const formData =  {
            employeeNo: employee.employeeNo,
            [VALIDATOR_DELEGATE_KEY_GROUP.formFields.firstName]: employee.firstName,
            [VALIDATOR_DELEGATE_KEY_GROUP.formFields.lastName]: employee.lastName,
            [VALIDATOR_DELEGATE_KEY_GROUP.formFields.middleName]: employee.middleName,
            [VALIDATOR_DELEGATE_KEY_GROUP.formFields.email]: employee.email || '',
            gender: employee.gender,
            [VALIDATOR_DELEGATE_KEY_GROUP.formFields.department]: employee.department.id,
            [VALIDATOR_DELEGATE_KEY_GROUP.formFields.dateOfBirth]: new Date(employee.dateOfBirth),
            [VALIDATOR_DELEGATE_KEY_GROUP.formFields.hiredDate]: new Date(employee.dateHired),
            age: employee.age,
            height: employee.height,
            weight: employee.weight,
            address: address ? address : {},
            details: {
                ...otherDetails,
                fixedSalary: isFixedSalary,
                payCycle: otherDetails.payCycle.toLowerCase()
            }
        }
        return formData
    }

    previewImage(event, imageRef) {
        let reader = new FileReader();
        let image =  this.profileImage;

        reader.onload = function() {
            const output = image.current;
            output.src = reader.result;
        }
        
        reader.readAsDataURL(event.target.files[0]);
        const file = event.target.files[0];
        const fieldSet = { 'profileImage' : file };
        this.props.employeeFieldDidChange(fieldSet);
         
    }

    composeUserSelectOption(user) {
        return {
            ...user,
            name: `${user.firstName} ${user.lastName}`,
            value: user.id,
            label: `${user.firstName} ${user.lastName} - ${user.email}`
        }
    }
    composeSelectOption(department) {
        return {
            id: department.id,
            value: department.id,
            label: `${department.name}`
        }
    }
    showDeleteDialog(contextName, deleleItem) {
        this.setState({deleteDialogVisible: true, 
            deleteContextName: contextName, 
            deleleContextItem: deleleItem });
    } 
    onConfirmDelete(deleteItem) {
        this.submitDelete(deleteItem);
        this.cancelDelete();
    } 
    cancelDelete() {
        this.setState({deleteDialogVisible: false, deleteContextName: ""});
    }

    getPromptMessage(){
        
        const apiOutcome = this.props.apiRequestManager.getApiOutcome(actionTypes.EMPLOYEE_SUBMIT_DATA, true) ?? {};
        return getPromptMessageProps('employee', apiOutcome);
    }
    
    render() {
        
        const actionBarCallback = {
            'save': this.submitFormData,
            'back': this.handlerBack,
            'delete': this.handleDelete
        };
        const defaultGender = this.props.employeeForm.gender === '' ? true : this.props.employeeForm.gender === this.gender.male ? true: false;
        const defaultPayCycle = this.props.employeeForm.details.payCycle === '' ? true : this.props.employeeForm.details.payCycle === this.payCycle.weekly ? true: false;

        //Updating context values
        const  actionBarMode = this.props.mode;
        const  saveSufixText = actionBarMode === 'edit' ? '' : 'Employee';
        
        const userList = Object.blank(this.props.users) || !Array.isArray(this.props.users.standard.pageData) ? [] : this.props.users.standard.pageData.map((user) => {
            return this.composeUserSelectOption(user);
        });
        
        let initalSeletedUser = userList.find((item) => item.id === this.props.employeeForm.user);

        const departmentList = !Array.isArray(this.props.departments) ? [] : this.props.departments.map((department) => this.composeSelectOption(department));

        let initalSeletedDepartment = departmentList.find((item) => item.id === this.props.employeeForm.department);
        
        const pictureSrc = this.props.employeeForm.profileImage ? URL.createObjectURL(this.props.employeeForm.profileImage) :  this.props.employee && this.props.employee.picture ? this.props.employee.picture.fileDownloadUri : getIconUrl('profile');

        const showAddressFields = this.state.showAddressFields || !Object.blank(this.props.employeeForm.address)? true : false ;

        const showUserList = this.state.showUserList || this.props.employeeForm.user  ? true : false ;

        const isFixedSalary = this.props.employeeForm.details.fixedSalary;
        const noDepartment =  this.props.departments && this.props.departments.empty() ? true : false;

        const formFields = VALIDATOR_DELEGATE_KEY_GROUP.formFields;

        const dialogProps = this.getPromptMessage();
  
        return (
            <React.Fragment>
                <ActionBar title={ "Employee" } saveTextSufix={ saveSufixText } entityContext={'employee'} actionResponsers={ actionBarCallback } permissionContext={ actionBarMode } />
                <div className="content-layout-wrap">
                    <PromptMessage message='No departments found, go to settings' error visible={noDepartment} sticky />
                    { dialogProps.visible && 
                        <PromptMessage {...dialogProps} />
                    }
                    <div className="view-layout-content">
                    <div className="default-Ly-ct">
                <Container >
                    <Row>
                    <Col></Col>
                    <Col sm="12" md={7}>
                        <div className="content-section patient">
                            <Row>
                                <Col sm="12">
                                    <Form onSubmit={(event) => event.preventDefault()}>
                                        <Section className="patient">
                                            <div className="section-head simple">
                                                <h3>Employee Info</h3>
                                            </div>
                                        </Section>
                                        <Section className="patient-info">
                                            <div className="section-body">
                                                <FormGroup className="section-inner">
                                                    <div className="image-thumbnail-box">
                                                        <div className="image-selector">
                                                            <img ref={this.profileImage} src={pictureSrc} alt=''></img>
                                                            <Button><span>Edit</span></Button>
                                                            <div className="input-field">
                                                                <Input onChange={this.previewImage} type="file" name="profile-image" accept="image/*" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="gender-group">
                                                    
                                                    <Label className="ip-radio-pill">
                                                        <Input onChange={this.handlePillSwitches } type="radio" name="gender" value={this.gender.male } checked={ defaultGender } />{' '}
                                                        <span className="radioselect"></span>
                                                        <span className="text">Male</span>
                                                    </Label>
                                                    <Label className="ip-radio-pill">
                                                    <Input onChange={this.handlePillSwitches } type="radio" name="gender"value={this.gender.female }
                                                     checked={this.props.employeeForm.gender === this.gender.female? true: false}/>{' '}
                                                        <span className="radioselect"></span>
                                                        <span className="text">Female</span>
                                                    </Label>
                                                </FormGroup>
                                                

                                                <FormGroup className="section-inner">
                                                <ValidateField required>
                                                <ErrorFeedback 
                                                    error
                                                    show={this.state.formErrors.departmentMissing}
                                                    filter='valueMissing'
                                                    message={<ErrorMessage message ='Please select a department' />} />
                                                    <SimpleSelect
                                                        id={formFields.department}
                                                        ref={this.selectDepartment}
                                                        className="ip-select-field"
                                                        name={formFields.department}
                                                        title="Choose department"
                                                        list={departmentList}
                                                        onChange={this.didSelectDepartmentOption}
                                                        initialSelected={initalSeletedDepartment}
                                                        />
                                                        </ValidateField>
                                                </FormGroup>

                                                
                                                <FormGroup className="section-inner username-field">
                                                    <InputField id="employeeNo" type="text" className="ip-text-default"  name={"employeeNo"} placeholder="Employee No." disabled/>
                                                    <span><i>Genterated </i> { this.props.employeeForm.employeeNo }</span>
                                                </FormGroup>

                                                <FormGroup className="section-inner">

                                                    <Label>Firstname</Label>
                                                    <ValidateField required>
                                                    <InputField onKeyUp={this.composeUserName} onChange={ this.handleTextField } id={formFields.firstName} type="text" name={formFields.firstName}className="ip-text-default" placeholder="John" value={this.props.employeeForm.firstName } />
                                                    <ErrorFeedback 
                                                        show={this.state.formErrors.firstNameMissing}
                                                        warning
                                                        filter='valueMissing'
                                                        message={<ErrorMessage message ='First name is required' />} />

                                                    <ErrorFeedback 
                                                        show={this.state.formErrors.firstNameInvalid}
                                                        warning
                                                        filter='letterInput'
                                                        message={<ErrorMessage message ='Name should only contain letters' />} />
                                                    </ValidateField>
                                                </FormGroup>

                                                <FormGroup className="section-inner">
                                                    <Label>Lastname</Label>
                                                    <ValidateField required>
                                                    <InputField onKeyUp={this.composeUserName} onChange={ this.handleTextField } id={formFields.lastName} type="text" name={formFields.lastName}className="ip-text-default" placeholder="Brown" value={this.props.employeeForm.lastName } />
                                                    <ErrorFeedback 
                                                       show={this.state.formErrors.lastNameMissing}
                                                        warning
                                                        filter='valueMissing'
                                                        message={<ErrorMessage message ='Last name is required' />} />

                                                    <ErrorFeedback 
                                                        show={this.state.formErrors.lastNameInvalid}
                                                        warning
                                                        filter='letterInput'
                                                        message={<ErrorMessage message ='Name should only contain letters' />} />
                                                    </ValidateField>
                                                </FormGroup>

                                                <FormGroup className="section-inner">
                                                    <Label>Middlename</Label>
                                                    <ValidateField>
                                                    <InputField onKeyUp={this.composeUserName} onChange={ this.handleTextField } id={formFields.middleName} type="text" name={formFields.middleName}className="ip-text-default" placeholder="John" value={this.props.employeeForm.middleName } />
                                                    
                                                    <ErrorFeedback 
                                                        show={this.state.formErrors.middleNameInvalid}
                                                        error
                                                        filter='letterInput'
                                                        message={<ErrorMessage message ='Name should only contain letters' />}  />
                                                        </ValidateField>
                                                </FormGroup>
                                                
                                                <FormGroup className="section-inner">
                                                    <Label>Email</Label>
                                                    <ValidateField required>
                                                    <InputField onChange={ this.handleTextField } id={formFields.email} type="text" name={formFields.email}className="ip-text-default" placeholder="example@mail.com"
                                                    value={ this.props.employeeForm.email } />
                                                    <ErrorFeedback 
                                                        show={this.state.formErrors.emailMissing}
                                                        warning
                                                        filter='valueMissing'
                                                        message={<ErrorMessage message ='Email is required' />} />

                                                    <ErrorFeedback 
                                                        show={this.state.formErrors.emailInvalid}
                                                        warning
                                                        filter='emailMismatch'
                                                        message={<ErrorMessage message ='Email is invalid' />} />
                                                    </ValidateField>
                                                </FormGroup>

                                                <FormGroup className="section-inner">
                                                    <Label>Date of Birth</Label>
                                                    
                                                    <div className="ip-date date-field"> 
                                                    <ValidateField required>
                                                    <DateTimePicker 
                                                    ref={this.datePicker}
                                                    className="date-picker"
                                                    clearIcon={null}
                                                    disableClock
                                                    time={false}
                                                    format={"MM-dd-y"}
                                                    onChange={this.dateChange}
                                                    value={this.props.employeeForm.dateOfBirth} />
                                                    <ErrorFeedback 
                                                        show={this.state.formErrors.dateOfBirthMissing}
                                                        warning
                                                        filter='valueMissing'
                                                        message={<ErrorMessage message ='Date is required' />} />
                                                        </ValidateField>
                                                    </div>
                                                    
                                                </FormGroup>

                                            </div>
                                        </Section>
                                        <Section className="address-info">
                                            <div className="section-head">
                                             <h3>Allow Auth</h3>
                                            </div>
                                            { !showUserList ?
                                            <div className="add-patient-holder">
                                                <Button className="add-address-btn" onClick={this.showUsersListOption}>
                                                    <span className="icon">
                                                        <img src={ getIconUrl("plusIcon") } alt=''/>
                                                    </span>
                                                    Choose User
                                                </Button>
                                            </div>
                                            : 
                                            <div className="section-body">
                                                <FormGroup className="section-inner">
                                                    <SimpleSelect
                                                        id={'user'}
                                                        className="ip-select-field"
                                                        name="user"
                                                        title="Choose user"
                                                        list={userList}
                                                        onChange={this.didSelectUserOption}
                                                        initialSelected={initalSeletedUser}
                                                        searchable={["Type Name or Email", "No matching user"]}
                                                        filterKeys={['name',
                                                                     'email']}
                                                        />
                                                </FormGroup>
                                            </div>
                                        }
                                        </Section>
                                        <Section className="details-info">
                                            <div className="section-head">
                                                <h3>Details</h3>
                                            </div>
                                            <div className="section-body">
                                                
                                                <FormGroup className="pay-period-group">
                                                    <Label className="label">
                                                        Pay Period
                                                    </Label>
                                                    <Label className="ip-radio-pill">
                                                        <Input onChange={this.handlePillSwitches } type="radio" name="payCycle" value={this.payCycle.weekly } checked={ defaultPayCycle } />{' '}
                                                        <span className="radioselect"></span>
                                                        <span className="text">Weekly</span>
                                                    </Label>

                                                    <Label className="ip-radio-pill">
                                                    <Input onChange={this.handlePillSwitches } type="radio" name="payCycle" value={this.payCycle.biWeekly }
                                                     checked={this.props.employeeForm.details.payCycle === this.payCycle.biWeekly? true: false}/>{' '}
                                                        <span className="radioselect"></span>
                                                        <span className="text">Bi-Weekly</span>
                                                    </Label>

                                                    <Label className="ip-radio-pill">
                                                    <Input onChange={this.handlePillSwitches } type="radio" name="payCycle" value={this.payCycle.monthly }
                                                     checked={this.props.employeeForm.details.payCycle === this.payCycle.monthly ? true: false}/>{' '}
                                                        <span className="radioselect"></span>
                                                        <span className="text">Monthly</span>
                                                    </Label>

                                                    <label className="ip-checkbox --fixed-salary">Fixed Salary?
                                                        <Input 
                                                        onChange={this.handleToggleSwitches }
                                                        id="fixedSalary"
                                                        value={this.props.employeeForm.details.fixedSalary || false}
                                                        checked={ this.props.employeeForm.details.fixedSalary }
                                                        type="checkbox" 
                                                        />
                                                        <span className="checkmark round"></span>
                                                    </label>
                                                    
                                                </FormGroup>

                                                <FormGroup className="section-inner">
                                                    <Label>Hired Date</Label>
                                                    
                                                    <div className="ip-date date-field">
                                                        <ValidateField required>
                                                            <DateTimePicker 
                                                            ref={this.hiredDatePicker}
                                                            className="date-picker"
                                                            clearIcon={null}
                                                            disableClock
                                                            time={false}
                                                            format={"MM-dd-y"}
                                                            
                                                            onChange={this.hiredDateChange}
                                                            value={this.props.employeeForm.hiredDate} />

                                                            <ErrorFeedback 
                                                            show={this.state.formErrors.hiredDateMissing}
                                                            warning
                                                            filter='valueMissing'
                                                            message={<ErrorMessage message ='Date is required' />} />
                                                        </ValidateField>
                                                    </div>
                                                    
                                                </FormGroup>
                                                
                                                <FormGroup className="section-inner">
                                                    <Label>Phone Number</Label>
                                                    <ValidateField>
                                                    <InputField onChange={ this.handleDetailsTextField } id={formFields.phoneNumber} type="text" name={formFields.phoneNumber} className="ip-text-default" placeholder="888-888-8888" value={this.props.employeeForm.details.phoneNumber } />
                                                    
                                                    <ErrorFeedback
                                                        show={this.state.formErrors.phoneNumberInvalid}
                                                        warning
                                                        filter='phoneNumber'
                                                        message={<ErrorMessage message ='Phone number is invalid' />} />
                                                    </ValidateField>
                                                </FormGroup>

                                                <FormGroup className="section-inner">
                                                    <Label>TRN</Label>
                                                    <ValidateField>
                                                    <InputField  onChange={ this.handleDetailsTextField } id={formFields.trn} type="text" name={formFields.trn}className="ip-text-default" placeholder="123-234-214"
                                                    value={this.props.employeeForm.details.trn }/>
                                                      <ErrorFeedback 
                                                        show={this.state.formErrors.trnInvalid}
                                                        warning
                                                        filter='trn'
                                                        message={<ErrorMessage message ='TRN is invalid' />} />
                                                    </ValidateField>
                                                    
                                                </FormGroup>
                                                <FormGroup className="section-inner">
                                                    <Label>NIS</Label>
                                                    <ValidateField>
                                                    <InputField  onChange={ this.handleDetailsTextField } id={formFields.nis} type="text" name={formFields.nis}className="ip-text-default" placeholder="123-456"
                                                    value={this.props.employeeForm.details.nis }/>
                                                    <ErrorFeedback 
                                                        show={this.state.formErrors.nisInvalid}
                                                        warning
                                                        filter='nis'
                                                        message={<ErrorMessage message ='NIS is invalid' />} />
                                                    </ValidateField>
                                                    
                                                </FormGroup>
                                                
                                                <FormGroup className="section-inner">
                                                    <Label>Hourly Rate</Label>
                                                   <ValidateField>
                                                    <InputField  onChange={ this.handleDetailsTextField } id={formFields.hourlyRate} type="number" name={formFields.hourlyRate}className="ip-text-default" placeholder="0.0"
                                                    min={0.0}
                                                    value={this.props.employeeForm.details.hourlyRate }
                                                    disabled={isFixedSalary}/>
                                                    <ErrorFeedback
                                                        show={this.state.formErrors.hourlyRateInvalid}
                                                        warning
                                                        filter='numberInput'
                                                        message={<ErrorMessage message ='Only numbers are allowed' />}  />
                                                    </ValidateField>
                                                </FormGroup>
                                                
                                                <FormGroup className="section-inner">
                                                    <Label>Salary</Label>
                                                    <ValidateField>
                                                    <InputField  onChange={ this.handleDetailsTextField } id={formFields.salary} type="number" name={formFields.salary}className="ip-text-default" placeholder="0.0"
                                                    min={0.0}
                                                    value={this.props.employeeForm.details.salary }
                                                    disabled={!isFixedSalary}
                                                    />
                                                     <ErrorFeedback
                                                        show={this.state.formErrors.salaryInvalid}
                                                        warning
                                                        filter='numberInput'
                                                        message={<ErrorMessage message ='Only numbers are allowed' />}   />
                                                    </ValidateField>
                                                    
                                                </FormGroup>

                                                <FormGroup className="section-inner">
                                                    <Label>Sick Days</Label>
                                                    <ValidateField>
                                                    <InputField  onChange={ this.handleDetailsTextField } id={formFields.sickDays} type="number" 
                                                    name={formFields.sickDays}className="ip-text-default" placeholder="0"
                                                    min={0}
                                                    value={this.props.employeeForm.details.sickDays }/>
                                                     <ErrorFeedback 
                                                        show={this.state.formErrors.sickDaysInvalid}
                                                        warning
                                                        filter='numberInput'
                                                        message={<ErrorMessage message ='Only numbers are allowed' />} />
                                                    </ValidateField>
                                                </FormGroup>
                                                <FormGroup className="section-inner">
                                                    <Label>Vacation Days</Label>
                                                    <ValidateField>
                                                    <InputField  onChange={ this.handleDetailsTextField } id={formFields.vacationDays} type="number" 
                                                    name={formFields.vacationDays}className="ip-text-default" placeholder="0"
                                                    min={0}
                                                    value={this.props.employeeForm.details.vacationDays }/>
                                                     <ErrorFeedback
                                                        show={this.state.formErrors.vacationDaysInvalid}
                                                        warning
                                                        filter='numberInput'
                                                        message={<ErrorMessage message ='Only numbers are allowed' />} />
                                                    </ValidateField>
                                                </FormGroup>
                                                
                                            </div>
                                        </Section>
                                        <Section className="address-info">
                                            <div className="section-head">
                                             <h3>Address</h3>
                                            </div>
                                            { !showAddressFields ?
                                            <div className="add-patient-holder">
                                                <Button className="add-address-btn" onClick={this.addAddressFields}>
                                                    <span className="icon">
                                                        <img src={getIconUrl("plusIcon") }alt=''/>
                                                    </span>
                                                    Add Address
                                                </Button>
                                            </div>
                                            : 
                                            <div className="section-body">
                                                <FormGroup className="section-inner">
                                                    <Label>Street</Label>
                                                    <InputField  onChange={ this.handleAddressTextField } 
                                                    id="street" 
                                                    type="text" name={"street"}
                                                    className="ip-text-default" placeholder="Address Line 1"
                                                    value={this.props.employeeForm.address.street }/>
                                            
                                                </FormGroup>
                                                <FormGroup className="section-inner">
                                                    <Label>Address Line 2</Label>
                                                    <InputField  onChange={ this.handleAddressTextField } 
                                                    id="streetTwo" 
                                                    type="text" name={"streetTwo"}
                                                    className="ip-text-default" placeholder="Address Line 2"
                                                    value={this.props.employeeForm.address.streetTwo }/>
                                            
                                                </FormGroup>
                                                <FormGroup className="section-inner">
                                                    <Label>City</Label>
                                                    <InputField  onChange={ this.handleAddressTextField } 
                                                    id="city" 
                                                    type="text" name={"city"}
                                                    className="ip-text-default" placeholder="City"
                                                    value={this.props.employeeForm.address.city }/>
                                            
                                                </FormGroup>
                                                <FormGroup className="section-inner">
                                                    <Label>Parish | State</Label>
                                                    <InputField  onChange={ this.handleAddressTextField } 
                                                    id="state" 
                                                    type="text" name={"state"}
                                                    className="ip-text-default" placeholder="Parish"
                                                    value={this.props.employeeForm.address.state }/>
                                            
                                                </FormGroup>
                                            </div>
                                        }
                                        </Section>
                                    </Form>
                                    </Col>
                                </Row>    
                        </div>
                    </Col>
                    <Col></Col>
                    
                    </Row>
                </Container>
                </div>
                </div>
                </div>

                { this.state.deleteDialogVisible && 
                    <ConfirmModal 
                    contextName={this.state.deleteContextName} 
                    contextItem={this.state.deleleContextItem} 
                    cancelCallback={this.cancelDelete}
                    confirmCallback={this.onConfirmDelete}
                    />
                }
            </React.Fragment>
        );
    }
}

const CrateUpdateView = (props) => {
    return (
        <CrateUpdate {...props } />
    );
};

const ConfigCrateUpdateUserView = setupPage( CrateUpdate.pageOption )(CrateUpdateView);


export default connect(mapStateToProps, mapDispatchToProps)(ConfigCrateUpdateUserView);