import React, {useState } from 'react';
import { setupPage } from '../../../../components/Layout/setupPage';
import {
    List, 
    Input,
    FormGroup,
    Label,
    FormText,
    Section,
} from '../../../../components';
import * as actionTypes from '../../../../store/actionType';
import text from '../../../../common/strings';
import { REQUESTS_TYPE } from '../../../../common/constant';
import { PromptMessage, getPromptMessageProps } from '../../../components/partials/PromptMessage';

import { getIconUrl } from '../../../../common/images-catalogue';

const Notification = (props) => {

    const { prefsGroupId, preferences, actions, apiRequestManager } = props;
    
    const prefsGroup =  preferences.prefsGroup[prefsGroupId];
    const prefs = prefsGroup.preferences.map((item) => {
        return actions.getPrefValue(item);
    });
    const [fieldSet, setFieldSet ] = useState(prefs);
    const onPrefChange = (evt, pref) => {
        let changedPref = {};

        const changes = fieldSet.map((item) => {
            if (item.prefId === pref.prefId) {
                item.value = item.dataType === 'bool' ? 
                evt.target.checked : evt.target.value;
                changedPref = item;
            }
            return item;
        });
        actions.preferenceWillChange(prefsGroupId, changedPref);
        setFieldSet(changes);
    }

   
    const getPromptMessage = () => {
        const apiOutcome = apiRequestManager.getApiOutcome(actionTypes.SUBMIT_CHANGED_PREFERENCES, true) ?? {};
        return getPromptMessageProps('settings', apiOutcome);
    }

    const dialogProps = getPromptMessage();

    return (
        <React.Fragment>
            { dialogProps.visible && 
                <PromptMessage {...dialogProps} />
            } 
        <div className="notification-pane">
            <Section className="grp-sec">
                <div className="title-wrap">
                    <h4 className="sec-title">{prefsGroup.name}</h4>
                    <a className="edit-tgr">
                        <img src={getIconUrl('edit') } />
                    </a>
                </div>
                <div className="section-cont">

                    { fieldSet.map((pref, i) => { 
                        return (<div key={i} className="field-set" >
                            <div className="label-w">
                                <Label>{pref.label}</Label>
                            </div>
                            <div className="field-w">
                                <div className="switch-group">
                                    <Label className="inline"></Label>
                                    <span className="switch-pill inline">
                                        <Input onChange={ (evt) => onPrefChange(evt, pref) } type="checkbox"
                                         checked={pref.value}
                                         id={pref.prefId} />
                                        <Label for={`${pref.prefId}`} id={pref.prefId}>Off</Label>
                                    </span>
                                </div>
                                <FormText>
                                    {pref.description}
                                </FormText>
                            </div>
                        </div>)
                    })}  

                </div>
            </Section>
        </div>
        </React.Fragment>
    );
}

Notification.defaultProps = {
    disableBodyScroll: true
};

const  NotificationPane = setupPage(Notification.defaultProps)(Notification);
export default NotificationPane;