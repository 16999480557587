import * as actionTypes from '../actionType';
import {action} from './actionCreator';
import {createRequestTypes, REQUEST, SUCCESS, FAILURE} from './../../service/httpRequestDialect';


export const messagesViewLoadedAction =  () => ({
    type: actionTypes.MESSAGES_VIEW_LOADED,
}); 
export const messagesViewUnloadAction =  () => ({
    type: actionTypes.MESSAGES_VIEW_UNLOAD
}); 
export const messageThreadViewLoadedAction =  () => ({
    type: actionTypes.MESSAGE_THREAD_VIEW_LOADED,
}); 
export const messageThreadViewUnloadAction =  () => ({
    type: actionTypes.MESSAGE_THREAD_VIEW_UNLOAD
}); 
export const messagesPreserveStateAction =  (yesOrNo) => ({
    type: actionTypes.MESSAGES_PRESERVE_STATE,
    payload: yesOrNo
}); 
export const sendMessageAction =  () => ({
    type: actionTypes.SEND_MESSAGE,
}); 
export const receivedMessageAction =  (payload) => ({
    type: actionTypes.RECEIVED_MESSAGE,
    payload: payload
}); 
export const getAllMessagesAction =  (payload) => ({
    type: actionTypes.FETCH_MESSAGES,
    payload: payload
});
export const getMessageThreadAction =  (payload) => ({
    type: actionTypes.FETCH_MESSAGE_THREAD,
    payload: payload
});

export const setMessageViewAction =  (payload) => ({
    type: actionTypes.SET_MESSAGE_VIEW,
    payload: payload
});
export const readMessageAction =  (payload) => ({
    type: actionTypes.SEND_MESSAGE_READ,
    payload: payload
});
export const deleteMessageAction =  (payload) => ({
    type: actionTypes.DELETE_MESSAGE,
    payload: payload
});

export const FETCH_MESSAGES = createRequestTypes('FETCH_MESSAGES');

export const unreadMessages = {
    request: () => action(FETCH_MESSAGES[REQUEST]),
    success: (payload) => action(FETCH_MESSAGES[SUCCESS], {payload}),
    failure: (error) => action(FETCH_MESSAGES[FAILURE], {error}),
};