
import * as urls from './urls';

export const SEARCH_SETTINGS = {
    entities: {
        record: {
            name: 'Record',
            target: urls.specimensUpdateUri
        },
        patient: {
            name: 'Patient',
            target:urls.patientIdOverviewUri
        },
        user: {
            name: 'User',
            target: urls.updateUserUri
        },
        employee: {
            name: 'Employee',
            target: urls.employeesUri
        },
        client: {
            name: 'Client',
            target: urls.clientIdOverviewUri
        },
        bill: {
            name: 'Bill',
            target: urls.updateBillUri
        },
        requisition: {
            name: 'Requisition',
            target: null
        },
    },
    defaultContext: 'Record'
}