
import {ofType } from 'redux-observable';
import { catchError, map, switchMap, mergeMap, takeUntil } from 'rxjs/operators';
import *  as actionTypes from '../actionType';
import { settingsDialect, checkReponse } from '../../service/agent';
import { of } from 'rxjs';
import * as actions from '../actions';
import {apiResponseActionCreator} from '../actions';
import { REQUESTS_TYPE } from '../../common/constant';


export const getFormItemGroupEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.FETCH_FORM_ITEM_GROUP),
        switchMap((action) => {
            return settingsDialect.fetchFeatureGroup().pipe(
                map(response => {
                    const responseData = checkReponse(response, action.type);
                    return  actions.apiResponseActionCreator(actionTypes.FETCHED_FORM_ITEM_GROUP_RESULTS, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.FETCH_FORM_ITEM_GROUP + actionTypes.ABORT_API_REQUEST))),
                catchError(error => {
                    console.log("ERROR", error);
                    return of( actions.apiResponseActionCreator(actionTypes.FETCHED_FORM_ITEM_GROUP_RESULTS, error));
               })
            );
        })
    )
}

export const submitFormGroupEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.FORM_GROUP_SUBMIT_DATA),
        
        mergeMap((action) => {
          const apiCalls = {
            'delete': settingsDialect.deleteFromGroup,
            'update': settingsDialect.updateFromGroup,
            'create': settingsDialect.createFromGroup
          }
            return apiCalls[action.payload.requestType](action.payload).pipe(
                map(response => {
                    const responseData = checkReponse(response, action.type, action.payload.requestType);
                    return apiResponseActionCreator(actionTypes.FORM_GROUP_SUBMITED_RESULTS, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.FORM_GROUP_SUBMIT_DATA + actionTypes.ABORT_API_REQUEST))),
                catchError(error => {
                    console.log("ERROR", error);
                    return of(apiResponseActionCreator(actionTypes.FORM_GROUP_SUBMITED_RESULTS, error));
               })
            );
        })
  )};

export const getAppPreferencesEpic = action$ => {
return action$.pipe(
    ofType(actionTypes.FETCH_APP_PREFERENCES),
    switchMap((action) => {
        return settingsDialect.fetchAppPreferences().pipe(
            map(response => {
                const responseData = checkReponse(response, action.type);
                return  actions.apiResponseActionCreator(actionTypes.FETCHED_APP_PREFERENCES_RESULTS, responseData);
            }),
            catchError(error => {
                console.log("ERROR", error);
                return of( actions.apiResponseActionCreator(actionTypes.FETCHED_APP_PREFERENCES_RESULTS, error));
            })
        );
    })
)
}

export const submitChangePreferencesEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.SUBMIT_CHANGED_PREFERENCES),
        switchMap((action) => {
            return settingsDialect.submitChangePreferences(action.payload).pipe(
                map(response => {
                    const responseData = checkReponse(response, action.type, action.payload.requestType);
                    return  actions.apiResponseActionCreator(actionTypes.SUBMIT_CHANGED_PREFERENCES_RESULT, responseData);
                }),
                catchError(error => {
                    console.log("ERROR", error);
                    return of( actions.apiResponseActionCreator(actionTypes.SUBMIT_CHANGED_PREFERENCES_RESULT, error));
                })
            );
        })
    )
    }


export const fetchPrintGroupsEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.FETCH_PRINT_GROUPS),

        mergeMap((action) => {
            return settingsDialect.fetchPrintGroups(action.payload).pipe(
                map(response => {
                    const responseData = checkReponse(response, action.type, action.payload.requestType);
                    return actions.apiResponseActionCreator(actionTypes.FETCHED_PRINT_GROUPS_RESULTS, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.FETCH_PRINT_GROUPS + actionTypes.ABORT_API_REQUEST))),
                catchError(error => {
                    console.log("ERROR", error);
                    return of(actions.apiResponseActionCreator(actionTypes.FETCHED_PRINT_GROUPS_RESULTS, error));
               })
            );
        })
    )
}

export const submitPrintGroupEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.PRINT_GROUP_SUBMIT_DATA),

        mergeMap(
            (action) => {
                const apiCalls = {
                    [REQUESTS_TYPE.CREATE]: settingsDialect.createPrintGroup,
                    [REQUESTS_TYPE.UPDATE]: settingsDialect.updatePrintGroup,
                    [REQUESTS_TYPE.DELETE]: settingsDialect.deletePrintGroup,
                }

                return apiCalls[action.payload.requestType](action.payload.data).pipe(
                    map(response=> {
                        const responseData = checkReponse(response, action.type, action.payload.requestType);
                        return actions.apiResponseActionCreator(actionTypes.PRINT_GROUP_SUBMIT_RESULTS, responseData);
                    }),
                    takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
                    catchError((error) => {
                        console.log('ERROR', error);
                        
                        return of(actions.apiResponseActionCreator(actionTypes.PRINT_GROUP_SUBMIT_RESULTS, error));
                    })
                )
            }
        )
    )
}