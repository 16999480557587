import { take, put, call, fork, select, all, takeLatest} from 'redux-saga/effects';
import *  as actionTypes from '../actionType';
import { patientDialect, specimenDialect, checkReponse , apiOutcome} from '../../service/agent';
import { patient } from '../actions/patient';
import { formDetails } from '../actions/specimen';

import { denormalizeResponse, setMetaData } from './../../service/httpRequestDialect';
import { formDetailsSchema, patientsArraySchema } from '../../service/schemas';
import ApiError from '../../utility/error/ApiError';


// each entity defines 3 creators { request, success, failure }
const patientsActions = patient;
const formDetailsActions = formDetails;

/**
 *  WORKERS 
 * --------------------------------------------------------------
 */

// reusable fetch Subroutine
// entity :  user | repo | starred | stargazers
// apiFn  : api.fetchUser | api.fetchRepo | ...
// id     : login | fullName
// url    : next page url. If not provided will use pass id to apiFn
function* fetchEntity(entity, apiFunc, payload) {
    yield put( entity.request() );
    const { response, error } = yield call(apiFunc, payload);
    return {response, error};
}

// yeah! we can also bind Generators
const doFetchPatients = fetchEntity.bind(null, patientsActions, patientDialect.getAllPatients);
const doFetchFormDetails = fetchEntity.bind(null, formDetailsActions, specimenDialect.getFromDetails);

// Fetches data for a User : user data + starred repos
function* parallelFetchPatientFormDetails(action) {

    // correct, effects will get executed in parallel
    setMetaData({ initiator:  action.type });
 
    const formDetails = yield call(doFetchFormDetails, action.payload.form);

    if (!formDetails.error) {
        let result = denormalizeResponse(formDetails.response.result, formDetailsSchema, formDetails.response.entities);

        yield put({type: actionTypes.FETCHED_FORM_DETAILS_RESULTS, payload : {
            formDetails:  {
                ...result,
                specimenTypes: formDetails.response.result.specimenTypes
            }
        }});
    }
        
    if (formDetails.error) 
        yield put({type: actionTypes.FETCHED_FORM_DETAILS_RESULTS, payload : {
           
            formDetails: formDetails.error || new ApiError()
        }});
        //yield put(patientsActions?.failure(patients.error));
        //yield put(formDetailsActions?.failure(formDetails.error));
    
    if (formDetails.response) {
        ///yield call(doFetchRoles, action.payload);
        //console.log(denormalizeResponse(formDetails.response.result, formDetailsSchema, formDetails.response.entities));
        //console.log(formDetails)
   
        let result = denormalizeResponse(formDetails.response.result, formDetailsSchema, formDetails.response.entities);

        yield put({type: actionTypes.FETCHED_FORM_DETAILS_RESULTS, payload : {
            formDetails: {
                ...result,
                specimenTypes: formDetails.response.result.specimenTypes
            }
        }});
    }
}

function* watchPatientFormDetails() {
    while(true) {
        const action = yield take(actionTypes.FETCH_FORM_DETAILS);
        yield fork(parallelFetchPatientFormDetails, action);
    }
}

export default function* root() {
    yield all([
        fork(watchPatientFormDetails)
    ]);
}