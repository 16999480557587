import React from 'react';
import {
    SimpleSelect,
    FormGroup,
    Input,
    Label
} from './../../../components';

import { STATUS_MAP, FORM_TYPE } from './../../../common/constant';

const Filter_RP01 = (props) => {

    const { reportForm, actionType, reportFieldChange, labCodes } = props;

    const didSelectStatus = (status) => {
        const fieldSet = {
            options: {
                ...reportForm.options,
                status: status.value
            }
        }
        reportFieldChange(actionType, fieldSet);
    }
    const didSelectLabcode = (labcode) => {

        const fieldSet = {
            options: {
                ...reportForm.options,
                labCode: labcode.value
            }
        }
        reportFieldChange(actionType, fieldSet);
    }
    const handleToggleSwitches = (evt) => {

        let fieldSet = {
            options: {
                ...reportForm.options,
                urgent: evt.target.checked
            }
        }
        if (!fieldSet.options.urgent) {
            const {urgent, ...otherOptions } = fieldSet.options;
            fieldSet.options = otherOptions;
        }
        reportFieldChange(actionType, fieldSet);
    }

    const handlePillSwitches = (evt) => {
        const fieldSet = {
            options: {
                ...reportForm.options,
                formType: evt.target.value
            }
        }
        reportFieldChange(actionType, fieldSet);
    }
    const labcodes = labCodes.map((labcode, i) => ({
        value: labcode.id,
        label: labcode.code
    }));

    const statusList = Object.keys(STATUS_MAP);
    
    const statuses = statusList.map((status, i) => ({
            value: status,
            label: status
        }));
    
    const formType = reportForm.options.formType !== null ? reportForm.options.formType: null;
    const toggleValue = reportForm.options.urgent !== null ? reportForm.options.urgent: false;
    
    const selectedLabCode =  !reportForm.options.labCode ? null : labcodes.find((labCode) => labCode.value === reportForm.options.labCode);

    const selectedStatus =  !reportForm.options.status ? null : statuses.find((item) => item.value === reportForm.options.status);

    return <div className="opts-wrap">
        <FormGroup>
            <Label>Labcode</Label>
                <SimpleSelect
                id={'labCode'}
                className="ip-sml-select"
                name="labCode"
                title="From Labcode"
                list={labcodes}
                deselect={true}
                initialSelected={selectedLabCode}
                onChange={didSelectLabcode}
                />
        </FormGroup>
        <FormGroup>
            <Label>Status</Label>
                <SimpleSelect
                id={'status'}
                className="ip-sml-select"
                name="status"
                title="With Status"
                list={statuses}
                onChange={didSelectStatus}
                initialSelected={selectedStatus}
                />
        </FormGroup>
        <FormGroup className="hdn">
            <Label>Form Type</Label>
            <div className="no-warp">
            <Label className="ip-radio-pill">
            <Input 
             onChange={handlePillSwitches } 
             type="radio"
             name="formType" value={FORM_TYPE.Gynec} 
             checked={formType && formType === FORM_TYPE.Gynec ? true : false}/>{' '}
                <span className="radioselect"></span>
                <span className="text">Gynec</span>
            </Label>

            <Label className="ip-radio-pill">
            <Input onChange={handlePillSwitches } 
            type="radio" name="formType" 
            value={FORM_TYPE.NonGynec}
            checked={formType && formType === FORM_TYPE.NonGynec ? true : false}
             />{' '}
                <span className="radioselect"></span>
                <span className="text">NGynec</span>
            </Label>
            </div>
        </FormGroup>
        <FormGroup>
                <Label>Submitted</Label>
                <div>
                <label className="ip-checkbox">Urgent?
                    <Input 
                        onChange={handleToggleSwitches }
                        id="fixedSalary"
                        value={toggleValue}
                        type="checkbox" 
                        checked={toggleValue}
                        />
                        <span className="checkmark round"></span>
                </label>
                </div>
        </FormGroup>
    </div>
}

export default Filter_RP01;