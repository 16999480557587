import { requests } from './agentDialect';

export const fetchAllDepartments = () => (
    requests.get(`/departments`) 
);

export const submitDepartments = (payload) => (
    requests.post(`/departments`, payload.data)
)

export const deleteDepartment = (payload) => (
    requests.delete(`/department/delete/${payload.departmentId}`, payload)
)


