import React from 'react';

import {
    Container,
    Row,
    Col,
    Section
} from '../../../components';

const EarningRowItem = (props) => {
    const {data} = props;
    const description = Object.blank(data)? '' : data.desc.toUpperCase();
    const unit = Object.blank(data)? '' : data.unit <= 0 ? '-' : data.unit.toCurrency();
    const rate = Object.blank(data)? '' : data.rate <= 0 ? '-' : data.rate + ' hrs';
    const amount = Object.blank(data)? '' :  data.amount <= 0 ? '-' : '$' + data.amount.toCurrency() ; 

    return (
        <tr>
            <td className="head-desc left-text"><a>{description}</a></td>
            <td className="head-unit">{unit}</td>
            <td className="head-rate">{rate}</td>
            <td className="head-amount">{amount}</td>
        </tr>
    );
}

const DeductionRowItem = (props) => {
    const {data} = props;
    const description = Object.blank(data)? '' : data.desc.toUpperCase();
    const amount = Object.blank(data)? '' :  data.amount <= 0 ? '-' : '$' + data.amount.toCurrency();
    const percent = Object.blank(data)? '' :  data.percent <= 0 ? '-' : data.percent.toFixed(1) + '%'; 
    return (
        <tr>
            <td className="head-desc left-text"><a>{description}</a></td>
            <td className="head-percent">{percent}</td>
            <td className="head-amount">{amount}</td>
        </tr>
    );
}

const PrintablePayslip = (props) => {
    const { payAdvice } = props;
    const employeeName = payAdvice.employeeInfo?.firstName + " " + payAdvice.employeeInfo?.lastName;
    
    const rowsCount = payAdvice.earnings?.length > payAdvice.deductions?.length ? payAdvice.earnings?.length : payAdvice.deductions?.length;
    
    const earningsData = [];
    const deductionsData = [];
    let totalDeductions = 0.0;
    let totalEarnings = 0.0;

    for( var i = 0; i < rowsCount; i++ ) {
        if (payAdvice.earnings[i] === undefined) {
            earningsData.push({});
        }else {
            earningsData.push(payAdvice.earnings[i]);
            totalEarnings += payAdvice.earnings[i].amount;
        }

        if (payAdvice.deductions[i] === undefined) {
            deductionsData.push({});
        }else {
            deductionsData.push(payAdvice.deductions[i]);
            totalDeductions += payAdvice.deductions[i].amount;
        }
    }
  
    return (  
            <div className="payslip-wrap">
                <h3 className="employee-name"> </h3>
                <div className="payslip">
                    <div className="structure">
                        <Section className="compy-sec">
                            <div className="col-split c-1">
                                <div className="item-grp">
                                    <span className="label">Company Name :</span>
                                    <span className="value">Cytologylab Ltd.</span>
                                </div>
                            </div>
                            <div className="col-split c-2">
                                <div className="item-grp">
                                    <span className="label">Department :</span>
                                    <span className="value">{payAdvice.employeeInfo.department.name}</span>
                                </div>
                            </div>
                            <div className="col-split c-3">
                                <div className="item-grp">
                                    <span className="label">Date :</span>
                                    <span className="value">{payAdvice.payrollDate}</span>
                                </div>
                            </div>
                        </Section>
                        <Section className="emple-sec">
                            <div className="col-split c-1">
                                <div className="item-grp">
                                    <span className="label">Employee :</span>
                                    <span className="value">{employeeName}</span>
                                </div>
                            </div>
                            <div className="col-split c-2">
                                <div className="item-grp">
                                    <span className="label">Employee ID :</span>
                                    <span className="value">{payAdvice.employeeInfo.employeeNo}</span>
                                </div>
                            </div>
                            <div className="col-split c-3">
                                <div className="item-grp">
                                    <span className="label">NIS :</span>
                                    <span className="value">{payAdvice.employeeInfo.details.nis}</span>
                                </div>
                            </div>
                            <div className="col-split c-4">
                                <div className="item-grp">
                                    <span className="label">TRN :</span>
                                    <span className="value">{payAdvice.employeeInfo.details.trn}</span>
                                </div>
                            </div>
                        </Section>
                        <Section className="erngs-deduc-sec">
                            <div className="earnings">
                                <table className="earnings-tbl">
                                    <tbody>
                                        <tr>
                                            <th className="table-heading" colSpan={4}>Earnings</th>
                                        </tr>
                                        <tr className="headings">
                                            <th className="left-text">Description</th>
                                            <th>Units</th>
                                            <th>Rate</th>
                                            <th>Amount</th>
                                        </tr>
                                        {earningsData.map((data, i) => {
                                            return <EarningRowItem key={i} data={data}/>
                                        })}
                                        
                                        <tr className="blank">
                                            <td className="head-desc left-text"></td>
                                            <td className="head-percent"></td>
                                            <td className="head-amount"></td>
                                            <td className="head-amount"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="deductions">
                                <table className="deductions-tbl">
                                    <tbody>
                                        <tr>
                                            <th className="table-heading" colSpan={4}>Deductions</th>
                                        </tr>
                                        <tr className="headings">
                                            <th className="left-text">Description</th>
                                            <th>Percent</th>
                                            <th>Amount</th>
                                        </tr>
                                        
                                        {deductionsData.map((data, i) => {
                                            return <DeductionRowItem key={i} data={data}/>
                                        })}
                                        
                                        <tr className="blank">
                                            <td className="head-desc left-text"></td>
                                            <td className="head-percent"></td>
                                            <td className="head-amount"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Section>
                        <Section className="totals-sec">
                            <div className="inner">
                                <div className="split earnings-totals">
                                    <span className="label">Gross Earnings</span>
                                    <span className="amount">${totalEarnings.toCurrency()}</span>
                                </div>
                                <div className="split deductions-totals">
                                    <span className="label">Total Deductions</span>
                                    <span className="amount">${totalDeductions.toCurrency()}</span>
                                </div>
                            </div>
                        </Section>
                        <Section className="net-pay-sec">
                            <div className="inner">
                                <div className="split netpay-label">
                                    <span className="label">NET PAY</span>
                                </div>
                                <div className="split netpay-amount">
                                    <span className="amount">${payAdvice.netPay.toCurrency()}</span>
                                </div>
                            </div>
                        </Section>
                        <Section className="yr-to-dte-sec">
                            <div className="payslip-summary">
                            <div className="yr-to-date-col">
                                    <span className="label">YEAR TO DATE</span>
                                </div>
                                <div className="smry-table-col">
                                <table className="summary-tbl">
                                    <tbody>
                                        <tr>
                                            <td className=" left-center">
                                                <label>Gross Taxable Income</label>
                                                <p>${payAdvice.grossIncomeCumulate.toCurrency()}</p>
                                            </td>
                                            <td className=" left-center">
                                                <label>Income Tax</label>
                                                <p>${payAdvice.payeCumulate.toCurrency()}</p>
                                            </td>
                                            <td className=" left-center">
                                                <label>NIS</label>
                                                <p>${payAdvice.nisCumulate.toCurrency()}</p>
                                            </td>
                                            <td className=" left-center">
                                                <label>Loan Balance</label>
                                                <p>$0.0</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className=" left-center">
                                                <label>N.H.T</label>
                                                <p>${payAdvice.nhtCumulate.toCurrency()}</p>
                                            </td>
                                            <td className=" left-center">
                                                <label>Education Tax</label>
                                                <p>${payAdvice.edutaxCumulate.toCurrency()}</p>
                                            </td>
                                            <td className=" left-center">
                                                <label>Pension</label>
                                                <p>${payAdvice.pensionCumulate.toCurrency()}</p>
                                            </td>
                                            <td className=" left-center">
                                                <p>${payAdvice.netPay.toCurrency()}</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </Section>
                    </div>
                </div>
            </div>);
}

export default PrintablePayslip;