import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const logos = {
    virtial: require('./../../../assets/images/logo.svg'),
    dashboardLogo: require('./../../../assets/images/dashboard-logo.svg'),
};

const getLogoUrl = (style, colour) => {
    return logos[style].default;
}

const LogoThemed = (props) => (
    <img
        src={ getLogoUrl(props.style, props.colour) }
            className={ classNames('d-block', props.className) }
            alt="App Logo"
            min-height="50px"
            //{ ...otherProps }
    /> 
);

LogoThemed.propTypes = {
    style: PropTypes.string,
    className: PropTypes.string,
};

export { LogoThemed };