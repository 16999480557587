import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Navbar as BootstrapNavbar, Container } from 'reactstrap';

const Navbar = ({ className, shadow, themed, colour, children, ...otherProps }) => {

    let navbarClass = classNames({
        'navbar-themed': themed || !!colour,
        'navbar-shadow': shadow,
    }, 'navbar-multi-collapse', className);

    return (
        <BootstrapNavbar className={ navbarClass }>
            {
                <Container className="navbar-wrap" >
                    { children }
                </Container>
            }
        </BootstrapNavbar>
    );
}

Navbar.propTypes = {
    themed: PropTypes.bool,
    fluid: PropTypes.bool,
    shadow: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
    color: PropTypes.string,
    dark: PropTypes.bool,
    light: PropTypes.bool
}

Navbar.defaultProps = {
    fluid: false,
    themed: false,
}

export { Navbar };