import React, { useCallback } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setupPage } from '../../../components/Layout/setupPage';
import PropType from 'prop-types';
import { 
    ThemeConsumer,
    Nav,
    NavItem,
    NavLink,
    SpecimenTable,
} from '../../../components';

import  * as actions  from '../../../store/actions';
import * as actionTypes from '../../../store/actionType'
import { Authority } from '../../../components';
import {SEARCH_SETTINGS } from '../../../config/searchable';
import './FileCabinet.style.scss';
import { getIconUrl } from '../../../common/images-catalogue';
import { ALPHABETICALLY } from '../../../common/constant';
import { PAGE_SIZE } from '../../../config/pagination';
const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
    getSpecimensForCabinet: (cabinetId) => dispatch(actions.getSpecimensForCabinetAction(cabinetId)),
    clearSpecimentData: () => dispatch(actions.clearSpecimentDataAction()),
});

class FileCabinet extends React.Component {

    static pageOption = {
        pageTitle: 'Cabinet',
        searchContext: SEARCH_SETTINGS.entities.record.name,
    };

    alphabetically = [
        ...ALPHABETICALLY
    ];

    constructor(props){
        super(props);

        this.state = {
            currentCabinetId: -1,
            activeTab: '...',
            sortedSpecimensData: null,
            currentPage: 1,
            pageSize: PAGE_SIZE
        }
            
        this.tabSelected = this.tabSelected.bind(this);
        this.onTableChange = this.onTableChange.bind(this);
    }

    componentWillUnmount() {
        this.props.apiRequestManager.abortRequest(actionTypes.FETCH_SPECIMENS_FOR_CABINET);
        this.props.clearSpecimentData();
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        if (prevState.currentCabinetId !== nextProps.selectedCabinetId) {
            nextProps.apiRequestManager.abortRequest(actionTypes.FETCH_SPECIMENS_FOR_CABINET);
        }
        
        if(!nextProps.apiRequestManager.inProgress(actionTypes.FETCH_SPECIMENS_FOR_CABINET) && prevState.currentCabinetId !== nextProps.selectedCabinetId){
            
            nextProps.clearSpecimentData();
            
            const payload = {
                cabinetId: nextProps.selectedCabinetId,
                filterLetter: nextProps.activeTab,
                page: prevState.currentPage > 0 ? prevState.currentPage - 1 : prevState.currentPage
            };

            nextProps.apiRequestManager.queueRequest(actionTypes.FETCH_SPECIMENS_FOR_CABINET, payload);

            return {
                currentCabinetId: nextProps.selectedCabinetId,
                sortedSpecimensData: null,
                activeTab: '...',
                currentPage: 1
            }
        }

        return null;
    }

    static sortSpecimensData (records) {
        records.sort((function(a, b){
            var nameA = a.patient.lastName.toLowerCase(), nameB = b.patient.lastName.toLowerCase();
            if (nameA < nameB) //sort string ascending
             return -1;
            if (nameA > nameB)
             return 1;
            return 0; //default return value (no sorting)
        }));
        return records;
    }
    
    tabSelected(tabId) {
        
        const payload = {
            cabinetId: this.props.selectedCabinetId,
            filterLetter: tabId !== '...' ? tabId : null,
            page: 0
        };

        this.props.apiRequestManager.queueRequest(actionTypes.FETCH_SPECIMENS_FOR_CABINET, payload);

        this.setState({
            activeTab: tabId,
            sortedSpecimensData: null,
            currentPage: 1
        });
    }

    onTableChange (tableOpt) {

        const pageNumber = tableOpt.page - 1;
        const pageSize = tableOpt.sizePerPage;

        const payload = {
            cabinetId: this.props.selectedCabinetId,
            filterLetter: this.state.activeTab !== '...' ? this.state.activeTab : null,
            page: pageNumber,
            pageSize: pageSize
        };

        this.props.apiRequestManager.queueRequest(actionTypes.FETCH_SPECIMENS_FOR_CABINET, payload);

        this.setState({
            currentPage: pageNumber,
            pageSize: pageSize
        });

    }

    render () {

        const cabinetSpecimens = this.state.sortedSpecimensData ? this.state.sortedSpecimensData :  this.props.cabinetSpecimens ? FileCabinet.sortSpecimensData(this.props.cabinetSpecimens) : [];

        const identifier = this.props.identifier || '';
        const color = this.props.color || '';

        const tableMetaData = {
            ...this.props.cabinetsTableMeta,
            pageSize: this.state.pageSize
        };

        return (
            <React.Fragment>
        
                <div className="spmt-Ly-ct-inner cabinet-lyr">
                    <div className="spmt-title-d">
                        <h3 className="spmt-title" style={{
                            color: color
                        }}>Cabinet <span className='cabinet-identifier'>{identifier}</span></h3>
                    </div>
                    <div className='tabs-lyr'>
                        <div className='tabs-nav-warp'>
                            <Nav tabs>
                                {this.alphabetically.map((letter, i) => 
                                (<NavItem key={i}>
                                    <NavLink
                                        className={classnames({ active: this.state.activeTab === letter })}
                                        onClick={() => { this.tabSelected(letter); }}
                                    >
                                        {letter}
                                    </NavLink>
                                </NavItem>))
                                }
                            </Nav>
                        </div>
                    </div>
                    <div className='specimens-records-wrap'>
                        
                        <div className='table'>
                            <SpecimenTable 
                            hoverOverRowAction={this.props.willHandleTableRowMouseOver}
                            quickActions={this.props.quickActionCallback} 
                            tableData={cabinetSpecimens}
                            pagable={true}
                            onTableChange={this.onTableChange}
                            metaData={tableMetaData}
                            />
                        </div>
                    </div>
                </div>
        
            </React.Fragment>
        );
    }
}

const FileCabinetView = (props) => {
    return (
        <FileCabinet {...props } />
    );
};

let ConfigFileCabinetView = setupPage(FileCabinet.pageOption)(FileCabinetView);
ConfigFileCabinetView =  connect(mapStateToProps, mapDispatchToProps)(ConfigFileCabinetView);

export default ConfigFileCabinetView ;

export const CabinetItem = (props) => {
    const {label, color, identifier, id, selectedCabinetId, onEdit} = props;

    const willClick = useCallback((event) => {
        props.onClick(event, {identifier, id, color})
    }, [props, color, id, identifier]);

    return (
        <NavItem className='cabinet-item-wrapper'>
            <NavLink to={"filecabinet"} onClick={willClick} 
            className={classnames({ active: selectedCabinetId === id })}>
                <span className="icon">
                <CabinetIcon color={color} />
                </span>{label}
            </NavLink>
            <Authority allowed='change' entity='cabinet'>
            <div className="action-item cabinet-item--edit">
                <NavLink onClick={ (event) => onEdit(event, id) }>
                    <span className="icon"><img className="img" src={getIconUrl("edit")} alt=''/></span>
                </NavLink>
            </div>
            </Authority>
        </NavItem>
    )
}

const cabinetItemPropTypes = {
    label: PropType.string.isRequired,
    color: PropType.string.isRequired,
    identifier: PropType.string.isRequired,
    selectedCabinetId: PropType.number,
    onClick: PropType.func.isRequired,
    onEdit: PropType.func.isRequired,
    id: PropType.number.isRequired
}

const cabinetItemDefaults = {
    id: -1,
    label: 'Cabinet Name',
    identifier: '',
    color: '#000000',
}

CabinetItem.propTypes = cabinetItemPropTypes
CabinetItem.defaultProps = cabinetItemDefaults

export const CabinetIcon = (props) => {

    return (
        <svg viewBox="0 0 100 76.94" fill={props.color}><path d="M98.46,21.2A10.36,10.36,0,0,0,87.8,10.75q-17.23,0-34.48,0A5.93,5.93,0,0,1,49,9.08c-1.62-1.56-3.32-3-5-4.59a10.69,10.69,0,0,0-7.59-3c-8.17,0-16.33,0-24.5,0A10.45,10.45,0,0,0,1.25,12.27q0,26.42,0,52.83a11.75,11.75,0,0,0,.19,2.14,10.45,10.45,0,0,0,10.44,8.54H49.83q19,0,38.08,0a10.42,10.42,0,0,0,10.56-10.5Q98.5,43.23,98.46,21.2Z"/></svg>
    ); 
    /* return (<svg viewBox="0 0 76.1 65.4" fill={props.color}>
    <g>
    <rect x="32.6" y="32.6" width="10.6" height="7.1"/>
    <path d="M63.7,15V8.4h-4.4V3h-24l-2.2-2.4H16l0.1,4.8l-4.2,0.1c0,0,0,9.5,0.1,9.6l-4.3,6.3l-0.1,43H68l0.1-43.1L63.7,15z M18.3,2.6
       h14.1L34.5,5h22.3v3.4H34.4l-2.7-3.1H18.3C18.3,5.4,18.3,2.8,18.3,2.6z M46.2,51c0.1,1-0.8,1.7-1.8,1.7c-2.2,0-4.3,0-6.5,0h-6.3
       c-1,0.1-1.9-0.6-2-1.6c0-1,0.9-1.7,1.8-1.7h0.1c4.3,0,8.6,0,12.9,0c0.9-0.1,1.7,0.5,1.8,1.4C46.2,50.9,46.2,51,46.2,51z M30.1,42.3
       V30h15.8v12.3H30.1z M14,21.7c0,0,0.1-14.1-0.1-14h17.2l2.5,2.7h27.8v11.3H14z"/>
    </g>
    </svg>)*/
}

const IconPropTypes = {
    color: PropType.string.isRequired,
}

CabinetIcon.propTypes = IconPropTypes

export const ColorSwatch = (props) => {
    let id = props.id.replace('#', '');

    return (<li 
        onClick={() => props.onClick(id, props.color)}
    className={`clr-swatch ${props.selected ? '--selected' : ''}`}>
        <span className='color' 
        style={{'backgroundColor': props.color}}></span>
    </li>)
}

const swatchPropTypes = {
    color: PropType.string.isRequired,
    id: PropType.string.isRequired,
    selected: PropType.bool,
    onClick: PropType.func.isRequired
}

ColorSwatch.propTypes = swatchPropTypes