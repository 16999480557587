import React from 'react';
import PropTypes from 'prop-types';

import { LogoThemed } from './ThemeLogo';

const ResultSheetHead = (props) => {

    return (
        <div className="rslt-sht-brand">
            <span className="logo-hldr">
                <LogoThemed  className="logo" style="dashboardLogo" height="90" />
            </span> 
            <h1 className="view-title">
                { props.title }
            </h1>
        </div>
    )
};

ResultSheetHead.propTypes = {
    title: PropTypes.string.isRequired,
};

export { ResultSheetHead };