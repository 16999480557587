import React from 'react';
import NotificationBadge from './NotificationBadge';
import { useHistory } from 'react-router-dom';
import * as urls from './../../../config/urls';
import NotificationTray from '../partials/NotificationTray';
import { getIconUrl } from '../../../common/images-catalogue';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from '../../../components';
import { PROFILE_PANES } from '../../../common/constant';

const NotificationBlock = (props) => {
  const { currentUser } = props;
  const name = currentUser
    ? `${currentUser.firstName} ${currentUser.lastName}`
    : 'User Name';
  const username = currentUser ? currentUser.username : 'username';

  const history = useHistory();

  const didClickShowMessage = (evt) => {
    history.push(urls.messagesUri);
  };

  const unreadMessages = Array.isArray(props.unreadMessages)
    ? props.unreadMessages.length
    : 0;
  const notifications = props.notifications;
  const dismiss = props.actions.dismissNotification;
  const userPicture =
    props.currentUser && props.currentUser.picture
      ? props.currentUser.picture.fileDownloadUri
      : getIconUrl('profile');
  const navigateToProfileSettings = props.actions.willShowProfileSettings;

  return (
    <div className='notification-block'>
      <UncontrolledDropdown>
        <DropdownToggle className='drop-down-trigger'>
          <div className='user-detail'>
            <span className='proifle-picture'>
              <img src={userPicture} className='image' alt='' />
            </span>
            <span className='user-name'>{name}</span>
          </div>
        </DropdownToggle>
        <DropdownMenu className='profile-menu'>
          <div className='user-detail drop-down'>
            <span className='proifle-picture'>
              <img src={userPicture} className='image' alt='' />
            </span>
            <span className='user-name'>{name}</span>
            <span className='user-name__sub-text'>user: {username}</span>
          </div>
          <DropdownItem
            className='profile__options'
            onClick={() =>
              navigateToProfileSettings(PROFILE_PANES.personalInfo)
            }
          >
            <span className='icon'>
              <img src={getIconUrl('profileSettings')} alt='' />
            </span>
            Profile Settings
          </DropdownItem>
          <DropdownItem
            className='profile__options'
            onClick={() => navigateToProfileSettings(PROFILE_PANES.security)}
          >
            <span className='icon'>
              <img src={getIconUrl('changePassword')} alt='' />
            </span>
            Change Password
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>

      <div className='notifiers-group'>
        <NotificationTray data={notifications} onDismiss={dismiss} />

        <button
          onClick={didClickShowMessage}
          className='icon-button message-btn'
        >
          <img src={getIconUrl('message')} alt='' />
          <NotificationBadge count={unreadMessages} />
        </button>
      </div>
    </div>
  );
};

export default NotificationBlock;
