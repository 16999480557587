import * as actionTypes from '../actionType';


export const reportsViewLoadedAction =  () => ({
    type: actionTypes.REPORTS_VIEW_LOADED,
}); 
export const reportsViewUnloadAction =  () => ({
    type: actionTypes.REPORTS_VIEW_UNLOAD
}); 

export const reportingViewLoadedAction =  () => ({
    type: actionTypes.REPORTING_VIEW_LOADED,
}); 
export const reportingViewUnloadAction =  () => ({
    type: actionTypes.REPORTING_VIEW_UNLOAD
}); 

export const setReportItemAction =  (reportItem) => ({
    type: actionTypes.SET_REPORT_ITEM,
    payload: reportItem
}); 

export const reportFieldAction =  (type, fieldSet) => ({
    type: type,
    payload: fieldSet
}); 

export const runReportAction =  (payload) => ({
    type: actionTypes.RUN_REPORT,
    payload: payload
}); 
export const reportFilterResetAction =  (payload) => ({
    type: actionTypes.REPORT_FILTER_RESET,
    payload: payload
}); 
export const getReportsOverviewSummaryAction =  (workspace) => ({
    type: actionTypes.FETCH_REPORTS_SUMMARY,
    payload: workspace
}); 
