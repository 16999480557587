import { requests } from './agentDialect';

export const fetchAllEmployees = (data) => {
    let uri = Object.blank(data) ? `/employees` : `/employees/?employed=${data.terminated}`;
    return requests.get(uri);
};
export const fetchEmployee = (employeeId) => {
    let uri = `/employee/${employeeId}`;
    return requests.get(uri);
};
export const submitEmployee = (data) => {
    let uri = "/employee";
    return requests.formPost(uri, data.form);
};
export const updateEmployee = (data) => {
    let uri = `/employee/update/${data.employeeId}`;
    return requests.formPut(uri, data.form);
};
export const fetchEmployeePayAdvice = (employeeId) => {
    let uri = `/employee/payadvice/${employeeId}`;
    return requests.get(uri);
};
export const deleteEmployee = (data) => {
    let uri = `/employee/delete/${data.employeeId}`;
    return requests.delete(uri, data);
};
export const terminateEmployee = (employeeId) => {
    let uri = `/employee/terminate/${employeeId}`;
    return requests.put(uri);
}