import React from 'react';
import PropTypes from 'prop-types';

import { 
    Progress,
    InputGroupAddon,
    InputGroupText,
    Input,
    InputField
} from './../../../../components';

const MetricVsTarget = (props) => (
    <React.Fragment>
        <h2 className="pt-4 pb-2">
            { props.value }
        </h2>
        <Progress value={ `${ props.progressbarValue }` } striped={props.striped} color={ `${ props.progressbarColor }` } className="mb-2" style={{height: "5px"}} />
    </React.Fragment>
)
MetricVsTarget.propTypes = {
    title: PropTypes.node,
    value: PropTypes.node,
    progressbarValue: PropTypes.number,
    progressbarColor: PropTypes.node,
    targetValue: PropTypes.node
};
MetricVsTarget.defaultProps = {
    title: "Title",
    value: "000.000",
    progressbarValue: 24,
    progressbarColor: "secondary",
    targetValue: "000.000"
};


export { MetricVsTarget };
