import * as actionTypes from '../actionType';

export const accountsViewLoadedAction =  () => ({
    type: actionTypes.ACCOUNTS_VIEW_LOADED,
}); 
export const accountsViewUnloadAction =  () => ({
    type: actionTypes.ACCOUNTS_VIEW_UNLOAD
}); 
export const getAllAccountsAction =  () => ({
    type: actionTypes.FETCH_ACCOUNTS,
});
export const getAccountByOwnerAction =  (owner) => ({
    type: actionTypes.FETCH_OWNER_ACCOUNT,
    payload: owner
});
export const setSelectedAccountAction =  (account) => ({
    type: actionTypes.SET_SELECTED_ACCOUNT,
    payload: account
}); 

export const restHistoryDataAction = () => ({
    type: actionTypes.RESET_ACCOUNT_HISTORY,
});

export const getWorkspaceDetailsAction = () => ({
    type: actionTypes.FETCH_WORKSPACE_DETAILS
})