import React from 'react';
import { connect } from 'react-redux';
import { setupPage } from '../../components/Layout/setupPage';
import {
    Button,
    Section,
    FormGroup,
    Label,
    NavLink
} from '../../components';

import './Report.style.scss';
import  * as actions  from '../../store/actions';
import  * as actionTypes  from '../../store/actionType';
import { Authority } from '../../components';
import DateTimePicker from 'react-datetime-picker';
import ReactToPrint,  { PrintContextConsumer } from 'react-to-print';
import {PrintBar} from './../components/partials/PrintBar';
import { formatDate } from '../../utility/helpers';
import {Loader } from '../components/partials/LoadingIndicator';
import { COMPANY_NAME } from '../../common/constant';
import { getIconUrl } from '../../common/images-catalogue';
import {
    Report_RP01,
    Report_RP02,
    Report_RP03,
    Report_RP04,
    Report_RP05,
    Report_RP06,
    Report_RP07,
    Report_RP08,
    Report_RP09,
    Report_RP10,
    Report_RP11,
} from './templates';

import {
    Filter_RP01,
    Filter_RP02,
    Filter_RP03,
    Filter_RP05,
    Filter_RP08,
    Filter_RP09,
} from './filters_options';
import {parse} from 'date-fns';



const REPORT_TEMPLATES = {
    'Report_RP01': { template: Report_RP01, filterOpt: Filter_RP01 },
    'Report_RP02': { template: Report_RP02, filterOpt: Filter_RP02},
    'Report_RP03': { template: Report_RP03, filterOpt: Filter_RP03},
    'Report_RP04': { template: Report_RP04, filterOpt: null},
    'Report_RP05': { template: Report_RP05, filterOpt: Filter_RP05},
    'Report_RP06': { template: Report_RP06, filterOpt: null},
    'Report_RP07': { template: Report_RP07, filterOpt: null},
    'Report_RP08': { template: Report_RP08, filterOpt: Filter_RP08},
    'Report_RP09': { template: Report_RP09, filterOpt: null},
    'Report_RP10': { template: Report_RP10, filterOpt: null},
    'Report_RP11': { template: Report_RP11, filterOpt: Filter_RP09}

}


const mapStateToProps = state => ({
    ...state.specimen.requisitionsState,
    ...state.reports,
});

const mapDispatchToProps = dispatch => ({
    onLoad: () => dispatch(actions.reportingViewLoadedAction()),
    unLoad: () => dispatch(actions.reportingViewUnloadAction()),
    reportFieldChange: (type, fields) => dispatch(actions.reportFieldAction(type, fields)),
    runReport:(payload) => dispatch(actions.runReportAction(payload)),
    getLabCodes: () => dispatch(actions.getAllLabcodesAction()),
    getCodeFindings: () => dispatch(actions.getCodeFindingsAction()),
    getCodeSheets: () => dispatch(actions.getCodeSheetsAction()),
    getClients: () => dispatch(actions.getAllClientsAction()),
    restFilters: () => dispatch(actions.reportFilterResetAction())
});

const renderText = (text) => text && text.replace(/(?:\r\n|\r|\n)/g, '<br />');

const ReportHeader = (props) => {
    const { reportItem, pageField, fieldDidChange, reportForm } = props;
    const onInputField = (evt) => {
        fieldDidChange(evt);
    }
    const heading  = pageField.pageHeader ? pageField.pageHeader : !props.workspace.global ? props.workspace.name : COMPANY_NAME;
    const subHeading  = pageField.subHeader ? pageField.subHeader :   reportItem.title;
    
    return <React.Fragment>
            <div className="report-header">
                <div className="header-inner">
                    <NavLink className="edit-hdr"><img src={getIconUrl('edit')} alt='' /></NavLink>
                    <div className="report-title editable --edit" contentEditable="true" suppressContentEditableWarning={true} name="pageHeader" onBlur={onInputField} dangerouslySetInnerHTML={{__html: renderText(heading)}} ></div>  
                    <div className="block">
                        <div className="editable sub-header --edit" contentEditable="true" suppressContentEditableWarning={true} name="subHeader"  onBlur={onInputField} dangerouslySetInnerHTML={{__html: renderText(subHeading)}}></div>
                    </div>
                    <p>As of {formatDate(reportForm.fromDate)} - {formatDate(reportForm.toDate)}</p>
                </div>
            </div>
        </React.Fragment>
}

const InputLine = (props) => {
    const {fieldDidChange, removeField, title, classes, innerContainerClasses, fieldName, fieldClasses, value } = props;

    const onInputField = (evt) => {
        fieldDidChange(evt);
    }

    const discard = (evt) => {
        evt.preventDefault();
        removeField();
    }
  
    return (
        <div className={classes}>
            {String(title).length > 0 && <Label>{title}</Label>}
            <div className={innerContainerClasses}>
                <NavLink onClick={discard}><img className="icon" src={getIconUrl('trash')} alt=''/></NavLink>
                <div name={fieldName} className={fieldClasses} contentEditable="" onBlur={onInputField} dangerouslySetInnerHTML={{__html: renderText(value)}}>
                </div>
            </div>
        </div>
    );
}

class Reporting extends React.Component {

    static pageOption = {pageTitle: 'Client'};

    constructor(props) {
        super(props);
        props.apiRequestManager.register(actionTypes.RUN_REPORT, props.runReport);
        props.apiRequestManager.register(actionTypes.FETCH_LABCODES, props.getLabCodes);
        props.apiRequestManager.register(actionTypes.FETCH_CODE_FINDINGS, props.getCodeFindings);
        props.apiRequestManager.register(actionTypes.FETCH_CODE_SHEETS, props.getCodeSheets);
        props.apiRequestManager.register(actionTypes.FETCH_CLIENTS, props.getClients);

        this.state = { };
        this.onRunReport = this.onRunReport.bind(this);
        this.textFieldDidChange = this.textFieldDidChange.bind(this);
        this.onRemoveNotes = this.onRemoveNotes.bind(this);
        this.willAddNotes = this.willAddNotes.bind(this);
        this.willAddFooter = this.willAddFooter.bind(this);
        this.removeFooter = this.removeFooter.bind(this);
        this.fromDateFieldChange = this.fromDateFieldChange.bind(this);
        this.toDateFieldChange = this.toDateFieldChange.bind(this);
        this.onResetFilters = this.onResetFilters.bind(this);
    }

    componentDidMount() {
        const fieldset = {
            reportType: this.props.reportIdentifier,
            fromDate: this.props.requisition ?
             parse(this.props.requisition.dateReceived, 'dd/MM/yyyy', new Date()) 
             : new Date(),
            toDate: new Date()
        }
        this.props.reportFieldChange(actionTypes.REPORT_FIELD_CHANGED, fieldset);
        this.props.runContextAction && this.props.runContextAction();
    }

    componentWillUnmount() {
        this.props.unLoad();
        this.props.pageConfig.disableBodyScroll(true);
        !this.props.restBackground && this.props.pageConfig.useGrayBackground(true);
    }

    didSelectOption(evt) {}

    onOptionChange() { }

    onRunReport() {
        const payload = {
            reportType:  this.props.reportIdentifier,
            workspace: this.props.workspace.id,
            ...this.props.reportForm,
            fromDate: this.formatDate(this.props.reportForm.fromDate),
            toDate: this.formatDate(this.props.reportForm.toDate)
        }
        this.props.apiRequestManager.queueRequest(actionTypes.RUN_REPORT, payload);
    }

    formatDate(date) {
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
      
        return day + '/' + month + '/' + year;
    }

    onRemoveNotes() {
        this.props.reportFieldChange(actionTypes.PRINT_FIELD_CHANGED, {
            notes: null
        });
    }
    willAddNotes() {
        this.props.reportFieldChange(actionTypes.PRINT_FIELD_CHANGED, {
            notes: ''
        });
    }

    willAddFooter(){
        this.props.reportFieldChange(actionTypes.PRINT_FIELD_CHANGED, {
            footer: ''
        });
    }

    removeFooter(){
        this.props.reportFieldChange(actionTypes.PRINT_FIELD_CHANGED, {
            footer: null,
        });
    }

    textFieldDidChange(evt) {
        
        const fieldName  = evt.target.getAttribute('name');
        const fieldset = {
            [fieldName]: evt.target.innerText
        }
        this.props.reportFieldChange(actionTypes.PRINT_FIELD_CHANGED, fieldset);
    }

    fromDateFieldChange(date) {
        const fieldset = {
            fromDate: date
        }
        this.props.reportFieldChange(actionTypes.REPORT_FIELD_CHANGED, fieldset);
    }
    toDateFieldChange(date) {
        const fieldset = {
            toDate: date
        }
        this.props.reportFieldChange(actionTypes.REPORT_FIELD_CHANGED, fieldset);
    }
    onResetFilters(evt) {
        this.props.restFilters();
    }

    render () {

        const templateIdentifier = this.props.reportIdentifier;
        const ReportTemplate = REPORT_TEMPLATES[templateIdentifier].template;

        const ReportFilters = REPORT_TEMPLATES[templateIdentifier].filterOpt;
        const customizeReport = this.props.customize;
        
        const reportItem = this.props.reportItem;
        const hideOptions = this.props.hideOptions;

        const { notes, footer } = this.props.pageForm;
        const notesValue = notes && notes.length > 0 ? notes : "Type your notes";
        const footerValue = footer && footer.length > 0 ? footer : "Type your footer";

        console.log(this.props.pageForm)
  
        return (
            <div className='reporting-wrap'>
                    { !hideOptions && 
                    <Section className="report-options-wrap">
                        <div className="filter-grp">
                            <div className="report-type-box">
                                <h3>{reportItem.title}</h3>
                            </div>
                            <div className="report-options">
                                <div className="date-filter">
                                    <Label>Report period</Label>
                                    <div className="date-inner-grp">
                                    <FormGroup className="date-wrap">
                                        <div className="date-field-group">
                                            <div className="ip-date">
                                                <DateTimePicker 
                                                    className="date-picker"
                                                    clearIcon={null}
                                                    disableClock
                                                    time={false}
                                                    format={"MM-dd-yyyy"}
                                                    calendarIcon={null}
                                                    onChange={this.fromDateFieldChange}
                                                    value={this.props.reportForm.fromDate}
                                                     />

                                                    <span className="icon">
                                                        <img src={getIconUrl("date")} alt='' />
                                                    </span>
                                            </div>
                                        </div>
                                    </FormGroup>
                                    <FormGroup className="date-wrap">
                                        <div className="date-field-group">
                                            <div className="ip-date">
                                                <DateTimePicker 
                                                    className="date-picker"
                                                    clearIcon={null}
                                                    disableClock
                                                    time={false}
                                                    format={"MM-dd-yyyy"}
                                                    calendarIcon={null}
                                                    onChange={this.toDateFieldChange}
                                                    value={this.props.reportForm.toDate}
                                                     />

                                                    <span className="icon">
                                                        <img src={getIconUrl("date")} alt='' />
                                                    </span>
                                            </div>
                                        </div>
                                    </FormGroup>
                                    </div>
                                </div>
                                <div className="custom-options">
                                    { (ReportFilters && customizeReport) && 
                                        <ReportFilters 
                                        {...this.props}
                                        actionType={actionTypes.REPORT_FIELD_CHANGED}
                                        />
                                    }
                                </div>  
                            </div>
                        </div>
                        <div className="report-actions">
                            <Button className="rest-btn"  onClick={this.onResetFilters}>
                                <span className="icon">
                                    <img src={getIconUrl('reset')} alt='' />
                                </span>
                            </Button>
                            <Button onClick={this.onRunReport} className="run-btn">
                                Run report
                            </Button>
                        </div>
                   </Section>
                   }

                   <Section className="report-viewer-wrap">
                       <div className="ly-cont">

                            <ReactToPrint content={() => this.componentRef}>
                        
                                <PrintContextConsumer>
                                    {({ handlePrint }) => (
                                        <PrintBar notes footer pdf 
                                        handlePrint={handlePrint}
                                        onAddNotes={this.willAddNotes}
                                        onAddFooter={this.willAddFooter}/>
                                    )}
                                </PrintContextConsumer>

                                <div className="report-viewer">
                                    <div className="print-area req-report"  
                                    ref={el => (this.componentRef = el)} >

                                    <ReportTemplate 
                                    {...this.props }
                                    ReportHeader={
                                    <ReportHeader 
                                    workspace={this.props.workspace}
                                    reportForm={this.props.reportForm}
                                    fieldDidChange={this.textFieldDidChange} 
                                    reportItem={reportItem} pageField={this.props.pageForm}/>}
                                    ReportFooter={
                                        <div className="page-footer">
                                        {
                                            this.props.pageForm.footer === null ?
                                            <p>MedDesk &copy; {new Date().getFullYear() }</p>
                                            :
                                            <InputLine fieldDidChange={this.textFieldDidChange}
                                            title=""
                                            removeField={this.removeFooter}
                                            classes="footer-wrap"
                                            innerContainerClasses="inner-content"
                                            fieldName="footer"
                                            fieldClasses="report-footer"
                                            value={footerValue} />
                                        }
                                        </div>
                                    }
                                    ReportNotes={
                                        this.props.pageForm.notes !== null ?
                                        <InputLine fieldDidChange={this.textFieldDidChange}
                                        title="Notes"
                                        removeField={this.onRemoveNotes}
                                        classes="notes-wrap"
                                        innerContainerClasses="inner-content"
                                        fieldName="notes"
                                        fieldClasses="notes"
                                        value={notesValue} />
                                        :
                                        null
                                    }
                                     />
                                    
                                    {this.props.isGenerating && 
                                    <div className="loader-modal">
                                        <Loader visible={ this.props.isGenerating } loadingText={'Generating...'} />
                                     </div>
                                    }
                                </div>
                                   
                                </div>
                                
                            </ReactToPrint>
                        </div>
                   </Section>
                 
            </div>
        );
    }
}

const ReportingView = (props) => {
    return (
        <Reporting {...props } />
    );
};

let ConfigReportingView = setupPage(Reporting.pageOption)(ReportingView);
ConfigReportingView =  connect(mapStateToProps, mapDispatchToProps)(ConfigReportingView);

export { ConfigReportingView };