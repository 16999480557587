import React, {useState} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setupPage } from '../../components/Layout/setupPage';
import classnames from 'classnames';
import { 
    ThemeConsumer,
    Container,
    Row,
    Col,
    Nav,
    Section,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Input,
    Label,
    List,
} from '../../components';
import SimpleBarChart from '../components/charts/SimpleBarChart';
import SlidingPane from "react-sliding-pane";
import { CloseButton } from '../components/partials/Buttons';
import './Report.style.scss';
import  * as actions  from '../../store/actions';
import  * as actionType  from '../../store/actionType';
import { Authority } from '../../components';
import { PaneContent } from './PaneContent';
import { Reporting } from '.';
import { PermissionEnforcer } from '../../utility/PermissionEnforcer';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
import { getIconUrl } from '../../common/images-catalogue';
const reportCatalogue = require('./report-catalogue.json');


const mapStateToProps = state => ({
    workspace: state.global.workspace,
    isClientDashboard: state.global.useClientDashboard,
    ...state.reports
});

const mapDispatchToProps = dispatch => ({
    apiRequestManager: ApiRequestManager.getInstance(ApiRequestManager), 
    unLoad: () => dispatch(actions.reportsViewUnloadAction()),
    setReportItem: (item) => dispatch(actions.setReportItemAction(item)),
    getReportSummary: (workspace) => dispatch(actions.getReportsOverviewSummaryAction(workspace))
});


const ReportFilterList = (props) => {
    const {reportsList, selectHandler} = props;

    const list = reportsList || [];

    return (<div className="reports-filter-list">
        <List className="fltr-list">
            {list.map((item, i) => <li key={i} className="fltr-item"><NavLink onClick={(evt) => selectHandler(evt, item)} to='' >{item.title}</NavLink></li>)}
        </List>
    </div>);
}

class Reports extends React.Component {

    static pageOption = {
        pageTitle: 'Client',
        disableBodyScroll: true,
        grayBackground: true,
    };

    constructor(props) {
        super(props);

        props.apiRequestManager.register(actionType.FETCH_REPORTS_SUMMARY, props.getReportSummary);

        this.state = {
            activeTab: '1',
            isPaneOpen: false,
            reportIdentifier: '',
            reportsData: reportCatalogue,
            showFilterList: false,
            filterFieldValue: '',
            customize: false
        };
        this.showReportingModal = this.showReportingModal.bind(this);
        this.closeReportingModal = this.closeReportingModal.bind(this);
        this.willFilter = this.willFilter.bind(this);
        this.willSelectFilterReport = this.willSelectFilterReport.bind(this);
    }

    componentDidMount() { 

    }

    componentWillUnmount() {
        this.props.unLoad();
    }

    static getDerivedStateFromProps(props, state) {
        if (props.workspace && !props.reportSummary && !props.apiRequestManager.inProgress(actionType.FETCH_REPORTS_SUMMARY)) {
            props.apiRequestManager.queueRequest(actionType.FETCH_REPORTS_SUMMARY, props.workspace.id);
        }
        return null;
    }

    showReportingModal(reportItem, customize=false) {
        this.props.setReportItem(reportItem);
        this.setState({ 
            isPaneOpen: true, 
            modalView: 'payment',
            reportIdentifier: 'Report_' + reportItem.identifier,
            customize: customize
        });
    }

    closeReportingModal(evt) {
        this.props.setReportItem({});
        this.setState({ isPaneOpen: false, customize: false });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({ activeTab: tab });
        }
    }

    filter = (keyword) => {
        const filterKeys = ['title'];
        var filteredList = reportCatalogue.filter(freight =>
            filterKeys.some(
              key =>
                freight[key]
                  .toLowerCase()
                  .indexOf(keyword.toLowerCase()) !== -1,
            ),
          );
          filteredList = filteredList.filter(item => {
              if ( this.props.isClientDashboard && item.forClient) {
                    return item;
              }else if (!this.props.isClientDashboard) {
                  return item;
              }
            });
          this.setState({reportsData: filteredList, activeTab: '4'});
    };

    willFilter (evt) {
        this.setState( { 
            showFilterList:  evt.target.value.length ? true :false,
            filterFieldValue: evt.target.value,
        });
        var keyword = evt.target.value;
        this.filter(keyword);
    }

    willSelectFilterReport(evt, reportItem) {
        evt.preventDefault();
        this.showReportingModal(reportItem);
        this.setState( { 
            showFilterList:  false,
            filterFieldValue: '',
            reportsData: reportCatalogue
        });
    }

    render () {

        const recommenend = [];
        const specimen = [];
        const billsPayments = [];
        const reportsList = this.state.reportsData.map((item) => {
           
            if (item.tags.find((tag) => tag === 'recommended')) {
                recommenend.push(item);
            }
            if (item.tags.find((tag) => tag === 'specimen')) {
                specimen.push(item);
            }
            if (item.tags.find((tag) => tag === 'bill') || item.tags.find((tag) => tag === 'payment')) {
                billsPayments.push(item);
            }
            return item
        }) ;

        const actions = {
            showReporting: this.showReportingModal
        }

        const summary = !Object.blank(this.props.reportSummary) ? this.props.reportSummary : {} ;
 
        const reportData = {
            revenue: summary.revenueTotal ? summary.revenueTotal :  0.0,
            gynecologyForms: summary.gynecologyForms ? summary.gynecologyForms :  0,
            nonGynecologyForms: summary.nonGynecologyForms ? summary.nonGynecologyForms :  0,
            chartData: summary.formInsight ? summary.formInsight : {}
        }
    
        return (
            <React.Fragment>
            <div className='content-layout-wrap'>
                <div className="view-layout-content">
                    <div className='container'>
                        <div className="default-Ly-ct">
                        <Container className="report-overview">
                           <Row>
                                <Col>
                                    <div className="title-box">
                                        <h1 className="title">Report Center</h1>
                                    </div>
                                    <Section className="quick-report-view">
                                        <div className="quick-report-content">
                                            <div className="q-col fcl">
                                                <div className="income">
                                                    <h1 className="amount">${reportData.revenue.toCurrency()}</h1>
                                                    <Label>{this.props.isClientDashboard ? 'Estimate Cost' : 'Gross Revenue'}</Label>
                                                </div>
                                            </div>
                                            <div className="q-col scl">
                                                <div className="vol-insight">

                                                    <div className="specimen-vol">
                                                        <div className="sp-frm-ngly">
                                                            <div className="amount">{reportData.gynecologyForms}
                                                            <span className="info">This year</span></div>
                                                            <Label>Gynecology</Label>
                                                        </div>
                                                        <div className="sp-frm-gly">
                                                            <div className="amount">{reportData.nonGynecologyForms}<span className="info">This year</span></div>
                                                                <Label>Non-Gynecology</Label>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="q-col tcl">
                                                <div className="specimen-col-chart">
                                                    <SimpleBarChart data={reportData.chartData} />
                                                </div>
                                            </div>
                                        </div>
                                    </Section>
                                    <Section className="report-catalogue-sec">
                                        <div className="report-search">
                                            <div className="search-wrap">
                                                <div className="search-ipt">
                                                    <span className="icon">
                                                        <img src={getIconUrl('search')} />
                                                    </span>
                                                    <Input 
                                                    onChange={this.willFilter}
                                                    value={this.state.filterFieldValue}
                                                    type="text" placeholder="Find a report" />
                                                </div>
                                                { this.state.showFilterList && 
                                                <ReportFilterList
                                                selectHandler={this.willSelectFilterReport}
                                                reportsList={this.state.reportsData}/>
                                                }
                                            </div>
                                        </div>
                                        <div className="catalogue-content">
                                        <div className="tab-nav">
                                            <Nav tabs>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: this.state.activeTab === '1' })}
                                                        onClick={() => { this.toggle('1'); }}
                                                                >
                                                        Recommended
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: this.state.activeTab === '2' })}
                                                        onClick={() => { this.toggle('2'); }}
                                                        >
                                                        Specimen Reports
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: this.state.activeTab === '3' })}
                                                        onClick={() => { this.toggle('3'); }}
                                                        >
                                                        Bill & Payments
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: this.state.activeTab === '4' })}
                                                        onClick={() => { this.toggle('4'); }}
                                                        >
                                                        All Reports
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                            </div>
                                            <div className="content-pane">
                                            <TabContent activeTab={this.state.activeTab}>

                                            <TabPane tabId='1' className="recmd-pane">
                                                <h3 className="pane-title">Recommended Reports</h3>
                                                <PaneContent clientDashboard={this.props.isClientDashboard} actions={ actions} reportList={recommenend} />
                                            </TabPane>

                                            <TabPane tabId='2' className="">
                                                <h3 className="pane-title">Specimen Reports</h3>
                                                <PaneContent clientDashboard={this.props.isClientDashboard} reportList={specimen}  actions={ actions} />
                                            </TabPane>
                                            <TabPane tabId='3' className="">
                                                <h3 className="pane-title">Bills & Payments Reports</h3>
                                                <PaneContent clientDashboard={this.props.isClientDashboard} reportList={billsPayments}  actions={ actions} />
                                            </TabPane>
                                            <TabPane tabId='4' className="">
                                                <h3 className="pane-title">All Reports</h3>
                                                <PaneContent clientDashboard={this.props.isClientDashboard} reportList={reportsList}  actions={ actions} />
                                            </TabPane>

                                            </TabContent>
                                            </div> 
                                        </div>
                                    </Section>
                                </Col>
                            </Row>
                        </Container>
                        </div>
                   </div>
                </div>
            </div>

            <SlidingPane
                    closeIcon={<CloseButton />}
                    isOpen={this.state.isPaneOpen}
                    title={"Reporting"}
                    from="bottom"
                    width="100%"
                    className="reporting-modal"
                    onRequestClose={this.closeReportingModal}
                >
                    <Reporting {...this.props} customize={this.state.customize} reportIdentifier={this.state.reportIdentifier } />
                </SlidingPane>

            </React.Fragment>
        );
    }
}

const ReportsView = (props) => {
    return (
        <Reports {...props } />
    );
};

let ConfigReportsView = setupPage(Reports.pageOption)(ReportsView);
ConfigReportsView =  connect(mapStateToProps, mapDispatchToProps)(ConfigReportsView);

export { ConfigReportsView };