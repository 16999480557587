import React from 'react';
import {
    SimpleSelect,
    FormGroup,
    Label
} from './../../../components';

const Filter_RP09 = (props) => {
    const {reportForm, actionType, reportFieldChange, clientsData, } = props;
    
    const didSelectClient = (client) => {
        const fieldSet = {
            options: {
                ...reportForm.options,
                client: client.value
            }
        }
        reportFieldChange(actionType, fieldSet);
    }

    const clientsList = clientsData.map((client, i) => ({
        value: client.clientInfo.id,
        firstName: client.firstName,
        lastName: client.lastName,
        accountNo: client.clientInfo.officeName,
        email: client.clientInfo.email,
        label: client.clientInfo.officeName.empty() ? `${ client.firstName } ${client.lastName} - ${client.clientInfo.accountNo}` :  `${client.clientInfo.officeName} - ${client.clientInfo.accountNo}`
    }));

    const selectedClient =  !reportForm.options.client ? null : clientsList.find((client) => client.value === reportForm.options.client);

    return <div className="opts-wrap">
        
        <FormGroup className="half">
            <Label>Clients</Label>
                <SimpleSelect
                id={'client'}
                className="ip-sml-select"
                name="client"
                title="By Client"
                list={clientsList}
                deselect={true}
                onChange={didSelectClient}
                initialSelected={selectedClient}
                searchable={["Type Name or Account No.", "No matching client"]}
                filterKeys={['label',
                            'firstName',
                            'lastName',
                            'email', 
                            'accountNo']}
                />
        </FormGroup>
    </div>
}

export default Filter_RP09;