/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ArrowDown from './assets/arrowDown.svg';
import ArrowUp from './assets/arrowUp.svg';
import './styles/global.sass';

import Placeholder from './elements/Placeholder';
import Search from './elements/Search';
import NoMatch from './elements/NoMatch';
import Option from './elements/Option';
import SelectedOption from './elements/SelectedOption';
import Indicator from './elements/Indicator';

const Check = <svg width="15" height="12" viewBox="0 0 15 12" fill="none">
<path d="M5.6 11.3L0.5 6L2.5 4.1L5.6 7.4L13 0L15 2L5.6 11.3Z" fill="black"/>
</svg>;

class SimpleSelect extends Component {
  constructor(props) {
    super(props);
    const { title, list, initialSelected } = this.props;

    this.state = {
      isListOpen: false,
      title,
      selectedItem: initialSelected ? initialSelected : null,
      keyword: '',
      list,
    };
    this.searchField = React.createRef();
  }

  componentDidMount() {
    const { select } = this.props;

    if (select) {
      this.selectSingleItem(select);
    }
  }

  componentDidUpdate() {
    const { isListOpen } = this.state;

    setTimeout(() => {
      if (isListOpen) {
        window.addEventListener('click', this.close);
      } else {
        window.removeEventListener('click', this.close);
      }
    }, 0);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.close);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { list } = nextProps;

    let newSelectedValue = nextProps.initialSelected ? nextProps.initialSelected.value : null;
    let currentSelectedValue = prevState.selectedItem ? prevState.selectedItem.value : null;

    if (newSelectedValue !== currentSelectedValue) {
      return {selectedItem :  nextProps.initialSelected, title: nextProps.title}
    }
    if (JSON.stringify(list) !== JSON.stringify(prevState.list)) {
      return { list };
    }
    
    return null;
  }

  shouldComponentUpdate(nextProps, prevState) {
    return true;
  }

  close = () => {
    this.setState({
      isListOpen: false,
    });
  }

  clearSelection = () => {
    const { name, title, onChange } = this.props;

    this.setState({
      selectedItem: null,
      title,
    }, () => {
      onChange(null, name);
    });
  }

  selectSingleItem = (item) => {
    const { list } = this.props;
    const selectedItem = list.find((i) => i.value === item.value);
    this.selectItem(selectedItem);
  }

  selectItem = (item) => {
    const { label, value } = item;
    const { list, selectedItem } = this.state;
    const { name, onChange } = this.props;
    
    let foundItem;

    if (!label) {
      foundItem = list.find((i) => i.value === item.value);
    }

    this.setState({
      title: label || foundItem.label,
      isListOpen: false,
      selectedItem: item,
    }, () => selectedItem?.value !== value && onChange(item, name));
  }

  toggleList = () => {
    this.setState((prevState) => ({
      isListOpen:  !prevState.isListOpen,
      keyword: '',
    }), () => {
      if (this.state.isListOpen && this.searchField.current) {
        this.searchField.current.focus();
        this.setState({
          keyword: '',
        });
      }
    });
  }

  filterList = (e) => {
    if ( this.props.searchHandler) {
      this.props.searchHandler(e.target.value.toLowerCase());
    } else {
      this.setState({
        keyword: e.target.value.toLowerCase(),
      });
    }
  }
  
  renderNoMatch = (props) => {
    const message =  props.searchable && !props.searchable.empty() ? props.searchable[1] : '';
    //const useStyle = listItemNoResult ? listItemNoResult : '';
 
    return (<div
      className={`template-option no-result ${props.id}`}>
      {message}
    </div>);
  }

  renderPlaceholder = (props) => {
    return (<div
      className={`select-placeholder-title ${props.id}`}
      style={props.styles.headerTitle}
    >
      {props.title}
    </div>);
  }

  renderOption = (item, props) => {
    return ( item.label );
  }

  listItems = () => {
    const {
      id,
      searchable,
      checkIcon,
      styles,
      components,
      filterKeys
    } = this.props;

    const { listItem, listItemNoResult } = styles;
    const { keyword, list } = this.state;
    let tempList = [...list];
    const selectedItemValue = this.state.selectedItem?.value;
    const properties = {
      id,
      searchable,
      checkIcon,
      keyword, 
      styles,
      list,
      listItem,
      listItemNoResult,
      selectedItemValue,
      selectHandler: this.selectItem
    }

    if (keyword.length) {
     /*tempList = list.filter((item) => 
        item.label.toLowerCase().includes(keyword.toLowerCase())
      );*/

      tempList = list.filter(freight =>
        filterKeys.some(
          key =>
            freight[key]
              .toLowerCase()
              .indexOf(keyword.toLowerCase()) !== -1,
        ),
      );
    }
   
    if (tempList.length) {
      return (
        tempList.map((item, i) => (
          components.Option ? components.Option({item: item, ...properties}) :
          <Option key={i} className="default-option" item={item} {...properties} >
            { this.renderOption(item) }
          </Option>
        ))
      );
    }

    return (
       <NoMatch {...properties}>
          { this.renderNoMatch({...properties, listItemNoResult: ''}) }
        </NoMatch>
    );
  }

  onSelectBlur = (event) => { }

  onSelectFocus =(event) => {
    
    if(this.props.disabled) {
      event.preventDefault();
      return;
    } 
    this.toggleList();
  }

  render() {
    
    const {
      id,
      searchable,
      styles,
      className,
      components
    } = this.props;

    const { isListOpen, title, selectedItem } = this.state;
    
    const {
      wrapper,
      header,
      placeholder,
      indicatorIcon,
      list,
      listSearchBar,
      scrollList,
    } = styles;

    const properties = {
      ...this.props,
      isListOpen,
      title
    }
    const customClassname = className ? className : '';
    const disableClassname = this.props.disabled ? '--disabled' : '';

    return (
      <div
        className={`select-container ${id} ${customClassname}`}
        style={wrapper}
        name={this.props.name}
        onBlur={this.onSelectBlur}
      >
        <div
          className={`select-control ${id} ${disableClassname}`}
          style={header}
          onMouseUp={this.onSelectFocus}
        >
          {
             selectedItem === null? 
             <Placeholder>{this.renderPlaceholder(properties)}</Placeholder> : 
             components.SelectedOption ? 
             components.SelectedOption({selectedItem: selectedItem, ...this.props}) 
               : 
             <SelectedOption className="--default">{ selectedItem.label }</SelectedOption>
          }
           
          {components.Indicator ? components.Indicator({inFocus: isListOpen }) : <Indicator className={indicatorIcon} inFocus={isListOpen}/> }
          
        </div>
        {isListOpen && (
          <div
            className={`options--default${searchable ? ' searchable' : ''} ${id}`}
            style={list}
          >
            {searchable
            && (
              <div className="search-component">
            <input
              ref={this.searchField}
              className={`search-filter-field ${id}`}
              style={listSearchBar}
              placeholder={searchable[0]}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => this.filterList(e)}
            />
            </div>
            )}
            <div
              className={`select_options--default ${id}`}
              style={scrollList}
            >
              {this.listItems()
            }
            </div>
          </div>
        )}
      </div>
    );
  }
}

SimpleSelect.defaultProps = {
  id: '',
  select: undefined,
  searchable: undefined,
  filterKeys: ['label'],
  styles: {},
  checkIcon: null,
  initialSelected: null,
  disabled: false,
  components: {
    Option: null,
    Indicator: null,
    Placeholder: null,
    SelectedOption: null
  },
  searchHandler: null
};

SimpleSelect.propTypes = {
  id: PropTypes.string,
  styles: PropTypes.shape({
    wrapper: PropTypes.string,
    header: PropTypes.string,
    headerTitle: PropTypes.string,
    indicatorIcon: PropTypes.string,
    checkIcon: PropTypes.string,
    list: PropTypes.string,
    listSearchBar: PropTypes.string,
    scrollList: PropTypes.string,
    listItem: PropTypes.string,
    listItemNoResult: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  select: PropTypes.shape({ value: PropTypes.string }),
  initialSelected: PropTypes.object,
  searchable: PropTypes.arrayOf(PropTypes.string),
  filterKeys: PropTypes.arrayOf(PropTypes.string),
  checkIcon: PropTypes.elementType,
  disabled: PropTypes.bool,
  components: PropTypes.shape({
    Option: PropTypes.func,
    Indicator: PropTypes.func,
    Placeholder: PropTypes.func,
    SelectedOption: PropTypes.func
  }),
  searchHandler: PropTypes.func
};

export default SimpleSelect;
