import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { setupPage } from '../../components/Layout/setupPage';
import { PermissionEnforcer } from '../../utility/PermissionEnforcer';
import { FormValidator } from '../../utility/FormValidator';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
import {
    Row,
    Col, 
    Nav,
    NavLink,
    NavItem,
    Button,
    Container,
    Authority
} from '../../components';

import './Settings.style.scss';
import  * as actions  from '../../store/actions';
import * as actionsTypes from '../../store/actionType';
import { Loader } from '../components/partials/LoadingIndicator';
import ConfirmModal from '../components/partials/ConfirmModal';
import * as Panes  from './index';

import { FEATURE_FLAG } from "../../common/constant";
import  FEATURES  from "../../config/featureFlags";

const mapStateToProps = state => ({
    apiRequestManager : ApiRequestManager.getInstance(ApiRequestManager),
    validator: FormValidator.getInstance(FormValidator) ,
    ...state.settings,
});

const mapDispatchToProps = dispatch => ({
    getPreferences: () => dispatch(actions.getAppPreferencesAction()),
    preferenceChanged: (type, data) => dispatch(actions.setSettingDataAction(type, data)),
    submitChangePreferences: (payload) => dispatch(actions.submitChangedPreferencesAction(payload))
});

class Settings extends React.Component {

    static pageOption = {
        pageTitle: 'Client',
        disableBodyScroll: true
    };

    registeredPanes = {
        codeSheet: { pane: Panes.CodeSheetPane, name: 'Code Sheet', id: 'codeSheet' },
        codeFindings: { pane: Panes.CodeFindingsPane, name: 'Code Findings', id: 'codeFindings' },
        labCode: { pane: Panes.LabCodePane,  name: 'Lab Code', id: 'labCode'},
        taxes: { pane: Panes.TaxesPane,  name: 'Setup Taxes', id: 'taxes'},
        services: { pane: Panes.ServicesPane,  name: 'Add Services', id: 'services'},
        departments: {pane: Panes.DepartmentPane, name: 'Departments', id: 'departments'},
        company: { pane: Panes.CompanyPane,  name: 'Company', id: 'company'},
        notification: { pane: Panes.NotificationPane,  name: 'Notifications', id: 'notification', groupId: 'PFG03'}
    };

    innerRef = {
        confirmDeleteCallback: null
    };

    constructor(props) {
        super(props);

        const paneId = this.props.match.params.pane_id;
        props.apiRequestManager.register(actionsTypes.FETCH_APP_PREFERENCES, props.getPreferences);
        props.apiRequestManager.register(actionsTypes.SUBMIT_CHANGED_PREFERENCES, props.submitChangePreferences)

        this.state = {
            activePane: paneId ? paneId : 'labCode',
            deleteDialogVisible: false,
            deleteContextName: 'item',
            deleleContextItem: {},
            changedGroupIndentifier: null,
            isModified: false
        };

        this.didClickSettingOption = this.didClickSettingOption.bind(this);
        this.willHandleSave = this.willHandleSave.bind(this);
        this.showDeleteDialog = this.showDeleteDialog.bind(this);
        this.onConfirmDelete = this.onConfirmDelete.bind(this);
        this.cancelDelete = this.cancelDelete.bind(this);
        this.getPrefValue = this.getPrefValue.bind(this);
        this.onSettingGroupChanged = this.onSettingGroupChanged.bind(this);
        this.onSaveSettings = this.onSaveSettings.bind(this);
        this.innerRef.saveHanlder = this.onSaveSettings;
        this.enableSaveAction = this.enableSaveAction.bind(this);
    }

    componentDidMount() {}

    static getDerivedStateFromProps(props, state) { 
        if ( !props.preferences && !props.apiRequestManager.inProgress(actionsTypes.FETCH_APP_PREFERENCES)) {
            props.apiRequestManager.queueRequest(actionsTypes.FETCH_APP_PREFERENCES);
        }
        return null;
    }

    showDeleteDialog(contextName, deleleItem) {
        this.setState({deleteDialogVisible: true, 
            deleteContextName: contextName, 
            deleleContextItem: deleleItem });
    } 
    
    cancelDelete() {
        this.setState({deleteDialogVisible: false, deleteContextName: ""});
    }

    onConfirmDelete(deleteItem) {
        this.innerRef.confirmDeleteCallback && this.innerRef.confirmDeleteCallback(deleteItem);
        this.cancelDelete();
    } 

    willHandleSave() {
        this.setState({isSaving: true});
        this.innerRef.saveHanlder && this.innerRef.saveHanlder();
    }

    didClickSettingOption(evt) {
        this.innerRef.saveHanlder = this.onSaveSettings;
        const paneIndetifier = evt.target.getAttribute('to');
        this.setState({activePane: paneIndetifier, 
                        isModified: false, 
                        changedGroupIndentifier: null});
    }

    enableSaveAction(value = true) {
        this.setState({isModified: value});
    }

    onSettingGroupChanged(identifier, preference) {
        const changePayload = {
            slice: 'group',
            data: {
                identifier: identifier,
                pref: preference
            }
        };
        this.props.preferenceChanged(actionsTypes.SETTINGS_OPTION_CHANGED, changePayload);
        this.setState({ changedGroupIndentifier: identifier, isModified: true });
    }

    onSaveSettings() {
        if (this.state.changedGroupIndentifier === null) return;
        const payload = this.getPreferencesInGroup(this.state.changedGroupIndentifier);
        payload.requestType = 'submit';
        this.props.apiRequestManager.queueRequest(actionsTypes.SUBMIT_CHANGED_PREFERENCES, payload);
    }

    getPreferencesInGroup(identifier) {
        const prefs = this.props.preferences.prefsGroup[identifier].preferences;
        return prefs;
    }

    getPrefValue(preference) {
        const prefValue = {
            prefId: preference.id,
            label: preference.name,
            description: preference.description,
            dataType: preference.valueSet.dataType
        };

        switch (preference.valueSet.dataType) {
            case 'bool':
                prefValue.valueKey = 'boolValue';
                break;
            case 'text':
                prefValue.valueKey = 'textValue';
                break;
            case 'integer':
                prefValue.valueKey = 'integerValue';
                break;
            case 'float':
                prefValue.valueKey = 'floatValue';
                break;
            default:
        }
        prefValue.value = preference.valueSet[prefValue.valueKey];
        return prefValue;
    }

    render () {

        const selectedPane = this.registeredPanes[this.state.activePane] !== undefined ? this.registeredPanes[this.state.activePane] : this.registeredPanes['labCode'];
        
        const VisiblePane = selectedPane.pane;
        const paneTitle = selectedPane.name;
        const activePane = this.state.activePane;
        const isSaving = this.props.isSaving;

        const props = {
            ...this.props,
            showDeleteDialog: this.showDeleteDialog
        };

        const globalPreferences = this.props.preferences;
        const prefsGroupId = selectedPane.groupId;

        const actions = {
            getPrefValue: this.getPrefValue,
            preferenceWillChange: this.onSettingGroupChanged,
            enableSave: this.enableSaveAction
        };

        return (
            <React.Fragment>
            <div className='content-layout-wrap'>
                <div className="view-layout-content">
                   <div className="default-Ly-ct">
                       <div className="settings-main">
                            <div className="setting-opt-list">
                                <Container>
                                    <div className="title-box">
                                        <h1 className="title">Settings</h1>
                                    </div>
                                    <Row>
                                        <Col lm={6}>
                                            <div className="setting-grp">
                                                <h2 className="grp-title">Form & Specimen</h2>
                                                <div className="setting-items">
                                                    <Nav>
                                                        <Authority allowed="change" entity="labcode">
                                                        <NavItem>
                                                            <NavLink to={'labCode'} onClick={this.didClickSettingOption} className={classnames({ active: activePane === this.registeredPanes.labCode.id })}>
                                                                Lab Codes
                                                            </NavLink>
                                                        </NavItem>
                                                        </Authority>
                                                        <NavItem>
                                                            <NavLink to={'codeSheet'} 
                                                            onClick={this.didClickSettingOption}
                                                            className={classnames({ active: activePane === this.registeredPanes.codeSheet.id })}>
                                                                Code Sheet
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem>
                                                            <NavLink to={'codeFindings'} 
                                                            onClick={this.didClickSettingOption}
                                                            className={classnames({ active: activePane === this.registeredPanes.codeFindings.id })}>
                                                                Code Findings
                                                            </NavLink>
                                                        </NavItem>
                                                    </Nav>
                                                </div>
                                            </div>
                                            <div className="setting-grp">
                                                <h2 className="grp-title">General</h2>
                                                <div className="setting-items">
                                                    <Nav>
                                                        <NavItem>
                                                            <NavLink to={'company'} onClick={this.didClickSettingOption}
                                                            className={classnames({ active: activePane === this.registeredPanes.company.id })} >
                                                                Company
                                                            </NavLink>
                                                        </NavItem>
                                                        {FEATURES.SecuritySetting === FEATURE_FLAG.ON && 
                                                        <NavItem>
                                                            <NavLink>
                                                                Security
                                                            </NavLink>
                                                        </NavItem>
                                                        }
                                                        <NavItem>
                                                            <NavLink to={'notification'} onClick={this.didClickSettingOption}
                                                            className={classnames({ active: activePane === this.registeredPanes.notification.id })}>
                                                                Notification
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem>
                                                            <NavLink to='departments' onClick={this.didClickSettingOption} className={classnames({active: activePane === this.registeredPanes.departments.id})}>
                                                                Departments
                                                            </NavLink>
                                                        </NavItem>
                                                    </Nav>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lm={6}>
                                            <div className="setting-grp">
                                                <h2 className="grp-title">Payroll & Billing</h2>
                                                <div className="setting-items">
                                                    <Nav>
                                                        <NavItem>
                                                            <NavLink to={'services'}
                                                            onClick={this.didClickSettingOption}
                                                            className={classnames({ active: activePane === this.registeredPanes.services.id })}>
                                                                Services
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem>
                                                            <NavLink to={'taxes'}
                                                            onClick={this.didClickSettingOption}
                                                            className={classnames({ active: activePane === this.registeredPanes.taxes.id })}>
                                                                Taxes
                                                            </NavLink>
                                                        </NavItem>
                                                    </Nav>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            <div className="setting-pane-hldr">
                                <Container>
                                    <div className="settings-pane">
                                        <div className="title-box">
                                            <h1 className="title">{paneTitle}</h1>
                                            <div className="col-l">
                                                <Loader visible={isSaving} loadingText={''} />
                                                <Button disabled={!this.state.isModified} onClick={this.willHandleSave}className="save-sttg-btn default-btn">Save</Button>
                                            </div>
                                        </div>
                                        <div className="pane-content">
                                        
                                            <VisiblePane 
                                            innerRef={this.innerRef} 
                                            preferences={globalPreferences}
                                            prefsGroupId={prefsGroupId} 
                                            actions={actions}
                                            {...props} />
                                        
                                        </div>
                                    </div>
                                </Container>
                            </div>
                        </div>
                   </div>
                </div>
            </div>
            
            { this.state.deleteDialogVisible && 
                <ConfirmModal 
                contextName={this.state.deleteContextName} 
                contextItem={this.state.deleleContextItem} 
                cancelCallback={this.cancelDelete}
                confirmCallback={this.onConfirmDelete}
                />
            }
            </React.Fragment>
        );
    }
}

const SettingsView = (props) => {
    return (
        <Settings {...props } />
    );
};

let ConfigSettingsView = setupPage(Settings.pageOption)(SettingsView);
ConfigSettingsView =  connect(mapStateToProps, mapDispatchToProps)(ConfigSettingsView);

export { ConfigSettingsView };