/* eslint-disable no-constant-condition */
import {delay} from 'redux-saga';
import { take, put, call, fork, select, all, takeEvery, takeLatest} from 'redux-saga/effects'
import *  as actionTypes from '../actionType';
import { permissionDialect } from '../../service/agent';
import * as actions from '../actions/permissionsRoles';
import { denormalizeResponse, setMetaData } from './../../service/httpRequestDialect';
import { rolesArraySchema, permissionsArraySchema } from '../../service/schemas';
import ApiError from '../../utility/error/ApiError';
// each entity defines 3 creators { request, success, failure }
const rolesActions = actions.roles;
const permissionsActions = actions.permissions;

/**
 *  WORKERS 
 * --------------------------------------------------------------
 */

// reusable fetch Subroutine
// entity :  user | repo | starred | stargazers
// apiFn  : api.fetchUser | api.fetchRepo | ...
// id     : login | fullName
// url    : next page url. If not provided will use pass id to apiFn
function* fetchEntity(entity, apiFunc, id, url) {
    yield put( entity.request(id) )
    const { response, error } = yield call(apiFunc, url || id)
    return {response, error};
}

// yeah! we can also bind Generators
const doFetchRoles = fetchEntity.bind(null, rolesActions, permissionDialect.fetchRoles);
const doFetchPermissions = fetchEntity.bind(null, permissionsActions, permissionDialect.fetchPermissions);

// Fetches data for a User : user data + starred repos
function* parallelFetchPermissionsRoles(action) {
    // correct, effects will get executed in parallel
    setMetaData({ initiator:  action.type });
    const roles = yield call(doFetchRoles, action.payload);
    const permissions = yield call(doFetchPermissions, action.payload);
    
    if (roles.error && !permissions.error) {
      
        yield put({type: actionTypes.FETCH_ROLES_PERMISSIONS_RESULTS, payload : {
            roles: new ApiError(),
            permissions: denormalizeResponse(permissions.response.result, permissionsArraySchema, permissions.response.entities)
        }});
    }
        
    if (permissions.error && !roles.error) 
        yield put({type: actionTypes.FETCH_ROLES_PERMISSIONS_RESULTS, payload : {
            roles: denormalizeResponse(roles.response.result, rolesArraySchema, roles.response.entities),
            permissions: new ApiError()
        }});

    //yield put(rolesActions.failure(roles.error))
    //yield put(permissionsActions.failure(permissions.error))
    
    if (roles.response && permissions.response)
        ///yield call(doFetchRoles, action.payload);
        yield put({type: actionTypes.FETCH_ROLES_PERMISSIONS_RESULTS, payload : {
            roles: denormalizeResponse(roles.response.result, rolesArraySchema, roles.response.entities),

            permissions: denormalizeResponse(permissions.response.result, permissionsArraySchema, permissions.response.entities)
        }});
    
        //yield fork(loadStarred, login)
}

function* fetchRoles(action) {
    setMetaData({ initiator:  action.type });
    const roles = yield call(doFetchRoles, action.payload);
    
    if (roles.error) 
        yield put(rolesActions.failure(roles.error))
    else      
        yield put(rolesActions.success(denormalizeResponse(roles.response.result, rolesArraySchema, roles.response.entities)));
}
 
/**
 *  WATCHERS 
 * --------------------------------------------------------------
 */

 // 
function* watchRoles() {
    while(true) {
        const action = yield take(actionTypes.FETCH_ROLES);
        yield fork(fetchRoles, action);
    }
}

//Watching for action to be dispatch
function* watchPermissionsRoles(){
    while(true) {
      const action = yield take(actionTypes.FETCH_ROLES_PERMISSIONS);
      yield fork(parallelFetchPermissionsRoles, action);
    }
}

export default function* root() {
    yield all([
        //fork(watchRoles),
        fork(watchPermissionsRoles)
    ])
}