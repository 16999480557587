
import {ofType } from 'redux-observable';
import { catchError, map, switchMap, concatMap,mergeMap, takeUntil } from 'rxjs/operators';
import *  as actionTypes from '../actionType';
import { billsDialect, serviceDialect, checkReponse, accountDialect, apiOutcome } from '../../service/agent';
import { of, forkJoin} from 'rxjs';
import * as actions from '../actions';
import { apiResponseActionCreator } from '../actions';
import  { requestEnded } from '../../service/agentDialect';

export const getAllBillsEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.FETCH_BILLS),
        switchMap((action) => {
            return billsDialect.fetchAllBills(action.payload).pipe(
               map(response => {
                    const responseData = checkReponse(response, action.type);
                    return  actions.apiResponseActionCreator(actionTypes.FETCHED_BILLS_RESULTS, responseData);
                }),
               catchError(error => {
                    console.log("ERROR", error);
                    return of( actions.apiResponseActionCreator(actionTypes.FETCHED_BILLS_RESULTS, error));
               })
            );
        })
    )
}

export const getBillEpic = action$ => {
  return action$.pipe(
      ofType(actionTypes.FETCH_BILL),
      switchMap((action) => {
          return billsDialect.fetchBill(action.payload).pipe(
             map(response => {
                  const responseData = checkReponse(response, action.type);
                  return  actions.apiResponseActionCreator(actionTypes.FETCHED_BILL_RESULT, responseData);
              }),
             catchError(error => {
                  return of( actions.apiResponseActionCreator(actionTypes.FETCHED_BILL_RESULT, error));
             })
          );
      })
  )
}


export const getAllBilledBillsEpic = action$ => {
  return action$.pipe(
      ofType(actionTypes.FETCH_BILLED_BILLS),
      switchMap((action) => {
          return billsDialect.fetchAllBilledBills(action.payload).pipe(
             map(response => {
                  const responseData = checkReponse(response, action.type);
                  return  actions.apiResponseActionCreator(actionTypes.FETCHED_BILLED_BILLS_RESULTS, responseData);
              }),
             catchError(error => {
                  console.log("ERROR", error);
                  return of( actions.apiResponseActionCreator(actionTypes.FETCHED_BILLED_BILLS_RESULTS, error));
             })
          );
      })
  )
}

export const fetchConcurrentClientsServicesEpic = (action$, store) =>
  action$.pipe(
    ofType(actionTypes.FETCH_CLIENTS_SERVICES),
    //map(action => action.payload),
    mergeMap((action) => {
        
      let  requestlist = [
        accountDialect.fetchAllClientsAccounts(),//.catchError(err => of({type: 'error', payloa: err }) ),
        serviceDialect.fetchAllServices(),//.catchError(err => of({type: 'error', payloa: err }) )
      ];
      return forkJoin(
        requestlist
      )
    }),
    takeUntil(action$.pipe(ofType('ABORT'))),
    map(responses => {
    var allPayload = responses.map((response) => {
        return checkReponse(response);
    })
    allPayload = {
      accounts: allPayload[0],
      services: allPayload[1],
    }

    apiOutcome.initiator = actionTypes.FETCH_CLIENTS_SERVICES;
    apiOutcome.success = true;
    requestEnded(apiOutcome);

    return {type: actionTypes.FETCHED_CLIENTS_SERVICES_RESULTS, payload: allPayload}
    }),
    
    catchError(err => {
      console.log(err);
    return of({type: 'error', payload: err })})
    );

export const submitBillEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.BILL_SUBMIT_DATA),
        
        mergeMap((action) => {
          const apiCalls = {
            'delete': billsDialect.deleteBill,
            'create': billsDialect.submitBill,
            'update': billsDialect.updateBill,
            'billed': billsDialect.billClient,
            'view': billsDialect.billViewed
          }
  
            return apiCalls[action.payload.requestType](action.payload).pipe(
               map(response => {
                    const responseData = checkReponse(response, action.type, action.payload.requestType);
                    return apiResponseActionCreator(actionTypes.BILL_SUBMITED_RESULTS, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
               catchError(error => {
                    console.log("ERROR", error);
                    return of(apiResponseActionCreator(actionTypes.BILL_SUBMITED_RESULTS, error));
               })
            );
        })
  )};


export const getAllPaidBillsEpic = action$ => {
  return action$.pipe(
      ofType(actionTypes.FETCH_PAID_BILLS),
      switchMap((action) => {
          return billsDialect.fetchAllPaidBills(action.payload).pipe(
              map(response => {
                  const responseData = checkReponse(response, action.type);
                  return  actions.apiResponseActionCreator(actionTypes.FETCHED_PAID_BILLS_RESULTS, responseData);
              }),
              catchError(error => {
                  console.log("ERROR", error);
                  return of( actions.apiResponseActionCreator(actionTypes.FETCHED_PAID_BILLS_RESULTS, error));
              })
          );
      })
  )
}

export const getAllUnpaidBillsEpic = action$ => {
  return action$.pipe(
      ofType(actionTypes.FETCH_UNPAID_BILLS),
      switchMap((action) => {
          return billsDialect.fetchAllUnpaidBills(action.payload).pipe(
              map(response => {
                  const responseData = checkReponse(response, action.type);
                  return  actions.apiResponseActionCreator(actionTypes.FETCHED_UNPAID_BILLS_RESULTS, responseData);
              }),
              catchError(error => {
                  console.log("ERROR", error);
                  return of( actions.apiResponseActionCreator(actionTypes.FETCHED_UNPAID_BILLS_RESULTS, error));
              })
          );
      })
  )
}

export const getBillsSummaryEpic = action$ => {
  return action$.pipe(
      ofType(actionTypes.FETCH_BILLS_SUMMARY),
      switchMap((action) => {
          return billsDialect.fetchBillsSummary(action.payload).pipe(
              map((response) => {
                  const responseData = checkReponse(response, action.type);
                  return apiResponseActionCreator(actionTypes.FETCHED_BILLS_SUMMARY_RESULTS, responseData);
              }),
              takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
              catchError((error) => {
                  console.log('ERROR', error);
                  return of(apiResponseActionCreator(actionTypes.FETCHED_BILLS_SUMMARY_RESULTS, error));
              })
          )
      })
  )
}

export const getBillPaymentsEpic = action$ => {
  return action$.pipe(
      ofType(actionTypes.FETCH_BILL_PAYMENTS),
      switchMap((action) => {
          return billsDialect.fetchBillPayments(action.payload).pipe(
              map((response) => {
                  const responseData = checkReponse(response, action.type);
                  return apiResponseActionCreator(actionTypes.FETCHED_BILL_PAYMENTS_RESULTS, responseData);
              }),
              takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
              catchError((error) => {
                  console.log('ERROR', error);
                  return of(apiResponseActionCreator(actionTypes.FETCHED_BILL_PAYMENTS_RESULTS, error));
              })
          )
      })
  )
}