import React from 'react';
import { Fragment } from 'react';
import * as actionTypes from '../../store/actionType';
import DelayRender from './../../utility/DelayRender';

import {timeDelayed } from '../../utility/helpers';
import { getIconUrl } from '../../common/images-catalogue';
import { 
    Section,
    Authority,
    SpecimenTable,
 } from '../../components';


class Authorize extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        
        if ( !this.props.specimensData && this.props.workspace ) {
            const requestParams = {
                workspace: this.props.useClientLayout && this.props.workspace.id
            }
            this.props.useClientLayout ? this.props.apiRequestManager.queueRequest(actionTypes.FETCH_SPECIMEN_RECORDS_APPROVED, requestParams) : 
            this.props.apiRequestManager.queueRequest(actionTypes.FETCH_SPECIMEN_RECORDS_APPROVED,requestParams);
        }

        this.unlistenAbort = this.props.history.listen(() => { 
            this.props.apiRequestManager.abortRequest(actionTypes.FETCH_SPECIMEN_RECORDS_APPROVED);
        });
    }

    componentWillUnmount() {
        this.props.unLoad();
        this.unlistenAbort();
    }

    static getDerivedStateFromProps(props, state) {
        // Return null to indicate no change to state.
        if ( !props.approvedSpecimensData && props.workspace ) {
           
            const requestParams = {
                workspace: props.useClientLayout && props.workspace.id,
            }
            props.useClientLayout ? props.apiRequestManager.queueRequest(actionTypes.FETCH_SPECIMEN_RECORDS_APPROVED, requestParams) : 
            props.apiRequestManager.queueRequest(actionTypes.FETCH_SPECIMEN_RECORDS_APPROVED,requestParams);
        }

        if ( props.approvedSpecimensData && props.workspace && props.lazyLoad) {
            
            const requestParams = {
                workspace: props.useClientLayout && props.workspace.id,
                page: props.pageLoaded 
            }
            props.useClientLayout ? props.apiRequestManager.queueRequest(actionTypes.FETCH_SPECIMEN_RECORDS_APPROVED, requestParams) : 
            props.apiRequestManager.queueRequest(actionTypes.FETCH_SPECIMEN_RECORDS_APPROVED,requestParams);
        }

        return null;
    }

    render() {

        const weekTableData =  !Object.blank(this.props.approvedSpecimensData) ? this.props.approvedSpecimensData.weekRecords : [];
        const monthTableData =  !Object.blank(this.props.approvedSpecimensData) ? this.props.approvedSpecimensData.monthRecords : [];
      
        return <Fragment>
            <div className="spmt-Ly-ct-inner">
                <div className="spmt-title-d">
                    <h3 className="spmt-title">Recently</h3>
                </div>
            </div>
            <div className="spmt-v-lay-ct">
                <div className="content-well">
                    <div className="sec-title">
                        <h5></h5>
                    </div>
                
                    <Section className="recent-sec-t specimen-table current-month">
                        <div className="sec-title">
                            <h5>Eariler this week</h5>
                        </div>
                        <SpecimenTable id="weekTable" 
                        imit={6} 
                        tableData={weekTableData}
                        hoverOverRowAction={this.props.willHandleTableRowMouseOver}
                        quickActions={this.props.quickActionCallback} />
                    </Section>
                    <Section className="recent-sec-t specimen-table older">
                        <div className="sec-title">
                            <h5>This month</h5>
                        </div>
                        <SpecimenTable id="mainTable" 
                        hoverOverRowAction={this.props.willHandleTableRowMouseOver}
                        quickActions={this.props.quickActionCallback} tableData={monthTableData}
                        />
                    </Section>
                </div>
            </div>
        </Fragment>
    }
};

export default Authorize;