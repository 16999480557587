import * as actionTypes from '../../actionType';
import { isError } from '../../../service/agent';

export const codeSheetState = {
    codeSheetSetting : {
        codeSheetsData: null
    }
}
    
var error = null;
let emptyArray = [];

const codeSheetReducer = (state, action ) => { 
    switch(action.type) {
        case actionTypes.SET_CODESHEETS_DATA:
            return {
                ...state,
                codeSheetSetting: {
                    ...state.codeSheetSetting,
                    codeSheetsData: action.payload
                }
            }
        case actionTypes.FETCHED_CODE_SHEETS_RESULTS:
            error = isError(action.payload);
            const codesheets = action.payload.codesheets || action.payload;
            return {
                ...state,
                codeSheetSetting: {
                    ...state.codeSheetSetting,
                    codeSheetsData: error ? emptyArray : codesheets.map((item, i) => {
                        return {
                            index: i,
                            ...item
                        }
                    })
                }
            }
        case actionTypes.CODESHEET_SUBMIT_DATA:
            return {
                ...state,
                requestType: action.payload.requestType,
                isSaving: true
            }
        case actionTypes.CODESHEET_SUBMIT_DATA_RESULT:
            error = isError(action.payload);
            if (state.requestType === 'delete') {
                const filteredTaxes =  error ? state.codeSheetSetting.codeSheetsData  : state.codeSheetSetting.codeSheetsData.filter((item) => item.id !== action.payload.deletedId);
                return {
                    ...state,
                    isSaving: false,
                    codeSheetSetting: {
                        ...state.codeSheetSetting,
                        codeSheetsData: filteredTaxes
                    }
                }
            }else {
                return {
                    ...state,
                    isSaving: false,
                    codeSheetSetting: {
                        ...state.codeSheetSetting,
                        codeSheetsData: error ? state.codeSheetSetting.codeSheetsData : action.payload.map((item, i) => {
                            return {
                                index: i,
                                ...item
                            }
                        })
                    }
                }
            }
        default:
            return state;
    }
};

export default codeSheetReducer;
