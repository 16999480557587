import React from 'react';
import DateTimePicker from 'react-datetime-picker';
import { 
    Button,
    FormGroup,
    Label,
    Section,
    EarningsTable
} from '../../components';
import {Loader } from '../components/partials/LoadingIndicator';
import './Payroll.style.scss';
import { getIconUrl } from '../../common/images-catalogue';

const toDecimal = (value) => {
    return value !== '' && value >= 0 ? parseFloat(value).toFixed(2) : '';
}

class EarningsPane extends React.Component {

    static pageOption = { 
        pageTitle: 'Pay Slips',
        disableBodyScroll: true
    };

    constructor(props) {
        super(props);
        
        this.state = { };

        this.tableRef = React.createRef();
        this.addInputField = this.addInputField.bind(this);
        this.inputFieldChange = this.inputFieldChange.bind(this);
        this.dateChange = this.dateChange.bind(this);
        this.onBlurHandler = this.onBlurHandler.bind(this);
    }

    componentDidMount() {
        this.dateChange(new Date());
    }

    componentWillUnmount() {}

    shouldComponentUpdate(nextProps, nextState) {
        (!nextProps.fetchingEmployees && !nextProps.employeesData.empty() && nextProps.payrollForm.earnings.empty()) && this.createTableRows(this.props.employeesData);
        return true;
    }

    static getDerivedStateFromProps(props, state) {
        return null;
    }

    dateChange(date) {
        const fieldSet = {
            payrollDate: date
        };
        this.props.payrollFieldDidChange(fieldSet);
    }

    addInputField(id, name, cell) {
        const fieldSet = {
            id : id,
            name: name,
            cell: cell,
            attr: 'use',
            value: true
        };
        this.props.payrollFieldDidChange(fieldSet);
    }

    inputFieldChange(id, name, cell, evt) {
        switch(cell) {
            case 'wageSalary':
                let fieldSet = {
                    id : id,
                    name: name,
                    cell: cell,
                    attr: name,
                    value: evt.target.value
                };
                this.props.payrollWageSalaryChange(fieldSet);
                break;
            default:
                fieldSet = {
                    id : id,
                    name: name,
                    cell: cell,
                    attr: 'amount',
                    value: evt.target.value
                };
                this.props.payrollFieldDidChange(fieldSet);
        }
    }
    
    onBlurHandler(id, name, cell, evt) {
        const fieldSet = {
            target: {
                value: name === 'hours' ? evt.target.value : toDecimal(evt.target.value)
            } 
        };
        this.inputFieldChange(id, name, cell, fieldSet);
    }

    calcBasicSalary(basicRate, details) {
        return toDecimal(this.props.getSalaryByPayPeriod(basicRate, details.payCycle));
    }

    createTableRows(employeesData) {
        const rowData = (key, data) => ({
            id: key,
            value: data,
        });

        let generateRow =  (data, i) => ({
            id: i,
            employee: rowData(i, data),
            wageSalary: rowData(i, { 
                payCycle: data.details?.payCycle,
                isFixedSalary: data.details.isFixedSalary, 
                basicRate:  data.details.isFixedSalary ? this.calcBasicSalary(data.details.salary, data.details) :  toDecimal(data.details.hourlyRate),
                salary:  toDecimal(data.details.salary),
                hours: 40 
            } ),
            addedEarnings: rowData(i, {
                commission: {amount: toDecimal(0), use: false},
                bonus: {amount: toDecimal(0), use: false} 
             }),
            grossPay: rowData(i, { 
                grossPay: toDecimal(0), 
                pension: {amount: toDecimal(0), use: false},
                reimbursement: {amount: toDecimal(0), use: false} }),
        });

        const tableData = employeesData?.map((row, i) => generateRow(row, i)) || [];

        const fieldSet = {
            setDefaults: true,
            earnings : tableData
        };
        
        (!this.props.payrollForm.setDefaults && this.props.viewInit) && this.props.payrollFormSetDefaults(fieldSet);

        return tableData;
    }


    calculateGrossPay(row) {
        let bonus = row.addedEarnings.value.bonus.amount === '' ? 0.0 : parseFloat(row.addedEarnings.value.bonus.amount);
        let commission = row.addedEarnings.value.commission.amount === '' ? 0.0 : parseFloat(row.addedEarnings.value.commission.amount);

        let additionalEarnings = bonus + commission;
        additionalEarnings = additionalEarnings === NaN ? 0.0 : additionalEarnings;
        const basePay = row.wageSalary.value.isFixedSalary ? 
                        row.wageSalary.value.basicRate : 
                        row.wageSalary.value.basicRate * row.wageSalary.value.hours;
        const grossPay = parseFloat(basePay) + additionalEarnings;

        return grossPay;
    
    }

    render () {
        
        const tableActionsCallback = {
            addInputFieldHandler: this.addInputField,
            cellTextFieldChange: this.inputFieldChange,
            onBlurHandler: this.onBlurHandler,
            getSalaryByPayPeriod: this.props.getSalaryByPayPeriod
        }
        
        let earningsRowsData = this.props.payrollForm.earnings; 
        //this.props.payrollForm.earnings.empty() ? this.createTableRows(this.props.employeesData) : this.props.payrollForm.earnings;
        
        earningsRowsData = earningsRowsData.map((row) => {
            row.grossPay.value.grossPay = this.calculateGrossPay(row);
            return {
                ...row
            };
        });

        const showLoader = this.props.fetchingEmployees;
    
        return (
            <React.Fragment>
           <div className="earnings-pane">
                <FormGroup className="date-wrap">
                    <div className="date-field-group">
                        <div className="ip-date">
                            <Label>Payroll date</Label>
                            <DateTimePicker 
                                className="date-picker"
                                clearIcon={null}
                                disableClock
                                time={false}
                                format={"MM-dd-yyyy"}
                                calendarIcon={null}
                                onChange={this.dateChange}
                                value={this.props.payrollForm.payrollDate} />

                                <span className="icon">
                                    <img  src={getIconUrl("date")}/>
                                </span>
                        </div>
                    </div>
                </FormGroup>

                <div className="table-wrap">
                        
                        <Loader loadingText="Gathering Eamployee's..." visible={showLoader}/>
                        
                        <EarningsTable 
                            payrollFieldDidChange={this.props.payrollFieldDidChange}
                            ref={this.tableRef}
                            actionHandlers={tableActionsCallback}
                            tableData={earningsRowsData}
                        />
                </div>
           </div>
           <Section className="actions-section">
               <div className="btns-wrap">
                    {this.props.BackButton}
                    <Button  disabled={ earningsRowsData.length === 0 } onClick={this.props.nextStep}>
                        Taxes
                    </Button>
                </div>
            </Section>
            </React.Fragment>
        );
    }
}

export { EarningsPane };