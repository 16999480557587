import { decodeToken, isExpired } from 'react-jwt';
import { differenceInYears, parse } from 'date-fns';

export const decodeJwtToken = function (token) {
  let claims = decodeToken(token);
  return claims;
};

export const isJwtTokenExpired = function (token) {
  let expired = isExpired(token);
  return expired;
};

export const createSingleton = (createInstance) => {
  let instance = undefined;
  return {
    getInstance: () => instance || (instance = createInstance()),
  };
};

export const getActiveUser = () => {
  const currentUser = window.localStorage.getMap('activeUser');
  return !Object.blank(currentUser) && currentUser.user !== ''
    ? currentUser
    : null;
};

export function setChangeListener(element, eventHandler) {
  //element.addEventListener("input", eventHandler);
  element.addEventListener('blur', eventHandler);
  element.addEventListener('keyup', eventHandler);
  element.addEventListener('paste', eventHandler);
  element.addEventListener('copy', eventHandler);
  element.addEventListener('cut', eventHandler);
  element.addEventListener('delete', eventHandler);
  element.addEventListener('mouseup', eventHandler);
}

/**
 * Returns a random number between min (inclusive) and max (exclusive)
 */
export function GenerateRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 */
export function generateDigits(digits) {
  var length = 1;
  var value = Array.apply(null, { length: length || 100 }).map(function () {
    return Math.floor(
      Math.random() * Math.pow(10, digits) + Math.pow(10, digits)
    )
      .toString()
      .slice(-digits);
  });

  return `${value}`;
}

export function delay(delayInms) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(2);
    }, delayInms);
  });
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function timeDelayed(i, ms, progressive = false, baseMs = 0) {
  let zeroMs = 0;
  const waitTime = i === 0 ? zeroMs : baseMs + ms / i;
  return progressive ? parseInt(waitTime) : i * ms;
}

export const pictureInitials = (firstName, lastName) => {
  if (!firstName || !lastName) return ''
  const firstInitial = firstName.substr(0, 1);
  const lastInitial = lastName.substr(0, 1);
  return `${firstInitial}${lastInitial}`;
};

export const getHexColor = () => {
  const randomColor = Math.floor(Math.random() * 16777215).toString(16);
  return '#' + randomColor;
};

export const formatDate = (date, locale='en-GB') => {
  let d = date;
  let ye = new Intl.DateTimeFormat(locale, { year: 'numeric' }).format(d);
  let mo = new Intl.DateTimeFormat(locale, { month: 'short' }).format(d);
  let da = new Intl.DateTimeFormat(locale, { day: '2-digit' }).format(d);
  return `${mo} ${da}, ${ye}`;
};

export const stripHtmlTags = (htmlText) =>
  htmlText.replace(/(<([^>]+)>)/gi, '');

export const objToFormData = (obj, formData = new FormData()) => {
  let createFormData = function (obj, subKeyStr = '') {
    for (let i in obj) {
      let value = obj[i];
      let subKeyStrTrans = subKeyStr ? subKeyStr + '[' + i + ']' : i;
      if (
        typeof value === 'string' ||
        typeof value === 'number' ||
        typeof value === 'boolean'
      ) {
        formData.append(subKeyStrTrans, value);
      } else if (typeof value === 'object') {
        createFormData(value, subKeyStrTrans);
      }
    }
  };
  createFormData(obj);
  return formData;
};

export function removeTextCharacters(value) {
  return String(value).replace(/\D/g, '');
}

export function clampString(str, limit) {
  return String(str).slice(0, limit);
}

export function formatPhoneNumber(rawPhoneNumber) {
  const number = clampString(removeTextCharacters(rawPhoneNumber), 10);

  return formatString(number, { 3: '-', 7: '-' });
}

/**
 *
 * @param {*} str a string
 * @param {*} indexSymbolMap a map that has an index location as the key value and the symbol
 * that should be placed at that location as the value.
 */
export function formatString(str, indexSymbolMap) {
  let resultText = str;
  const indexKeys = Object.keys(indexSymbolMap);

  for (let key of indexKeys) {
    const index = Number(key);

    //if the text is long enough to now add the symbol
    if (str.length > index) {
      resultText =
        resultText.slice(0, index) +
        indexSymbolMap[index] +
        resultText.slice(index);
    }
  }

  return resultText;
}

export const calculatePercent = (divident, diviser) => {
  return parseFloat(divident / diviser) * 100;
};

// a simple implementation of the shallowCompare.
// only compares the first level properties and hence shallow.
// state updates(theoretically) if this function returns true.
export function shallowCompare(newObj, prevObj) {
  for (var key in newObj) {
    if (newObj[key] !== prevObj[key]) return true;
  }
  return false;
}

export function objListsAreEqual(arr1, arr2, comparisonKey = '') {
  if (typeof arr1 !== typeof arr2) return false;

  if (!Array.isArray(arr1)) throw Error('arr1 must be an array');
  if (!Array.isArray(arr2)) throw Error('arr2 must be an array');

  if (arr1.length !== arr2.length) {
    return false;
  } else {
    arr1.sort((item1, item2) =>
      item1[comparisonKey] < item2[comparisonKey] ? -1 : 1
    );
    arr2.sort((item1, item2) =>
      item1[comparisonKey] < item2[comparisonKey] ? -1 : 1
    );

    for (let i = 0; i < arr1.length; i++) {
      if (shallowCompare(arr1[i], arr2[i])) {
        return false;
      }
    }
  }

  return true;
}

export function printEntriesInFormData(formData) {
  for (let pair of formData.entries()) {
    console.log(pair[0] + ', ' + pair[1]);
  }
}

export function toResultDateFormat(dateString) {
  
  if (dateString === undefined || !dateString.length) return ''
  
  let sanitizedDateString = dateString;
  let hasTimestamp = false;

  /**
   * This is necessary because if a date string includes a timestamp like 18:18 PM or AM is entered it will
   * cause the resulting date to be invalid.
   */
  if (
    sanitizedDateString.includes('AM') ||
    sanitizedDateString.includes('PM')
  ) {
    sanitizedDateString = sanitizedDateString.slice(0, -2);
    hasTimestamp = true;
  }
  const dateObj = new Date(sanitizedDateString);

  const date = dateObj.toLocaleDateString('en-GB');
  let time = '';
  if (hasTimestamp)
    time = dateObj.toTimeString().split(/[:\s]/g).slice(0, 2).join(':');

  return `${date} ${time}`;
}

export function capitializeSentence(sentence) {
  if (!sentence) return;
  
  return String(sentence)
    .toLowerCase()
    .split(' ')
    .map((word, index) => {
      //only the first word of the sentence.
      if (index === 0 && word.length > 1) return word[0].toUpperCase() + word.slice(1);
      return word;
    })
    .join(' ');
}

/**
 * Returns a new date after adding 'daysToAdd' to the given 'date'.
 * @param {*} date - A valid Date object or Date string.
 * @param {*} daysToAdd - Number of days to be added.
 * @returns
 */
export function addDaysToDate(date, daysToAdd) {
  const _date = new Date(date);

  if (
    typeof daysToAdd !== 'number' ||
    daysToAdd === Infinity ||
    daysToAdd === -Infinity ||
    isNaN(daysToAdd) ||
    isNaN(_date.getTime())
  )
    return new Date();

  return new Date(_date.getTime() + daysToMilliSeconds(daysToAdd));
}

export function daysToMilliSeconds(days) {
  const MILLISECONDS_IN_DAY = 86400000;
  return days * MILLISECONDS_IN_DAY;
}

export function debounce(callBackFn, delayMs) {
  if (typeof callBackFn !== 'function')
    throw Error(
      `callBackFn must be a function, but was found to be a ${typeof callBackFn}`
    );

  let timerId;
  const defaultDelayMS = 300;
  const _delay =
    typeof delayMs !== 'number' || isNaN(delayMs) ? defaultDelayMS : delayMs;

  return (...args) => {
    clearTimeout(timerId);

    timerId = setTimeout(() => {
      callBackFn(...args);
    }, _delay);
  };
}

export const fadeIn = (element, duration) => {
  (function increment(value = 0) {
    element.style.opacity = String(value);
    if (element.style.opacity !== '1') {
      setTimeout(() => {
        increment(value + 0.1);
      }, duration / 10);
    }
  })();
};

export const fadeOut = (element, duration) => {
  (function decrement() {
    (element.style.opacity -= 0.1) < 0
      ? (element.style.display = 'none')
      : setTimeout(() => {
          decrement();
        }, duration / 10);
  })();
};

/**
 * Returns true if date is valid.
 */
export function isValidDate(date){
  //a valid date will return a number from getTime.
  return !isNaN(new Date(date).getTime());
}

/**
 * Returns true if number is valid.
 */
export function isValidNumber(value){
  return !isNaN(Number(value));
}

/**
 * Returns a date object formatted to a given timezone and locale
 */
export function getDateInTimeZone(dateObject){
  let dateString = getDateInTimeZoneString({date: dateObject});
  
  //date string will have the format -> 22/06/2022, 15:13:58. Need to remove the time part.
  dateString = dateString.split(",")[0];

  return parse(dateString, 'dd/MM/yyyy', new Date());
}

/**
 * Returns a date object formatted to a given timezone and locale
 */
 export function getDateInTimeZoneString({date, timeZoneString = 'America/Jamaica', locale = 'en-GB'}){

  let options = {
    timeZone: timeZoneString,
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true 
  },
  formatter = new Intl.DateTimeFormat(locale, options);

  const dateString = formatter.format(date);

  if(!isValidDate(date)) throw new Error(`date must be a valid Date, but was of type ${typeof date}`);
  if(typeof timeZoneString !== 'string') throw new Error(`timeZoneString must be of type string, but was of type ${typeof timeZoneString}`);
  if(typeof locale !== 'string') throw new Error(`locale must be of type string, but was of type ${typeof locale}`);

  //const dstring = new Date(date).toString(locale, {timeZone: timeZoneString});
  return dateString;
}

/**
 * Given an age, birth month, and birth day, returns the year in which a person was born.
 * This is specific to the patient age problem and how the patient form works.
 * @param {*} age - the age.
 * @param {*} day - the day in Date.getDate() format.
 * @param {*} month - the month in Date.getMonth() format where 0 = January, and so on.
 */
export function calculateYearOfBirth(age, day, month){
  const _age = Number(age);
  const _day = Number(day);
  const _month = Number(month);

  if(!isValidNumber(_age)) throw new Error(`age must be a valid number, but was of type ${typeof age}`);
  if(!isValidNumber(_day)) throw new Error(`day must be a valid number, but was of type ${typeof day}`);
  if(!isValidNumber(_month)) throw new Error(`month must be a valid number, but was of type ${typeof month}`);

  const today = getDateInTimeZone(new Date());
  let birthYear = _age > 0 ? today.getFullYear() - _age : today.getFullYear();

  const monthDiff = today.getMonth() - _month;

  //birth month has passed. No need to adjust year
  if(monthDiff >= 1) return birthYear;

  //birth month hasn't passed yet.
  if(monthDiff < 0) return --birthYear;

  //currently in birth month, see if birth day has passed.
  const dayDiff = today.getDate() - _day;

  //Not yet the person's birthday.
  if(dayDiff < 0) return --birthYear;

  //birthday has passed or is today. No need to adjust year.
  if(dayDiff >= 0) return birthYear;
}

export function calculateAge(dateOfBirth) {
  const _DOB =
    typeof dateOfBirth !== 'object' ? new Date(dateOfBirth) : dateOfBirth;

  return differenceInYears(new Date(), _DOB);
}

/**
 * Given a name, returns the initials. For e.g: 
 * Jane Doe = J.D
 * Jane H. Doe = J.H.D
 * Jane H. Doe-Brown = J.H.D-B
 * Doe = D
 * Dr Doe-Brown = D-B
 * Dr Jane Doe-Brown = J.D-B
 * @param {*} fullName 
 */
export function getInitials(fullName, includeTitle) {
  let _fullName = String(fullName).trim();
  let finalInitials = '';
  const nameParts = _fullName.split(' ');

  //if the name includes the 'Dr' title.
  const hasTitle = new RegExp(/^Dr[\W]/gi).test(_fullName);
  let title = "";

  //Stores the title
  if(hasTitle) title = nameParts.shift();

  //Splits each part of the name by '-' and gets the initial for each section.
  finalInitials = nameParts.map((name) => name.split('-').map(n => getFirstLetter(n)).join('-'))
  .join('.');

  return includeTitle ? title + " " + finalInitials : finalInitials;
}

function getFirstLetter(text) {
  return text[0];
}


export function objectToQueryString(baseUri, paramsObj) {
  let uri = baseUri;
    const queryStringList = [];
    
    Object.keys(paramsObj).forEach((key, i) => {
        const value = paramsObj[key];
        if (value && value !== '') {
            queryStringList.push(`${key}=${value}`);
        }
    })
    
    queryStringList.forEach((keyPair, i) => {
        uri +=  i === 0 ? `?${keyPair}` : `&${keyPair}`;
    });

    return uri;
}

// function m(n,d){x=(''+n).length,p=Math.pow,d=p(10,d)
// x-=x%3
// return Math.round(n*d/p(10,x))/d+" kMGTPE"[x/3]}

export function abbrNumber(number, decPlaces) {
  // 2 decimal places => 100, 3 => 1000, etc
  decPlaces = Math.pow(10, decPlaces);

  // Enumerate number abbreviations
  const abbrev = [ "k", "m", "b", "t" ];

  // Go through the array backwards, so we do the largest first
  for (let i = abbrev.length-1; i >= 0; i--) {

      // Convert array index to "1000", "1000000", etc
      let size = Math.pow(10,(i+1)*3);

      // If the number is bigger or equal do the abbreviation
      if(size <= number) {
           // Here, we multiply by decPlaces, round, and then divide by decPlaces.
           // This gives us nice rounding to a particular decimal place.
           number = Math.round(number*decPlaces/size)/decPlaces;

           // Add the letter for the abbreviation
           number += abbrev[i];

           // We are done... stop
           break;
      }
  }

  return number;
}
