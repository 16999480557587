import { schema } from 'normalizr';
import { object } from 'underscore';

/**
 * Mutates the entity adding a unique id if one doesnt exist
 * @param {Object} value - entity
 * @returns {string} uid
 */
const processStrategy = value => {
  return {idAttribute: () => Math.random().toString()};
};

const permissionSchema = new schema.Entity('permissions', {
    idAttribute: 'id',
});
//* Entity
const roleSchema = new schema.Entity('roles', [{
    idAttribute: 'id',
}]);

const messageSchema = new schema.Entity('messages', undefined, processStrategy());

const notificationSchema = new schema.Entity('notifications', [{
    idAttribute: 'id',
}]);

const patientSchema = new schema.Entity('patient', {
    idAttribute: 'id',
});

const specimenTypeSchema = new schema.Entity('specimenType', undefined ,  processStrategy())

const clinicalFeatureSchema = new schema.Entity('clinicalFeature', [{
    idAttribute: 'id',
}]);

/*roleSchema.define({
    permissions: [permissionSchema]
})*/

/**/
//export const roleSchemaArray
export const rolesArraySchema = new schema.Array(roleSchema);
export const permissionsArraySchema = new schema.Array(permissionSchema);

export const notificationsArraySchema = new schema.Array(notificationSchema);
export const unreadMessagesArraySchema = new schema.Array(messageSchema);

export const patientsArraySchema = new schema.Array(patientSchema);
export const formDetailsSchema = new schema.Object({
    specimenTypes: new schema.Array(specimenTypeSchema),
    clinicalFeatures: new schema.Array(clinicalFeatureSchema),  
})
