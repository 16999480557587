import * as actionTypes from '../actionType';
import { isError } from '../../service/agent';
import { API_OUTCOME_SUFFIX, REQUESTS_TYPE } from '../../common/constant';
import { combineReducers } from 'redux';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
import { PAGE_SIZE } from '../../config/pagination';

var error = null;
let emptyArray = [];
let requestManager = null;
let apiOutcome = null;

const initialSamplesState = {
    lazyLoad: false,
    viewInit: false,
    patientsList: null,
    clientsList: null,
    pageLoaded: 0,
    formDetails: null,
    activeView: '',
    activePage: null,
    patientLink: false,
    specimenForm: {
        labNumber: '',
        doctor: '',
        patient: null,
        vialsamples: [],
        clinicalFeatures: {},
        otherClinicalInfo: '',
        therapy: {}
    },
    specimenRecord: {},
    specimensData: null,
    recentSpecimensData: null,
    approvedSpecimensData: null,
    cabinetSpecimens: null,
    cabinetsData: null,
    cabinetsTableMeta: {
        currentPage: 0,
        totalItems: 0,
        maxPages: 0
    },
    cabinetContextItem: null,
    syncedForm: false,
    clientRequisitions: [],
};

const initialRequisitionState = {
    viewInit: false,
    clientsList: null,
    requisition: null,
    requisitionForm: {
        client: null,
        dateReceived: new Date(),
        items: [],
        totalAmount: 0
    }
};

const initialFetchRequisitionState = {
    viewInit: false,
    requisitionsList: null,
    pageLoaded: 0,
};

const initialResultSheetState = {
    viewInit: false,
    resultSheet: null,
};


const responseOutcome = (state, action) => {
    switch (action.type) {
        
        case actionTypes.RESULT_SHEET_SUBMIT:
            return {
                inProgress: true
            }
        case `${actionTypes.FETCH_REQUISITIONS}_${API_OUTCOME_SUFFIX}`:
            return {
                ...action.payload
            }
        case `${actionTypes.RESULT_SHEET_SUBMIT}_${API_OUTCOME_SUFFIX}`:
            return {
                ...action.payload
            }
        default:
            return state
    }
}

const samplesState = (state = initialSamplesState, action ) => { 

    switch(action.type) {
        case actionTypes.SPECIMEN_VIEW_LOADED: 
        case actionTypes.SPECIMEN_UPDATE_VIEW_LOADED: 
            return {
                ...initialSamplesState,
                specimenRecord: state.specimenRecord ?  state.specimenRecord : {},
                requisitionLinkItem:  state.requisitionLinkItem ? state.requisitionLinkItem : null,
                patientLink: state.patientLink,
                viewInit: true,
                activePage: state.activePage || null
            };
        case actionTypes.SPECIMEN_VIEW_UNLOAD: 
            const { specimenRecord, requisitionLinkItem } = state;
            if ( specimenRecord ) {
                return {
                    ...initialSamplesState,
                    specimenRecord: specimenRecord,
                    requisitionLinkItem: !state.viewInit ? requisitionLinkItem : null,
                    patientLink: state.patientLink,
                    cabinetsData: state.cabinetsData,
                };
            }else {
                return {
                    ...initialSamplesState,
                    requisitionLinkItem: state.requisitionLinkItem ? state.requisitionLinkItem : null,
                    patientLink: state.patientLink,
                    cabinetsData: state.cabinetsData,
                };
            }
        case actionTypes.SPECIMEN_UPDATE_VIEW_UNLOAD:
            const { resultSheet, activePage } = state;
            if ( resultSheet ) {
                return {
                    ...initialSamplesState,
                    specimenRecord: state.specimenRecord,
                    cabinetsData: state.cabinetsData,
                };
            } if(activePage) {
                return {
                    ...initialSamplesState,
                    activePage: state.activePage,
                    requisitionLinkItem: state.requisitionLinkItem ? state.requisitionLinkItem : null,
                    patientLink: state.patientLink,
                    cabinetsData: state.cabinetsData,
                }
            }else {
                return {
                    ...initialSamplesState,
                    requisitionLinkItem: state.requisitionLinkItem ? state.requisitionLinkItem : null,
                    patientLink: state.patientLink,
                    cabinetsData: state.cabinetsData,
                };
            }   
        case actionTypes.FETCHED_FORM_DETAILS_RESULTS: 
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.FETCH_FORM_DETAILS) ?? {};

            error = isError(action.payload.patients);
            //const patients = error ? emptyArray : action.payload.patients;
            error = isError(action.payload.formDetails);
            const formDetails = error ? emptyArray : action.payload.formDetails;
            return {
                ...state,
                //patientsList: patients,
                formDetails: formDetails
            };

        case actionTypes.FETCHED_SEARCH_PATIENTS_RESULTS:
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.FETCH_SEARCH_PATIENTS) ?? {};

            return {
                ...state,
                patientsList: apiOutcome?.success ? action.payload : emptyArray
            }
            
        case actionTypes.FETCHED_ACCOUNTS_RESULT:
            error = isError(action.payload);

            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.FETCH_ACCOUNTS, true) ?? {};

            return {
                ...state,
                clientsList: apiOutcome?.success ?  action.payload : emptyArray
            } 
        case actionTypes.SPECIMEN_PAGE_ACTIVE_VIEW:
            return {
                ...state,
                activePage: action.payload
            }
        case actionTypes.SPECIMEN_FORM_VIEW:
            return {
                ...state,
                activeView: action.payload
            }
        case actionTypes.SPECIMEN_CLIENT_SETDATA:
            return {
                ...state,
                specimenForm: {
                    ...state.specimenForm,
                    account: action.payload.account
                }
            }
        case actionTypes.SPECIMEN_FORM_FIELD_CHANGED :

            const { clinicalFeatures } = action.payload;
            const features = clinicalFeatures ? clinicalFeatures : {};
            const { therapy } = action.payload;
            const therapyItem = therapy ? therapy : {};

            return {
                ...state,
                specimenForm: {
                    ...state.specimenForm,
                    ...action.payload,
                    clinicalFeatures: {
                        ...state.specimenForm.clinicalFeatures,
                        ...features
                    },
                    therapy : {
                        ...state.specimenForm.therapy,
                        ...therapyItem
                    }
                }
        }
        case actionTypes.SPECIMEN_SUBMIT_DATA: 
            return {
                ...state,
                populatedForm: false
            } 
        case actionTypes.SPECIMEN_SUBMITED_RESULTS:
                
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.SPECIMEN_SUBMIT_DATA) ?? {};

            if (apiOutcome.requestType === REQUESTS_TYPE.DELETE) {
                const isDeletedResults = apiOutcome.error ? {} : action.payload;

                return apiOutcome.error ? { ...state } : {
                    ...initialSamplesState,
                    ...isDeletedResults,
                    requisitionLinkItem: state.requisitionLinkItem ? state.requisitionLinkItem : null,
                    patientLink: state.patientLink,
                }
            }else {
                return {
                    ...state,
                    specimenRecord: apiOutcome.error ? {} : action.payload,
                    syncedForm: false,
                    requisitionLinkItem: state.requisitionLinkItem ? state.requisitionLinkItem : null,
                    ...apiOutcome
                }
            }
        case actionTypes.SPECIMEN_SET_LAZY_LOADING:
            return {
                ...state,
                lazyLoad: action.payload
            }
        case actionTypes.FETCHED_SPECIMEN_RECORDS_RESULTS:
            let specimens = state.specimensData === null ? emptyArray : state.specimensData;

            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.FETCH_SPECIMEN_RECORDS, true) ?? {};
    
            if ( apiOutcome && apiOutcome?.success && action.payload && ((Array.isArray(specimens) && specimens?.empty()) || specimens?.length < action.payload.totalItems) ) {
                specimens = [
                    ...specimens,
                    ...action.payload?.pageData || []
                ]
            }
            const nextPage = apiOutcome?.error ? state.pageLoaded : (Array.isArray(action.payload.pageData) && action.payload.pageData?.length === 0)? state.pageLoaded :  action.payload.pageData?.length !== PAGE_SIZE ?  state.pageLoaded: state.pageLoaded + 1;

            return {
                ...state,
                lazyLoad: false,
                specimensData: specimens,
                pageLoaded: nextPage
            }

        case actionTypes.FETCHED_SPECIMEN_RECORDS_APPROVED_RESULTS : {
            const {approvedSpecimensData, ...newState} = state;
            apiOutcome = requestManager.getApiOutcome(actionTypes.FETCH_SPECIMEN_RECORDS_APPROVED) ?? {};
            let specimens = state.approvedSpecimensData === null ? emptyArray : state.approvedSpecimensData;
           
            if ( apiOutcome?.success && action.payload &&  (specimens.empty() || specimens.length < action.payload.monthRecords.totalItems) ) {
                let monthRecords = specimens.monthRecords === undefined ? [
                    ...action.payload.monthRecords.pageData
                ] : [
                    ...specimens.monthRecords,
                    ...action.payload.monthRecords.pageData
                ]

                specimens = {
                    ...specimens,
                    weekRecords: action.payload.weekRecords.pageData,
                    monthRecords: monthRecords
                }
            }
            
            const nextPage = apiOutcome?.error ? state.pageLoaded : (Array.isArray(action.payload.monthRecords.pageData) && action.payload.monthRecords.pageData.length === 0)? state.pageLoaded :  action.payload.monthRecords.pageData.length !== PAGE_SIZE ?  state.pageLoaded: state.pageLoaded + 1;

            return {
                ...state,
                lazyLoad: false,
                approvedSpecimensData: specimens,
                pageLoaded: nextPage
            }
        }
        case actionTypes.FETCHED_RECENT_SPECIMEN_RECORDS_RESULTS : {
            
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.FETCH_RECENT_SPECIMEN_RECORDS) ?? {};
            let specimens = state.recentSpecimensData === null ? emptyArray : state.recentSpecimensData;
           
            if ( apiOutcome?.success && action.payload && (specimens.empty() || specimens.length < action.payload.monthRecords.totalItems) ) {
                let monthRecords = specimens.monthRecords === undefined ? [
                    ...action.payload.monthRecords.pageData
                ] : [
                    ...specimens.monthRecords,
                    ...action.payload.monthRecords.pageData
                ]
                specimens = {
                    ...specimens,
                    todaysRecords: action.payload.todaysRecords.pageData,
                    weekRecords: action.payload.weekRecords.pageData,
                    monthRecords: monthRecords
                }
            }
            
            const nextPage = apiOutcome?.error ? state.pageLoaded : (Array.isArray(action.payload.monthRecords.pageData) && action.payload.monthRecords.pageData.length === 0)? state.pageLoaded :  action.payload.monthRecords.pageData.length !== PAGE_SIZE ?  state.pageLoaded: state.pageLoaded + 1;

            return {
                ...state,
                lazyLoad: false,
                recentSpecimensData: specimens,
                pageLoaded: nextPage
            }
        }

        case actionTypes.SPECIMEN_EDIT_SETDATA:
            const { specimen, ...formData } = action.payload
            
            const data  = {
                ...state,
                specimenForm: formData,
                specimenRecord: specimen
            }
            return data;
        case actionTypes.SPECIMEN_RESULT_SHEET:
            return {
                ...state,
                specimenRecord: action.payload.specimen,
                resultSheet: action.payload.resultSheet
            }
        case actionTypes.TALBE_ROW_HOVER_EVENT:
            return {
            ...state,
            rowSpecimen: action.payload.specimen
        }
        case actionTypes.CONTEXT_MENU_EVENT:
    
            const specimensRecordList = state.specimensData || state.recentSpecimensData?.monthRecords || state.approvedSpecimensData?.monthRecords || [] ;

            const contextSpecimen = specimensRecordList  && specimensRecordList.find((item) => item.id === action.payload);

            return {
            ...state,
            rowSpecimen: !action.payload ? state.rowSpecimen : contextSpecimen,
            rowSpecimenContext: action.payload,
            //rowSpecimen: action.payload ? state.rowSpecimen : {} 
        }
        case actionTypes.SPECIMEN_ADD_STATUS_RESULT:
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.SPECIMEN_ADD_STATUS) ?? {};
            if ( apiOutcome?.error ) {
                return {
                    ...state
                }
            }else {

                const {specimenRecord, ...status} = action.payload;
                if ( !Object.blank(state.specimenRecord)) {
                    return {
                        ...state,
                        specimenRecord: {
                            ...state.specimenRecord,
                            statuses: [
                                ...state.specimenRecord.statuses,
                                status
                            ]
                        }
                    }
                }else {

                    var specimensData = state.specimensData.map((specimen) => {
                        if (specimenRecord === specimen.id) {
                            const updateSpecimen = {
                                ...specimen,
                                statuses: [
                                    ...specimen.statuses,
                                    status
                                ]
                            }
                            return updateSpecimen;
                        }else {
                            return specimen
                        }
                    })
                    
                    return {
                        ...state,
                        specimensData: [
                            ...specimensData
                        ]
                    }
                }
            }
        /*ATTACH FILE TO SPECIMENT*/
        case actionTypes.SPECIMEN_ATTACH_FILE_RESULTS:
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.SPECIMEN_ATTACH_FILE) ?? {};
            return {
            ...state,
            specimenRecord : apiOutcome?.success ?  action.payload : state.specimenRecord
        }
        /*RESULT SHEET SCECTION*/
        case actionTypes.SPECIMEN_RESULTSHEET_VIEW_UNLOADED:
            return {
            ...state,
            resultSheet : null
        }
        case actionTypes.RESULT_SHEET_ADD_RESULT_ENTRY:

            const { resultEntries } = state.resultSheet ? state.resultSheet : emptyArray;
            return {
            ...state,
            resultSheet : {
                ...state.resultSheet,
                resultEntries: {
                    ...resultEntries,
                    [action.payload.specimen]: action.payload.resultLines
                }
            }
        }
        case actionTypes.RESULT_SHEET_ADD_REPORT :
            return {
            ...state,
            resultSheet : {
                ...state.resultSheet,
                reports: [
                    ...action.payload
                ]
            }
        }
        case actionTypes.RESULT_SHEET_DISCARD_REPORT :
            const { reports } = state.resultSheet ? state.resultSheet : emptyArray;
            return {
            ...state,
            resultSheet : {
                ...state.resultSheet,
                reports: [
                    ...action.payload
                ]
            }
        }
        case actionTypes.SET_SPECIMENT_RESULT_SHEET :
            return {
                ...state,
                resultSheet : {
                    ...action.payload
                }
            }
            
        case actionTypes.RESULT_SHEET_SUBMITED_RESULTS :
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.RESULT_SHEET_SUBMIT) ?? {};
            if (apiOutcome?.error) {
                return {
                    ...state,
                    error: apiOutcome.error
                }
            }
            const {status } = action.payload;
            const resultSheetPayload = action.payload.resultSheet ? action.payload.resultSheet : action.payload;

            const statuses = status ? [
                ...state.specimenRecord.statuses,
                    action.payload.status
            ] : [
                ...state.specimenRecord.statuses
            ];

            const resultLines = {};
            for(var i = 0; i <  resultSheetPayload.resultEntries.length; i++) {
                resultLines[resultSheetPayload.resultEntries[i].specimen] = resultSheetPayload.resultEntries[i].resultLines.map((item, i) => ({
                    index: i,
                    ...item
                }));
            }

            const updatedSpecimenRecord = {
                ...state.specimenRecord,
                statuses: [
                    ...statuses
                ],
                resultSheet: {
                    ...state.resultSheet,
                    ...resultSheetPayload,
                }
            };

            const updateSpecimensData = Array.blank(state.specimensData) ? [] : state.specimensData.map((specimen) => {
                if (specimen.id === updatedSpecimenRecord.id) {
                    return updatedSpecimenRecord;
                }else {
                    return specimen;
                }
            });

            return {
                ...state,
                specimensData: [
                    ...updateSpecimensData
                ],
                specimenRecord: {
                    ...updatedSpecimenRecord
                },
                resultSheet : {
                    ...state.resultSheet,
                    ...resultSheetPayload,
                    resultEntries: resultLines
                }
            }
        case actionTypes.FETCHED_SPECIMEN_RECORD_RUSULT :
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.FETCH_SPECIMEN_RECORD) ?? {};
            return {
                ...state,
                specimenRecord: apiOutcome?.success ? action.payload : {}
            }
        case actionTypes.DOWNLOAD_FILE_RESULT :
            return {
                ...state,
                fileDownload: action.payload
            }

        case actionTypes.PATIENT_SUBMITED_RESULTS:
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.PATIENT_SUBMIT_DATA) ?? {};
            // IMPORTANT REVISIT
            return {
                ...state,
                patientsList: apiOutcome?.error ? state.patientsList : !state.patientsList  ? [] : [
                    action.payload,
                    ...state.patientsList
                ]
            }
        case actionTypes.REQUISITION_SET_LINK_ITEM:
            return {
                ...state,
                requisitionLinkItem: action.payload.requisitionItem
            }
        case actionTypes.SPECIMEN_SET_PATIENT_LINK_ITEM:
            return {
                ...state,
                patientLink: action.payload,
            } 

        // Cabinet 
        case actionTypes.CLEAR_SPECIMENS_DATA:
            return {
                ...state,
                specimensData: null
            }
        case actionTypes.SET_CABINET_CONTEXT_ITEM:
            return {
                ...state,
                cabinetContextItem: action.payload
            }
        case actionTypes.FETCHED_SPECIMENS_FOR_CABINET_RESULTS:
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.FETCH_SPECIMENS_FOR_CABINET);
            const { pageData, ...tableMetaData } = action.payload;

            return {
                ...state,
                cabinetSpecimens: apiOutcome?.success ? pageData : emptyArray,
                cabinetsTableMeta: apiOutcome?.success ? tableMetaData : state.cabinetsTableMeta,
            }
        
        case actionTypes.CABINET_SUBMIT_DATA_RESULTS:
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.CABINET_SUBMIT_DATA);
            
            if(apiOutcome?.requestType === REQUESTS_TYPE.DELETE){
                return {
                    ...state,
                    cabinetsData: apiOutcome?.success ? state.cabinetsData.filter(cabinetData => (
                        cabinetData.id !== action.payload.cabinetId
                    )) : state.cabinetsData
                }
            }else if(apiOutcome?.requestType === REQUESTS_TYPE.SUBMIT){
                return {
                    ...state,
                    cabinetsData: apiOutcome?.success ? [action.payload, ...state.cabinetsData] : state.cabinetsData,
                    cabinetContextItem: apiOutcome?.success ? action.payload : state.cabinetContextItem
                }
            }else if(apiOutcome?.requestType === REQUESTS_TYPE.UPDATE){
                return {
                    ...state,
                    cabinetContextItem: apiOutcome?.success ? action.payload : state.cabinetContextItem,
                    cabinetsData: apiOutcome?.success ? state.cabinetsData.map(cabinetData => {
                        if(cabinetData.id === action.payload.id){
                            return {
                                ...action.payload
                            }
                        }

                        return cabinetData;
                    }) : state.cabinetsData
                }
            }else{
                return {
                    ...state
                }
            }

        case actionTypes.FETCHED_CABINETS_RESULT:
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.FETCH_CABINETS);

            return {
                ...state,
                cabinetsData: apiOutcome?.success ? action.payload : emptyArray,
            }

        case actionTypes.SPECIMEN_EDIT_SAMPLE_STATE:
            return {
                ...state,
                ...action.payload
            }

        //get requisition for client
        case actionTypes.FETCHED_CLIENT_REQUISITIONS_RESULTS:
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.FETCH_CLIENT_REQUISITIONS) ?? {};

            return {
                ...state,
                clientRequisitions: apiOutcome?.error ? state.clientRequisitions : action.payload,
            }

        default:
            return state;
    }
};

const resultSheetState = (state = initialResultSheetState, action) => {
   
    switch(action.type) {
        case actionTypes.RESULT_SHEET_VIEW_LOADED:
            return {
                ...initialResultSheetState
            }

        case actionTypes.RESULT_SHEET_SUBMITED_RESULTS:
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.RESULT_SHEET_SUBMIT) ?? {};

            return {
                ...state,
                resultSheet: apiOutcome?.success ? action.payload : null,
                ...responseOutcome(state, action)
            }
        default:
            return {
                ...state,
                ...responseOutcome(state, action),
            }
    }
}

const fetchRequisitionsState = (state = initialFetchRequisitionState.requisitionsList, action) => {
    switch (action.type) {
        case actionTypes.REQUISITION_VIEW_UNLOAD:
            return {
                ...initialFetchRequisitionState.requisitionsList
            }
        case actionTypes.FETCHED_REQUISITIONS_RESULTS:
            error = isError(action.payload);
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.FETCH_REQUISITIONS);

            return {
                ...state,
                requisitionsList: apiOutcome?.success ? action.payload : emptyArray,

            }
      default:
        return {
            ...state,
            ...responseOutcome(state, action),
        }
    }
}

const requisitionsState = (state = initialRequisitionState, action) => {
    switch (action.type) {
        case actionTypes.REQUISITION_VIEW_UNLOAD:
            return {
                ...initialRequisitionState,
                previousRequisitionPage: state.previousRequisitionPage
            }
        case actionTypes.REQUISITION_SET_PAGE_NUMBER:
            return {
                ...state,
                previousRequisitionPage: action.payload.pageNumber
            }
        case actionTypes.FETCHED_ACCOUNTS_RESULT:
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.FETCH_ACCOUNTS, true);
            return {
                ...state,
                clientsList: apiOutcome?.success ?  action.payload : emptyArray
            }
        case actionTypes.SHOW_REQUISITION_MODAL:
            return {
                ...state,
                showModal: action.payload,
            } 
        case actionTypes.REQUISITION_FORM_FIELD_CHANGED :
            const {isDeleted , ...otherState} = state;
            return {
                ...otherState,
                requisitionForm: {
                    ...state.requisitionForm,
                    ...action.payload
                }
            }
        case actionTypes.REQUISITION_SUBMIT_DATA:
            return {
                ...state,
                requestType: action.payload.requestType
            }
        case actionTypes.REQUISITION_SUBMITED_RESULTS:
            
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.REQUISITION_SUBMIT_DATA);

            if (apiOutcome?.requestType === 'delete') {
                const isDeletedResults = apiOutcome.error ? {} : action.payload;
                
                return {
                    ...state,
                    requisition: apiOutcome.error ? state.requisition : null,
                    requisitionForm: apiOutcome.error ? state.requisitionForm : initialRequisitionState.requisitionForm,
                    ...isDeletedResults
                }
            }else {
                return {
                    ...state,
                    requisition: apiOutcome?.success ?  action.payload : null
                }
            }
        case actionTypes.FETCHED_REQUISITION_RUSULT:
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.FETCH_REQUISITION);
            return {
                ...state,
                requisition: apiOutcome && apiOutcome?.success ?  action.payload : null
            }

        case actionTypes.REQUISITION_SETDATA:
            return {
                ...state,
                requisition: action.payload
            }

        case actionTypes.REQUISITION_ITEM_SUBMITTED_RESULTS:
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.REQUISITION_ITEM_SUBMIT_DATA);
            const updatedItems = Array.isArray(state.requisition?.items) && state.requisition.items.filter(item => item.id !== action.payload.requisitionItemId);
            
            const newTotal = updatedItems.reduce((accumulator, item)=> accumulator + item.cost, 0);

            if(apiOutcome?.requestType === REQUESTS_TYPE.DELETE){
                return {
                    ...state,
                    requisition: state.requisition ? {
                        ...state.requisition,
                        items: updatedItems,
                        totalAmount: newTotal,
                    } : null,
                    requisitionForm: {
                        ...state.requisitionForm,
                        items: updatedItems.map((item, index) =>({
                            ...item,
                            index
                        })),
                        totalAmount: newTotal,
                    }
                }
            }else{
                return {
                    ...state
                }
            }
      default:
        return state
    }
}

export default combineReducers({
    samplesState,
    requisitionsState,
    fetchRequisitionsState,
    resultSheetState,
  })

//export default specimenReducer;