
import {ofType } from 'redux-observable';
import { catchError, map, switchMap, mergeMap, takeUntil } from 'rxjs/operators';
import *  as actionTypes from '../actionType';
import { clientDialect, commonDialect, permissionDialect, checkReponse, apiOutcome } from '../../service/agent';
import { defer, from,of, forkJoin } from 'rxjs';
import * as actions from '../actions';
import { apiResponseActionCreator } from '../actions';
import  { requestEnded } from '../../service/agentDialect';

export const getAllClientsEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.FETCH_CLIENTS),
        switchMap((action) => {
            const request$ = defer(() => from(clientDialect.fetchAllClients(action.payload)));
            return request$.pipe(
               map(response => {
                    const responseData = checkReponse(response, action.type);
                    return  actions.apiResponseActionCreator(actionTypes.FETCHED_CLIENTS_RESULTS, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.FETCHED_CLIENTS_RESULTS + actionTypes.ABORT_API_REQUEST))),
                catchError(error => {
                    console.log("ERROR", error);
                    return of( actions.apiResponseActionCreator(actionTypes.FETCHED_CLIENTS_RESULTS, error));
               })
            );
        })
    )
}

export const getClientEpic = action$ => {
  return action$.pipe(
      ofType(actionTypes.FETCH_CLIENT),
      switchMap((action) => {
          const request$ = defer(() => from(clientDialect.fetchClient(action.payload)));
          return request$.pipe(
             map(response => {
                  const responseData = checkReponse(response, action.type);
                  return  actions.apiResponseActionCreator(actionTypes.FETCHED_CLIENT_RESULT, responseData);
              }),
              takeUntil(action$.pipe(ofType(actionTypes.FETCHED_CLIENT_RESULT + actionTypes.ABORT_API_REQUEST))),
             catchError(error => {
                  return of( actions.apiResponseActionCreator(actionTypes.FETCHED_CLIENT_RESULT, error));
             })
          );
      })
  )
}

export const fetchConcurrentUserWorkspaceRolesEpic = (action$, store) =>
  action$.pipe(
    ofType(actionTypes.FETCH_USER_WORKSPACE_ROLES),
    //map(action => action.payload),
    mergeMap((action) => {
        
      let  requestlist = [
        permissionDialect.fetchAllRoles(action.payload),//.catchError(err => of({type: 'error', payloa: err }) ),
        permissionDialect.fetchWorkspaceRoles(action.payload),//.catchError(err => of({type: 'error', payloa: err }) )
        commonDialect.fetchAllLabCodes()
      ];
      return forkJoin(
        requestlist
      )
    }),
    map(responses => {
    var allPayload = responses.map((response) => {
        return checkReponse(response);
    })
    allPayload = {
      userRoles: allPayload[0],
      workspaceRoles: allPayload[1],
      labCodes: allPayload[2]
    }

    apiOutcome.initiator = actionTypes.FETCH_USER_WORKSPACE_ROLES;
    apiOutcome.success = true;
    requestEnded(apiOutcome);

    return {type: actionTypes.FETCHED_USER_WORKSPACE_ROLES_RESULTS, payload: allPayload}
    }),
    takeUntil(action$.pipe(ofType(actionTypes.FETCH_USER_WORKSPACE_ROLES + actionTypes.ABORT_API_REQUEST))),
    catchError(err => {
      console.log(err);
    return of({type: 'error', payloa: err })})
    );

export const submitClientEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.CLIENT_SUBMIT_DATA),
        
        mergeMap((action) => {
          const apiCalls = {
            'delete': clientDialect.deleteClient,
            'create': clientDialect.submitClient,
            'update': clientDialect.updateClient
          }
            return apiCalls[action.payload.requestType](action.payload).pipe(
               map(response => {
                    const responseData = checkReponse(response, action.type, action.payload.requestType);
                    return apiResponseActionCreator(actionTypes.CLIENT_SUBMITED_RESULTS, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
               catchError(error => {
                    console.log("ERROR", error);
                    return of(apiResponseActionCreator(actionTypes.CLIENT_SUBMITED_RESULTS, error));
               })
            );
        })
)};

export const getClientOverviewDataEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.FETCH_CLIENT_OVERVIEW_DATA),
        mergeMap((action) => {
            return commonDialect.fetchDashboardData(action.payload).pipe(
                map(response => {
                    const responseData = checkReponse(response, action.type);
                    return  actions.apiResponseActionCreator(actionTypes.FETCHED_CLIENT_OVERVIEW_DATA_RESULT, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.FETCH_CLIENT_OVERVIEW_DATA + actionTypes.ABORT_API_REQUEST))),
                catchError(error => {
                    console.log("ERROR", error);
                    return of( actions.apiResponseActionCreator(actionTypes.FETCHED_CLIENT_OVERVIEW_DATA_RESULT, error));
                })
            );
        })
    )
}
