import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setupPage } from '../../../../components/Layout/setupPage';
import { 
    List,
    Input,
    FormGroup,
    Label,
    Section,
} from '../../../../components';

import { AddButton } from '../../../components/partials/Buttons';


import  * as actions  from '../../../../store/actions';
import * as actionTypes from '../../../../store/actionType';
import { ErrorMessage } from '../../../components/partials/ErrorMessage';
import text from '../../../../common/strings';
import { REQUESTS_TYPE, VALIDATOR_DELEGATE_KEY_GROUP, VALIDATOR_DELEGATE_TYPES } from '../../../../common/constant';
import { PromptMessage , getPromptMessageProps } from '../../../components/partials/PromptMessage';
import settingsValidatorDelegate from '../../../../forms/settingsValidator.delegate';
import { objListsAreEqual } from '../../../../utility/helpers';

import { getIconUrl } from '../../../../common/images-catalogue';

const mapStateToProps = state => ({
    ...state.settings.codeFindingSetting
});

const mapDispatchToProps = dispatch => ({
    setCodeFindings: (type, data) => dispatch(actions.setSettingDataAction(type, data)),
    getAllCodeFindings: () => dispatch(actions.getCodeFindingsAction()),
    submitData: (payload) => dispatch(actions.submitCodeFindingAction(payload))  
});

const CodeFindingItem = (props) => {
    const {data, actions} = props;

    const fieldChange = (evt) => {
        const values = {
            index: data.index,
            ...data,
            [evt.target.name]: evt.target.name !== 'abbreviation' ? evt.target.value : evt.target.value.toUpperCase()
        };
        if (data.id) {
            values.id = data.id;
        }
        actions.addOrChange(evt, data.index, values);
    };
    
    const isNewClass = !data.id ? '--new' : '';
    const text = !data.id ? 'Remove' : 'Delete';
    
    return (
        <li className={`code-sheet-item ${isNewClass}`}>
        <div className="code-sheet">
            <div className="ip-group">
                <FormGroup>
                    <Label>Abbreviated Code</Label>
                    {( data.abbreviation ? '' : <ErrorMessage message ='Please Enter Abbreviated Code ' /> ) }
                    <Input onChange={fieldChange} type='text' name={'abbreviation'} value={data.abbreviation}/>
                </FormGroup>
            </div>
            <FormGroup>
                <Label>Description</Label>
                {( data.description ? '' : <ErrorMessage message ='Please Enter Description ' /> ) }
                <Input  onChange={fieldChange} type='text' name={'description'} value={data.description}/>
            </FormGroup>
            <Link onClick={(evt) => actions.remove(evt, data)} to={'#'} className="remove"><img className={'icon'} src={getIconUrl('trash')} alt='' /> {text}</Link>
        </div> 
    </li>
    );
}
class CodeFindings extends React.Component {

    static pageOption = { 
        pageTitle: 'Client',
        disableBodyScroll: true
    };

    constructor (props) {
        super(props);

        props.apiRequestManager.register(actionTypes.FETCH_CODE_FINDINGS, props.getAllCodeFindings);
        props.apiRequestManager.register(actionTypes.CODEFINDING_SUBMIT_DATA, props.submitData);

        this.state = { };

        this.removeCodeFinding = this.removeCodeFinding.bind(this);
        this.addOrReplaceCodeFinding = this.addOrReplaceCodeFinding.bind(this);
        this.composeCodeFinding = this.composeCodeFinding.bind(this);
        this.onSaveHanlder = this.onSaveHanlder.bind(this);
        this.getPromptMessage = this.getPromptMessage.bind(this);
        this.validation = this.validation.bind(this);

        props.innerRef.saveHanlder = this.onSaveHanlder;
        props.innerRef.confirmDeleteCallback = this.onConfirmDelete.bind(this);
    }

    componentDidMount() {
        this.props.validator.addDelegate(VALIDATOR_DELEGATE_TYPES.settings, settingsValidatorDelegate)
     }

    static getDerivedStateFromProps(props, state) {
        if ( !props.codeFindingsData && !props.apiRequestManager.inProgress(actionTypes.FETCH_CODE_FINDINGS)) {
            props.apiRequestManager.queueRequest(actionTypes.FETCH_CODE_FINDINGS);
        }
        return null;
    }

    shouldComponentUpdate(nextProps){
        /**
         * This runs the validation if something changed in codeFindings list.
         */
        const prevList = this.props.codeFindingsData;
        const nextList = nextProps.codeFindingsData;

        if (Array.isArray(prevList) && Array.isArray(nextList) && !objListsAreEqual(prevList, nextList,'index')){
            const errors = this.validation(nextList);
            
            if(this.props.validator.hasActiveFormError(errors)){
                this.props.actions.enableSave(false);
            }else{
                this.props.actions.enableSave();
            }
        }

        return true;
    }

  getPromptMessage(){
      const apiOutcome = this.props.apiRequestManager.getApiOutcome(actionTypes.CODEFINDING_SUBMIT_DATA, true) ?? {};
      return getPromptMessageProps('codeFindings', apiOutcome);
  }

    removeCodeFinding(evt, finding) {
        evt.preventDefault();
        if (finding.id) {
            this.props.showDeleteDialog('codefinding', finding);
        }else {
            const codeFindings = this.props.codeFindingsData.filter((item) => finding.index !== item.index);
            this.props.setCodeFindings(actionTypes.SET_CODEFINDINGS_DATA, codeFindings);
        }
    }
    onConfirmDelete(deleteItem) {
        const payload = {
            requestType: REQUESTS_TYPE.DELETE,
            codeFindingId: deleteItem.id
        };
        
        this.props.apiRequestManager.queueRequest(actionTypes.CODEFINDING_SUBMIT_DATA, payload);
    }
    addOrReplaceCodeFinding(evt, itemIndex, codeFinding) {
        let codeFindings = [];
        if (itemIndex === undefined) {
            let codeFindingsWithNewEntry = [this.composeCodeFinding(), ...this.props.codeFindingsData];
            codeFindingsWithNewEntry.sortArrayObjectByKey('index')
            
            codeFindings = !this.props.codeFindingsData.length? [this.composeCodeFinding()] : codeFindingsWithNewEntry;
            this.props.setCodeFindings(actionTypes.SET_CODEFINDINGS_DATA, codeFindings);
        }else {
            codeFindings = this.props.codeFindingsData.map((item) => {
                if (item.index === itemIndex) {
                    item = {
                        ...codeFinding
                    }
                }
                return item;
            });
            this.props.setCodeFindings(actionTypes.SET_CODEFINDINGS_DATA, codeFindings);
        }
    }

    validation(codeFindings){
        return {
            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.settings, VALIDATOR_DELEGATE_KEY_GROUP.settings.codeFindings, codeFindings)
        }
    }

    onSaveHanlder() {
        const payload = {
            requestType: REQUESTS_TYPE.SUBMIT,
            data: this.props.codeFindingsData,
        }
        this.props.apiRequestManager.queueRequest(actionTypes.CODEFINDING_SUBMIT_DATA, payload);
    }
    
    composeCodeFinding() {
        return {
            index: 0,
            abbreviation: '',
            description: ''
        };
    }

    render () {
        const actions = {
            remove: this.removeCodeFinding,
            addOrChange: this.addOrReplaceCodeFinding
        }

        const codefindings = Array.isArray(this.props.codeFindingsData) ? this.props.codeFindingsData : [];

        const dialogProps = this.getPromptMessage();

        return (
            <React.Fragment>
                { dialogProps.visible && 
                        <PromptMessage {...dialogProps} />
                } 
                <div className='pane-layout-wrap pane code-sheet-pane'>
                    <div className="pane-cont">
                        <div className="setting-info">
                            <p>Code findings are used to represent the outcome of a sample analysis. Items created here are used when creating or modifying a <b>Result Sheet</b>.</p>
                        </div>
                        <div className="actions-btn">
                            <AddButton actionHandler={this.addOrReplaceCodeFinding} label={'Add Code Finding'} />
                        </div>
                        <Section className="code-sheet-list">
                            <List>
                                { codefindings.map((item, i) => {
                                    return  <CodeFindingItem key={i} data={item} actions={actions}/>
                                })}
                               
                            </List>
                        </Section>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const CodeFindingsView = (props) => {
    return (
        <CodeFindings {...props } />
    );
};

let ConfigCodeFindingsView = setupPage(CodeFindings.pageOption)(CodeFindingsView);
ConfigCodeFindingsView =  connect(mapStateToProps, mapDispatchToProps)(ConfigCodeFindingsView);

export { ConfigCodeFindingsView };