import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { Comparator, dateFilter } from 'react-bootstrap-table2-filter'
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import PropType  from 'prop-types';

import _ from 'lodash';
import { InputField, List , NavLink} from '../index';
import {pictureInitials} from './../../utility/helpers';
import { PaginationPanel, PaginationTotal } from './';
import { PAGE_SIZE } from '../../config/pagination';
import { getIconUrl } from '../../common/images-catalogue';
const INITIAL_PRODUCTS_COUNT = 8;


const generateRow = (data) => ({
    id: data.id,
    payroll: data,
    earnings: data,
    deductions: data,
    grosspay: data,
    netpay: data
});

const payrollInfo = (data) => {
   
    return (
        <ContextMenuTrigger id="payadvice_table_context_menu">
        <div className="payroll-info">
            <div className="payroll-info-holder">
                <div className="payroll-info-content">
                    <p># {data.payrollNo}</p>
                </div>
            </div>
        </div>
        </ContextMenuTrigger>
    );
}

const earningsInfo = (data) => {
    var amount = 0.0;
    for(var i =0; i < data.earnings.length; i++) {
        if (data.earnings[i].desc !== 'BaseWage' ) {
            amount += data.earnings[i].amount;
        } 
    };

    return (
        <ContextMenuTrigger id="users_table_context_menu">
        <div className="earnings-info">
            <div className="earnings-info-holder">
                <div className="earnings-info-content">
                    <p className="info">${amount.toCurrency()}</p>
                </div>
            </div>
        </div>
        </ContextMenuTrigger>
    );
}

const deductionsInfo = (data) => {
    
    var amount = 0.0;
    var taxAmount = 0.0;
    for(var i =0; i < data.earnings.length; i++) {
        if (data.deductions[i] && data.deductions[i].type !== 'Pay' ) {
            amount += data.deductions[i].amount;
        } 

        if (data.deductions[i] && data.deductions[i].type !== 'Tax' ) {
            taxAmount += data.deductions[i].amount;
        } 
    };

    return (
        <ContextMenuTrigger id="users_table_context_menu">
        <div className="deductions-info">
            <div className="deductions-info-holder">
                <div className="deductions-info-content">
                    <p className="info"><span className="label">Taxes:</span><span className="dedcution">${taxAmount.toCurrency()}</span></p>
                    <p className="info"><span className="label">Other:</span><span className="dedcution">${amount.toCurrency()}</span></p>
                </div>
            </div>
        </div>
        </ContextMenuTrigger>
    );
}

const grossPayInfo = (actions) => {
    //return function with cell content
    return (data) => (
        <div className="user-details">
            <ContextMenuTrigger id="users_table_context_menu">
            <div className="grosspay-details-holder">
                <div className="grosspay-details-content">
                    <p className="info"><span className="">${data.grossPay.toCurrency()}</span></p>
                </div>
            </div>
            </ContextMenuTrigger>
        </div>
    );
}

const netPayInfo = (actions) => {

return (data) => {
    return (
        <ContextMenuTrigger id="users_table_context_menu">
        <div className="netpay-info">
            <div className="toolbar">
                <List className="action-menu">
                    <li className="action-item">
                        <NavLink actiontag="view">
                            <span actiontag="view" onClick={(evt) => actions.viewAction(evt, data)} className="icon"><img className="icon" src={getIconUrl("view")} alt='' /></span>
                        </NavLink>
                    </li>
                    <li className="action-item">
                        <NavLink >
                            <span className="icon"><img className="icon" src={getIconUrl("option")} alt='' /></span>
                        </NavLink>
                    </li>
                </List>
            </div>
            <div className="netpay-info-holder">
                <div className="netpay-info-content">
                    <p className="info"><span className="highlight">${data.netPay.toCurrency()}</span></p>
                </div>
            </div>
        </div>
        </ContextMenuTrigger>
    );
    }
}


class PayAdviceTable extends React.Component { 
    static propsTypes = {
        tableData: PropType.array.require,
        actionResponsers: PropType.object,
    }

    static defaultProps = {
        actionResponsers: {
            editButton: null,
        }
    }

    constructor(props) {
        super(props);
        
        this.state = {
            tableData: _.times(INITIAL_PRODUCTS_COUNT, generateRow),
            selected: [],
            rowInFocus: null
        };
        this.table = React.createRef();
        this.willEnterRow = this.willEnterRow.bind(this);
        this.didClickRow = this.didClickRow.bind(this);
        this.didContextClick = this.didContextClick.bind(this);
    }

    createColumnDefinitions(quickActions) { 
        return [
            {
                dataField: 'payroll',
                text: "Payroll No",
                headerAttrs: {
                    hidden: false,
                    className: 'th-payroll-cell',
                },
                classes: 'id-payroll-cell td-cell',
                formatter: payrollInfo
            },
            {
                dataField: 'earnings',
                text: "Add Earnings",
                headerAttrs: {
                    hidden: false,
                    className: 'th-earnings-cell',
                },
                classes: 'id-earnings-cell td-cell',
                formatter: earningsInfo
            },
            {
                dataField: 'deductions',
                text: "Deductions",
                headerAttrs: {
                    hidden: false,
                    className: 'th-deductions-cell',
                },
                classes: 'id-deductions-cell td-cell',
                formatter: deductionsInfo
            },
            {
                dataField: 'grosspay',
                text: "Gross Pay",
                headerAttrs: {
                    hidden: false,
                    className: 'th-grosspay-cell',
                },
                classes: 'id-grosspay-cell td-cell',
                formatter: grossPayInfo( quickActions )
            },
            {
                dataField: 'netpay',
                text: "Net Pay",
                headerAttrs: {
                    hidden: false,
                    className: 'th-netpay-cell',
                },
                classes: 'id-netpay-cell td-cell',
                formatter: netPayInfo(quickActions)
            },
        ];
    }

    handleSelect() { }

    handleSelectAll() { }

    handleOnChange() { }

    willEnterRow (evt, row, rowIndex) { }

    didClickRow(evt, row, index) {
        if (row) {
            this.props.quickActions.viewAction(row.payroll);
        }
    }  

    didContextClick (evt, row, rowIndex) {
        evt.preventDefault();
    }

    render () {
        const columnDefs = this.createColumnDefinitions(this.props.quickActions);
        const paginationDef = paginationFactory({
            paginationSize: 5,
            sizePerPage: PAGE_SIZE,
            showTotal: true,
            pageListRenderer: (props) => (
                <PaginationPanel { ...props } size="sm" className="ml-md-auto mt-2 mt-md-0" />
            ),
            paginationTotalRenderer: (from, to, size) => (
                <PaginationTotal { ...{ from, to, size } } />
            )
        
        });
        const selectRowConfig = {
            mode: 'checkbox',
            selected: this.state.selected,
            onSelect: this.handleSelect.bind(this),
            onSelectAll: this.handleSelectAll.bind(this),
            selectionRenderer: ({ mode, checked, disabled }) => (
                <InputField id="" onChange={this.handleOnChange} type={ mode } checked={ checked } disabled={ disabled } />
            ),
            selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
                <InputField id="" onChange={this.handleOnChange} type={ mode } checked={ checked } innerRef={el => el && (el.indeterminate = indeterminate)} />
            )
        };

        const rowEventsHandlers = {
            onContextMenu: this.didContextClick,
            onMouseEnter: this.willEnterRow,
            onClick: this.didClickRow
        };

        var options = {
            noDataMessage: () => 'No pay advice found.'
        };

        //const payAdviceData = this.state.tableData;
        const payAdviceData = this.props.tableData.map(payadvice => generateRow(payadvice));
        
        return (
            <ToolkitProvider
                keyField="id"
                data={ payAdviceData }
                columns={columnDefs} >
                {
                    props => (
                        <React.Fragment>
                            <BootstrapTable
                            ref={this.table}
                            classes="table-responsive pay-advice-table"
                            pagination={ paginationDef }
                            filter={ filterFactory() }
                            selectRow={ selectRowConfig }
                            bordered={ false }
                            responsive
                            { ...props.baseProps }
                            rowEvents={ rowEventsHandlers }
                            loading={ true } 
                            noDataIndication={options.noDataMessage}
                        />
                        </React.Fragment>
                    )
                }
            </ToolkitProvider>
        );
    }
}

export { PayAdviceTable }