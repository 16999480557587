import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { 

    NavLink,
} from '../../../../components';

import { Caret } from './../../../../utility/Caret';

const ReportEntry = (props) => {
    const {data, specimenSupervisor, authorizer, discardReportHandler, textFieldChange, onFocus, onBlur } = props;

    const didTyped = (evt) => {
        
        Caret.setEndContenteditable(evt.target);
        textFieldChange(evt, data);
    };

    const willFocus = () => {
        onFocus(data);
    }

    const didLeaveField = () => {
        onBlur(data);
    }
    
    const isEditable = specimenSupervisor.isAuthoriable() && specimenSupervisor.isReportOwner(data);
    const authorizerName = data.id ?  data.writtenBy :  `${authorizer.firstName} ${authorizer.lastName}` ;
    const profession = data.id ? data.medicalEntry : authorizer.profession;
    const digitalSignature = data.id ? data.digitalSignature  : authorizer.digitalSignature ;
    const signature = data.id  && data.signature ? data.signature.fileDownloadUri : authorizer.imageSignature ? authorizer.imageSignature.fileDownloadUri : null;
    const dateUpdated = data.id ? data.dateUpdated : '';
    const errorClassname = data.error ? 'error' : '';
    
    return (
        <div className="report-item">
            <div className="info-bar">
                <p className="authorizer">
                    <span >Authorizer:</span>Dr. {authorizerName}</p>
                    <span className="type">{profession}</span>
            </div>
            <div className="report-body">
                { !isEditable ? null : 
                <NavLink to={''} onClick={ () => discardReportHandler(data) } className="discard">Discard</NavLink>
                }

                <p className="label">Your Report</p>

                <div className={`ip-text-area ${isEditable ? '--editable': ''} ${errorClassname}`}
                onInput={didTyped}
                onBlur={didLeaveField}
                onFocus={willFocus}
                multiline="true"
                suppressContentEditableWarning={true} 
                contentEditable={isEditable}>{data.content}
                </div>
                <div className="signature-group">  
                    <span>{digitalSignature}</span>
                    { signature === null ? null : 
                        <img className="sig"src={signature}/>
                    }
                </div>
                <span className="date-updated">{dateUpdated}</span>
            </div>                                           
        </div>                                          
    );
};

ReportEntry.propTypes = {
   
};

export { ReportEntry };