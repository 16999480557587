import React, { useState, useRef, useEffect } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import { setupPage } from '../../components/Layout/setupPage';
import ActionBar from '../components/partials/ActionBar';
import DateTimePicker from 'react-datetime-picker';
import { FormValidator } from '../../utility/FormValidator';

import { 
    Container,
    Row,
    Col,
    Button,
    List,
    Section,
    Form,
    FormGroup,
    Label,
    NavLink,
    SimpleSelect,
    BillItemsTable,
    InputField,
    ValidateField,
    ErrorFeedback,
    Authority
} from '../../components';

import * as actionTypes from '../../store/actionType';
import  * as actions  from '../../store/actions';
import * as urls from '../../config/urls';
import * as helpers from '../../utility/helpers';
import { ACTION_BAR_MODES, TAX_TYPE } from './../../common/constant';
import text from '../../common/strings'
import  { PermissionEnforcer }  from '../../utility/PermissionEnforcer';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
import {SEARCH_SETTINGS } from '../../config/searchable';
import './Billing.style.scss';
import { ErrorMessage } from '../components/partials/ErrorMessage';
import { Loader } from '../components/partials/LoadingIndicator';
import { getPromptMessageProps, PromptMessage } from '../components/partials/PromptMessage';
import { getIconUrl } from '../../common/images-catalogue';
import BillPayments from './BillPayments';
import { NotFound } from '../components/partials/NotFound'


const mapStateToProps = state => ({
    apiRequestManager: ApiRequestManager.getInstance(ApiRequestManager),
    useClientLayout: state.global.useClientDashboard,
    mode:  state.global.actionBarMode,
    validator: FormValidator.getInstance(FormValidator),
    ...state.billing
});

const mapDispatchToProps = dispatch => ({
    onLoad: (clentType) => dispatch(actions.billCreateViewLoadedAction(clentType)),
    unLoad: () => dispatch(actions.billCreateViewUnloadAction()),
    setMode: (mode) => dispatch(actions.actionBarModeAction(mode)),
    getClientsServices: () => dispatch(actions.getAllClientsServicesAction()),
    billFieldDidChange: (fieldWithValue) => dispatch(actions.billFieldChangeAction(fieldWithValue)),
    getBillableRecords: (client) => dispatch(actions.getClientSpecimenRecords(client)),
    setBillSummary:(summary) => dispatch(actions.setBillSummaryAction(summary)),
    setBillableItems: (items) => dispatch(actions.setBillableRecordsAction(items)),
    submitData: (data) => dispatch(actions.submitBillAction(data)),
    setBillFormData: (data) => dispatch(actions.setUpdateBillDataAction(data)),
    getBillPayments: (billId) => dispatch(actions.getBillPaymentsAction(billId)),
    getTaxesByCode: (taxCode) => dispatch(actions.getTaxByTypeAction(taxCode)),
});

class CreateUpdate extends React.Component {

    PAYMENT_DUE_DAYS = 14;
    
    static pageOption = { 
        pageTitle: 'Billing',
        disableBodyScroll: true,
        grayBackground: true,
        searchContext: SEARCH_SETTINGS.entities.bill.name
    };

    constructor(props) {
        super(props);

        props.apiRequestManager.register(actionTypes.FETCH_CLIENTS_SERVICES, props.getClientsServices);
        props.apiRequestManager.register(actionTypes.FETCH_CLIENT_SPECIMEN_RECORDS, props.getBillableRecords);
        props.apiRequestManager.register(actionTypes.BILL_SUBMIT_DATA, props.submitData);
        props.apiRequestManager.register(actionTypes.FETCH_BILL_PAYMENTS, props.getBillPayments);
        props.apiRequestManager.register(actionTypes.FETCH_TAXES_BY_TYPE, props.getTaxesByCode);

        this.state = {
           isFetchingTaxes: false,
           date: new Date(),
           formErrors: {},
           showPaymentsModal: false,
        };

        //References
        this.selectUser = React.createRef();

        this.didSelectClientOption = this.didSelectClientOption.bind(this);
        this.didSelectServiceOption = this.didSelectServiceOption.bind(this);
        this.handleBillField = this.handleBillField.bind(this);
        this.handleTaxListOptionSelected = this.handleTaxListOptionSelected.bind(this);
        this.handlerBack = this.handlerBack.bind(this);
        this.onAddBillLine = this.onAddBillLine.bind(this);
        this.removeTableRow = this.removeTableRow.bind(this);
        this.didSelectBillableItemOption = this.didSelectBillableItemOption.bind(this);
        this.didSelectServiceItemOption = this.didSelectServiceItemOption.bind(this);
        this.onCellFieldChange = this.onCellFieldChange.bind(this);
        this.billDateChange = this.billDateChange.bind(this);
        this.dueDateChange = this.dueDateChange.bind(this);
        this.submitFormData = this.submitFormData.bind(this);
        this.billClientHanlder = this.billClientHanlder.bind(this);
        this.getPromptMessage = this.getPromptMessage.bind(this);
        this.setFormDefaults = this.setFormDefaults.bind(this);
        this.calculateBillDueDate = this.calculateBillDueDate.bind(this);
        this.onViewPayments = this.onViewPayments.bind(this);
        this.showViewPaymentsModal = this.showViewPaymentsModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.showSettings = this.showSettings.bind(this);
        //Init view 
    }

    componentDidMount() {
        if (!this.props.viewInit) {
            this.props.onLoad();
        }  
        
        const bill = this.props.bill;
        const mode = bill ? 'edit' : 'create';
        this.props.setMode(mode); 
        //this.props.onLoad();
        mode === 'create' && this.setFormDefaults();
        
        if (mode === ACTION_BAR_MODES.edit && !bill.empty()) {
            const formData = this.composeFormData(bill);
            this.props.setBillFormData ({bill: bill, formData: formData});

            this.didSelectClientOption(this.composeAccountOption(bill.account));
            this.calculateBillSummary(formData.items, bill.carriedBalance);
            
        } else if(mode === ACTION_BAR_MODES.create && !this.props.apiRequestManager.inProgress(actionTypes.FETCH_TAXES_BY_TYPE)) {
            this.props.apiRequestManager.queueRequest(actionTypes.FETCH_TAXES_BY_TYPE, TAX_TYPE.service);
            this.setState({isFetchingTaxes: true});
        }
    }

    componentWillUnmount() { 
        this.props.viewInit && this.props.unLoad();
    }

    static getDerivedStateFromProps(props, state) {
        if (!props.accounts && !props.services) {
            props.apiRequestManager.queueRequest(actionTypes.FETCH_CLIENTS_SERVICES);
        }

        if (state.isFetchingTaxes !== props.apiRequestManager.inProgress(actionTypes.FETCH_TAXES_BY_TYPE)) {
            return {
                isFetchingTaxes: props.apiRequestManager.inProgress(actionTypes.FETCH_TAXES_BY_TYPE)
            }
        }

        return null;
    }

    shouldComponentUpdate(props, state) {
        
        if ( !Object.blank(props.bill)  && !props.billForm.client ) {
            const formData = this.composeFormData(props.bill);
            props.setBillFormData ({bill: props.bill, formData: formData});
            this.calculateBillSummary(formData.items, props.bill.carriedBalance);
            this.props.setMode('edit'); 
        }
        return true;
    }

    onViewPayments(event){
        event.preventDefault();
        this.showViewPaymentsModal(true);
    }

    onCloseModal(){
        this.showViewPaymentsModal(false);
    }

    showViewPaymentsModal(isVisible){
        this.setState({
            showPaymentsModal: isVisible,
        })
    }

    setFormDefaults() {
        
        const fieldSet = {
            billDate : this.state.date,
            dueDate : this.calculateBillDueDate(this.state.date),
            referenceNo: this.generateBillNumber()
        };
        
        this.props.billFieldDidChange(fieldSet);
        this.calculateBillSummary();
        if (!this.props.bill)
            this.props.billForm.items.empty() && this.defaultRow();
    }

    /**TODO: Change when api supports user controlled bill date. */
    calculateBillDueDate(billCreateDate){
        let billDelayDayCount = this.PAYMENT_DUE_DAYS;
        return helpers.addDaysToDate(billCreateDate, billDelayDayCount);
    }

    generateBillNumber() {
        const date  = new Date();
        const month = date.getMonth() + 1;
        const days = date.getDate();
        const year = `${date.getFullYear()}`.substring(2, 4);
        return`${helpers.generateDigits(5)}-${month}${days}${year}` 
    }

    onAddBillLine(evt) {
        evt.preventDefault();
        const newBilline = this.newBillLine();

        const newItems = [
            ...this.props.billForm.items,
            this.calculateBillLineAmount(newBilline)
         ]
         this.props.billFieldDidChange({items: newItems});
         this.setState({
            formErrors: {
                ...this.state.formErrors,
                billLinesEmpty: false
            }
        });
    }

    newBillLine() {
        const nextIndex = this.props.billForm.items.length;

        const billLine = {
            id: nextIndex,
            item: null,
            service: null,
            unitPrice: this.props.billForm.service ? this.props.billForm.service.price : 0.0,
            quantity: 1,
            expressCost: this.props.billForm.expressCost || 0.0,
            fee: 0.0,
            amount: 0.0
        }
        return billLine;
    }

    billDateChange(date) {
        this.props.billFieldDidChange( { 
            'billDate' : date,
            'dueDate': this.calculateBillDueDate(date),
        });
    }

    dueDateChange(date) {
        this.props.billFieldDidChange( {'dueDate' : date});
    }

    handleTaxListOptionSelected(option){
        const fieldSet = {
            tax: {
                ...this.props.billForm.tax,
                percent: option.percent,
            }
        }
        this.props.billFieldDidChange(fieldSet);

        setTimeout(() => this.calculateBillSummary(this.props.billForm.items), 0);
    }

    handleBillField(event) {
        let fieldSet = {
           [event.target.name] : event.target.value 
        };

        let billLines = this.props.billForm.items;
        
        switch(event.target.name) {
            case 'expressCost':
                billLines = billLines.map((billLine) => {
                    return {
                       ...billLine ,
                       expressCost: this.applyExpressFee(billLine) ? event.target.value : 0
                    }
                });

                this.props.billFieldDidChange({
                    items: billLines
                });

                fieldSet = {
                    [event.target.name] : event.target.value 
                 };
                break;
            case 'percent': 
            case 'taxCode':
                fieldSet = {
                    tax: {
                        ...this.props.billForm.tax,
                        [event.target.name] : event.target.value
                    }  
                };
                break;  
            default:
                this.props.billFieldDidChange(fieldSet);
                return;
        }
        
        this.props.billFieldDidChange(fieldSet);

        /**
         * The delay is to prevent the old state from being used. React batches some state update,
         * so sometimes (in this case) the old state is used when we would expect the updated state 
         * to be used.
         */
        setTimeout(() => this.calculateBillSummary(billLines), 0);
    }

    applyExpressFee(billLine) {
        const applyExpressCost = !billLine.item ? false : billLine.item.urgent;
        return applyExpressCost;
    }
    
    didSelectClientOption(client) {
        this.props.apiRequestManager.queueRequest(actionTypes.FETCH_CLIENT_SPECIMEN_RECORDS, client.value);
        const carriedBalance = !this.props.bill ? client.previousBalance : this.props.bill.carriedBalance;
        this.props.billFieldDidChange({client: client, carriedBalance: carriedBalance});
        !this.props.bill && this.defaultRow(client);
        this.setState({
            formErrors: {
                ...this.state.formErrors,
                selectClientMissing: false
            }
        });
    }

    didSelectServiceOption(service) {
        this.props.billFieldDidChange({service: service});
        let billLines = this.props.billForm.items.map((row) => {
                row.unitPrice = service.price;
            return row;
        });
        
        this.props.billFieldDidChange({items: billLines});
        this.calculateBillSummary(billLines);
    }

    removeTableRow(index, option) {
        //var removedList = this.props.billForm.items.filter((row) => row.id !== index);
        let removedList = this.props.billForm.items.splice(index, 1);
        if (removedList.length === 0) return;
        
        removedList = removedList.map((row, index) => {
            return  {
                ...row,
                id: index
            }   
        });

        let filteredRecords = [];
        let filteredItems = [];

        filteredItems = this.props.billableRecordsList.pageData.filter((record) => {
            return option && record.id === option.value;
        });
        filteredRecords = this.props.billableRecords.merge(filteredItems);

        //this.props.billFieldDidChange({items: removedList});
        this.props.setBillableItems(filteredRecords);
        this.calculateBillSummary(this.props.billForm.items);
    }
    
    defaultRow() {
        const row = this.newBillLine();
        row.id = 0;
        const singleRow = [
            row
        ]
        this.props.billFieldDidChange({items: singleRow});
        this.calculateBillSummary(singleRow);
    }

    didSelectBillableItemOption(option, index, id) {
     
        let filteredItems = [];
        let newItems = this.props.billForm.items.filter((row) => {
            if (row.id === index) {
                row.item = option;
            }
            return row;
        });
        
        newItems.forEach(option => {
            //const { item, ...profilesWithoutKey } = profiles;
             this.props.billableRecordsList.pageData.forEach((record) => {
                if (option.item) {
                    record.id === option.item.value && filteredItems.push(record);
                }
             } );
        });

        let filteredRecords = this.props.billableRecordsList.pageData.filter(item => !filteredItems.includes(item));

        this.props.billFieldDidChange({items: newItems});
        this.props.setBillableItems({pageData: filteredRecords});
        this.calculateBillSummary(newItems);
    }

    didSelectServiceItemOption(option, index, id) {
        const billLines = this.props.billForm.items.map((row) => {
            if (row.id === index) {
                row.service = option;
                row.unitPrice = row.service.price
            }
            return row;
        });

        this.props.billFieldDidChange({items: billLines});
        this.calculateBillSummary(billLines);
        this.setState({
            formErrors: {
                ...this.state.formErrors,
                billLinesInvalid: false
            }
        });
    }

    onCellFieldChange(id, field, fieldName) {
        const newItems = this.props.billForm.items.map((row) => {
            
            if (row.id === id) {
                
                row[fieldName] = field.value;
                if (fieldName === 'expressCost' && !this.applyExpressFee(row)) {
                    row[fieldName] = 0.0
                }
            }
            return this.calculateBillLineAmount(row);
        });
        //this.props.billFieldDidChange({items: newItems});
        this.calculateBillSummary(newItems);
    }

    composeFormData(bill) {

        const formData =  {
            referenceNo: bill.referenceNo,
            billDate: new Date(bill.billDate),
            dueDate: new Date(bill.dueDate),
            carriedBalance: bill.carriedBalance,
            client: this.composeAccountOption(bill.account),
            //service: this.composeServiceOption(bill.service),
            expressCost: bill.expressCost,
            message: bill.message,
            tax: {
                taxCode: bill.taxCode,
                percent: bill.taxPercent
            },
            items: bill.billLines.map((item, i) => {
                return {
                    ...item,
                    id: i
                }
            })
        };
        return formData
    }

    calculateBillLineAmount(billLine) {

        const netAmount = (parseFloat(billLine.unitPrice) * parseInt(billLine.quantity)) + parseInt(billLine.fee) + parseFloat(this.applyExpressFee(billLine) ? billLine.expressCost : 0);
        const calcBillLine = {
            ...billLine,
            amount: netAmount
        }
        return calcBillLine;
    }

    calculateBillSummary(items = null, carriedBalance = null) {
        const billLines = items !== null ? items : this.props.billForm.items;
        const taxPercentage = this.props.billForm.tax.percent;

        const calcBillLines = billLines.map((row) => {
            return this.calculateBillLineAmount(row);
        });

        const netTotal = calcBillLines.reduce((total, billLine) => total += billLine.amount, 0);

        const prevBalance = carriedBalance ? carriedBalance : this.props.billForm.carriedBalance;
        let tax = 0;

        //Calculate taxes
        tax = (taxPercentage / 100) * netTotal;
        const billSummary = {
            ...this.props.billSummary,
            netTotal: netTotal,
            prevBalance: prevBalance,
            tax: tax,
            paymentDue: netTotal + prevBalance + tax
        }

        this.props.billFieldDidChange({items: calcBillLines});
        this.props.setBillSummary(billSummary);
    }

    submitFormData(evt) {

        const isUpdate = this.props.mode === 'edit' ? true : false;

        let billLines = this.props.billForm.items.map((billLine) => {
            if (billLine.item) {
                return {
                    ...billLine,
                    item: billLine.item ? billLine.item.value : null,
                    service: billLine.service ? billLine.service.value : null
                }
            }else {
                return billLine;
            }

        });

        let data = {
            requestType: isUpdate ? 'update' : 'create' ,
            ...this.props.billForm,
            client: this.props.billForm.client && this.props.billForm.client.clientId,
            //service: this.props.billForm.service && this.props.billForm.service.id,
            billDate: this.props.billForm.billDate.toLocaleString(),
            dueDate: this.props.billForm.dueDate.toLocaleString(),
            billLines: billLines,
            billSummary: {
                ...this.props.billSummary 
            }
        }
        //toLocaleDateString("en-US")
        if (isUpdate) {
            data['billId'] = this.props.billForm !== undefined ? this.props.bill.id : null
        }
   
        let formPayload = {
           ...data
        }

        const formErrors = this.validateFields(data);

        if (Object.blank(formErrors)) {
            this.props.apiRequestManager.queueRequest(actionTypes.BILL_SUBMIT_DATA, formPayload);
        }else {
            this.setState({
                formErrors: formErrors
            });
        }
    }

    validateFields(formData) {
        let validations = {};
        
        if (this.props.validator.isNotNull(formData.client)) {
            validations.selectClientMissing = true;
        }

        if(this.props.validator.isEmptyValue(formData.billLines)){
            validations.billLinesEmpty = true;
        }

        if(!this.props.validator.isEmptyValue(formData.billLines) && this.isBillLinesBillable(formData.billLines)){
            validations.billLinesInvalid = true;
        }
  
        return  validations;  
    }

    isBillLinesBillable(billLines) {
        const hasMissingValues = billLines.map((line) => (!line.item || !line.service) ? true : false);
        return hasMissingValues.includes(true);
    }

    billClientHanlder(evt) {
        evt.preventDefault();
        const data = {
            requestType: 'billed',
            billId: this.props.bill.id
        }
        this.props.apiRequestManager.queueRequest(actionTypes.BILL_SUBMIT_DATA, data);
    }

    updateBillData(evt) {
        this.submitFormData(evt);  
    }

    handlerBack = (evt) => {
        this.props.setMode('');
        this.props.unLoad();
        this.props.history.push({ pathname: urls.billingUri});
    }

    composeAccountOption (account) {
        return {
            ...account,
            value: account.clientInfo.id,
            name: `${account.clientInfo.firstName} ${account.clientInfo.lastName}`,
            email: `${account.clientInfo.email}`,
            account: account.accountNo,
            label: `${account.clientInfo.firstName} ${account.clientInfo.lastName} - ${account.accountNo}`
        }
    }

    composeServiceOption(service) {
        return {
            ...service,
            value: service.id,
            label: service.name
        }
    }

    showSettings() {
        this.props.history.push({ pathname: urls.settingsUri});
    }


    getPromptMessage() {
        const apiOutcome = this.props.apiRequestManager.getApiOutcome(actionTypes.BILL_SUBMIT_DATA, true) ?? {};
        return getPromptMessageProps('billing', apiOutcome);
    }

 

    render () {

        const actionBarCallback = {
            'save': this.submitFormData,
            'back': this.handlerBack,
            'delete': this.handleDelete
        };
        const tableActionsCallback = {
            removeRowHandler: this.removeTableRow,
            didSelectOption: this.didSelectBillableItemOption,
            didSelectService: this.didSelectServiceItemOption,
            cellTextFiledChange: this.onCellFieldChange
        }
        
        let actionBarMode =  this.props.mode;
        actionBarMode = this.props.bill? ACTION_BAR_MODES.edit : actionBarMode;
        actionBarMode = this.props.bill && this.props.bill.status !== "Pending" ? '' : actionBarMode;

        const  saveSufixText = actionBarMode === 'edit'? '' : 'Bill';

        const accountsList =  Object.blank(this.props.accounts) ? [] : this.props.accounts.map((account) => {
            return this.composeAccountOption(account);
        });
        const servicesList = Object.blank(this.props.services) ? [] : this.props.services.map((service) => {
            return this.composeServiceOption(service);
        });;

        const initalSeletedClient = this.props.billForm.client;
        const billableItems =  this.props.billableRecords.pageData;
        const selectClient = this.props.billForm.client;
        const clientDetails = {
            name: !selectClient ?  '' :  selectClient.clientInfo.officeName ?  selectClient.clientInfo.officeName : `${selectClient.name}`,
            accountNo: selectClient && selectClient.account,
            email: selectClient && selectClient.email,
            phoneNumber: selectClient && selectClient.clientInfo.phoneNumber,
            labCode: selectClient && selectClient.clientInfo.labCode,
            billingTerms: selectClient && selectClient.billCycle
        }

        const showBilledClientButton = Object.blank(this.props.bill) ? false : this.props.bill.status !== 'Pending' ? false : true; 

        const isEditable = !this.props.bill ? false : this.props.bill && this.props.bill.status === "Pending" ? false : true; 

        const tableItems =  this.props.billForm.items;
        const servicesItems =  this.props.billForm.services;

        const dialogProps = this.getPromptMessage();
       
        return (
            <React.Fragment>
            <ActionBar title={ "Bill" } saveTextSufix={ saveSufixText } entityContext={'bill'} actionResponsers={ actionBarCallback } permissionContext={ actionBarMode } />
            <div className='content-layout-wrap bill-create'>
                { dialogProps.visible && 
                    <PromptMessage {...dialogProps} />
                } 
                <div className="view-layout-content">
                   <div className="default-Ly-ct">
                       <Container> 
                           
                           <div className="bill-content">
                                <Section className="head-section">
                                    <div className="title-box">
                                        <div className="title-area">
                                            <span className="icon">
                                                <img src={getIconUrl('bill')} alt='' />
                                            </span>
                                            <h1>{this.props.mode === 'edit' ? 'Edit Bill' : 'Create New Bill'}</h1>
                                        </div>
                                        <div className="quick-action">

                                        </div>
                                    </div>
                                    {(this.props.bill && this.props.bill.hasPayments) && 
                                    <React.Fragment>
                                        <Authority allowed='view' entity='payment'>
                                            <NavLink className="arrow-link" onClick={this.onViewPayments} href>
                                                <span className="arrow-indicator"></span>View Payment
                                            </NavLink>
                                        </Authority>
                                    </React.Fragment>
                                    }
                                </Section>
                                <div className="bill-wrap">
                                    <Form onSubmit={(event) => event.preventDefault()}>
                                    <Section className="details-section">
                                        <div className="form-fields">
                                           
                                            <Row>
                                                <Col sm={6}>
                                                <div className="billed-to">
                                                <FormGroup>
                                                    
                                                    <h3>Bill To</h3>
                                                    <ValidateField required>
                                                <ErrorFeedback 
                                                    error
                                                    show={this.state.formErrors.selectClientMissing}
                                                    filter='valueMissing'
                                                    message={<ErrorMessage message ='Please select a customer to bill' />} />
                                                    <SimpleSelect
                                                    id={'user'}
                                                    ref={this.selectUser}
                                                    className="ip-sml-select"
                                                    name="user"
                                                    title="Choose customer"
                                                    list={accountsList}
                                                    onChange={this.didSelectClientOption}
                                                    initialSelected={initalSeletedClient}
                                                    searchable={["Type Name or Email", "No matching customer"]}
                                                    filterKeys={['name',
                                                                'email', 
                                                                'account']}
                                                    disabled={isEditable}
                                                />
                                                </ValidateField>
                                                </FormGroup>

                                                { !Object.blank(selectClient) &&
                                                <FormGroup>
                                                    <h3>Client Type</h3>
                                                    <p className="info tab-line"> {selectClient.clientType }</p>
                                                </FormGroup>
                                                }

                                                { this.props.billForm.client &&
                                                <FormGroup>
                                                    <div className="client-bill-info">
                                                        <h4>{clientDetails.name}</h4>
                                                        <p className="info">{clientDetails.accountNo}, Labcode: <strong>{clientDetails.labCode.code}</strong></p>
                                                        <p className="info">{clientDetails.email}</p>
                                                        <p className="info">Billing Terms: <strong>{clientDetails.billingTerms}</strong></p>
                                                    </div>
                                                </FormGroup>
                                                 }
                                                </div>
                                                </Col>
                                                <Col sm={6}>
                                                <div className="form-group billing--details">
                                                        
                                                <h3>Bill Details</h3>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup className="bill-ref">
                                                                <Label>Reference No.</Label>
                                                                <p>{this.props.billForm.referenceNo}</p>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                        <Row>
                                                            <Col>
                                                            
                                                            <FormGroup>
                                                                <Label>Bill date</Label>
                                                <div className="ip-date squ date-field"> 
                                                    <ValidateField required>
                                                <ErrorFeedback 
                                                    error
                                                    show={this.state.formErrors.datePicker}
                                                    filter='valueMissing'
                                                    message={<ErrorMessage message ='Please select a date' />} />
                                                    <DateTimePicker 
                                                        ref={this.datePicker}
                                                        className="date-picker"
                                                        clearIcon={null}
                                                        disableClock
                                                        time={false}
                                                        format={"MM-dd-y"}
                                                        onChange={this.billDateChange}
                                                        value={this.props.billForm.billDate}
                                                        disabled={isEditable} 
                                                    />
                                                    </ValidateField>
                                                    </div>
                                                    </FormGroup>
                                                    </Col>

                                                    <Col>
                                                            <FormGroup>
                                                                <Label>Payment due date</Label>
                                                            <div className="ip-date squ date-field"> 
                                                <ValidateField required>
                                                <ErrorFeedback 
                                                    error
                                                    show={this.state.formErrors.datePicker}
                                                    filter='valueMissing'
                                                    message={<ErrorMessage message ='Please select a date' />} />
                                                    <DateTimePicker 
                                                    ref={this.datePicker}
                                                    className="date-picker"
                                                    clearIcon={null}
                                                    disableClock
                                                    time={false}
                                                    format={"MM-dd-y"}
                                                    onChange={this.dueDateChange}
                                                    value={this.props.billForm.dueDate} 
                                                    disabled={isEditable} />
                                                    </ValidateField>
                                                    </div>
                                                    </FormGroup>
                                                    </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup className="bill-ref">
                                                                <Label>Express Cost</Label>
                                                                <InputField
                                                                className="express-field"
                                                                id="express" 
                                                                type="number"
                                                                min="0"
                                                                placeholder="0.0"
                                                                name="expressCost"
                                                                value={this.props.billForm.expressCost}
                                                                onChange={this.handleBillField}
                                                                disabled={isEditable}  />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <FormGroup className="bill-ref">
                                                                <Label>Previous balance</Label>
                                                                <p>${this.props.billForm.carriedBalance.toCurrency()}</p>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                </Col>
                                            </Row>
                                            
                                        </div>
                                    </Section>
                                    <Section className="taxes-section">
                                        <Row>
                                            <Col md={6}>
                                            <div className="form-fields">
                                                <FormGroup>
                                                    <h3>Taxes</h3>
                                                </FormGroup>
                                                <div className="tax-holder">
                                                    { this.state.isFetchingTaxes ? 
                                                    <div className='bill__loader-wrap'>
                                                        <Loader visible={this.state.isFetchingTaxes} loadingText="Fetching taxes..." />
                                                    </div>
                                                  :
                                                    <FormGroup className="tax-line">
                                                        <span className="form-l-grp">
                                                            <Label>Tax</Label>
                                                            <input 
                                                            type="text" 
                                                            placeholder="GCT"
                                                            className="tax-code"
                                                            name="taxCode"
                                                            value={this.props.billForm.tax.taxCode || 'GCT'}
                                                            onChange={this.handleBillField}
                                                            disabled={isEditable}/>
                                                        </span>
                                                        <span className="form-l-grp">
                                                            <EditableSelect 
                                                            labelText="Percentage %"
                                                            isInputDisabled={isEditable}
                                                            handleChange={this.handleBillField}
                                                            inputFieldClasses='tax-percent'
                                                            inputName='percent'
                                                            inputValue={this.props.billForm.tax.percent}
                                                            inputType='number'
                                                            inputPlaceholder='0.0'
                                                            listOptions={this.props.serviceTaxes}
                                                            optionLabelKey={'percent'}
                                                            handleSelectOption={this.handleTaxListOptionSelected}
                                                            emptyOptionsComponent={
                                                                <NotFound.Items
                                                                itemClasses='item-not-found--xs'
                                                                onClick={this.showSettings}
                                                                messageComponent={
                                                                    <span style={{fontSize: '12.8px'}} >No Service taxes found. <br/> <b>Click</b> here to create. </span>
                                                                } />
                                                            } />
                                                        </span>
                                                    </FormGroup>
                                                    }
                                                </div>
                                            </div>
                                            </Col>
                                        </Row>
                                    </Section>
                                    <Section className="item-lines-section">
                                        <Row>
                                            <Col>
                                            <div className="form-fields">
                                                <FormGroup>
                                                    <h3>Items</h3>
                                                    { (!tableItems.length || this.state.formErrors.billLinesEmpty)  && 
                                                        <ErrorMessage message ='Please add a bill item.' />
                                                    }

                                                    {(tableItems.length > 0 && this.state.formErrors.billLinesInvalid) && 
                                                        <ErrorMessage message ='Please check if a record or service is selected' />
                                                    }
                                                    
                                                </FormGroup>
                                                <div className="table-holder">
                                                    <BillItemsTable 
                                                        disabled={isEditable}
                                                        actionHandlers={tableActionsCallback}
                                                        tableItems={tableItems}
                                                        servicesItems={servicesItems}
                                                        billableItems={billableItems}
                                                        servicesList={servicesList}
                                                    />
                                                </div>
                                                { (!this.props.bill || this.props.bill.status === 'Pending') &&
                                                <div>
                                                <Button 
                                                onClick={this.onAddBillLine}
                                                disabled={isEditable} className="add-btn-item bill-line-btn">
                                                        <span className="icon">
                                                            <img src={getIconUrl('plusIcon')} alt=''/>
                                                        </span>
                                                        New bill Line 
                                                    </Button>
                                                </div>
                                                }
                                            </div>
                                            </Col>
                                        </Row>
                                    </Section>
                                    <Section className="total-section">
                                        <Row>
                                            <Col md={8}>
                                                <textarea 
                                                disabled={isEditable}
                                                onChange={this.handleBillField}
                                                name="message"
                                                defaultValue={this.props.billForm.message}
                                                className="notes" placeholder="Write a description">
                                                     
                                                </textarea>
                                            </Col>
                                            <Col md={4}>
                                                <div className="total-box">
                                                    <p className="total-line amout"><label>Net Total</label><span className="value">${this.props.billSummary.netTotal.toCurrency()}</span></p>
                                                    <p className="total-line tax">
                                                    <label>Perv. Balance</label><span className="value">${this.props.billSummary.prevBalance.toCurrency()}</span>
                                                    </p>
                                                    <p className="total-line tax">
                                                    <label>Tax</label><span className="value">${this.props.billSummary.tax.toCurrency()}</span>
                                                    </p>
                                                    <p className="total-line total">
                                                    <label>Payment:</label><span className="value">${this.props.billSummary.paymentDue.toCurrency()}</span>
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Section>

                                    <Section className="buttons-section">
                                        { showBilledClientButton && 
                                        <div className="btn-group">
                                            <Button 
                                            className="bill-btn" 
                                            disabled={isEditable}
                                            onClick={this.billClientHanlder}>
                                                Bill Client
                                            </Button>
                                        </div>
                                        }
                                    </Section>
                                    </Form>
                                </div>
                            </div>
                        </Container>
                   </div>
                   {
                       this.state.showPaymentsModal &&
                       <BillPayments {...this.props} 
                        closeModal={this.onCloseModal}
                        paymentsData={this.props.billPayments} />
                   }
                </div>
            </div>
            </React.Fragment>
        );
    }
}

function EditableSelect(props){
    const [showList, setShowList] = useState(false);
    const listRef = useRef(null);

    const { 
        wrapperClasses,
        labelText,

        /**Input element props */
        inputFieldClasses,
        isInputDisabled,
        handleChange,
        inputName,
        inputValue,
        inputType,
        inputPlaceholder,

        /**List element props */
        listClasses,
        listOptions,
        optionLabelKey,
        handleSelectOption,
        emptyOptionsComponent
    } = props;

    const handleClickAway = (event) =>{
        event.preventDefault();

        if(listRef.current && !listRef.current.contains(event.target)){
            setShowList(false);
        }
    }

    const onOptionClick = (option) =>{
        handleSelectOption(option);
        setShowList(false);
    }

    useEffect(() => {
        window.addEventListener('click', handleClickAway);
        //Clean up when unmounting.
        return () => window.removeEventListener('click', handleClickAway);
    },[]);

    return(
        <div ref={listRef} className={`editable-list__wrapper ${wrapperClasses || ''}`}>
            <Label>{labelText}</Label>
            <input 
            type={inputType}
            placeholder={inputPlaceholder}
            className={inputFieldClasses || ''}
            name={inputName}
            value={inputValue}
            onChange={handleChange}
            disabled={isInputDisabled}
            onFocus={() => setShowList(true)}
            />
            {showList && <List className={`editable-list ${listClasses || ''}`} >
                {Array.isArray(listOptions) && 
                (listOptions.empty() ? 

                /**Render a component to indicate that no options were found, or default text if that component wasn't passed in. */
                emptyOptionsComponent || 'No options.'

                : listOptions.map((option, index) => (
                    <li className='editable-list__option' taxid={option.id} key={index} 
                    onClick={() => onOptionClick(option)}>
                        { optionLabelKey ? option[optionLabelKey] : option}<i>%</i> <span className='tax-name'>{option.taxName}</span>
                    </li>
                )))}
            </List>
            }
        </div>
    );
}

EditableSelect.propType = {
    wrapperClasses: PropType.string,
    labelText: PropType.string,

    /**Input element props */
    inputFieldClasses: PropType.string,
    isInputDisabled: PropType.bool,
    handleChange: PropType.func,
    inputName: PropType.string,
    inputValue: PropType.string,
    inputType: PropType.string,
    inputPlaceholder: PropType.string,

    /**List element props */
    listClasses: PropType.string,
    listOptions: PropType.string,
    optionLabelKey: PropType.string,
    handleSelectOption: PropType.func,
    emptyOptionsComponent: PropType.element,
}

const CreateUpdateView = (props) => {
    return (
        <CreateUpdate {...props } />
    );
};

let ConfigCreateUpdateBill = setupPage(CreateUpdate.pageOption)(CreateUpdateView);
ConfigCreateUpdateBill =  connect(mapStateToProps, mapDispatchToProps)(ConfigCreateUpdateBill);

export { ConfigCreateUpdateBill };