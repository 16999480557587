import strings from "../../common/strings";
class NetworkError extends Error {
    errorCode;
    errorResponse;

    constructor(
        errorCode = 1,
        errorMessage,
        detail,
        type,
        ...params
    ) {
        super(...params);

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, NetworkError)
        }

        this.name = 'NetworkError'
        this.message = errorMessage === null ? strings.defaultMessage : errorMessage 
        this.errorCode = errorCode

        this.errorResponse = {
            detail,
            type,
            message: (errorMessage === null || errorMessage === '') ? strings.defaultMessage : errorMessage ,
        }
    }
}

export default NetworkError;