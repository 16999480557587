import React from 'react';
import TemplateFooter from './TemplateFooter';

class Report_RP04 extends React.PureComponent  {

    constructor(props) {
        super(props);
        this.state = {
            rowGroup : {}
        }

        this.collapse = this.collapse.bind(this);
    }
    collapse(evt) {
    
        const rowId = evt.currentTarget.getAttribute('rowgroup');
        const rowSet = {
            rowGroup: {
                ...this.state.rowGroup,
                [rowId]: this.state.rowGroup[rowId] ? false : true
            }
        }
        this.setState({
            ...rowSet
        });
    }

    render () {

        const {ReportHeader, ReportFooter, ReportNotes} = this.props;
        const reportData = Array.isArray(this.props.reportResults) && this.props.reportResults.length ? this.props.reportResults[0] : {};

        const dataKeys = Object.keys(reportData);

        return <React.Fragment>
            <div  className="print-template">
                { ReportHeader }
                <div className="report-body">

                    <table className="default">
                        <thead>
                            <tr>
                                <th>Service</th>
                                <th className="number">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                        { dataKeys.map((key, g) => {
                            
                            const add = (accumulator, item) => {
                                return accumulator + (item.amount || item);
                            }
                            var sum = reportData[key].reduce(add, 0);

                            var rowGroup = [<tr key={g} onClick={this.collapse} className={`collapsible-row ${this.state.rowGroup[g] ? '--folded' : ''}`} rowgroup={g}>
                            <td>{key}</td>
                            <td className="number">${sum.toCurrency()}</td>
                            </tr>]

                            reportData[key].map((billLine, i) => {
                                rowGroup.push(<tr key={billLine.id}  className={`smpl-row ${this.state.rowGroup[g] ? '--hide' : ''}`} groupid={g}>
                                <td>
                                    {billLine.item.formType} 
                                    <small> ({billLine.item.identifier})</small>
                
                                </td>
                                <td className="number">${billLine.amount.toCurrency()}</td>
                                </tr>)
                            });

                            return rowGroup;
                            })}
                        </tbody>
                    </table>
                </div>
                { <TemplateFooter 
                    ReportFooter={ReportFooter}  
                    ReportNotes={ReportNotes}
                    /> 
                }
            </div>
           
        </React.Fragment> 
    }  
}

export default Report_RP04;