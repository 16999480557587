import React from 'react';
import classNames from 'classnames';
import { CustomInput  } from 'reactstrap';

const InputField = (props) => {
    const { className, ...otherProps } = props;
    const inputClass = classNames(className, {
        'custom-control-empty': !props.label
    });

    return (
        <CustomInput className={ inputClass } { ...otherProps } />
    );
}
InputField.propTypes = { ...CustomInput.propTypes };

export { InputField };
