import * as actionTypes from '../../store/actionType';
import { DASHBOARD_KEY } from '../../common/constant';
import { loginUri, rootUri } from '../../config/urls';
import { isError } from '../../service/agent';
import { PermissionEnforcer } from "./../../utility/PermissionEnforcer";
import { ApiRequestManager } from "../../utility/ApiRequestManager";
import { SpecimenSupervisor } from "../../utility/SpecimenSupervisor";
import SocketCommunicator  from "../../utility/SocketCommunicator";
import RefreshJwtSubroutine from '../../utility/RefreshJwtSubroutine';
import { some } from 'lodash';

const refreshTokenRoutine = RefreshJwtSubroutine.getInstance(RefreshJwtSubroutine);

const appMiddleware = store => next => action => {

   const enforcer = PermissionEnforcer.getInstance(PermissionEnforcer);
   const requestManager = ApiRequestManager.getInstance(ApiRequestManager);
   
   if ( action.type === actionTypes.ASYNC_ENDED ) {
      action.payload.initiator !== undefined && requestManager.syncEnded(action.payload);
   }

   if( action.type === actionTypes.LOGOUT ) {
      enforcer.discard();
      const specimenSupervisor = SpecimenSupervisor.getInstance(SpecimenSupervisor);
      specimenSupervisor.retire();

      window.localStorage.setItem(DASHBOARD_KEY, false);
      
      SocketCommunicator.getInstance(SocketCommunicator).destroy();
   }
   
   if (action.type === actionTypes.AUTH_RESULTS || 
      action.type === actionTypes.REQUEST_AUTH_USER_RESULT) {
      if ( !isError(action.payload) ) {
         const { user } = action.payload;
         enforcer.user = user || action.payload;
      }
   }

   if ( action.type === actionTypes.AUTH_RESULTS ) {
      if ( !isError(action.payload) ) {
         window.localStorage.setItem(DASHBOARD_KEY, action.payload.useClientDashboard);
      }
   } 

   switch( action.type ) {
      //[0] Actions that are allowed to continue
      case refreshTokenRoutine.isAllowedAction(action.type):
         //[3 Actions to block if jwtToken is expired
         if ( refreshTokenRoutine.completeRenewal(action) ) {
            refreshTokenRoutine.dispatchQueuedActions();
            refreshTokenRoutine.destructor();
            return;
         }
         break;

      default:

         // Skip token refresh when on login view
         if ( store.getState().global.appRoute === loginUri || store.getState().global.appRoute === rootUri ) {
            break;
         }

         //[1] Check is sessioned token is expired and dispatch refresh token action
         refreshTokenRoutine.initialize(store, next);

         if ( !refreshTokenRoutine.isRenewing && refreshTokenRoutine.isTokenExpired() ) {
            if ( refreshTokenRoutine.performRefreshToken(action) )
               return;
         }
         //[2] Actions to block if jwtToken is expired
         if ( refreshTokenRoutine.isRenewing && action.type !== actionTypes.ASYNC_COMPLETED ) {
            refreshTokenRoutine.addActionToQueue(action);
            return;
         } 
   }

   next(action);
};

export default  appMiddleware; 