import React from 'react';
import { Link } from 'react-router-dom';
import {
    Button,
    List,
    Section,
    InputField,
    FormGroup,
} from '../../components';

import './Payroll.style.scss';
import { Input } from 'reactstrap';
import { getIconUrl } from '../../common/images-catalogue';
import * as urls from '../../config/urls'
import { NotFound } from '../components/partials/NotFound';

const TaxItem = (props) => {
    const {data, index, willRemoveHanlder, onChangeHanlder, onBlurHandler} = props;
    const fields = {
        taxCode: 'taxCode',
        percent: 'percent'
    }
    const percent = data.percent === '' ? 0.0 : data.percent;
    const defaultValue = data.taxCode;
    return (
        <React.Fragment>
            <div className="field-group">
                <div className="field tax-code">
                    <FormGroup >
                        <Input 
                        id={index}
                        className="ip-basic-select taxcode"
                        type="select"
                        name="taxcode"
                        disabled
                        defaultValue={defaultValue}
                        onChange={ (evt) => onChangeHanlder(evt, fields.taxCode, index) } >
                        {defaultValue === '' && 
                        <option value="">Select Tax</option>
                        }
                        <option value="NIS">N.I.S</option>
                        <option value="NHT">N.H.T</option>
                        <option value="EDUTAX">EDU. TAX</option>
                        <option value="PAYE">PAYE</option>
                        </Input>
                    </FormGroup>
                </div>
                <div className="field percent">
                    <FormGroup >
                    <InputField 
                        className="clear-ip-field percent"
                        name='percent'
                        id={index}
                        type="number"
                        min='0'
                        max='100'
                        value={percent}
                        onBlur={ (evt) => onBlurHandler(evt, fields.percent, index) } 
                        onChange={ (evt) => onChangeHanlder(evt, fields.percent, index) } />
                    
                    <span className="symbol b">%</span>
                    </FormGroup>
                </div>
            </div>
            <Link 
                to=''
                onClick={(evt) => willRemoveHanlder(data, index, evt)}
                className="remove-icon" tag={'discard'}><span className="dash"></span></Link>
        </React.Fragment>
    );
}

class TaxesPane extends React.Component {

    static pageOption = { 
        pageTitle: 'Pay Slips',
        disableBodyScroll: true
    };

    constructor(props) {
        super(props);
        this.state = {
           
        };
        
        this.addTaxItem = this.addTaxItem.bind(this);
        this.removeTaxItem = this.removeTaxItem.bind(this);
        this.onChangeHanlder = this.onChangeHanlder.bind(this);
        this.onBlurHandler = this.onBlurHandler.bind(this);
        this.showSettings = this.showSettings.bind(this);
    }

    componentDidMount() {}

    componentWillUnmount() {}

    static getDerivedStateFromProps(props, state) {
        return null;
    }

    addTaxItem (evt) {
        const newItem = {
            taxCode: '',
            percent: 0.0.toFixed(1)
        };

        const taxes = [
            ...this.props.payrollForm.taxes,
            newItem
        ] 

        const fieldSet = {
            taxes: taxes
        };

        this.props.payrollFieldDidChange(fieldSet);
    }

    removeTaxItem(data, index, evt) {
        evt.preventDefault();
        const removeItems = this.props.payrollForm.taxes.filter((item, i) => index !== i)
        const fieldSet = {
            taxes: removeItems
        };
        this.props.payrollFieldDidChange(fieldSet);
    }

    onChangeHanlder(evt, field, index) {
        const changeItems = this.props.payrollForm.taxes.map((item, i) => {
            return (i !== index) ? item : {
                ...item,
                [field]: evt.target.value
            }
        })
        const fieldSet = {
            taxes: changeItems
        };
        this.props.payrollFieldDidChange(fieldSet);
    }

    onBlurHandler(evt, field, index) {
        const fieldSet = {
            target: {
                value: parseFloat(evt.target.value).toFixed(1)
            }
        };
        this.onChangeHanlder(fieldSet, field, index);
    }

    showSettings() {
        this.props.history.push({ pathname: urls.settingsUri});
    }

    render () {
        const taxes = this.props.payrollForm.taxes;
        const hasTaxes = taxes.length > 0;
        const disableAddBtn = true;

        return (
            <React.Fragment>
            <div className="taxes-pane">
                <p>Taxes will be deducted from employee's Gross Salary.</p>
                <div className="taxes-content">
                    <List className="taxes-list">
                        { hasTaxes ?
                        <>
                            <li className="titles">
                                <div className="tax-code">Tax Code</div>
                                <div className="percent">Percent</div>
                            </li>
                            { taxes.map((item, i) => {
                                    return (
                                    <li key={i} className="tax-item">
                                        <TaxItem 
                                        data={item} index={i}
                                        onBlurHandler={this.onBlurHandler}
                                        willRemoveHanlder = {this.removeTaxItem}
                                        onChangeHanlder={this.onChangeHanlder} />
                                    </li>
                                    )
                                })
                            }
                        </>
                        :
                            <NotFound.Items onClick={this.showSettings} messageComponent={<span>
                                No Taxes were found. Taxes can be created in the <b>Settings</b> area.
                            </span>} />
                        }
                    </List>
                    { !disableAddBtn && 
                        <div className="tax-btn-holder">
                            
                            <Button 
                            className="add-tax-btn" 
                            onClick={this.addTaxItem}>
                                <span className="icon">
                                    <img src={getIconUrl("plusIcon")} alt='' />
                                </span>
                                Add Tax Item
                            </Button>
                            
                        </div>
                    }
                </div>
            </div>
            <Section className="actions-section">
               <div className="btns-wrap">
                 {this.props.BackButton}
                 <Button onClick={this.props.nextStep}>
                 Review</Button>
                 </div>
             </Section>
             </React.Fragment>
        );
    }
}

export { TaxesPane };