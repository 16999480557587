import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { Comparator, dateFilter } from 'react-bootstrap-table2-filter'
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import PropType  from 'prop-types';

import _ from 'lodash';

import {pictureInitials} from '../../utility/helpers';
import { PaginationPanel, PaginationTotal } from '.';
import { 
    InputField,
    Label,
    NavLink
} from '../index';

const employeeInfo = (data) => {
    const name = `${data.value.firstName} ${data.value.lastName}`;
    const details = data.value.details;
    const empNumber = data.value.employeeNo;

    const picture = data.value.picture ? data.value.picture.fileDownloadUri : pictureInitials(data.value.firstName, data.value.lastName);

    const hasPicture = data.value.picture ? true : false;

    return (
        <ContextMenuTrigger id="users_table_context_menu">
        <div className="employee-info">
            <div className="picture-holder">
                {hasPicture ? 
                    <div className="picture-content">
                        <img className="picture" src={picture} alt='' />
                    </div>
                    :
                    <div className="picture-content">
                        { picture }
                    </div>
                }
            </div>
            <div className="employee-info-holder">
                <div className="employee-info-content">
                    <h3>{name}</h3>
                    <p className="emp-info highlight">Emp# {empNumber}</p>
                    <p className="emp-info">Fixed Salary: {details.isFixedSalary ? 'YES' : 'NO' }</p>
                    <p className="emp-info">TRN: {details.trn}</p>
                    <p className="emp-info">NIS: {details.nis}</p>   
                </div>
            </div>
        </div>
        </ContextMenuTrigger>
    );
}



const wageSalaryDef = (actions, cell) => {
    return (data) => {
        
        const basicSalary =  parseFloat(data.value.salary);
        var basicRate = data.value.basicRate;

        const hours = data.value.hours;

        const rateWidth = `${basicRate}`.length + 2;
        const hoursWidth = `${hours}`.length + 1;

        const fieldNames = {
            basicRate: 'basicRate',
            hours: 'hours'
        }

        const rateLabel = data.value.isFixedSalary ? 'Salary' : 'Hourly Rate';
        
        return(
        <ContextMenuTrigger id="users_table_context_menu">
        <div className="ratehours-info">
            <div className="ratehours-info-holder">
                <div className="ratehours-info-content">
                    <div className="fields-group">
                     <Label>{rateLabel}</Label>
                        <div className="field">
                        <span className="symbol f">$</span>
                            <InputField 
                            className="clear-ip-field rate"
                            id={'rate'}
                            type="number"
                            min="0"
                            value={basicRate}
                            style={
                                { width: `${rateWidth}ch`}
                            }
                            onBlur={ (evt) => actions.onBlurHandler(data.id, fieldNames.basicRate, cell, evt) }
                            onChange={ (evt) => actions.cellTextFieldChange(data.id, fieldNames.basicRate, cell, evt) } />
                        </div>
                        { data.value.isFixedSalary &&
                            <p className="symbol info">${basicSalary.toCurrency()}/yr</p> 
                        }

                        { !data.value.isFixedSalary &&
                        <React.Fragment>
                        <Label></Label>
                        <div className="field">
                            <InputField 
                            className="clear-ip-field hours"
                            id={'hours'}
                            type="number"
                            min="0"
                            value={hours}
                            style={
                                { width: `${hoursWidth}ch`}
                            }
                            onBlur={ (evt) => actions.onBlurHandler(data.id, fieldNames.hours, cell, evt) }
                            onChange={ (evt) => actions.cellTextFieldChange(data.id, fieldNames.hours, cell, evt) } />
                            <span className="symbol b">hrs</span>
                        </div>
                        </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </div>
        </ContextMenuTrigger>
    )};
}

const earningsDef = (actions, cell) => {
    
    return (data) => {
        
        var useCommission = data.value.commission.use;
        var useBonus = data.value.bonus.use;
        const commission =  data.value.commission.amount;
        const bonus = data.value.bonus.amount;

        const commissionWidth = `${commission}`.length + 2;
        const bonusWidth = `${bonus}`.length + 2;
        
        const fieldNames = {
            bonus: 'bonus',
            commission: 'commission'
        }

        return(
        <ContextMenuTrigger id="users_table_context_menu">
        <div className="added-earnings">
            <div className="added-earnings-holder">
                <div className="added-earnings-content">
                    <div className="fields-group">
                        { !useCommission &&
                        <NavLink className="add-item" onClick={() => actions.addInputFieldHandler (data.id, fieldNames.commission, cell )} >
                            <span className="plus-icon">+</span>Commission
                        </NavLink>
                        }
                        { useCommission &&
                        <React.Fragment>
                            <Label>Commission</Label>
                            <div className="field">
                                <span className="symbol f">$</span>
                                <InputField 
                                className="clear-ip-field hours"
                                id={'hours'}
                                type="number"
                                min="0"
                                value={commission}
                                style={
                                    { width: `${commissionWidth}ch`}
                                }
                                onBlur={ (evt) => actions.onBlurHandler(data.id, fieldNames.commission, cell, evt)}
                                onChange={ (evt) => actions.cellTextFieldChange(data.id, fieldNames.commission, cell, evt) } />
                            </div>
                        </React.Fragment>
                        }
                    </div>
                    <div className="fields-group">
                        { !useBonus &&
                        <NavLink className="add-item" onClick={() => actions.addInputFieldHandler (data.id, fieldNames.bonus, cell)} >
                            <span className="plus-icon">+</span>Bonus
                        </NavLink>
                         }
                        { useBonus &&
                        <React.Fragment>
                            <Label>Bonus</Label>
                            <div className="field">
                                <span className="symbol f">$</span>
                                <InputField 
                                className="clear-ip-field hours"
                                id={'bonus'}
                                type="number"
                                min="0"
                                value={bonus}
                                style={
                                    { width: `${bonusWidth}ch`}
                                }
                                onBlur={ (evt) => actions.onBlurHandler(data.id, fieldNames.bonus, cell, evt) }
                                onChange={ (evt) => actions.cellTextFieldChange(data.id, fieldNames.bonus, cell, evt) } />
                            </div>
                        </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </div>
        </ContextMenuTrigger>
    )};
}

const grossPayDetails = (actions, cell) => {
    //return function with cell content
    
    return (data) => {
     
        var usePension = data.value.pension.use;
        var useReimburse = data.value.reimbursement.use;

        const pension = data.value.pension.amount;
        const reimburse = data.value.reimbursement.amount;

        const pensionWidth = `${pension}`.length + 2;
        const reimburseWidth = `${reimburse}`.length + 2;

        const fieldNames = {
            pension: 'pension',
            reimbursement: 'reimbursement'
        }
 
        return(
        <div className="gross-pay">
            <ContextMenuTrigger id="users_table_context_menu">
            
            <div className="gross-pay-holder">
                <div className="gross-pay-content">
                    <p className="gross-pay">${data.value.grossPay.toCurrency()}</p>
                    <div className="fields-group">
                        { !usePension &&
                        <NavLink className="add-item" onClick={() => actions.addInputFieldHandler (data.id, fieldNames.pension, cell) }>
                            <span className="plus-icon">+</span>Pension
                        </NavLink>
                        }
                        { usePension &&
                        <React.Fragment>
                            <Label>Pension</Label>
                            <div className="field">
                                <span className="symbol f"> - $</span>
                                <InputField 
                                className="clear-ip-field hours"
                                id={'hours'}
                                type="number"
                                min="0"
                                value={pension}
                                style={
                                    { width: `${pensionWidth}ch`}
                                }
                                onBlur={ (evt) => actions.onBlurHandler(data.id, fieldNames.pension, cell, evt) }
                                onChange={ (evt) => actions.cellTextFieldChange(data.id, fieldNames.pension, cell, evt) } />
                            </div>
                        </React.Fragment>
                        }
                    </div>
                    <div className="fields-group">
                        { !useReimburse &&
                        <NavLink className="add-item"  onClick={() => actions.addInputFieldHandler (data.id, fieldNames.reimbursement, cell)} >
                            <span className="plus-icon">+</span>Reimbursement
                        </NavLink>
                         }
                        { useReimburse &&
                        <React.Fragment>
                            <Label>Reimbursement</Label>
                            <div className="field">
                                <span className="symbol f"> - $</span>
                                <InputField 
                                className="clear-ip-field hours"
                                id={'bonus'}
                                type="number"
                                min="0"
                                value={reimburse}
                                style={
                                    { width: `${reimburseWidth}ch`}
                                }
                                onBlur={(evt) => actions.onBlurHandler(data.id, fieldNames.reimbursement, cell, evt)}
                                onChange={ (evt) => actions.cellTextFieldChange(data.id, fieldNames.reimbursement, cell, evt) } />
                            </div>
                        </React.Fragment>
                        }
                    </div>
                </div>
            </div>
            </ContextMenuTrigger>
        </div>
    )};
}

class EarningsTable extends React.Component { 
    static propsTypes = {
        tableData: PropType.array.require,
        actionResponsers: PropType.object,
    }

    static defaultProps = {
        actionResponsers: {
            editButton: null,
        }
    }

    constructor(props) {
        super(props);
        
        this.state = {
            selected: [],
            updateCount: 0,
        };
        
        this.willEnterRow = this.willEnterRow.bind(this);
        this.didContextClick = this.didContextClick.bind(this);
        this.didClickRow = this.didClickRow.bind(this);
    }

    createColumnDefinitions(quickActions) { 
        return [
            {
                dataField: 'employee',
                text: "Employee's",
                headerAttrs: {
                    hidden: false,
                    className: 'th-employee-cell',
                },
                classes: 'id-employee-cell td-cell',
                formatter: employeeInfo
            },
            {
                dataField: 'wageSalary',
                text: "Wages / Salary",
                headerAttrs: {
                    hidden: false,
                    className: 'th-rate-hours-cell',
                },
                classes: 'id-rate-hours-cell td-cell',
                formatter: wageSalaryDef(quickActions, 'wageSalary')
            },
            {
                dataField: 'addedEarnings',
                text: "Additional Earnings",
                headerAttrs: {
                    hidden: false,
                    className: 'th-added-earnings-cell',
                },
                classes: 'id-added-earnings-cell td-cell',
                formatter: earningsDef(quickActions, 'addedEarnings')
            },
            {
                dataField: 'grossPay',
                text: "Gross Pay & Deductions",
                headerAttrs: {
                    hidden: false,
                    className: 'th-gross-pay-cell',
                },
                classes: 'id-gross-pay-cell td-cell',
                formatter: grossPayDetails( quickActions, 'grossPay')
            }
        ];
    }

    handleSelect(row, isSelected) {
        var selections = [];
        if (isSelected) {
            selections = [...this.state.selected, row.id];
        } else {
            selections = this.state.selected.filter(itemId => itemId !== row.id);
        }

        const fieldSet = {
            earningsSelected: selections
        }
        this.props.payrollFieldDidChange(fieldSet);
        this.setState({ selected: selections });
    }

    handleSelectAll(isSelected, rows) {
        var selections = [];
        if (isSelected) {
            selections= _.map(rows, 'id');
        }
        const fieldSet = {
            earningsSelected: selections
        }
        this.props.payrollFieldDidChange(fieldSet);
        this.setState({ selected: selections })
    }
    willEnterRow (evt, row, rowIndex) { }

    didClickRow(evt, row, index) {
        const rowId = evt.target.getAttribute('rowid');
        const actionTag = evt.target.getAttribute('actiontag');
        if (rowId && actionTag && actionTag === 'view') {
            this.props.quickActions.viewDetails(row.details);
        }
    }  

    didContextClick (evt, row, rowIndex) {
        evt.preventDefault();
    }

    render () {
        
        const columnDefs = this.createColumnDefinitions(this.props.actionHandlers);
        const paginationDef = paginationFactory({
            paginationSize: 5,
            sizePerPage: 50,
            showTotal: true,
            pageListRenderer: (props) => (
                <PaginationPanel { ...props } size="sm" className="ml-md-auto mt-2 mt-md-0" />
            ),
            paginationTotalRenderer: (from, to, size) => (
                <PaginationTotal { ...{ from, to, size } } />
            )
        
        });
        const selectRowConfig = {
            mode: 'checkbox',
            selected: this.state.selected,
            classes: '--selected',
            onSelect: this.handleSelect.bind(this),
            onSelectAll: this.handleSelectAll.bind(this),
            selectionRenderer: ({ mode, checked, disabled }) => (
                <InputField onChange={()=> {}} id="" type={ mode } checked={ checked } disabled={ disabled } />
            ),
            selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
                <InputField onChange={()=> {}} id="" type={ mode } checked={ checked } innerRef={el => el && (el.indeterminate = indeterminate)} />
            )
        };

        const rowEventsHandlers = {
            onContextMenu: this.didContextClick,
            onMouseEnter: this.willEnterRow,
            onClick: this.didClickRow
        };

        var options = {
            noDataMessage: () => 'No payee found.'
        };

        const earningsData = this.props.tableData;   
        return (
            <ToolkitProvider
                keyField="id"
                data={ earningsData }
                columns={columnDefs} >
                {
                    props => (
                        <React.Fragment>
                             
                                <h5 className="emp-count"
                                >
                                    { this.state.selected.length > 0 ?
                                    <span>
                                        <b>{this.state.selected.length}</b> of {earningsData.length} employees selected
                                    </span>
                                    :
                                     <span>Payroll will run for all employee's</span>
                                    }
                                </h5>
                            
                            <BootstrapTable
                            classes="table-responsive earnings-table"
                            pagination={ paginationDef }
                            filter={ filterFactory() }
                            selectRow={ selectRowConfig }
                            bordered={ false }
                            responsive
                            { ...props.baseProps }
                            rowEvents={ rowEventsHandlers }
                            loading={ true } 
                            noDataIndication={options.noDataMessage}
                        />
                        </React.Fragment>
                    )
                }
            </ToolkitProvider>
        );
    }
}

export { EarningsTable }