import * as actionTypes from '../actionType';
//Department
export const getAllDepartments = () => ({
    type: actionTypes.FETCH_DEPARTMENTS
});
export const setDepartmentsData = (data) =>({
    type: actionTypes.SET_DEPARTMENT_DATA,
    payload: data
});
export const submitDepartmentDataAction = (payload) => ({
    type: actionTypes.DEPARTMENT_SUBMIT_DATA,
    payload,
});