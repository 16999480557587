import * as actionTypes from '../actionType';
import { isError } from '../../service/agent';
import { PROFILE_PANES } from '../../common/constant';
import { combineReducers } from 'redux'
import { ApiRequestManager } from '../../utility/ApiRequestManager';

const initialState = {
  activePane: PROFILE_PANES.personalInfo,

  //store values being typed into fields
  profileForm: {
    profileImage: null,
    userType: '',
    workspace: null,
    role: null,
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    currentPassword: '',
    password: '',
    passwordTwo: '',
    phoneNumber: '',
    blank: null,

    authorizerId: null,
    profession: '',
    digitalSignature: '',
    
    address: { }
  },
};

const initialChangePasswordState = {
    success: false,
    message: ''
}

let error = null;
let apiOutcome = null;
let requestManager = null;
//TODO: add the functionality. Use the fields from profile data to set form field values
function initProfileForm(userData) {
  return !userData ? initialState.profileForm : {
    userType: userData.type,
    role: userData.role.id,
    workspace: userData.workspaceBounds,
    username: userData.username,
    firstName: userData.firstName,
    lastName: userData.lastName,
    profileImage: userData.picture?.fileDownloadUri ?? null,
    gender: userData.gender || null,
    email: userData.email,

    authorizerId: userData?.authorizerId,
    profession: userData?.profession,
    digitalSignature: userData?.digitalSignature,
  };
}

function profileSlice(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_ACTIVE_PROFILE_PANE:
      return {
        ...state,
        activePane: action.payload,
      };

    case actionTypes.PROFILE_FIELD_CHANGED:
      return {
        ...state,
        profileForm: {
          ...state.profileForm,
          ...action.payload,
        },
      };

    //Inits the target field to the stored value
    case actionTypes.CLICKED_EDIT_PROFILE_FIELD:
      return {
        ...state,
        profileForm: {
          ...state.profileForm,
          ...action.payload,
        },
      };

    case actionTypes.RESET_PROFILE_FORM:
      
      return {
        ...state,
        profileForm: initProfileForm(action.payload),
      };
    case actionTypes.PROFILE_SUBMIT_DATA_RESULT:
      requestManager = ApiRequestManager.getInstance(ApiRequestManager);
      apiOutcome = requestManager.getApiOutcome(actionTypes.PROFILE_SUBMIT_DATA) || {};

      return {
        ...state,
        profileForm: apiOutcome?.success ? initProfileForm(action.payload) : state.profileForm,
      };
    case actionTypes.PASSWORD_CHANGE_SUBMIT_DATA_RESULT:
      requestManager = ApiRequestManager.getInstance(ApiRequestManager);
      apiOutcome = requestManager.getApiOutcome(actionTypes.PASSWORD_CHANGE_SUBMIT_DATA) || {};

      let passwordFields = {
          currentPassword: apiOutcome?.success ? '': state.profileForm.currentPassword,
          password: apiOutcome?.success ? '': state.profileForm.password,
          passwordTwo: apiOutcome?.success ? '': state.profileForm.passwordTwo
      }

      return {
        ...state,
        requestType: action.payload.requestType,
        profileForm: {
            ...state.profileForm,
            ...passwordFields
        }
      }

    default:
      return state;
  }
}

const changePasswordSlice = (state = initialChangePasswordState, action) => {
   
  switch(action.type) {
    case actionTypes.PASSWORD_CHANGE_SUBMIT_DATA_RESULT:

    const changePasswordResponse = {
      message:  action.payload.message
    }
    return {
      ...state,
      ...changePasswordResponse
    }
    default:
      return {
        ...state
      }
  }
}

export default combineReducers({
  profileSlice,
  changePasswordSlice
})

