import React from 'react';
import PropType from 'prop-types';
import {
    List,
    NavLink,
    Button
} from './../../../components';
import { getIconUrl } from '../../../common/images-catalogue';
import { Loader } from './LoadingIndicator';


export const PrintBar = (props) => {

    const {handlePrint, onAddNotes, notes, footer, onAddFooter,pdf, isFetchingPrintGroups } = props;
    const addNotes = () => {
        onAddNotes && onAddNotes();
    }
    const addFooter = () => {
        typeof onAddFooter === 'function' && onAddFooter();
    }

    return (
        <div className="print-bar">
            <div className="inner">
                <div className="actions">
                    <div className="page-actions">
                        { notes && 
                            <NavLink onClick={addNotes}>Add notes</NavLink>
                        }
                        { footer &&
                            <NavLink onClick={addFooter}>Add footer</NavLink>
                        }
                    </div>
                    <List className='print-bar__action-list'>
                        <li className="act-item">
                            <Button>
                                <span className="icon"><img src={getIconUrl('newMessage')} alt=''/></span>
                            </Button>
                        </li>
                        <li className="act-item">
                            { isFetchingPrintGroups ? 
                            <Loader visible={isFetchingPrintGroups} />
                            :
                            <Button  onClick={handlePrint}>
                                <span className="icon"><img src={getIconUrl('print')} alt=''/></span>
                            </Button>}
                        </li>
                        { pdf && 
                        <li className="act-item">
                            <Button>
                                <span className="icon"><img src={getIconUrl('doc')} alt=''/></span>
                            </Button>
                        </li>
                        }
                    </List>
                </div>
            </div>
        </div>
    );
}

PrintBar.defaultProps = {
    notes: false,
    footer: false,
    pdf: false,
    isFetchingPrintGroups: false,
}

PrintBar.propTypes = {
    notes: PropType.bool,
    footer: PropType.bool,
    pdf: PropType.bool,
    isFetchingPrintGroups: PropType.bool
}