import * as actionTypes from '../actionType';
import { isError } from '../../service/agent';
import { REQUESTS_TYPE } from '../../common/constant';
import { ApiRequestManager } from '../../utility/ApiRequestManager';

const initialState = {
    viewInit: false,
    departmentsData: null,
};

var error = null;
const emptyArray = [];
let requestManager = null;
let apiOutcome = null;

const departmentReducer = (state = initialState, action ) => { 
    switch(action.type) {
        case actionTypes.FETCHED_DEPARTMENTS_RESULTS :
            error = isError(action.payload);
            return {
                ...state,
                departmentsData: error ? emptyArray : action.payload.map((department, index)=>{
                    return {
                        ...department,
                        index
                    }
                })
            }

        case actionTypes.SET_DEPARTMENT_DATA:
            return {
                ...state,
                departmentsData: action.payload
            }

        case actionTypes.DEPARTMENT_SUBMIT_DATA:
            return {
                ...state,
                isSaving: true,
                requestType: action.payload.requestType,
            }

        case actionTypes.DEPARTMENT_SUBMIT_DATA_RESULT:

            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.DEPARTMENT_SUBMIT_DATA) ?? {};

            if (apiOutcome?.success && apiOutcome.requestType === REQUESTS_TYPE.DELETE) {
                const updatedDepartments = apiOutcome?.error ? state.departmentsData : state.departmentsData
                .filter(department => department.id !== action.payload.departmentId)

                return {
                    ...state,
                    isSaving: false,
                    departmentsData: updatedDepartments
                }
            }else {
                return {
                    ...state,
                    isSaving: false,
                    departmentsData: apiOutcome?.error ? state.departmentsData : action.payload.map((department, index)=>{
                        return {
                            ...department,
                            index
                        }
                    })
                }
            }

        default:
            return state;
    }
};

export default departmentReducer;