import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { Comparator, dateFilter } from 'react-bootstrap-table2-filter'
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import PropType  from 'prop-types';

import { InputField, List , NavLink} from '../index';
import {pictureInitials} from './../../utility/helpers';
import { PAGE_SIZE } from '../../config/pagination';
import { PaginationPanel, PaginationTotal } from './';
import { getIconUrl } from '../../common/images-catalogue';

const generateRow = (data) => ({
    id: data.id,
    patient: data,
    client: data,
    details: data
});

const patientNameInfo = (data) => {
    const name = `${data.firstName} ${data.lastName}`;
    const picture = data.picture ? data.picture.fileDownloadUri : pictureInitials(data.firstName, data.lastName);

    const hasPicture = data.picture ? true : false;
    
    return (
        <ContextMenuTrigger id="users_table_context_menu">
        <div className="patient-info">
            <div className="patient-picture">
                <div className="picture-holder">
                    {hasPicture ? 
                        <div className="picture-content">
                            <img className="picture" src={picture} alt='' />
                        </div>
                        :
                        <div className="picture-content">
                            { picture }
                        </div>
                    }
                </div>
            </div>
            <div className="patient-info-holder">
                <div className="patient-info-content">
                    <h3>{name}</h3>
                    <p className="gender-info">{data.gender}, <span>Age: {data.ageDetails?.years}</span></p>
                </div>
            </div>
        </div>
        </ContextMenuTrigger>
    );
}

const clientsInfo = (clientOwner, isClientDashboard) => (data) => {
    const {clients} = data;
    let firstClient = clients.length && !clients.empty() ? clients.first(): null;
    if (clients.length > 1 && clientOwner && isClientDashboard) { 
        firstClient = clients.find((client) => client.userName === clientOwner);
   }
    const clientName = !firstClient ? '--' : firstClient.officeName.empty() ? `${firstClient.firstName} ${firstClient.lastName}` : firstClient.officeName;
    const email = firstClient ? firstClient.email: '--';
    const additionalClientCount = clients.length > 1 && firstClient ? clients.length - 1: null;

    return (
        <ContextMenuTrigger id="users_table_context_menu">
        <div className="client-info">
            <div className="client-info-holder">
                <div className="client-info-content">
                    <h3>{additionalClientCount && <span className='client-count'>+{additionalClientCount}</span>} {clientName}</h3>
                    <p className="info">{email}</p>
                </div>
            </div>
        </div>
        </ContextMenuTrigger>
    );
}


const patientDetails = (actions) => {
    //return function with cell content
    return (data) => (
        <div className="user-details">
            <ContextMenuTrigger id="users_table_context_menu">
            <div className="toolbar">
                <List className="action-menu">
                    <li className="action-item">
                        <NavLink key={data.id} rowid={data.id} actiontag="view">
                            <span className="icon"><img className="icon" src={getIconUrl("edit")} alt='' /></span>
                        </NavLink>
                    </li>
                    <li className="action-item">
                        <NavLink key={data.id} rowid={data.id}>
                            <span className="icon"><img className="icon" src={getIconUrl("option")} alt='' /></span>
                        </NavLink>
                    </li>
                </List>
            </div>
            
            <div className="patient-details-holder">
                <div className="patient-details-content">
                    
                    <span className="auth-snippet email">{data.email}</span>
                    <p className="info">Reg#: {data.registrationNo}</p>
                </div>
            </div>
            </ContextMenuTrigger>
        </div>
    );
}

class PatientTable extends React.Component { 
    static propsTypes = {
        tableData: PropType.array.require,
        actionResponsers: PropType.object,
    }

    static defaultProps = {
        actionResponsers: {
            editButton: null,
        }
    }

    constructor(props) {
        super(props);
        
        this.state = {
            selected: [],
            rowInFocus: null
        };
        this.table = React.createRef();
        this.willEnterRow = this.willEnterRow.bind(this);
        this.didClickRow = this.didClickRow.bind(this);
        this.didContextClick = this.didContextClick.bind(this);
        this.onTableChange = this.onTableChange.bind(this);
    }

    createColumnDefinitions(quickActions, client, isClientDashboard) { 
        return [
            {
                dataField: 'patient',
                text: "Patient",
                headerAttrs: {
                    hidden: false,
                    className: 'th-patient-cell',
                },
                classes: 'id-patient-cell td-cell',
                formatter: patientNameInfo
            },
            {
                dataField: 'client',
                text: "Doctor",
                headerAttrs: {
                    hidden: false,
                    className: 'th-client-cell',
                },
                classes: 'id-client-cell td-cell',
                formatter: clientsInfo(client, isClientDashboard)
            },
            {
                dataField: 'details',
                text: "Details",
                headerAttrs: {
                    hidden: false,
                    className: 'th-details-cell',
                },
                classes: 'id-details-cell td-cell',
                formatter: patientDetails( quickActions )
            }
        ];
    }

    handleSelect() { }

    handleSelectAll() { }

    handleOnChange() {}

    willEnterRow (evt, row, rowIndex) { }

    didClickRow(evt, row, index) {
        const rowId = evt.target.getAttribute('rowid');
        const actionTag = evt.target.getAttribute('actiontag');
        if (rowId && actionTag && actionTag === 'view') {
            this.props.quickActions.viewDetails(row.patient);
        }
    }  

    didContextClick (evt, row, rowIndex) {
        evt.preventDefault();
    }

    onTableChange(evt, opts) {
        this.props.onTableChange(opts);
    }

    render () {
        const columnDefs = this.createColumnDefinitions(this.props.quickActions, this.props.client, this.props.isClientDashboard);
        const paginationDef = paginationFactory({
            paginationSize: 1,
            sizePerPage: this.props.metaData?.pageSize || PAGE_SIZE,
            showTotal: true,
            totalSize: this.props.metaData?.totalItems || 0,
            page: this.props.metaData?.currentPage + 1 || 0,
            pageListRenderer: (props) => (
                <PaginationPanel { ...props } size="sm" className="ml-md-auto mt-2 mt-md-0" />
            ),
            paginationTotalRenderer: (from, to, size) => (
                <PaginationTotal { ...{ from, to, size } } />
            ),
        });
        const selectRowConfig = {
            mode: 'checkbox',
            selected: this.state.selected,
            onSelect: this.handleSelect.bind(this),
            onSelectAll: this.handleSelectAll.bind(this),
            selectionRenderer: ({ mode, checked, disabled }) => (
                <InputField id="" onChange={this.handleOnChange}  type={ mode } checked={ checked } disabled={ disabled } />
            ),
            selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
                <InputField id="" onChange={this.handleOnChange}  type={ mode } checked={ checked } innerRef={el => el && (el.indeterminate = indeterminate)} />
            )
        };

        const rowEventsHandlers = {
            onContextMenu: this.didContextClick,
            onMouseEnter: this.willEnterRow,
            onClick: this.didClickRow
        };

        var options = {
            noDataMessage: () => 'No patients found.'
        };

        let patientsData = this.props.tableData.map(patient => generateRow(patient))
        
        return (
            <ToolkitProvider
                keyField="id"
                data={ patientsData }
                columns={columnDefs} >
                {
                    props => (
                        <React.Fragment>
                            <BootstrapTable
                            ref={this.table}
                            remote={{ pagination: this.props.metaData?.remote }}
                            classes="table-responsive patients-table"
                            pagination={ paginationDef }
                            filter={ filterFactory() }
                            selectRow={ selectRowConfig }
                            bordered={ false }
                            responsive
                            { ...props.baseProps }
                            rowEvents={ rowEventsHandlers }
                            loading={ true } 
                            noDataIndication={options.noDataMessage}
                            onTableChange={this.onTableChange}
                        />
                        </React.Fragment>
                    )
                }
            </ToolkitProvider>
        );
    }
}

export { PatientTable }