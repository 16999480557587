/* eslint-disable no-extend-native */
Storage.prototype.setMap = function(key, value) {
    this.setItem(key, JSON.stringify(value));
}

Storage.prototype.getMap = function(key) {
    var value = this.getItem(key);
    return value && JSON.parse(value);
}
// eslint-disable-next-line no-extend-native
String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
}
// eslint-disable-next-line no-extend-native
String.prototype.yesOrNoToBoolean = function() {
    return this === 'YES' ? true : false;
}
// eslint-disable-next-line no-extend-native
String.prototype.stringToBoolean = function() {
    return this === 'true' ? true : false;
}

String.prototype.empty = function() {
    return this.length === 0 ? true : false;
}

Array.prototype.empty = function() {
    return Array.isArray(this) && this.length === 0 ? true : false;
}

Array.prototype.first = function() {
    return this[0];
}

Array.prototype.last = function() {
    return this[this.length - 1];
}

Array.prototype.removeItem = function(index) {
    return this.slice(0, index-1).concat(this.slice(index, this.length));
}

Array.prototype.merge = function(items) {
    return this.concat(items);
} 

Function.prototype.blank = function(obj) {
    return !obj || obj === undefined || Object.keys(obj).length === 0 ? true : false;
}

Array.prototype.blank = function(obj) {
    return !this || this === undefined || this.length === 0 ? true : false;
}

Object.defineProperty(Object.prototype, 'empty',{
    value: function(){ return Object.keys(this).length === 0 ? true : false; },
    writable: true,
    configurable: true,
    enumerable: false
});

Number.prototype.round = function(obj) {
    return Math.round((this + Number.EPSILON) * 100) / 100;
}

Number.prototype.toCurrency = function (decimalCount = 2, decimal = ".", thousands = ",") {
    try {
        var amount = this;
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
  
        const negativeSign = amount < 0 ? "-" : "";
  
        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;
  
        const value =  negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");

        return value;
    } catch (e) {
      console.log(e)
    }
  };

Array.prototype.sortArrayObjectByKey = function(prop) {
    return this.map((item, index) =>  {
        item[prop] = index;
        return  item;
    });
}

String.prototype.ellipsis = (textLimit) => {
    /* if (this.length > textLimit) {
      return `${this.substring(0, textLimit)}...`
    } */
    return this
  }