
import React, { useCallback, useState, useRef } from 'react';
import * as actionTypes from '../../../store/actionType';
import { ApiRequestManager } from '../../../utility/ApiRequestManager';
import { PermissionEnforcer } from '../../../utility/PermissionEnforcer';
import * as actions from '../../../store/actions';
import { store } from '../../../store/store';

import {
    Row,
    Col
} from '../../../components';

export const WorkspaceBar = (props) => {
    
    const submitUpdate = (payload) => store.dispatch(actions.updateWorkspaceName(payload));
    const textField = useRef(null);
    const apiRequestManager = ApiRequestManager.getInstance(ApiRequestManager);
    apiRequestManager.register(actionTypes.SUBMIT_WORKSPACE_NAME, submitUpdate);

    const enforcer = PermissionEnforcer.getInstance(PermissionEnforcer);
    
    const workspaceName = props.workspaceData !== null && props.show ? props.workspaceData.name : '';
    
    const accountNo = props.accountData  !== null && props.show ? props.accountData.accountNo : '';
    const [inputValue, setInputValue] = useState(workspaceName);
 
    const workspaceNameDidChange =  useCallback((evt) => {
        if (!enforcer.isPermitted('workspace', 'change')) 
            return
        
        const value = textField.current.innerHTML.replace(/<br>/gi, '');
        setInputValue (value);

        if (evt.type === 'blur' && !value.empty()) { 
            const workspacePayload = {
                workspaceName : value.replace(/<br>/gi, ''),
                workspaceId : props.workspaceData.id
            }

            if(workspaceName !== inputValue  && ! apiRequestManager.inProgress(actionTypes.SUBMIT_WORKSPACE_NAME)) {
                apiRequestManager.queueRequest(actionTypes.SUBMIT_WORKSPACE_NAME, workspacePayload);
            }
        }
        
    }, [apiRequestManager, enforcer, inputValue, props.workspaceData, workspaceName]);
      
    const editableAttribute = enforcer.isPermitted('workspace', 'change') ? {contentEditable: true} : {}

    return ( !props.show ?  null :
      
      <React.Fragment>
            <div className="workspace-bar-wrap">
                <div className="workspace-bar">
                    <Row className="inner">
                        <Col sm='12' md={8}>
                            <div className="workspace-name" >
                                <label>Workspace</label>
                                <span ref={textField} className="ip-field-name" {...editableAttribute} suppressContentEditableWarning={true}  onInput={ workspaceNameDidChange} onBlur={workspaceNameDidChange}>
                                    { workspaceName }
                                </span>
                            </div>
                        </Col> 
                        <Col sm='12' md={4}>
                            <div className="account-info">
                                <p className="state"><span className="text">Account:</span><span className="status">Active<i className="indicator good"></i></span></p>
                                <h3 className="account-number">{ accountNo }</h3>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    ); 
}


export default  WorkspaceBar


    
