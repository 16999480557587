import {React} from 'react';

export const ErrorMessage = (props) => {
    const {message} = props;

    return (
        <span className="errors">
            <div className="wrap">
                <span className="icon">
                    <span className="circle">
                        <span className="vline lng"></span>
                        <span className="vline dot"></span>
                    </span>
                </span>
            </div>
            <p>{message}</p>
        </span>
    );
}