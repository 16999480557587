import React from 'react';
import PropTypes from 'prop-types';
import {  
    PieChart, 
    Pie,
    Cell,
    ResponsiveContainer,
    Legend, 
} from 'recharts';

import colours from './../../../../assets/colors';
import { getHexColor } from './../../../../utility/helpers';

const dataset = [
    {name: 'Group A', value: 400},
    {name: 'Group B', value: 300},
    {name: 'Group C', value: 300},
    {name: 'Group C', value: 300}
];

const colors = {
    white: '#ffffff',
    color1: '#228B8C',
    color2: '#2BAEAE',
    color3: '#32C8C7',
    color4: '#37DCDD',
}

const COLORS = [ colors.color1,  colors.color2,  colors.color3,  colors.color4];

const TinyDonutChartBig = (props) => {
    
    const data = props.data;
    console.log(data)
    return (
        
    <PieChart width={ 220 } height={ 220 } >
        <Pie
            //cx={115} cy={115}
            data={data}
            dataKey="value"
            stroke={ colors['white'] }
            innerRadius={ 75 }
            outerRadius={ 100 }
            fill={ colors[ props.pieBg ] }
        >
        {
            data.map((entry, index) => {
                const colorKey = entry.color;
                return <Cell key={ index } fill={colors[colorKey]} />}
            ) 
        }
        </Pie>
    </PieChart>
    
)};

TinyDonutChartBig.propTypes = {
    pieBg: PropTypes.string
};
TinyDonutChartBig.defaultProps = {
    pieBg: "300"
};

export { TinyDonutChartBig };
