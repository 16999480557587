import React from 'react';
import BloodVial from './BloodVial';
import CellSlide from './CellSlide';
import PropType from 'prop-types';
import {STATUS} from '../../../common/constant';
import { getIconUrl } from '../../../common/images-catalogue';

const SpecimenCard = (props) => {
    const { eventHandler, data } = props;
    const patientName = data.patient ? `${data.patient.firstName} ${data.patient.lastName}` : 'Patient Name';
    
    const registrationNo = data.patient ? data.patient.registrationNo : '000000';
    var vialData = data.specimens ? data.specimens[0] : props.vialData;
    const identifier = data.identifier ? data.identifier : '--';
    const clientName = data.client ? data.client.type === 'Doctor' ? `Dr. ${data.client.lastName}`: data.client.officeName : '--';
    const accountNo = data.client ? data.client.accountNo : '--';
    const status = data.statuses.last().status !== STATUS.Approved;

    return(
        
        <div className="item-spc" onClick={ () => eventHandler(data) }>
            { (data.urgent && status) && 
                <span className='urgent-indicator'>URGENT</span>
            }
            <div className={`it-vial-well ${data.formType === 'Gynecology' ? '' : 'vial' }`}>
                { data.formType === 'Gynecology' ? 
                <CellSlide  
                cells={ vialData.vialColour }
                specimenLabel={ vialData.specimenLabel} />
                :
                <BloodVial 
                specimenLabel={ vialData.specimenLabel} 
                cork={ vialData.vialColour }
                bloodType={ vialData.bloodGroup }/>
            }
            </div>
            <div className="it-details">
                <div className="ct-inner">
                    <div className="doc-pt-details">
                        <div className="pt-picture">
                            <img src={getIconUrl("profile")} alt='' />
                        </div>
                        <h4>{ patientName }</h4>
                        <p>Reg.#: {registrationNo}</p>
                    </div>
                    <div className="sp-rc-details">
                        <p className="info-ln"><span>Ref</span>{identifier}</p>
                        <p className="info-ln"><span>CL</span>{clientName}</p>
                        <p className="info-ln"><span>AC#</span>{accountNo}</p>
                    </div>
                </div>
            </div>                      
        </div>
    );
}
SpecimenCard.propTypes = {
    data: PropType.object.isRequired,
    vialData: PropType.object
}
SpecimenCard.defaultProps = {
    vialData: {
        specimenLabel: 'VIAL.LABEL',
        cork: '',
        bloodGroup: ''
    }
}
export default SpecimenCard