import React from 'react';
import { 
    ThemeConsumer,
    Button
} from '../../components';
import { withPageConfig } from '../../components/Layout';
import * as urls from './../../config/urls';
//import { setupPage } from '../../components/Layout/setupPage';
import './Home.style.scss';
import { getIconUrl } from '../../common/images-catalogue';

class Home extends React.Component {

    

    constructor(props) {
        super(props);

        this.state = {
            useStyle: true,
            initialStyle: 'home',
            initialColor: 'blue',
        };
   
        this.handleProceedAuthScreen = this.handleProceedAuthScreen.bind(this);
        this.setupView(props);
    }
    componentDidMount() {
        this.setupView(this.props);
    }

    setupView(props) {
        props.pageConfig.setElementsVisibility({
            navbarHidden: true,
            workspaceBar: false
        });

        this.props.onChangeTheme({
            color: this.state.initialColor,
            style: this.state.initialStyle,
            overrideStyle: this.state.useStyle
        });
    }

    componentWillUnmount() {
        const { pageConfig } = this.props;
        pageConfig.setElementsVisibility({
            navbarHidden: false,
            workspaceBar: true
        });
    }

    handleProceedAuthScreen(){
        this.props.history.push({ pathname: urls.loginUri});
    }

    render() {
        return (
            <div className='wrapper'>
                <div className='layout-wrap'>
                    <div className='content-box'>
                        <div className='logo-img'>
                            <span className="logo-icon">
                                <img src={getIconUrl('logo')} alt=''/>
                            </span>
                        </div>
                        <h1>Welcome to CytoLabs</h1>
                        <p>Your digital lab experience start here</p>
                        <Button onClick={this.handleProceedAuthScreen} className='to-login-btn'>Proceed to Login</Button>
                    </div>
                </div>
                
            </div>
        );
    }
}

const HomeView = (props) => {
    return (
        <Home {...props } />
    );
};

const ConfigHomePage = withPageConfig(HomeView);

const HomeContextTheme = (props) => (
    <ThemeConsumer>
        {
            (themeState) => <ConfigHomePage { ...themeState } { ...props } />
        }
    </ThemeConsumer>
)



export default HomeContextTheme;