import * as urls from '../config/urls'

const routesGuardConfig : {[key: string] : object}  = {
    viewUsersGuard: { allowed:"view", entity:"user", redirectTo: urls.dashboardUri  },
    createUserGuard: { allowed:"create", entity:"user", redirectTo: urls.usersUri },
    updateUserGuard : { allowed:"change", entity:"user", redirectTo: urls.usersUri },
    viewPermissionGuard : { allowed:"view", entity:"permission", redirectTo: urls.dashboardUri },

    viewProfileGuard: { allowed:"view", entity:"user", redirectTo: urls.usersUri },

    //Client
    viewClientGuard : { allowed:"view", entity:"client", redirectTo: urls.dashboardUri },
    createClientGuard : { allowed:"create", entity:"client", redirectTo: urls.doctorsUri },
    updateClientGuard : { allowed:"change", entity:"client", redirectTo: urls.doctorsUri },

    //Specimen
    viewSpecimensGuard  : { allowed:"view", entity:"record", redirectTo: urls.dashboardUri  },
    createSpecimenGuard : { allowed:"create", entity:"record", redirectTo: urls.specimensUri },
    updateSpecimenGuard : { allowed:"change", entity:"record", redirectTo: urls.specimensUri },
    //Patient
    viewPatientGuard : { allowed:"view", entity:"patient", redirectTo: urls.dashboardUri },
    createPatientGuard : { allowed:"create", entity:"patient", redirectTo: urls.patientsUri },
    updatePatientGuard : { allowed:"change", entity:"patient", redirectTo: urls.patientsUri },

    //Account
    viewAccountGuard : { allowed:"view", entity:"account", redirectTo: urls.dashboardUri },

    //Employee
    viewEmployeeGuard : { allowed:"view", entity:"employee", redirectTo: urls.dashboardUri },
    createEmployeeGuard : { allowed:"create", entity:"employee", redirectTo: urls.employeesUri },
    updateEmployeeGuard : { allowed:"change", entity:"employee", redirectTo: urls.employeesUri },

    //Billing
    viewBillingGuard : { allowed:"view", entity:"bill", redirectTo: urls.dashboardUri },
    createBillGuard : { allowed:"create", entity:"bill", redirectTo: urls.billingUri },
    updateBillGuard : { allowed:"change", entity:"bill", redirectTo: urls.billingUri },

    //PayAdvice
    viewPayrollGuard : { allowed:"view", entity:"payroll", redirectTo: urls.dashboardUri },
    viewPayrollDetailsGuard : { allowed:"view", entity:"payroll", redirectTo: urls.payrollUri },
    viewPayslipGuard : { allowed:"view", entity:"payadvice", /*redirectTo: urls.payrollUri*/ },
    //PayAdvice
    createPayrollGuard : { allowed:"create", entity:"payroll", redirectTo: urls.dashboardUri },
    //Message
    viewMessageGuard : { allowed:"view", entity:"message", redirectTo: urls.dashboardUri },
    viewMessageThreadGuard : { allowed:"view", entity:"message", redirectTo: urls.messagesUri },

    viewSettingsGuard : { allowed:"view", entity:"permission", redirectTo: urls.dashboardUri },
    viewFormSettingGuard : { allowed:"view", entity:"clinicalitemgroup", redirectTo: urls.settingsUri },
    viewReportsGuard : { allowed:"view", entity:"report", redirectTo: urls.dashboardUri }, 
}

export default routesGuardConfig