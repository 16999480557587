import * as actionTypes from '../actionType';


export const clientsViewLoadedAction =  (clientType) => ({
    type: actionTypes.CLIENTS_VIEW_LOADED,
    payload: clientType
}); 

export const clientsViewUnloadAction =  () => ({
    type: actionTypes.CLIENTS_VIEW_UNLOAD
}); 
export const clientCreateViewLoadedAction =  () => ({
    type: actionTypes.CLIENT_CREATE_VIEW_LOADED,
}); 

export const clientCreateViewUnloadAction =  () => ({
    type: actionTypes.CLIENT_CREATE_VIEW_UNLOAD
}); 


export const getClientAccountAction =  (userId) => ({
    type: actionTypes.FETCH_CIENT_ACCOUNT,
    payload: userId
}); 

export const getAllClientsAction =  (type) => ({
    type: actionTypes.FETCH_CLIENTS,
    payload: type
}); 

export const getClientAction =  (clientId) => ({
    type: actionTypes.FETCH_CLIENT,
    payload: clientId
}); 
export const setClientAction = (client) => ({
    type: actionTypes.SET_CLIENT_DATA,
    payload: client
});

export const getUserWorkspaceRoles = (workspace) => ({
    type: actionTypes.FETCH_USER_WORKSPACE_ROLES,
    payload: workspace
});

export const clientAssignedRoleAction =  (payload) => ({
    type: actionTypes.CLIENT_ASSIGNED_ROLE,
    payload: payload
}); 

export const clientFieldChangeAction =  (field) => ({
    type: actionTypes.CLIENT_FIELD_CHANGED,
    payload: {
       ...field
    }
}); 

export const submitClientAction = (data) => ({
    type: actionTypes.CLIENT_SUBMIT_DATA,
    payload: data
}); 

export const setUpdateClientDataAction =  (fieldsData) => ({
    type: actionTypes.CLIENT_EDIT_SETDATA,
    payload: {
       ...fieldsData
    }
}); 

export const getClientOverviewDataAction = (workspace) => ({
    type: actionTypes.FETCH_CLIENT_OVERVIEW_DATA,
    payload: workspace
});
