
import 'unfetch';
import { clearInterval } from "stompjs";
import { dispatchNetworkStatusAction } from '../service/agentDialect';
class NetworkStats {   
    
    static _instance = null;
    _dispatch = null;
    _online = null;
    _since = null;
    _connected = true;
    _connectinInfo = {};
    _timeout = 6000;
    _pingTime = 5000;
    _infoMetaData = {};
    _apiUrl = '';
    _connection = null;
    _pingIntervalRef = null;
    

    constructor(dispatch, url) {
        this._online = navigator.onLine;
        this._dispatch = dispatch;
        this._apiUrl = url;
        this.onLineSignalHandler = this.onLineSignalHandler.bind(this);
        this.offLineSignalHandler = this.offLineSignalHandler.bind(this);
        this.dispatchNetworkStatus = this.dispatchNetworkStatus.bind(this);
        this.connectionChangeHandler = this.connectionChangeHandler.bind(this);
        this.ping = this.ping.bind(this);
        this.onPingSuccess = this.onPingSuccess.bind(this);
        this.onPingError = this.onPingError.bind(this);
        this.getNetworkConnection = this.getNetworkConnection.bind(this);
        this.startPingRoutine = this.startPingRoutine.bind(this);
        this.initialize();
    }

    initialize() {
        this._connection = this.getNetworkConnection();
        window.addEventListener('online', this.onLineSignalHandler);
        window.addEventListener('offline', this.offLineSignalHandler);
        this._connection?.addEventListener('change',this.connectionChangeHandler);
        this.dispatchNetworkStatus();
        this._since = this._connection ? new Date().toString() : null;
        this.startPingRoutine();
        this._pingIntervalRef = setInterval(
            this.startPingRoutine // function returns a true if an internet connection exists
        , this._pingTime);
    }

    onLineSignalHandler() {
        this._online = true;
        this._since = new Date().toString();
        this.dispatchNetworkStatus();
    }
    
    offLineSignalHandler() {
        this._online = false;
        this._since = null;
        this.dispatchNetworkStatus();
    }

    connectionChangeHandler() {
        this.dispatchNetworkStatus();
    }
    startPingRoutine() {
        this.ping(this._apiUrl)
        .then(this.onPingSuccess)
        .catch(this.onPingError);
    }
    onPingSuccess(response) {
        this._connected = response;
        this.dispatchNetworkStatus();
    }

    onPingError(error) {
        console.log(`${this._apiUrl} : `,error);
        this._connected = false;
        this.dispatchNetworkStatus();
    }

    dispatchNetworkStatus () {
        const details = {
            isOnline: this._online,
            isConnected: this._connected,
            onlineSince: this._since,
            ...this.getNetworkConnectionMetaData()
        };
        dispatchNetworkStatusAction(details);
    }

    getNetworkConnection() {
        return navigator.connection || navigator.mozConnection || navigator.webkitConnection || null;
    }

    getNetworkConnectionMetaData() {
        const connection = this.getNetworkConnection();
        if (connection) {
            this._infoMetaData = {
                rtt: connection.rtt,
                type: connection.type || this._online ? 'Ethernet | Wifi' : 'none',
                downlink: connection.downlink,
                downlinkMax: connection.downlinkMax || this._online ? 'Infinite' : 0,
                saveData: connection.saveData, //true,
                effectiveType: connection.effectiveType

            }
            return this._infoMetaData;
        }else {
            return this._infoMetaData = {};
        } 
    }

    get connectionInfo () {
        return this._infoMetaData;
    }

    get isOnline() {
        return this._online;
    }

    get isConnected() {
        return this._connected;
    }

    evict () {
        window.removeEventListener('online', this.onLineSignalHandler);
        window.removeEventListener('offline', this.offLineSignalHandler);
        this._connection?.removeEventListener('change', this.connectionChangeHandler);
        this._connection = null;
        this._infoMetaData = {};
        clearInterval(this._pingIntervalRef);
    }

    set dispatch(dispatch) {
        this._dispatch = dispatch;
    }

    ping(url) {
        return new Promise((resolve, reject) => {
            const urlRule = new RegExp('(https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]');
            if (!urlRule.test(url)) reject('invalid url');
            try {
              fetch(url)
                .then(() => resolve(true))
                .catch(() => resolve(false));
              setTimeout(() => {
                resolve(false);
              }, this._timeout);
            } catch (e) {
              reject(e);
            }
        });
    }

    static getInstance = (classInstance, ...args) => NetworkStats._instance ? NetworkStats._instance : (NetworkStats._instance = new classInstance(...args));
}


export default NetworkStats;