import  * as actions  from '../store/actions';
import { store } from '../store/store';
import * as actionTypes from '../store/actionType';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import {
  getToken,
  getApiRoot,
  getWebSocketHost,
} from '../service/agentDialect';
import { getEnvironment } from '../service/agentDialect';

function makeid(length) {
  let result = [];
  let characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result.push(
      characters.charAt(Math.floor(Math.random() * charactersLength))
    );
  }
  return result.join('');
}

let sessionId = makeid(8);
class SocketCommunicator {
  SOCKET_URL = getApiRoot() + '/ws';
  static initialize = false;
  static _instance = null;
  requestManager = null;
  static stompClient = null;
  static socket = null;
  static timerId = null;
  static _isConnected = false;
  static retryLimit = 5;
  static retryCount = 0;
  _authUser = null;

  static responseType = {
    message: 'MessageResponsePayload',
    notification: 'NotificationResponsePayload',
    default: 'MessagePayload',
  };

  options = {
    protocols_whitelist: [
      'websocket',
      'xhr-streaming',
      'xdr-streaming',
      'xhr-polling',
      'xdr-polling',
      'iframe-htmlfile',
      'iframe-eventsource',
      'iframe-xhr-polling',
    ],
    debug: false,
  };

  dispatchMessage = (message) =>
    store.dispatch(actions.receivedMessageAction(message));

  openConnection() {
    if (!this._authUser || SocketCommunicator._isConnected) return;
    let jwtToken = getToken();
    let header = {
      Authorization: `Bearer ${jwtToken}`,
    };
    
    SocketCommunicator.socket = new SockJS(
      this.SOCKET_URL /*,[], {
            sessionId: () => {
               return 'riky123'
            }
         }*/,
      this.options
    );

    SocketCommunicator.stompClient = Stomp.over(SocketCommunicator.socket);
    if (getEnvironment() === 'production') {
      SocketCommunicator.stompClient.debug = null;
    }
    SocketCommunicator.stompClient.connect(
      header,
      this.connectionSuccess,
      this.onError
    );

    clearInterval(SocketCommunicator.timerId);
    SocketCommunicator.socket.onclose = SocketCommunicator.onDisconnect;
  }

  onError(error) {
    //SocketCommunicator.socket.close();
    console.log(error);
  }

  static get connected() {
    return SocketCommunicator._isConnected;
  }
  
  static onDisconnect() {
    console.log('Websocket connection closed and handled from our app.');
    SocketCommunicator.stompClient.connected &&
      SocketCommunicator.stompClient.disconnect();
    SocketCommunicator._isConnected = false;

    if (SocketCommunicator.retryCount < SocketCommunicator.retryLimit) {
      SocketCommunicator.reconnect();
    }
    if (SocketCommunicator.retryCount === SocketCommunicator.retryLimit) {
      SocketCommunicator._instance.closeConnection();
    }
  }

  closeConnection() {
    if (
      SocketCommunicator._isConnected ||
      SocketCommunicator.retryCount === SocketCommunicator.retryLimit
    ) {
      SocketCommunicator.initialize = false;
      this.unsubscribe();
      SocketCommunicator.stompClient.disconnect();
      SocketCommunicator.socket.close();
      this._authUser = null;
      SocketCommunicator._isConnected = false;
      clearInterval(SocketCommunicator.timerId);
    }
  }

  unsubscribe() {
    for (const sub in SocketCommunicator.stompClient.subscriptions) {
      if (SocketCommunicator.stompClient.subscriptions.hasOwnProperty(sub)) {
        SocketCommunicator.stompClient.unsubscribe(sub);
      }
    }
  }

  static onMessageReceived(payload) {
    let response = JSON.parse(payload.body);
    
    const { MessageResponsePayload, ...messageObj } = response;
    if (MessageResponsePayload !== undefined) {
      store.dispatch({
        type: actionTypes.RECEIVED_MESSAGE,
        payload: MessageResponsePayload,
      });
    }

    if (
      !messageObj.hasOwnProperty(SocketCommunicator.responseType.default) &&
      messageObj.hasOwnProperty('tag')
    ) {
      store.dispatch({
        type: actionTypes.RECEIVED_MESSAGE,
        payload: messageObj,
      });
    }
  }

  static onNotificationReceived(payload) {
    let response = JSON.parse(payload.body);
    const { NotificationResponsePayload, ...notificationObj } = response;

    if (NotificationResponsePayload || notificationObj) {
      NotificationResponsePayload
        ? store.dispatch({
            type: actionTypes.RECEIVED_NOTIFICATION,
            payload: NotificationResponsePayload,
          })
        : store.dispatch({
            type: actionTypes.RECEIVED_NOTIFICATION,
            payload: notificationObj,
          });
    }
  }

  connectionSuccess(frame) {
    SocketCommunicator._isConnected = SocketCommunicator.stompClient.connected;
    if (
      SocketCommunicator.stompClient.ws.readyState === 1 &&
      SocketCommunicator.stompClient.connected
    ) {
      let url = SocketCommunicator.stompClient.ws._transport.url;
      url = url.replace(`${getWebSocketHost()}/ws/`, '');
      url = url.replace('/websocket', '');
      url = url.replace(/^[0-9]+\//, '');
      sessionId = url;
      // do stuff that requires a connection, like establish subscriptions
      SocketCommunicator.stompClient.subscribe(
        '/topic/messages',
        SocketCommunicator.onMessageReceived
      );

      SocketCommunicator.stompClient.subscribe(
        '/topic/notifications',
        SocketCommunicator.onNotificationReceived
      );

      SocketCommunicator.stompClient.subscribe(
        '/user/queue/private',
        SocketCommunicator.onMessageReceived,
        { 'auto-delete': true }
      );
      SocketCommunicator._instance.sendUser();
    }

    SocketCommunicator.retryCount = 0;
  }

  set authUser(user) {
    this._authUser = user;
  }

  get authUser() {
    return this._authUser;
  }

  sendUser() {
    SocketCommunicator.stompClient.send(
      '/app/chat.newUser',
      { Authorization: `Bearer ${getToken()}` },
      JSON.stringify({
        sender: this._authUser,
        type: 'newUser',
      })
    );
  }

  static reconnect() {
    SocketCommunicator.timerId = setInterval(() => {
      SocketCommunicator._instance.openConnection();
      SocketCommunicator.retryCount = SocketCommunicator.retryCount + 1;
    }, 10000);
  }

  sendMessage(chatMessage) {
    if (SocketCommunicator.stompClient) {
      SocketCommunicator.stompClient.send(
        '/app/chat.privateMessage',
        {
          'x-auth-user': this._authUser,
          Authorization: `Bearer ${getToken()}`,
        },
        JSON.stringify(chatMessage)
      );
    }
  }

  destroy() {
    this.closeConnection();
    SocketCommunicator._instance = null;
  }

  static getInstance = (classInstance) =>
    SocketCommunicator._instance !== null
      ? SocketCommunicator._instance
      : (SocketCommunicator._instance = new classInstance());
}

export default SocketCommunicator;
