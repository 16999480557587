import React from 'react';
import ReactDOM from 'react-dom';
import PropType from 'prop-types';
import { Container, Row, Button } from '../../../components';
import { PermissionEnforcer } from '../../../utility/PermissionEnforcer';
import { getIconUrl } from '../../../common/images-catalogue';

class ActionBar extends React.Component {
  shouldRenderButton(buttonType, showButtonPropVal, permissionRights) {
    const hasDeleteRights = permissionRights.hasDeleteRights || false;
    const hasEditRights = permissionRights.hasEditRights || false;
    const hasSaveRights = permissionRights.hasSaveRights || false;
    const hasViewRights = permissionRights.hasViewRights || false;

    switch (String(buttonType).toLowerCase()) {
      case 'delete':
        return showButtonPropVal
          ? this.props.permissionContext === 'edit' && hasDeleteRights
          : false;

      default:
        return false;
    }
  }

  render() {
    const { actionResponsers, entityContext, permissionContext, disableSave } =
      this.props;
    var saveCallback, editCallback, deleteCallback, backCallback;
    const dumpCallback = (evt) => {};

    saveCallback = actionResponsers.save ? actionResponsers.save : dumpCallback;
    editCallback = actionResponsers.edit ? actionResponsers.edit : dumpCallback;
    deleteCallback = actionResponsers.delete
      ? actionResponsers.delete
      : dumpCallback;
    backCallback = actionResponsers.back ? actionResponsers.back : dumpCallback;

    const permissionEnforcer =
      PermissionEnforcer.getInstance(PermissionEnforcer);

    const hasSaveRights = permissionEnforcer.isPermitted(
      entityContext,
      'create'
    );
    const hasEditRights = permissionEnforcer.isPermitted(
      entityContext,
      'change'
    );
    const hasDeleteRights = permissionEnforcer.isPermitted(
      entityContext,
      'delete'
    );
    const hasViewRights = permissionEnforcer.isPermitted(entityContext, 'view');

    const { title, saveTextSufix } = this.props;
    const titlePrefix =
      permissionContext === 'create' || permissionContext === 'edit'
        ? permissionContext.capitalize()
        : '';

    return (
      <React.Fragment>
        <div className='action-bar'>
          <Container>
            <Row>
              <div className='el-left'>
                <h3 className='bar-title'>
                  {titlePrefix} {title}
                </h3>
              </div>
              <div className='el-right'>
                <div className='action-group'>
                  {((this.props.saveButton && hasSaveRights) ||
                    (this.props.permissionContext === 'create' &&
                      hasSaveRights) ||
                    this.props.permissionContext === 'edit') &&
                  hasEditRights ? (
                    <Button
                      onClick={(evt) =>
                        saveCallback({ ...evt, action: 'save' })
                      }
                      className='icon-button'
                      action='save'
                      disabled={disableSave}
                    >
                      <span className='icon'>
                        <img src={getIconUrl('save')} alt='' />
                      </span>
                      Save {saveTextSufix}
                    </Button>
                  ) : null}
                  {(this.props.editButton && hasEditRights) ||
                  (this.props.permissionContext == 'view' && hasViewRights) ? (
                    <Button
                      onClick={(evt) =>
                        editCallback({ ...evt, action: 'edit' })
                      }
                      className='icon-button'
                      action='edit'
                    >
                      <span className='icon'>
                        <img src={getIconUrl('edit')} alt='' />
                      </span>
                      Edit
                    </Button>
                  ) : null}
                  {this.shouldRenderButton('delete', this.props.deleteButton, {
                    hasDeleteRights,
                  }) ? (
                    <Button
                      onClick={(evt) =>
                        deleteCallback({ ...evt, action: 'delete' })
                      }
                      className='icon-button delete'
                      action='delete'
                    >
                      <span className='icon'>
                        <img src={getIconUrl('trash')} alt='' />
                      </span>
                      Delete
                    </Button>
                  ) : null}
                  <span className='divider'></span>
                  <Button
                    onClick={(evt) => backCallback({ ...evt, action: 'back' })}
                    className='icon-button'
                    action='back'
                  >
                    <span className='icon'>
                      <img src={getIconUrl('back')} alt='' />
                    </span>
                    Back
                  </Button>
                </div>
              </div>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const propsTypes = {
  title: PropType.string.isRequired,
  saveButton: PropType.bool,
  disableSave: PropType.bool,
  editButton: PropType.bool,
  deleteButton: PropType.bool,
  backButton: PropType.bool,
  contextMode: PropType.string,
  saveTextSufix: PropType.string,
  entityContext: PropType.string,
  actionResponsers: PropType.object,
};

const defaultProps = {
  editButton: false,
  deleteButton: true,
  saveButton: false,
  title: '',
  actionResponsers: {
    save: null,
    edit: null,
    delete: null,
    back: null,
  },
};

ActionBar.propTypes = propsTypes;
ActionBar.defaultProps = defaultProps;

export default ActionBar;
