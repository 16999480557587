
import {ofType } from 'redux-observable';
import { catchError, map, switchMap, mergeMap, takeUntil } from 'rxjs/operators';
import *  as actionTypes from '../actionType';
import { departmentDialect, checkReponse } from '../../service/agent';
import { of } from 'rxjs';
import * as actions from '../actions';

export const getAllDepartmentsEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.FETCH_DEPARTMENTS),
        switchMap((action) => {
            return departmentDialect.fetchAllDepartments(action.payload).pipe(
               map(response => {
                    const responseData = checkReponse(response, action.type);
                    return  actions.apiResponseActionCreator(actionTypes.FETCHED_DEPARTMENTS_RESULTS, responseData);
                }),
               catchError(error => {
                    console.log("ERROR", error);
                    return of( actions.apiResponseActionCreator(actionTypes.FETCHED_DEPARTMENTS_RESULTS, error));
               })
            );
        })
    )
}

export const submitDepartmentEpic = action$ =>{
    return action$.pipe(
        ofType(actionTypes.DEPARTMENT_SUBMIT_DATA),
        mergeMap(action =>{
            const apiCalls = {
                'delete': departmentDialect.deleteDepartment,
                'submit': departmentDialect.submitDepartments,
            }
            return apiCalls[action.payload.requestType](action.payload).pipe(
                map(response =>{
                    const responseData = checkReponse(response, action.type, action.payload.requestType);
                    return actions.apiResponseActionCreator(actionTypes.DEPARTMENT_SUBMIT_DATA_RESULT, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
                catchError(error => {
                    console.log("ERROR", error);
                    return of(actions.apiResponseActionCreator(actionTypes.DEPARTMENT_SUBMIT_DATA_RESULT, error));
                })
            )
        })
    )
}