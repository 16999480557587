import React from 'react';

import  slideCell  from './../../../assets/svg/slide-cell';
import  slideCell1  from './../../../assets/svg/slide-cell-1';
import  slideCell2  from './../../../assets/svg/slide-cell-2';
import  slideCell3  from './../../../assets/svg/slide-cell-3';
import  slideCell4  from './../../../assets/svg/slide-cell-4';
import  slideCell5  from './../../../assets/svg/slide-cell-5';

const icons = {
    cells0: slideCell, // require('./../../../assets/images/slide-cell.svg'),
    cells1: slideCell1,
    cells2: slideCell2,
    cells3: slideCell3,
    cells4: slideCell4,
    cells5: slideCell5,
};

const getIconUrl = (name, colour) => {
    return icons[name];
}

const CellSlide = (props) => {
    const { specimenLabel, cells, labNo, clickHandler, onTypedHandler, data, index, editable } = props;

    const label = specimenLabel ? specimenLabel.split('.') : '';
   
    const willClick = (evt) => {
        clickHandler && clickHandler(evt, specimenLabel, index)
    }
    const cellNum = cells;

    return <div className='cell-slide-wrap'>
        <div className="cell-slide" onClick={willClick}>
            <div className="cell-label">
                <span className="cell-text">
                    {label[0]}<br/>
                    {label[1]}
                </span>
                <span className="cell-lab-no">
                   { labNo ? labNo : '' }
                </span>
            </div>
            <div className="cell-lyr">
                <img src={getIconUrl('cells' + cellNum)} />
            </div>
            <div className="lyr-1"></div>
            <div className="lyr-2"></div>
        </div>
    </div>
}

export default CellSlide;