import React from 'react';
import {
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Authority
} from '../../../components';

import { FEATURE_FLAG } from "../../../common/constant";
import  FEATURES  from "../../../config/featureFlags";
import { PermissionEnforcer } from './../../../utility/PermissionEnforcer';
import { clientSettingMenuItems, standardSettingMenuItems, hasVisibleItem } from './menuStructure';
import { getIconUrl } from '../../../common/images-catalogue';

const ClientSettingsDropdown = (props) => {
    const permissionEnforcer = PermissionEnforcer.getInstance(PermissionEnforcer);
    //const accountOwner = props.accountInfo &&  props.accountInfo.owner;
    const showMore = hasVisibleItem(permissionEnforcer, clientSettingMenuItems);

    const actionHandlers = {
        rolesPermission: props.willShowRolePermission,
        accountPrefs: () => {},
    };
    
    return (
        !permissionEnforcer.isPermitted('accountprefs', 'view') && !showMore ? null :
        <UncontrolledButtonDropdown >
            <DropdownToggle className="drop-down-trigger" >
                <div className="icon-button">
                    <img src={ getIconUrl('setting') } alt=''/>
                </div>
            </DropdownToggle>
            
            <DropdownMenu right >
            { clientSettingMenuItems.map((settingItemData, index) => {
                                    return (
                <Authority key={index} allowed={settingItemData.allowed} entity={settingItemData.entity}>
                    <DropdownItem key={index} onClick={actionHandlers[settingItemData.action]}>
                        <span className="icon"><img src={getIconUrl(settingItemData.iconName)} alt=''/></span>{settingItemData.navItemName}
                    </DropdownItem>
                </Authority>)
                })
            }
            </DropdownMenu>
            
        </UncontrolledButtonDropdown>
    );
}

const SettingsDropdown = (props) => {
    const showDividerAfterEntity = 'report';

    const actionHandlers = {
        labcode: () => props.willShowSettingPane('labCode'),
        service: () => props.willShowSettingPane('services'),
        role: () => props.willShowRolePermission(),
        form: () => props.willShowFormSetting(),
        setting: () => props.willShowSettings(),
        report: () => {},
    }

    return (
        <UncontrolledButtonDropdown >
            <DropdownToggle className="drop-down-trigger" >
                <div className="icon-button">
                <img src={ getIconUrl('setting') } alt=''/>
                </div>
            </DropdownToggle>
            <DropdownMenu right >
                {
                    standardSettingMenuItems.map((menuItem, index) => {
                        return (
                            <React.Fragment key={index} >
                                { menuItem.visible && (menuItem.useAuthority ?
                                    <Authority key={index} allowed={menuItem.allowed} entity={menuItem.entity}>
                                        <DropdownItem onClick={actionHandlers[menuItem.action]}>
                                            <span className="icon"><img src={getIconUrl(menuItem.iconName)} alt=''/></span>{menuItem.navItemName}
                                        </DropdownItem>
                                    </Authority>
                                    :
                                    <DropdownItem key={index} onClick={actionHandlers[menuItem.action]}>
                                        <span className="icon"><img src={getIconUrl(menuItem.iconName)} alt=''/></span>{menuItem.navItemName}
                                    </DropdownItem>)
                                }
                                {
                                    menuItem.entity === showDividerAfterEntity ? <DropdownItem key={index} divider /> : null
                                }
                            </React.Fragment>
                        )
                    })
                }
            </DropdownMenu>
        </UncontrolledButtonDropdown>
    );
}

const SettingsBlock = (props) => {
    const permissionEnforcer = PermissionEnforcer.getInstance(PermissionEnforcer); 
    return (
        <div className="settings-triggers">   

            { ( props.clientLayout || (props.clientLayout && permissionEnforcer.isSecondaryUser()) ) ? <ClientSettingsDropdown { ...props } /> : ! permissionEnforcer.isUserAuthorizer() ?<SettingsDropdown { ...props } /> : null }
            
            <UncontrolledButtonDropdown >
                    <DropdownToggle className="drop-down-trigger" >
                        <div className="icon-button">
                            <img src={ getIconUrl('power') } alt='' />
                        </div>
                    </DropdownToggle>
                    <DropdownMenu right >
                        <DropdownItem>
                            <span className="icon"><img src={getIconUrl('help')} alt='' /></span>Help Center
                        </DropdownItem>
                        <DropdownItem onClick={props.willLogout}>
                            <span className="icon"><img src={getIconUrl('logout')} alt='' /></span>Logout
                        </DropdownItem>
                    </DropdownMenu>
            </UncontrolledButtonDropdown>
        </div>
    );
};

export default SettingsBlock;