import React from 'react';
import {
    SimpleSelect,
    FormGroup,
    Input,
    Label
} from './../../../components';

const AGE_GROUP  = {
    "0-10": 'Child',
    "11-19": "Teen",
    "20-30": "Young Adults",
    "31-45": "Middle-age Adults",
    "46-100": "Old-age Adults"
};

const Filter_RP05 = (props) => {
    const {reportForm, actionType, reportFieldChange } = props;

    const GENDER = {
        Male: "Male",
        Female: "Female"
    }
    
    const didSelectLabcode = (option) => {
        const fieldSet = {
            options: {
                ...reportForm.options,
                ageGroup: option.value
            }
        }
        reportFieldChange(actionType, fieldSet);
    }

    const handlePillSwitches = (evt) => {
        const fieldSet = {
            options: {
                ...reportForm.options,
                gender: evt.target.value
            }
        }
        reportFieldChange(actionType, fieldSet);
    }

    const ageGroups = Object.keys(AGE_GROUP).map((age, i) => ({
        value: age,
        label: AGE_GROUP[age]
    }));


    const gender = reportForm.options.gender !== null ? reportForm.options.gender: null;

    const selectedAgeGroup =  !reportForm.options.ageGroup ? null : ageGroups.find((age) => age.value === reportForm.options.ageGroup);

    return <div className="opts-wrap">
        <FormGroup>
            <Label>Age Group</Label>
                <SimpleSelect
                id={'age'}
                className="ip-sml-select"
                name="age"
                title="By Age Group"
                list={ageGroups}
                deselect={true}
                onChange={didSelectLabcode}
                initialSelected={selectedAgeGroup}
                />
        </FormGroup>
        <FormGroup className="hdn">
            <Label>By Gender</Label>
            <div className="no-warp">
            <Label className="ip-radio-pill">
            <Input 
             onChange={handlePillSwitches } 
             type="radio"
             name="gender" value={GENDER.Male} 
             checked={gender && gender === GENDER.Male ? true : false}/>{' '}
                <span className="radioselect"></span>
                <span className="text">{GENDER.Male}</span>
            </Label>

            <Label className="ip-radio-pill">
            <Input onChange={handlePillSwitches } 
            type="radio" name="gender" 
            value={GENDER.Female}
            checked={gender && gender === GENDER.Female? true : false}
             />{' '}
                <span className="radioselect"></span>
                <span className="text">{GENDER.Female}</span>
            </Label>
            </div>
        </FormGroup>
    </div>
}

export default Filter_RP05;