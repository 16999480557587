import React from 'react';
import { setupPage } from '../../components/Layout/setupPage';
import ActionBar from '../components/partials/ActionBar';
import DateTimePicker from 'react-datetime-picker';
import { FormValidator } from '../../utility/FormValidator';

import {
    Row,
    Container,
    Col,
    Button,
    Section,
    Form, 
    FormGroup, 
    Label, 
    Input, 
    InputField,
    FormText,
    SimpleSelectMultiple,
    ValidateField,
    ErrorFeedback

} from '../../components';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import * as urls from '../../config/urls';
import * as helpers from '../../utility/helpers';
import * as actionTypes from '../../store/actionType';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
import patientValidatorDelegate from '../../forms/patientValidator.delegate'
import FileUploader from '../../utility/FileUploader';
import {getDateInTimeZoneString} from '../../utility/helpers';
import ConfirmModal from '../components/partials/ConfirmModal';
import {SEARCH_SETTINGS } from '../../config/searchable';
import { REQUESTS_TYPE, VALIDATOR_DELEGATE_TYPES, VALIDATOR_DELEGATE_KEY_GROUP } from '../../common/constant';
import './Patient.style.scss';
import { ErrorMessage } from '../components/partials/ErrorMessage';
import { PromptMessage,getPromptMessageProps } from '../components/partials/PromptMessage';
import text from '../../common/strings';

import { getIconUrl } from '../../common/images-catalogue';

const mapStateToProps = (state) => ({
    apiRequestManager : ApiRequestManager.getInstance(ApiRequestManager),
    workspace: state.global.workspace,
    clientDashboard: state.global.useClientDashboard,
    validator: FormValidator.getInstance(FormValidator),
    mode:  state.global.actionBarMode,
    account:  state.global.account,
    ...state.patient,
});

const mapDispatchToProps = (dispatch) => ({
    onLoad: () => dispatch(actions.patientsCreateViewLoadedAction()),
    unLoad: () => dispatch(actions.patientsCreateViewUnloadAction()),
    setMode: (mode) => dispatch(actions.actionBarModeAction(mode)),
    getAllClients: () => dispatch(actions.getAllClientsAction()),
    patientFieldDidChange: (fieldWithValue) => dispatch(actions.patientFieldChangeAction(fieldWithValue)),
    submitData : (data) => dispatch(actions.submitPatientAction(data)),
    setPatientFormData: (data) => dispatch(actions.setUpdatePatientDataAction(data)),
}); 

class CrateUpdate extends React.Component {
    
    static pageOption = {
        grayBackground: true,
        disableBodyScroll: true,
        searchContext: SEARCH_SETTINGS.entities.patient.name,
    };

    gender = {
        male: 'Male',
        female: 'Female'
    }

    constructor(props) {
        super(props);
        
        props.apiRequestManager.register(actionTypes.PATIENT_SUBMIT_DATA, props.submitData);
        props.apiRequestManager.register(actionTypes.FETCH_CLIENTS, props.getAllClients);

        this.state = {
            activeTab: '1',
            showAddressFields: false,
            formInit: false,
            date: new Date(),
            deleteDialogVisible: false,
            deleleContextItem: null,
            formErrors: {}
        };

        this.datePicker = React.createRef();
        this.profileImageRef = React.createRef();
        this.clientSelectRef = React.createRef();

        //Add event listeners
        this.handlerBack = this.handlerBack.bind(this);
        this.handleTextField = this.handleTextField.bind(this);
        this.handleAddressTextField = this.handleAddressTextField.bind(this);
        this.handlePillSwitches = this.handlePillSwitches.bind(this);
        this.submitFormData = this.submitFormData.bind(this);
        this.updateUserData = this.updateUserData.bind(this);
        this.handleDeletePatient = this.handleDeletePatient.bind(this);
        this.didSelectClientOption = this.didSelectClientOption.bind(this);
        this.addAddressFields = this.addAddressFields.bind(this);
        this.dateChange = this.dateChange.bind(this);
        this.previewImage = this.previewImage.bind(this);
        this.submitDeletePatient = this.submitDeletePatient.bind(this);
        this.showHideDeleteDialog = this.showHideDeleteDialog.bind(this);
        this.onConfirmDelete = this.onConfirmDelete.bind(this);
       
        this.getPromptMessage = this.getPromptMessage.bind(this);
    }

    shouldComponentUpdate(nextProp, nextState) {

        if (nextProp.currentPatient && !nextState.formInit ) {
            this.setState({formInit: true}, () => {
                const formData = this.composeFormData(nextProp.currentPatient);
                this.props.patientFieldDidChange(formData);
            });
        }

        return true;
    }

    static getDerivedStateFromProps(props, state) {

        if (props.apiRequestManager.inProgress(actionTypes.PATIENT_SUBMIT_DATA) && state.formInit) {
            return {
                formInit: false
            }
        }

        return null;
    }

    componentDidMount() {
     
        const mode = this.props.currentPatient !== undefined ? 'edit' : 'create';
        this.props.setMode(mode); 
        this.props.onLoad();

        if (mode === 'edit' && this.props.currentPatient) { 
            const formData = this.composeFormData(this.props.currentPatient);
            this.props.patientFieldDidChange(formData);
        }
        this.setFormDefaults();

        this.props.validator.addDelegate(VALIDATOR_DELEGATE_TYPES.patient, patientValidatorDelegate);

    }
    
    componentWillUnmount() {
        this.props.apiRequestManager.terminateAll();
        this.props.viewInit && this.props.unLoad();
        this.props.viewInit && this.props.setMode('');
    }

    setFormDefaults() {
        !this.props.clientDashboard && this.props.apiRequestManager.queueRequest(actionTypes.FETCH_CLIENTS);
        !this.props.currentPatient && this.generateRegistrationNo();

        const fieldset = {
            'gender' : this.gender.male,
            [VALIDATOR_DELEGATE_KEY_GROUP.formFields.dateOfBirth] : this.state.date
        };
        
        if (this.props.account) {
            fieldset['selectedClients'] = [{id:  this.props.account.clientId}]
        }

        this.props.patientFieldDidChange(fieldset);
        (this.props.currentPatient && this.props.currentPatient.address) && this.addAddressFields();
    }

    handleTextField  (event) {
        let finalFieldValue;

        if (event.target.name === 'age') {
            const value = parseInt(event.target.value) > 0 ? parseInt(event.target.value) : 0;  
            const age = !event.target.value.empty() ?  value : 0;
            const birthMonth = this.props.patientForm.dateOfBirth.getMonth();
            const birthDay = this.props.patientForm.dateOfBirth.getDate();

            const birthYear = helpers.calculateYearOfBirth(age, birthDay, birthMonth);
            const dateOfBirth = new Date(birthYear, birthMonth, birthDay);

            const MAX_AGE_LIMIT = 1000;
            finalFieldValue = Math.min(age, MAX_AGE_LIMIT);
            this.props.patientFieldDidChange( { 'age' : finalFieldValue});
            this.props.patientFieldDidChange( { [VALIDATOR_DELEGATE_KEY_GROUP.formFields.dateOfBirth] : dateOfBirth});
            
        } else if(event.target.name === VALIDATOR_DELEGATE_KEY_GROUP.formFields.phoneNumber){
            const phoneNumber = event.target.value;
            finalFieldValue = helpers.formatPhoneNumber(phoneNumber);
            const formatted = {
                [event.target.name] : finalFieldValue
            }
            this.props.patientFieldDidChange(formatted);
        } else {
            finalFieldValue = event.target.value;
            const fieldSet = {
                [event.target.name] : finalFieldValue
            };
            this.props.patientFieldDidChange(fieldSet);
        }

        this.setState({
            formErrors: {
                ...this.state.formErrors,
                ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.patient,event.target.name,finalFieldValue),
            }
        });
    }
    handleAddressTextField(event) {
        const fieldSet = {
            address: {
                ...this.props.patientForm.address,
                [event.target.name] : event.target.value 
            }
          };
  
         this.props.patientFieldDidChange(fieldSet);
    }
    handlePillSwitches  (event) {
        const fieldSet = {
            'gender' : event.target.value 
        };
        this.props.patientFieldDidChange(fieldSet);
    }
    
    generateRegistrationNo() {
        const fieldSet = { 'registrationNo' : helpers.generateDigits(8) };
        this.props.patientFieldDidChange(fieldSet);
    }

    submitFormData(evt) {

        const isUpdate = this.props.mode === 'edit' ? true : false;
        const uploader = FileUploader.getInstance(FileUploader);
        let {profileImage, dateOfBirth, selectedClients, ...otherFields} = this.props.patientForm;
        
        let clientsIds = [];
        if (isUpdate) {
            clientsIds = this.props.clientDashboard ? [...this.props.currentPatient.clients.map((client) => client.id)
            ] : selectedClients.map((client) => client.value);
        } else {
            clientsIds = this.props.clientDashboard ? [this.props.account.clientId] : selectedClients !== null ? selectedClients.map((client) => client.value) : [];
        }

        otherFields.clients = clientsIds;
        let formData = helpers.objToFormData(otherFields);
        formData.append(VALIDATOR_DELEGATE_KEY_GROUP.formFields.dateOfBirth, getDateInTimeZoneString({date:dateOfBirth}));
        formData.append('workspace', this.props.workspace ? this.props.workspace.id : '');
        
        if (profileImage) {
            const fileName =  uploader.generateFileName(profileImage);
            formData.append("profileImage", profileImage, fileName);
        }

        let formPayload = {
            requestType: isUpdate ? REQUESTS_TYPE.UPDATE : REQUESTS_TYPE.CREATE ,
            form: formData
        }
      
        if (isUpdate) {
            formPayload['patientId'] = this.props.currentPatient !== undefined ? this.props.currentPatient.id : null;
        }
        
        let formErrors = this.validateFields(formPayload.form);

        if (!this.props.validator.hasActiveFormError(formErrors) ) {
            this.setState({
                formInit: false
            });

            if (evt.action === 'save'){
                this.props.apiRequestManager.queueRequest(actionTypes.PATIENT_SUBMIT_DATA, formPayload);
            }
            
        }else {
            this.setState({
                formErrors: formErrors
            });
        }
    }

    validateFields(formData) {
        const formFields = VALIDATOR_DELEGATE_KEY_GROUP.formFields;

        let validations = {
            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.patient,formFields.email,formData.get(formFields.email)),

            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.patient,formFields.client,formData.get('clients')),

            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.patient,formFields.firstName,formData.get(formFields.firstName)),

            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.patient,formFields.lastName,formData.get(formFields.lastName)),

            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.patient,formFields.phoneNumber,formData.get(formFields.phoneNumber)),

            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.patient,formFields.middleName,formData.get(formFields.middleName)),

            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.patient,formFields.motherMaidenName,formData.get(formFields.motherMaidenName)),
        };
        
        if (formData.get('age') < 0) {
            validations.age = true;
        }

        return  validations;  
    }


    submitDeletePatient(patient) {
        const formPayload  = {
            requestType: REQUESTS_TYPE.DELETE,
            workspaceId: this.props.workspace.id,
            patientId: patient.id
        };

        if (formPayload.patientId) {
            this.props.apiRequestManager.queueRequest(actionTypes.PATIENT_SUBMIT_DATA, formPayload);
        }
    }

    updateUserData(evt) {
        this.submitFormData(evt);
    }

    handlerBack(evt) {
        this.props.history.push(urls.patientsUri);
    }

    dateChange(date) {
        const age = helpers.calculateAge(date);
        this.props.patientFieldDidChange( { 'age' : age});
        this.props.patientFieldDidChange( { [VALIDATOR_DELEGATE_KEY_GROUP.formFields.dateOfBirth] : date});
    }

    didSelectClientOption(clients) {
        this.props.patientFieldDidChange( { 'selectedClients' : clients });

        this.setState({
            formErrors: {
                ...this.state.formErrors,
                ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.patient,VALIDATOR_DELEGATE_KEY_GROUP.formFields.client,clients)
            }
        });
    }

    addAddressFields(evt) {
        this.setState({showAddressFields: true});
    }

    composeFormData(patient) {
        
        const formData =  {
            registrationNo: patient.registrationNo,
            firstName: patient.firstName,
            lastName: patient.lastName,
            middleName: patient.middleName,
            motherMaidenName: patient.motherMaidenName,
            email: patient.email,
            phoneNumber: patient.phoneNumber,
            gender: patient.gender,
            dateOfBirth: new Date(patient.dateOfBirth),
            age: patient.age ? patient.age : 0,
            height: patient.height ? patient.height : 0.0,
            weight: patient.weight ? patient.weight : 0.0,
            address: patient.address ? patient.address : {},
            selectedClients: patient.clients

        }
        return formData
    }

    composeSelectOption(client) {
        return {
            ...client,
            name: `${client.firstName} ${client.lastName}`,
            value: client.clientInfo ? client.clientInfo.id :  client.id,
            label: `${client.firstName} ${client.lastName} - ${client.email}`
        }
    }

    previewImage(event, imageRef) {
        let reader = new FileReader();
        let image = imageRef ? imageRef : this.profileImageRef;

        reader.onload = function() {
            let output = image.current; //document.getElementById('imagePreview');
            output.src = reader.result;
        }
        reader.readAsDataURL(event.target.files[0]);
        const file = event.target.files[0];
        
        const fieldSet = { 'profileImage' : file };
        this.props.patientFieldDidChange(fieldSet); 
    }

    handleDeletePatient() {
        const contextItem = {
            data: this.props.currentPatient
        }
        this.showHideDeleteDialog(contextItem);
    }

    onConfirmDelete(contextItem) {
        this.submitDeletePatient(contextItem.data);
        this.showHideDeleteDialog();
    }

    showHideDeleteDialog(contextItem) {
        this.setState({
            deleteDialogVisible: !this.state.deleteDialogVisible,
            deleleContextItem: contextItem || null
        });
    }

    getPromptMessage(){
        const apiOutcome = this.props.apiRequestManager.getApiOutcome(actionTypes.PATIENT_SUBMIT_DATA, true) ?? {};
        return getPromptMessageProps('patient', apiOutcome);
    }

    render() {
        
        const actionBarCallback = {
            'save': this.submitFormData,
            'back': this.handlerBack,
            'delete': this.handleDeletePatient
        };
        const defaultGender = this.props.patientForm.gender === '' ? true : this.props.patientForm.gender === this.gender.male ? true: false;

        //Updating context values
        const  actionBarMode = this.props.mode;
        const  saveSufixText = actionBarMode === 'edit'? '' : 'Patient';
        
        const clientList = !Object.blank(this.props.clientsData) ? !Array.isArray(this.props.clientsData.clients) ? [] : this.props.clientsData.clients.map((client, i) => {
            return this.composeSelectOption(client);
        }) : [];

        const initialSelectedClient =  this.props.patientForm.selectedClients && this.props.patientForm.selectedClients.map((client, i) => {
            return this.composeSelectOption(client);
        });

        const signatureSrc = this.props.patientForm.profileImage ? URL.createObjectURL(this.props.patientForm.profileImage) :  this.props.currentPatient && this.props.currentPatient.picture ? this.props.currentPatient.picture.fileDownloadUri : getIconUrl('profile');
        const dialogProps = this.getPromptMessage();

        const formFields = VALIDATOR_DELEGATE_KEY_GROUP.formFields;
       
        return (
            <React.Fragment>
                <ActionBar title={ "Patient" } saveTextSufix={ saveSufixText } entityContext={'patient'} actionResponsers={ actionBarCallback } permissionContext={ actionBarMode } deleteButton={true} />
              
                <div className="content-layout-wrap">
                    { dialogProps.visible && 
                        <PromptMessage {...dialogProps} />
                    } 
                    <div className="view-layout-content">
                    <div className="default-Ly-ct">
                <Container >
                    <Row>
                    <Col></Col>
                    <Col sm="12" md={7}>
                        <div className="content-section patient">
                            <Row>
                                <Col sm="12">
                                    <Form onSubmit={(event) => event.preventDefault()}>
                                        <Section className="patient">
                                            <div className="section-head simple">
                                                <h3>Patient Info</h3>
                                            </div>
                                        </Section>
                                        <Section className="patient-info">
                                            <div className="section-body">
                                                <FormGroup className="section-inner">
                                                    <div className="image-thumbnail-box">
                                                        <div className="image-selector">
                                                            <img ref={this.profileImageRef} src={signatureSrc} alt=""/> 
                                                            <Button><span>Edit</span></Button>
                                                            <div className="input-field">
                                                                <Input onChange={this.previewImage} type="file" name="profile-image" accept="image/*" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="gender-group">
                                                    <Label className="ip-radio-pill">
                                                        <Input onChange={this.handlePillSwitches } type="radio" name="gender" value={this.gender.male } checked={ defaultGender } />{' '}
                                                        <span className="radioselect"></span>
                                                        <span className="text">Male</span>
                                                    </Label>
                                                    <Label className="ip-radio-pill">
                                                    <Input onChange={this.handlePillSwitches } type="radio" name="gender"value={this.gender.female }
                                                     checked={this.props.patientForm.gender === this.gender.female? true: false}/>{' '}
                                                        <span className="radioselect"></span>
                                                        <span className="text">Female</span>
                                                    </Label>
                                                </FormGroup>
                                                { !this.props.clientDashboard  && 

                                                <FormGroup className="section-inner">
                                                      
                                                    <ValidateField>
                                                    <SimpleSelectMultiple
                                                        id={'client'}
                                                        ref={this.clientSelectRef}
                                                        displayField={'name'}
                                                        className="ip-select-field"
                                                        name="client"
                                                        title="Choose client"
                                                        list={clientList}
                                                        titleSingular={'Client'}
                                                        titlePlurals={'Clients'}
                                                        onChange={this.didSelectClientOption }
                                                        searchable={["Type Name or Email", "No matching client"]}
                                                        filterKeys={['name',
                                                                     'email']}

                                                        select={initialSelectedClient}
                                                        />
                                                         {/* <ErrorFeedback 
                                                            error
                                                            show={this.state.formErrors.clientMissing}
                                                            filter='valueMissing'
                                                            message={<ErrorMessage message={'Please choose a client'} /> }
                                                        /> */}
                                                    </ValidateField>
                                                </FormGroup>

                                                }
                                                <FormGroup className="section-inner username-field">
                                                    <InputField id="username" type="text" className="ip-text-default"  name={"username"} placeholder="Registration No." disabled/>
                                                    <span><i>Genterated </i> { this.props.patientForm.registrationNo }</span>
                                                </FormGroup>

                                                <FormGroup className="section-inner">
                                                    <Label>First Name</Label>
                                                    <ValidateField required>

                                                    <InputField onKeyUp={this.composeUserName} onChange={ this.handleTextField } id={formFields.firstName} type="text" name={formFields.firstName}className="ip-text-default" placeholder="John" value={this.props.patientForm.firstName } />

                                                    <ErrorFeedback 
                                                        show={this.state.formErrors.firstNameMissing}
                                                        warning
                                                        filter='valueMissing'
                                                        message={<ErrorMessage message ='First name is required' />} />
                                                       
                                                     <ErrorFeedback 
                                                        error
                                                        show={this.state.formErrors.firstNameInvalid}
                                                        filter='letterInput'
                                                        message={<ErrorMessage message='Name should contain only letters'/>} />
                                                    </ValidateField>
                                                </FormGroup>

                                                <FormGroup className="section-inner">
                                                    <Label>Last Name</Label>
                                                    <ValidateField required>
                                                        <InputField onKeyUp={this.composeUserName} onChange={ this.handleTextField } id={formFields.lastName} type="text" name={formFields.lastName}className="ip-text-default" placeholder="Brown" value={this.props.patientForm.lastName } />
                                                    
                                                      <ErrorFeedback 
                                                        show={this.state.formErrors.lastNameMissing}
                                                        warning
                                                        filter='valueMissing'
                                                        message={<ErrorMessage message ='Last name is required' />} />

                                                      <ErrorFeedback 
                                                        error
                                                        show={this.state.formErrors.lastNameInvalid}
                                                        filter='letterInput'
                                                        message={<ErrorMessage message ='Name should contain only letters' />} />
                                                    </ValidateField>
                                                </FormGroup>
                                                
                                                <FormGroup className="section-inner">
                                                    <Label>Middlename</Label>
                                                    <ValidateField>
                                                    <InputField onKeyUp={this.composeUserName} onChange={ this.handleTextField } id={formFields.middleName} type="text" name={formFields.middleName}className="ip-text-default" placeholder="John" value={this.props.patientForm.middleName } />
                                                    
                                                    <ErrorFeedback 
                                                        show={this.state.formErrors.middleNameInvalid}
                                                        error
                                                        filter='letterInput'
                                                        message={<ErrorMessage message ='Name should contain only letters' />} />
                                                    </ValidateField>
                                                </FormGroup>

                                                <FormGroup className="section-inner">

                                                    <Label>Mother's Name</Label>
                                                    <ValidateField>
                                                    <InputField onKeyUp={this.composeUserName} onChange={ this.handleTextField } id={formFields.motherMaidenName} type="text" name={formFields.motherMaidenName}className="ip-text-default" placeholder="Mother's Name" value={this.props.patientForm.motherMaidenName } />
                                                    <FormText>Mother's name before marriage (Maiden Name).</FormText>
                                                     
                                                    <ErrorFeedback 
                                                        show={this.state.formErrors.motherMaidenNameInvalid}
                                                        error
                                                        filter='letterInput'
                                                        message={<ErrorMessage message ='Name should contain only letters' />} />
                                                    </ValidateField>
                                                </FormGroup>
                                                
                                                <FormGroup className="section-inner">
                                                    <Label>Email</Label>
                                                    <ValidateField>
                                                    <InputField onChange={ this.handleTextField } id={formFields.email} type="text" name={formFields.email}className="ip-text-default" placeholder="example@mail.com"
                                                    value={ this.props.patientForm.email } />
                                                       
                                                       <ErrorFeedback 
                                                        show={this.state.formErrors.emailMissing}
                                                        warning
                                                        filter='valueMissing'
                                                        message={<ErrorMessage message ='Email is required' />} />
                                                    
                                                    <ErrorFeedback 
                                                        show={this.state.formErrors.emailInvalid}
                                                        error
                                                        filter='emailMismatch'
                                                        message={<ErrorMessage message ='Not a valid email address' />} />
                                                    </ValidateField>
                                                </FormGroup>

                                                <FormGroup className="section-inner">
                                                    <Label>Phone Number</Label>
                                                    <ValidateField>
                                                    <InputField onChange={ this.handleTextField } id={formFields.phoneNumber} type="text" name={formFields.phoneNumber} className="ip-text-default" placeholder="888-888-8888" value={this.props.patientForm.phoneNumber } />

                                                    <ErrorFeedback 
                                                            error
                                                            show={this.state.formErrors.phoneNumberMissing}
                                                            filter='valueMissing'
                                                            message={<ErrorMessage message ='Please add a Phone number' />}
                                                        />

                                                    <ErrorFeedback 
                                                            error
                                                            show={this.state.formErrors.phoneNumberInvalid}
                                                            filter='phoneNumber'
                                                            message={<ErrorMessage message ='Phone number
                                                            not valid' />}
                                                        />
                                                    </ValidateField>
                                                </FormGroup>
                                            </div>
                                        </Section>
                                        <Section className="attr-info">
                                            <div className="section-head">
                                                <h3>Attributes</h3>
                                            </div>
                                            <div className="section-body">
                                                
                                                <FormGroup className="section-inner">
                                                    <Label>Date of Birth</Label>
                                                    <div className="ip-date date-field"> 
                                                    <DateTimePicker 
                                                    ref={this.datePicker}
                                                    maxDate={this.state.date}
                                                    className="date-picker"
                                                    clearIcon={null}
                                                    disableClock
                                                    time={false}
                                                    format={"dd-MM-yyyy"}
                                                    onChange={this.dateChange}
                                                    value={this.props.patientForm.dateOfBirth} />
                                                    </div>
                                                    
                                                </FormGroup>
                                                <FormGroup className="section-inner">
                                                    <Label>Age</Label>
                                                    <InputField  onChange={ this.handleTextField } id="age" type="age" name={"age"}className="ip-text-default" placeholder="29"
                                                    value={this.props.patientForm.age }/>
                                                    
                                                </FormGroup>
                                                { /*
                                                <FormGroup className="section-inner">
                                                    <Label>Height</Label>
                                                    <InputField  onChange={ this.handleTextField } id="height" type="number" name={"height"}className="ip-text-default" placeholder="123.3"
                                                    value={this.props.patientForm.height }/>
                                                    
                                                </FormGroup>
                                                <FormGroup className="section-inner">
                                                    <Label>Weight</Label>
                                                    <InputField  onChange={ this.handleTextField } id="weight" type="number" name={"weight"}className="ip-text-default" placeholder="150"
                                                    value={this.props.patientForm.weight }/>
                                                    
                                                </FormGroup>
                                                */}
                                            </div>
                                        </Section>
                                        <Section className="address-info">
                                            <div className="section-head">
                                             <h3>Address</h3>
                                            </div>
                                            { !this.state.showAddressFields ?
                                            <div className="add-patient-holder">
                                                <Button className="add-address-btn" onClick={this.addAddressFields}>
                                                    <span className="icon">
                                                        <img src={getIconUrl("plusIcon") }alt=""/>
                                                    </span>
                                                    Add Address
                                                </Button>
                                            </div>
                                            : 
                                            <div className="section-body">
                                                <FormGroup className="section-inner">
                                                    <Label>Street</Label>
                                                    <InputField  onChange={ this.handleAddressTextField } 
                                                    id="street" 
                                                    type="text" name={"street"}
                                                    className="ip-text-default" placeholder="Address Line 1"
                                                    value={this.props.patientForm.address.street }/>
                                            
                                                </FormGroup>
                                                <FormGroup className="section-inner">
                                                    <Label>Address Line 2</Label>
                                                    <InputField  
                                                    onChange={ this.handleAddressTextField } 
                                                    id="streetTwo" 
                                                    type="text" name={"streetTwo"}
                                                    className="ip-text-default" placeholder="Address Line 2"
                                                    value={this.props.patientForm.address.streetTwo }/>
                                            
                                                </FormGroup>
                                                <FormGroup className="section-inner">
                                                    <Label>City</Label>
                                                    <InputField  
                                                    onChange={ this.handleAddressTextField } 
                                                    id="city" 
                                                    type="text" name={"city"}
                                                    className="ip-text-default" placeholder="City"
                                                    value={this.props.patientForm.address.city }/>
                                            
                                                </FormGroup>
                                                <FormGroup className="section-inner">
                                                    <Label>Parish | State</Label>
                                                    <InputField  
                                                    onChange={ this.handleAddressTextField } 
                                                    id="state" 
                                                    type="text" name={"state"}
                                                    className="ip-text-default" placeholder="Parish"
                                                    value={this.props.patientForm.address.state }/>
                                            
                                                </FormGroup>
                                            </div>
                                        }
                                        </Section>
                                    </Form>
                                    </Col>
                                </Row>    
                        </div>
                    </Col>
                    <Col></Col>
                    
                    </Row>
                </Container>
                </div>
                </div>
                </div>
                { this.state.deleteDialogVisible && 
                    <ConfirmModal 
                        contextName={'patient'}
                        contextItem={this.state.deleleContextItem} 
                        cancelCallback={this.showHideDeleteDialog}
                        confirmCallback={this.onConfirmDelete}
                    />
                }
            </React.Fragment>
        );
    }
}

const CrateUpdateView = (props) => {
    return (
        <CrateUpdate {...props } />
    );
};

const ConfigCrateUpdateUserView = setupPage( CrateUpdate.pageOption )(CrateUpdateView);

export default connect(mapStateToProps, mapDispatchToProps)(ConfigCrateUpdateUserView);