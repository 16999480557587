import React from 'react';

export default function ReportPage({children, ReportHeader, ReportFooter, currentPageNum, maxPages}) {
  return (
    <div className="print-template">
      {ReportHeader}
      <div className="report-body">
        {children}
      </div>
      {ReportFooter}
      <span className="footer__page-num">
        {`Page ${currentPageNum} of ${maxPages}`}
      </span>
    </div>
  )
}
