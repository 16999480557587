import { requests, Auth } from './agentDialect';

export const login = (username, password) => (
    Auth.login(`/authenticate`, { username: username, password: password }) 
    );

export const currentUser = () => requests.get(`/authenticate/user`);

export const fetchAuthUser = (username) => {
    return Auth.getAuthUser(`/authenticate/username/?credential=${username}`) 
};

export const refreshJwtToken = () => {
    return Auth.refreshToken(`/token/refresh`)
};