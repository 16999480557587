import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from './../pages/Home';
import Login from './../pages/Login';
import Dashbaord from './../pages/Dashboard';
import Profile from './../pages/Profile';
import PermissionRole from './../pages/Permission';
import Client, { ClientOverview, CreateUpdateClient } from './../pages/Client';
import Patient, { PatientOverview, CreateUpdatePatient }  from "../pages/Patient";
import Specimen, { CreateUpdateSpecimen } from './../pages/Specimen';
import Account from './../pages/Account';
import Billing, { CreateUpdateBill, BillView } from './../pages/Billing';
import Employee, { CreateUpdateEmployee } from './../pages/Employee';
import Payroll, {
  PayrollWizard,
  PayrollDetails,
  Payslip,
} from './../pages/Payroll';
import Payment from './../pages/Payment';
import Message, { MessageThread } from './../pages/Message';
import Settings, { FormSetting } from './../pages/Settings';
import { Users, CreateUpdateUser } from '../pages/Users';
import Reports from '../pages/Reports';

import * as urls from '../config/urls';
import ROUTE_CONFIG from './routeGuard.config';
import { RouteGuard } from '../components';

export const RoutedContent = () => {

  return(
      <Switch>
          {/*   Pages Routes   */}
          <Route exact component={ Home } path="/" />
          <Route exact component={ Login } path="/auth/login" />
          <Route exact component={ Dashbaord } path={urls.dashboardUri} />
          
          {/*------ Users Routes ------*/}
          <Route exact path={ urls.usersUri } component={ RouteGuard(Users, ROUTE_CONFIG.viewUsersGuard) } />
          <Route exact path={ urls.createUserUri } component={ RouteGuard(CreateUpdateUser, ROUTE_CONFIG.createUserGuard) } />
          <Route exact path={ urls.updateUserUri } component={ RouteGuard(CreateUpdateUser, ROUTE_CONFIG.updateUserGuard) } />

          <Route exact path={ urls.profileUri } component={ Profile } />
          {/*------ End ------*/}
          <Route exact path={ urls.permissionUri } component={ RouteGuard(PermissionRole, ROUTE_CONFIG.viewPermissionGuard) } />

          {/*------ Client ------*/}
          <Route exact path={ urls.labsUri } component={ RouteGuard(Client, ROUTE_CONFIG.viewClientGuard) } />
          <Route exact path={ urls.doctorsUri } component={ RouteGuard(Client, ROUTE_CONFIG.viewClientGuard) } />
          <Route exact path={ urls.clientOverviewUri } component={ RouteGuard(ClientOverview, ROUTE_CONFIG.viewClientGuard) } />
          <Route exact path={ urls.clientIdOverviewUri } component={ RouteGuard(ClientOverview, ROUTE_CONFIG.viewClientGuard) } />
          <Route exact path={ urls.clientCreateUri } component={ RouteGuard(CreateUpdateClient, ROUTE_CONFIG.createClientGuard) } />
          <Route exact path={ urls.clientUpdateUri } component={ RouteGuard(CreateUpdateClient, ROUTE_CONFIG.updateClientGuard) } />

          {/*------ Specimen ------*/}
          <Route exact path={ urls.specimensUri } component={ RouteGuard(Specimen, ROUTE_CONFIG.viewSpecimensGuard) } />
          <Route exact path={ urls.specimensCreateUri } component={ RouteGuard(CreateUpdateSpecimen, ROUTE_CONFIG.createSpecimenGuard) } />
          <Route exact path={ urls.specimensUpdateUri } component={ RouteGuard(CreateUpdateSpecimen, ROUTE_CONFIG.updateSpecimenGuard) } />

          {/*------ Patient ------*/}
          <Route exact path={ urls.patientsUri } component={ RouteGuard(Patient, ROUTE_CONFIG.viewPatientGuard) } />
          <Route exact path={ urls.patientOverviewUri } component={ RouteGuard(PatientOverview, ROUTE_CONFIG.viewPatientGuard) } />
          <Route exact path={ urls.patientIdOverviewUri } component={ RouteGuard(PatientOverview, ROUTE_CONFIG.viewPatientGuard) } />
          <Route exact path={ urls.patientCreateUri } component={ RouteGuard(CreateUpdatePatient, ROUTE_CONFIG.createPatientGuard) } />
          <Route exact path={ urls.patientUpdateUri } component={ RouteGuard(CreateUpdatePatient, ROUTE_CONFIG.updatePatientGuard) } />

          {/*------ Account ------*/}
          <Route exact path={ urls.accountsUri } component={ RouteGuard(Account, ROUTE_CONFIG.viewAccountGuard) } />

          {/*------ Employee ------*/}
          <Route exact path={ urls.employeesUri } component={ RouteGuard(Employee, ROUTE_CONFIG.viewEmployeeGuard) } />
          <Route exact path={ urls.employeeCreateUri } component={ RouteGuard(CreateUpdateEmployee, ROUTE_CONFIG.createEmployeeGuard) } />
          <Route exact path={ urls.employeeUpdateUri } component={ RouteGuard(CreateUpdateEmployee, ROUTE_CONFIG.updateEmployeeGuard) } />

          {/*------ Billing ------*/}
          <Route exact path={ urls.billingUri } component={ RouteGuard(Billing, ROUTE_CONFIG.viewBillingGuard) } />
          <Route exact path={ urls.billViewUri } component={ RouteGuard(BillView, ROUTE_CONFIG.viewBillingGuard) } />
          <Route exact path={ urls.createBillUri } component={ RouteGuard(CreateUpdateBill, ROUTE_CONFIG.createBillGuard) } />
          <Route exact path={ urls.updateBillUri } component={ RouteGuard(CreateUpdateBill, ROUTE_CONFIG.updateBillGuard) } />

          {/*------ Payroll ------*/}
          <Route exact path={ urls.payrollUri } component={ RouteGuard(Payroll, ROUTE_CONFIG.viewPayrollGuard) } />
          <Route exact path={ urls.payrollDetailsUri } component={ RouteGuard(PayrollDetails, ROUTE_CONFIG.viewPayrollDetailsGuard) } />
          <Route exact path={ urls.payrollWizardUri } component={ RouteGuard(PayrollWizard, ROUTE_CONFIG.createPayrollGuard) } />
          <Route exact path={ urls.payrollPayslipUri } component={ RouteGuard(Payslip, ROUTE_CONFIG.viewPayslipGuard) } />
          <Route exact path={ urls.payrollPayslipSingleUri } component={ RouteGuard(Payslip, ROUTE_CONFIG.viewPayslipGuard) } />

          {/*------ Payment ------*/}
          <Route exact path={ urls.paymentsUri } component={ RouteGuard(Payment, ROUTE_CONFIG.viewPatientGuard) } />

          {/*------ Message ------*/}
          <Route exact path={ urls.messagesUri } component={ RouteGuard(Message, ROUTE_CONFIG.viewMessageGuard) } />
          <Route exact path={ urls.messagesThreadUri } component={ RouteGuard(MessageThread, ROUTE_CONFIG.viewMessageThreadGuard) } />

          {/*------ Settings ------*/}
          <Route exact path={ urls.settingsUri } component={ RouteGuard(Settings, ROUTE_CONFIG.viewSettingsGuard) } />
          <Route exact path={ urls.settingsPaneUri } component={ RouteGuard(Settings, ROUTE_CONFIG.viewSettingsGuard) } />
          <Route exact path={ urls.formSettingUri } component={ RouteGuard(FormSetting, ROUTE_CONFIG.viewFormSettingGuard) } />

          {/*------ Reports ------*/}
          <Route exact path={ urls.reportsUri } component={ RouteGuard(Reports, ROUTE_CONFIG.viewReportsGuard) } />

      </Switch>
  )
}
