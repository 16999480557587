import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export default class SimpleBarChart extends PureComponent {

  render() {
    const data = this.props.data;

    var dataArray = [];
    if ( !Object.blank(data) ) {
        var leadData = null;

        if (Object.keys(data.gynecologyData).length > Object.keys(data.nonGynecologyData).length) {
            leadData = data.gynecologyData;
        }else {
            leadData = data.nonGynecologyData;
        }

        dataArray = Object.keys(leadData).map((key) => {

            const item = {
                name: key,
                 ngyne: data.nonGynecologyData.hasOwnProperty(key)? data.nonGynecologyData[key] : 0 , 
                 gyne: data.gynecologyData.hasOwnProperty(key)? data.gynecologyData[key] : 0, 
                 amt: data.gynecologyData.hasOwnProperty(key) && data.nonGynecologyData.hasOwnProperty(key)? data.gynecologyData[key] > data.nonGynecologyData[key]?   data.gynecologyData[key] : data.nonGynecologyData[key] : 0 
            }
            return item;
        });
    }
    const height = 160;
    return (
      <ResponsiveContainer width="85%" height={height || 250}>
        <BarChart
          width={400}
          height={160}
          data={dataArray}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" stroke="#808080" tick={{ fill: '#808080' }}/>
           <YAxis stroke="#808080" tick={{ fill: '#808080' }} />
          <XAxis dataKey="name" stroke="#eee"/>
          <YAxis />
          <Tooltip cursor={{fill: '#ebebec94'}}  />
          <Legend layout="horizontal" verticalAlign="top" align="center" />
          <Bar dataKey="gyne" stackId="a" fill="#64c154" barSize={40}/>
          <Bar dataKey="ngyne" stackId="a" fill="#40ceca"  barSize={40} />
        </BarChart>
        </ResponsiveContainer>
    );
  }
}
