import React from 'react';
import PropTypes from 'prop-types';
import { map, isInteger } from 'lodash';
import { Pagination, PaginationItem, PaginationLink, Col } from '..';

const mapToFa = {
    '<': <i className="line-arrow square left" />,
    //'<<': <i className="fa fa-angle-double-left" />,
    '>': <i className="line-arrow square right" />,
    //'>>': <i className="fa fa-angle-double-right" />
}

export const PaginationPanel = ({ onPageChange, pages, ...otherProps }) => (
    <Col md={ 6 } className="d-flex page-controls">
        <Pagination aria-label="Page navigation example" { ...otherProps } listClassName="my-0">
            {
                map(pages, page => {
                   
                    return page.page === '<<' || page.page === '>>' ? null : <PaginationItem key={page.page} active={ page.active } disabled={ page.disabled }>
                        <PaginationLink onClick={() => onPageChange(page.page)}>
                            { isInteger(page.page) ? page.page : mapToFa[page.page] }
                        </PaginationLink>
                    </PaginationItem>
                })
            }
        </Pagination>


    </Col>
);
PaginationPanel.propTypes = {
    pages: PropTypes.array,
    onPageChange: PropTypes.func
};

export const PaginationTotal = ({ from, to, size }) => (
    <span className="small ml-2 pagination-page-count">
        { from }-{ to } of { size }
    </span>
);
PaginationTotal.propTypes = {
    from: PropTypes.number,
    to: PropTypes.number,
    size: PropTypes.number,
};

