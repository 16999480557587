import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setupPage } from '../../components/Layout/setupPage';
import _ from 'lodash';
import { 
    Col,
    FloatGrid as Grid,
    Card,
    CardHeader,
    CardBody,
    Label,
    List
} from '../../components';

import * as actionTypes from '../../store/actionType';
import  * as actions  from '../../store/actions';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
import { applyColumn } from './../../components/FloatGrid';

import './Payment.style.scss';

const LAYOUT = {
    'metric-v-insight-revenue': { md: 12, h: 6  },
    'customer-balance-list': { md: 6, h: 11, minH: 6 },
    'customer-paid-list': { md: 6, h: 11, minH: 6 },
}

const mapStateToProps = state => ({
    apiRequestManager: ApiRequestManager.getInstance(ApiRequestManager),
    useClientLayout: state.global.useClientDashboard,
    mode:  state.global.actionBarMode,
    ...state.payment
});

const mapDispatchToProps = dispatch => ({
    unLoad: () => dispatch(actions.paymentViewUnloadAction()),
    getPayments: () => dispatch(actions.getAllPaymentsAction()),
    getPaymentsSummary: (accountOwner) => dispatch(actions.getPaymentsSummaryAction(accountOwner))
});

class Overview extends React.Component {

    static pageOption = {
        pageTitle: 'Overview',
        grayBackground: true,
        disableBodyScroll: true
    };

    state = { }

    resetLayout = () => {
        this.setState({
            layouts: _.clone(LAYOUT)
        })
    }

    constructor(props) {
        super(props);

        this.props.apiRequestManager.register(actionTypes.FETCH_PAYMENTS_SUMMARY, this.props.getPaymentsSummary);

        this.state = {
            layouts: _.clone(LAYOUT)
        };

        props.apiRequestManager.register(actionTypes.FETCH_PAYMENTS, props.getPayments);
    }

    componentDidMount() {}

    componentWillUnmount() {
        this.props.unLoad();
    }

    static getDerivedStateFromProps(props, state) {
        if ( !props.useClientLayout && !props.paymentsInsight && !props.apiRequestManager.inProgress(actionTypes.FETCH_PAYMENTS_SUMMARY) ) {
            props.apiRequestManager.queueRequest(actionTypes.FETCH_PAYMENTS_SUMMARY);
        }
        return null;
    }

    render () {

        const { layouts } = this.state;
        const calculatePercent = (divident, diviser) => {
            return (parseFloat(divident/diviser )) * 100
        };

        const paymentsInsight = this.props.paymentsInsight;
        const paymentSummaryInsights = {
            totalPayments: !Object.blank(paymentsInsight) ? paymentsInsight.paymentSummary?.totalPayments || 0.0 : 0.0,
            totalRevenue: !Object.blank(paymentsInsight) ? paymentsInsight.paymentSummary?.totalRevenue || 0.0 : 0.0,
            outStandingPayment: !Object.blank(paymentsInsight) ? paymentsInsight.paymentSummary?.outStandingPayment || 0.0 : 0.0,
            collectedPayments: !Object.blank(paymentsInsight) ? paymentsInsight.paymentSummary?.collectedPayments || 0.0 : 0.0,
            uncollectedPayments: !Object.blank(paymentsInsight) ? paymentsInsight.paymentSummary?.uncollectedPayments || 0.0 : 0.0,
        };

        const recentPayments = !Object.blank(paymentsInsight) ? paymentsInsight.recentPayments : [];
        const recentBills = !Object.blank(paymentsInsight) ? paymentsInsight.recentBills : [];
        
        const paymentsRatio = {
            revenueRatio: paymentsInsight ? calculatePercent(paymentSummaryInsights.collectedPayments, paymentSummaryInsights.totalPayments) : 0.0,
            collectedRatio: paymentsInsight ? calculatePercent(paymentSummaryInsights.outStandingPayment, paymentSummaryInsights.totalPayments) : 0.0
        };

        return (
            <React.Fragment>
            <div className='content-layout-wrap'>
                <div className="view-layout-content">
                    <div className='container'>
                   <div className="default-Ly-ct payment-overview">
                    <Col lg={ 12 }>
                    <Link to={"#"} className="navbar-brand">
                        <h1 className="dashboard-title">Payment Insights</h1>
                    </Link>
                    <Grid>
                    <Grid.Row 
                        isDraggable={false}
                        isResizable={ false}
                        onLayoutChange={ (evt) => { /*this.setState({ layouts })*/ } }
                        columnSizes={ this.state.layouts }
                        rowHeight={ 55 }
                        >
                            <Grid.Col { ...(applyColumn('metric-v-insight-revenue', layouts)) }>
                                <div className="insight-panel-wrap">
                                    <Card>
                                        <div className="insight-panel">
                                            <div className="top-sec">
                                                <h3 className="title">Payment Summary</h3>
                                            </div>
                                            <div className="content-sec">
                                                <div className="inner">
                                                    <div className="is-col revenue">
                                                        <h2 className="mc">${paymentSummaryInsights.totalRevenue.toCurrency()}</h2>
                                                        <Label>Revenue</Label>
                                                    </div>
                                                    <div className="is-col collected">
                                                        <h2 className="mc">${paymentSummaryInsights.totalPayments.toCurrency()}</h2>
                                                        <Label>Payments Received</Label>
                                                    </div>
                                                    <div className="is-col uncollected">
                                                        <h2 className="mc">${paymentSummaryInsights.uncollectedPayments.toCurrency()}</h2>
                                                        <Label>Uncollected Payments</Label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bottom-sec">
                                                <p>Payment Ratio Chart</p>
                                                <div className="btm-cont">
                                                    <div className="stripe-bar-chart lf-chart">
                                                        <div className="chat">
                                                            <div className="details">
                                                                <h4>${paymentSummaryInsights.collectedPayments.toCurrency()}</h4>
                                                                <span>Collected</span>
                                                            </div>
                                                            <div className="chat-bar">
                                                                <div className="inner-bar" 
                                                                style={
                                                                    {width: `${paymentsRatio.revenueRatio}%`}
                                                                }></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="stripe-bar-chart rt-chart">
                                                        <div className="chat">
                                                            <div className="details">
                                                                <h4>${paymentSummaryInsights.outStandingPayment.toCurrency()}</h4>
                                                                <span>Unverified</span>
                                                            </div>
                                                            <div className="chat-bar">
                                                                <div className="inner-bar" 
                                                                style={
                                                                    {width: `${paymentsRatio.collectedRatio}%`}
                                                                }></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            </Grid.Col>

                            <Grid.Col { ...(applyColumn('customer-balance-list', layouts)) }>
                            <Card>
                                <CardHeader>
                                    <div className="title">Recent Payments</div>
                                    <span className="sub-info">Most recent payments made</span>
                                </CardHeader>
                                <CardBody>
                                    <List className="chart-list">
                                        { recentPayments.length === 0 ?
                                            <li className="ccenter no-entry">No data avaliable</li>
                                            :
                                            recentPayments.map((item, i) => 
                                            <li key={i} className="chart-item">
                                                <div className="item-info">
                                                    <h4>{item.name}</h4>
                                                    <span>AC: {item.account}</span>
                                                </div>
                                                <div className="info-lines">
                                                    <p>
                                                        <span className="label">Amount</span> 
                                                        <span className="value">${item.amount.toCurrency()}</span>
                                                    </p>
                                                    <p>
                                                        <span className="label">Date Paid</span> 
                                                        <span className="value">{item.date}</span>
                                                    </p>
                                                </div>
                                            </li>)
                                        }
                                    </List>
                                </CardBody>
                            </Card>

                            </Grid.Col>

                            <Grid.Col { ...(applyColumn('customer-paid-list', layouts)) }>
                            <Card>
                                <CardHeader>
                                    <div className="title">Bills with Balance</div>
                                    <span className="sub-info">Most recent bills with blance</span>
                                </CardHeader>
                                <CardBody>
                                    <List className="chart-list">
                                        { recentBills.length === 0 ? 
                                        <li className="ccenter no-entry">No data avaliable</li>
                                        :
                                        recentBills.map((item, i) => 
                                        <li key={i} className="chart-item">
                                            <div className="item-info">
                                                <h4>{item.name}</h4>
                                                <span>AC: {item.account}</span>
                                            </div>
                                            <div className="info-lines">
                                                <p>
                                                    <span className="label">Payment Due</span> 
                                                    <span className="value">${item.amount.toCurrency()}</span>
                                                </p>
                                                <p>
                                                    <span className="label">Due Date</span> 
                                                    <span className="value">{item.date}</span>
                                                </p>
                                            </div>
                                        </li>
                                        )}
                                    </List>
                                </CardBody>
                            </Card>

                            </Grid.Col>
                        
                    </Grid.Row>

                    </Grid>
                    </Col>
                   </div>
                    </div>
                </div>
            </div>
            </React.Fragment>
        );
    }
}

const OverviewView = (props) => {
    return (
        <Overview {...props } />
    );
};

let ConfigOverviewView = setupPage(Overview.pageOption)(OverviewView);
ConfigOverviewView =  connect(mapStateToProps, mapDispatchToProps)(ConfigOverviewView);

export { ConfigOverviewView };