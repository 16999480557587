import * as actionTypes from '../actionType';

export const getAllFormItemGroupAction =  () => ({
    type: actionTypes.FETCH_FORM_ITEM_GROUP,
});
export const setFeatureDataAction = (data) => ({
    type: actionTypes.FORM_GROUP_SET_DATA,
    payload: data
});
export const formFieldChangeAction = (fieldSet) => ({
    type: actionTypes.FORM_GROUP_FIELD_CHANGED,
    payload: fieldSet
});
export const submitFormGroupAction = (data) => ({
    type: actionTypes.FORM_GROUP_SUBMIT_DATA,
    payload: data
}); 
export const setSettingDataAction = (type, data) => ({
    type: type,
    payload: data
});
export const submitLabCodeAction = (data) => ({
    type: actionTypes.LABCODE_SUBMIT_DATA,
    payload: data
}); 

export const submitCodeSheetAction = (data) => ({
    type: actionTypes.CODESHEET_SUBMIT_DATA,
    payload: data
}); 
export const submitCodeFindingAction = (data) => ({
    type: actionTypes.CODEFINDING_SUBMIT_DATA,
    payload: data
}); 
export const submitServicesAction = (data) => ({
    type: actionTypes.SERVICE_SUBMIT_DATA,
    payload: data
});
export const submitTaxesAction = (data) => ({
    type: actionTypes.TAX_SUBMIT_DATA,
    payload: data
}); 

export const getAppPreferencesAction = (data) => ({
    type: actionTypes.FETCH_APP_PREFERENCES,
    payload: data
});

export const submitChangedPreferencesAction = (data) => ({
    type: actionTypes.SUBMIT_CHANGED_PREFERENCES,
    payload: data
});  

export const getPrintGroups = (data) => ({
    type: actionTypes.FETCH_PRINT_GROUPS,
    payload: data
});

export const submitPrintGroups = (data) => ({
    type: actionTypes.PRINT_GROUP_SUBMIT_DATA,
    payload: data
});

export const setPrintGroupData = (data) =>({
    type: actionTypes.PRINT_GROUP_SET_DATA,
    payload: data,
});