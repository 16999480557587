import * as actionTypes from '../actionType';
import { isError } from '../../service/agent';

const initialState = {
    viewInit: false,
    paymentsData: null,
    accounts: null,
    payment: null,
    bills: null,

    paymentForm: {
        paymentType: '',
        bank: '',
        chequeNumber: '',
        amount: 0,
        account: null, 
    },

    accountBalanace: 0.0,
    paymentSuccess: false,

    paymentsInsight: null
 };

var error = null;
let empty = {}
let emptyArray = []

const paymentReducer = (state = initialState, action ) => { 

    switch(action.type) {
        case actionTypes.PAYMENTS_VIEW_LOADED:
            return {
                ...state,
                viewInit: true
            } 
        case actionTypes.PAYMENTS_VIEW_UNLOAD:
            return {
                ...initialState,
            } 
        case actionTypes.PAYMENT_MODAL_VIEW_UNLOAD:
            return {
                ...state,
                payment: state.paymentSuccess ? state.payment : null,
                accounts: null,
                bills: null,
                accountBalanace: initialState.accountBalanace,
                paymentForm: initialState.paymentForm,
                paymentSuccess: false
            } 
        case actionTypes.FETCHED_ACCOUNTS_RESULT:
            error = isError(action.payload);
            return {
                ...state,
                accounts: error ? emptyArray : action.payload
            } 
        case actionTypes.FETCHED_OWNER_ACCOUNT_RESULT:
            error = isError(action.payload);
            return {
                ...state,
                accounts: error ? emptyArray : [action.payload]
            }
        case actionTypes.FETCHED_PAYMENTS_RESULTS:
            error = isError(action.payload);
            return {
                ...state,
                paymentsData: error ? emptyArray : action.payload
            }
        case actionTypes.PAYMENT_FIELD_CHANGED :
            return {
                ...state,
                paymentForm: {
                ...state.paymentForm,
                ...action.payload
            }
        }
        case actionTypes.FETCHED_BILLED_BILLS_RESULTS :
            error = isError(action.payload);
            return {
                ...state,
                bills: error ? emptyArray : action.payload
            }
        case actionTypes.SET_ACCOUNT_BALANCE :
            return {
                ...state,
                accountBalanace: action.payload
            }
        case actionTypes.PAYMENT_SUBMITED_RESULTS :
            error = isError(action.payload);
            return {
                ...state,
                payment: error ? empty : action.payload,
                paymentSuccess: error ? false : true,
                paymentsInsight: error ? state.paymentsInsight : null,
                paymentsData: error ? state.paymentsData : null

            }
        case actionTypes.SET_PAYMENT_DATA :
            return {
                ...state,
                payment:  action.payload.payment
            }
        case actionTypes.FETCHED_PAYMENTS_SUMMARY_RESULTS:
            error = isError(action.payload);
            return {
                ...state,
                paymentsInsight: error ? empty : action.payload,
            }
        default:
            return state;
    }
};

export default paymentReducer;