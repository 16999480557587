import React from 'react';
import { Link } from 'react-router-dom';
import { LogoThemed } from './ThemeLogo';
import { Navbar } from '../../../components';
import { ApiRequestManager } from '../../../utility/ApiRequestManager';
import { NavigationContext } from '../navbar_elements/NavigationContext';
import {
  logoutActionCreator,
  dismissNotificationAction,
  contextSearchAction,
  searchTermChangeAction,
  setProfilePaneAction,
} from '../../../store/actions';

import SearchBar from '../navbar_elements/SearchBar';
import NavigationMenu from '../navbar_elements/NavigationMenu';
import ClientNavigationMenu from '../navbar_elements/ClientNavigationMenu';
import NotificationBlock from '../navbar_elements/NotificationBlock';
import SettingsBlock from '../navbar_elements/SettingsBlock';
import { connect } from 'react-redux';
import { PermissionEnforcer } from '../../../utility/PermissionEnforcer';
import * as urls from '../../../config/urls';

const mapStateToProps = (state) => ({
  apiRequestManager: ApiRequestManager.getInstance(ApiRequestManager),
  unreadMessages: state.message.unread,
  notifications: state.global.notifications,
  searchResults: state.global.searchResults,
  searchTerm: state.global.searchTerm,
  workspace: state.global.workspace,
});
const mapDispatchToProps = (dispatch) => ({
  //onLoad: payload => dispatch({ type: PROFILE_PAGE_LOADED, payload }),
  onLogout: () => dispatch(logoutActionCreator()),
  showRolePermission: () => dispatch(logoutActionCreator()),
  dismissNotification: (payload) =>
    dispatch(dismissNotificationAction(payload)),
  performSearch: (payload) => dispatch(contextSearchAction(payload)),
  searchFieldChanage: (value) => dispatch(searchTermChangeAction(value)),
  setActiveProfilePane: (activePane) =>
    dispatch(setProfilePaneAction(activePane)),
});

class MainNavigation extends React.Component {
  doLogout() {
    this.props.onLogout();
  }

  showRolePermission() {
    this.props.history.push({ pathname: urls.permissionUri });
  }

  showSettings() {
    this.props.history.push({ pathname: urls.settingsUri });
  }
  showSettingPane(paneId) {
    this.props.history.push({ pathname: '/settings/' + paneId });
  }

  showFormSetting() {
    this.props.history.push({ pathname: urls.formSettingUri });
  }

  showProfileSettings(activePane) {
    this.props.history.push({ pathname: urls.profileUri });
    this.props.setActiveProfilePane(activePane);
  }

  render() {
    const permissionEnforcer =
      PermissionEnforcer.getInstance(PermissionEnforcer);

    const settingsBlockActions = {
      willLogout: this.doLogout.bind(this),
      willShowRolePermission: this.showRolePermission.bind(this),
      willShowSettings: this.showSettings.bind(this),
      willShowFormSetting: this.showFormSetting.bind(this),
      willShowSettingPane: this.showSettingPane.bind(this),
    };

    const useClientLayout = this.props.clientLayout;

    const actions = {
      dismissNotification: this.props.dismissNotification,
      willShowProfileSettings: this.showProfileSettings.bind(this),
    };
    return (
      <React.Fragment>
        {/* ---- Top Nabe bar ---- */}
        <NavigationContext.Provider value={{}}>
          <Navbar>
            <Link to={urls.dashboardUri}>
              <LogoThemed
                title={''}
                className='logo'
                style='dashboardLogo'
                height='60'
              />
            </Link>

            <SearchBar {...this.props} />
            <div className='ntf-stg-group'>
              <NotificationBlock
                unreadMessages={this.props.unreadMessages}
                notifications={this.props.notifications}
                actions={actions}
                currentUser={this.props.currentUser}
              />

              <span className='horizontal-divider'></span>

              <SettingsBlock
                accountInfo={this.props.accountInfo}
                clientLayout={useClientLayout}
                {...settingsBlockActions}
              />
            </div>
          </Navbar>

          {/* ---- Navigation Menu ---- */}
          <Navbar className='menu-item-nav'>
            {useClientLayout ? <ClientNavigationMenu /> : <NavigationMenu />}
          </Navbar>
        </NavigationContext.Provider>
      </React.Fragment>
    );
  }
}

const MainNavigationContext = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainNavigation);

export default MainNavigationContext;
