import { USER_TYPE } from './../common/constant';

class PermissionEnforcer {
    
    static _instance = null;
    _rolePrefix = /^ROLE/;
    _superRole = false;
    _role = null;
    _permissions = [];
    _chosenSet = [];
    _authorities = [];
    _user = null;

    hasRights(rights, entity) {
        if (!Array.isArray(rights)) {
            rights = [rights];
        }
        let grantedAuthorities = this.filterAuthorities(this._authorities);
        return this.isSuperRole() || rights.some(right => grantedAuthorities.includes(`${right}_${entity}`));
    }
    isAccountOwner(owner) {
        return this._user && this._user.username === owner ? true : false;
    }
    isUserAuthorizer() {
        return this._user && this._user.type === USER_TYPE.Authorizer.toLocaleLowerCase() ? true : false;
    }
    isPermitted(entity, allowed) {
        let grantedAuthorities = this.filterAuthorities(this._authorities);
        return this.isSuperRole() || grantedAuthorities.includes(`${allowed}_${entity}`);
    }

    isSuperRole() {
        if (this._user) {
            this._superRole = this._user.role.superRole;
        }
        return this._superRole;
    }
    isStandardUser() {
       return this._user && this._user.type === USER_TYPE.Standard.toLocaleLowerCase() ? true : false;
    }
    isClientOrStaff() {
        return this.isClientUser() || this.isSecondaryUser() ? true : false;
    }
    isClientUser() {
        return this._user && this._user.type === USER_TYPE.Client.toLocaleLowerCase() ? true : false;
     }
    isSecondaryUser() {
        return this._user && this._user.secondary ? true : false;
    }

    combine(){
        this._permissions = this._permissions.merge(this._chosenSet);
        this._chosenSet = [];
    }
    
    get superRole() {
        return this._superRole;
    }

    set superRole(value) {
        this._superRole = value;
    }

    get role() {
        return this._role;
    }

    get userRights() {
        return {
            role: this._role,
            authorities: this._authorities
        }
    }

    get chosenPermissions() {
        return this._chosenSet;
    }

    set user(user) {
        this._user = user;
    }

    get user() {
        return this._user;
    }

    set chosenPermissions(permissions) {
        this._chosenSet = permissions;
    }

    get permissions() {
        return this._permissions;
    }

    set permissions(permissions) {
        this._permissions = permissions;
    }
    get authorities() {
        return this._authorities;
    }

    set authorities(authorities) {
        this._authorities = authorities;
        //this.filterAuthorities(authorities);
    }

    filterAuthorities(authoritiesSet) {
        let grantedauthorities = [];
        for (var i in authoritiesSet) {
            if ( this._rolePrefix.test(authoritiesSet[i].authority) ) {
               this._role = authoritiesSet[i].authority;
            }else {
                grantedauthorities.push(authoritiesSet[i].authority);
            }
        }
        return grantedauthorities;
    }

    discard() {
        this._role = null;
        this._user = null;
        this._authorities = [];
        this._permissions = [];
        this._chosenSet = [];
        this._superRole = false;
        PermissionEnforcer._instance = null;
    }

    static getInstance = (classInstance) => PermissionEnforcer._instance ? PermissionEnforcer._instance : (PermissionEnforcer._instance = new classInstance());
};

export { PermissionEnforcer };