import { requests } from './agentDialect';

export const fetchProfile = (profileId) => {
  let uri = `/profile/${profileId}`;
  return requests.get(uri);
};

export const updateProfile = (data) => {
  let uri = `/profile/update/${data.id}`;
  return requests.formPut(uri, data.form);
};
