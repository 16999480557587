import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withPageConfig } from './withPageConfig'

export const setupPage = (startupConfig) => (Component) => {
    class PageSetupWrap extends React.Component {
        static propTypes = {
            pageConfig: PropTypes.object
        }
        componentDidMount() {
            this.prevConfig = _.pick(this.props.pageConfig,
                ['pageTitle', 'pageDescription', 'pageKeywords']);
            this.props.pageConfig.changeMeta(startupConfig);

            const {grayBackground, disableBodyScroll } = startupConfig;
            
            grayBackground && this.props.pageConfig.useGrayBackground(grayBackground);
            disableBodyScroll && this.props.pageConfig.disableBodyScroll(disableBodyScroll);
        }

        componentWillUnmount() {
            this.props.pageConfig.changeMeta(this.prevConfig);
            this.props.pageConfig.useGrayBackground(false);
            this.props.pageConfig.disableBodyScroll(false);
            this.props.pageConfig.setSearchContext(null);
        }

        render() {
            return (
                <Component { ...this.props } />
            )
        }
    }

     return withPageConfig(PageSetupWrap);
};