import * as actionTypes from '../actionType';


export const usersViewLoadedAction =  () => ({
    type: actionTypes.USERS_VIEW_LOADED,
}); 

export const usersViewUnloadAction =  () => ({
    type: actionTypes.USERS_VIEW_UNLOAD
}); 

export const userCreateViewLoadedAction =  (mode) => ({
    type: actionTypes.USER_CREATE_VIEW_LOADED,
    payload: {
        mode
    }
}); 

export const userCreateViewUnloadAction =  () => ({
    type: actionTypes.USER_CREATE_VIEW_UNLOAD
}); 

export const getAllUsersActionCreator = (payload) => ({
    type: actionTypes.FETCH_USERS,
    payload: payload
}); 

export const getUserAction = (userId) => ({
    type: actionTypes.FETCH_USER,
    payload: userId
}); 

export const userAssignedRoleAction =  (payload) => ({
    type: actionTypes.USERS_ASSIGNED_ROLE,
    payload: payload
}); 

export const userFieldChangeAction =  (field) => ({
    type: actionTypes.USER_FIELD_CHANGED,
    payload: {
       ...field
    }
}); 

export const setUpdateUserDataAction =  (fieldsData) => ({
    type: actionTypes.USER_EDIT_SETDATA,
    payload: {
       ...fieldsData
    }
}); 

export const submitUserAction = (data) => ({
    type: actionTypes.USER_SUBMIT_DATA,
    payload: data
}); 

export const setSingleSuperuser = (isTure) => ({
    type: actionTypes.SET_SINGLE_SUPERUSER,
    payload: isTure
}); 

export const userSubmitedResultsAction = (payload) => ({
    type: actionTypes.USER_SUBMITED_RESULTS,
    payload
}); 

export const getAuthUserAction = (payload) => ({
    type: actionTypes.FETCH_AUTH_USER,
    payload
}); 

export const setAuthUserAction = (data) => ({
    type: actionTypes.SET_AUTH_USER,
    payload: data
}); 

export const setSavedUsersAction = (usersList) => ({
    type: actionTypes.SET_SAVED_USERS,
    payload: usersList
}); 

export const submitChangePasswordAction = (payload) => ({
    type: actionTypes.PASSWORD_CHANGE_SUBMIT_DATA,
    payload
}); 

export const submitAuthAccessAction = (payload) => ({
    type: actionTypes.AUTH_ACCESS_SUBMIT_DATA,
    payload
}); 

