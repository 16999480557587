import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { setupPage } from '../../components/Layout/setupPage';
import parse from 'html-react-parser';
import { 
    Nav,
    NavItem,
    NavLink,
    Button,
    Authority,
    ThemeConsumer,
} from '../../components';

import  { PermissionEnforcer }  from '../../utility/PermissionEnforcer';
import { ApiRequestManager } from '../../utility/ApiRequestManager';

import  * as actions  from '../../store/actions';
import * as actionTypes from '../../store/actionType';
import * as urls from '../../config/urls';
import { MESSAGE_CODES } from '../../common/constant';
import {Compose} from './Compose';
import './Message.style.scss';

import SocketCommunicator from './../../utility/SocketCommunicator';
import ConfirmModal from '../components/partials/ConfirmModal';
import { getIconUrl } from '../../common/images-catalogue';

const mapStateToProps = state => ({
    apiRequestManager: ApiRequestManager.getInstance(ApiRequestManager),
    socketCommunicator: SocketCommunicator.getInstance(SocketCommunicator),
    authorizeUser: state.global.authorizeUser,
    ...state.message
});

const mapDispatchToProps = dispatch => ({
    onLoad: () => dispatch(actions.messageThreadViewLoadedAction()),
    unLoad: () => dispatch(actions.messageThreadViewUnloadAction()),
    getMessageThread: (payload) => dispatch(actions.getMessageThreadAction(payload)),
    setViewContext: (payload) => dispatch(actions.setMessageViewAction(payload)),
    markMessagesRead: (messages) => dispatch(actions.readMessageAction(messages)),
    deleteMessage: (payload) => dispatch(actions.deleteMessageAction(payload)),
    onRedirect: () => dispatch({ type: actionTypes.REDIRECT}),
    doRedirect: (to) => dispatch({ type: actionTypes.REDIRECT_TO, payload:  to}),
    setPreserveState: (yesOrNo) => dispatch(actions.messagesPreserveStateAction(yesOrNo))
});

const MessageItem = (props) => {

    const { message, actions }= props;
    const type = `${message.tag.toLowerCase()}`;
    const onReply = (data) => {
        actions.showInlineReply(data);
    }
    const onDelete = (data) => {
        actions.deleteMessage(data);
    }
    const body = message.body;
    const firstName = message.headerFrames[0]?.sender?.firstName || 'Fisrtname';
    const lastName = message.headerFrames[0]?.sender?.lastName|| 'Lastname';
    const sender = message.tag === 'Inbound' ? firstName + ' ' + lastName : 'Me';
    const sentDate = message.dateCreated;
    const readDate = message.readDate;
    const hasReplay = message.tag === 'Inbound' ? true : false;
    const showReadIndicator = message.tag === 'Outbound' && message.isRead ? true : false;
    
    return (

        <div className={`message-wrp ${type}-msg`}>
            <div className="message-bx">
                <div className="msg-action-br">
                    <div className="msg-date">
                    <Button className="arrow-btn"><span className="arrow-indicator"></span></Button>
                    {sentDate}
                        <div className="msg-meta-data">
                            <div className="wrap">
                                <div className="l-side">
                                    <span className="meta-label ln">from:</span>
                                    <span className="meta-label ln">to:</span>
                                    <span className="meta-label ln">subject:</span>
                                    <span className="meta-label ln">date:</span>
                                </div>
                                <div className="r-side">
                                    <p className="meta-value ln">
                                        <span className="md">
                                            {message.headerFrames[0]?.sender?.username}
                                        </span>
                                    </p>
                                    <p className="meta-value ln">
                                        {    
                                        message.headerFrames.map((item, i) => <span className="md rpt" key={i}>{item.recipient?.username}</span>)
                                        }
                                        
                                    </p>
                                    <p className="meta-value ln">
                                        <span className="md">{message.headerFrames.first()?.subject}</span>
                                    </p>
                                    <p className="meta-value ln">
                                        <span className="md">{message.sendDate}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="msg-actions">
                        { hasReplay && 
                            <Button onClick={() => onReply(message)}>
                                <span className="icon">
                                    <img src={getIconUrl('reply')} />
                                </span>
                            </Button>
                        }
                        <Button onClick={() => onDelete(message)}>
                            <span className="icon">
                                <img src={getIconUrl('trash')} />
                            </span>
                        </Button>
                    </div>
                </div>
                <div className="msg-cont">
                    <div className="msg-inner">
                        <div className="msg-sender">
                            <div className="user-picture">
                                <div className="picture-holder">
                                    <div className="picture-content">
                                        {firstName[0]}{lastName[0]}
                                    </div>
                                </div>
                            </div>
                            <div className="sender">
                                {sender}
                            </div>
                        </div>
                        <div className="msg-body">
                            <div>
                                {parse(body)}
                            </div>
                        </div>
                        { showReadIndicator &&
                            <div className="read-receipt">
                                <span className="read-date">{readDate}</span>
                                <span className="read-receipt-indicator">
                                    <img src={getIconUrl("read")} />
                                </span>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

class MessageThread extends React.Component {

    static pageOption = {
        pageTitle: 'Client',
        disableBodyScroll: true,
        grayBackground: true,
    };

    constructor(props) {
        super(props);
        this.state = {
            initialView: 'inbox',
            showCompose: false,
            leadMessage: null,
            sentReadRequest: false,
            showInlineCompose: false,
            minimize: false,
            deleteDialogVisible: false,
            deleleContextItem: {}
        }

        props.apiRequestManager.register(actionTypes.FETCH_MESSAGE_THREAD, props.getMessageThread);
        props.apiRequestManager.register(actionTypes.SEND_MESSAGE_READ, props.markMessagesRead);
        props.apiRequestManager.register(actionTypes.DELETE_MESSAGE, props.deleteMessage);

        this.onShowCompose = this.onShowCompose.bind(this);
        this.onHideCompose = this.onHideCompose.bind(this);
        this.onShowInlineReply = this.onShowInlineReply.bind(this);
        this.onHideInlineReply = this.onHideInlineReply.bind(this);
        this.onMinimize = this.onMinimize.bind(this);
        this.willGoBack = this.willGoBack.bind(this);
        this.onSendMessage = this.onSendMessage.bind(this);
        this.changeView = this.changeView.bind(this);
        this.onDeleteMessage = this.onDeleteMessage.bind(this);
        this.deleteAllMessages = this.deleteAllMessages.bind(this);
        this.onConfirmDelete = this.onConfirmDelete.bind(this);
        this.showHideDeleteDialog = this.showHideDeleteDialog.bind(this);

        props.setViewContext({
            activeView: props.activeView === undefined ? this.state.initialView : props.activeView,
            viewContext: 'thread'
        });
    }

    componentDidMount() {
        this.loadMessges();
        this.props.onLoad();
    }

    componentWillUnmount() {
        !this.props.preserveState && this.props.unLoad();
    }

    shouldComponentUpdate(props, state) {
        return true;
    }   

    static getDerivedStateFromProps(props, state) {
        // Return null to indicate no change to state.
        if (state.leadMessage === null  && props.messageThread !== null && !props.messageThread.messages.empty()) { 
            return{
                leadMessage: props.messageThread.messages[0]
            };
        }
        
        if (!state.sentReadRequest && props.messageThread !== null && !props.messageThread.messages.empty()) {
            let unreadList = [];
            props.messageThread.messages.map((msg) => {
                if ( !msg.isRead && msg.tag === 'Inbound' ) {
                    unreadList.push(msg.id);
                }
            });

            !unreadList.empty() && props.apiRequestManager.queueRequest(actionTypes.SEND_MESSAGE_READ, unreadList);
            return {
                sentReadRequest: true
            }
        }

        if (props.redirectTo) {
            props.onRedirect();
            props.doRedirect(props.redirectTo);
        }

        return null;
    }

    loadMessges() {
        const threadId = this.props.match.params.thread_id;
        this.props.apiRequestManager.queueRequest(actionTypes.FETCH_MESSAGE_THREAD, {
            threadId: threadId
        });
    }

    onShowCompose(evt) {
        this.setState({showCompose: true });
    }

    onHideCompose(evt) {
        this.setState({showCompose: false,  minimize: false });
    }

    onShowInlineReply(data) {
        
        const lastIndex = this.props.messageThread.messages.length - 1;
        const currentMessage = data === undefined ? 
        this.props.messageThread.messages[lastIndex] : data;
        this.setState({showInlineCompose: true, leadMessage: currentMessage });
    }

    onDeleteMessage(data, deleteAll=false) {

        const contextItem = {
            deleteAll: deleteAll,
            data: data
        }
        this.showHideDeleteDialog(contextItem);
    }

    deleteAllMessages() {
        if (this.props.messageThread && !this.props.messageThread.messages.empty() ) {
            const contextItem = {
                deleteAll: true,
                data: {}
            }
            this.showHideDeleteDialog(contextItem);
        }
    }

    onConfirmDelete(contextItem) {
        
        const payload = {
            threadId: contextItem.deleteAll || this.props.messageThread.messages.length === 1 ? this.props.messageThread.id : null,
            messageId: !contextItem.deleteAll && this.props.messageThread.messages.length >= 2 ? contextItem.data.id : null,
        };

        this.props.apiRequestManager.queueRequest(actionTypes.DELETE_MESSAGE, payload);
        this.showHideDeleteDialog();
    }

    showHideDeleteDialog(contextItem) {
        this.setState({
            deleteDialogVisible: !this.state.deleteDialogVisible,
            deleleContextItem: contextItem
        });
    }

    onHideInlineReply() {
        this.setState({showInlineCompose: false });
    }

    onMinimize(evt) {
        this.setState({minimize: !this.state.minimize });
    }

    willGoBack() {
        this.props.setPreserveState(true);
        this.props.history.push(urls.messagesUri);
    }

    changeView(evt) {
        const view  = evt.target.getAttribute('to');
        
        this.props.setViewContext({
            activeView: view,
        });
        this.props.history.push(urls.messagesUri);    
    }

    onSendMessage(data, isReply) {
        
        data['sender'] =  this.props.authorizeUser.username;
        data['type'] =  !isReply ? MESSAGE_CODES.NEW : MESSAGE_CODES.REPLY;
        if (isReply) {
            const leadMessage = this.state.leadMessage;
            const recipient = leadMessage.tag === "Inbound" ? leadMessage.headerFrames.first().sender.username : leadMessage.headerFrames.first().recipient.username;

            data['subject'] = leadMessage.headerFrames.first().subject;
            data['recipients'] = [recipient];
            data['replyCandidate'] = leadMessage.messageThreadRef
        }
        this.props.socketCommunicator.sendMessage(data);
    }

    render () {

        const showComposeClass = this.state.showCompose? 'show' : '';
        const minimizeClass = this.state.minimize ? '--minimize' : '';

        const unreadCount =   this.props.unread.length > 0 ? `(${this.props.unread.length})`: '';

        const composeActions = {
            close: this.onHideCompose,
            minimize: this.onMinimize,
            sendMessage: this.onSendMessage
        }
        const InlineComposeActions = {
            showInlineReply: this.onShowInlineReply,
            deleteMessage: this.onDeleteMessage,
            close: this.onHideInlineReply,
            sendMessage: this.onSendMessage
        }

        const thread = this.props.messageThread;
        const messages = thread !== null ? this.props.messageThread.messages : [];
        const activeView = this.props.activeView;
        const mainSubject = !Object.blank(this.state.leadMessage) ? this.state.leadMessage?.headerFrames?.first()?.subject : '';
       
        return (
            <React.Fragment>
            <div className='content-layout-wrap specimen-list message-thread'>
               <div className="sidebar">
                    <div className="button-wrap">
                        <Authority  allowed="create" entity="record" >
                            <Button className="default-btn add-btn" onClick={this.onShowCompose}>
                                <span className="icon">
                                    <img src={getIconUrl("newMessage")} alt='' />
                                </span>
                                Compose
                            </Button>
                        </Authority>
                    </div>
                    <div className="nav-container">
                        
                        <nav className="sidebar-nav">

                            <Nav>
                                <NavItem>
                                    <NavLink to={"inbox"} onClick={this.changeView} className={classnames({ active: activeView === 'inbox' })}><span className="icon">
                                        <img src={getIconUrl("inbox")} alt=''/>
                                        </span>Inbox {unreadCount}</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink to={"sent"} onClick={this.changeView} className={classnames({ active: activeView === 'sent' })}><span className="icon">
                                        <img src={getIconUrl("sent")} alt=''/>
                                    </span>Sent</NavLink>
                                </NavItem>
                            </Nav> 
                
                        </nav>
                    </div>
               </div>
               <div className="v-line"></div>
               <div className="view-layout-content container">
                    <div className="spmt-Ly-ct-inner">
                        <div className="msg-sub-title-d">
                            <Button className="back-button" onClick={this.willGoBack}>
                                 <span className="arrow-left"></span>   
                            </Button>
                            <h3 className="msg-sub-title">
                                <span className="subj">Subject:</span>
                                <span className="subj-text">{mainSubject}</span>
                            </h3>
                            <Button className="del-all-btn" onClick={this.deleteAllMessages }>
                            <span className="icon">
                                <img src={getIconUrl('trash')} />
                            </span>
                            </Button>
                        </div>
                    </div>
                    
                    <div className="spmt-Ly-ct">
                        <div className="spmt-v-lay-ct">
                            <div className="content-well">
                                <div className="msg-thread">
                                    <div className="thread-cnt">

                                        { messages.map((item, i) => {
                                                return (<MessageItem 
                                                    key={i}
                                                    actions= {InlineComposeActions} 
                                                    message={item}/>
                                                );
                                            })
                                        }
                                        {messages.length > 0  && 
                                        <div className="iLne-cmpe">
                                            {this.state.showInlineCompose ? 
                                                <Compose
                                                actions= {InlineComposeActions} 
                                                contextMessage={this.state.leadMessage}
                                                label={'Reply'}
                                                inline={true}/>
                                                :
                                                <Button 
                                                onClick={() => this.onShowInlineReply()} 
                                                className="reply-btn">
                                                    <span className="icon">
                                                        <img src={getIconUrl('reply')} />
                                                    </span>
                                                    Reply
                                                </Button>
                                            }
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>   
                    </div>
                    
                </div>
            </div>

            <div className="cmpe-lyr">
                    <div className="cL cmpe-oL">
                        <div className="cL cmpe-iL">
                            <div className={`cmpe-bL ${minimizeClass}`}>
                                <div className="cL fL cmpe-wL">
                                    <div className={`cL cmpe-oN ${showComposeClass}`}>
                                        <div className="cL fL cmpe-wL">
                                            <div className="cL cmpe-ED">
                                                <Compose 
                                                    actions= {
                                                        composeActions
                                                    }
                                                    label={'Send'}
                                                    inline={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                { this.state.deleteDialogVisible && 
                <ConfirmModal 
                    contextName={'message'}
                    contextItem={this.state.deleleContextItem} 
                    cancelCallback={this.showHideDeleteDialog}
                    confirmCallback={this.onConfirmDelete}
                />
                }
            </React.Fragment>
        );
    }
}

const MessageThreadView = (props) => {
    return (
        <MessageThread {...props } />
    );
};

let ConfigMessageThreadView = setupPage(MessageThread.pageOption)(MessageThreadView);
ConfigMessageThreadView =  connect(mapStateToProps, mapDispatchToProps)(ConfigMessageThreadView);

export { ConfigMessageThreadView };