import * as actionTypes from '../actionType';
import { decodeJwtToken } from "../../utility/helpers";
import { isError } from '../../service/agent';
import { ApiRequestManager } from '../../utility/ApiRequestManager';

const initialState = {
    appRoute: '/',
    token: null,
    workspace: null,
    account: null,
    dashboardData: null,
    syncInProgress: false,
    refreshJwtToken: false,
    initGlobalData: false,
    systemPermissions: [],
    notifications: [],
    searchResults: [],
    searchTerm: '',
    networkStatus: {},
    workspaceName: ""
};
const empty = {};
const emptyArray = [];
var error = null;
let requestManager = null;
let apiOutcome = null;

const commonReducer = (state = initialState, action) => {
    
    switch(action.type) {
        case actionTypes.PING_SERVER:
            return { ...state, isPinging: true };
        case actionTypes.PING_SERVER_DONE:
            return { ...state, isPinging: false, ping: action.payload.ping };
        case actionTypes.ASYNC_START:
            return { ...state, syncInProgress: true }
        case actionTypes.NETWORK_STATUS :
            return {...state, networkStatus: action.payload}
        case actionTypes.APP_ROUTE_CHANGE:
        case actionTypes.ROUTER_ROUTE_CHANGE:
            const { location } = action.payload
            const uri = location ? location.pathname : action.payload
            return {...state, searchTerm: '', appRoute:uri }
        case actionTypes.APP_LOADED: 
            return {
                ...state,
                initialize: true,
                token: action.payload || null,
                refreshJwtToken: false
            }
        case actionTypes.REDIRECT_TO:
            return { ...state, redirectTo: action.payload };
        case actionTypes.REDIRECT:
            return { ...state, redirectTo: null };
        
        case actionTypes.LOGOUT:
            
            return { 
                ...initialState, 
                redirectTo: '/auth/login',
                authorizeUser: null,
                token: null,
                initGlobalData: false,
                dashboardData: null,
                permissions: emptyArray,
            };
        case actionTypes.AUTH_RESULTS:
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.LOGIN) ?? {};

            let claims  = apiOutcome?.success ?  decodeJwtToken(action.payload.accessToken) : null;
            
            return {
                ...state,
                redirectTo: apiOutcome?.error ? null : '/dashboard',
                token: apiOutcome?.error ? null : action.payload.accessToken,
                useClientDashboard: apiOutcome?.error ? null : action.payload.useClientDashboard,
                authorizeUser: apiOutcome?.error ? null : action.payload.user,
                permissions: claims ? claims.authorities : emptyArray
            };
        case actionTypes.REQUEST_AUTH_USER_RESULT:
        case actionTypes.USER_SUBMITED_RESULTS:
        case actionTypes.PROFILE_SUBMIT_DATA_RESULT:
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.REQEUST_AUTH_USER) || 
                        requestManager.getApiOutcome(actionTypes.PROFILE_SUBMIT_DATA) ||
                        requestManager.getApiOutcome(actionTypes.USER_SUBMIT_DATA) ||
                        {};

            return {
                ...state,
                authorizeUser: apiOutcome?.success ? action.payload : null
            }
        case actionTypes.DASHBAORD_LAYOUT:
            return {
                ...state,
                useClientDashboard:  action.payload
            }
        case actionTypes.DASHBOARD_VIEW_UNLOAD:
            return {
                ...state,
                dashboardData:  null
            }
        case actionTypes.ACTION_BAR_MODE :
                return {
                ...state,
                actionBarMode: action.payload
        }
        case actionTypes.FETCH_ROLES_PERMISSIONS_RESULTS:
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.FETCH_ROLES_PERMISSIONS) ?? {};
            
            return { 
                ...state, 
                systemPermissions: apiOutcome?.success ? action.payload.permissions : emptyArray
             };
        case actionTypes.USER_DETAILS:
            return {
                ...state,
                permissions: action.payload.claims.authorities
            };
        case actionTypes.FETCHED_CIENT_ACCOUNT_RESULT: 
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.FETCH_CIENT_ACCOUNT) ?? {};
            if (apiOutcome?.error) {
                return {
                    ...state,
                    account: empty,
                    workspace: empty 
                };
            }
            var {workspace, ...otherData} = action.payload;
            if (workspace === undefined) {
                workspace = otherData;
                otherData = null;
            }
            return {
                ...state,
                account: error ? empty : otherData,
                workspace: error ? empty : workspace
            };
        case actionTypes.PERMISSIONS_ROLES_VIEW_UNLOAD:
            return {
                ...state, 
                systemPermissions: []
            }
        case actionTypes.REFRESH_JWT_TOKEN:
            return {
                ...state,
                refreshJwtToken: true
            }
        case actionTypes.REFRESH_JWT_TOKEN_RESULT:
            return {
                ...state,
                token: action.payload.accessToken,
                refreshJwtToken: false
            }
        case actionTypes.ASYNC_COMPLETED:
            const {preTokenRefreshAction, ...otherState} = state;
            return  {
                ...otherState,
                syncInProgress: false 
            }

        case actionTypes.FETCHED_CODE_SHEETS_RESULTS:
            error = isError(action.payload);
            const codesheets = action.payload.codesheets || action.payload;
            return {
                ...state,
                codeSheets: error ? emptyArray : codesheets
        }
        case actionTypes.FETCHED_CODE_FINDINGS_RESULTS:
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.FETCH_CODE_FINDINGS) ?? {};
            return {
                ...state,
             codeFindings: apiOutcome?.success ? action.payload : emptyArray
        }
        case actionTypes.FETCHED_GLOBAL_DATA_RESULTS:
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.FETCH_GLOBAL_DATA) ?? {};
            return {
                ...state,
                initGlobalData: true,
                notifications: apiOutcome?.success ? action.payload.notifications : apiOutcome
            }
        case actionTypes.DISMISS_NOTIFICATION_RESULTS:
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.DISMISS_NOTIFICATION) ?? {};
            let filteredNotificatons = apiOutcome?.error ? state.notifications : state.notifications.filter((item) => {
                var match = false;
                for (let i = 0; i < action.payload.length; i++) {
                    if ( action.payload[i] === item.id ) {
                        match = true;
                        break;
                    }
                }
                if ( !match ) {
                    return item;
                }
            });
            return {
                ...state,
                notifications: filteredNotificatons
            }
        case actionTypes.RECEIVED_NOTIFICATION:
            if (action.payload.workspace === state.workspace.id) {
                return {
                    ...state,
                    notifications: [
                        action.payload,
                        ...state.notifications
                    ]
                }
            }else {
                return {
                    ...state
                }
            }
        case actionTypes.FETCHED_DASHBOARD_DATA_RESULT:
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.FETCH_DASHBOARD_DATA) ?? {};
            return {
                ...state,
                dashboardData: apiOutcome?.success ? action.payload : empty
            }
        case actionTypes.NETWORK_ERROR: 
            error = isError(action.payload);
            return {
                ...state,
                inProgress: false,
                errors: error ? action.payload : null,
            };
        case actionTypes.SEARCH_FIELD_CHANGE: 
            return {
                ...state,
                searchTerm:  action.payload.term,
                searchResults: action.payload.clear ? [] : state.searchResults
            };
        case actionTypes.CONTEXT_SEARCH_RESULTS: 
            error = isError(action.payload);
            return {
                ...state,
                searchResults: error ? emptyArray : action.payload,
            };
        case actionTypes.SUBMIT_WORKSPACE_NAME_RESULTS:
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.SUBMIT_WORKSPACE_NAME) ?? {};
            return {
                ...state,
                workspaceName: action.payload
            };
        default:
            return state;
    }
};

export default commonReducer;