import * as actionTypes from '../actionType';

export const billsViewLoadedAction =  () => ({
    type: actionTypes.BILLS_VIEW_LOADED,
}); 

export const billsViewUnloadAction =  () => ({
    type: actionTypes.BILLS_VIEW_UNLOAD
}); 
export const billCreateViewLoadedAction =  () => ({
    type: actionTypes.BILL_CREATE_VIEW_LOADED,
}); 

export const billCreateViewUnloadAction =  () => ({
    type: actionTypes.BILL_CREATE_VIEW_UNLOAD
}); 

export const getAllBillsAction =  (payload) => ({
    type: actionTypes.FETCH_BILLS,
    payload
});

export const getBillAction =  (billId) => ({
    type: actionTypes.FETCH_BILL,
    payload: billId
});

export const getBillPaymentsAction = (billId) => ({
    type: actionTypes.FETCH_BILL_PAYMENTS,
    payload: billId,
});

export const getAllBilledBillsAction =  (client) => ({
    type: actionTypes.FETCH_BILLED_BILLS,
    payload: client
});
export const getAllPaidBillsAction =  (payload) => ({
    type: actionTypes.FETCH_PAID_BILLS,
    payload
});
export const getAllUnpaidBillsAction =  (payload) => ({
    type: actionTypes.FETCH_UNPAID_BILLS,
    payload
});
export const getAllClientsServicesAction = () => ({
    type: actionTypes.FETCH_CLIENTS_SERVICES,
});
export const setSelectedBillAction =  (employee) => ({
    type: actionTypes.SET_SELECTED_BILL,
    payload: employee
}); 
export const billFieldChangeAction =  (field) => ({
    type: actionTypes.BILL_FIELD_CHANGED,
    payload: {
       ...field
    }
}); 
export const setBillSummaryAction =  (summary) => ({
    type: actionTypes.SET_BILL_SUMMARY,
    payload: {
       ...summary
    }
}); 
export const getBillsSummaryAction = (client) => ({
    type: actionTypes.FETCH_BILLS_SUMMARY,
    payload: client
});
export const setBillableRecordsAction = (items) => ({
    type: actionTypes.SET_BILLABLE_RECORDS,
    payload: items
});
export const setUpdateBillDataAction =  (fieldsData) => ({
    type: actionTypes.BILL_EDIT_SETDATA,
    payload: {
       ...fieldsData
    }
}); 
export const submitBillAction = (data) => ({
    type: actionTypes.BILL_SUBMIT_DATA,
    payload: data
});
