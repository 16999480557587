import { VALIDATOR_DELEGATE_KEY_GROUP } from "../common/constant";

const delegate = (validator, key, value) => {
  const results = {};

  switch(key){
      case VALIDATOR_DELEGATE_KEY_GROUP.formFields.department:
        results.departmentMissing = false;

        if(validator.isValueMissing(value)) results.departmentMissing = true;
        break;
      
      case VALIDATOR_DELEGATE_KEY_GROUP.formFields.email:
        results.emailMissing = false;
        results.emailInvalid = false;

        if(validator.isValueMissing(value)) results.emailMissing = true
        if(!validator.isEmailValue(value)) results.emailInvalid = true;
        break;

      case VALIDATOR_DELEGATE_KEY_GROUP.formFields.firstName:
        results.firstNameMissing = false;
        results.firstNameInvalid = false;

        if(validator.isValueMissing(value)) results.firstNameMissing = true;
        if(!validator.isTextValue(value)) results.firstNameInvalid = true;
        break;
      
      case VALIDATOR_DELEGATE_KEY_GROUP.formFields.lastName:
        results.lastNameMissing = false;
        results.lastNameInvalid = false;

        if(validator.isValueMissing(value)) results.lastNameMissing = true;
        if(!validator.isTextValue(value)) results.lastNameInvalid = true;
        break;

      case VALIDATOR_DELEGATE_KEY_GROUP.formFields.middleName:
        results.middleNameInvalid = false;

        if(!validator.isValueMissing(value) && !validator.isTextValue(value)) results.middleNameInvalid = true;
        break;

      case VALIDATOR_DELEGATE_KEY_GROUP.formFields.phoneNumber:
        //results.phoneNumberMissing = false;
        results.phoneNumberInvalid = false;

        //if(validator.isValueMissing(value)) results.phoneNumberMissing = true;
        if(!validator.isValueMissing(value) && !validator.isPhonenumber(value)) results.phoneNumberInvalid = true;
        break;

      case VALIDATOR_DELEGATE_KEY_GROUP.formFields.dateOfBirth:
        results.dateOfBirthMissing = false;

        if(validator.isValueMissing(value)) results.dateOfBirthMissing = true;
        break;

      case VALIDATOR_DELEGATE_KEY_GROUP.formFields.hiredDate:
        results.hiredDateMissing = false;

        if(validator.isValueMissing(value)) results.hiredDateMissing = true;
        break;
      
      case VALIDATOR_DELEGATE_KEY_GROUP.formFields.trn:
        results.trnInvalid = false;

        if(!validator.isValueMissing(value) && !validator.isTrnNumber(value)) results.trnInvalid = true;
        break;

      case VALIDATOR_DELEGATE_KEY_GROUP.formFields.nis:
        results.nisInvalid = false;

        if(!validator.isValueMissing(value) && !validator.isNisNumber(value)) results.nisInvalid = true;
        break;

      case VALIDATOR_DELEGATE_KEY_GROUP.formFields.hourlyRate:
        results.hourlyRateInvalid = false;

        if(!validator.isValueMissing(value) && !validator.isNumberValue(value)) results.hourlyRateInvalid = true;
        break;

      case VALIDATOR_DELEGATE_KEY_GROUP.formFields.salary:
        results.salaryInvalid = false;

        if(!validator.isValueMissing(value) && !validator.isNumberValue(value)) results.salaryInvalid = true;
        break;

      case VALIDATOR_DELEGATE_KEY_GROUP.formFields.sickDays:
        results.sickDaysInvalid = false;

        if(!validator.isValueMissing(value) && !validator.isNumberValue(value)) results.sickDaysInvalid = true;
        break;

      case VALIDATOR_DELEGATE_KEY_GROUP.formFields.vacationDays:
        results.vacationDaysInvalid = false;

        if(!validator.isValueMissing(value) && !validator.isNumberValue(value)) results.vacationDaysInvalid = true;
        break;

      default:
          break;
  }

  return results;
}
export default delegate;