import { requests } from './agentDialect';

export const fetchAllClients = (clientType) => {
    let uri =  clientType === undefined ? "/clients" : `/clients?type=${clientType}`;
    return requests.get(uri);
};
export const fetchClient = (clientId) => {
    let uri = `/client/${clientId}`;
    return requests.get(uri);
};
export const submitClient = (data) => {
    let uri = "/client";
    return requests.formPost(uri, data.form);
};
export const updateClient = (data) => {
    let uri = `/client/update/${data.clientId}`;
    return requests.formPut(uri, data.form);
};
export const deleteClient = (data) => {
    let uri = `/client/delete/${data.client}`;
    return requests.delete(uri, data);
};