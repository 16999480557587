import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setupPage } from '../../components/Layout/setupPage';
import ActionBar from '../components/partials/ActionBar';
import {ResultSheetHead } from './../components/partials/ResultSheetHead';
import { ResultSheet } from '.';
import './Specimen.style.scss';
import * as actionTypes from '../../store/actionType';
import  * as actions  from '../../store/actions';
import * as urls from '../../config/urls';
import { STATUS, STATUS_MAP, ACTION_BAR_MODES } from './../../common/constant';
import DateTimePicker from 'react-datetime-picker';
import { ContextMenu, MenuItem,ContextMenuTrigger } from "react-contextmenu";
import { CloseButton } from '../components/partials/Buttons';
import {SpecimenSupervisor } from './../../utility/SpecimenSupervisor';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
import { PermissionEnforcer } from '../../utility/PermissionEnforcer';
import { FormValidator } from '../../utility/FormValidator';
import FileUploader from '../../utility/FileUploader';
import { Loader } from '../components/partials/LoadingIndicator';
import { Viewer } from '.';
import SlidingPane from "react-sliding-pane";
import RequisitionLinkModal from './Requisition/RequisitionLinkModal';

import { 
    NavLink,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
    Container,
    Row,
    Col,
    List,
    Authority,
    Section,
    InputField,
    SimpleSelect,
    ValidateField,
    ErrorFeedback,
    
} from '../../components';

import {elements} from '../../components/SimpleSelect';
import { ErrorMessage } from '../components/partials/ErrorMessage';
import { PromptMessage, getPromptMessageProps } from '../components/partials/PromptMessage';
import ConfirmModal from '../components/partials/ConfirmModal';
import BloodVial from './../components/partials/BloodVial';
import CellSlide from './../components/partials/CellSlide';
import { CreatePatientModal } from './../Patient';
import { REQUESTS_TYPE } from '../../common/constant';
import { getIconUrl } from '../../common/images-catalogue';
import { NotFound } from '../components/partials/NotFound';

const mapStateToProps = state => ({
    apiRequestManager: ApiRequestManager.getInstance(ApiRequestManager),
    permissionEnforcer: PermissionEnforcer.getInstance(PermissionEnforcer),
    specimenSupervisor: SpecimenSupervisor.getInstance(SpecimenSupervisor),
    validator: FormValidator.getInstance(FormValidator),
    syncInProgress: state.global.syncInProgress,
    useClientLayout: state.global.useClientDashboard,
    mode:  state.global.actionBarMode,
    workspace: state.global.workspace,
    account: state.global.account,
    ...state.specimen.samplesState,
});

const nameToInitial = (name)=> {
    const namesPart = name.split(' ');
    const initials = namesPart[0].substr(0,1)  + namesPart[1].substr(0,1);
    return initials;
}
const mapDispatchToProps = dispatch => ({
    onLoad: () => dispatch(actions.specimenUpdateRecordLoadedAction()),
    unLoad: () => dispatch(actions.specimenUpdateViewUnLoadAction()),
    setMode: (mode) => dispatch(actions.actionBarModeAction(mode)),
    getClientPatients: (workspace) => dispatch(actions.getClientPatientsAction(workspace)),
    getAllClients: () => dispatch(actions.getAllAccountsAction()),
    getPatientsFormDetails: (payload) => dispatch(actions.getPatientsFormDetailsAction(payload)),
    fieldDidChange: (payload) => dispatch(actions.specimenFormFieldChangeAction(payload)),
    submitData: (payload) => dispatch(actions.submitSpecimenAction(payload)),
    submitResultData: (payload) => dispatch(actions.submitResultSheet(payload)),
    setSpecimenFormData: (data) => dispatch(actions.setUpdateSpecimenDataAction(data)),
    setClientData: (client) => dispatch(actions.setClientDataAction(client)),
    setActiveView: (view) => dispatch(actions.specimenFormViewAction(view)),
    setActivePage: (page) => dispatch(actions.setSpecimenPageActiveView(page)),
    showRequisitionModal: (open) => dispatch(actions.showRequisitionModalAction(open)),
    setRequisitionItem: (data) => dispatch(actions.setRequisitionItemAction(data)),
    onResultSheet: (speciment) => dispatch(actions.specimenResultSheetAction(speciment)),
    changeStatus: (status) => dispatch(actions.specimenAddStatus(status)),
    attachFile: (payload) => dispatch(actions.specimenAttachFileAction(payload)),
    setSpecimenPatientLink: (value) => dispatch(actions.setSpecimenPatientLink(value)),
    editSampleState: (dataObject) => dispatch(actions.editSampleStateAction(dataObject)),
    fetchClientRequisition: (clientId) => dispatch(actions.getClientRequisitionsAction(clientId)),
    searchPatients: (payload) => dispatch(actions.searchPatientsAction(payload)),
});

function offset(el) {
    var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

export const Option = ({...props}) => {

    const {item } = props;
    const isPatient = item.registrationNo ? true : false;

    const picture = item.picture ? item.picture.fileDownloadUri : nameToInitial(item.label);
    const hasPicture = item.picture ? true : false;

    return (
    <elements.Option key={item.id} className="patient-option" {...props}>
        <div className="patient-opt">
            <div className="patinet-info">
                {hasPicture ? 
                    <div className="picture-content">
                        <img className="picture" src={picture} alt='' />
                    </div>
                    :
                    <span className="patient-picture">
                        { picture }
                    </span>
                }
                <label>{item.label}</label>
            </div>
            <div className="regist-no">
                { isPatient ? 
                 <p>Registration #:<span>{item.registrationNo}</span></p>
                 : 
                 <p>Acct #:<span>{item.accountNo}</span></p>
                }
            </div>
        </div>
    </elements.Option>
  )};

  export const Indicator = ({...props}) => {

    const svgIcon = <svg><path className="cls-1" d="M59,1.67H18.41V9H59Zm0,17H18.37v7.24H59ZM18.43,35.53v7.26H58.94V35.53ZM10.47,9V1.67H1.22V9Zm0,9.63H1.2v7.25h9.27ZM1.19,35.56v7.26h9.27V35.56Z"/><path className="cls-1" d="M59,1.67V9H18.41V1.67Z"/><path className="cls-1" d="M59,18.63v7.24H18.37V18.63Z"/><path className="cls-1" d="M18.43,35.53H58.94v7.26H18.43Z"/><path className="cls-1" d="M10.47,9H1.22V1.67h9.25Z"/><path className="cls-1" d="M10.47,18.66v7.25H1.2V18.66Z"/><path className="cls-1" d="M1.19,35.56h9.27v7.26H1.19Z"/></svg>;
    const icons = {
        normalIcon: svgIcon,
        focusIcon: svgIcon
    };
    return (
        <elements.Indicator className="ip-select-indicator" {...icons} {...props} />
    )};

    export const SelectedOption = ({...props}) => {

    const {selectedItem } = props;
    const picture = selectedItem.picture ? selectedItem.picture.fileDownloadUri : nameToInitial(selectedItem.label);
    const hasPicture = selectedItem.picture ? true : false;
  
    if (!selectedItem.registrationNo) {
        const {clientInfo} = selectedItem;

        const userName = clientInfo?.userName || selectedItem.userName;
        const type = clientInfo?.type || selectedItem.type;
        return (
            <elements.SelectedOption className="template-content">
        <div className="chosen-option" >
            <div className="option-template">
                <div className="template-content">
                    {hasPicture ? 
                        <div className="picture-content">
                            <img className="picture" src={picture} alt='' />
                        </div>
                        :
                        <span className="patient-picture">
                            { picture }
                        </span>
                    }
                    <div className="patient-info">
                        <p className="name">{ selectedItem.label }</p>
                        <p className="regist-no">Acc#:<span>{selectedItem.accountNo}</span></p>
                    </div>
                    <div  className="patient-details">
                        <p className="info">User:<span>{userName}</span></p>
                        <p className="info">Type:<span>{type}</span></p>
                    </div>
                </div>
            </div> 
        </div>
    </elements.SelectedOption>
        )
    } else {
        return (
        <elements.SelectedOption className="template-content">
            <div className="chosen-option" >
                <div className="option-template">
                    <div className="template-content">
                        {hasPicture ? 
                            <div className="picture-content">
                                <img className="picture" src={picture} alt='' />
                            </div>
                            :
                            <span className="patient-picture">
                                { picture }
                            </span>
                        }
                        <div className="patient-info">
                            <p className="name">{ selectedItem.label }</p>
                            <p className="regist-no">Registration#:<span>{selectedItem.registrationNo}</span></p>
                        </div>
                        <div  className="patient-details">
                            <p className="info">Gender:<span>{selectedItem.gender}</span></p>
                            <p className="info">Age:<span>{selectedItem.age}</span></p>
                        </div>
                    </div>
                </div> 
            </div>
        </elements.SelectedOption>
    )
    }
};

const FormChooser = (props) => {

    const infoText = {
        form: 'Choose the type of form you would like to create.'
    }

    const textRef = React.createRef();
    const showRoleInfo = (evt) => {
        textRef.current.innerHTML = infoText.form;
    }
    
    return (
        <Authority allowed="create" entity="record" redirectTo={urls.specimensUri } alternateView>
            <div className="role-chooser-wrap spmt-form">
            <PromptMessage visible={props.showPrompt} error message="Specimen Record was deleted." />
                <div className="role-chooser-content">
                    <h1 className="title">Choose Form</h1>
                    <div className="role-chooser">
                        <div className="inner">
                            <List className="roles-type-selection">
                                <li key={1} className="role-type">
                                    <Link to='' id="Gynec" onMouseEnter={showRoleInfo} onClick={props.callback} className="trigger">
                                        <div className="icon-holder">
                                            <span className="icon">
                                                <img src={getIconUrl('gyncForm')} alt="" />
                                            </span>
                                        </div>
                                        <label>Gynecology</label>
                                    </Link>
                                </li>
                                
                                <React.Fragment>
                                <li key={2}  ><strong className="or-text">OR</strong></li>
                                <li key={3} className="role-type">
                                    <Link to='' id="NonGynec" onMouseEnter={showRoleInfo} onClick={props.callback} className="trigger">
                                        <div className="icon-holder">
                                            <span className="icon">
                                                <img src={getIconUrl('nonGyncForm')} alt="" />
                                            </span>
                                        </div>
                                        <label>Non Gynecology</label>
                                    </Link>
                                </li>
                                </React.Fragment>
                                
                            </List>
                            <div className="info-well">
                                <p ref={textRef} className="info-text">{ infoText.form }</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Authority>
    );
}

const SimpleContextMenu = (props) => {
    const {contextMenuOptClick, specimenSupervisor} = props;

    return (
        <React.Fragment>
            <MenuItem disabled={!specimenSupervisor.showClientsResultSheet()} 
            data={{opt: 'resultSheet'}} onClick={contextMenuOptClick}>
                Result Sheet
            </MenuItem>
        </React.Fragment>
    );
}

class CreateUpdate extends React.Component {

    static pageOption = { 
        pageTitle: 'Create Sample',
        searchContext: 'Record',
        disableBodyScroll: true
    };

    specimenType = {
        EA: 'ENDOCERV.ASP',
        VP: 'VAG.POOL',
        CS: 'CERV.SCRAP'
    }

    formType = {
        Gynec: 'Gynecology',
        NonGynec: 'NonGynecology'
    }

    constructor(props) {
        super(props);

        props.specimenSupervisor.clientDashboard = this.props.useClientLayout;
        props.apiRequestManager.register(actionTypes.FETCH_FORM_DETAILS, props.getPatientsFormDetails);

        props.apiRequestManager.register(actionTypes.SPECIMEN_SUBMIT_DATA, props.submitData);
        props.apiRequestManager.register(actionTypes.RESULT_SHEET_SUBMIT, props.submitResultData);

        props.apiRequestManager.register(actionTypes.FETCH_ACCOUNTS, props.getAllClients);
        props.apiRequestManager.register(actionTypes.SPECIMEN_ATTACH_FILE, props.attachFile);

        props.apiRequestManager.register(actionTypes.FETCH_CLIENT_REQUISITIONS, props.fetchClientRequisition);
        props.apiRequestManager.register(actionTypes.FETCH_SEARCH_PATIENTS, props.searchPatients);
        
        this.state = {
            hasPrivilege: props.permissionEnforcer.hasRights(['view'], 'record'),
            activeView: 'chooserView',
            formType: null,
            selectedOption: null,
            date: new Date(),
            vialsamples: [],
            selectedSpeciment: null,
            currentIndex: 0,
            isPaneOpen: false,
            showViewer: false,
            formErrors: {},
            showCreateModal: false,
            deleteDialogVisible: false,
            statusDialogVisible: false,
            deleleContextItem: null,
            backToRequisitionClicked: null,
            backToPatientClicked: null,
            showLinkToReqModal: false,
        }

        this.datePicker = React.createRef();
        this.clientSelect = React.createRef();
        this.patientSelect = React.createRef();
        this.urgentCheckbox = React.createRef();
        this.fileInputField = React.createRef();

        this.onAddSpecimen = this.onAddSpecimen.bind(this);
        this.onRemoveSample = this.onRemoveSample.bind(this);
        this.onSelectSample = this.onSelectSample.bind(this);
        this.onChangeBloodType = this.onChangeBloodType.bind(this);
        this.contextMenuOptClick = this.contextMenuOptClick.bind(this);

        this.specimenTypeChange = this.specimenTypeChange.bind(this);
        this.willSelectFormType = this.willSelectFormType.bind(this);
        this.featureChange = this.featureChange.bind(this);
        this.therapyFieldChange = this.therapyFieldChange.bind(this);

        this.textFieldChange = this.textFieldChange.bind(this);
        this.submitDataPayloadComposer = this.submitDataPayloadComposer.bind(this);
        this.submitFormData = this.submitFormData.bind(this);
        this.submitReqLinkRequest = this.submitReqLinkRequest.bind(this);
        this.submitToLab = this.submitToLab.bind(this);
        this.approveResultSheet = this.approveResultSheet.bind(this);

        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.showHideView = this.showHideView.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.hideRequisitionLinkModal = this.hideRequisitionLinkModal.bind(this);
        this.showRequisitionLinkModal = this.showRequisitionLinkModal.bind(this);
        this.preSelectNewPatient = this.preSelectNewPatient.bind(this);

        this.handleDeleteSpecimen = this.handleDeleteSpecimen.bind(this);
        this.submitDeleteSpecimen = this.submitDeleteSpecimen.bind(this);
        this.onConfirmDelete = this.onConfirmDelete.bind(this);
        this.onConfirmStatus = this.onConfirmStatus.bind(this);
        this.showHideDeleteDialog = this.showHideDeleteDialog.bind(this);
        this.showHideConfimStatusDialog = this.showHideConfimStatusDialog.bind(this);
        this.showFormSetting = this.showFormSetting.bind(this);
        this.initForm = this.initForm.bind(this);

        this.prepareSelectedClient = this.prepareSelectedClient.bind(this);
        this.handlePatientSearch = this.handlePatientSearch.bind(this);

        !Object.blank(this.props.specimenRecord) && this.props.setActiveView( 'formView');
    }

    componentDidMount() {
        
        this.props.onLoad();
        
        !this.props.apiRequestManager.inProgress(actionTypes.FETCH_SPECIMEN_RECORD) && Object.blank(this.props.specimenRecord)  &&  this.props.setActiveView('chooserView');

        if (this.props.mode === ACTION_BAR_MODES.edit && !Object.blank(this.props.specimenRecord)) {
            this.initForm(this.props.specimenRecord);
            this.props.editSampleState({syncedForm: true});
        }else {
            //this.props.setMode('');
            this.setState({
                formType: null,
                reIniteView: false,
                backToRequisitionClicked: false,
                backToPatientClicked: false,
            });
        }

        this.unlistenAbort = this.props.history.listen(() => { 
            this.props.apiRequestManager.abortRequest(actionTypes.FETCH_ACCOUNTS);
            this.props.apiRequestManager.abortRequest(actionTypes.FETCH_PATIENTS);
            this.props.apiRequestManager.abortRequest(actionTypes.FETCH_REQUISITION_SPECIMENS);
        });

    }
    
    componentWillUnmount() {
        this.props.specimenSupervisor.retire();
        /**
         * If we are unmount for any reason other than to go back to the requisition item.
         */
        if(this.state.backToRequisitionClicked === false){
            this.props.setRequisitionItem(null);
        }

        if(this.state.backToPatientClicked === false){
            this.props.setSpecimenPatientLink(false)
        }
        this.unlistenAbort();
        this.props.unLoad();
    }

    static getDerivedStateFromProps(props, state) {
        // Return null to indicate no change to state.
        if (!props.useClientLayout && !props.clientsList && !props.apiRequestManager.inProgress(actionTypes.FETCH_ACCOUNTS)) {
            props.apiRequestManager.queueRequest(actionTypes.FETCH_ACCOUNTS)
        }
        
        if (state.hasPrivilege && state.formType && props.formDetails == null && !props.apiRequestManager.inProgress(actionTypes.FETCH_FORM_DETAILS)) {
            
            props.useClientLayout && props.workspace ? props.apiRequestManager.queueRequest(actionTypes.FETCH_FORM_DETAILS,
                {
                    workspace: props.workspace.id,
                    form: state.formType
                }) : props.apiRequestManager.queueRequest(actionTypes.FETCH_FORM_DETAILS, {
                    form: state.formType
                });
        }

        let apiOutcome =   props.apiRequestManager.getApiOutcome(actionTypes.SPECIMEN_SUBMIT_DATA) ?? {};

        if (apiOutcome?.requestType === REQUESTS_TYPE.DELETE && apiOutcome.success) {
            props.setMode('');
            props.setActiveView('chooserView');
            return {
                vialsamples: [],
                selectedSpeciment: null
            }
        }

        if (!Object.blank(props.specimenRecord) && props.mode !== 'edit') {
            props.setMode('edit');
            return {
                populatedForm: false
            }
        }

        return null;
    }

    shouldComponentUpdate(props, state) {

        if(!Object.blank(props.specimenRecord) && props.activeView !== 'formView'){
            this.props.setActiveView('formView');
            this.props.setMode('edit');
        }

        /**
         * Updates the form if it is out of sync with specimen data in redux store..
         */
        if (!props.syncedForm && !Object.blank(props.specimenRecord) && Object.blank(props.requisitionLinkItem)) {
            this.initForm(props.specimenRecord);
            this.props.editSampleState({syncedForm: true});
        }
        
        /**
         * If we navigated to this view in order to create a specimen for a requisiton line item.
         */
        if (!Object.blank(props.requisitionLinkItem) && props.activeView !== 'formView') {
            props.setClientData({account: props.requisitionLinkItem.client});
            this.props.setActiveView('formView');
            this.props.setMode('create');

            this.setState({
                formType: props.requisitionLinkItem.formType,
            });
        }

        /**
         * If we navigated to this current view in order to view/edit a specimen associated with a requisiton line item.
         */
        if(!Object.blank(props.specimenRecord) && !Object.blank(props.requisitionLinkItem) && !props.syncedForm && !Object.blank(props.formDetails)){
            this.initForm(props.specimenRecord);
            this.props.editSampleState({
                syncedForm: true
            });
        }

        return true;

    }
    
    featureChange = (evt) => {
        const featureData = this.composeClinicalItem(evt.target.getAttribute('data_id'), evt.target)

        let fieldset = {};
        fieldset[featureData.label] = featureData;
        
        this.props.fieldDidChange({clinicalFeatures: fieldset});
    };

    composeClinicalItem(clinicalItemId, field) {
        
        const clinicalFeatures = this.props.specimenSupervisor.specimenPresent() ? this.props.specimenSupervisor.specimen.clinicalFeatures : this.props.formDetails.clinicalFeatures;

        const shouldUpdatePrintFields = this.props.specimenSupervisor.canUpdatePrintFields();

        let {printGroupId, ...clinicalItem} = clinicalFeatures.find((feature) => parseInt(feature.id) ===  parseInt(clinicalItemId) );
        
        let printFieldUpdates = {
            isPrintable: clinicalItem?.isPrintable || false,
            printGroup: printGroupId || clinicalItem?.printGroup
        };
        
        if(shouldUpdatePrintFields) {
           const formClinicalFeature = this.props.formDetails.clinicalFeatures.find((feature) => feature.label === clinicalItem.label);
            if (formClinicalFeature)
                printFieldUpdates = {
                    isPrintable: formClinicalFeature?.isPrintable || false,
                    printGroup:  formClinicalFeature?.printGroup || null,
                    printGroupName: formClinicalFeature?.printGroupName || null
                }
        }

        clinicalItem = {
            ...clinicalItem,
            ...printFieldUpdates
        } 
         
        //cliet 
        switch(field.type) {
            case 'number' :
            case 'text':
                clinicalItem.value = field.value;
                break;
            default:
                clinicalItem.value = field.checked;
        }

        return clinicalItem;
    }

    therapyFieldChange = (evt) => {
        //this.setState({ selectedOption });
       let fieldset = {};
       fieldset[evt.target.id] = evt.target.value;
        this.props.fieldDidChange({therapy: fieldset});
    };

    textFieldChange = (evt) => {
        let fieldset = {};
        const fieldName = evt.target.name;
        fieldset[fieldName] = evt.target.value;
        this.props.fieldDidChange(fieldset);

        fieldName === 'doctor' && this.setState({
            formErrors: {
                ...this.state.formErrors,
                doctorMissing: false
            }
        });
    };

    dateChange = (date, evt) => {

        const vials = this.state.vialsamples.map((item , i) => {
            if(i === this.state.currentIndex) {
                item.date = date.toISOString();
            }
            return item;
        });
        
        this.props.fieldDidChange({vialsamples: vials});

        this.setState({
            date: date,
            vialsamples: vials
        });
    };

    onAddSpecimen(evt) {
        const vial = {
            id: this.state.vialsamples?.length,
            specimenLabel: this.state.selectedSpeciment,
            bloodGroup: '',
            date: this.datePicker.current.props.value.toISOString(),
            vialColour: Math.floor(Math.random() * 6)
        };

        const vials = this.state.vialsamples;
        this.state.selectedSpeciment && vials.unshift(vial);
        this.props.fieldDidChange({vialsamples: vials});
        this.state.selectedSpeciment && this.setState({vialsamples: vials, formErrors: {
            ...this.state.formErrors, 
            vialsamplesEmpty: false
        }});
    }

    onRemoveSample(evt) {
        const id = parseInt(evt.target.id);
        let selectId = 0;
        
        const filteredlist = this.state.vialsamples.filter((item, i) => {
            if (item.id !== id) {
                return item;
            }else {
                selectId = i - 1;
            }
        });
       
        selectId = selectId === -1 ? selectId + 1 : selectId;
        let newState = {vialsamples: filteredlist};

        if (filteredlist?.length > 0)  {
            const selectSample = filteredlist[selectId];
            newState = {
                ...newState,
                selectedSpeciment: selectSample.specimenLabel, 
                currentIndex: selectId,
                date: new Date(selectSample.dateReceived || selectSample.date)
            }
        }

        this.setState(newState);
        this.props.fieldDidChange({vialsamples: filteredlist});
    }

    onSelectSample(evt, label, index) {
        const sample = this.state.vialsamples.filter((item, i) => i === index);
        this.setState({
            selectedSpeciment: label, 
            currentIndex: index,
            date: new Date(sample[0].dateReceived || sample[0].date)
            }
        );
    }
    
    onChangeBloodType(value, index) {
        
        const vials = this.state.vialsamples.map((item , i) => {
            if(i === index) {
                item.bloodGroup = value;
            }
            return item;
        });
        this.props.fieldDidChange({vialsamples: vials});
        this.setState({vialsamples: vials}); 
    }

    renderClinicalFeature = (feature, formData) => {
        
        const disable = this.props.specimenSupervisor.isEditable(this.props.workspace);
        const yesOrNoField = (item) => {
            
            const label =  item.label;
            const checked = formData.clinicalFeatures && formData.clinicalFeatures[label]?.value;
            
            return   (<FormGroup key={item.id} className="feature-grp yrn">
                    <span className="label">{label}</span>
                    <label  className="ip-checkbox">
                        <Input disabled={!disable} checked={checked} onChange={this.featureChange} type="checkbox" name={"clinicalFeatures[]"} data_id={item.id} />
                        <span className="checkmark round"></span>
                    </label>
                </FormGroup>)};

        const textField = (item) => {

           const label =  item.label;
           const value = formData.clinicalFeatures && formData.clinicalFeatures[label]?.value;
         
           return (<FormGroup key={item.id} className="feature-grp txt">
                <span className="label">{label}</span>                                   
                <InputField disabled={!disable} value={value} onChange={this.featureChange} id={label} type={item.dataType} name={"clinicalFeatures[]"} data_id={item.id} />     
            </FormGroup>)};
        
        switch(feature.dataType) {
            case 'bool':
                return yesOrNoField(feature);
            default:
                return textField(feature);
        }
    }

    didSelectOption = (patient) => {
        this.props.fieldDidChange({patient: patient});
        this.setState({formErrors: {...this.state.formErrors, patientMissing: false }});
    };

    didSelectClientOption = (data) => {
        this.props.setClientData({account: data});
        this.setState({formErrors: {...this.state.formErrors, ownerMissing: false }});
    };

    specimenTypeChange(evt) {
        
        const vials = this.state.vialsamples.map((item , i) => {
            if(i === this.state.currentIndex) {
                item.specimenLabel = evt.target.value;
            }
            return item;
        });
        
        this.props.fieldDidChange({vialsamples: vials});
        this.setState({
            selectedSpeciment: evt.target.value,
            vialsamples: vials,
            formErrors: {
                ...this.state.formErrors,
                vialsamplesEmpty: false
            }
        }, () => {
            !this.state.vialsamples?.length && this.onAddSpecimen(evt);
        });

    }

    willSelectFormType =(evt) => {
        evt.preventDefault();
        this.props.setMode('create');

        this.setState({
            formType : this.formType[evt.currentTarget.id],
        }, () => {
            this.props.setActiveView('formView');
        });
        
    }

    backToFormChooder = (evt) => {
        
        this.props.setMode('');
        this.props.unLoad();

        if ( this.props.mode === 'edit' && !this.props.requisitionLinkItem && !this.props.patientLink) {
            this.props.history.push({ pathname: urls.specimensUri});
        } else if (this.props.requisitionLinkItem) {
            this.props.setActivePage('requisition');
            this.props.showRequisitionModal(true);
            this.setState({backToRequisitionClicked: true});

            /**
             * need to delay the change in url so the local state can be updated.
             * This is to determine whether or not to clear reqLinkItem.
             */
            setTimeout(() => this.props.history.push({ pathname: urls.specimensUri}),0);
            
        } else if(this.props.patientLink){
            this.setState({backToPatientClicked: true});

            setTimeout(() => this.props.history.push({ pathname: urls.patientOverviewUri}),0);
        } else {
            this.setState({
                formType : null,
                date: new Date(),
                formErrors: {},
                vialsamples: [],
                selectedSpeciment: null,
                currentIndex: 0,
            });

            this.props.setActiveView('chooserView')
        }
    }

    handleFileFieldClick(evt) {
        this.fileInputField.current.click();
    }

    handleFileUpload(evt) {
        //const field = this.fileInputField.current.files; //evt.target;
        let files = this.fileInputField.current.files;
        if(files.length === 0) {
            return;
        }
        let file = files[0];
        //uploadSingleFile(files[0]);
        const uploader = FileUploader.getInstance(FileUploader);
        let payload = uploader.composeFormPayload(file, 'result_sheet');
        payload.append('specimen', this.props.specimenRecord.id);

        this.props.apiRequestManager.queueRequest(actionTypes.SPECIMEN_ATTACH_FILE, payload);
        //uploader.upload(file, 'result_sheet');
        evt.preventDefault();
    }

    /*Context Menu Action*/

    handlerResultSheetAction(evt, specimen) {
        this.props.onResultSheet({specimen: specimen, resultSheet: true });
        this.setState({isPaneOpen: true});
        //this.props.history.push({pathname: urls.specimensResultSheetUri});
    }

    handlerChangeStatusAction(specimen) {
        const validStatuses = STATUS_MAP[specimen.statuses.last().status];
        const payload = {
            requestType: 'statusChange',
            status: validStatuses[0],
            recordId: this.props.specimenRecord.id
        }
        this.props.changeStatus(payload);
    }  

    contextMenuOptClick(evt, data) {
        const speciment = this.props.specimenRecord;
        
        switch(data.opt){
            case 'changeStatus':
                if (!this.props.permissionEnforcer.hasRights('change', 'recordstatus'))
                    return
                this.showHideConfimStatusDialog()
                break;
            case 'resultSheet':
                if (!this.props.permissionEnforcer.hasRights('view', 'resultsheet'))
                    return
                this.handlerResultSheetAction(evt, speciment);
                break;
            case 'addFile':
                if (!this.props.permissionEnforcer.hasRights('change', 'record'))
                    return
                return this.handleFileFieldClick(evt);
            case 'authorize':
                if (!this.props.permissionEnforcer.hasRights('authorize', 'resultsheet'))
                    return
                this.approveResultSheet();
                break
            case 'linkToReq':
                this.showRequisitionLinkModal();
                break;
            default:   
        }
    }

    initForm(specimenRecord) {
        const formData = this.composeFormData(specimenRecord);
        this.props.setSpecimenFormData(formData);
        this.props.setClientData({account: specimenRecord.client});

        this.setState({
            formType: specimenRecord.formType,
            vialsamples: formData.vialsamples || [],
            selectedSpeciment: Array.isArray(formData.vialsamples) ? formData.vialsamples[0]?.specimenLabel : '',
            date: !Array.isArray(formData.vialsamples) ? new Date(formData.vialsamples[0].dateReceived) : new Date(),
        }); 
    }

    /* ------ Context Menu Action ------ */
    composeFormData(specimen) {
        
        let formData =  {
            account: this.composeClientSelectOption(specimen.client),
            patient: this.composePatientSelectOption(specimen.patient),
            vialsamples: specimen.specimens,
            clinicalFeatures: {},
            therapy: specimen.therapy ? specimen.therapy : {},
            labNumber: specimen.labNumber,
            doctor: specimen.doctor,
            otherClinicalInfo: specimen.otherClinicalInfo,
            specimen: specimen
        };

        const shouldUpdatePrintFields = this.props.specimenSupervisor.canUpdatePrintFields();

        const updatedClinicalFeatures = specimen.clinicalFeatures?.map(item => {
            let printFieldUpdates = {};
            const featureItem = this.props.formDetails?.clinicalFeatures ? this.props.formDetails.clinicalFeatures.find(feature => parseInt(feature.id) === parseInt(item.id)) : {};

            if(shouldUpdatePrintFields && featureItem) {
                printFieldUpdates = {
                    isPrintable: featureItem.isPrintable,
                    printGroup: featureItem.printGroup,
                    printGroupName: featureItem.printGroupName
                }
            }

            item = {
                ...item,
                value: item.dataType === 'bool' ? this.yesOrNoToBoolean(item.value) : item.value,
                ...printFieldUpdates
            } 
            
            formData.clinicalFeatures[item.label] = item;

            return item;
        }) ?? [];

        if(shouldUpdatePrintFields) formData.specimen.clinicalFeatures = updatedClinicalFeatures;
        
        return formData
    }

    yesOrNoToBoolean(value) {
        return value === 'YES' ? true : false;
    }

    preSelectNewPatient(patientData) {
        const patient = this.composePatientSelectOption(patientData);
        this.props.fieldDidChange({patient: patient});
    }

    submitToLab(evt) {
        evt.preventDefault();
        
        const formPayload = {
            requestType: 'submit',
            recordId: this.props.specimenRecord.id,
            hasUrgent: this.urgentCheckbox.current.checked
        }
        this.props.apiRequestManager.queueRequest(actionTypes.SPECIMEN_SUBMIT_DATA, formPayload);
    }

    submitDataPayloadComposer(reqItemData){
        let formPayload = {};

        const featuresList = document.querySelectorAll('[name="clinicalFeatures[]"]');
        const therapyFields = document.querySelectorAll('[name="therapy[]"]');
        
        let clinicalFeaturesSet = {};
        let therapyFieldsSet = {};
        let {patient, account, ...specimenForm } = this.props.specimenForm;
        
        account = this.props.useClientLayout ?  this.props.account : account;
   
        Array.from(featuresList).forEach((field) => {
            const featureData = this.composeClinicalItem(field.getAttribute('data_id'), field)
            clinicalFeaturesSet[featureData.label] = featureData;
        });

        Array.from(therapyFields).forEach((field) => {
            therapyFieldsSet[`${field.id}`] = field.value;
        });
        
        formPayload = {
            formType: this.state.formType,
            clientCompose: this.props.useClientLayout,
            patient: patient ? patient.id : null,
            ...specimenForm,
            labNumber: specimenForm.labNumber.toUpperCase()
        }
        formPayload.clinicalFeatures = {
            ...clinicalFeaturesSet
        }

        if ( !therapyFieldsSet.empty() ) {
            formPayload.therapy = {
                ...therapyFieldsSet
            }
        }else {
            const {therapy, ...formPayloadWithoutTherapy} = formPayload;
            formPayload = formPayloadWithoutTherapy;
        }

        formPayload['owner'] = account.owner || account.userName;

        //add req item to link the record to a requisition
        if(!Object.blank(reqItemData)){
            formPayload.requisitionItem = reqItemData.id;
        }

        return formPayload;
    }

    submitReqLinkRequest(reqItemData){
        let formPayload = this.submitDataPayloadComposer(reqItemData);
        const formErrors = this.validateFields(formPayload);

        if (Object.blank(formErrors) ) {
            if (formPayload.patient && (formPayload.vialsamples && !formPayload.vialsamples.empty()) ) {
                formPayload = {
                    ...formPayload,
                    requestType: 'update',
                    recordId: this.props.specimenRecord.id,
                    patient: formPayload.patient
                }
                this.props.apiRequestManager.queueRequest(actionTypes.SPECIMEN_SUBMIT_DATA, formPayload);
            } 
        }else {
            this.setState({
                formErrors: formErrors
            })
        }
    }

    submitFormData(evt) {
        const { requisitionLinkItem } = this.props;
        let formPayload = this.submitDataPayloadComposer();
        const formErrors = this.validateFields(formPayload);

        if (Object.blank(formErrors) ) {
            if (evt.action === 'save' && formPayload.patient && 
            (formPayload.vialsamples && !formPayload.vialsamples.empty()) ) {

                if (this.props.mode === 'create') {
                    formPayload['requestType'] = 'create';
                    if (requisitionLinkItem) {
                        formPayload['requisitionItem'] = requisitionLinkItem.id
                    }
                    this.props.apiRequestManager.queueRequest(actionTypes.SPECIMEN_SUBMIT_DATA, formPayload);
                }else {
                    formPayload = {
                        ...formPayload,
                        requestType: 'update',
                        recordId: this.props.specimenRecord.id,
                        patient: formPayload.patient
                    }
                    this.props.apiRequestManager.queueRequest(actionTypes.SPECIMEN_SUBMIT_DATA, formPayload);
                }
            } 
        }else {
            this.setState({
                formErrors: formErrors
            })
        }
    }

    submitDeleteSpecimen(specimen) {
        const formPayload  = {
            requestType: 'delete',
            recordId: specimen.id
        };
        
        if (formPayload.recordId) {
            this.props.apiRequestManager.queueRequest(actionTypes.SPECIMEN_SUBMIT_DATA, formPayload);
        }
    }

    validateFields(formData) {
        var validations = {};
        
        if (this.props.validator.isEmptyValue(formData.vialsamples)) {
            validations.vialsamplesEmpty = true;
        }
        if (this.props.validator.isValueMissing(formData.owner)) {
            validations.ownerMissing = true;
        }
        if (this.props.validator.isValueMissing(formData.patient)) {
            validations.patientMissing = true;
        }
        if (this.props.validator.isValueMissing(formData.doctor) || this.props.validator.isNumberValue(formData.doctor) ) {
            validations.doctorMissing = true;
        }
        return  validations;  
    }

    composePatientSelectOption = (item) => {
        let optionItem = {
            ...item,
            label: `${item?.firstName} ${item?.lastName}`,
            value: item?.id
        } 
        return optionItem; 
    }

    composeClientSelectOption(client) {
       
        const  clientInfo  = client?.clientInfo || client?.clientInfo !== undefined ? client.clientInfo : client || {}
        
        return {
            ...clientInfo,
            id: clientInfo?.id,
            label: `${clientInfo?.firstName} ${clientInfo?.lastName}`,
            value: clientInfo?.accountNo,
            firstName: clientInfo?.firstName,
            lastName:  clientInfo?.lastName
        }
    }

    getInitialSelectedClient(account, clientsList) {
        //const client = clientsList.find((item) => item.clientInfo.id === account.id);
        return this.composeClientSelectOption(account);
    }

    prepareSelectedClient(){
        const account = this.props.specimenForm.account;

        if(account !== undefined){
            //if it was already composed
            if(account.label) return account;

            return this.composeClientSelectOption(account);
        }
        //account hasn't been added to specimen form yet.
        return account;
    }

    showHideView() {
        this.setState({showViewer: !this.state.showViewer });
    }

    showModal() {
        this.setState({showCreateModal: true});
    }

    hideModal() {
        this.setState({showCreateModal: false});
    }

    showRequisitionLinkModal(){
        this.setState({showLinkToReqModal: true});
    }

    hideRequisitionLinkModal(){
        this.setState({ showLinkToReqModal: false });
    }

    handleDeleteSpecimen() {
        const contextItem = {
            data: this.props.specimenRecord
        }
        this.showHideDeleteDialog(contextItem);
    }

    onConfirmDelete(contextItem) {
        this.submitDeleteSpecimen(contextItem.data);
        this.showHideDeleteDialog();
    }

    showHideDeleteDialog(contextItem) {
        this.setState({
            deleteDialogVisible: !this.state.deleteDialogVisible,
            deleleContextItem: contextItem || null
        });
    }

    showHideConfimStatusDialog(contextItem) {
        this.setState({
            statusDialogVisible: !this.state.statusDialogVisible,
        });
    }

    approveResultSheet() {
        this.props.specimenSupervisor.approveResultSheet(this.props.apiRequestManager, actionTypes.RESULT_SHEET_SUBMIT);
    }

    onConfirmStatus(contextItem) {
        const speciment = this.props.specimenRecord;
        this.handlerChangeStatusAction(speciment);
        this.showHideConfimStatusDialog();
    }

    getDialogMessage() {
        const apiOutcome =   this.props.apiRequestManager.getApiOutcome(actionTypes.SPECIMEN_SUBMIT_DATA, true) ?? {};
        
        return getPromptMessageProps('specimen', apiOutcome);
    }

    showFormSetting() {
        this.props.history.push({ pathname: urls.formSettingUri});
    }

    handlePatientSearch(searchTerm) {
        const requestParams = {
            searchTerm,
            workspaceId: this.props.useClientLayout && this.props.workspace ? this.props.workspace.id : null
        }
        this.props.apiRequestManager.queueRequest(actionTypes.FETCH_SEARCH_PATIENTS, requestParams);
    }

    render () {
        
        const specimenSupervisor = this.props.specimenSupervisor;
        specimenSupervisor.specimen = this.props.specimenRecord;
        const fileHandler = FileUploader.getInstance(FileUploader);
        
        const saveSufixText = '';
        //Action Bar mode
        var actionBarMode = this.props.mode;
        const allowedPermissions = ['create', 'change'];

        if (this.props.mode === ACTION_BAR_MODES.edit) {
            actionBarMode = specimenSupervisor.isEditable(this.props.workspace) ? ACTION_BAR_MODES.edit : ACTION_BAR_MODES.none;
        }

        const actionBarCallback = { 
            'back': this.backToFormChooder,
            'save': this.submitFormData,
            'delete': this.handleDeleteSpecimen,
        };
        
        const contextMenuOptClick = this.contextMenuOptClick;
        const patients = Array.isArray(this.props.patientsList)  ?  this.props.patientsList.map(this.composePatientSelectOption) : [] ;

        const clients = !Array.isArray(this.props.clientsList) ? [] : this.props.clientsList.map((client) => {
            return this.composeClientSelectOption(client);
        });
        
        var clinicalFeatures =  (actionBarMode === 'create' && this.props.formDetails && this.props.formDetails.clinicalFeatures)  ? this.props.formDetails.clinicalFeatures: [] ;

        clinicalFeatures =  !Object.blank(this.props.specimenRecord) ?   this.props.specimenRecord.clinicalFeatures : clinicalFeatures;  

        //Therapy data value
        const therapyData = this.props.specimenForm.therapy ? 
        this.props.specimenForm.therapy:  {
            hormone: '',
            surgical: '',
            radiation: '',
            other: ''
        } ;

        const specimenTypes = this.props.formDetails?.specimenTypes  ? this.props.formDetails.specimenTypes: [];
        
        const showSubmitBar = this.props.specimenRecord && specimenSupervisor.hasStatus() && specimenSupervisor.getStatus() && specimenSupervisor.getStatus() === STATUS.Pending ? true : false;
   
        const isEditMode  =  this.props.mode === 'edit' && this.props.specimenRecord && !this.props.specimenRecord.empty() ? true : false;

        const status = specimenSupervisor.hasStatus() ? this.props.specimenRecord.statuses[this.props.specimenRecord.statuses?.length -1].status : null;
        const statusClassname = status && `--${status.toLowerCase()}`;

        //When item is available
        let initialSelectedPatient = this.props.specimenForm.patient ? this.props.specimenForm.patient : null;

        //
        var initialSelectedClient = this.prepareSelectedClient();
        if (!this.props.useClientLayout && this.props.specimenRecord && !this.props.specimenRecord.empty() && !initialSelectedClient) {
            initialSelectedClient = this.getInitialSelectedClient(this.props.specimenRecord.client, clients);
        }
      
        if (this.props.requisitionLinkItem) {
            initialSelectedClient = this.composeClientSelectOption(this.props.requisitionLinkItem.client);
        }

        const vialsamplesList = this.props.specimenForm.vialsamples ? this.props.specimenForm.vialsamples :  this.state.vialsamples;
        const showMinimalContextMenu = specimenSupervisor.showMinimalContextMenu(); 
        ///<PromptMessage visible success message="some message to display" />

        const disableClientSelect = this.props.requisitionLinkItem ? false : specimenSupervisor.isEditable(this.props.workspace);

        const preSelectUrgent = this.props.specimenRecord && this.props.specimenRecord.requisitionItem && this.props.specimenRecord.requisitionItem.urgent;

        const showRequisitionLinkIcon = this.props.specimenRecord && (this.props.specimenRecord.requisitionItem || this.props.requisitionLinkItem);

        const dialogProps = this.getDialogMessage();
        const foundClinicalFeatures = this.props.mode === 'create' && this.props.formDetails?.clinicalFeatures?.empty() ? false : true;
        
        const isFetchingSpecimens = this.props.apiRequestManager.inProgress();

        const clientId = this.props.specimenRecord?.client?.id;
       
        return (
            
            <React.Fragment>    
                <ActionBar title={ "Record" } saveTextSufix={ saveSufixText } entityContext={'record'} actionResponsers={ actionBarCallback } permissionContext={ actionBarMode } deleteButton={true} />

                {  this.props.activeView === 'chooserView' && !isEditMode ? <FormChooser  callback={this.willSelectFormType} showPrompt={dialogProps.info ? dialogProps.visible : false } /> : 
                <Authority rights={ allowedPermissions } entity="record" alternateView>
                <div className='content-layout-wrap'>
                    { dialogProps.visible && 
                        <PromptMessage {...dialogProps} />
                    } 
                    <div className="view-layout-content --with-act-bar">
                        <div className="default-Ly-ct sptm-cre">
                            <Container>
                                <ContextMenuTrigger id="specimen_record_context_menu">
                                
                                { !specimenSupervisor.showUrgentBadge()? null :
                                <div className="urgent-bar">
                                    <span className="urgent-badge">Urgent</span>
                                </div>
                                }
                                
                                <Authority allowed="submit" entity="record">
                                { !showSubmitBar ? <div></div> :    
                                <div className="form-submit-bar">
                                    <div className="inner">
                                        <div className="submit-buttom-holder">
                                        <FormGroup>
                                            <label className="ip-checkbox">Submit has Urgent?
                                                <input 
                                                ref={this.urgentCheckbox} type="checkbox"
                                                checked={preSelectUrgent}
                                                onChange={() => {}} />
                                                <span className="checkmark round"></span>
                                            </label>
                                        </FormGroup>
                                            <Button onClick={this.submitToLab} className="submit-btn">
                                                <span className="icon">
                                                    <img src={getIconUrl("submit")} />
                                                </span>
                                                Submit to Cytolab
                                            </Button>
                                        </div>
                                        <FormText>
                                            Additonal cost will apply for express results?
                                        </FormText>
                                    </div>
                                </div>
                                }
                                </Authority>
                                
                                <Row>
                                    <Col sm={12} md={12}>
                                        <div className="sptm-cre-frm-lay">
                                        
                                            <div className="sptm-frm-cont">
                                                <div className="inner-cont">
                                                <div className="title-bar">
                                                    <h1 className="title tool-tip">{ this.formType.Gynec === this.state.formType ? this.formType.Gynec : 'Non Gynecology'}

                                                    { showRequisitionLinkIcon && 
                                                    <span className="requisition-link">
                                                        <img src={getIconUrl('link')} alt=''/>
                                                    </span>
                                                    }

                                                    { !status ? null : 
                                                        <span className={`status-indicator ${statusClassname}`}>{ status}</span>
                                                    }
                                                    { (status && status === STATUS.Resulted) &&
                                                        <span className='await-approval'>
                                                            Awaiting Approval
                                                        </span>
                                                    }
                                                    { status &&
                                                    <span className="tooltiptext tooltip-right">Ref#: {specimenSupervisor.specimen.identifier}</span>
                                                    }

                                                    </h1>
                                                </div>
                                                <div className="sptm-frm-grp">
                                                     <Form onSubmit={(event) => event.preventDefault()}>
                                                     { !this.props.useClientLayout &&
                                                        <Section>
                                                        <h3 className="frm-sec-title">Lab No.</h3>
                                                           <FormGroup>
                                                               <Input className="ip-text-spc" type='text'
                                                               name="labNumber"
                                                               value={this.props.specimenForm.labNumber}
                                                               onChange={this.textFieldChange}
                                                               placeholder="CBL21-02-123"
                                                               disabled={!specimenSupervisor.isEditable(this.props.workspace)} />
                                                           </FormGroup>
                                                        </Section>
                                                    }
                                                    { !this.props.useClientLayout &&
                                                     <Section>
                                                            <h3 className="frm-sec-title">Client</h3>
                                                            <div className="select-wrapper">
                                                                <div className="select-well">
                                                               { 
                                                               <ValidateField required>
                                                                <ErrorFeedback 
                                                                    error
                                                                    show={this.state.formErrors.ownerMissing}
                                                                    filter='valueMissing'
                                                                    message={<ErrorMessage message ='Please select a client' />} />
                                                                <SimpleSelect
                                                                    components={{
                                                                        Indicator: Indicator,
                                                                        Option: Option,
                                                                        SelectedOption: SelectedOption
                                                                    }}
                                                                    ref={this.clientSelect}
                                                                    className="ip-ff-select"
                                                                    name="Client"
                                                                    title="Choose client"
                                                                    searchable={["Type Name or Account No.", "No matching account"]}
                                                                    filterKeys={['firstName',
                                                                    'lastName', 'accountNo']}
                                                                    list={clients}
                                                                    onChange={this.didSelectClientOption}
                                                                    initialSelected={ initialSelectedClient }
                                                                    disabled={!disableClientSelect}
                                                                    />
                                                                    
                                                                </ValidateField>
                                                                }
                                                               
                                                                </div>
                                                            </div>
                                                        </Section>
                                                        }
                                                        <Section>
                                                            <h3 className="frm-sec-title">Patient</h3>
                                                            <div className="select-wrapper">
                                                                <div className="select-well">
                                                               { 
                                                               <ValidateField required >
                                                               <ErrorFeedback 
                                                                   error
                                                                   show={this.state.formErrors.patientMissing}
                                                                   filter='valueMissing'
                                                                   message={<ErrorMessage message ='Please select a patient' />} />
                                                                <SimpleSelect
                                                                    components={{
                                                                        Indicator: Indicator,
                                                                        Option: Option,
                                                                        SelectedOption: SelectedOption
                                                                    }}
                                                                    ref={this.patientSelect}
                                                                    className="ip-ff-select"
                                                                    name="Patient"
                                                                    title="Choose patient"
                                                                    searchable={["Type Name or Registration No.", "No matching patient"]}
                                                                    filterKeys={['firstName',
                                                                    'lastName', 'registrationNo']}
                                                                    list={patients}
                                                                    onChange={this.didSelectOption}
                                                                    initialSelected={ initialSelectedPatient }
                                                                    disabled={!specimenSupervisor.isEditable(this.props.workspace)}
                                                                    searchHandler={this.handlePatientSearch}
                                                                    />

                                                                </ValidateField>
                                                                }
                                                               
                                                                </div>
                                                                { specimenSupervisor.isEditable(this.props.workspace) && 
                                                                <Authority allowed="create" entity="patient">
                                                                <div className="add-btn-hldr">
                                                                    <Button onClick={this.showModal}
                                                        className="add-vial add-patient">
                                                            <span className="icon"><img src={ getIconUrl("plus") } alt="icon"/></span>

                                                                    </Button>
                                                                </div>
                                                                </Authority>
                                                                }
                                                            </div>
                                                            
                                                            <FormGroup>
                                                            <ValidateField required>
                                                               <Input type='text' 
                                                               name="doctor"
                                                               onChange={this.textFieldChange}
                                                               value={this.props.specimenForm.doctor}
                                                               className="ip-text-spc"
                                                               placeholder="Doctor"
                                                               disabled={!specimenSupervisor.isEditable(this.props.workspace)} />

                                                                <ErrorFeedback 
                                                                    error
                                                                    show={this.state.formErrors.doctorMissing}
                                                                    filter='valueMissing'
                                                                    message={<ErrorMessage 
                                                                    message ='Please enter a doctor' />} />
                                                                </ValidateField>
                                                           </FormGroup>
                                                           
                                                         </Section>
                                                         <Section>
                                                         <h3 className="frm-sec-title">Specimen</h3>
                                                         
                                                            <FormGroup>
                                                                {this.state.formErrors.vialsamplesEmpty && <ErrorMessage message="Sample is required, select specimen type and press + plus button to the right." 
                                                                />}
                                                                { specimenTypes.map((specimenType, i) => {

                                                                const isChecked = specimenType === this.state.selectedSpeciment ? true : false;

                                                                    return <Label key={i} className="ip-radio-pill">
                                                                    <Input onChange={this.specimenTypeChange} 
                                                                    value={ specimenType } type="radio" name="specimenType" 
                                                                    checked={isChecked}
                                                                    disabled={!specimenSupervisor.isEditable(this.props.workspace)}
                                                                    />{' '}
                                                                    <span className="radioselect">
                                                                    <i><img src={getIconUrl('specimenTwo')} alt=''/></i>
                                                                    </span>
                                                                    <span className="text">{specimenType}</span>
                                                                </Label> 

                                                                })}
                                                            
                                                            </FormGroup>
                                                            
                                                            <FormGroup>
                                                                <div className="date-field-group">
                                                                    <div className="ip-date">
                                                                        <Label>Date of specimen</Label>
                                                                    
                                                                 <DateTimePicker 
                                                                 ref={this.datePicker}
                                                                 format={'dd-MM-yyyy'}
                                                                 className="date-picker"
                                                                clearIcon={null}
                                                                disableClock
                                                                disabled={!specimenSupervisor.isEditable(this.props.workspace)}
                                                            
                                                                calendarIcon={null}
                                                                onChange={this.dateChange}
                                                                value={this.state.date} />

                                                                <span className="icon">
                                                                    <img  src={getIconUrl("date")} alt=''/>
                                                                </span>
                                                                </div>
                                                                </div>
                                                            </FormGroup>
                                                         </Section>
                                                         
                                                         <Section>
                                                         <h3 className="frm-sec-title">Clinical Features</h3>
                                                            {foundClinicalFeatures ?
                                                                <div className="clinical-features">
                                                                    { Array.isArray(clinicalFeatures) &&  clinicalFeatures.map(item => this.renderClinicalFeature(item, this.props.specimenForm)) }
                                                                </div>
                                                                :
                                                                    <NotFound.Items onClick={this.showFormSetting} 
                                                                    messageComponent={<span>
                                                                        No <b>Clinical Features</b> were found. Click here to create some.
                                                                    </span>} />
                                                            }
                                                         </Section>
                                                        
                                                         {this.state.formType === this.formType.NonGynec ? null : 
                                                         <Section>
                                                          <div className="thpy-feilds-grp">
                                                            <FormGroup>
                                                                <div className="ip-text-line">
                                                                    <label>Other Clinical Data</label>
                                                                    <InputField
                                                                    id="otherClinicalInfo" 
                                                                    value={this.props.specimenForm.otherClinicalInfo}
                                                                    name={'otherClinicalInfo'}
                                                                    onChange={this.textFieldChange}
                                                                     type="text" 
                                                                     disabled={!specimenSupervisor.isEditable(this.props.workspace)}/>
                                                                </div>
                                                            </FormGroup>
                                                            </div>
                                                            </Section>
                                                        }
                                                         {this.state.formType === this.formType.NonGynec ? null : 
                                                         <Section>
                                                         <h3 className="frm-sec-title">Therapy</h3>
                                                          <div className="thpy-feilds-grp">
                                                            <FormGroup>
                                                                <div className="ip-text-line">
                                                                    <label>Hormone</label>
                                                                    <InputField
                                                                    id="hormone" 
                                                                    value={therapyData.hormone}
                                                                    name={'therapy[]'}
                                                                    onChange={this.therapyFieldChange}
                                                                     type="text" 
                                                                     disabled={!specimenSupervisor.isEditable(this.props.workspace)}/>
                                                                </div>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <div className="ip-text-line">
                                                                    <label>Radiation</label>
                                                                    <InputField 
                                                                    id="radiation" 
                                                                    value={therapyData.radiation}
                                                                    name={'therapy[]'}onChange={this.therapyFieldChange}type="text"
                                                                    disabled={!specimenSupervisor.isEditable(this.props.workspace)}/>
                                                                </div>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <div className="ip-text-line">
                                                                    <label>Surgical</label>
                                                                    <InputField 
                                                                    id="surgical" 
                                                                    value={therapyData.surgical}
                                                                    name={'therapy[]'}onChange={this.therapyFieldChange}type="text"
                                                                    disabled={!specimenSupervisor.isEditable(this.props.workspace)}/>
                                                                </div>
                                                                <FormText>
                                                                    Some description here
                                                                </FormText>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <div className="ip-text-line">
                                                                    <label>Other</label>
                                                                    <InputField 
                                                                     id="other"
                                                                     value={therapyData.other}
                                                                     name={'therapy[]'}
                                                                     onChange={this.therapyFieldChange} type="text"
                                                                     disabled={!specimenSupervisor.isEditable(this.props.workspace)}/>
                                                                </div>
                                                            </FormGroup>
                                                          </div>
                                                         </Section>
                                                         }
                                                     </Form>
                                                </div>
                                                { specimenSupervisor.hasAttachment() &&
                                      
                                                    <div className="atch-sec">
                                                        <h3>
                                                            <span className="icon">
                                                                <img src={getIconUrl("attachment")} alt=""/>
                                                            </span>
                                                        Attachment</h3>
                                                        <div className="atch-wrap">
                                                            <span className="fPvew">
                                                                <a onClick={this.showHideView} className="aLnk">
                                                                   <div className="wrp">
                                                                        <div className="fEl">
                                                                            <div className="fNme">
                                                                                <span className="ext">{fileHandler.getFileExtension(specimenSupervisor.specimen.resultSheetFile.fileName).toUpperCase()}</span>
                                                                                <span className="name">
                                                                                    Result sheet.{fileHandler.getFileExtension(specimenSupervisor.specimen.resultSheetFile.fileName)}
                                                                                </span>
                                                                            </div>

                                                                        </div> 
                                                                        <div className="aSI">
                                                                            <div  className="aSJ" ></div>
                                                                        </div>
                                                                    </div> 
                                                                </a>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="sptm-frm-smpl">
                                                <Section className="vial-sec">
                                                <h3 className="frm-sec-title">{this.formType.Gynec === this.state.formType ?  "Slide" : "Vial"} Samples</h3>
                                                    <div className={`vial-well ${this.formType.Gynec === this.state.formType ?  "" : "vials"} ${this.state.formErrors.vialsamplesEmpty ? '--error' : ''} `}>
                                                        <div className="add-btn-hldr">
                                                        {specimenSupervisor.isEditable(this.props.workspace) && 
                                                        <Button 
                                                        onClick={this.onAddSpecimen}
                                                        className="add-vial">
                                                            <span className="icon"><img src={ getIconUrl("plus") } alt="icon"/></span>
                                                        </Button>
                                                        }
                                                        </div>
                                                        <div className="vial-list-wrap">
                                                            
                                                            <List className="vial-list">

                                                                {
                                                                vialsamplesList?.length > 0 ?
                                                                vialsamplesList.map((item, i) =>  {

                                                                    const selected = item.specimenLabel === this.state.selectedSpeciment  && this.state.currentIndex === i ? '--selected' : '';
                                                                    
                                                                return (<li key={item.id} className={`${this.formType.Gynec === this.state.formType ?  "slide-item" : "vial-item"} ${selected}`}>
                                                                    <div className={`${this.formType.Gynec === this.state.formType ?  "slide-hldr" : "vial-hldr"}`}>

                                                                    { (specimenSupervisor.isEditable(this.props.workspace) && !item.dateCreated ) && 
                                                                        <NavLink
                                                                        id={item.id}
                                                                        onClick={this.onRemoveSample}
                                                                        className="rmv-btn">
                                                                            <img src={getIconUrl("plus")} alt=''/>
                                                                        </NavLink>
                                                                    }

                                                                    { this.formType.Gynec === this.state.formType ?
                                                                    <CellSlide 
                                                                    specimenLabel={item.specimenLabel}
                                                                    cells={item.vialColour}
                                                                    data={item}
                                                                    index={i}
                                                                    clickHandler={this.onSelectSample}
                                                                />
                                                                    :
                                                                    <BloodVial
                                                                    clickHandler={this.onSelectSample}
                                                                    onTypedHandler={this.onChangeBloodType}
                                                                    cork={item.vialColour}
                                                                    data={item}
                                                                    specimenLabel={item.specimenLabel} 
                                                                    bloodType={item.bloodGroup}
                                                                    editable={specimenSupervisor.isEditable(this.props.workspace)}
                                                                    index={i} />
                                                                }
                                                                    </div>
                                                                </li>)
                                                                }
                                                                )
                                                                :
                                                                <li key={1}className="no-vial">
                                                                    <div className="empty">
                                                                        <p>Choose Specimen<br />
                                                                        Click <strong>+</strong>
                                                                        </p>
                                                                    </div>
                                                                </li>
                                                                }
                                                            </List>
                                                        </div>
                                                    </div>
                                                </Section>
                                            </div>
                                        </div>  
                                    </Col>
                                </Row>
                                </ContextMenuTrigger>
                            </Container>
                        </div>
                    </div>
                    <input 
                    ref={this.fileInputField}
                    onChange={this.handleFileUpload}
                    id="fileInput" 
                    type='file' 
                    name="file" 
                    style={ {"display": "none"}}/>
                </div>
                </Authority>
                }
                { this.state.deleteDialogVisible && 
                    <ConfirmModal 
                        contextName={'specimen record'}
                        contextItem={this.state.deleleContextItem} 
                        cancelCallback={this.showHideDeleteDialog}
                        confirmCallback={this.onConfirmDelete}
                    />
                }

                {
                    this.state.statusDialogVisible && 
                    <ConfirmModal 
                        contextName={'specimen record'}
                        contextItem={null} 
                        isDelete={false}
                        title={`Change the status to ${STATUS_MAP[specimenSupervisor.getStatus()][0]}` }
                        cancelCallback={this.showHideConfimStatusDialog}
                        confirmCallback={this.onConfirmStatus}
                    />
                }
                <ContextMenu ref={this.contextMenuRef} id="specimen_record_context_menu">
                    { showMinimalContextMenu ? 
                    <SimpleContextMenu 
                    specimenSupervisor={specimenSupervisor}
                    contextMenuOptClick={contextMenuOptClick}/>
                       :
                    <React.Fragment>                
                    
                    { !specimenSupervisor.showStatusOption() || this.props.permissionEnforcer.isUserAuthorizer() ? null : 
                     <React.Fragment> 
                       <MenuItem disabled={!specimenSupervisor.enableStatusChange() && !specimenSupervisor.canAuthorizerChangeStatus() } data={{opt: 'changeStatus'}} onClick={contextMenuOptClick}>
                            Change Status
                        </MenuItem>
                        <MenuItem divider />
                        </React.Fragment>
                    }

                    { specimenSupervisor.isApproved() || !this.props.permissionEnforcer.isUserAuthorizer() ? null :
                    <React.Fragment> 
                        <MenuItem disabled={this.props.permissionEnforcer.isUserAuthorizer() && !specimenSupervisor.canAuthorizerChangeStatus()} data={{opt: 'authorize'}} onClick={contextMenuOptClick}>
                            Authorize
                        </MenuItem>
                        <MenuItem divider />
                    </React.Fragment>
                    }
                    
                    <MenuItem 
                        disabled={!specimenSupervisor.showResultSheet() && !specimenSupervisor.showAutorizerResultSheet()}
                         data={{opt: 'resultSheet'}} onClick={contextMenuOptClick}>
                            { specimenSupervisor.hasResultSheet() ? 'Result Sheet' : 'Add Result Sheet'}
                    </MenuItem>
                    <MenuItem disabled={!specimenSupervisor.canAttachFile()} data={{opt: 'addFile'}} onClick={contextMenuOptClick}>
                        Attach File
                    </MenuItem>
                    <MenuItem disabled={!specimenSupervisor.isApproved()} data={{opt: 'delete'}} onClick={contextMenuOptClick}>
                        Bill
                    </MenuItem>

                    <MenuItem disabled={!specimenSupervisor.canLinkToRequisition()} data={{opt: 'linkToReq'}} onClick={contextMenuOptClick}>
                        Link Requisition
                    </MenuItem>
                    </React.Fragment>  
                    }
                </ContextMenu>

                <SlidingPane
                    closeIcon={<CloseButton />}
                    isOpen={this.state.isPaneOpen}
                    title={<ResultSheetHead title="Result Sheet"/>}
                    from="bottom"
                    width="100%"
                    onRequestClose={() => this.setState({ isPaneOpen: false })}
                >
                    <ResultSheet />
                </SlidingPane>
                { this.state.showViewer &&
                    <Viewer showHideView={this.showHideView} 
                    fileDownload={this.props.fileDownload}
                    file={ specimenSupervisor.specimen ? specimenSupervisor.specimen.resultSheetFile : null } />
                }

                { this.state.showCreateModal &&
                 <CreatePatientModal
                    callSpecimenFieldDidChange={this.props.fieldDidChange}
                    composePatientSelectOption={this.composePatientSelectOption}
                    preSelectNewPatient={this.preSelectNewPatient}
                    closeModal={this.hideModal} 
                 />
                }
                {this.state.showLinkToReqModal && 
                    <RequisitionLinkModal 
                        hideRequisitionLinkModal={this.hideRequisitionLinkModal}
                        clientId={clientId}
                        formType={this.state.formType}
                        onSubmit={this.submitReqLinkRequest}
                        {...this.props}
                    />
                }
            </React.Fragment>
        );
    }
}

const CreateUpdateView = (props) => {
    return (
        <CreateUpdate 
        {...props } 
        />
    );
};

let ConfigCreateUpdateView = setupPage(CreateUpdate.pageOption)(CreateUpdateView);
ConfigCreateUpdateView =  connect(mapStateToProps, mapDispatchToProps)(ConfigCreateUpdateView);

export { ConfigCreateUpdateView };