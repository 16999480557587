import * as actionTypes from '../actionType';

export const employeesViewLoadedAction =  () => ({
    type: actionTypes.EMPLOYEES_VIEW_LOADED,
}); 

export const employeesViewUnloadAction =  () => ({
    type: actionTypes.EMPLOYEES_VIEW_UNLOAD
}); 
export const employeesCreateViewLoadedAction =  () => ({
    type: actionTypes.EMPLOYEES_CREATE_VIEW_LOADED,
}); 

export const employeesCreateViewUnloadAction =  () => ({
    type: actionTypes.EMPLOYEES_CREATE_VIEW_UNLOAD
}); 
export const getAllEmployeesAction =  (payload) => ({
    type: actionTypes.FETCH_EMPLOYEES,
    payload: payload
});
export const getEmployeeAction =  (employeeId) => ({
    type: actionTypes.FETCH_EMPLOYEE,
    payload: employeeId
});
export const setSelectedEmployeeAction =  (employee) => ({
    type: actionTypes.SET_SELECTED_EMPLOYEE,
    payload: employee
}); 
export const employeeFieldChangeAction =  (field) => ({
    type: actionTypes.EMPLOYEE_FIELD_CHANGED,
    payload: {
       ...field
    }
}); 
export const setUpdateEmployeeDataAction =  (fieldsData) => ({
    type: actionTypes.EMPLOYEE_EDIT_SETDATA,
    payload: {
       ...fieldsData
    }
}); 
export const submitEmployeeAction = (data) => ({
    type: actionTypes.EMPLOYEE_SUBMIT_DATA,
    payload: data
});
export const getEmployeePayAdviceAction = (employee) => ({
    type: actionTypes.FETCH_EMPLOYEE_PAYADVICE,
    payload: employee
});
export const terminateEmployeeAction = (employeeId) => ({
    type: actionTypes.TERMINATE_EMPLOYEE,
    payload: employeeId
});
