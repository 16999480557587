
import React from 'react';
import PropType from 'prop-types';
import { GENDER } from './../../../common/constant';
import { getIconUrl } from '../../../common/images-catalogue';

const PatientOrb = (props) => {

    const {data, clickHandler } = props;
    //const classnames = classNames('role-item',( role.superRole ? 'super-role' : '' ) );
    const name = `${data.firstName} ${data.lastName}`;

    const pictureSrc = data.picture ?  data.picture.fileDownloadUri : getIconUrl('profile')
    return (
        <React.Fragment>
        <div className="patient-holder" onClick={() => clickHandler(data)}>
            <span className="patient">
                <img src={pictureSrc} alt='' />
            </span>
            <span className="name">{ name }</span>
        </div>
        </React.Fragment>
    );
}

PatientOrb.defaultProps = {
    data: null
};
PatientOrb.propTypes = {
    data: PropType.object.isRequired
};


export default PatientOrb;