import React from 'react';
import classnames from 'classnames';
import { withPageConfig } from '../../components/Layout';
import { setupPage } from '../../components/Layout/setupPage';
import ActionBar from '../components/partials/ActionBar';
import Base64 from '../../utility/Base64Encoder';
import { FormValidator } from '../../utility/FormValidator';


import {
    Row,
    Container,
    Nav,
    Col,
    NavItem,
    NavLink as Link,
    TabContent,
    Button,
    TabPane,
    Section,
    Form, 
    FormGroup, 
    Label, 
    Input, 
    InputField,
    List,
    SimpleSelect,
    ValidateField,
    ErrorFeedback

} from '../../components';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import RoleItem from '../components/partials/RoleItem';
import * as urls from '../../config/urls';
import * as helpers from '../../utility/helpers';
import * as actionTypes from '../../store/actionType';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
import clientValidatorDelegate from '../../forms/clientValidator.delegate'
import FileUploader from '../../utility/FileUploader';
import {SEARCH_SETTINGS } from '../../config/searchable';
import { ErrorMessage } from '../components/partials/ErrorMessage';
import { PromptMessage, getPromptMessageProps } from '../components/partials/PromptMessage';
import { CLIENT_TYPE, REQUESTS_TYPE, VALIDATOR_DELEGATE_TYPES, VALIDATOR_DELEGATE_KEY_GROUP } from './../../common/constant';
import './Client.style.scss';

import { getIconUrl } from '../../common/images-catalogue';

const mapStateToProps = (state) => ({
    apiRequestManager : ApiRequestManager.getInstance(ApiRequestManager),
    useClientLayout: state.global.useClientDashboard,
    mode:  state.global.actionBarMode,
    workspace: state.global.workspace,
    validator: FormValidator.getInstance(FormValidator),
    ...state.clientCreateView,
    ...state.client,
    currentClient: state.client.currentClient
    
});

const mapDispatchToProps = (dispatch) => ({
    onLoad: () => dispatch(actions.clientCreateViewLoadedAction()),
    unLoad: () => dispatch(actions.clientCreateViewUnloadAction()),
    getUserWorkspaceRoles: (workspace) => dispatch(actions.getUserWorkspaceRoles(workspace)),
    setMode: (mode) => dispatch(actions.actionBarModeAction(mode)),
    setAssignedRole: (filteredRoleSet) => dispatch(actions.clientAssignedRoleAction(filteredRoleSet)),
    clientFieldDidChange: (fieldWithValue) => dispatch(actions.clientFieldChangeAction(fieldWithValue)),
    submitData : (data) => dispatch(actions.submitClientAction(data)),
    setClientFormData: (data) => dispatch(actions.setUpdateClientDataAction(data))
}); 


class CrateUpdate extends React.Component {
    
    static pageOption = {
        grayBackground: true,
        disableBodyScroll: true,
        searchContext: SEARCH_SETTINGS.entities.client.name
    };

    constructor(props) {
        super(props);
        
        props.apiRequestManager.register(actionTypes.FETCH_USER_WORKSPACE_ROLES, props.getUserWorkspaceRoles);
        props.apiRequestManager.register(actionTypes.CLIENT_SUBMIT_DATA, props.submitData);

        this.state = {
            activeTab: '1',
            formErrors: {},
            showAddressFields: false,
            updatePassword: false,
        };

        this.clientType = {
            laboratory: 'laboratory',
            doctor: 'doctor'
        };

        //References
        this.assignedRoleWell = React.createRef();
        this.profileImageRef = React.createRef();
        this.labCodeSelect = React.createRef();
        this.constraintRole = React.createRef();

        //Add event listeners
        this.toggle = this.toggle.bind(this);
        this.didSelectRole = this.didSelectRole.bind(this); 
        this.handlerBack = this.handlerBack.bind(this);
        this.handleTextField = this.handleTextField.bind(this);
        this.handleAddressTextField = this.handleAddressTextField.bind(this);
        this.addAddressFields = this.addAddressFields.bind(this);
        this.handlePillSwitches = this.handlePillSwitches.bind(this);
        this.handleToggleSwitches = this.handleToggleSwitches.bind(this);
        this.composeUserName = this.composeUserName.bind(this);
        this.submitFormData = this.submitFormData.bind(this);
        this.updateClientData = this.updateClientData.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.didSelectConstraintOption = this.didSelectConstraintOption.bind(this);
        this.didSelectLabCodeOption = this.didSelectLabCodeOption.bind(this);
        this.previewImage = this.previewImage.bind(this);
        this.willUpdatePassword = this.willUpdatePassword.bind(this);
        this.getPromptMessage = this.getPromptMessage.bind(this);
        this.setFormDefaults();
    }

    static getDerivedStateFromProps(props, state) {
     
        if (!props.userRoles &&  !props.workspaceRoles && !props.apiRequestManager.inProgress(actionTypes.FETCH_USER_WORKSPACE_ROLES) ) {
            props.apiRequestManager.queueRequest(actionTypes.FETCH_USER_WORKSPACE_ROLES);
        }

        if (props.currentClient) {
            //this.composeFormData(props.currentClient);
        }
        
        return null;
    }

    shouldComponentUpdate(props, state) {

        if ( !Object.blank(props.currentClient) && !props.assignedRole && Array.isArray(props.userRoles) ) {
            //this.didSelectRole(props.currentClient.role);
            const filterRolesSet = {
                chosenRole: props.currentClient.role,
                availableRoles: props.userRoles 
            }
            this.props.setAssignedRole(filterRolesSet);
        }
        return true;
    }

    componentDidMount() {
        
        const mode = this.props.currentClient !== undefined ? 'edit' : 'create'; 
        this.props.onLoad();
        this.props.setMode(mode);
        
        if ( !Object.blank(this.props.currentClient) ) { 
            const formData = this.composeFormData(this.props.currentClient);
            this.props.clientFieldDidChange(formData);
            //this.didSelectRole(this.props.currentClient.role);
            const transformedFormData = helpers.objToFormData(formData);

            this.setState({
                showAddressFields: !Object.blank(formData.address),
                formErrors: {
                    ...this.state.formErrors,
                    ...this.validateFields(transformedFormData),
                }
            })
        }

        this.props.validator.addDelegate(VALIDATOR_DELEGATE_TYPES.client, clientValidatorDelegate);
    }
    componentWillUnmount() {
        this.props.apiRequestManager.terminateAll();
        this.props.viewInit && this.props.unLoad();
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({ activeTab: tab });
        }
    }

    setFormDefaults() {
        const fieldset = {
            clientType : this.clientType.doctor,
            active: true
        }
        this.props.clientFieldDidChange(fieldset);
    }

    didSelectRole(role) {
        let filterRolesSet =  null;

        if ( role.assigned ) {
            let roles = this.props.userRoles;
            roles.push(role);
            filterRolesSet =  {
                chosenRole: null,
                availableRoles: roles
            };
            this.assignedRoleWell.current.classList.add('removed');
        }else {
            filterRolesSet =  this.filterAssignedRoles(this.props.userRoles, role);
            this.assignedRoleWell.current.classList.remove('removed');
        }
        this.props.setAssignedRole(filterRolesSet);
    }

    handleTextField  (event) {
        const key = event.target.name;
        let finalFieldValue = event.target.value;

        if(key === VALIDATOR_DELEGATE_KEY_GROUP.formFields.phoneNumber ||
           key === VALIDATOR_DELEGATE_KEY_GROUP.formFields.officeNumber ||
           key === VALIDATOR_DELEGATE_KEY_GROUP.formFields.mobileNumber){
            const phoneNumber = event.target.value;
            finalFieldValue = helpers.formatPhoneNumber(phoneNumber);
        }

        if (key === 'password'|| key === 'passwordTwo') {
            finalFieldValue = Base64.encode(event.target.value);
        }

        const fieldSet = {
           [key] : finalFieldValue, 
         };

        //handle password checks
        let passwordErrors = {};
        if(String(key).includes('password')){
            let value2 = ''
            if(key === VALIDATOR_DELEGATE_KEY_GROUP.formFields.password){
                value2 = this.props.clientForm.passwordTwo;
            }else{
                value2 = this.props.clientForm.password;
            }

            passwordErrors = {
                ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.client,VALIDATOR_DELEGATE_KEY_GROUP.formFields[key], fieldSet[key], value2)
            }
        }

        this.setState({
            formErrors: {
                ...this.state.formErrors,
                ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.client,VALIDATOR_DELEGATE_KEY_GROUP.formFields[key], finalFieldValue),
                ...passwordErrors,
            }
        });


        this.props.clientFieldDidChange(fieldSet);
    }

    handleAddressTextField(event) {
        const fieldSet = {
            address: {
                ...this.props.clientForm.address,
                [event.target.name] : event.target.value 
            }
          };
         this.props.clientFieldDidChange(fieldSet);
    }

    addAddressFields(evt) {
        evt.preventDefault();
        this.setState({ showAddressFields: true });
    }

    willUpdatePassword() {
        this.setState({updatePassword: true});
    }

    handlePillSwitches  (event) {
        const fieldSet = {
            clientType : event.target.value 
        };
        this.props.clientFieldDidChange(fieldSet);
    }

    handleToggleSwitches (event) {
        var fieldSet = {};

        switch(event.currentTarget.id) {
            case 'active':
                fieldSet = {
                    active : event.target.checked 
                };
                break;
            case 'blocked':
                fieldSet = {
                    blocked : event.target.checked 
                };
                break;
            case 'twoFactorAuth':
                fieldSet = {
                    twoFactorAuth : event.target.checked 
                };
                break;
            default:
        }
        this.props.clientFieldDidChange(fieldSet);
    }

    composeUserName(event) {
        if (this.props.currentClient) return;
        
        event.type !== 'keydown' && event.preventDefault();
        let firstName = event.target.name === 'firstName ' ? event.target.value.substr(0, 1) : this.props.clientForm.firstName.substr(0, 1);

        let lastName = event.target.name === 'lastName ' ? event.target.value : this.props.clientForm.lastName;
        let useCode = lastName === '' ?  '' : helpers.generateDigits(3);
        let userName = `${ firstName.capitalize() }${ lastName.replace(/\s+/g, 'X').capitalize() }${ useCode }`;

        const fieldSet = { 'username' : userName };
        this.props.clientFieldDidChange(fieldSet);
        return userName;
    }

    submitFormData(evt) {
        let isUpdate = this.props.mode === 'edit' ? true : false;
        const uploader = FileUploader.getInstance(FileUploader);

        const {profileImage, secondary, role, ...otherFields} = this.props.clientForm;

        // remove password field from payload if not set to update
        if (isUpdate && !this.state.updatePassword) {
            delete otherFields.password;
            delete otherFields.passwordTwo;
        }
        
        let formData = helpers.objToFormData(otherFields);
        formData.append('role', this.props.assignedRole ? this.props.assignedRole.id : null);
     
        formData.append('workspace', this.props.workspace.id);
        //formData.append('userType', 'Client');
        
        let formPayload = {
            requestType: isUpdate ? REQUESTS_TYPE.UPDATE : REQUESTS_TYPE.CREATE ,
            form: formData
        }
        if (profileImage) {
            const fileName =  uploader.generateFileName(profileImage);
            formData.append("profileImage", profileImage, fileName);
        }

        if (isUpdate) {
            formPayload['clientId'] = this.props.currentClient !== undefined ? this.props.currentClient.id : null
        }

        const formErrors = this.validateFields(formPayload.form);

        if (!this.props.validator.hasActiveFormError(formErrors)) {
            this.props.apiRequestManager.queueRequest(actionTypes.CLIENT_SUBMIT_DATA, formPayload);
    
        }else {
            this.setState({
                formErrors: formErrors
            });
        }
    }

    validateFields(formData) {
        const formFields = VALIDATOR_DELEGATE_KEY_GROUP.formFields;

        const passwordValidation = this.props.mode === 'create' || this.state.updatePassword ? {
            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.user,formFields.password, formData.get(formFields.password), formData.get(formFields.passwordTwo))
        } : {}

        return {
            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.client,formFields.firstName, formData.get(formFields.firstName)),

            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.client,formFields.lastName, formData.get(formFields.lastName)),

            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.client,formFields.email, formData.get(formFields.email)),

            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.client,formFields.officeName, formData.get(formFields.officeName)),

            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.client,formFields.phoneNumber, formData.get(formFields.phoneNumber)),

            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.client,formFields.workspaceName, formData.get(formFields.workspaceName)),

            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.client,formFields.labCode, formData.get(formFields.labCode)),

            constraintRoleMissing: this.props.clientForm.assignedConstraint === null ? true : false,

            ...passwordValidation,

            roleMissing: this.props.assignedRole === null ? true : false,
        }
        
    }

    updateClientData(evt) {
        this.submitFormData(evt);
    }

    handleDelete(evt) { }

    handlerBack(evt) {
        this.props.clientView === CLIENT_TYPE.Doctor ? this.props.history.push(urls.doctorsUri) : this.props.history.push(urls.labsUri);
    }

    didSelectConstraintOption(option) {
        const fieldSet = {
            assignedConstraint: option.id
         };
        this.props.clientFieldDidChange(fieldSet);
    }

    didSelectLabCodeOption(option) {
        const fieldSet = {
            labCode: option.id
        };

        this.setState({
            formErrors: {
                ...this.state.formErrors,
                ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.client,VALIDATOR_DELEGATE_KEY_GROUP.formFields.labCode, fieldSet.labCode)
            }
        });
    
        this.props.clientFieldDidChange(fieldSet);
    }

    composeFormData(client) {
        
        const formData =  {
            clientType: client.clientInfo.type.toLowerCase(),
            [VALIDATOR_DELEGATE_KEY_GROUP.formFields.firstName]: client.firstName,
            [VALIDATOR_DELEGATE_KEY_GROUP.formFields.lastName]: client.lastName,
            username: client.username,
            [VALIDATOR_DELEGATE_KEY_GROUP.formFields.email]: client.email,
            [VALIDATOR_DELEGATE_KEY_GROUP.formFields.password]: '',
            [VALIDATOR_DELEGATE_KEY_GROUP.formFields.passwordTwo]: '',
            active: client.active,
            secondary: client.secondary,
            blocked: client.blocked,
            twoFactorAuth: client.twoFactorAuth,
            role: client.role,
            [VALIDATOR_DELEGATE_KEY_GROUP.formFields.workspaceName]: client.clientInfo.workspaceName,
            [VALIDATOR_DELEGATE_KEY_GROUP.formFields.officeName]: client.clientInfo.officeName,
            [VALIDATOR_DELEGATE_KEY_GROUP.formFields.officeNumber]: client.clientInfo.officeNumber,
            [VALIDATOR_DELEGATE_KEY_GROUP.formFields.phoneNumber]: client.clientInfo.phoneNumber,
            [VALIDATOR_DELEGATE_KEY_GROUP.formFields.mobileNumber]: client.clientInfo.mobileNumber,
            [VALIDATOR_DELEGATE_KEY_GROUP.formFields.labCode]: client.clientInfo.labCode.id,
            assignedConstraint: client.clientInfo.workspaceRole,
            address: client.clientInfo.address || {}
        }
        return formData
    }

    filterAssignedRoles(availableRoles, selectedRole){
        this.props.assignedRole && availableRoles.push(this.props.assignedRole)
        const filteredRoles = availableRoles.filter(item => item.id !== selectedRole.id);

        return {
            chosenRole: selectedRole,
            availableRoles: filteredRoles 
        }
    }

    composeSelectConstraintOption(roleConstraint) {
        return {
            id: roleConstraint.id,
            value: roleConstraint.id,
            label: roleConstraint.role
        }
    }
    composeSelectLabCodeOption(labCode) {
        return {
            id: labCode.id,
            value: labCode.id,
            label: labCode.code
        }
    }

    previewImage(event, imageRef) {
        const reader = new FileReader();
        let image = imageRef ? imageRef : this.profileImageRef;

        reader.onload = function() {
            const output = image.current; //document.getElementById('imagePreview');
            output.src = reader.result;
        }
        reader.readAsDataURL(event.target.files.first());
        
        const file = event.target.files.first();
        
        const fieldSet = { 'profileImage' : file };
        this.props.clientFieldDidChange(fieldSet); 
    }

 
    getPromptMessage(){
        
        const apiOutcome = this.props.apiRequestManager.getApiOutcome(actionTypes.CLIENT_SUBMIT_DATA, true) ?? {};
        return getPromptMessageProps('client', apiOutcome);
    }

    render() {
       
        const selectedRole = this.props.assignedRole ? this.props.assignedRole : null;
        const actionBarCallback = {
            'save': this.submitFormData,
            'back': this.handlerBack,
            'delete': this.handleDelete
        };
        const defaultClientType = this.props.clientForm.clientType === '' ? true : this.props.clientForm.clientType === this.clientType.doctor ? true: false;

        //Updating context values
        const  actionBarMode = this.props.mode;
        const  saveSufixText = actionBarMode === 'edit'? '' : 'Client';
        const workspaceRolesList = !this.props.workspaceRoles ? [] : this.props.workspaceRoles.map((role) => {
            return this.composeSelectConstraintOption(role);
        });
  
        let initialSelectedConstraint = ! this.props.clientForm.assignedConstraint ? null : workspaceRolesList.find((item) => {
            return item.id === this.props.clientForm.assignedConstraint;
        });
        
        const labCodesList = !this.props.labCodes ? [] : this.props.labCodes.map((labcode) => {
            return this.composeSelectLabCodeOption(labcode);
        });

        const selectLabCode = !this.props.clientForm.labCode ? null : labCodesList.find((item) => {
            return item.id === this.props.clientForm.labCode;
        });

        const initialSelectedLabCode = selectLabCode ? selectLabCode : null;
        const userRoles = this.props.userRoles ? this.props.userRoles : [];
        
        const signatureSrc = this.props.clientForm.profileImage ? URL.createObjectURL(this.props.clientForm.profileImage) :  this.props.currentClient && this.props.currentClient.picture ? this.props.currentClient.picture.fileDownloadUri : getIconUrl('profile');

        const showAddressFields = this.state.showAddressFields || !Object.blank(this.props.clientForm.address)? true : false ;

        const dialogProps = this.getPromptMessage();
        const formFields = VALIDATOR_DELEGATE_KEY_GROUP.formFields;

        const shouldUpdatePassword = actionBarMode === 'create' ? true : this.state.updatePassword && this.props.currentClient;

        return (
            <React.Fragment>
                <ActionBar title={ "Client" } saveTextSufix={ saveSufixText } entityContext={'client'} actionResponsers={ actionBarCallback } permissionContext={ actionBarMode } />
                <div className="content-layout-wrap">
                    <div className="view-layout-content">
                    { dialogProps.visible && 
                            <PromptMessage {...dialogProps} />
                        } 
                    <div className="default-Ly-ct">
                <Container>
                    <Row>
                    <Col></Col>
                    <Col sm="12" md={7}>
                    <div className="content-section">
                            <Nav tabs>
                                <NavItem>
                                <Link
                                    className={classnames({ active: this.state.activeTab === '1' })}
                                    onClick={() => { this.toggle('1'); }}
                                >
                                    Client Info
                                </Link>
                                </NavItem>
                                <NavItem>
                                <Link
                                    className={classnames({ active: this.state.activeTab === '2' })}
                                    onClick={() => { this.toggle('2'); }}
                                >
                                    { (!selectedRole && this.state.activeTab != '2') && <ErrorMessage message=''/>}
                                   Roles
                                </Link>
                                </NavItem>
                            </Nav>
                            <TabContent className="create-user" activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                <Row>
                                    <Col sm="12">
                                    <Form onSubmit={(event) => event.preventDefault()}>
                                        <Section className="client">
                                            <div className="section-head simple">
                                                <h3>Workspace</h3>
                                            </div>
                                            <div className="section-body">
                                                <FormGroup className="section-inner">
                                                    <Label>Name</Label>
                                                    <ValidateField required>
                                                        <InputField onChange={ this.handleTextField } id={formFields.workspaceName} type="text" name={formFields.workspaceName}className="ip-text-default" placeholder="Workspace Name" value={this.props.clientForm.workspaceName } />
                                                        <ErrorFeedback 
                                                        error
                                                        show={this.state.formErrors.workspaceNameMissing}
                                                        filter='valueMissing'
                                                        message={<ErrorMessage message ='Worksapce Name is required' />} />
                                                      
                                                </ValidateField>
                                                </FormGroup>
                                                <FormGroup className="section-inner">
                                                    <Label>Labcode</Label>
                                                    <ValidateField required>
                                                        <SimpleSelect
                                                            id={formFields.labCode}
                                                            ref={this.labCodeSelect}
                                                            className="ip-select-field"
                                                            name={formFields.labCode}
                                                            title="Choose Labcode"
                                                            list={labCodesList}
                                                            onChange={this.didSelectLabCodeOption}
                                                            initialSelected={initialSelectedLabCode}
                                                        />
                                                        <ErrorFeedback 
                                                        error
                                                        show={this.state.formErrors.labCodeMissing}
                                                        filter='valueMissing'
                                                        message={<ErrorMessage message ='Please select a Lab Code' />} />
                                                    </ValidateField>
                                                </FormGroup>
                                                
                                            </div>
                                        </Section>
                                        <Section className="user-info">
                                            <div className="section-head">
                                                <h3>Attributes</h3>
                                            </div>
                                            <div className="section-body">
                                                <FormGroup className="section-inner">
                                                    <div className="image-thumbnail-box">
                                                        <div className="image-selector">
                                                            <img ref={this.profileImageRef} src={signatureSrc} alt=''></img>
                                                            <Button><span>Edit</span></Button>
                                                            <div className="input-field">
                                                                <Input onChange={this.previewImage} type="file" name="profile-image" accept="image/*" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="client-group">
                                                    <Label className="ip-radio-pill">
                                                        <Input onChange={this.handlePillSwitches } type="radio" name="userType" value={this.clientType.doctor } checked={ defaultClientType } />{' '}
                                                        <span className="radioselect"></span>
                                                        <span className="text">Doctor</span>
                                                    </Label>
                                                    <Label className="ip-radio-pill">
                                                    <Input onChange={this.handlePillSwitches } 
                                                    type="radio" name="userType"
                                                    value={this.clientType.laboratory } checked={this.props.clientForm.clientType === this.clientType.laboratory? true: false}/>{' '}
                                                        <span className="radioselect"></span>
                                                        <span className="text">Laboratory</span>
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup className="section-inner">
                                                    <div className="switch-group">
                                                        <Label className="inline">Active</Label>
                                                        <span className="switch-pill inline">
                                                            <Input onChange={ this.handleToggleSwitches } type="checkbox" id="active" checked={ this.props.clientForm.active }/>
                                                            <Label for="active" id="active">Off</Label>
                                                        </span>
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="section-inner">
                                                    <div className="switch-group">
                                                        <Label className="inline">Blocked</Label>
                                                        <span className="switch-pill inline">
                                                            <Input onChange={this.handleToggleSwitches } type="checkbox" id="blocked" checked={ this.props.clientForm.blocked } />
                                                            <Label for="blocked" id="blocked">Off</Label>
                                                        </span>
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="section-inner">
                                                    <Label>Firstname</Label>
                                                    <ValidateField required>
                                                        <InputField onKeyUp={this.composeUserName} onChange={ this.handleTextField } id={formFields.firstName} type="text" name={formFields.firstName}className="ip-text-default" placeholder="John" value={this.props.clientForm.firstName } />

                                                        <ErrorFeedback 
                                                        show={this.state.formErrors.firstNameMissing}
                                                        error
                                                        filter='valueMissing'
                                                        message={<ErrorMessage message ='First Name is required' />} />
                                                        <ErrorFeedback 
                                                        show={this.state.formErrors.firstNameInvalid}
                                                        error
                                                        filter='letterInput'
                                                        message={<ErrorMessage message ='Name should contain only letters' />} />
                                                      
                                                </ValidateField>
                                                </FormGroup>

                                                <FormGroup className="section-inner">
                                                    <Label>Lastname</Label>
                                                    <ValidateField required>
                                                    <InputField onKeyUp={this.composeUserName} onChange={ this.handleTextField } id={formFields.lastName} type="text" name={formFields.lastName}className="ip-text-default" placeholder="Brown" value={this.props.clientForm.lastName } />
                                                    <ErrorFeedback 
                                                        error
                                                        show={this.state.formErrors.lastNameMissing}
                                                        filter='valueMissing'
                                                        message={<ErrorMessage message ='Last Name is required' />} />
                                                        
                                                        <ErrorFeedback 
                                                        error
                                                        show={this.state.formErrors.lastNameInvalid}
                                                        filter='letterInput'
                                                        message={<ErrorMessage message ='Name should contain only letters' />} />
                                                    
                                                    </ValidateField>
                                                </FormGroup>
                                            </div>
                                        </Section>
                                        <Section className="auth">
                                            <div className="section-head">
                                                <h3>Auth Information</h3>
                                            </div>
                                            <div className="section-body">
                                                <FormGroup className="section-inner username-field">
                                                    <InputField id="username" type="text" className="ip-text-default"  name={"username"} placeholder="Username" disabled/>
                                                    <span><i>Genterated </i> { this.props.clientForm.username }</span>
                                                </FormGroup>
                                                <FormGroup className="section-inner">
                                                    <Label>Email</Label>
                                                    <ValidateField required >
                                                    <InputField onChange={ this.handleTextField } id={formFields.email} type="text" name={formFields.email}className="ip-text-default" placeholder="example@mail.com"
                                                    value={ this.props.clientForm.email } />
                                                       <ErrorFeedback 
                                                        show={this.state.formErrors.emailMissing}
                                                        warning
                                                        filter='valueMissing'
                                                        message={<ErrorMessage message ='Email is required' />} />

                                                    <ErrorFeedback 
                                                        show={this.state.formErrors.emailInvalid}
                                                        warning
                                                        filter='emailMismatch'
                                                        message={<ErrorMessage message ='Not a valid email address' />} />
                                                    </ValidateField>
                                                </FormGroup>

                                                { !shouldUpdatePassword ?
                                                <FormGroup className="section-inner">
                                                    <Link className="arrow-link" onClick={this.willUpdatePassword}>
                                                        <span className="arrow-indicator"></span>Update Password
                                                    </Link>
                                                </FormGroup>
                                                :

                                                <React.Fragment>
                                                <FormGroup className="section-inner">
                                                    <Label>Password</Label>
                                                    <ValidateField pattern={'[a-zA-Z0-9@#$%^&+-]{6,}?'} required > 
                                                    <InputField  onChange={ this.handleTextField } id={formFields.password} type="password" name={formFields.password}className="ip-text-default" placeholder="* * * * * *"/>
                                                    <ErrorFeedback 
                                                        show={this.state.formErrors.passwordMissing}
                                                        warning
                                                        filter='valueMissing'
                                                        message={<ErrorMessage message ='Please enter a Password'/>} />

                                                    <ErrorFeedback 
                                                        warning
                                                        filter='patternMismatch'
                                                        message='Use 6 characters or more for your Password' />
                                                    </ValidateField>
                                                </FormGroup>
                                                <FormGroup className="section-inner">
                                                    <Label>Re-Enter</Label>
                                                    <ValidateField matchField={'password'} >   
                                                        <InputField  onChange={ this.handleTextField } id={formFields.passwordTwo} type="password" name={formFields.passwordTwo}className="ip-text-default" placeholder="* * * * * *"/>
                                                        < ErrorFeedback 
                                                        show={this.state.formErrors.passwordsDifferent}
                                                        error
                                                        filter='matchEqual'
                                                        message={<ErrorMessage message ='Those passwords didn’t match'/>} />
                                                    </ValidateField>
                                                </FormGroup>
                                                </React.Fragment>
                                                }

                                                <FormGroup className="section-inner">
                                                    <div className="switch-group">
                                                        <Label>Two factor athentication</Label>
                                                        <span className="switch-pill">
                                                            <Input type="checkbox" id="twoFactorAuth" onChange={ this.handleToggleSwitches } checked={this.props.clientForm.twoFactorAuth } />
                                                            <Label for="twoFactorAuth" id="twoFactorAuth">Off</Label>
                                                        </span>
                                                    </div>
                                                </FormGroup>
                                            </div>
                                        </Section>
                                        <Section className="auth">
                                            <div className="section-head">
                                                <h3>Client Details</h3>
                                            </div>
                                            <div className="section-body">
                                                <FormGroup className="section-inner">
                                                    <Label>Office Name</Label>
                                                    <ValidateField required>
                                                        <InputField onChange={ this.handleTextField } id={formFields.officeName} type="text" name={formFields.officeName}className="ip-text-default" placeholder="Office Name"
                                                        value={ this.props.clientForm.officeName } />

                                                        <ErrorFeedback 
                                                        show={this.state.formErrors.officeNameMissing}
                                                        error
                                                        filter='valueMissing'
                                                        message={<ErrorMessage message ='Office Name is required' />} />

                                                        {/* <ErrorFeedback 
                                                        show={this.state.formErrors.officeNameInvalid}
                                                        warning
                                                        filter='letterInput'
                                                        message={<ErrorMessage message ='Name should contain only letters' />} /> */}
                                                    </ValidateField>
                                                </FormGroup>
                                                <FormGroup className="section-inner">
                                                    <Label>Phone Number</Label>
                                                    <ValidateField>
                                                        <InputField  onChange={ this.handleTextField } id={formFields.phoneNumber} 
                                                        type="text" name={formFields.phoneNumber}className="ip-text-default" placeholder="888-888-8888"
                                                        value={ this.props.clientForm.phoneNumber }/>

                                                        <ErrorFeedback 
                                                        error
                                                        show={this.state.formErrors.phoneNumberMissing}
                                                        filter='valueMissing'
                                                        message={<ErrorMessage message ='Please add a Phone number' />}
                                                        />
                                                    
                                                        <ErrorFeedback 
                                                        show={this.state.formErrors.phoneNumberInvalid}
                                                        error
                                                        filter='phoneNumber'
                                                        message={<ErrorMessage message ='Phone number
                                                        not valid' />} />
                                                    </ValidateField>
                                                </FormGroup>
                                                <FormGroup className="section-inner">
                                                    <Label>Office Number</Label>
                                                    <ValidateField>
                                                        <InputField  onChange={ this.handleTextField } id={formFields.officeNumber} type="text" name={formFields.officeNumber}className="ip-text-default" placeholder="888-888-8888"
                                                        value={ this.props.clientForm.officeNumber }/>
                                                    
                                                        <ErrorFeedback 
                                                        show={this.state.formErrors.officeNumberInvalid}
                                                        error
                                                        filter='phoneNumber'
                                                        message={<ErrorMessage message ='Phone number
                                                        not valid' />} />
                                                    </ValidateField>
                                                    
                                                </FormGroup>
                                                <FormGroup className="section-inner">
                                                    <Label>Mobile Number</Label>
                                                    <ValidateField>
                                                        <InputField  onChange={ this.handleTextField } id={formFields.mobileNumber} type="text" name={formFields.mobileNumber}className="ip-text-default" placeholder="888-888-8888"
                                                        value={ this.props.clientForm.mobileNumber }/>
                                                    
                                                        <ErrorFeedback 
                                                        show={this.state.formErrors.mobileNumberInvalid}
                                                        error
                                                        filter='phoneNumber'
                                                        message={<ErrorMessage message ='Phone number
                                                        not valid' />} />
                                                    </ValidateField>
                                                </FormGroup>
                                                { !showAddressFields ?
                                                    <div className="add-patient-holder">
                                                        <Button className="add-address-btn" onClick={this.addAddressFields}>
                                                            <span className="icon">
                                                                <img src={getIconUrl("plusIcon") }alt=''/>
                                                            </span>
                                                            Add Address
                                                        </Button>
                                                    </div>
                                                    : 
                                                    <>
                                                        <FormGroup className="section-inner">
                                                            <Label>Street</Label>
                                                            <InputField  onChange={ this.handleAddressTextField } 
                                                            id="street" 
                                                            type="text" name={"street"}
                                                            className="ip-text-default" placeholder="Address Line 1"
                                                            value={this.props.clientForm.address.street }/>
                                                    
                                                        </FormGroup>
                                                        <FormGroup className="section-inner">
                                                            <Label>Address Line 2</Label>
                                                            <InputField  onChange={ this.handleAddressTextField } 
                                                            id="streetTwo" 
                                                            type="text" name={"streetTwo"}
                                                            className="ip-text-default" placeholder="Address Line 2"
                                                            value={this.props.clientForm.address.streetTwo }/>
                                                    
                                                        </FormGroup>
                                                        <FormGroup className="section-inner">
                                                            <Label>City</Label>
                                                            <InputField  onChange={ this.handleAddressTextField } 
                                                            id="city" 
                                                            type="text" name={"city"}
                                                            className="ip-text-default" placeholder="City"
                                                            value={this.props.clientForm.address.city }/>
                                                    
                                                        </FormGroup>
                                                        <FormGroup className="section-inner">
                                                            <Label>Parish | State</Label>
                                                            <InputField  onChange={ this.handleAddressTextField } 
                                                            id="state" 
                                                            type="text" name={"state"}
                                                            className="ip-text-default" placeholder="Parish"
                                                            value={this.props.clientForm.address.state }/>
                                                    
                                                        </FormGroup>
                                                    </>
                                                }
                                            </div>
                                        </Section>
                                    </Form>
                                    </Col>
                                </Row>
                                </TabPane>
                                <TabPane tabId="2" id="roles-pane">
                                <Row>
                                    <Col sm="12">
                                        <Section>
                                            <div className="section-head simple">
                                                <h3>Assigned Role</h3>
                                            </div>
                                            <div className="section-body">
                                                <div className="role-well">
                                                { !selectedRole && <ErrorMessage message='Please select a role'/>}
                                                    <div ref={this.assignedRoleWell} className="role-well-container holder">
                                                        <List>
                                                        { !selectedRole ? 
                                                        <li><p className="unassign">No Role Selected</p></li>
                                                        :
                                                            <RoleItem  role={ selectedRole } isAssigned eventHanlder={this.didSelectRole}/>
                                                        }
                                                        </List>
                                                    </div>
                                                </div>
                                            </div>
                                        </Section>

                                        <Section>
                                            <div className="section-head simple">
                                                <h3>Workspace Constraint Role</h3>
                                                
                                            </div>
                                            <div className="section-body wksp">
                                                <div className="role-well">
                                                { (initialSelectedConstraint ? '': <ErrorMessage message='Please select a workspace constraint'/>)}
                                                    <div className="role-well-container holder">
                                                    <SimpleSelect
                                                        id={'constraintRole'}
                                                        className="ip-select-field"
                                                        ref={this.constraintRole}
                                                        name="Constraint Role"
                                                        title="Choose Constraint Role"
                                                        list={workspaceRolesList}
                                                        onChange={this.didSelectConstraintOption}
                                                        initialSelected={initialSelectedConstraint}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Section>

                                        <Section>
                                            <div className="section-head simple">
                                                <h3>Available Roles</h3>
                                            </div>
                                            <div className="section-body">
                                                <div className="role-well">
                                                    <div className="role-well-container">
                                                        
                                                        <List>
                                                            {
                                                                userRoles.empty() && !this.props.assignedRole ?
                                                                <li>
                                                                    <div className="no-roles"><p>Add Roles in settings.</p>
                                                                    </div>
                                                                </li> : 
                                                            
                                                          userRoles.map((role, ) => {
                                                              
                                                            return <RoleItem key={role.id} role={role }eventHanlder={this.didSelectRole} />
                                                          })  
                                                        }
                                                        </List>
                                                    </div>
                                                </div>
                                            </div>
                                        </Section>
                                    </Col>
                                </Row>
                                </TabPane>
                            </TabContent>
                            </div>
                    </Col>
                    <Col></Col>
                    </Row>
                </Container>
                </div>
                </div>
                </div>
            </React.Fragment>
        );
    }
}

const CrateUpdateView = (props) => {
    return (
        <CrateUpdate {...props } />
    );
};

const ConfigCrateUpdateUserView = setupPage( CrateUpdate.pageOption )(CrateUpdateView);


export default connect(mapStateToProps, mapDispatchToProps)(ConfigCrateUpdateUserView);