import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { tagPropType, mapToCssModules } from '../../utility/component';

const propTypes = {
    className: PropTypes.string,
    tag: tagPropType
};

const defaultProps = {
    tag: 'section'
};

  
class Section extends React.Component {
    
    constructor(props) {
        super(props);
    }

    render() {

        const {
            className,
            cssModule,
            fluid,
            tag: Tag,
            ...attributes
        } = this.props;

        let sectionClass = 'section';

        const classes = mapToCssModules(classNames(
            className,
            sectionClass
          ), cssModule);

        return <Tag {...attributes} className={classes} />
    }
}

Section.propTypes = propTypes;
Section.defaultProps = defaultProps;

export default Section;