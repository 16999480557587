import * as actionTypes from '../actionType';
import { isError } from '../../service/agent';
import { ApiRequestManager } from '../../utility/ApiRequestManager';

const initialState = {
    viewInit: false,
    assignedRole: null,
    availableRoles: [],
    roles: null,
    currentUser: null,
    singleSuperuser: false,
    userForm: {
        userId: null,
        userType: '',
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        password: '',
        passwordTwo: '',
        active: false,
        secondary: false,
        blocked: false,
        twoFactorAuth: false,
        //Authorizer fieds
        authorizerType: '',
        gender:'',
        phoneNumber: '',
        digitalSignature: '',
        signature: null
    },
    authAccessRequest: {
        message: ''
    }
};

const emptyArray = [] ;
var  error = null;
let requestManager = null;
let apiOutcome = null;

const usersReducer = (state = initialState, action ) => { 

    switch(action.type) {
        case actionTypes.USER_CREATE_VIEW_LOADED:
            return {
                ...state,
            };
            
        case actionTypes.USER_CREATE_VIEW_UNLOAD:
            //const {currentUser, ...newState } = state;
            return {
                ...initialState,
                currentUser: state.currentUser ? state.currentUser : null,
                singleSuperuser: state.singleSuperuser
            };
        case actionTypes.USERS_VIEW_LOADED:
            return {
                ...initialState
            };
        case actionTypes.USERS_VIEW_UNLOAD:
            let { userForm, initial } = initialState;
            return {
                ...state,
                ...initial,
                currentUser: state.currentUser 
            };

        case actionTypes.FETCH_USERS_RESULTS :
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.FETCH_USERS);

            return {
                ...state,
            usersData: apiOutcome?.success ? action.payload : emptyArray
        }
        case actionTypes.FETCH_ROLES_RESULTS :
           
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.FETCH_ROLES) || {};
      
            return {
            ...state,
            roles: apiOutcome?.success ? action.payload : emptyArray,
            availableRoles: apiOutcome?.success ? action.payload : emptyArray
        }
        case actionTypes.FETCH_USER_RESULT :
            error = isError(action.payload);
            return {
            ...state,
            currentUser: error ? null : action.payload
        }
        case actionTypes.USERS_ASSIGNED_ROLE :
            
            return {
            ...state,
            assignedRole:  action.payload.chosenRole,
            availableRoles: action.payload.availableRoles,
        }
        case actionTypes.USER_FIELD_CHANGED :
            return {
            ...state,
            userForm: {
                ...state.userForm,
                ...action.payload
            }
        }
        case actionTypes.SET_SINGLE_SUPERUSER:
            return {
                ...state,
                singleSuperuser: action.payload
            }
        case actionTypes.USER_SUBMIT_DATA:
            return {
                ...state,
                requestType: action.payload.requestType
            }
        case actionTypes.USER_SUBMITED_RESULTS:
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.USER_SUBMIT_DATA) || {};
      
            if (apiOutcome?.requestType === 'delete') {
                return {
                    ...state,
                    currentUser: apiOutcome?.success ? null :  state.currentUser
                }
            }else { 
                return {
                    ...state,
                    currentUser: apiOutcome?.success ? action.payload : state.currentUser 
                }
            }
        case actionTypes.TALBE_ROW_HOVER_EVENT :
            return {
            ...state,
            currentUser: action.payload.user
        }
        case actionTypes.CONTEXT_MENU_EVENT :
            return {
            ...state,
            rowUserContext: action.payload
        }
        case actionTypes.USER_EDIT_SETDATA :
            const {role, ...formData } = action.payload
            
            const data  = {
                ...state,
                assignedRole: role,
                userForm: formData 
            }
            return data;

        case actionTypes.AUTH_ACCESS_SUBMITED_RESULT:

            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.AUTH_ACCESS_SUBMIT_DATA);

            return {
                ...state,
                authAccessRequest: {
                    message : action.payload.message,
                    ...apiOutcome
                }
            };
        
        default:
    }
    return state;
};

export default usersReducer;