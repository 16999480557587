import { requests } from './agentDialect';
import { objectToQueryString } from '../utility/helpers';

export const fetchAllPayments = (payload) => { 
    const {clientId, ...queryParams} = payload;
    queryParams['client'] = clientId;
    let uri = "/payments" ;
    uri = objectToQueryString(uri, queryParams);

    return requests.get(uri);
}; 

export const fetchPaymentsSummary = (client) => {
    let uri =  client === undefined ? "/payments/summary" : `/payments/summary?client=${client}`;
    return requests.get(uri);
}; 

export const submitPayment = (data) => {
    let uri = "/payment/create";
    return requests.post(uri, data);
};
export const updatePayment = (data) => {
    let uri = `/payment/verify/${data.paymentId}`;
    return requests.put(uri, data);
};