import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setupPage } from '../../components/Layout/setupPage';
import { Overview, Recent, Authorize, ResultSheet, Requisition } from '.';
import {ResultSheetHead } from './../components/partials/ResultSheetHead';
import classnames from 'classnames';
import * as urls from '../../config/urls';
import { ContextMenu, MenuItem } from "react-contextmenu";

import * as actionTypes from '../../store/actionType';
import  * as actions  from '../../store/actions';
import  { PermissionEnforcer }  from '../../utility/PermissionEnforcer';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
import FileUploader from '../../utility/FileUploader';
import { ACTION_BAR_MODES, STATUS_MAP } from './../../common/constant';
import {SpecimenSupervisor } from './../../utility/SpecimenSupervisor';
import SlidingPane from "react-sliding-pane";
import { CloseButton } from '../components/partials/Buttons';
import { Loader } from '../components/partials/LoadingIndicator';
import ConfirmModal from '../components/partials/ConfirmModal';
import CabinetModal from './FileCabinet/CabinetModal';
import FileCabinet,  {CabinetItem} from './FileCabinet/FileCabinet';
import {SEARCH_SETTINGS } from '../../config/searchable';
import './Specimen.style.scss';

import { 
    Nav,
    NavItem,
    NavLink,
    Button,
    Authority
 } from '../../components';

import { getIconUrl } from '../../common/images-catalogue';

const mapStateToProps = state => ({
    apiRequestManager: ApiRequestManager.getInstance(ApiRequestManager),
    specimenSupervisor: SpecimenSupervisor.getInstance(SpecimenSupervisor),
    useClientLayout: state.global.useClientDashboard,
    authorizeUser: state.global.authorizeUser,
    mode:  state.global.actionBarMode,
    workspace: state.global.workspace,
    previousRequisitionPage: state.specimen.requisitionsState.previousRequisitionPage,
    ...state.specimen.samplesState
});

const mapDispatchToProps = dispatch => ({
    onLoad: () => dispatch(actions.specimenRecordLoadedAction()),
    unLoad: () => dispatch(actions.specimenViewUnLoadAction()),
    setLazyLoad: (type, yesOrNo) => dispatch(actions.setLazyLoadingAction(type, yesOrNo)),
    setMode: (mode) => dispatch(actions.actionBarModeAction(mode)),
    getSpecimens: (requestParams) => dispatch(actions.getAllSpecimenRecordsAction(requestParams)),
    getApprovedSpecimens: (workspace) => dispatch(actions.getAllSpecimenRecordsApproved(workspace)),
    getRecentSpecimens: (workspace) => dispatch(actions.getRecentSpecimenRecordsAction(workspace)),
    setUpdateSpecimenData: (data) => dispatch(actions.setUpdateSpecimenDataAction(data)),
    rowDidMouseOver: (contextData) => dispatch(actions.tableRowMouseOverAction(contextData)),
    contextMenuEvent: (inContext) => dispatch(actions.contextMenuEventAction(inContext)),
    onResultSheet: (speciment) => dispatch(actions.specimenResultSheetAction(speciment)),
    submitResultData: (payload) => dispatch(actions.submitResultSheet(payload)),
    changeStatus: (status) => dispatch(actions.specimenAddStatus(status)),
    getCabinets: () => dispatch(actions.getCabinetsAction()),
    setCabinetContextItem: (cabinet) => dispatch(actions.setCabinetsContextItemAction(cabinet)),
    getSpecimensForCabinet: (payload) => dispatch(actions.getSpecimensForCabinetAction(payload)),
    clearSpecimentData: () => dispatch(actions.clearSpecimentDataAction()),
    setRequisitionPageNumber: (pageNumber) => dispatch(actions.setRequisitionPageNumberAction(pageNumber)),
});

function offset(el) {
    var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

const SimpleContextMenu = (props) => {
    const {contextMenuOptClick, specimenSupervisor} = props;
    
    return (
        <React.Fragment>
             <MenuItem 
            data={{opt: 'view'}} onClick={contextMenuOptClick}>
                View Details
            </MenuItem>
            <MenuItem disabled={!specimenSupervisor.showClientsResultSheet()} 
            data={{opt: 'resultSheet'}} onClick={contextMenuOptClick}>
                Result Sheet
            </MenuItem>
        </React.Fragment>
    );
}

class Specimen extends React.Component {

    static pageOption = {
        pageTitle: 'Samples',
        searchContext: SEARCH_SETTINGS.entities.record.name,
        disableBodyScroll: true,
        statusDialogVisible: false
    };

    subViews = {
        overview: 'overview',
        authorize: 'authorize',
        recent: 'recent',
        requisition: 'requisition',
        cabinet: 'cabinet',
    }

    cabinetDefaults = {
        selectedCabinetId: -1,
        cabinetIdentifier: null,
        color: ''
    };
    
    constructor(props) {
        super(props);

        props.apiRequestManager.register(actionTypes.FETCH_SPECIMEN_RECORDS, props.getSpecimens);
        props.apiRequestManager.register(actionTypes.FETCH_SPECIMEN_RECORDS_APPROVED, props.getApprovedSpecimens);
        props.apiRequestManager.register(actionTypes.FETCH_RECENT_SPECIMEN_RECORDS, props.getRecentSpecimens);
        props.apiRequestManager.register(actionTypes.RESULT_SHEET_SUBMIT, props.submitResultData);
        props.apiRequestManager.register(actionTypes.FETCH_CABINETS, props.getCabinets);
        props.apiRequestManager.register(actionTypes.FETCH_SPECIMENS_FOR_CABINET, props.getSpecimensForCabinet);
    
        this.state = {
            cabinetVisible: false,
            activeView:  props.activePage || 'overview',
            isPaneOpen: false,
            lastScrollYPos: null,
            selectedCabinet: this.cabinetDefaults,
            cabinetMode: ACTION_BAR_MODES.create,
        }

        this.switchView = this.switchView.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.onAddSpecimen = this.onAddSpecimen.bind(this);
        this.contextMenuWillAppear = this.contextMenuWillAppear.bind(this);
        this.contextMenuDismissed = this.contextMenuDismissed.bind(this);
        this.contextMenuOptClick = this.contextMenuOptClick.bind(this);
        this.updateSpecimentAction = this.updateSpecimentAction.bind(this);
        this.willHandleTableRowMouseOver = this.willHandleTableRowMouseOver.bind(this);
        this.showResultSheet = this.showResultSheet.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.showHideConfimStatusDialog = this.showHideConfimStatusDialog.bind(this);
        this.onConfirmStatus = this.onConfirmStatus.bind(this);
        this.showHideCabinetModal = this.showHideCabinetModal.bind(this);
        this.handleEditCabinateItem = this.handleEditCabinateItem.bind(this);
        this.resetCabinetStates = this.resetCabinetStates.bind(this);

        this.fileInputField = React.createRef();
        this.props.specimenSupervisor.clientDashboard = this.props.useClientLayout;
    }

    componentDidMount() {
        this.props.onLoad();
    
        this.props.viewInit && window.addEventListener('REACT_CONTEXTMENU_SHOW', this.contextMenuWillAppear);
        this.props.viewInit && window.addEventListener('REACT_CONTEXTMENU_HIDE', this.contextMenuDismissed);
        const contentEl =  document.querySelector('.spmt-Ly-ct');
        contentEl.addEventListener('scroll', this.handleScroll);
        //document.body.classList.add('diable-scroll'); 

        if(!this.props.cabinetsData && !this.props.apiRequestManager.inProgress(actionTypes.FETCH_CABINETS)) {
            const permissionEnforcer = PermissionEnforcer.getInstance(PermissionEnforcer);

            if (permissionEnforcer.isPermitted('cabinet', 'view')) {
                this.props.apiRequestManager.queueRequest(actionTypes.FETCH_CABINETS);
            }
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const cabinetContextItem = nextProps.cabinetContextItem;
        const selectedCabinet = prevState.selectedCabinet;

        /**
         * Updates selectedCabinet if the selectedCabinet was updated from the modal view.
         */
        const shouldUpdateCabinet = cabinetContextItem && (cabinetContextItem.id === selectedCabinet.selectedCabinetId) && ((cabinetContextItem.color !== selectedCabinet.color) || (cabinetContextItem.identifier !== selectedCabinet.identifier));
        
        if(shouldUpdateCabinet){
            return {
                selectedCabinet: {
                    selectedCabinetId: cabinetContextItem.id,
                    cabinetIdentifier: cabinetContextItem.identifier,
                    color: cabinetContextItem.color
                }
            }
        }
        /**
         * ---- BLOCK RELATED FUNCTIONALITY ----
         * Set the requisition page number to 0, when transition to another view
         */
        if ( nextProps.previousRequisitionPage > 0 && prevState.activeView !== 'requisition' ) {
            nextProps.setRequisitionPageNumber(0);
        }
        /** ---- END OF BLOCK ---- */

        // Return null to indicate no change to state.
        return null;
    }

    resetCabinetStates() {
        this.setState({ 
            activeView: this.subViews.overview,
            selectedCabinet: this.cabinetDefaults
        });
    }
    
    componentWillUnmount() {
        const contentEl =  document.querySelector('.spmt-Ly-ct');
        contentEl.removeEventListener('scroll', this.handleScroll);
        this.props.specimenSupervisor.retire();

        window.removeEventListener('REACT_CONTEXTMENU_SHOW', this.contextMenuWillAppear);
        window.removeEventListener('REACT_CONTEXTMENU_HIDE', this.contextMenuDismissed);
        //this.props.unLoad();
        this.props.apiRequestManager.abortRequest(actionTypes.FETCH_CABINETS);
    }

    onAddSpecimen(evt) {
        this.props.history.push({ pathname: urls.specimensCreateUri});
    }

    willHandleTableRowMouseOver(rowData) {
        (this.props.rowSpecimenContext === undefined || !this.props.rowSpecimenContext) && this.props.rowDidMouseOver(rowData);
    }
    /* Context Menu Actions */
    updateSpecimentAction(evt, rowSpecimen ) {
        this.handleSpecimentRecordClick(this.props.rowSpecimen || rowSpecimen);
    }

    approvedSpecimenAction(evt, rowSpecimen) {
        // this.props.rowSpecimen
        this.props.specimenSupervisor.specimen = this.props.rowSpecimen || rowSpecimen;
        this.props.specimenSupervisor.approveResultSheet(this.props.apiRequestManager, actionTypes.RESULT_SHEET_SUBMIT);
    }

    addResultSheetAction(evt, rowSpecimen) {
        this.props.setUpdateSpecimenData({specimen: this.props.rowSpecimen || rowSpecimen});
        this.setState({isPaneOpen: true});
        //this.handleSpecimentRecordClick(this.props.rowSpecimen);
    }

    handlerChangeStatusAction(evt, rowSpecimen) {
        const specimen = this.props.rowSpecimen || rowSpecimen;
        const validStatuses = STATUS_MAP[specimen.statuses.last().status];
        const payload = {
            status: validStatuses[0],
            recordId: specimen.id
        }
        this.props.changeStatus(payload);
    }  
    /* ----End of Context Menu Actions--- */

    contextMenuWillAppear(evt) {
        const specimenRecordId = parseInt(evt.detail.target.getAttribute('data-specimenid'));
        this.props.contextMenuEvent(specimenRecordId);
    }
    contextMenuDismissed(evt) {
        this.props.contextMenuEvent(false);
    }
    
    handleScroll(event) {
        //let scrollTop = event.srcElement.body.scrollTop,
        let workspaceBar = document.querySelector('.workspace-bar');
        let barHeight = workspaceBar ? workspaceBar.offsetHeight: 0; //Math.min(0, scrollTop/3 - 60);
        
        if (this.state.activeView !== 'overview' 
        && this.state.activeView !== 'recent' && this.state.activeView !== 'authorize')
            return;

        let scrollTop = event.srcElement.scrollTop;
        let scollElementPosY = (offset(event.target).top  ) - 3 ;
        //console.log(scrollTop);
    
        const container = document.getElementById('mainTable');
        //.querySelector('table.table-responsive');
        const tableHead = document.querySelector('table#mainTable thead');
        const scrollYPos = container.getBoundingClientRect().top - scollElementPosY;

        scrollYPos <= -1 ? tableHead.classList.add('detach'): tableHead.classList.remove('detach');
        //const docElementHeight = document.documentElement.scrollHeight;
        const scrollElementHeight = Math.round(event.srcElement.scrollHeight + scollElementPosY);

        if ((scrollTop  + window.innerHeight + barHeight + 145) > scrollElementHeight) {
            // console.log("you're at the bottom of the page");
            const isScrollingDown = scrollTop > this.state.lastScrollYPos;
            if(isScrollingDown){
                this.props.setLazyLoad(actionTypes.SPECIMEN_SET_LAZY_LOADING, true);
            }
            this.setState({lastScrollYPos: scrollTop});
        }
        
        if ((window.scrollY + window.innerHeight) >= (scrollElementHeight)) {
            // console.log("you're at the bottom of the page");
        }
        //console(scrollTop);
    }

    switchView(evt, cabinetData) {
        evt.preventDefault();
        const view = evt.target.getAttribute("to");

        if (this.state.activeView !== view) {
            this.setState({ 
                activeView: view,
                selectedCabinet: view !== this.state.selectedCabinet ? this.cabinetDefaults : this.state.selectedCabinet
            });
        }
    
        cabinetData && this.setState({
            selectedCabinet : {
                selectedCabinetId: cabinetData.id,
                cabinetIdentifier: cabinetData.identifier,
                color: cabinetData.color
            }
        });
    }

    contextMenuOptClick(evt, data, element) {
        const specimenRecordId = parseInt(element.getAttribute('data-specimenid'));
        const tableId = element.closest('table').id;
        let tableDataKey = '';

        switch(tableId) {
            case 'weekTable':
                tableDataKey = 'weekRecords';
                break;
            case 'mainTable':
                tableDataKey = 'monthRecords';
                break;
            default:
                tableDataKey = 'todaysRecords';
                break;
        }

        const tableData = this.props.specimensData || (this.props.recentSpecimensData && this.props.recentSpecimensData[tableDataKey]) || (this.props.approvedSpecimensData && this.props.approvedSpecimensData[tableDataKey]);
        
        let contextSpecimenRecord =  tableData.find((specimenRecord) => 
            specimenRecord.id === specimenRecordId);
            
        this.props.specimenSupervisor.specimen = contextSpecimenRecord;
        contextSpecimenRecord && this.props.rowDidMouseOver({specimen: contextSpecimenRecord});
        
        const permissionEnforcer = PermissionEnforcer.getInstance(PermissionEnforcer);

        switch(data.opt){
            case 'view':
                if (!permissionEnforcer.hasRights('view', 'record')) return
                return this.updateSpecimentAction(evt, contextSpecimenRecord);
            case 'resultSheet':
                if (!permissionEnforcer.hasRights('view', 'resultsheet')) return
                return this.addResultSheetAction(evt, contextSpecimenRecord);
            case 'changeStatus':
                if (!permissionEnforcer.hasRights('change', 'recordstatus')) return
                return this.showHideConfimStatusDialog(evt, contextSpecimenRecord);
            case 'addFile':
                if (!permissionEnforcer.hasRights('change', 'record')) return
                return this.handleFileFieldClick(evt, contextSpecimenRecord);
            case 'delete':
                if (!permissionEnforcer.hasRights('delete', 'record')) return
                return this.deleteSpecimenAction(evt, contextSpecimenRecord);
            case 'authorize':
                if (!permissionEnforcer.hasRights('autorize', 'resultshet')) return
                return this.approvedSpecimenAction(evt, contextSpecimenRecord);
            default:
        }
    }

    handleFileFieldClick(evt) {
        this.fileInputField.current.click();
    }

    handleFileUpload(evt) {
        //const field = this.fileInputField.current.files; //evt.target;
        var files = this.fileInputField.current.files;
        if(files.length === 0) {
            return;
        }
        var file = files[0];

        //uploadSingleFile(files[0]);
        const uploader = FileUploader.getInstance(FileUploader);
        uploader.upload(file, 'result_sheet');
        evt.preventDefault();
    }

    handleSpecimentRecordClick = (data) => {
        
        this.props.setMode(ACTION_BAR_MODES.edit);
        this.props.setUpdateSpecimenData({specimen: data});
        this.props.history.push(urls.specimensUpdateUri);
    }
    
    showResultSheet(evt) {
        this.setState({ isPaneOpen: true });
    }

    showHideConfimStatusDialog(contextItem) {
        if (this.state.statusDialogVisible ) {
            this.props.specimenSupervisor.specimen = null;
        }
        
        this.setState({
            statusDialogVisible: !this.state.statusDialogVisible,
        });
    }

    onConfirmStatus(contextItem) {
        this.handlerChangeStatusAction();
        this.showHideConfimStatusDialog();
    }

    showHideCabinetModal(visible, mode) {
        this.setState({
            cabinetVisible: visible,
            cabinetMode: mode
        });
    }

    handleEditCabinateItem(event, cabinateId){
        event.preventDefault();
        
        const contextItem = Array.isArray(this.props.cabinetsData) ? this.props.cabinetsData.find(cabinate => cabinate.id === cabinateId) : null;
        this.props.setCabinetContextItem(contextItem);
        this.showHideCabinetModal(true, ACTION_BAR_MODES.edit);
    }

    render () {
        const specimenSupervisor = this.props.specimenSupervisor;
        specimenSupervisor.specimen = this.props.rowSpecimen;

        const quickActionCallback = {
            'editAction' : this.updateSpecimentAction,
        };
        
        const contextMenuOptClick = this.contextMenuOptClick;
        const permissionEnforcer = PermissionEnforcer.getInstance(PermissionEnforcer);
        const showMinimalContextMenu = specimenSupervisor.showMinimalContextMenu();        
        const nextStatus = STATUS_MAP[specimenSupervisor.getStatus()];

        const cabinets = this.props.cabinetsData;
        const isFetchingCabinet = this.props.apiRequestManager.inProgress(actionTypes.FETCH_CABINETS);

        const getAuthorizeNavItem = () => {

            return (!this.props.useClientLayout && 
                <Authority allowed='view' entity='requisition'>
                    <NavItem>
                        <NavLink to={this.subViews.requisition} onClick={this.switchView} className={classnames({ active: this.state.activeView === this.subViews.requisition }) } ><span className="icon">
                            <img src={getIconUrl("requisition")}/></span>Requisition</NavLink>
                    </NavItem>
                </Authority>
            );
        }

        return (
            <div className='content-layout-wrap specimen-list'>
               <div className="sidebar">
                    <div className="button-wrap">
                        <Authority  allowed="create" entity="record" >
                            <Button className="default-btn add-btn" onClick={this.onAddSpecimen}>
                                <span className="icon">
                                    <img src={getIconUrl("sample")} alt=''/>
                                </span>
                                Add New
                            </Button>
                        </Authority>
                    </div>
                    <div className="nav-container">
                        
                        <nav className="sidebar-nav">
                            { permissionEnforcer.isUserAuthorizer() ? 
                            <Nav>
                                <NavItem>
                                    <NavLink to={this.subViews.overview} onClick={this.switchView} className={classnames({ active: this.state.activeView === this.subViews.overview })}><span className="icon"><img src={getIconUrl("overview")}/></span>Awaiting Approval</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink to={this.subViews.authorize} onClick={this.switchView} className={classnames({ active: this.state.activeView === this.subViews.authorize })}><span className="icon"><img src={getIconUrl("results")}/></span>Approved</NavLink>
                                </NavItem>

                                { 
                                    getAuthorizeNavItem()
                                }
                            </Nav> 
                            
                            : 
                        <Nav>
                            <NavItem>
                               <NavLink to={this.subViews.overview} onClick={this.switchView} className={classnames({ active: this.state.activeView === this.subViews.overview })}><span className="icon"><img src={getIconUrl("overview")}/></span>Overview</NavLink>
                            </NavItem>
                            
                            { 
                                getAuthorizeNavItem()
                            }

                            <NavItem>
                                <NavLink to={this.subViews.recent} onClick={this.switchView} className={classnames({ active: this.state.activeView === this.subViews.recent }) } ><span className="icon"><img src={getIconUrl("recent")}/></span>Recent</NavLink>
                            </NavItem>
                            
                            { this.props.useClientLayout ? 
                            <NavItem>
                                <NavLink to={this.subViews.authorize} onClick={this.switchView} className={classnames({ active: this.state.activeView === this.subViews.authorize })}><span className="icon"><img src={getIconUrl("results")}/></span>Results</NavLink>
                            </NavItem>
                            :
                            <NavItem>
                                <NavLink to={this.subViews.authorize} onClick={this.switchView} className={classnames({ active: this.state.activeView === this.subViews.authorize })}><span className="icon">
                                    <img src={getIconUrl("authorize")} alt=''/>
                                    </span>Authorized</NavLink>
                            </NavItem>
                            }
                            { !this.props.useClientLayout && 
                                <div className='cabinet-contents'>
                                    <h3>Folders</h3>
                                    <Authority allowed='create' entity='cabinet'>
                                    <Button 
                                    onClick={(evt) => this.showHideCabinetModal(true, ACTION_BAR_MODES.create)}
                                    className='add-cabinet-btn'>
                                        <span className='icon'>
                                            <img src={getIconUrl("plus")} alt=''/>
                                        </span>
                                        Add Folder
                                    </Button>
                                    </Authority>
                                    <Authority allowed='view' entity='cabinet'>
                                    <div className='folder-content-list'>
                                    { isFetchingCabinet ? 
                                        <Loader visible={isFetchingCabinet} loadingText={''} />
                                        
                                        :
                                        
                                        <React.Fragment>
                                        { cabinets?.empty() && (
                                            <p className='no-folder-msg'>No folders created</p>
                                        )}   
                                        {Array.isArray(cabinets) && cabinets?.map((cabinetData) => {
                                            return (
                                                <CabinetItem
                                                key={cabinetData.id}
                                                label={cabinetData.label}
                                                onClick={this.switchView}
                                                onEdit={this.handleEditCabinateItem}
                                                identifier={cabinetData.identifier}
                                                id={cabinetData.id}
                                                selectedCabinetId={this.state.selectedCabinet.selectedCabinetId}
                                                color={cabinetData.color}
                                                />
                                            )
                                        })}
                                        </React.Fragment>
                                        }
                                    
                                    </div>
                                    </Authority>
                                </div>
                                
                            }
                        </Nav>
                        }
                        </nav>
                    </div>
               </div>
               <div className="v-line"></div>
               <div className="view-layout-content container">
                   <div className="spmt-Ly-ct">
                       
                       { ( this.state.activeView === 'overview' && <Overview quickActionCallback={quickActionCallback} 
                       handleSpecimentCardClick={this.handleSpecimentRecordClick} 
                       willHandleTableRowMouseOver={this.willHandleTableRowMouseOver}
                       {...this.props} /> )}

                        { ( this.state.activeView === 'requisition' && <Requisition quickActionCallback={quickActionCallback} 
                       handleSpecimentCardClick={this.handleSpecimentRecordClick} 
                       willHandleTableRowMouseOver={this.willHandleTableRowMouseOver}
                       {...this.props} /> )}

                       { ( this.state.activeView === 'recent' && <Recent 
                       quickActionCallback={quickActionCallback}
                       handleSpecimentCardClick={this.handleSpecimentRecordClick} 
                       willHandleTableRowMouseOver={this.willHandleTableRowMouseOver}
                       {...this.props} /> )}
                       
                       { ( this.state.activeView === 'authorize' && <Authorize 
                       quickActionCallback={quickActionCallback}
                       willHandleTableRowMouseOver={this.willHandleTableRowMouseOver}
                       {...this.props} /> )}

                      { ( this.state.activeView === 'filecabinet' && <FileCabinet 
                       quickActionCallback={quickActionCallback}
                       willHandleTableRowMouseOver={this.willHandleTableRowMouseOver}
                       selectedCabinetId={this.state.selectedCabinet.selectedCabinetId}
                       identifier={this.state.selectedCabinet.cabinetIdentifier}
                       color={this.state.selectedCabinet.color}
                       {...this.props} /> )}

                        <Loader  fixed visible={this.props.lazyLoad} loadingText={''} />
                       
                       <ContextMenu ref={this.contextMenuRef}id="specimen_table_context_menu">

                       { showMinimalContextMenu ? 
                        <SimpleContextMenu 
                        specimenSupervisor={specimenSupervisor}
                        contextMenuOptClick={contextMenuOptClick}/>
                       :
                       <React.Fragment>   
                            <MenuItem data={{opt: 'view'}} onClick={contextMenuOptClick}>
                                View Details
                            </MenuItem>

                            { specimenSupervisor.isApproved() || !permissionEnforcer.isUserAuthorizer() ? null :
                            <MenuItem disabled={permissionEnforcer.isUserAuthorizer() && !specimenSupervisor.canAuthorizerChangeStatus()} data={{opt: 'authorize'}} onClick={contextMenuOptClick}>
                                Authorize
                            </MenuItem>
                            }
                            
                            <MenuItem divider />
                            { !specimenSupervisor.showStatusOption() ? null : 
                            <MenuItem disabled={!specimenSupervisor.enableStatusChange() && !specimenSupervisor.canAuthorizerChangeStatus() } data={{opt: 'changeStatus'}} onClick={contextMenuOptClick}>
                                Change Status
                            </MenuItem>
                             }
                            <MenuItem 
                            disabled={!specimenSupervisor.showResultSheet() && !specimenSupervisor.showAutorizerResultSheet()}
                            data={{opt: 'resultSheet'}} onClick={contextMenuOptClick}>
                                { specimenSupervisor.hasResultSheet() ? 'Result Sheet' : 'Add Result Sheet'}
                            </MenuItem>
                            <MenuItem disabled={!specimenSupervisor.canAttachFile()} data={{opt: 'addFile'}} onClick={contextMenuOptClick}>
                                Attach File
                            </MenuItem>
                            {/*
                            <MenuItem disabled={!specimenSupervisor.specimenDeletable()} data={{opt: 'delete'}} onClick={contextMenuOptClick}>
                                Delete
                            </MenuItem>
                            */}
                            </React.Fragment> 
                            
                        }
                        </ContextMenu>
                   </div>
                   <input 
                    ref={this.fileInputField}
                    onChange={this.handleFileUpload}
                    id="fileInput" 
                    type='file' 
                    name="file" 
                    style={ {"display": "none"}}/>
               </div>
               
               <SlidingPane
                    closeIcon={<CloseButton />}
                    isOpen={this.state.isPaneOpen}
                    title={<ResultSheetHead title={"ResultSheet"}/>}
                    from="bottom"
                    width="100%"
                    onRequestClose={() => this.setState({ isPaneOpen: false })}
                >
                    <ResultSheet/>
                </SlidingPane>

                {
                    this.state.statusDialogVisible && 
                    <ConfirmModal 
                        contextName={'specimen record'}
                        contextItem={null} 
                        isDelete={false}
                        title={`Change the status to ${nextStatus ? nextStatus[0] : ''}`}
                        cancelCallback={this.showHideConfimStatusDialog}
                        confirmCallback={this.onConfirmStatus}
                    />
                }
                { this.state.cabinetVisible &&
                    <CabinetModal 
                        showHideModal={this.showHideCabinetModal}
                        cabinetMode={this.state.cabinetMode}
                        cabinetContextItem={this.props.cabinetContextItem}
                        setCabinetContextItem={this.props.setCabinetContextItem}
                        clearCabinetStates={this.resetCabinetStates}
                        {...this.props}
                    />
                }
 
            </div>
        );
    }
}

const SpecimenView = (props) => {
    return (
        <Specimen {...props } />
    );
};

let ConfigSpecimenView = setupPage(Specimen.pageOption)(SpecimenView);
ConfigSpecimenView =  connect(mapStateToProps, mapDispatchToProps)(ConfigSpecimenView);

export { ConfigSpecimenView };