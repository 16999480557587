import { take, put, call, fork, select, all, takeLatest} from 'redux-saga/effects';
import *  as actionTypes from '../actionType';
import * as commonDialect  from '../../service/commonDialect';
import { notifications } from '../actions/common';
import { unreadMessages } from '../actions/message';

import { denormalizeResponse, setMetaData } from './../../service/httpRequestDialect';
import { notificationsArraySchema, unreadMessagesArraySchema } from '../../service/schemas';
import ApiError from '../../utility/error/ApiError';


// each entity defines 3 creators { request, success, failure }
const notificationsActions = notifications;
const unreadMessagesActions = unreadMessages;

/**
 *  WORKERS 
 * --------------------------------------------------------------
 */

// reusable fetch Subroutine
// entity :  user | repo | starred | stargazers
// apiFn  : api.fetchUser | api.fetchRepo | ...
// id     : login | fullName
// url    : next page url. If not provided will use pass id to apiFn
function* fetchEntity(entity, apiFunc, payload) {
    yield put( entity.request() )
    const { response, error } = yield call(apiFunc, payload)
    return {response, error};
}

// yeah! we can also bind Generators
const doFetchNotifications = fetchEntity.bind(null, notificationsActions, commonDialect.getNotifications);
const doFetchUnreadMessages = fetchEntity.bind(null, unreadMessagesActions, commonDialect.getUnreadMessages);

// Fetches data for a User : user data + starred repos
function* parallelFetchMessagesNotifications(action) {

    // correct, effects will get executed in parallel
    setMetaData({ initiator:  action.type });
    const messages = yield call(doFetchUnreadMessages, action.payload);
    const notifications = yield call(doFetchNotifications, action.payload);
  
    if (messages.error) 
        yield put(unreadMessagesActions?.failure(messages.error));

    if (notifications.error) 
        yield put(notificationsActions?.failure(notifications.error));

    if (messages.error && !notifications.error) {
        yield put({type: actionTypes.FETCHED_GLOBAL_DATA_RESULTS, payload : {
            unreadMessages: new ApiError(),
            notifications:  denormalizeResponse(messages.response.result, unreadMessagesArraySchema, messages.response.entities)
        }});
    }
        
    if (notifications.error && !messages.error) 
        yield put({type: actionTypes.FETCHED_GLOBAL_DATA_RESULTS, payload : {
            unreadMessages: denormalizeResponse(messages.response.result, unreadMessagesArraySchema, messages.response.entities),
            notifications: new ApiError()
        }});
    
    if (messages.response && notifications.response)
        yield put({type: actionTypes.FETCHED_GLOBAL_DATA_RESULTS, payload : {
            unreadMessages: denormalizeResponse(messages.response.result, unreadMessagesArraySchema, messages.response.entities),

            notifications: denormalizeResponse(notifications.response.result, notificationsArraySchema, notifications.response.entities)
        }});
}

function* watchMessagesNotifications() {
    while(true) {
        const action = yield take(actionTypes.FETCH_GLOBAL_DATA);
        yield fork(parallelFetchMessagesNotifications, action);
    }
}

export default function* root() {
    yield all([
        fork(watchMessagesNotifications)
    ]);
}