import React from 'react';
import  * as actionTypes  from '../../../store/actionType';
import TemplateFooter from './TemplateFooter';

class Report_RP03 extends React.PureComponent  {
    
    componentDidMount() {
        const customizeReport = this.props.customize;
        customizeReport && this.props.apiRequestManager.queueRequest(actionTypes.FETCH_LABCODES);
        
    }

    render () {

        const {ReportHeader, ReportFooter, ReportNotes} = this.props;
        const reportData = Array.isArray(this.props.reportResults) ? this.props.reportResults : [];
     
        return <React.Fragment>

            <div  className="print-template">
                { ReportHeader }
                <div className="report-body">

                    <table className="default">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Company</th>
                                <th>Account</th>
                                <th>Type</th>
                                <th>Labcode</th>
                            </tr>
                        </thead>
                        <tbody>
                            { reportData.map((client, i) => {
                                
                                return ( <tr key={i} className="smpl-row">
                                        <td>{client.firstName} {client.lastName}</td>
                                        <td>{client.clientInfo.officeName}</td>
                                        <td>{client.clientInfo.accountNo}</td>
                                        <td>{client.clientInfo.type}</td>
                                        <td>{client.clientInfo.labCode.code}</td>
                                    </tr>);
                            })}
                           
                            
                        </tbody>
                    </table>
                </div>
                { <TemplateFooter 
                    ReportFooter={ReportFooter}  
                    ReportNotes={ReportNotes}
                    /> 
                }
            </div>

        </React.Fragment> 
    }  
}

export default Report_RP03;