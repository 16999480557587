import React from 'react';
import {
    SimpleSelect,
    FormGroup,
    Input,
    Label
} from './../../../components';

import { STATUS } from './../../../common/constant';
const Filter_RP08 = (props) => {
    const {reportForm, actionType, reportFieldChange } = props;
    
    const handlePillSwitches = (evt) => {
        const fieldSet = {
            options: {
                ...reportForm.options,
                status: evt.target.value
            }
        }
        reportFieldChange(actionType, fieldSet);
    }

    const status = reportForm.options.status !== null ? reportForm.options.status: null;

    return <div className="opts-wrap">
        
        <FormGroup className="half hdn">
            <Label>By Status</Label>
            <div className="no-warp">
            <Label className="ip-radio-pill">
            <Input 
             onChange={handlePillSwitches } 
             type="radio"
             name="status" value={STATUS.Sent} 
             checked={status && status === STATUS.Sent ? true : false}/>{' '}
                <span className="radioselect"></span>
                <span className="text">{STATUS.Sent}</span>
            </Label>

            <Label className="ip-radio-pill">
            <Input 
             onChange={handlePillSwitches } 
             type="radio"
             name="status" value={STATUS.Partial} 
             checked={status && status === STATUS.Partial ? true : false}/>{' '}
                <span className="radioselect"></span>
                <span className="text">{STATUS.Partial}</span>
            </Label>

            <Label className="ip-radio-pill">
            <Input onChange={handlePillSwitches } 
            type="radio" name="status" 
            value={STATUS.Paid}
            checked={status && status === STATUS.Paid? true : false}
             />{' '}
                <span className="radioselect"></span>
                <span className="text">{STATUS.Paid}</span>
            </Label>
            </div>
        </FormGroup>
    </div>
}

export default Filter_RP08;