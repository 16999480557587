//AUTHENTICATE REDUX KEYS
const NAMESPACE = '@cytolab/';
export const PING_SERVER = `${NAMESPACE}PING_SERVER`;
export const PING_SERVER_DONE = `${NAMESPACE}PING_SERVER_DONE`;
export const NETWORK_ERROR = `${NAMESPACE}NETWORK_ERROR`;
export const NETWORK_STATUS = `${NAMESPACE}NETWORK_STATUS`;
export const REDIRECT = `${NAMESPACE}REDIRECT`;
export const REDIRECT_TO = `${NAMESPACE}REDIRECT_TO`;
export const APP_LOADED = `${NAMESPACE}APP_LOADED`;
export const APP_UNLOAD = `${NAMESPACE}APP_UNLOAD`;
export const APP_ROUTE_CHANGE = `${NAMESPACE}APP_ROUTE_CHANGE`;
export const ROUTER_ROUTE_CHANGE = `${'@@router/LOCATION_CHANGE'}`;
export const LOGIN = `${NAMESPACE}LOGIN`;
export const AUTH_RESULTS = `${NAMESPACE}AUTH_RESULTS`;
export const LOGOUT = `${NAMESPACE}LOGOUT`;
export const LOGIN_VIEW_UNLOAD = `${NAMESPACE}LOGIN_VIEW_UNLOAD`;
export const LOGIN_VIEW_LOADED = `${NAMESPACE}LOGIN_VIEW_LOADED`;
export const SET_LOGIN_VIEW = `${NAMESPACE}SET_LOGIN_VIEW`;
export const AUTHENTICATED_USER_FALIURE = `${NAMESPACE}AUTHENTICATED_USER_FALIURE`;
export const ASYNC_START = `${NAMESPACE}ASYNC_START`;
export const ASYNC_ENDED = `${NAMESPACE}ASYNC_ENDED`;
export const ASYNC_COMPLETED = `${NAMESPACE}ASYNC_COMPLETED`;
export const REFRESH_JWT_TOKEN = `${NAMESPACE}REFRESH_JWT_TOKEN`;
export const REFRESH_JWT_TOKEN_RESULT = `${NAMESPACE}REFRESH_JWT_TOKEN_RESULT`;
export const AUTH_FIELD_CHANGED = `${NAMESPACE}AUTH_FIELD_CHANGED`;
export const USER_DETAILS = `${NAMESPACE}USER_DETAILS`;
export const REQEUST_AUTH_USER = `${NAMESPACE}REQEUST_AUTH_USER`;
export const REQUEST_AUTH_USER_RESULT = `${NAMESPACE}REQUEST_AUTH_USER_RESULT`;
export const ACTION_BAR_MODE = `${NAMESPACE}ACTION_BAR_MODE`;
export const DASHBAORD_LAYOUT = `${NAMESPACE}DASHBAORD_LAYOUT`;
export const TALBE_ROW_HOVER_EVENT = `${NAMESPACE}TALBE_ROW_HOVER_EVENT`;
export const CONTEXT_MENU_EVENT = `${NAMESPACE}CONTEXT_MENU_EVENT`;
export const ABORT_API_REQUEST = `_ABORT`;
export const FETCH_GLOBAL_DATA = `${NAMESPACE}FETCH_GLOBAL_DATA`;
export const FETCHED_GLOBAL_DATA_RESULTS = `${NAMESPACE}FETCHED_GLOBAL_DATA_RESULTS`;
export const DISMISS_NOTIFICATION = `${NAMESPACE}DISMISS_NOTIFICATION`;
export const DISMISS_NOTIFICATION_RESULTS = `${NAMESPACE}DISMISS_NOTIFICATION_RESULTS`;
export const RECEIVED_NOTIFICATION = `${NAMESPACE}RECEIVED_NOTIFICATION`;
export const FETCH_DASHBOARD_DATA = `${NAMESPACE}FETCH_DASHBOARD_DATA`;
export const FETCHED_DASHBOARD_DATA_RESULT = `${NAMESPACE}FETCHED_DASHBOARD_DATA_RESULT`;
export const DASHBOARD_VIEW_LOADED = `${NAMESPACE}DASHBOARD_VIEW_LOADED`;
export const DASHBOARD_VIEW_UNLOAD = `${NAMESPACE}DASHBOARD_VIEW_UNLOAD`;
export const CONTEXT_SEARCH = `${NAMESPACE}CONTEXT_SEARCH`;
export const CONTEXT_SEARCH_RESULTS = `${NAMESPACE}CONTEXT_SEARCH_RESULTS`;
export const SEARCH_FIELD_CHANGE = `${NAMESPACE}SEARCH_FIELD_CHANGE`;

//Code Sheet
export const FETCH_CODE_SHEETS = `${NAMESPACE}FETCH_CODE_SHEETS`;
export const FETCHED_CODE_SHEETS_RESULTS = `${NAMESPACE}FETCHED_CODE_SHEETS_RESULTS`;
export const SET_CODESHEETS_DATA = `${NAMESPACE}SET_CODESHEETS_DATA`;
export const CODESHEET_SUBMIT_DATA = `${NAMESPACE}CODESHEET_SUBMIT_DATA`;
export const CODESHEET_SUBMIT_DATA_RESULT = `${NAMESPACE}CODESHEET_SUBMIT_DATA_RESULT`;
//Code Finding
export const FETCH_CODE_FINDINGS = `${NAMESPACE}FETCH_CODE_FINDINGS`;
export const FETCHED_CODE_FINDINGS_RESULTS = `${NAMESPACE}FETCHED_CODE_FINDINGS_RESULTS`;
export const SET_CODEFINDINGS_DATA = `${NAMESPACE}SET_CODEFINDINGS_DATA`;
export const CODEFINDING_SUBMIT_DATA = `${NAMESPACE}CODEFINDING_SUBMIT_DATA`;
export const CODEFINDING_SUBMIT_DATA_RESULT = `${NAMESPACE}CODEFINDING_SUBMIT_DATA_RESULT`;
//Labcode
export const FETCH_LABCODES = `${NAMESPACE}FETCH_LABCODES`;
export const FETCHED_LABCODES_RESULTS = `${NAMESPACE}FETCHED_LABCODES_RESULTS`;
export const SET_LABCODE_DATA = `${NAMESPACE}SET_LABCODE_DATA`;
export const LABCODE_SUBMIT_DATA = `${NAMESPACE}LABCODE_SUBMIT_DATA`;
export const LABCODE_SUBMIT_DATA_RESULT = `${NAMESPACE}LABCODE_SUBMIT_DATA_RESULT`;
//Departments
export const FETCH_DEPARTMENTS = `${NAMESPACE}FETCH_DEPARTMENTS`;
export const FETCHED_DEPARTMENTS_RESULTS = `${NAMESPACE}FETCHED_DEPARTMENTS_RESULTS`;
export const SET_DEPARTMENT_DATA = `${NAMESPACE}SET_DEPARTMENT_DATA`;
export const DEPARTMENT_SUBMIT_DATA = `${NAMESPACE}DEPARTMENT_SUBMIT_DATA`;
export const DEPARTMENT_SUBMIT_DATA_RESULT = `${NAMESPACE}DEPARTMENT_SUBMIT_DATA_RESULT`;

/* Permissions Roles*/
export const PERMISSIONS_ROLES_LOADED = `${NAMESPACE}PERMISSIONS_ROLES_LOADED`;
export const PERMISSIONS_ROLES_VIEW_UNLOAD = `${NAMESPACE}PERMISSIONS_ROLES_VIEW_UNLOAD`;
export const FETCH_PERMISSIONS = `${NAMESPACE}FETCH_PERMISSIONS`;
export const FETCH_ROLES_PERMISSIONS = `${NAMESPACE}FETCH_ROLES_PERMISSIONS`;
export const FETCH_ROLES_PERMISSIONS_RESULTS = `${NAMESPACE}FETCH_ROLES_PERMISSIONS_RESULTS`;
export const FETCHED_PERMISSIONS_RESULTS = `${NAMESPACE}FETCHED_PERMISSIONS_RESULTS`;
export const FETCH_ROLES = `${NAMESPACE}FETCH_ROLES`;
export const FETCH_ROLES_RESULTS = `${NAMESPACE}FETCH_ROLES_RESULTS`;
export const PERMISSIONS_ROLE_VIEW = `${NAMESPACE}PERMISSIONS_ROLE_VIEW`;
export const PERMISSIONS_ROLE_TYPE = `${NAMESPACE}PERMISSIONS_ROLE_TYPE`;
export const PERMISSIONS_SELECTED_ROLE = `${NAMESPACE}PERMISSIONS_SELECTED_ROLE`;
export const CHOSEN_PERMISSIONS = `${NAMESPACE}CHOSEN_PERMISSIONS`;
export const SELECTED_SUPEUSER_ROLE = `${NAMESPACE}SELECTED_SUPEUSER_ROLE`;
export const ROLENAME_FILED_CHANGED = `${NAMESPACE}ROLENAME_FILED_CHANGED`;
export const PERMISSIONS_ROLE_SUBMIT_DATA = `${NAMESPACE}PERMISSIONS_ROLE_SUBMIT_DATA`;
export const PERMISSIONS_ROLE_SUBMITED_RESULTS = `${NAMESPACE}PERMISSIONS_ROLE_SUBMITED_RESULTS`;
export const PERMISSIONS_BACK_T0_ACTION = `${NAMESPACE}PERMISSIONS_BACK_T0_ACTION`;
export const FETCH_USER_WORKSPACE_ROLES = `${NAMESPACE}FETCH_USER_WORKSPACE_ROLES`;
export const FETCHED_USER_WORKSPACE_ROLES_RESULTS = `${NAMESPACE}FETCHED_USER_WORKSPACE_ROLES_RESULTS`;

/* Users */
export const USERS_VIEW_LOADED = `${NAMESPACE}USERS_VIEW_LOADED`;
export const USERS_VIEW_UNLOAD = `${NAMESPACE}USERS_VIEW_UNLOAD`;
export const USER_CREATE_VIEW_LOADED = `${NAMESPACE}USER_CREATE_VIEW_LOADED`;
export const USER_CREATE_VIEW_UNLOAD = `${NAMESPACE}USER_CREATE_VIEW_UNLOAD`;
export const USER_EDIT_SETDATA = `${NAMESPACE}USER_EDIT_SETDATA`;
export const FETCH_USERS = `${NAMESPACE}FETCH_USERS`;
export const FETCH_USERS_RESULTS = `${NAMESPACE}FETCH_USERS_RESULTS`;
export const FETCH_USER = `${NAMESPACE}FETCH_USER`;
export const FETCH_USER_RESULT = `${NAMESPACE}FETCH_USER_RESULT`;
export const USERS_ASSIGNED_ROLE = `${NAMESPACE}USERS_ASSIGNED_ROLE`;
export const USER_FIELD_CHANGED = `${NAMESPACE}USER_FIELD_CHANGED`;
export const USER_SUBMIT_DATA = `${NAMESPACE}USER_SUBMIT_DATA`;
export const USER_SUBMITED_RESULTS = `${NAMESPACE}USER_SUBMITED_RESULTS`;
export const FETCH_AUTH_USER = `${NAMESPACE}FETCH_AUTH_USER`;
export const FETCHED_AUTH_USER_RESULT = `${NAMESPACE}FETCHED_AUTH_USER_RESULT`;
export const SET_AUTH_USER = `${NAMESPACE}SET_AUTH_USER`;
export const SET_SAVED_USERS = `${NAMESPACE}SET_SAVED_USERS`;
export const SET_SINGLE_SUPERUSER = `${NAMESPACE}SET_SINGLE_SUPERUSER`;

export const AUTH_ACCESS_SUBMIT_DATA = `${NAMESPACE}AUTH_ACCESS_SUBMIT_DATA`;
export const AUTH_ACCESS_SUBMITED_RESULT = `${NAMESPACE}AUTH_ACCESS_SUBMITED_RESULT`;

/* Client */
export const CLIENTS_VIEW_LOADED = `${NAMESPACE}CLIENTS_VIEW_LOADED`;
export const CLIENTS_VIEW_UNLOAD = `${NAMESPACE}CLIENTS_VIEW_UNLOAD`;
export const FETCH_CIENT_ACCOUNT = `${NAMESPACE}FETCH_CIENT_ACCOUNT`;
export const FETCHED_CIENT_ACCOUNT_RESULT = `${NAMESPACE}FETCHED_CIENT_ACCOUNT_RESULT`;
export const FETCH_CLIENTS = `${NAMESPACE}FETCH_CLIENTS`;
export const FETCHED_CLIENTS_RESULTS = `${NAMESPACE}FETCHED_CLIENTS_RESULTS`;
export const FETCH_CLIENT = `${NAMESPACE}FETCH_CLIENT`;
export const FETCHED_CLIENT_RESULT = `${NAMESPACE}FETCHED_CLIENT_RESULT`;
export const SET_CLIENT_DATA = `${NAMESPACE}SET_CLIENT_DATA`;
export const CLIENT_ASSIGNED_ROLE = `${NAMESPACE}CLIENT_ASSIGNED_ROLE`;
export const CLIENT_FIELD_CHANGED = `${NAMESPACE}CLIENT_FIELD_CHANGED`;
export const CLIENT_SUBMIT_DATA = `${NAMESPACE}CLIENT_SUBMIT_DATA`;
export const CLIENT_SUBMITED_RESULTS = `${NAMESPACE}CLIENT_SUBMITED_RESULTS`;
export const CLIENT_EDIT_SETDATA = `${NAMESPACE}CLIENT_EDIT_SETDATA`;
export const CLIENT_CREATE_VIEW_LOADED = `${NAMESPACE}CLIENT_CREATE_VIEW_LOADED`;
export const CLIENT_CREATE_VIEW_UNLOAD = `${NAMESPACE}CLIENT_CREATE_VIEW_UNLOAD`;
export const FETCH_CLIENT_OVERVIEW_DATA = `${NAMESPACE}FETCH_CLIENT_OVERVIEW_DATA`;
export const FETCHED_CLIENT_OVERVIEW_DATA_RESULT = `${NAMESPACE}FETCHED_CLIENT_OVERVIEW_DATA_RESULT`;

/* Patient */
export const PATIENTS_VIEW_LOADED = `${NAMESPACE}PATIENTS_VIEW_LOADED`;
export const PATIENTS_VIEW_UNLOAD = `${NAMESPACE}PATIENTS_VIEW_UNLOAD`;
export const PATIENTS_CREATE_VIEW_LOADED = `${NAMESPACE}PATIENTS_CREATE_VIEW_LOADED`;
export const PATIENTS_CREATE_VIEW_UNLOAD = `${NAMESPACE}PATIENTS_CREATE_VIEW_UNLOAD`;
export const FETCH_PATIENTS = `${NAMESPACE}FETCH_PATIENTS`;
export const FETCHED_PATIENTS_RESULT = `${NAMESPACE}FETCHED_PATIENTS_RESULT`;
export const FETCH_PATIENT = `${NAMESPACE}FETCH_PATIENT`;
export const FETCHED_PATIENT_RESULT = `${NAMESPACE}FETCHED_PATIENT_RESULT`;
export const SET_SPLIT_PATIENTS = `${NAMESPACE}SET_SPLIT_PATIENTS`;
export const SET_PATIENT_DATA = `${NAMESPACE}SET_PATIENT_DATA`;
export const PATIENT_FIELD_CHANGED = `${NAMESPACE}PATIENT_FIELD_CHANGED`;
export const PATIENT_SUBMIT_DATA = `${NAMESPACE}PATIENT_SUBMIT_DATA`;
export const PATIENT_SUBMITED_RESULTS = `${NAMESPACE}PATIENT_SUBMITED_RESULTS`;
export const PATIENT_EDIT_SETDATA = `${NAMESPACE}PATIENT_EDIT_SETDATA`;
export const FETCH_CLIENT_PATIENTS = `${NAMESPACE}FETCH_CLIENT_PATIENTS`;
export const FETCHED_CLIENT_PATIENTS_RESULTS = `${NAMESPACE}FETCHED_CLIENT_PATIENTS_RESULTS`;
export const FETCH_SEARCH_PATIENTS = `${NAMESPACE}FETCH_SEARCH_PATIENTS`
export const FETCHED_SEARCH_PATIENTS_RESULTS = `${NAMESPACE}FETCHED_SEARCH_PATIENTS_RESULTS`

/* Specimen */
export const SPECIMEN_VIEW_LOADED = `${NAMESPACE}SPECIMEN_VIEW_LOADED`;
export const SPECIMEN_UPDATE_VIEW_LOADED = `${NAMESPACE}SPECIMEN_UPDATE_VIEW_LOADED`;
export const SPECIMEN_VIEW_UNLOAD = `${NAMESPACE}SPECIMEN_VIEW_UNLOAD`;
export const SPECIMEN_UPDATE_VIEW_UNLOAD = `${NAMESPACE}SPECIMEN_UPDATE_VIEW_UNLOAD`;
export const SPECIMEN_RESULTSHEET_VIEW_LOADED = `${NAMESPACE}SPECIMEN_RESULTSHEET_VIEW_LOADED`;
export const SPECIMEN_RESULTSHEET_VIEW_UNLOADED = `${NAMESPACE}SPECIMEN_RESULTSHEET_VIEW_UNLOADED`;
export const FETCH_FORM_DETAILS = `${NAMESPACE}FETCH_FORM_DETAILS`;
export const FETCHED_FORM_DETAILS_RESULTS = `${NAMESPACE}FETCHED_FORM_DETAILS_RESULTS`;
export const FETCH_SPECIMEN_RECORDS = `${NAMESPACE}FETCH_SPECIMEN_RECORDS`;
export const FETCHED_SPECIMEN_RECORDS_RESULTS = `${NAMESPACE}FETCHED_SPECIMEN_RECORDS_RESULTS`;
export const FETCH_CLIENT_SPECIMEN_RECORDS = `${NAMESPACE}FETCH_CLIENT_SPECIMEN_RECORDS`;
export const FETCHED_CLIENT_SPECIMEN_RECORDS_RESULTS = `${NAMESPACE}FETCHED_CLIENT_SPECIMEN_RECORDS_RESULTS`;
export const CLEAR_SPECIMENS_DATA = `${NAMESPACE}CLEAR_SPECIMENS_DATA`;

export const FETCH_PATIENT_SPECIMENS_HISTORY = `${NAMESPACE}FETCH_PATIENT_SPECIMENS_HISTORY`;
export const FETCHED_PATIENT_SPECIMENS_HISTORY_RESULTS = `${NAMESPACE}FETCHED_PATIENT_SPECIMENS_HISTORY_RESULTS`;

export const FETCH_SPECIMEN_RECORDS_APPROVED = `${NAMESPACE}FETCH_SPECIMEN_RECORDS_APPROVED`;
export const FETCHED_SPECIMEN_RECORDS_APPROVED_RESULTS = `${NAMESPACE}FETCHED_SPECIMEN_RECORDS_APPROVED_RESULTS`;
export const FETCH_RECENT_SPECIMEN_RECORDS = `${NAMESPACE}FETCH_RECENT_SPECIMEN_RECORDS`;
export const FETCHED_RECENT_SPECIMEN_RECORDS_RESULTS = `${NAMESPACE}FETCHED_RECENT_SPECIMEN_RECORDS_RESULTS`;

export const FETCH_CLIENT_SPECIMENS_HISTORY = `${NAMESPACE}FETCH_CLIENT_SPECIMENS_HISTORY`;
export const FETCHED_CLIENT_SPECIMENS_HISTORY_RESULTS = `${NAMESPACE}FETCHED_CLIENT_SPECIMENS_HISTORY_RESULTS`;

export const SPECIMEN_SET_LAZY_LOADING = `${NAMESPACE}SPECIMEN_SET_LAZY_LOADING`;

export const SPECIMEN_CLIENT_SETDATA = `${NAMESPACE}SPECIMEN_CLIENT_SETDATA`;
export const SPECIMEN_FORM_VIEW = `${NAMESPACE}SPECIMEN_FORM_VIEW`;
export const SPECIMEN_FORM_FIELD_CHANGED = `${NAMESPACE}SPECIMEN_FORM_FIELD_CHANGED`;
export const SPECIMEN_SUBMIT_DATA = `${NAMESPACE}SPECIMEN_SUBMIT_DATA`;
export const SPECIMEN_SUBMITED_RESULTS = `${NAMESPACE}SPECIMEN_SUBMITED_RESULTS`;
export const SPECIMEN_EDIT_SETDATA = `${NAMESPACE}SPECIMEN_EDIT_SETDATA`;
export const SPECIMEN_RESULT_SHEET = `${NAMESPACE}SPECIMEN_RESULT_SHEET`;
export const SPECIMEN_ADD_STATUS = `${NAMESPACE}ADD_SPECIMEN_STATUS`;
export const SPECIMEN_ADD_STATUS_RESULT = `${NAMESPACE}ADD_SPECIMEN_STATUS_RESULT`;
export const SPECIMEN_ATTACH_FILE = `${NAMESPACE}SPECIMEN_ATTACH_FILE`;
export const SPECIMEN_ATTACH_FILE_RESULTS = `${NAMESPACE}SPECIMEN_ATTACH_FILE_RESULTS`;
export const FETCH_SPECIMEN_RECORD = `${NAMESPACE}FETCH_SPECIMEN_RECORD`;
export const FETCHED_SPECIMEN_RECORD_RUSULT = `${NAMESPACE}FETCHED_SPECIMEN_RECORD_RUSULT`;
export const SPECIMEN_PAGE_ACTIVE_VIEW = `${NAMESPACE}SPECIMEN_PAGE_ACTIVE_VIEW`;
export const SPECIMEN_SET_PATIENT_LINK_ITEM = `${NAMESPACE}SPECIMEN_SET_PATIENT_LINK_ITEM`;
export const SPECIMEN_EDIT_SAMPLE_STATE = `${NAMESPACE}SPECIMEN_EDIT_SAMPLE_STATE`;
//Cabinets
export const CABINET_MODAL_VIEW_LOADED = `${NAMESPACE}CABINET_MODAL_VIEW_LOADED`;
export const CABINET_MODAL_VIEW_UNLOAD = `${NAMESPACE}CABINET_MODAL_VIEW_UNLOAD`;
export const FETCH_SPECIMENS_FOR_CABINET = `${NAMESPACE}FETCH_SPECIMENS_FOR_CABINET`;
export const FETCHED_SPECIMENS_FOR_CABINET_RESULTS = `${NAMESPACE}FETCHED_SPECIMENS_FOR_CABINET_RESULTS`;
export const FETCH_CABINETS = `${NAMESPACE}FETCH_CABINETS`;
export const FETCHED_CABINETS_RESULT = `${NAMESPACE}FETCHED_CABINETS_RESULT`;
export const CABINET_SUBMIT_DATA = `${NAMESPACE}CABINET_SUBMIT_DATA`;
export const CABINET_SUBMIT_DATA_RESULTS = `${NAMESPACE}CABINET_SUBMIT_DATA_RESULTS`;
export const SET_CABINET_CONTEXT_ITEM = `${NAMESPACE}SET_CABINET_CONTEXT_ITEM`;

/* Requisition */
export const REQUISITION_VIEW_UNLOAD = `${NAMESPACE}REQUISITION_VIEW_UNLOAD`;
export const REQUISITION_CREATE_VIEW_UNLOAD = `${NAMESPACE}REQUISITION_CREATE_VIEW_UNLOAD`;
export const REQUISITION_FORM_FIELD_CHANGED = `${NAMESPACE}REQUISITION_FORM_FIELD_CHANGED`;
export const REQUISITION_SUBMIT_DATA = `${NAMESPACE}REQUISITION_SUBMIT_DATA`;
export const REQUISITION_SUBMITED_RESULTS = `${NAMESPACE}REQUISITION_SUBMITED_RESULTS`;
export const FETCH_REQUISITIONS = `${NAMESPACE}FETCH_REQUISITIONS`;
export const FETCHED_REQUISITIONS_RESULTS = `${NAMESPACE}FETCHED_REQUISITIONS_RESULTS`;
export const REQUISITION_SETDATA = `${NAMESPACE}REQUISITION_SETDATA`;
export const REQUISITION_SET_LINK_ITEM = `${NAMESPACE}REQUISITION_SET_LINK_ITEM`;
export const REQUISITION_SET_PAGE_NUMBER = `${NAMESPACE}REQUISITION_SET_PAGE_NUMBER`;
export const FETCH_REQUISITION_SPECIMENS = `${NAMESPACE}FECH_REQUISITION_SPECIMENS`;
export const FETCHED_REQUISITION_SPECIMENS_RESULTS = `${NAMESPACE}FETCHED_REQUISITION_SPECIMENS_RESULTS`;
export const FETCH_REQUISITION = `${NAMESPACE}FETCH_REQUISITION`;
export const FETCHED_REQUISITION_RUSULT = `${NAMESPACE}FETCHED_REQUISITION_RUSULT`;
export const SHOW_REQUISITION_MODAL = `${NAMESPACE}SHOW_REQUISITION_MODAL`;
export const REQUISITION_ITEM_SUBMIT_DATA = `${NAMESPACE}REQUISITION_ITEM_SUBMIT_DATA`;
export const REQUISITION_ITEM_SUBMITTED_RESULTS = `${NAMESPACE}REQUISITION_ITEM_SUBMITTED_RESULTS`;
export const FETCH_CLIENT_REQUISITIONS = `${NAMESPACE}FETCH_CLIENT_REQUISITIONS`;
export const FETCHED_CLIENT_REQUISITIONS_RESULTS = `${NAMESPACE}FETCHED_CLIENT_REQUISITIONS_RESULTS`;

/* Result Sheet */
export const RESULT_SHEET_VIEW_LOADED = `${NAMESPACE}RESULT_SHEET_VIEW_LOADED`;
export const RESULT_SHEET_VIEW_UNLOAD = `${NAMESPACE}RESULT_SHEET_VIEW_UNLOAD`;
export const RESULT_SHEET_ADD_RESULT_ENTRY = `${NAMESPACE}RESULT_SHEET_ADD_RESULT_ENTRY`;
export const RESULT_SHEET_ADD_REPORT = `${NAMESPACE}RESULT_SHEET_ADD_REPORT`;
export const RESULT_SHEET_DISCARD_REPORT = `${NAMESPACE}RESULT_SHEET_DISCARD_REPORT`;
export const RESULT_SHEET_SUBMIT = `${NAMESPACE}RESULT_SHEET_SUBMIT`;
export const RESULT_SHEET_SUBMITED_RESULTS = `${NAMESPACE}RESULT_SHEET_SUBMITED_RESULTS`;
export const RESULT_SHEET_AUTHORIZED = `${NAMESPACE}RESULT_SHEET_AUTHORIZED`;
export const RESULT_SHEET_AUTHORIZED_RESULT = `${NAMESPACE}RESULT_SHEET_AUTHORIZED_RESULT`;
export const SET_SPECIMENT_RESULT_SHEET = `${NAMESPACE}SET_SPECIMENT_RESULT_SHEET`;

/* Account */
export const ACCOUNTS_VIEW_LOADED = `${NAMESPACE}ACCOUNTS_VIEW_LOADED`;
export const ACCOUNTS_VIEW_UNLOAD = `${NAMESPACE}ACCOUNTS_VIEW_UNLOAD`;
export const FETCH_ACCOUNTS = `${NAMESPACE}FETCH_ACCOUNTS`;
export const FETCHED_ACCOUNTS_RESULT = `${NAMESPACE}FETCHED_ACCOUNTS_RESULT`;
export const SET_SELECTED_ACCOUNT = `${NAMESPACE}SET_SELECTED_ACCOUNT`;
export const FETCH_OWNER_ACCOUNT = `${NAMESPACE}FETCH_OWNER_ACCOUNT`;
export const FETCHED_OWNER_ACCOUNT_RESULT = `${NAMESPACE}FETCHED_OWNER_ACCOUNT_RESULT`;
export const RESET_ACCOUNT_HISTORY = `${NAMESPACE}RESET_ACCOUNT_HISTORY`;
export const FETCH_WORKSPACE_DETAILS = `${NAMESPACE}FETCH_WORKSPACE_DETAILS`;

/* Employee */
export const EMPLOYEES_VIEW_LOADED = `${NAMESPACE}EMPLOYEES_VIEW_LOADED`;
export const EMPLOYEES_VIEW_UNLOAD = `${NAMESPACE}EMPLOYEES_VIEW_UNLOAD`;
export const EMPLOYEES_CREATE_VIEW_LOADED = `${NAMESPACE}EMPLOYEES_CREATE_VIEW_LOADED`;
export const EMPLOYEES_CREATE_VIEW_UNLOAD = `${NAMESPACE}EMPLOYEES_CREATE_VIEW_UNLOAD`;
export const FETCH_EMPLOYEES = `${NAMESPACE}FETCH_EMPLOYEES`;
export const FETCHED_EMPLOYEES_RESULT = `${NAMESPACE}FETCHED_EMPLOYEES_RESULT`;
export const FETCH_EMPLOYEE = `${NAMESPACE}FETCH_EMPLOYEE`;
export const FETCHED_EMPLOYEE_RESULT = `${NAMESPACE}FETCHED_EMPLOYEE_RESULT`;
export const FETCH_EMPLOYEE_PAYADVICE = `${NAMESPACE}FETCH_EMPLOYEE_PAYADVICE`;
export const FETCHED_EMPLOYEE_PAYADVICE_RESULT = `${NAMESPACE}FETCHED_EMPLOYEE_PAYADVICE_RESULT`;
export const SET_SELECTED_EMPLOYEE = `${NAMESPACE}SET_SELECTED_EMPLOYEE`;
export const EMPLOYEE_FIELD_CHANGED = `${NAMESPACE}EMPLOYEE_FIELD_CHANGED`;
export const EMPLOYEE_SUBMIT_DATA = `${NAMESPACE}EMPLOYEE_SUBMIT_DATA`;
export const EMPLOYEE_SUBMITED_RESULTS = `${NAMESPACE}EMPLOYEE_SUBMITED_RESULTS`;
export const EMPLOYEE_EDIT_SETDATA = `${NAMESPACE}EMPLOYEE_EDIT_SETDATA`;
export const TERMINATE_EMPLOYEE = `${NAMESPACE}TERMINATE_EMPLOYEE`;
export const TERMINATE_EMPLOYEE_RESULT = `${NAMESPACE}TERMINATE_EMPLOYEE_RESULT`;

/* Bill */
export const BILLS_VIEW_LOADED = `${NAMESPACE}BILLS_VIEW_LOADED`;
export const BILLS_VIEW_UNLOAD = `${NAMESPACE}BILLS_VIEW_UNLOAD`;
export const BILL_CREATE_VIEW_LOADED = `${NAMESPACE}BILL_CREATE_VIEW_LOADED`;
export const BILL_CREATE_VIEW_UNLOAD = `${NAMESPACE}BILL_CREATE_VIEW_UNLOAD`;
export const FETCH_BILLS = `${NAMESPACE}FETCH_BILLS`;
export const FETCHED_BILLS_RESULTS = `${NAMESPACE}FETCHED_BILLS_RESULTS`;
export const SET_SELECTED_BILL = `${NAMESPACE}SET_SELECTED_BILL`;
export const BILL_FIELD_CHANGED = `${NAMESPACE}BILL_FIELD_CHANGED`;
export const BILL_SUBMIT_DATA = `${NAMESPACE}BILL_SUBMIT_DATA`;
export const BILL_SUBMITED_RESULTS = `${NAMESPACE}BILL_SUBMITED_RESULTS`;
export const BILL_EDIT_SETDATA = `${NAMESPACE}BILL_EDIT_SETDATA`;
export const FETCH_CLIENTS_SERVICES = `${NAMESPACE}FETCH_CLIENTS_SERVICES`;
export const FETCHED_CLIENTS_SERVICES_RESULTS = `${NAMESPACE}FETCHED_CLIENTS_SERVICES_RESULTS`;
export const SET_BILL_SUMMARY = `${NAMESPACE}SET_BILL_SUMMARY`;
export const FETCH_BILLED_BILLS = `${NAMESPACE}FETCH_BILLED_BILLS`;
export const FETCHED_BILLED_BILLS_RESULTS = `${NAMESPACE}FETCHED_BILLED_BILLS_RESULTS`;
export const FETCH_BILL = `${NAMESPACE}FETCH_BILL`;
export const FETCHED_BILL_RESULT = `${NAMESPACE}FETCHED_BILL_RESULT`;
export const SET_BILLABLE_RECORDS = `${NAMESPACE}SET_BILLABLE_RECORDS`;
export const FETCH_PAID_BILLS = `${NAMESPACE}FETCH_PAID_BILLS`;
export const FETCHED_PAID_BILLS_RESULTS = `${NAMESPACE}FETCHED_PAID_BILLS_RESULTS`;
export const FETCH_UNPAID_BILLS = `${NAMESPACE}FETCH_UNPAID_BILLS`;
export const FETCHED_UNPAID_BILLS_RESULTS = `${NAMESPACE}FETCHED_UNPAID_BILLS_RESULTS`;
export const FETCH_BILL_PAYMENTS = `${NAMESPACE}FETCH_BILL_PAYMENTS`;
export const FETCHED_BILL_PAYMENTS_RESULTS = `${NAMESPACE}FETCHED_BILL_PAYMENTS_RESULTS`;
export const FETCH_BILLS_SUMMARY = `${NAMESPACE}FETCH_BILLS_SUMMARY`;
export const FETCHED_BILLS_SUMMARY_RESULTS = `${NAMESPACE}FETCHED_BILLS_SUMMARY_RESULTS`;

/* Payment */
export const PAYMENTS_VIEW_LOADED = `${NAMESPACE}PAYMENTS_VIEW_LOADED`;
export const PAYMENTS_VIEW_UNLOAD = `${NAMESPACE}PAYMENTS_VIEW_UNLOAD`;
export const PAYMENT_MODAL_VIEW_LOADED = `${NAMESPACE}PAYMENT_MODAL_VIEW_LOADED`;
export const PAYMENT_MODAL_VIEW_UNLOAD = `${NAMESPACE}PAYMENT_MODAL_VIEW_UNLOAD`;
export const FETCH_PAYMENTS = `${NAMESPACE}FETCH_PAYMENTS`;
export const FETCHED_PAYMENTS_RESULTS = `${NAMESPACE}FETCHED_PAYMENTS_RESULTS`;
export const PAYMENT_FIELD_CHANGED = `${NAMESPACE}PAYMENT_FIELD_CHANGED`;
export const PAYMENT_SUBMIT_DATA = `${NAMESPACE}PAYMENT_SUBMIT_DATA`;
export const PAYMENT_SUBMITED_RESULTS = `${NAMESPACE}PAYMENT_SUBMITED_RESULTS`;
export const SET_ACCOUNT_BALANCE = `${NAMESPACE}SET_ACCOUNT_BALANCE`;
export const SET_PAYMENT_DATA = `${NAMESPACE}SET_PAYMENT_DATA`;
export const FETCH_PAYMENTS_SUMMARY = `${NAMESPACE}FETCH_PAYMENTS_SUMMARY`;
export const FETCHED_PAYMENTS_SUMMARY_RESULTS = `${NAMESPACE}FETCHED_PAYMENTS_SUMMARY_RESULTS`;

/*Payroll*/
export const PAYROLL_VIEW_LOADED = `${NAMESPACE}PAYROLL_VIEW_LOADED`;
export const PAYROLL_VIEW_UNLOAD = `${NAMESPACE}PAYROLL_VIEW_UNLOAD`;
export const PAYROLL_CREATE_VIEW_LOADED = `${NAMESPACE}PAYROLL_CREATE_VIEW_LOADED`;
export const PAYROLL_CREATE_VIEW_UNLOAD = `${NAMESPACE}PAYROLL_CREATE_VIEW_UNLOAD`;
export const PAYROLL_FIELD_CHANGED = `${NAMESPACE}PAYROLL_FIELD_CHANGED`;
export const PAYROLL_WAGE_SALARY_FIELD = `${NAMESPACE}PAYROLL_WAGE_SALARY_FIELD`;
export const PAYROLL_SET_FORM_DEFAULTS = `${NAMESPACE}PAYROLL_SET_FORM_DEFAULTS`;
export const FETCH_PAYROLLS = `${NAMESPACE}FETCH_PAYROLLS`;
export const FETCHED_PAYROLLS_RESULTS = `${NAMESPACE}FETCHED_PAYROLLS_RESULTS`;
export const FETCH_PAYADVICES = `${NAMESPACE}FETCH_PAYADVICES`;
export const FETCHED_PAYADVICES_RESULTS = `${NAMESPACE}FETCHED_PAYADVICES_RESULTS`;
export const PAYROLL_SUBMIT_DATA = `${NAMESPACE}PAYROLL_SUBMIT_DATA`;
export const PAYROLL_SUBMITED_RESULTS = `${NAMESPACE}PAYROLL_SUBMITED_RESULTS`;
export const SET_PAYROLL_DATA = `${NAMESPACE}SET_PAYROLL_DATA`;

/* Taxes */
export const FETCHED_TAXES_RESULTS = `${NAMESPACE}FETCHED_TAXES_RESULTS`;
export const FETCH_TAXES = `${NAMESPACE}FETCH_TAXES`;
export const SET_TAX_DATA = `${NAMESPACE}SET_TAX_DATA`;
export const TAX_SUBMIT_DATA = `${NAMESPACE}TAX_SUBMIT_DATA`;
export const TAX_SUBMIT_DATA_RESULT = `${NAMESPACE}TAX_SUBMIT_DATA_RESULT`;
export const FETCH_TAXES_BY_TYPE = `${NAMESPACE}FETCH_TAXES_BY_TYPE`;
export const FETCHED_TAXES_BY_TYPE_RESULT = `${NAMESPACE}FETCHED_TAXES_BY_TYPE_RESULT`;

/* Services */
export const FETCH_SERVICES = `${NAMESPACE}FETCH_SERVICES`;
export const FETCHED_SERVICES_RESULT = `${NAMESPACE}FETCHED_SERVICES_RESULT`;
export const SET_SERVICES_DATA = `${NAMESPACE}SET_SERVICES_DATA`;
export const SERVICE_SUBMIT_DATA = `${NAMESPACE}SERVICE_SUBMIT_DATA`;
export const SERVICE_SUBMIT_DATA_RESULT = `${NAMESPACE}SERVICE_SUBMIT_DATA_RESULT`;

/* Message */
export const MESSAGES_VIEW_LOADED = `${NAMESPACE}MESSAGES_VIEW_LOADED`;
export const MESSAGES_VIEW_UNLOAD = `${NAMESPACE}MESSAGES_VIEW_UNLOAD`;
export const MESSAGE_THREAD_VIEW_LOADED = `${NAMESPACE}MESSAGE_THREAD_VIEW_LOADED`;
export const MESSAGE_THREAD_VIEW_UNLOAD = `${NAMESPACE}MESSAGE_THREAD_VIEW_UNLOAD`;
export const MESSAGES_PRESERVE_STATE = `${NAMESPACE}MESSAGES_PRESERVE_STATE`;
export const RECEIVED_MESSAGE = `${NAMESPACE}RECEIVED_MESSAGE`;
export const SEND_MESSAGE = `${NAMESPACE}SEND_MESSAGE`;
export const SET_MESSAGE_VIEW = `${NAMESPACE}SET_MESSAGE_VIEW`;
export const FETCH_MESSAGES = `${NAMESPACE}FETCH_MESSAGES`;
export const FETCHED_MESSAGES_RESULTS = `${NAMESPACE}FETCHED_MESSAGES_RESULTS`;
export const FETCH_MESSAGE_THREAD = `${NAMESPACE}FETCH_MESSAGE_THREAD`;
export const FETCHED_MESSAGE_THREAD_RESULT = `${NAMESPACE}FETCHED_MESSAGE_THREAD_RESULT`;
export const SEND_MESSAGE_READ = `${NAMESPACE}SEND_MESSAGE_READ`;
export const SEND_MESSAGE_READ_RESULT = `${NAMESPACE}SEND_MESSAGE_READ_RESULT`;
export const DELETE_MESSAGE = `${NAMESPACE}DELETE_MESSAGE`;
export const DELETE_MESSAGE_RESULT = `${NAMESPACE}DELETE_MESSAGE_RESULT`;
/* File */
export const UPLOAD_FILE = `${NAMESPACE}UPLOAD_FILE`;
export const UPLOAD_FILE_RESULT = `${NAMESPACE}UPLOAD_FILE_RESULT`;
export const DOWNLOAD_FILE = `${NAMESPACE}DOWNLOAD_FILE`;
export const DOWNLOAD_FILE_RESULT = `${NAMESPACE}DOWNLOAD_FILE_RESULT`;

/*Settings*/
export const FETCH_FORM_ITEM_GROUP = `${NAMESPACE}FETCH_FORM_ITEM_GROUP`;
export const FETCHED_FORM_ITEM_GROUP_RESULTS = `${NAMESPACE}FETCHED_FORM_ITEM_GROUP_RESULTS`;
export const FORM_GROUP_SET_DATA = `${NAMESPACE}FORM_GROUP_SET_DATA`;
export const FORM_GROUP_FIELD_CHANGED = `${NAMESPACE}FORM_GROUP_FIELD_CHANGED`;
export const FORM_GROUP_SUBMIT_DATA = `${NAMESPACE}FORM_GROUP_SUBMIT_DATA`;
export const FORM_GROUP_SUBMITED_RESULTS = `${NAMESPACE}FORM_GROUP_SUBMITED_RESULTS`;
export const SETTINGS_OPTION_CHANGED = `${NAMESPACE}SETTINGS_OPTION_CHANGED`;
export const SUBMIT_CHANGED_PREFERENCES = `${NAMESPACE}SUBMIT_CHANGED_PREFERENCES`;
export const SUBMIT_CHANGED_PREFERENCES_RESULT = `${NAMESPACE}SUBMIT_CHANGED_PREFERENCES_RESULT`;
export const FETCH_APP_PREFERENCES = `${NAMESPACE}FETCH_APP_PREFERENCES`;
export const FETCHED_APP_PREFERENCES_RESULTS = `${NAMESPACE}FETCHED_APP_PREFERENCES_RESULTS`;

export const FETCH_PRINT_GROUPS = `${NAMESPACE}FETCH_PRINT_GROUPS`;
export const FETCHED_PRINT_GROUPS_RESULTS = `${NAMESPACE}FETCHED_PRINT_GROUPS_RESULTS`;
export const PRINT_GROUP_SET_DATA = `${NAMESPACE}PRINT_GROUP_SET_DATA`;
export const PRINT_GROUP_SUBMIT_DATA = `${NAMESPACE}PRINT_GROUP_SUBMIT_DATA`;
export const PRINT_GROUP_SUBMIT_RESULTS = `${NAMESPACE}PRINT_GROUP_SUBMIT_RESULTS`;

/*Reporting*/
export const REPORTING_VIEW_LOADED = `${NAMESPACE}REPORTING_VIEW_LOADED`;
export const REPORTING_VIEW_UNLOAD = `${NAMESPACE}REPORTING_VIEW_UNLOAD`;
export const REPORTS_VIEW_LOADED = `${NAMESPACE}REPORTS_VIEW_LOADED`;
export const REPORTS_VIEW_UNLOAD = `${NAMESPACE}REPORTS_VIEW_UNLOAD`;
export const SET_REPORT_ITEM = `${NAMESPACE}SET_REPORT_ITEM`;
export const REPORT_FIELD_CHANGED = `${NAMESPACE}REPORT_FIELD_CHANGED`;
export const PRINT_FIELD_CHANGED = `${NAMESPACE}PRINT_FIELD_CHANGED`;
export const RUN_REPORT = `${NAMESPACE}RUN_REPORT`;
export const RUN_REPORT_RESULTS = `${NAMESPACE}RUN_REPORT_RESULTS`;
export const REPORT_FILTER_RESET = `${NAMESPACE}REPORT_FILTER_RESET`;
export const FETCH_REPORTS_SUMMARY = `${NAMESPACE}FETCH_REPORTS_SUMMARY`;
export const FETCHED_REPORTS_SUMMARY_RESULTS = `${NAMESPACE}FETCH_REPORTS_SUMMARY_RESULTS`;

//workspace
export const SUBMIT_WORKSPACE_NAME = `${NAMESPACE}SUBMIT_WORKSPACE_NAME`;
export const SUBMIT_WORKSPACE_NAME_RESULTS = `${NAMESPACE}SUBMIT_WORKSPACE_NAME_RESULTS`;

/*Profile */
export const SET_ACTIVE_PROFILE_PANE = `${NAMESPACE}SET_ACTIVE_PROFILE_PANE`;
export const PROFILE_FIELD_CHANGED = `${NAMESPACE}PROFILE_FIELD_CHANGED`;
export const RESET_PROFILE_FORM = `${NAMESPACE}RESET_PROFILE_FORM`;
export const CLICKED_EDIT_PROFILE_FIELD = `${NAMESPACE}CLICKED_EDIT_PROFILE_FIELD`;
export const PROFILE_SUBMIT_DATA = `${NAMESPACE}PROFILE_SUBMIT_DATA`;
export const PROFILE_SUBMIT_DATA_RESULT = `${NAMESPACE}PROFILE_SUBMIT_DATA_RESULT`;
export const FETCH_PROFILE_DATA = `${NAMESPACE}FETCH_PROFILE_DATA`;
export const FETCHED_PROFILE_DATA_RESULT = `${NAMESPACE}FETCHED_PROFILE_DATA_RESULT`;
export const PASSWORD_CHANGE_SUBMIT_DATA = `${NAMESPACE}PASSWORD_CHANGE_SUBMIT_DATA`;
export const PASSWORD_CHANGE_SUBMIT_DATA_RESULT = `${NAMESPACE}PASSWORD_CHANGE_SUBMIT_DATA_RESULT`;
