import * as actionTypes from '../actionType';
import { isError } from '../../service/agent';
import { ApiRequestManager } from '../../utility/ApiRequestManager';

const initialState = {
    viewInit: false,
    employees: null,
    payrollData: null,
    payrolls: null,
    taxes: null,
    payrollForm : {
        setDefaults: false,
        payrollDate: '',
        earnings: [],
        taxes: [],
        earningsSelected: [],
        payrollList: [ ]
    },
    processing: false,
    payAdvices: []
 };

var error = null;
let empty = {};
let emptyArray = [];

let requestManager = null;
let apiOutcome = null;

const payrollReducer = (state = initialState, action ) => { 

    switch(action.type) {

        case actionTypes.EMPLOYEES_VIEW_UNLOAD :
            return {
                ...state,
                employees: null  
            }
        
        case actionTypes.PAYROLL_VIEW_UNLOAD :
            return {
                payrolls: null,
                taxes: null,
                payrollForm: initialState.payrollForm
                
            }
        case actionTypes.PAYROLL_CREATE_VIEW_UNLOAD:
            return {
                ...initialState,
            }
        case actionTypes.FETCHED_EMPLOYEES_RESULT: 
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.FETCH_EMPLOYEES) ?? {};
            return {
                ...state,
                viewInit: true,
                employees: apiOutcome.success? action.payload : emptyArray
            }
        case actionTypes.FETCHED_TAXES_BY_TYPE_RESULT :
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.FETCH_TAXES_BY_TYPE) ?? {};

            const taxes = apiOutcome?.error ? emptyArray : action.payload.map((item, i) => ({
                ...item,
                index: i
            }));
            
            return {
                ...state,
                viewInit: true,
                taxes: taxes,
                payrollForm: {
                    ...state.payrollForm,
                   taxes: taxes 
                }
            }
        case actionTypes.PAYROLL_SET_FORM_DEFAULTS :
            return {
                ...state,
                payrollForm: {
                    ...state.payrollForm,
                    ...action.payload
                }
            }
        case actionTypes.PAYROLL_WAGE_SALARY_FIELD :
            
            var row = state.payrollForm.earnings[action.payload.id];
            var newData = {
                ...row[action.payload.cell].value,
                [action.payload.attr]: action.payload.value
            }

            var newValue = {
                ...newData
            }

            var newEarnings = state.payrollForm.earnings.map((item)=> {
                return item.id !== action.payload.id ? item : {
                    ...item,
                    [action.payload.cell]: {id: item.id, value: newValue}
                }
            })
            return {
                ...state,
                payrollForm: {
                    ...state.payrollForm,
                    earnings: [
                        ...newEarnings,

                    ]
                }
            }
        case actionTypes.PAYROLL_FIELD_CHANGED:
            if (action.payload.id === undefined) 
                return {
                    ...state,
                    payrollForm: {
                        ...state.payrollForm,
                        ...action.payload
                    }
                }
            row = state.payrollForm.earnings[action.payload.id];
            newData = {
                ...row[action.payload.cell].value[action.payload.name],
                [action.payload.attr]: action.payload.value
            }

            newValue = {
                ...row[action.payload.cell].value,
                [action.payload.name]: newData
            }

            newEarnings = state.payrollForm.earnings.map((item)=> {

                if (item.id !== action.payload.id) {
                    // This isn't the item we care about - keep it as-is
                    return item
                  }
              
                  // Otherwise, this is the one we want - return an updated value
                  return {
                    ...item,
                    [action.payload.cell]: {id: item.id, value: newValue}
                  }

            })

            return {
                ...state,
                payrollForm: {
                    ...state.payrollForm,
                    earnings: [
                        ...newEarnings,
                    ]
                }
            }
        case actionTypes.FETCHED_PAYROLLS_RESULTS :
            error = isError(action.payload);
            return {
                ...state,
                payrolls: error ? emptyArray : action.payload
            }
        case actionTypes.PAYROLL_SUBMITED_RESULTS :
            error = isError(action.payload);
         
            if (state.approvalRequest) {
                return {
                    ...state,
                    approvalRequest: false,
                    processing: false,
                    payrollData: error ? state.payrollData : action.payload,
                    error: error ?  action.payload : null,
                }
            }else {
                return {
                    ...state,
                    processing: false,
                    payrollData: error ? null : action.payload,
                    payrollSuccess: error ? false : true
                }
            }
        case actionTypes.PAYROLL_SUBMIT_DATA :
            return {
                ...state,
                processing: true,
                approvalRequest: action.payload.approvalRequest
            }
        case actionTypes.FETCH_PAYADVICES :
            return {
                ...state,
                processing: action.payload.processing,
            }
        case actionTypes.SET_PAYROLL_DATA :
            return {
                ...state,
                payrollData: action.payload
            }
        case actionTypes.FETCHED_PAYADVICES_RESULTS :
            error = isError(action.payload);
            return {
                ...state,
                payAdvices: error ? emptyArray : action.payload,
                processing: false
            }
        default:
            return state;
    }
};

export default payrollReducer;