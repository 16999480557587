import React, { useState } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { setupPage } from '../../components/Layout/setupPage';
import ActionBar from '../components/partials/ActionBar';
import * as urls from '../../config/urls';

import { 
    Container,
    Row,
    Col,
    Button,
    List,
    Section,
    Input,
    Label,
    FormGroup,
    FormText,
    SimpleSelect,
    ValidateField,
    ErrorFeedback,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Authority
} from '../../components';

import { PermissionEnforcer } from '../../utility/PermissionEnforcer';
import { FormValidator } from '../../utility/FormValidator';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
import { Caret } from './../../utility/Caret';

import  * as actions  from '../../store/actions';
import  * as actionTypes  from '../../store/actionType';
import { FORM_TYPE, REQUESTS_TYPE } from '../../common/constant';
import text from '../../common/strings';
import ConfirmModal from '../components/partials/ConfirmModal';
import { ErrorMessage } from '../components/partials/ErrorMessage';
import { PromptMessage, getPromptMessageProps } from '../components/partials/PromptMessage';

import { getIconUrl } from '../../common/images-catalogue';
import { NotFound } from '../components/partials/NotFound';
const mapStateToProps = state => ({
    apiRequestManager : ApiRequestManager.getInstance(ApiRequestManager),
    validator: FormValidator.getInstance(FormValidator),
    ...state.settings.formSetting,
    mode:  state.global.actionBarMode,
    authorizeUser: state.global.authorizeUser,
    clientDashboard: state.global.useClientDashboard,
    workspace: state.global.workspace,
    redirectTo: state.settings.redirectTo
});

const mapDispatchToProps = dispatch => ({
    onRedirect: () => dispatch({ type: actionTypes.REDIRECT}),
    doRedirect: (to) => dispatch({ type: actionTypes.REDIRECT_TO, payload:  to}),
    setMode: (mode) => dispatch(actions.actionBarModeAction(mode)),
    getFromGroup: () => dispatch(actions.getAllFormItemGroupAction()),
    setFeatureData: (data) => dispatch(actions.setFeatureDataAction(data)),
    formFieldChange: (fieldSet) => dispatch(actions.formFieldChangeAction(fieldSet)),
    submitData: (payload) => dispatch(actions.submitFormGroupAction(payload)),
    submitPrintGroups: (payload) => dispatch(actions.submitPrintGroups(payload)),
    setPrintGroupData: (payload) => dispatch(actions.setPrintGroupData(payload)),
});


const FeatureItem = (props) => {

    const {data, index, editIndex, onEdit, onDelete, fieldMode} = props;
    const isTextType = data.dataType === 'text' ? true : false;
    const typeLabel = isTextType ? 'Textfield' : 'Checkbox';
    
    return(<div className={`ftre-item`}>
    <div className="cont">
        <div className={`inner-cont ${classnames({ 'form-group-item': !fieldMode})}`}>
            <div className="lbl-col">
                <div className="cl">{fieldMode ? 'Label': 'Print Item Name'}</div>
                <div className="flbl">{data.label}</div>
            </div>
            {fieldMode && <div className="typ-col">
            <div className="cl">Type</div>
                <div className="ficon">
                    <span>
                        { isTextType ? 
                        <img src={getIconUrl('textField')} alt='' />
                        : 
                        <img src={getIconUrl('checkField')} alt='' />
                        }
                    </span>
                </div>
                <div className="fk">{typeLabel}</div>
            </div>}
        </div>
        <div className="ftr-actions">
            <Authority allowed={ 'change' } entity="clinicalformitem"  >
                <Button onClick={() => onEdit(data, index)}>
                    <img src={getIconUrl('edit')} alt='' />
                </Button>
            </Authority>
            <Authority allowed={ 'delete' } entity="clinicalformitem" >
                <Button onClick={() => onDelete(data, index)}>
                    <img src={getIconUrl('trash')} alt='' />
                </Button>
            </Authority>
        </div>
    </div>
</div>);
}

const ComposeField = (props) => {
    const editingClass = props.isEditMode ? '--editing' : '';
    return (
    <div className={`comps-form ${editingClass}`}>
        <div className="inner">
            <div children="top">
                <span className="tle">
                    { props.title }
                </span>
            </div>
            <div className="frm-fields">
                {props.fieldMode && <div className="sle-f">
                    <SimpleSelect 
                        id={'data-type'}
                        title="type"
                        className="ip-select-field"
                        name="client"
                        list={props.listTypes}
                        onChange={props.optionChange}
                        initialSelected={props.selectedOption}
                        />
                </div>}
                <div className="ip-f">
                    <ValidateField>
                    <Input onChange={props.itemNameFieldChange} type="text" placeholder="Name" name='fieldName' value={props.fieldName}></Input>
                    <ErrorFeedback 
                        error
                        filter='valueMissing'
                        message={<ErrorMessage message ='Please enter a field name.' />} />
                    </ValidateField>
                </div>
                <div className="act-f">
                    <Button onClick={props.onAddHandler}>
                        { props.isNewField ? 'Add' : 'Save' }
                    </Button>
                    <Button className={'delete-btn'} onClick={props.handleCancel}>
                        Cancel
                    </Button>
                </div>
                {props.fieldMode && <div className="sle-f clinical-features pad-top">
                <FormGroup style={{margin: '0rem'}} className="feature-grp yrn checkbox--resize">
                    <span className="label">Show when printing results?</span>
                    <label className="ip-checkbox">
                        <Input checked={props.isPrintable} onChange={props.handleShowInPrint} type="checkbox" name={"isPrintable"} datalabel="" />
                        <span className="checkmark round"></span>
                    </label>
                </FormGroup>
                </div>}
                {props.fieldMode && <div className="ip-f">
                    <FormGroup>
                        <div>
                            <FormText className="tle">Choose a Print Group</FormText>
                        </div>
                        <SimpleSelect 
                            id={'print-group'}
                            title="Print group"
                            className="ip-select-field"
                            name="printGroup"
                            searchable={["Type Group Name.", "No matching group"]}
                            filterKeys={['value']}
                            onChange={props.selectedGroupChange}
                            list={props.groups}
                            initialSelected={props.selectedGroup}
                            />
                    </FormGroup>
                </div>}
            </div>
        </div>
    </div>)
}

const FeatureGroup = (props) => {
    const {data} = props;
    const itemsCount = data.clinicalItems.length;
    const formType = data.form === FORM_TYPE.NonGynec ? "Non Gynecology" : "Gynecology";
    
    return (
        <Col md={6}>
            <div className="form-item">
                <div className="form-wrp">
                    <div className="frm-name">
                        <h2>{data.name}</h2>
                    </div>
                    <div className="frm-cont">
                        <div className="ftre-count">{itemsCount} <span>items</span></div>
                        <p>Fields included in the form</p>
                        <h3 className="frm-typ">{formType} Form</h3>
                    </div>
                    <div className="frm-actions">
                        <Authority allowed={ 'delete' } entity="clinicalitemgroup"  >
                            <Button onClick={(evt) => props.onDelete(evt, data)}>
                                <img src={getIconUrl('trash')} alt='' /> Delete
                            </Button>
                        </Authority >
                        <Authority  allowed={ 'change' } entity="clinicalitemgroup" >
                            <Button onClick={(evt) => props.onEdit(data)}>
                                <img src={getIconUrl('edit')} alt='' /> Edit
                            </Button>
                        </Authority >
                    </div>
                </div>
            </div>
        </Col>
    );
}

const FormListView = (props) => {
    
    const listData = Array.isArray(props.formGroupsList) ? props.formGroupsList : [];
    const actions = {
        onEdit: props.onEdit,
        onDelete: props.onDeleteGroup
    };

    const disableAddNew = props.isFormGroupPresent(FORM_TYPE.Gynec) && props.isFormGroupPresent(FORM_TYPE.NonGynec) ? true : false;
    
    return (
        <Container className="form-overview">
            <Row>
                <Col>
                    <div className="title-box">
                        <h1 className="title">Form Setup</h1>
                    </div>
                    <Section className="add-sec">
                        <div className="top-sec">
                            <Button disabled={disableAddNew} onClick={props.onAddForm}>
                                <span className="icon">
                                    <img src={getIconUrl('plus')} alt='' />
                                </span>
                            Add Form</Button>
                            <p>Create a form with your desired Clinical Features</p>
                        </div>
                    </Section>
                    <Section className="form-itm-sec" >
                        <h1 className="sec-title">Form Clinical Features</h1>
                        <div className="inner-cont">
                            <Row>
                                { listData.map((item, i) => {
                                    return <FeatureGroup 
                                    key={i} 
                                    {...actions}
                                    data={item} />
                                })}
                            </Row>
                        </div>
                    </Section>
                </Col>
            </Row>
        </Container>
    );
}

const composePrintGroupOptions = (groups) => {
    return groups.map(group => ({
        ...group,
        value: group.name,
        label: group.name,
    }))
}

class CreateEditPrintGroups extends React.Component{
    constructor(props){
        super(props);

        props.apiRequestManager.register(actionTypes.PRINT_GROUP_SUBMIT_DATA, props.submitPrintGroups);

        this.state = {
            showAddNew: false,
            isEditMode: false,
            fieldName: '',
            fieldInEditId: null,
            isNewField: false,
            deleteDialogVisible: false,
            deleteContextItem: null,
        };

        this.toggleAddNewFieldGroup = this.toggleAddNewFieldGroup.bind(this);
        this.toggleEditFieldGroup = this.toggleEditFieldGroup.bind(this);
        this.onRemoveFieldGroupItem = this.onRemoveFieldGroupItem.bind(this);
        this.groupNameChanged = this.groupNameChanged.bind(this);
        this.addGroupHandler = this.addGroupHandler.bind(this);
        this.cancelHandler = this.cancelHandler.bind(this);
        this.getPromptMessage = this.getPromptMessage.bind(this);
        this.onConfirmDelete = this.onConfirmDelete.bind(this);
    }

    toggleAddNewFieldGroup(value) {
        this.setState({
            showAddNew: value,
            fieldName: '',
            fieldInEditId: null,
            isNewField: true,
            isEditMode: false,
        });
    }

    toggleEditFieldGroup(group){
        this.setState({
            showAddNew: false,
            fieldName: group.name,
            fieldInEditId: group.id,
            isNewField: false,
            isEditMode: true,
        });
    }

    onRemoveFieldGroupItem(group){
        this.setState({
            deleteDialogVisible: true,
            deleteContextItem: group,
        });
    }

    onConfirmDelete(contextItem){
        const payload = {
            requestType: REQUESTS_TYPE.DELETE,
            data: {
                id: contextItem.id,
                name: contextItem.name,
                formGroupId: contextItem.formGroupId
            },
        }
        this.setState({deleteDialogVisible: false});
        this.props.apiRequestManager.queueRequest(actionTypes.PRINT_GROUP_SUBMIT_DATA, payload);
    }

    groupNameChanged(event){
        this.setState({fieldName: event.target.value});
    }

    addGroupHandler(){
        if(!this.state.fieldName) return;

        const id = this.state.fieldInEditId;
        const dataForUpdatePayload = id ? { id } : {};

        const payload = {
            requestType: id ? REQUESTS_TYPE.UPDATE : REQUESTS_TYPE.CREATE,
            data: {
                name: this.state.fieldName,
                formGroupId: this.props.featureGroupData.id,
                ...dataForUpdatePayload,
            }
        }

        this.props.apiRequestManager.queueRequest(actionTypes.PRINT_GROUP_SUBMIT_DATA, payload);
        this.toggleAddNewFieldGroup(false);
    }

    cancelHandler(){
        this.setState({
            fieldName: '',
            isEditMode: false,
            showAddNew: false,
            fieldInEditId: null,
            isNewField: false,
        });
    }

    getPromptMessage(){
        const apiOutcome = this.props.apiRequestManager.getApiOutcome(actionTypes.PRINT_GROUP_SUBMIT_DATA, true) ?? {};
        return getPromptMessageProps('printGroup', apiOutcome);
    }

    render(){
        const dialogProps = this.getPromptMessage();

        const fieldGroups = this.props.printGroups ? composePrintGroupOptions(this.props.printGroups) : [];
        const { formErrors } = this.props;

        const composeProps = {
            onAddHandler: this.addGroupHandler,
            handleCancel: this.cancelHandler,
            itemNameFieldChange: this.groupNameChanged,
            isNewField: this.state.isNewField,
            fieldName: this.state.fieldName,
            isEditMode: this.state.isEditMode,
            fieldMode: false,
            title: this.state.isNewField ? 'New Group' : 'Edit Group'
        };

        return (<>
            <div className="crte-edt-wrap">
            { dialogProps.visible && 
                <PromptMessage {...dialogProps} />
            } 
                <div className="title"><h3>Print Items</h3></div>
                <Section>
                    {this.state.showAddNew && !this.state.isEditMode? 
                    <ComposeField
                        {...composeProps}
                    />
                    :
                    <div className="add-new-wrap">
                        <Authority allowed={ 'create' } entity="formprintgroup"  >
                            <Button onClick={()=> this.toggleAddNewFieldGroup(true)}>
                                <span>
                                    <img src={getIconUrl('plus')} alt='' />
                                </span>
                                Add Print Item
                            </Button>
                        </Authority>
                    </div>
                    }
                </Section>
                <Section>
                    <h2>Items</h2>
                    <div className="fields-list">
                        <List>
                            { fieldGroups.empty() ?  
                             <NotFound.Items messageComponent={
                                 <span>
                                     No <b>Print Items</b> were found. Create one above.
                                 </span>
                             } />
                            : 
                            fieldGroups.map((item, i) => {
                            return this.state.fieldInEditId === item.id && this.state.isEditMode ?
                            <ComposeField
                                {...composeProps}
                            />

                            :(<FeatureItem
                                onEdit={this.toggleEditFieldGroup}
                                onDelete={this.onRemoveFieldGroupItem} 
                                key={i}
                                index={i}
                                fieldMode={false}
                                data={item}/>)
                            })}
                        </List>
                    </div>
                </Section>

                { this.state.deleteDialogVisible && 
                <ConfirmModal 
                    contextName={'print group'}
                    contextItem={this.state.deleteContextItem} 
                    cancelCallback={()=> this.setState({deleteDialogVisible: false})}
                    confirmCallback={this.onConfirmDelete}
                />
                }
            </div>
        </>);
    }
}


class CreateEdit extends React.Component {

    listTypes = [
        {label: 'Text', value: 'text'}, 
        {label:'Checkbox', value: 'bool'}
    ];

    formType = {
        NonGyno: 'NonGynecology',
        Gyno: 'Gynecology',
    };

    constructor(props) {
        super(props);
        
        this.state = {
            selectedOption: this.listTypes[0],
            fieldName: '',
            isPrintable: false,
            selectedGroup: null,
            isNewField: true,
            fieldInEdit: -1
        }

        this.composeFromEditData = this.composeFromEditData.bind(this);
        this.onAddNewItem = this.onAddNewItem.bind(this);
        this.onHandleCancel = this.onHandleCancel.bind(this);
    
        if (this.props.featureGroupData && !this.props.formData.setInitData) {
            this.composeFromEditData(this.props.featureGroupData);
        }
    }

    componentDidMount() {

        if (!this.props.featureGroupData && this.props.mode === 'create') {
            this.props.formFieldChange({formType: this.formType.Gyno} );
        }
        if (!this.props.featureGroupData && this.props.mode === 'create' && this.props.isFormGroupPresent(this.formType.Gyno)) {
            this.props.formFieldChange({formType: this.formType.NonGyno} );
        }
     }

    shouldComponentUpdate(props, state) {
        if (props.featureGroupData && props.mode !== 'edit') {
            props.setMode('edit');
        }
        return true;
    }

    componentWillUnmount() {
        this.props.onBackHandler();
    }

    composeFromEditData(data) {
        
        const formData = {
            name: data.name,
            formType: data.form,
            items: data.clinicalItems.map((item, i) => {
                return {
                    index:i,
                    id: item.id,
                    label: item.label,
                    dataType: item.dataType,
                    printGroup: item.printGroup,
                    isPrintable: item.isPrintable,
                }
            }),
        }
        this.props.formFieldChange(formData);
    }

    onHandleCancel() {
        this.setState({
            fieldName: '',
            isNewField: true,
            selectedOption: this.listTypes[0],
            isPrintable: false,
            selectedGroup: null,
        });
        this.props.toggleEditMode(false);
    }

    onAddNewItem() {
        this.setState({
            fieldName: '',
            isNewField: true,
            selectedOption: this.listTypes[0],
            isPrintable: false,
            selectedGroup: null,
        });
        this.props.toggleAddItem(true);
    } 

    render () {

        const isAddNew = this.props.showAddNew;
        const isEditMode = this.props.isEditMode;
        const addNewItem = () => this.onAddNewItem();
        const handleCancel = () => this.onHandleCancel();

        const featureItemsData = this.props.formData.items ? this.props.formData.items : []; 
        const viewMode = this.props.mode;
        const featureGroupName = viewMode === 'create' && !this.props.featureGroupData? 'Enter name': this.props.formData.name;

        const nameFieldChange = (evt) => {
            const textValue = evt.target.innerHTML.replace(/(&nbsp;)/gi, '');
            this.props.formFieldChange({name: textValue});
            Caret.setEndContenteditable(evt.target);
        };

        const radioFieldChange = (evt) => {
            this.props.formFieldChange({formType: evt.target.value});
        };
        
        const itemNameFieldChange = (evt) => {
            this.setState({fieldName: evt.target.value});
        };

        const optionChange = (data) => {
            this.setState({selectedOption: data});
        };

        const selectedGroupChange = (data) => {
            this.setState({selectedGroup: data});
        }

        const handleShowInPrint = (event) =>{
            this.setState({isPrintable: event.target.checked})
        }

        const editFieldItem = (field, index) => {
            this.setState({fieldName: field.label});
            this.setState({fieldInEdit: field.index});
            const opt = this.listTypes.filter((opt) => opt.value === field.dataType);
            this.setState({ selectedOption: opt[0]});
            this.setState({isNewField: false});

            const selectedGroup = composePrintGroupOptions(this.props.printGroups).filter(group => group.id === field.printGroup)[0] || null;

            this.setState({
                isPrintable: field.isPrintable,
                selectedGroup,
            });

            this.props.toggleEditMode(true);
        }

        const removeFieldItem = (field, index) => {
            const items = this.props.formData.items.filter((item, i) => item.index !== field.index);
            this.props.formFieldChange({items: items});
        }

        const onAddHandler = () => {
            if(this.state.fieldName === '') {
                return;
            }

            const item = {
                index : this.state.isNewField ?  this.props.formData.items.length : this.state.fieldInEdit,
                dataType: this.state.selectedOption.value,
                label: this.state.fieldName,
                printGroup: this.state.selectedGroup ? this.state.selectedGroup.id : null,
                isPrintable: this.state.isPrintable
            };
            var items = [];

            if (this.state.isNewField) {
                items = [
                    //this is needed because of a bug that occurs when adding new items when
                    //there is already a single item in the list
                    ...this.props.formData.items.map((item, index)=>{
                        return {
                            ...item,
                            index: index
                        }
                    }), 
                    item,
                ];
            }else {
                items = this.props.formData.items.map((oldItem, i) => {
                    if (oldItem.index === this.state.fieldInEdit) {
                        oldItem = {
                            id: oldItem.id,
                            ...item
                        }
                    }
                    return oldItem;
                });
                this.setState({fieldInEdit: -1});
            }
        
            this.props.formFieldChange({items: items});
            this.props.toggleAddItem(false); 
           
            this.setState({
                fieldName: '',
                isNewField: true,
                selectedOption: this.listTypes[0],
                isPrintable: false,
                selectedGroup: null,
            });
            
        }
        
        const selectGynoFromType = (this.props.formData.formType === this.formType.Gyno) || (!this.props.isFormGroupPresent(this.formType.Gyno) &&  this.props.formData.formType === this.formType.Gyno && this.props.mode === 'edit') ?  true  : false;

        const selectNonGynoFromType = (this.props.formData.formType === this.formType.NonGyno) || (!this.props.isFormGroupPresent(this.formType.NonGyno) &&  this.props.formData.formType === this.formType.NonGyno && this.props.mode === 'edit')  ?  true  : false;

        const groups = composePrintGroupOptions(this.props.printGroups);

        const composeProps = {
            onAddHandler: onAddHandler,
            handleCancel: handleCancel,
            itemNameFieldChange: itemNameFieldChange,
            optionChange: optionChange,
            handleShowInPrint: handleShowInPrint,
            selectedGroupChange: selectedGroupChange,
            selectedOption: this.state.selectedOption,
            isPrintable: this.state.isPrintable,
            isNewField: this.state.isNewField,
            fieldName: this.state.fieldName,
            listTypes: this.listTypes,
            isEditMode: this.props.isEditMode,
            clearFieldErrors: this.clearFieldErrors,
            groups: groups,
            selectedGroup: this.state.selectedGroup,
            //used to control what components are rendered in ComposeField
            fieldMode: true,
            title: this.state.isNewField ? 'New Feature' : 'Edit Feature'
        }

        const {formErrors} = this.props;

        return (<>
            <div className="crte-edt-wrap">
                <div className="title"><h3>Clinical Features</h3></div>
                <Section>
                    <FormGroup>
                        <span 
                        id="fgrp-name" className={`name-field ${formErrors.nameEmpty || formErrors.nameWrongValue ? 'error' : ''}`}
                        contentEditable={true} 
                        onInput={nameFieldChange} 
                        suppressContentEditableWarning={true}>
                            {featureGroupName}
                        </span>

                        { formErrors.nameEmpty && <ErrorMessage message ='Please enter Feature Group Name.' />}
                        { formErrors.nameWrongValue && <ErrorMessage message ='Name must be letter only.' />}

                    </FormGroup>
                    <FormGroup>
                        { !this.props.isFormGroupPresent(this.formType.Gyno) &&
                            <Label className="ip-radio-pill">
                                <Input onChange={radioFieldChange} type="radio" name="formType" value={this.formType.Gyno} checked={selectGynoFromType} />{' '}
                                <span className="radioselect"></span>
                                <span className="text">Gynecology Form</span>
                            </Label>
                         }
                        { !this.props.isFormGroupPresent(this.formType.NonGyno) &&
                        <Label className="ip-radio-pill">
                        <Input onChange={radioFieldChange } type="radio" name="formType"value={this.formType.NonGyno } checked={selectNonGynoFromType} />{' '}
                            <span className="radioselect"></span>
                            <span className="text">Non Gynecology Form</span>
                        </Label>
                        }
                    </FormGroup>
            
                </Section>
                <Section>
                    {isAddNew && !isEditMode? 
                    <ComposeField
                        {...composeProps}
                    />
                    :
                    <div className="add-new-wrap">
                        <Authority allowed={ 'create' } entity="clinicalformitem"  >
                            <Button onClick={addNewItem}>
                                <span>
                                    <img src={getIconUrl('plus')} alt='' />
                                </span>
                                Add Clinical Feature
                            </Button>
                        </Authority>
                    </div>
                    }
                </Section>
                <Section>
                    <h2>Items</h2>
                    <div className="fields-list">
                        { formErrors.itemsEmpty && <ErrorMessage message ='Add one or more form item.' />}

                        <List>
                            { featureItemsData.empty() ?  
                                <NotFound.Items messageComponent={
                                    <span>
                                        No <b>Clinical Features</b> were found. Create one above.
                                    </span>
                                } />
                            :
                                featureItemsData.map((item, i) => {
                                return this.state.fieldInEdit === item.index && this.props.isEditMode ?
                                <ComposeField
                                    {...composeProps}
                                />

                                :(<FeatureItem
                                    onEdit={editFieldItem}
                                    onDelete={removeFieldItem} 
                                    key={i}
                                    index={i} 
                                    editIndex={this.state.fieldInEdit}
                                    fieldMode={true}
                                    data={item}/>)
                                })}
                        </List>
                    </div>
                </Section>
            </div>
        </>);
    };
}


class FormPaneContainer extends React.Component {
    tabIds = {
        formFields: 1,
        formGroup: 2,
    }
    constructor(props){
        super(props);

        this.state = {
            activeTab: this.tabIds.formFields,
        }

        this.toggleTab = this.toggleTab.bind(this);
    }

    toggleTab(tabNumber){
        if(this.setState.activeTab !== tabNumber) this.setState({activeTab: tabNumber});
    }

    render(){
        return (
            <Container>
                <Row>
                    <Col>
                        <div className="tab-content-area">
                            <div className="tab-nav form-settings__tab">
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === this.tabIds.formFields })}
                                            onClick={() => { this.toggleTab(this.tabIds.formFields); }}
                                        >
                                        Form Fields
                                        </NavLink>
                                    </NavItem>
                                    <Authority allowed={ 'view' } entity="formprintgroup"  >
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === this.tabIds.formGroup })}
                                            onClick={() => { this.toggleTab(this.tabIds.formGroup); }}
                                        >
                                        Print Items
                                        </NavLink>
                                    </NavItem>
                                </Authority>

                                </Nav>
                            </div>
                            <div className="content-pane">
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId={this.tabIds.formFields}>
                                    <Section>
                                        <CreateEdit {...this.props} />
                                    </Section>
                                </TabPane>
                                <Authority allowed={ 'view' } entity="formprintgroup"  >
                                    <TabPane tabId={this.tabIds.formGroup}>
                                        <Section>
                                            <CreateEditPrintGroups {...this.props}/>
                                        </Section>
                                    </TabPane>
                                </Authority>
                            </TabContent>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

class Form extends React.Component {

    static pageOption = {
        pageTitle: 'Client',
        disableBodyScroll: true,
        grayBackground: true
    };

    viewModes = {
        list: 'list',
        createEdit: 'createEdit',
    }

    constructor(props) {
        super(props);
        
        props.apiRequestManager.register(actionTypes.FETCH_FORM_ITEM_GROUP, props.getFromGroup);
        props.apiRequestManager.register(actionTypes.FORM_GROUP_SUBMIT_DATA, props.submitData);

        this.state = {
            view: 'list',
            showAddNew: false,
            isEditMode: false,
            deleteDialogVisible: false,
            deleleContextItem: null,
            formErrors: {}
        }

        this.onCreateGroup = this.onCreateGroup.bind(this);
        this.onBackHandler = this.onBackHandler.bind(this);
        this.handleFreatureGroupEdit = this.handleFreatureGroupEdit.bind(this);
        this.handleFreatureGroupDelete = this.handleFreatureGroupDelete.bind(this);
        this.onToggleAddItem = this.onToggleAddItem.bind(this);
        this.onToggleEditMode = this.onToggleEditMode.bind(this);
        this.submitFormData = this.submitFormData.bind(this);
        this.isFormGroupPresent = this.isFormGroupPresent.bind(this);
        this.clearFieldErrors = this.clearFieldErrors.bind(this);

        this.handleDeleteFormGroup = this.handleDeleteFormGroup.bind(this);
        this.onConfirmDelete = this.onConfirmDelete.bind(this);
        this.showHideDeleteDialog = this.showHideDeleteDialog.bind(this);
    }

    componentDidMount() {
        
    }

    static getDerivedStateFromProps(props, state) {
       
        if ( !props.formGroupsList && !props.apiRequestManager.inProgress(actionTypes.FETCH_FORM_ITEM_GROUP)) {
           props.apiRequestManager.queueRequest(actionTypes.FETCH_FORM_ITEM_GROUP);
        }
       
        if ( props.redirectTo ) {
            props.doRedirect(props.redirectTo);
            props.onRedirect();
        }
        return null;
    }

    onCreateGroup() {
        this.props.setMode('create');
        this.setState({view: this.viewModes.createEdit});
    }

    onToggleAddItem(value) {
        this.setState({
            showAddNew: value,  
            fieldInEdit: -1, 
            isEditMode: false
        });
    }

    onToggleEditMode(value) {
        this.setState({
            isEditMode: value, 
            showAddNew: false,
            fieldInEdit: -1
        });
    }

    onBackHandler () {
        this.props.setFeatureData(null);
        this.props.formFieldChange({
            name: '',
            formType: null,
            items: []
        });
        this.props.history.push(urls.formSettingUri);
    }   

    handleFreatureGroupDelete(data) {
        const fieldGroup = !Object.blank(data)? data : this.props.featureGroupData;
        const payload = {
            requestType:  'delete',
            formGroupId: fieldGroup.id
        }

        this.props.apiRequestManager.queueRequest(actionTypes.FORM_GROUP_SUBMIT_DATA, payload);
    }

    handleFreatureGroupEdit(data) {
        this.props.setMode('edit');
        this.props.setFeatureData(data);
        this.setState({view: this.viewModes.createEdit});
    }

    isFormGroupPresent(formType) {
        const found = Array.isArray(this.props.formGroupsList) ? this.props.formGroupsList.filter((item) => formType === item.form) : [];
        return found.length > 0 ? true : false;
    }

    submitFormData() {
        var payload = {} 
     
        if (this.props.mode === 'create' && this.props.featureGroupData === null) {
            payload = {
                ...this.props.formData,
                requestType:  'create' 
            }
        }else {
            payload = {
                ...this.props.formData,
                requestType:  'update',
                formGroupId: this.props.featureGroupData.id
            }
        }
  
        const formErrors = this.validateFields(payload);
        if (Object.blank(formErrors)) {
            this.props.apiRequestManager.queueRequest(actionTypes.FORM_GROUP_SUBMIT_DATA, payload);
        }else {
            this.setState({
                formErrors: formErrors
            });
        }
    }

    validateFields(formData) {
        var validations = {};
        if (this.props.validator.isEmptyValue(formData.name) || formData.name === 'Enter name') {
            validations.nameEmpty = true;
        }
        if (this.props.validator.isNumberValue(formData.name)) {
            validations.nameWrongValue = true;
        }

        if (this.props.validator.isEmptyValue(formData.items)) {
            validations.itemsEmpty = true;
        }

        if (this.props.validator.isValueMissing(formData.formType)) {
            validations.formType = true;
        }

        return  validations;  
    }

    handleDeleteFormGroup(evt, formGroup) {
        const contextItem = {
            data: formGroup
        }
        this.showHideDeleteDialog(contextItem);
    }

    clearFieldErrors(fieldName, value) {
        this.setState({
            formErrors: this.state.formErrors,
            [fieldName]: value
        });
    } 

    onConfirmDelete(contextItem) {
        this.handleFreatureGroupDelete(contextItem.data);
        this.showHideDeleteDialog();
    }

    showHideDeleteDialog(contextItem) {
        this.setState({
            deleteDialogVisible: !this.state.deleteDialogVisible,
            deleleContextItem: contextItem || null
        });
    }

    getDialogMessage() {
       
        let apiOutcome =   this.props.apiRequestManager.getApiOutcome(actionTypes.FORM_GROUP_SUBMIT_DATA, true) ?? {};
        return getPromptMessageProps('formFieldsText', apiOutcome);
    }

    render () {
        
        const props = {
            ...this.props,
            formErrors: this.state.formErrors,
            showAddNew: this.state.showAddNew,
            isEditMode: this.state.isEditMode,
            onAddForm: this.onCreateGroup,
            onEdit: this.handleFreatureGroupEdit,
            onDeleteGroup: this.handleDeleteFormGroup,
            toggleAddItem: this.onToggleAddItem,
            toggleEditMode: this.onToggleEditMode,
            onBackHandler: this.onBackHandler,
            isFormGroupPresent: this.isFormGroupPresent,
            clearFieldErrors: this.clearFieldErrors
        }

        const actionBarCallback = {
            'save': this.submitFormData,
            'back': this.onBackHandler,
            'delete': this.handleDeleteFormGroup
        };

        const dialogProps = this.getDialogMessage();

        return (
            <React.Fragment>
                { this.state.view === this.viewModes.createEdit &&
                    <ActionBar title={ "Form" } 
                    saveTextSufix={ '' } 
                    entityContext={'clinicalitemgroup'}
                    permissionContext={ this.props.mode }
                    actionResponsers={ actionBarCallback } />
                }
                <div className='content-layout-wrap'>
                    { dialogProps.visible && 
                        <PromptMessage {...dialogProps} />
                    } 
                    <div className="view-layout-content container">
                    <div className="default-Ly-ct">
                        { this.state.view === this.viewModes.list ? 
                            <FormListView {...props} />
                                :
                            <FormPaneContainer {...props} />
                        }
                    </div>
                    </div>
                </div>
                { this.state.deleteDialogVisible && 
                <ConfirmModal 
                    contextName={'form items'}
                    contextItem={this.state.deleleContextItem} 
                    cancelCallback={this.showHideDeleteDialog}
                    confirmCallback={this.onConfirmDelete}
                />
                }
            </React.Fragment>
        );
    }
}

const FormView = (props) => {
    return (
        <Form {...props } />
    );
};

let ConfigFormView = setupPage(Form.pageOption)(FormView);
ConfigFormView =  connect(mapStateToProps, mapDispatchToProps)(ConfigFormView);

export { ConfigFormView };