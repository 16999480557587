import {ofType } from 'redux-observable';
import { catchError, exhaustMap, map,switchMap,  mergeMap, takeUntil } from 'rxjs/operators';
import { pingServerDoneAction } from '../actions';
import *  as actionTypes from '../actionType';
import { defer, from, of, forkJoin } from 'rxjs';
import { apiResponseActionCreator } from '../actions';
import * as actions from '../actions';
import { pingServer,commonDialect, authDialect, checkReponse, apiOutcome} from '../../service/agent';
import  { requestEnded } from '../../service/agentDialect';

export const pingServerEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.PING_SERVER),
        mergeMap(() => {
            return pingServer().pipe(
               map(payload => (pingServerDoneAction({ping: 'success'}))),
               catchError(error=> {
                    console.log(error);
                    return of(pingServerDoneAction({ping: 'failed'}))
               })
            );
        })
    )
}

export const refreshJwtTokenEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.REFRESH_JWT_TOKEN),
        mergeMap((action) => {
            const request$ = defer(() => from(authDialect.refreshJwtToken()));
            return request$.pipe(
               map(response => {
                const responseData = checkReponse(response);
                return apiResponseActionCreator(actionTypes.REFRESH_JWT_TOKEN_RESULT, responseData);
               }),
               catchError(error => {
                    return of(apiResponseActionCreator(actionTypes.REFRESH_JWT_TOKEN_RESULT, error))
               })
            );
        })
    )
}

export const fetchCodeSheets = action$ => {
    return action$.pipe(
        ofType(actionTypes.FETCH_CODE_SHEETS),
        
        exhaustMap((action) => {
    
            return commonDialect.fetchAllCodeSheets().pipe(
               map(response => {
                    const responseData = checkReponse(response, action.type);
                    return apiResponseActionCreator(actionTypes.FETCHED_CODE_SHEETS_RESULTS, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
               catchError(error => {
                    console.log("ERROR", error);
                    return of(apiResponseActionCreator(actionTypes.FETCHED_CODE_SHEETS_RESULTS, error));
               })
            );
        })
    )
};

export const fetchCodeFindings = action$ => {
    return action$.pipe(
        ofType(actionTypes.FETCH_CODE_FINDINGS),
        
        exhaustMap((action) => {
    
            return commonDialect.fetchAllCodeFindings().pipe(
               map(response => {
                    const responseData = checkReponse(response, action.type);
                    return apiResponseActionCreator(actionTypes.FETCHED_CODE_FINDINGS_RESULTS, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
               catchError(error => {
                    console.log("ERROR", error);
                    return of(apiResponseActionCreator(actionTypes.FETCHED_CODE_FINDINGS_RESULTS, error));
               })
            );
        })
    )
};


export const fetchConcurrentCodeSheetResultEntryEpic = (action$, store) =>
    action$.pipe(
    ofType(actionTypes.FETCH_CODE_SHEETS),
    //map(action => action.payload),
    mergeMap((action) => {
        let  promiselist = [
            commonDialect.fetchAllCodeSheets(),
        ];
        return forkJoin(
            promiselist
            )
        }),
    map(responses => {
        var allPayload = responses.map((response) => {
        return checkReponse(response); })
        allPayload = {
            codesheets: allPayload[0],
        }
        
        // requestEnded('actionTypes.FETCH_CODE_SHEETS');
        apiOutcome.initiator = actionTypes.FETCH_CODE_SHEETS;
        apiOutcome.success = true;
        requestEnded(apiOutcome);

        return {type: actionTypes.FETCHED_CODE_SHEETS_RESULTS, payload: allPayload}
    }),
    takeUntil(action$.pipe(ofType('ABORT'))),
    catchError(err => {
        console.log(err);
        return of({type: 'error', payloa: err })
    })
);

export const submitFileUploadEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.UPLOAD_FILE),
        mergeMap((action) => {
            return commonDialect.submitFileUpload(action.payload).pipe(
               map(response => {
                    const responseData = checkReponse(response, action.type);
                    return apiResponseActionCreator(actionTypes.UPLOAD_FILE_RESULT, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
               catchError(error => {
                    console.log("ERROR", error);
                    return of(apiResponseActionCreator(actionTypes.UPLOAD_FILE_RESULT, error));
               })
            );
        })
  )};

export const requestFileDownloadEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.DOWNLOAD_FILE),
        mergeMap((action) => {
            return commonDialect.fetchDownloadFile(action.payload).pipe(
               map(response => {
                    const responseData = response;
                    console.log(responseData);
                    return apiResponseActionCreator(actionTypes.DOWNLOAD_FILE_RESULT, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
               catchError(error => {
                    console.log("ERROR", error);
                    return of(apiResponseActionCreator(actionTypes.DOWNLOAD_FILE_RESULT, error));
               })
            );
        })
  )};


export const fetchConcurrentGlobalDataEpic = (action$, store) =>
    action$.pipe(
        ofType(actionTypes.FETCH_GLOBAL_DATA),
        switchMap((action) => {
        let  promiselist = [
            defer(() => from(commonDialect.fetchUnreadMessages(action.payload))),
            defer(() => from(commonDialect.fetchNotifications(action.payload)))
        ];
        return forkJoin(promiselist).pipe(
            map(responses => {
                var allPayload = responses.map((response) => {
                    return checkReponse(response);
                })
                allPayload = {
                    unreadMessages: allPayload[0],
                    notifications: allPayload[1]
                }
                apiOutcome.initiator = actionTypes.FETCH_GLOBAL_DATA
                apiOutcome.success = true
                requestEnded(apiOutcome);
        
                return {type: actionTypes.FETCHED_GLOBAL_DATA_RESULTS, payload: allPayload}
            }),
            takeUntil(action$.pipe(ofType('ABORT'))),
            catchError(err => {
                console.log(err);
                return of({type: 'error', payload: err });
            })
        )
    })
);

export const dismissNotificationEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.DISMISS_NOTIFICATION),
        mergeMap((action) => {
            return commonDialect.dismissNotification(action.payload).pipe(
               map(response => {
                    const responseData = checkReponse(response, action.type);
                    return apiResponseActionCreator(actionTypes.DISMISS_NOTIFICATION_RESULTS, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
               catchError(error => {
                    console.log("ERROR", error);
                    return of(apiResponseActionCreator(actionTypes.DISMISS_NOTIFICATION_RESULTS, error));
               })
            );
        })
  )};

export const fetchLabCodesEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.FETCH_LABCODES),
        mergeMap((action) => {
            return commonDialect.fetchAllLabCodes().pipe(
                map(response => {
                    const responseData = checkReponse(response, action.type);
                    return apiResponseActionCreator(actionTypes.FETCHED_LABCODES_RESULTS, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
                catchError(error => {
                    console.log("ERROR", error);
                    return of(apiResponseActionCreator(actionTypes.FETCHED_LABCODES_RESULTS, error));
                })
            );
        })
    )};

export const fetchServicesEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.FETCH_SERVICES),
        mergeMap((action) => {
            return commonDialect.fetchAllServices().pipe(
                map(response => {
                    const responseData = checkReponse(response, action.type);
                    return apiResponseActionCreator(actionTypes.FETCHED_SERVICES_RESULT, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
                catchError(error => {
                    console.log("ERROR", error);
                    return of(apiResponseActionCreator(actionTypes.FETCHED_SERVICES_RESULT, error));
                })
            );
        })
    )};

export const fetchTaxesEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.FETCH_TAXES),
        mergeMap((action) => {
            return commonDialect.fetchAllTaxes().pipe(
                map(response => {
                    const responseData = checkReponse(response, action.type);
                    return apiResponseActionCreator(actionTypes.FETCHED_TAXES_RESULTS, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
                catchError(error => {
                    console.log("ERROR", error);
                    return of(apiResponseActionCreator(actionTypes.FETCHED_TAXES_RESULTS, error));
                })
            );
        })
    )};

export const submitTaxesEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.TAX_SUBMIT_DATA),
        
        mergeMap((action) => {
            const apiCalls = {
            'delete': commonDialect.deleteTax,
            'submit': commonDialect.submitTaxes,
            }
            return apiCalls[action.payload.requestType](action.payload).pipe(
                map(response => {
                    const responseData = checkReponse(response, action.type, action.payload.requestType);
                    return apiResponseActionCreator(actionTypes.TAX_SUBMIT_DATA_RESULT, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
                catchError(error => {
                    console.log("ERROR", error);
                    return of(apiResponseActionCreator(actionTypes.TAX_SUBMIT_DATA_RESULT, error));
                })
            );
        })
    )};

export const submitServicesEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.SERVICE_SUBMIT_DATA),
        
        mergeMap((action) => {
            const apiCalls = {
            'delete': commonDialect.deleteService,
            'submit': commonDialect.submitServices,
            }
            return apiCalls[action.payload.requestType](action.payload).pipe(
                map(response => {
                    const responseData = checkReponse(response, action.type, action.payload.requestType);
                    return apiResponseActionCreator(actionTypes.SERVICE_SUBMIT_DATA_RESULT, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
                catchError(error => {
                    console.log("ERROR", error);
                    return of(apiResponseActionCreator(actionTypes.SERVICE_SUBMIT_DATA_RESULT, error));
                })
            );
        })
    )};

export const submitCodeSheetEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.CODESHEET_SUBMIT_DATA),
        
        mergeMap((action) => {
            const apiCalls = {
            'delete': commonDialect.deleteCodeSheet,
            'submit': commonDialect.submitCodeSheets,
            }
            return apiCalls[action.payload.requestType](action.payload).pipe(
                map(response => {
                    const responseData = checkReponse(response, action.type, action.payload.requestType);
                    return apiResponseActionCreator(actionTypes.CODESHEET_SUBMIT_DATA_RESULT, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
                catchError(error => {
                    console.log("ERROR", error);
                    return of(apiResponseActionCreator(actionTypes.CODESHEET_SUBMIT_DATA_RESULT, error));
                })
            );
        })
    )};

export const submitCodeFindingEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.CODEFINDING_SUBMIT_DATA),
        
        mergeMap((action) => {
            const apiCalls = {
            'delete': commonDialect.deleteCodeFinding,
            'submit': commonDialect.submitCodeFindings,
            }
            return apiCalls[action.payload.requestType](action.payload).pipe(
                map(response => {
                    const responseData = checkReponse(response, action.type, action.payload.requestType);
                    return apiResponseActionCreator(actionTypes.CODEFINDING_SUBMIT_DATA_RESULT, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
                catchError(error => {
                    console.log("ERROR", error);
                    return of(apiResponseActionCreator(actionTypes.CODEFINDING_SUBMIT_DATA_RESULT, error));
                })
            );
        })
    )};

export const submitLabCodeEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.LABCODE_SUBMIT_DATA),
        
        mergeMap((action) => {
            const apiCalls = {
            'delete': commonDialect.deleteLabCode,
            'submit': commonDialect.submitLabCode,
            }
            return apiCalls[action.payload.requestType](action.payload).pipe(
                map(response => {
                    const responseData = checkReponse(response, action.type, action.payload.requestType);
                    return apiResponseActionCreator(actionTypes.LABCODE_SUBMIT_DATA_RESULT, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
                catchError(error => {
                    console.log("ERROR", error);
                    return of(apiResponseActionCreator(actionTypes.LABCODE_SUBMIT_DATA_RESULT, error));
                })
            );
        })
    )};

export const getTaxesByTypeEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.FETCH_TAXES_BY_TYPE),
        switchMap((action) => {
            return commonDialect.fetchTaxesByType(action.payload).pipe(
                map(response => {
                    const responseData = checkReponse(response, action.type);
                    return  actions.apiResponseActionCreator(actionTypes.FETCHED_TAXES_BY_TYPE_RESULT, responseData);
                }),
                catchError(error => {
                    console.log("ERROR", error);
                    return of( actions.apiResponseActionCreator(actionTypes.FETCHED_TAXES_BY_TYPE_RESULT, error));
                })
            );
        })
    )
}
export const getDashboardDataEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.FETCH_DASHBOARD_DATA),
        mergeMap((action) => {
            return commonDialect.fetchDashboardData(action.payload).pipe(
                map(response => {
                    const responseData = checkReponse(response, action.type);
                    return  actions.apiResponseActionCreator(actionTypes.FETCHED_DASHBOARD_DATA_RESULT, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.FETCH_DASHBOARD_DATA + actionTypes.ABORT_API_REQUEST))),
                catchError(error => {
                    console.log("ERROR", error);
                    return of( actions.apiResponseActionCreator(actionTypes.FETCHED_DASHBOARD_DATA_RESULT, error));
                })
            );
        })
    )
}

export const contextSearchEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.CONTEXT_SEARCH),
        switchMap((action) => {
            const request$ = defer(() => from(commonDialect.searchRequest(action.payload)));
            return request$.pipe(
                map(response => {
                    const responseData = checkReponse(response, action.type);
                    return  actions.apiResponseActionCreator(actionTypes.CONTEXT_SEARCH_RESULTS, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.CONTEXT_SEARCH + actionTypes.ABORT_API_REQUEST))),
                catchError(error => {
                    return of( actions.apiResponseActionCreator(actionTypes.CONTEXT_SEARCH_RESULTS, error));
                })
            );
        })
    )
}

export const runReportEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.RUN_REPORT),
        mergeMap((action) => {
            return commonDialect.runReportRequest(action.payload).pipe(
                map(response => {
                    const responseData = checkReponse(response, action.type);
                    return apiResponseActionCreator(actionTypes.RUN_REPORT_RESULTS, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
                catchError(error => {
                    console.log("ERROR", error);
                    return of(apiResponseActionCreator(actionTypes.RUN_REPORT_RESULTS, error));
                })
            );
        })
    )};

export const getReportsSummaryEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.FETCH_REPORTS_SUMMARY),
        mergeMap((action) => {
            return commonDialect.fetchReportsSummary(action.payload).pipe(
                map(response => {
                    const responseData = checkReponse(response, action.type);
                    return apiResponseActionCreator(actionTypes.FETCHED_REPORTS_SUMMARY_RESULTS, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
                catchError(error => {
                    console.log("ERROR", error);
                    return of(apiResponseActionCreator(actionTypes.FETCHED_REPORTS_SUMMARY_RESULTS, error));
                })
            );
        })
    )};

    export const submitWorkspaceNameEpic = action$ => {
        return action$.pipe(
            ofType(actionTypes.SUBMIT_WORKSPACE_NAME),
            mergeMap((action) => {
                return commonDialect.updateWorkspaceName(action.payload).pipe(
                    map(response => {
                        const responseData = checkReponse(response, action.type);
                        return apiResponseActionCreator(actionTypes.SUBMIT_WORKSPACE_NAME_RESULTS, responseData);
                    }),
                    takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
                    catchError(error => {
                        console.log("ERROR", error);
                        return of(apiResponseActionCreator(actionTypes.SUBMIT_WORKSPACE_NAME_RESULTS, error));
                    })
                );
            })
        )};    