import React from 'react';
import { connect } from 'react-redux';
import { setupPage } from '../../components/Layout/setupPage';
import { 
    Container,
    Row,
    Col,
    Button,
    List,
    PatientTable,
    Authority
} from '../../components';

import {GENDER} from '../../common/constant';
import * as actionTypes from '../../store/actionType';
import  * as actions  from '../../store/actions';
import * as urls from './../../config/urls';
import  { PermissionEnforcer }  from '../../utility/PermissionEnforcer';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
import ProgressRing from '../components/partials/ProgressRing';
import PatientOrb from '../components/partials/PatientOrb';

import {SEARCH_SETTINGS } from '../../config/searchable';
import { PAGE_SIZE } from '../../config/pagination';
import './Patient.style.scss';

import { getIconUrl } from '../../common/images-catalogue';


const mapStateToProps = state => ({
    apiRequestManager: ApiRequestManager.getInstance(ApiRequestManager),
    useClientLayout: state.global.useClientDashboard,
    authorizeUser: state.global.authorizeUser,
    mode:  state.global.actionBarMode,
    workspace: state.global.workspace,
    account: state.global.account,
    ...state.patient
});

const mapDispatchToProps = dispatch => ({ 
    onLoad: () => dispatch(actions.patientsViewLoadedAction()),
    unLoad: () => dispatch(actions.patientsViewUnloadAction()),
    getPatients: (workspace) => dispatch(actions.getClientPatientsAction(workspace)),
    setSplitGender: (payload) => dispatch(actions.setSplitGenderAction(payload)),
    setPatient: (payload) => dispatch(actions.setPatientAction(payload)),
    setMode: (mode) => dispatch(actions.actionBarModeAction(mode))
});

class Patient extends React.Component {

    static pageOption = { 
        pageTitle: 'Patients',
        searchContext: SEARCH_SETTINGS.entities.patient.name,
        disableBodyScroll: true
    };

    constructor(props) {
        super(props);
        this.state = {
            malePercentage: 0,
            femalePercentage: 0,
            percentageSet: false ,
            percentage : {},
            pageSize: PAGE_SIZE,
            currentPage: 0
        };

        props.apiRequestManager.register(actionTypes.FETCH_PATIENTS, props.getPatients);

        this.patientsTable = React.createRef();

        this.viewPatientDetails = this.viewPatientDetails.bind(this);
        this.onAddPatient = this.onAddPatient.bind(this);
        this.handleTableChange = this.handleTableChange.bind(this);
    }

    componentDidMount() {
        this.props.onLoad();
        this.unlistenAbort = this.props.history.listen(() => { 
            this.props.apiRequestManager.abortRequest(actionTypes.FETCH_PATIENTS);
        });
        // emulating progress;
    }

    componentWillUnmount() {
        this.props.unLoad();
        this.unlistenAbort();
    }

    shouldComponentUpdate(props, state) {
        if (props.patientsList?.analytics  && !state.percentageSet) {
            this.getPercentageRatio(props);
        }
        return true;
    }

    static getDerivedStateFromProps(props, state) {
        // Return null to indicate no change to state.
        if ( !props.patientsList && !Object.blank(props.workspace) ) {
            const requestParams = {
                workspaceId: props.useClientLayout ? props.workspace.id : null,
                pageSize: state.pageSize,
                page: state.currentPage
            }
            props.apiRequestManager.queueRequest(actionTypes.FETCH_PATIENTS, requestParams);
        }

        if ( props.patientsList && !props.malePatients && !props.femalePatients ) {
            Patient.filterPatients(props);
        }
        
        return null;
    }

    willHandleTableRowMouseOver(rowData) {}

    static filterPatients(props) {
        const females = [];
        const males = [];
        
        if (props.patientsList.pageData) {
            for(let patient of props.patientsList.pageData) {
                if (patient.gender === GENDER.Male) {
                    males.push(patient);
                }else {
                    females.push(patient);
                }
            }
        }

        props.setSplitGender({males: males, females: females});
    }
    
    viewPatientDetails(data) {
        this.props.setMode('view');
        this.props.setPatient(data);
        this.props.history.push(urls.patientOverviewUri);
    }
    
    getPercentageRatio(props) {
        const ratio = {
            male : 0,
            female: 0
        }
        const useProps = props || this.props;
        
        if (useProps.patientsList.analytics) {
            let count = useProps.patientsList.analytics.totalPatients || 0;
            let malePercent = (useProps.patientsList.analytics.totalMalePatients / count) * 100;
            let femalePercent = 100 - malePercent;
            ratio.male = malePercent > 0 ? malePercent.toFixed(0) : 0;
            ratio.female = femalePercent> 0 ? femalePercent.toFixed(0): 0;
        }

        setTimeout(() => {
            this.setState({ malePercentage: ratio.male , femalePercentage: ratio.female , percentageSet: true, percentage: ratio});
        }, 600);

        return ratio;
    }

    onAddPatient(evt) {
        this.props.history.push(urls.patientCreateUri);
    }

    handleTableChange(tableOpt) {

        const pageNumber = !tableOpt.page || tableOpt.page - 1;
        const pageSize = tableOpt.sizePerPage;

        const requestParams = {
            workspaceId: this.props.useClientLayout ? this.props.workspace.id : null,
            page: pageNumber,
            pageSize: pageSize
        };

        this.props.apiRequestManager.queueRequest(actionTypes.FETCH_PATIENTS, requestParams);

        this.setState({
            currentPage: pageNumber,
            pageSize: pageSize
        });

        this.props.setSplitGender({males: null, females: null});
    }

    render () {
        const quickActionCallback = {
            viewDetails: this.viewPatientDetails
        };

        let {pageData, analytics, ...tableMetadata} = this.props.patientsList || [];
        
        tableMetadata = {
            ...tableMetadata,
            remote: true,
            pageSize: this.state.pageSize
        };

        const tableData = pageData || [];
        const malePatients = this.props.malePatients ? this.props.malePatients : [];
        const femalePatients = this.props.femalePatients? this.props.femalePatients : [];
        const femalePatientsCount = analytics ? analytics.totalFemalePatients : 0;
        const malePatientsCount = analytics ? analytics.totalMalePatients : 0;
        const percentage = this.state.percentage;
        
        return (
            <React.Fragment>
            <div className='content-layout-wrap patient-list'>
                <div className="view-layout-content">
                   <div className="default-Ly-ct">
                        <Container>
                            <Row>
                                <Col lg={3}>
                                    <div className="widget-section">
                                        <div className="button-wrap">
                                         <Authority allowed="create" entity="patient" >
                                            <Button className="default-btn add-btn" onClick={this.onAddPatient}>
                                                <span className="icon patient-icon"><img src={getIconUrl("addPatient")} alt=""/></span>
                                                Add Patient
                                            </Button>
                                        </Authority>
                                        </div>
                                        <div className="patients-widgets-list">
                                            <div className="progress-holder">
                                                <ProgressRing
                                                radius={ 80 }
                                                stroke={ 8 }
                                                label={'Male'}
                                                progress={ this.state.malePercentage }
                                                />
                                            </div>
                                            <div className="progress-holder">
                                            <ProgressRing
                                            radius={ 80 }
                                            stroke={ 8 }
                                            label={'Female'}
                                            color={'#ff87b2'}
                                            progress={ this.state.femalePercentage }
                                            />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={9}>
                                    <div className="summay-details">
                                        <div className="title-d">
                                            <h3 className="title">Male to Female Patients</h3>
                                        </div>
                                        <div className="ratio-holder">
                                            <div className="ratio-count">
                                                <span className="male-count">{malePatientsCount > 0 ? malePatientsCount : 'No'} <i> males</i></span>
                                                <span className="female-count">{femalePatientsCount > 0 ? femalePatientsCount : 'No'} <i> females</i></span>
                                            </div>
                                            <div className="ratio-line">
                                                <div className={`male-line r-line ${percentage.male > 0 ? '--visible' : ''}`} style={
                                                    {width: `${percentage.male}%`}
                                                }></div>
                                                <div className={`female-line r-line ${percentage.female > 0 ? '--visible' : ''}`} style={
                                                    {width: `${percentage.female}%`}
                                                }></div>
                                            </div>
                                        </div>
                                        <div className="recent-patient">
                                            <div className="male-group">
                                                <List className="recent-list">
                                                    { malePatients.slice(0, 5).map((patient) => {
                                                         const name = `${patient.firstName} ${patient.lastName}`
                                                        return <li key={patient.id}>
                                                            <PatientOrb 
                                                                clickHandler={this.viewPatientDetails}
                                                                data={patient}
                                                            />
                                                        </li>
                                                    })}
                                                </List>
                                            </div>
                                            <div className="female-group">
                                                <List className="recent-list">
                                                    { femalePatients.slice(0, 5).map((patient) => {
                                                        return <li key={patient.id}>
                                                            <PatientOrb 
                                                                clickHandler={this.viewPatientDetails}
                                                                data={patient}
                                                            />
                                                        </li>
                                                    })}
                                                </List>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-container">
                                    <PatientTable 
                                    ref={this.patientsTable}
                                    id="patientTable" 
                                    hoverOverRowAction={this.willHandleTableRowMouseOver}
                                    quickActions={quickActionCallback} 
                                    tableData={tableData}
                                    client={this.props.account?.owner}
                                    isClientDashboard={this.props.useClientLayout}
                                    metaData={tableMetadata}
                                    onTableChange={this.handleTableChange}
                                    />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                   </div>
                </div>
            </div>
            </React.Fragment>
        );
    }
}

const PatientView = (props) => {
    return (
        <Patient {...props } />
    );
};

let ConfigPatientView = setupPage(Patient.pageOption)(PatientView);
ConfigPatientView =  connect(mapStateToProps, mapDispatchToProps)(ConfigPatientView);

export { ConfigPatientView };
