import {ofType } from 'redux-observable';
import { catchError, map,  switchMap, mergeMap, mapTo, filter, takeUntil, exhaustMap } from 'rxjs/operators';
import *  as actionTypes from '../actionType';
import { from, defer, of, forkJoin } from 'rxjs';
import { apiResponseActionCreator } from '../actions';
import { permissionDialect, checkReponse, apiOutcome } from '../../service/agent';

import  { requestEnded } from '../../service/agentDialect';

export const permissionRoleLoadedEpic = action$ => action$.pipe(
  filter(action => action.type === actionTypes.PERMISSIONS_ROLES_LOADED),
  mapTo({ type: 'PONG' })
);

export const getSystemPermissionsEpic = action$ => {
  return action$.pipe(
      ofType(actionTypes.FETCH_PERMISSIONS),
      
      mergeMap((action) => {
          const request$ = defer(() => from(permissionDialect.fetchAllPermissions(action.payload)));
          return request$.pipe(
             map(response => {
                  const responseData = checkReponse(response, action.type);
                  return apiResponseActionCreator(actionTypes.FETCHED_PERMISSIONS_RESULTS, responseData);
              }),
              takeUntil(action$.pipe(ofType(actionTypes.FETCH_PERMISSIONS + actionTypes.ABORT_API_REQUEST))),
              catchError(error => {
                  console.log("ERROR", error);
                  return of(apiResponseActionCreator(actionTypes.FETCHED_PERMISSIONS_RESULTS, error));
             })
          );
      })
  )};

  export const getAllRolesEpic = action$ => {
    return action$.pipe(
        //ofType(actionTypes.FETCH_ROLES),
        filter(action => action.type === actionTypes.FETCH_ROLES),
        
        switchMap( (action) => {
            const request$ = defer(() => from(permissionDialect.fetchAllRoles(action.payload)));
            return request$.pipe(
                map(response => {
                    const responseData = checkReponse(response, action.type);
                    return apiResponseActionCreator(actionTypes.FETCH_ROLES_RESULTS, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.FETCH_ROLES + actionTypes.ABORT_API_REQUEST))),
                catchError(error => {
                    console.log("ERROR", error);
                    return of(apiResponseActionCreator(actionTypes.FETCH_ROLES_RESULTS, error));
               })
            );
        })
    )};


export const submitRolePermissionsEpic = action$ => {
  return action$.pipe(
      ofType(actionTypes.PERMISSIONS_ROLE_SUBMIT_DATA),
      
      mergeMap((action) => {
        const apiCalls = {
          'delete': permissionDialect.deleteRolePermissions,
          'create': permissionDialect.submitRolePermissions,
          'update': permissionDialect.updateRolePermissions
        }
          return apiCalls[action.payload.requestType](action.payload).pipe(
             map(response => {
                  const responseData = checkReponse(response, action.type, action.payload.requestType);
                  return apiResponseActionCreator(actionTypes.PERMISSIONS_ROLE_SUBMITED_RESULTS, responseData);
              }),
             catchError(error => {
                  console.log("ERROR", error);
                  return of(apiResponseActionCreator(actionTypes.PERMISSIONS_ROLE_SUBMITED_RESULTS, error));
             })
          );
      })
)};
  

export const permissionRoleViewEpic = action$ => action$.pipe(
    filter(action => action.type === actionTypes.PERMISSIONS_ROLE_VIEW),
    mapTo((action)=> {
        console.log(action);
    })
);

export const fetchConcurrentRolesPermissionsEpic = (action$, store) =>
    action$.pipe(
        ofType(actionTypes.FETCH_ROLES_PERMISSIONS),
    //map(action => action.payload),
    mergeMap((action) => {
        
      let  promiselist = [
        defer(() => from(permissionDialect.fetchAllRoles(action.payload))),//.catchError(err => of({type: 'error', payloa: err }) ),
        defer(() => from(permissionDialect.fetchAllPermissions(action.payload)))//.catchError(err => of({type: 'error', payloa: err }) )
      ];
      return forkJoin(
        promiselist
      )
  }),
 map(responses => {
    var allPayload = responses.map((response) => {
        return checkReponse(response);
    })
    allPayload = {
      roles: allPayload[0],
      permissions: allPayload[1]
    }

    apiOutcome.initiator = actionTypes.FETCH_ROLES_PERMISSIONS;
    apiOutcome.success = true;
    requestEnded(apiOutcome);

    return {type: actionTypes.FETCH_ROLES_PERMISSIONS_RESULTS, payload: allPayload}
 }),
 takeUntil(action$.pipe(ofType(actionTypes.FETCH_ROLES_PERMISSIONS + actionTypes.ABORT_API_REQUEST))),
 catchError(err => {
     console.log(err);
    return of({type: 'error', payload: err })})
 );

