import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import { LayoutContent } from './LayoutContent';
import { LayoutNavbar } from './LayoutNavbar';
import { LayoutWorkspaceBar } from './LayoutWorkspaceBar';
import { PageConfigContext } from './PageConfigContext';
import { ThemeClass } from './../Theme';

const findChildByType = (children, targetType) => {
    let result;

    React.Children.forEach(children, (child) => {
        if (child.type.layoutPartName === targetType.layoutPartName) {
            result = child;
        }
    });

    return result;
};

const findChildrenByType = (children, targetType) => {
    return _.filter(React.Children.toArray(children), (child) =>
        child.type.layoutPartName === targetType.layoutPartName);
};

class Layout extends React.Component {

    static propTypes = {
        children: PropTypes.node
    }
    
    bodyElement = null;

    constructor(props) {
        super(props);

        this.state = {
            sidebarHidden: false,
            navbarHidden: false,
            footerHidden: false,
            workspaceBar: true,
            pageTitle: null,
        };

        this.changeMeta = this.changeMeta.bind(this);
        this.useGrayBackground = this.useGrayBackground.bind(this);
        this.setSearchContext = this.setSearchContext.bind(this);
        
        this.initialization();
    }

    initialization() {
        if (typeof document !== 'undefined') {
            this.bodyElement = document.body;
            this.documentElement = document.documentElement;
        } 
    }

    componentDidMount() {
        // Add document initialization
        
    }

    setElementsVisibility(elements) {
        this.setState(_.pick(elements, ['sidebarHidden', 'workspaceBar', 'navbarHidden', 'footerHidden']));
    }

    setSearchContext(context) {
        this.setState({searchContext: context});
    }

    changeMeta(metaData) {
        this.setState(metaData);
    }

    useGrayBackground(use) {
        use &&  this.bodyElement.classList.add('background-gray');
        !use && this.bodyElement.classList.remove('background-gray');
    }
    
    disableBodyScroll(disable) {
        disable && document.body.classList.add('diable-scroll');
        !disable && document.body.classList.remove('diable-scroll');
    }

    render() {
        const { children } = this.props;
        const content = findChildByType(children, LayoutContent);
        const navbars = findChildrenByType(children, LayoutNavbar);
        const workspaceBar = findChildrenByType(children, LayoutWorkspaceBar);
        const otherChildren = _.differenceBy(
            React.Children.toArray(children),
            [
                ...navbars,
                ...workspaceBar,
                content
            ],
            'type'
        );

        const layoutClass = classNames('layout', 'layout--animations-enabled', {
            //'layout--only-navbar': this.state.sidebarHidden && !this.state.navbarHidden
        });
        
        return (
           
            <PageConfigContext.Provider
                value={{
                    ...this.state,
                    setElementsVisibility: this.setElementsVisibility.bind(this),
                    changeMeta: this.changeMeta,
                    setSearchContext: this.setSearchContext,
                    useGrayBackground: this.useGrayBackground,
                    disableBodyScroll: this.disableBodyScroll
                }}>
                <ThemeClass>
                    {(themeClass) => (
                        <div className={ classNames(layoutClass, themeClass) }>
                            <div className="layout__wrap">
                                { !this.state.navbarHidden && navbars }
                                { this.state.workspaceBar && workspaceBar }
                                { content }
                            </div>
                            { otherChildren }
                        </div>
                    )}
                </ThemeClass>
            </PageConfigContext.Provider>
        );
    }
}

const routedLayout = withRouter(Layout);

export { routedLayout as Layout };