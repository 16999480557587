import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setupPage } from '../../../../components/Layout/setupPage';
import {
    List, 
    Input,
    FormGroup,
    Label,
    Section,
} from '../../../../components';

import  * as actions  from '../../../../store/actions';
import * as actionTypes from '../../../../store/actionType';
import { AddButton } from '../../../components/partials/Buttons';
import { ErrorMessage } from '../../../components/partials/ErrorMessage';
import text from '../../../../common/strings';
import { REQUESTS_TYPE, VALIDATOR_DELEGATE_KEY_GROUP, VALIDATOR_DELEGATE_TYPES } from '../../../../common/constant';
import { PromptMessage, getPromptMessageProps } from '../../../components/partials/PromptMessage';
import settingsValidatorDelegate from '../../../../forms/settingsValidator.delegate';
import { objListsAreEqual } from '../../../../utility/helpers';

import { getIconUrl } from '../../../../common/images-catalogue';
const mapStateToProps = state => ({
    ...state.settings.serviceSetting
});

const mapDispatchToProps = dispatch => ({
    getAllServices: () => dispatch(actions.getAllServicesAction()),
    setServices: (type, data) => dispatch(actions.setSettingDataAction(type, data)),
    submitData: (payload) => dispatch(actions.submitServicesAction(payload)) 
});

const ServiceItem = (props) => {
    const {data, actions} = props;

    const fieldChange = (evt) => {
        const values = {
            index: data.index,
            ...data,
            [evt.target.name]: evt.target.value
        };
        actions.addOrChange(evt, data.index, values);
    };
    
    const isNewClass = !data.id ? '--new' : '';

    const text = !data.id ? 'Remove' : 'Delete';

    return (
        <li className={`services-item ${isNewClass}`}>
            <div className="service" >
            <div className="col-l">
                <div className="ser-icon">
                    <img src={getIconUrl('service')} alt='' />
                </div>
            </div>
            <div className="col-r">
                <FormGroup>
                    <Label>Name</Label>
                    {(data.name ? '':<ErrorMessage message='Enter a Service Name'/>)}
                    <Input type="text" name='name' 
                    onChange={fieldChange}
                    placeholder="Service name"  value={data.name}/>
                </FormGroup>

                <FormGroup>
                    <Label>Price</Label>
                    {(data.price || data.price === 0 ? '':<ErrorMessage message='Enter a price'/>)}
                    <Input type="number" 
                    name="price"
                    min={1}
                    placeholder="0.0"
                    onChange={fieldChange}
                    value={data.price} />
                </FormGroup>

                <FormGroup>
                    <Label>Description</Label>
                    <Input onChange={fieldChange} type="text" placeholder="description" name="description" value={data.description} />
                </FormGroup>
                <div className="ser-ft">
                    <Link onClick={(evt) => actions.remove(evt, data)} to={'#'} className="remove"><img className={'icon'} src={getIconUrl('trash')} alt='' /> {text}</Link>
                </div>
            </div>
            
            </div>
        </li>
    );
}

class Services extends React.Component {

    static pageOption = {
        pageTitle: 'Client',
        disableBodyScroll: true
    };

    constructor(props) {
        super(props);

        props.apiRequestManager.register(actionTypes.FETCH_SERVICES, props.getAllServices);
        this.props.apiRequestManager.register(actionTypes.SERVICE_SUBMIT_DATA, props.submitData);

        this.state = {

        };

        this.addOrReplaceService = this.addOrReplaceService.bind(this);
        this.removeService = this.removeService.bind(this);
        this.composeService = this.composeService.bind(this);
        this.onSaveHanlder = this.onSaveHanlder.bind(this);
        this.getPromptMessage = this.getPromptMessage.bind(this);
        this.validation = this.validation.bind(this);

        props.innerRef.saveHanlder = this.onSaveHanlder;
        props.innerRef.confirmDeleteCallback = this.onConfirmDelete.bind(this);
    }
    
    componentDidMount() {
        this.props.validator.addDelegate(VALIDATOR_DELEGATE_TYPES.settings, settingsValidatorDelegate)
     }

    static getDerivedStateFromProps(props, state) {
    
        if ( !props.servicesData && !props.apiRequestManager.inProgress(actionTypes.FETCH_LABCODES)) {
            props.apiRequestManager.queueRequest(actionTypes.FETCH_SERVICES);
        }
        return null;
    }

    shouldComponentUpdate(nextProps){
        /**
         * This runs the validation if something changed in services list.
         */
        const prevServices = this.props.servicesData;
        const nextServices = nextProps.servicesData;

        if (Array.isArray(prevServices) && Array.isArray(nextServices) && !objListsAreEqual(prevServices, nextServices,'index')){
            const servicesErrors = this.validation(nextServices);
            
            if(this.props.validator.hasActiveFormError(servicesErrors)){
                this.props.actions.enableSave(false);
            }else{
                this.props.actions.enableSave();
            }
        }

        return true;
    }


    getPromptMessage(){
        const apiOutcome = this.props.apiRequestManager.getApiOutcome(actionTypes.SERVICE_SUBMIT_DATA, true) ?? {};
        return getPromptMessageProps('service', apiOutcome);

    }

    removeService(evt, service) {
        evt.preventDefault();
        if (service.id) {
            this.props.showDeleteDialog('service', service);
        }else {
            const services = this.props.servicesData.filter((item) => service.index !== item.index);
            this.props.setServices(actionTypes.SET_SERVICES_DATA, services);
        }
    }

    onConfirmDelete(deleteItem) {
        const payload = {
            requestType: 'delete',
            serviceId: deleteItem.id
        };
        
        this.props.apiRequestManager.queueRequest(actionTypes.SERVICE_SUBMIT_DATA, payload);
    }

    addOrReplaceService(evt, itemIndex, service) {
        var services = [];

        if (itemIndex === undefined) {
            let servicesWithNewEntry = [this.composeService(), ...this.props.servicesData];
            servicesWithNewEntry.sortArrayObjectByKey('index')

            services = !this.props.servicesData.length? [this.composeService()] : servicesWithNewEntry;
            this.props.setServices(actionTypes.SET_SERVICES_DATA, services);
        }else {
            services = this.props.servicesData.map((item) => {
                if (item.index === itemIndex) {
                    item = {
                        ...service
                    }
                }
                return item;
            });
            this.props.setServices(actionTypes.SET_SERVICES_DATA, services);
        }
    }

    validation(services){
        return {
            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.settings, VALIDATOR_DELEGATE_KEY_GROUP.settings.services, services)
        }
    }

    composeService() {
        const service = {
            index: 0,
            name: '',
            price: 0.0,
            description: ''
        }
        return service;
    }

    onSaveHanlder() {
        const payload = {
            requestType: 'submit',
            data: this.props.servicesData.map((item) => {
                return {
                    ...item,
                    price: parseFloat(item.price)
                }
            })
        }
        this.props.apiRequestManager.queueRequest(actionTypes.SERVICE_SUBMIT_DATA, payload);
    }

    render () {

        const services = Array.isArray(this.props.servicesData) ? this.props.servicesData :  [];
        
        const actions = {
            remove: this.removeService,
            addOrChange: this.addOrReplaceService
        }

        const dialogProps = this.getPromptMessage();

        return (
            <React.Fragment>
                { dialogProps.visible && 
                        <PromptMessage {...dialogProps} />
                } 
                <div className='pane-layout-wrap pane services-setting-pane'>
                    <div className="pane-cont">
                        <div className="setting-info">
                            <p>Service Items created in this section are used when generating a <b>Bill</b>. </p>
                        </div>
                        <div className="actions-btn">
                            <AddButton actionHandler={this.addOrReplaceService} label={'Add Service'} />
                        </div>
                        <Section className="services-list">
                            <List>
                                { services.map((item, i) => {
                                    return <ServiceItem key={i} actions={actions} data={item} />
                                }) }
                                
                            </List>
                        </Section>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const ServicesView = (props) => {
    return (
        <Services {...props } />
    );
};

let ConfigServicesView = setupPage(Services.pageOption)(ServicesView);
ConfigServicesView =  connect(mapStateToProps, mapDispatchToProps)(ConfigServicesView);

export { ConfigServicesView };