import React from 'react';


const SelectedOption = (props) => {

    const { children, className } = props;
    const classNames = className ? className : '';
    return (<div className={`selected-option ${classNames}`}>
        {children}
    </div>)
}

export default SelectedOption;