import React from 'react';
import { connect } from 'react-redux';
//import { hot } from 'react-hot-loader'
import DefaultLayout from './layouts/default.layout';
import { RoutedContent } from './routes';
import * as actionTypes from './store/actionType';
import { JWT_COLLECTION_KEY, DASHBOARD_KEY } from './common/constant';
import { setToken, getApiRoot } from './service/agentDialect';
import * as urls from './config/urls';
import { store } from './store/store';
import { push } from 'connected-react-router';
import { decodeJwtToken, isJwtTokenExpired } from './utility/helpers';
import SocketCommunicator from './utility/SocketCommunicator';

//import  ConcurrentTaskQueue  from "./utility/ConcurrentTaskQueue";
import NetworkStats from './utility/NetworkStats';
//import {apiServer} from './service/agentDialect';

import {
  pingServerAction,
  userDetailsActionCreator,
  authUserRequestedActionCreator,
  getClientAccountAction,
  dashboadLayoutAction,
  authRefreshTokenAction,
  getGlobalAppDataAction,
  logoutActionCreator,
  appLoadedAction,
  appRouteChangeAction,
  getWorkspaceDetailsAction,
} from './store/actions';

import { PermissionEnforcer } from './utility/PermissionEnforcer';
import { ApiRequestManager } from './utility/ApiRequestManager';
import { get } from 'lodash';

const mapStateToProps = (state) => {
  return {
    apiRequestManager: ApiRequestManager.getInstance(ApiRequestManager),
    enforcer: PermissionEnforcer.getInstance(PermissionEnforcer),
    socketCommunicator: SocketCommunicator.getInstance(SocketCommunicator),
    useClientDashboard:
      state.global.useClientDashboard !== undefined
        ? state.global.useClientDashboard
        : window.localStorage.getItem(DASHBOARD_KEY) === 'true'
        ? true
        : false,
    authorizeUser: state.global.authorizeUser,
    token: state.global.token,
    redirectTo: state.global.redirectTo,
    account: state.global.account,
    workspace: state.global.workspace,
    initGlobalData: state.global.initGlobalData,
    appInitialize: state.global.initialize,
    networkStatus: state.global.networkStatus,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onLoad: (token) => dispatch(appLoadedAction(token)),
  onRedirect: () => dispatch({ type: actionTypes.REDIRECT }),
  pingServer: () => dispatch(pingServerAction()),
  setupUserDetails: (details) => dispatch(userDetailsActionCreator(details)),
  getAuthUser: () => dispatch(authUserRequestedActionCreator()),
  getClientAccount: (userId) => dispatch(getClientAccountAction(userId)),
  getWorkspaceDetails: () => dispatch(getWorkspaceDetailsAction()),
  setLayoutFlag: (clientLayout) => dispatch(dashboadLayoutAction(clientLayout)),
  refreshToken: (header) => dispatch(authRefreshTokenAction(header)),
  logout: () => dispatch(logoutActionCreator()),
  getGlobalData: (userId) => dispatch(getGlobalAppDataAction(userId)),
  setAppRoute: (route) => dispatch(appRouteChangeAction(route)),
});

class App extends React.Component {
  static redirectExceptionUri = {
    payslipsUriPattern: /^\/payroll\/payslip\/(\d+)/,
    payslipSingleUriPattern: /^\/payroll\/payslip\/(\d+)\/\?payadvice_f=(\d+)/,
  };

  constructor(props) {
    super(props);

    this.state = {};

    this.registerApiRequest();

    if (!window.localStorage.getItem(JWT_COLLECTION_KEY)) {
      window.localStorage.setMap(JWT_COLLECTION_KEY, {});
    }
    this.props.setLayoutFlag(this.props.useClientDashboard);
    //this.props.pingServer();
    !this.isLoginOrHome() && this.initializeSecurityChecks();
    this.routeDidChange = this.routeDidChange.bind(this);
    this.registerApiRequest = this.registerApiRequest.bind(this);
    // this.networkUtil = NetworkStats.getInstance(NetworkStats, store.dispatch, getApiRoot());
  }

  registerApiRequest() {
    this.props.apiRequestManager.register(
      actionTypes.REQEUST_AUTH_USER,
      this.props.getAuthUser
    );
    this.props.apiRequestManager.register(
      actionTypes.FETCH_CIENT_ACCOUNT,
      this.props.getClientAccount
    );
    this.props.apiRequestManager.register(
      actionTypes.FETCH_WORKSPACE_DETAILS,
      this.props.getWorkspaceDetails
    );
    this.props.apiRequestManager.register(
      actionTypes.FETCH_GLOBAL_DATA,
      this.props.getGlobalData
    );
  }

  static authGuard(props) {
    const currentUser = window.localStorage.getMap('activeUser');
    const isPresent = (!Object.blank(currentUser) && currentUser.user) ? true : false;
    const grantedAccess =
      (props.authorizeUser || isPresent) && props.token ? true : false;

    if (!grantedAccess && props.location.pathname !== urls.rootUri) {
      
      const payslipsRegExp = new RegExp(
        App.redirectExceptionUri.payslipsUriPattern
      );
      const payslipSingleRegExp = new RegExp(
        App.redirectExceptionUri.payslipSingleUriPattern
      );

      if (
        props.location.pathname !== urls.loginUri &&
        !payslipsRegExp.test(props.location.pathname) &&
        !payslipSingleRegExp.test(props.location.pathname)
      ) {
        props.history.push({ pathname: urls.loginUri});
        props.enforcer.discard();
        //props.logout();
      }
      //window.history.replaceState(null, "New Page Title", "/auth/login")
    }

    /**
     * To prevent permissions from being lost if a token is actual present.
     */
    if (
      props.token !== null &&
      props.enforcer.authorities &&
      !props.enforcer.authorities.length
    ) {
      let details = {
        claims: decodeJwtToken(props.token),
        superRole: currentUser.superRole,
      };
      props.setupUserDetails(details);
    }
  }

  componentDidMount() {
    this.routeChangeUnlisten = this.props.history.listen((location, action) => {
      this.routeDidChange(location, action);
    });
  }

  componentWillUnmount() {
    this.routeChangeUnlisten();
    this.props.enforcer.discard();
    this.props.socketCommunicator?.destroy();
  }

  routeDidChange(location, action, other) {
    this.props.setAppRoute(location.pathname);
  }

  isLoginOrHome() {
    return this.props.location.pathname === urls.rootUri ||
      this.props.location.pathname === urls.loginUri
      ? true
      : false;
  }

  static getDerivedStateFromProps(props, state) {
    App.authGuard(props);

    if (props.redirectTo) {
      store.dispatch(push(props.redirectTo));
      //props.history.push({ pathname: props.redirectTo});
      props.onRedirect();
    }

    if (props.authorizeUser && Object.blank(props.workspace)) {
      if (props.enforcer.isClientOrStaff()) {
        props.apiRequestManager.queueRequest(
          actionTypes.FETCH_CIENT_ACCOUNT,
          props.authorizeUser.id
        );
      } else {
        props.apiRequestManager.queueRequest(
          actionTypes.FETCH_WORKSPACE_DETAILS
        );
      }
      //Open Websoket Connection
      props.socketCommunicator.authUser = props.authorizeUser.username;
    }

    if (props.authorizeUser && props.workspace && !props.initGlobalData) {
      //Get Global App Data
      App.getGlobalData(props);
    }

    if (
      props.authorizeUser &&
      !SocketCommunicator.connected &&
      !SocketCommunicator.initialize
    ) {
      props.socketCommunicator.openConnection();
      SocketCommunicator.initialize = true;
    }

    return null;
  }

  initializeSecurityChecks() {
    const storedSessionUser = window.localStorage.getMap('activeUser');
    const loggedInUser = !Object.blank(storedSessionUser) && storedSessionUser.user !== '';
    let token = null;

    if (loggedInUser) {
      token = window.localStorage.getMap(JWT_COLLECTION_KEY)[storedSessionUser.user];
      token && setToken(token);

      if (!token.empty()) {
        const details = {
          claims: decodeJwtToken(token),
          superRole: storedSessionUser.superRole,
        };
        this.props.setupUserDetails(details);
      }
    }
    this.props.onLoad(loggedInUser && token);

    loggedInUser &&
      this.props.apiRequestManager.queueRequest(actionTypes.REQEUST_AUTH_USER);
    // App.authGuard(this.props);
  }

  static getGlobalData(props) {
    const payload = {
      authUser: props.authorizeUser.id,
      workspaceId: props.workspace.id,
    };
    if (!props.apiRequestManager.inProgress(actionTypes.FETCH_GLOBAL_DATA)) {
      props.apiRequestManager.queueRequest(
        actionTypes.FETCH_GLOBAL_DATA,
        payload
      );
    }
  }

  render() {
    return (
      <DefaultLayout {...this.props}>
        <RoutedContent />
      </DefaultLayout>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
