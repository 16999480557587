
import { ofType } from 'redux-observable';
import { catchError, map, switchMap, mergeMap, takeUntil } from 'rxjs/operators';
import { authResultActionCreator } from '../actions';
import *  as actionTypes from '../actionType';
import { authDialect, usersDialect, checkReponse } from '../../service/agent';
import { of } from 'rxjs';
import { apiResponseActionCreator } from '../actions';

export const authenticateEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.LOGIN),
        mergeMap((action) => { 
            return authDialect.login(action.payload.username, action.payload.password).pipe(
               map(response => {
                    const responseData = checkReponse(response, action.type);
                    return authResultActionCreator(responseData);
                }),
                catchError(error => {
                    console.log("ERROR", error);
                    return of(authResultActionCreator(checkReponse(error, action.type)));
               })
            );
        })
    )
}

export const authUserRequestEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.REQEUST_AUTH_USER),
        switchMap((action) => {
            return authDialect.currentUser().pipe(
               map(response => {
                    const responseData = checkReponse(response, action.type);
                    return { type: actionTypes.REQUEST_AUTH_USER_RESULT, payload: responseData};
                }),
                takeUntil(action$.pipe(ofType(actionTypes.REQEUST_AUTH_USER + actionTypes.ABORT_API_REQUEST))),
                catchError(error => {
                    console.log("ERROR", error);
                    return of({ type: actionTypes.REQUEST_AUTH_USER_RESULT, payload: error});
               })
            );
        })
    )
}

export const changeUserPasswordEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.PASSWORD_CHANGE_SUBMIT_DATA),
        switchMap((action) => {
            return usersDialect.changeUserPassword(action.payload).pipe(
               map(response => {
                    const responseData = checkReponse(response, action.type);
                    return { type: actionTypes.PASSWORD_CHANGE_SUBMIT_DATA_RESULT, payload: responseData};
                }),
                takeUntil(action$.pipe(ofType(actionTypes.PASSWORD_CHANGE_SUBMIT_DATA + actionTypes.ABORT_API_REQUEST))),
                catchError(error => {
                    console.log("ERROR", error);
                    return of({ type: actionTypes.PASSWORD_CHANGE_SUBMIT_DATA_RESULT, payload: error});
               })
            );
        })
    )
}


export const findAuthUserRequestEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.FETCH_AUTH_USER),
        switchMap((action) => {
            return authDialect.fetchAuthUser(action.payload).pipe(
               map(response => {
                    const responseData = checkReponse(response, action.type);
                    return { type: actionTypes.FETCHED_AUTH_USER_RESULT, payload: responseData};
                }),
                takeUntil(action$.pipe(ofType(actionTypes.FETCH_AUTH_USER + actionTypes.ABORT_API_REQUEST))),
                catchError(error => {
                    console.log("FETCH_AUTH_USER ERROR", error);
                    return of({ type: actionTypes.NETWORK_ERROR, payload: checkReponse(error, action.type)});
               })
            );
        })
    )
}

export const userAuthAccessRequestEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.AUTH_ACCESS_SUBMIT_DATA),
        switchMap((action) => {
            return usersDialect.changeUserAuthAccess(action.payload).pipe(
               map(response => {
                    const responseData = checkReponse(response, action.type);
                    return { type: actionTypes.AUTH_ACCESS_SUBMITED_RESULT, payload: responseData};
                }),
                takeUntil(action$.pipe(ofType(actionTypes.AUTH_ACCESS_SUBMIT_DATA + actionTypes.ABORT_API_REQUEST))),
                catchError(error => {
                    console.log("FETCH_AUTH_USER ERROR", error);
                    return of({ type: actionTypes.NETWORK_ERROR, payload: checkReponse(error, action.type)});
               })
            );
        })
    )
}


export const getAllUsersEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.FETCH_USERS),
        
        mergeMap((action) => {
            return usersDialect.fetchAllUsers(action.payload).pipe(
               map(response => {
                    const responseData = checkReponse(response, action.type);
                    return apiResponseActionCreator(actionTypes.FETCH_USERS_RESULTS, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.FETCH_USERS + actionTypes.ABORT_API_REQUEST))),
               catchError(error => {
                    console.log("ERROR", error);
                    return of(apiResponseActionCreator(actionTypes.FETCH_USERS_RESULTS, error));
               })
            );
        })
    )};

export const getUserEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.FETCH_USER),
        
        switchMap((action) => {
            return usersDialect.fetchUser(action.payload).pipe(
                map(response => {
                    const responseData = checkReponse(response, action.type);
                    return apiResponseActionCreator(actionTypes.FETCH_USER_RESULT, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
                catchError(error => {
                    return of(apiResponseActionCreator(actionTypes.FETCH_USER_RESULT, error));
                })
            );
        })
    )};

export const submitUserPermissionsEpic = action$ => {
    return action$.pipe(
        ofType(actionTypes.USER_SUBMIT_DATA),
        
        mergeMap((action) => {
          const apiCalls = {
            'delete': usersDialect.deleteUser,
            'create': usersDialect.submitUser,
            'update': usersDialect.updateUser
          }
            return apiCalls[action.payload.requestType](action.payload).pipe(
               map(response => {
                    const responseData = checkReponse(response, action.type, action.payload.requestType);
                    return apiResponseActionCreator(actionTypes.USER_SUBMITED_RESULTS, responseData);
                }),
                takeUntil(action$.pipe(ofType(actionTypes.ABORT_API_REQUEST))),
               catchError(error => {
                    console.log("ERROR", error);
                    return of(apiResponseActionCreator(actionTypes.USER_SUBMITED_RESULTS, error));
               })
            );
        })
  )};