import React from 'react'

export default function Modal({ children }) {
  return (
    <div className='cbt-modal-wrap'>
      <div className='cbt-modal-content'>
        {children}
      </div>
    </div>
  )
}
