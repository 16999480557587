import * as urls from '../../../config/urls';
import { FEATURE_FLAG } from "../../../common/constant";
import  FEATURES  from "../../../config/featureFlags";

const createMenuItem = (name, uri, icon, allowed, entity ) => ({ 
    iconName: icon, 
    navItemName: name,  
    allowed: allowed, 
    entity: entity, 
    toUrl: uri, 
});

export const subMenuNavItems = [
    createMenuItem('Accounts', urls.accountsUri, 'account', 'view', 'account'),
    createMenuItem('Employees', urls.employeesUri, 'employee', 'view', 'employee'),
    createMenuItem('Billing', urls.billingUri, 'billing', 'view', 'bill'),
    createMenuItem('Payroll', urls.payrollUri, 'payroll', 'view', 'payroll'),
    createMenuItem('Payments', urls.paymentsUri, 'paymentIcon', 'view', 'payment'),
    createMenuItem('Reports', urls.reportsUri, 'report', 'view', 'report'),
];

const createSettingsNavItem = (navItemName, iconUrl, allowed, entity, actionKey) => ({
  iconName: iconUrl,
  navItemName,
  allowed,
  entity,
  action: actionKey,
});

export const clientSettingMenuItems = [
  createSettingsNavItem('Roles & Permission', 'rolePerm', 'view', 'role', 'rolesPermission'),
  createSettingsNavItem('Accout Settings', 'settings', 'view', 'accountprefs', 'accountprefs'),
];

export const standardSettingMenuItems = [
  {
    ...createSettingsNavItem('Labcode', 'labcode', 'view', 'labcode', 'labcode'),
    visible: true,
    useAuthority: true,
  },
  {
    ...createSettingsNavItem('Services', 'service', 'view', 'service', 'service'),
    visible: true,
    useAuthority: true,
  },
  {
    ...createSettingsNavItem('Report', 'report', 'view', 'report', 'report'),
    visible: FEATURES.ReportSetting === FEATURE_FLAG.ON,
    useAuthority: false,
  },
  {
    ...createSettingsNavItem('Roles & Permission', 'rolePerm', 'view', 'role', 'role'),
    visible: true,
    useAuthority: true,
  },
  {
    ...createSettingsNavItem('Form', 'form', 'view', 'clinicalitemgroup', 'form'),
    visible: true,
    useAuthority: true,
  },
  {
    ...createSettingsNavItem('System', 'settings', '', '', 'setting'),
    visible: true,
    useAuthority: false,
  },
]

/**
 * Items in menuItem must have entity and allowed properties.
 */
export function hasVisibleItem(permissionEnforcer, menuItems){
  if(!(menuItems instanceof Array)) return false;

  for (let index = 0; index < menuItems.length; index++) {
    const item = menuItems[index];
    if(permissionEnforcer.isPermitted(item.entity, item.allowed)){
        return true;
    }
  };

  return false;
}