import React from 'react';
import { connect } from 'react-redux';
import { setupPage } from '../../components/Layout/setupPage';
import { 
    Container,
    Row,
    Col,
    Label,
    Section,
    Authority
} from '../../components';

import  * as actions  from '../../store/actions';
import * as urls from '../../config/urls';
import  { PermissionEnforcer }  from '../../utility/PermissionEnforcer';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
import './Billing.style.scss';

const mapStateToProps = state => ({
    apiRequestManager: ApiRequestManager.getInstance(ApiRequestManager),
    useClientLayout: state.global.useClientDashboard,
    mode:  state.global.actionBarMode,
    bill: state.billing.bill
});

const mapDispatchToProps = dispatch => ({
    onLoad: (clentType) => dispatch(actions.clientsViewLoadedAction(clentType)),
    unLoad: () => dispatch(actions.clientsViewUnloadAction()),
});

class Bill extends React.Component {

    static pageOption = { 
        pageTitle: 'Patients',
        disableBodyScroll: true,
        grayBackground: true
    };

    constructor(props) {
        super(props);
        this.state = { };
        !props.bill && props.history.push(urls.billingUri);
    }

    componentDidMount() { }

    componentWillUnmount() { }

    static getDerivedStateFromProps(props, state) {
        return null;
    }

    render () {
        if (!this.props.bill) {
            return null;
        }
        const statusClassname = this.props.bill.status.toLowerCase();
        const customerName = this.props.bill.account.clientInfo.officeName ? this.props.bill.account.clientInfo.officeName : `${this.props.bill.account.clientInfo.firstName} ${this.props.bill.account.clientInfo.lastName}`;
        
        return (
            <React.Fragment>
            <div className='content-layout-wrap client-list'>
                <div className="view-layout-content">
                   <div className="default-Ly-ct">
                       <Container>
                            <Row>
                                <Col>
                                    <div className="bill-content">
                                        <span className={`status-badge --4${statusClassname}`}>
                                            status: {this.props.bill.status}
                                        </span>
                                        <div className="bill-view-wrap">
                                            <div className="print-area">
                                                <Section className="client-details-section">
                                                    <Row>
                                                        <Col md={8}>
                                                            <div className="bill-info">
                                                                <h3>Bill: {this.props.bill.referenceNo}</h3>
                                                                <div className="info-group">
                                                                    <label>Issue Date</label>
                                                                    <p>{this.props.bill.billDate}</p>
                                                                </div>
                                                                <div className="info-group">
                                                                    <label>Billing Cycle</label>
                                                                    <p>{this.props.bill.billCycle}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div className='compy-details'>
                                                                <h1>CytoLabs</h1>
                                                                <p className="text-line">12 Kingstion 5</p>
                                                                <p className="text-line">Kingstion</p>
                                                                <p className="text-line">St. Andrew</p>
                                                                <p className="text-line">Jamaica W.I.</p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Section>
                                                <Section className="client-details-section">
                                                    <Row>
                                                        <Col md={5}>
                                                            <div className="info-group">
                                                                <label>Labcode</label>
                                                                <p>{this.props.bill.account.clientInfo.labCode.code}</p>
                                                            </div>
                                                        </Col>
                                                       </Row>
                                                </Section>
                                                <Section className="bill-details-section">
                                                    <Row>
                                                        <Col md={5}>
                                                            <div className="bill-segment --client">
                                                                <Label className="title">Client</Label>
                                                                <h3>{customerName}</h3>
                                                                <p className="text-line">{this.props.bill.account.accountNo}</p>
                                                                <p className="text-line">{this.props.bill.account.clientInfo.email}</p>
                                                                <p className="text-line">{this.props.bill.account.clientInfo.phoneNumber}</p>

                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className="bill-segment --additional">
                                                                <Label className="title">Additional</Label>
                                                                <div className="info-group">
                                                                    <label>Carried Balance</label>
                                                                    <p>${this.props.bill.carriedBalance.toCurrency()}</p>
                                                                </div>
                                                                <div className="info-group">
                                                                    <label>Express Fee</label>
                                                                    <p>${this.props.bill.expressCost.toCurrency()}</p>
                                                                </div>
                                                                <div className="info-group">
                                                                    <label>Taxes</label>
                                                                    <p>${this.props.bill.taxAmount.toCurrency()}  ({this.props.bill.taxCode} {this.props.bill.taxPercent}%)</p>
                                                                </div>

                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div className="bill-segment --due">
                                                                <Label className="title">Payment Due</Label>
                                                                <div className="info-group --balance-due">
                                                                    <label>Base Charge</label>
                                                                    <p className="--base-charge">${(this.props.bill.amountDue - this.props.bill.carriedBalance).toCurrency()}</p>
                                                                    <label>Amount Due</label>
                                                                    <p>${this.props.bill.amountDue.toCurrency()}</p>
                                                                    <span className="due-date">
                                                                        Due Date: {this.props.bill.dueDate}
                                                                    </span>
                                                                </div>

                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Section>
                                            </div>
                                            <div className="">
                                                    <Section className="bill-items-section">
                                                        <ul className="list-items">
                                                            <li key={0} className="head">
                                                                <div className="col --count">
                                                                    #
                                                                </div>
                                                                <div className="col --items">
                                                                    Record
                                                                </div>
                                                                <div className="col">
                                                                    Unit Price
                                                                </div>
                                                                <div className="col">
                                                                    Service
                                                                </div>
                                                                <div className="col">
                                                                    Express Fee
                                                                </div>
                                                                <div className="col">
                                                                    Added Fees
                                                                </div>
                                                                <div className="col">
                                                                    Net Amount
                                                                </div>
                                                            </li>
                                                            
                                                                { this.props.bill.billLines.map((billLine, index) => (
                                                                
                                                                <li key={(index+1)}className="body --line">
                                                                <div className="col --count">
                                                                    {(index + 1)}
                                                                </div>
                                                                <div className="col --items">
                                                                    { billLine.item.formType } ({ billLine.item.identifier })
                                                                </div>
                                                                <div className="col">
                                                                { billLine.unitPrice.toCurrency() }
                                                                </div>
                                                                <div className="col">
                                                                { billLine.service.name }
                                                                </div>
                                                                <div className="col">
                                                                ${billLine.expressCost ? billLine.expressCost.toCurrency() : 0.0}
                                                                </div>
                                                                <div className="col">
                                                                ${billLine.fee ? billLine.fee.toCurrency() : 0.0 }
                                                                </div>
                                                                <div className="col">
                                                                    ${ billLine.amount.toCurrency() }
                                                                </div> 
                                                                </li>
                                                               
                                                                ))}
                                                           
                                                        </ul>
                                                    </Section>
                                            </div>
                                            <div className="bill-message">
                                                <Section className="message-section">
                                                    <label>Message</label>
                                                    <p className="">
                                                        {this.props.bill.message}
                                                    </p>
                                                </Section>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                   </div>
                </div>
            </div>
            </React.Fragment>
        );
    }
}

const BillView = (props) => {
    return (
        <Bill {...props } />
    );
};

let ConfigBillView = setupPage(Bill.pageOption)(BillView);
ConfigBillView =  connect(mapStateToProps, mapDispatchToProps)(ConfigBillView);

export { ConfigBillView };