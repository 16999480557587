import * as actionTypes from '../actionType';
import {action} from './actionCreator';
import {createRequestTypes, REQUEST, SUCCESS, FAILURE} from './../../service/httpRequestDialect';

export const getAllSpecimenRecordsAction = (payload) => ({
    type: actionTypes.FETCH_SPECIMEN_RECORDS,
    payload: payload
}); 

export const getAllSpecimenRecordsApproved = (payload) => ({
    type: actionTypes.FETCH_SPECIMEN_RECORDS_APPROVED,
    payload: payload
}); 

export const getRecentSpecimenRecordsAction = (payload) => ({
    type: actionTypes.FETCH_RECENT_SPECIMEN_RECORDS,
    payload: payload
});

export const specimenRecordLoadedAction = () => ({
    type: actionTypes.SPECIMEN_VIEW_LOADED
});
export const setSpecimenPageActiveView = (view) => ({
    type: actionTypes.SPECIMEN_PAGE_ACTIVE_VIEW,
    payload: view
});
export const specimenUpdateRecordLoadedAction = () => ({
    type: actionTypes.SPECIMEN_UPDATE_VIEW_LOADED
});
export const resultSheetLoadedAction = () => ({
    type: actionTypes.RESULT_SHEET_VIEW_LOADED
});
export const resultSheetUnLoadedAction = () => ({
    type: actionTypes.RESULT_SHEET_VIEW_UNLOAD
});

export const specimenViewUnLoadAction = () => ({
    type: actionTypes.SPECIMEN_VIEW_UNLOAD
});
export const specimenUpdateViewUnLoadAction = () => ({
    type: actionTypes.SPECIMEN_UPDATE_VIEW_UNLOAD
});
export const specimenFormViewAction = (view) => ({
    type: actionTypes.SPECIMEN_FORM_VIEW,
    payload: view
});    
export const specimenResultSheetLoadedAction = () => ({
    type: actionTypes.SPECIMEN_RESULTSHEET_VIEW_LOADED
});
export const specimenResultSheetUnLoadAction = () => ({
    type: actionTypes.SPECIMEN_RESULTSHEET_VIEW_UNLOADED
});
export const getPatientsFormDetailsAction =  (payload) => ({
    type: actionTypes.FETCH_FORM_DETAILS,
    payload: payload
}); 

export const specimenFormFieldChangeAction =  (payload) => ({
    type: actionTypes.SPECIMEN_FORM_FIELD_CHANGED,
    payload: payload
}); 
export const requisitionFormFieldChangeAction =  (payload) => ({
    type: actionTypes.REQUISITION_FORM_FIELD_CHANGED,
    payload: payload
});
export const submitSpecimenAction = (data) => ({
    type: actionTypes.SPECIMEN_SUBMIT_DATA,
    payload: data
}); 
export const getAllRequisitions = (payload) => ({
    type: actionTypes.FETCH_REQUISITIONS,
    payload
}); 
export const submitRequisitionAction = (data) => ({
    type: actionTypes.REQUISITION_SUBMIT_DATA,
    payload: data
}); 
export const specimenResultSheetAction = (specimen) => ({
    type: actionTypes.SPECIMEN_RESULT_SHEET,
    payload: specimen
}); 

export const setUpdateSpecimenDataAction =  (dataFields) => ({
    type: actionTypes.SPECIMEN_EDIT_SETDATA,
    payload: {
       ...dataFields
    }
});
export const setClientDataAction =  (dataFields) => ({
    type: actionTypes.SPECIMEN_CLIENT_SETDATA,
    payload: {
       ...dataFields
    }
});
export const setRequisitionDataAction =  (dataFields) => ({
    type: actionTypes.REQUISITION_SETDATA,
    payload: dataFields
    
});
export const setRequisitionItemAction = (requisitionItem) => ({
    type: actionTypes.REQUISITION_SET_LINK_ITEM,
    payload: { requisitionItem }
})

export const setRequisitionPageNumberAction = (pageNumber) => ({
    type: actionTypes.REQUISITION_SET_PAGE_NUMBER,
    payload: { pageNumber }
})

export const specimenAddStatus =  (status) => ({
    type: actionTypes.SPECIMEN_ADD_STATUS,
    payload: status
});
export const getClientSpecimenRecords =  (payload) => ({
    type: actionTypes.FETCH_CLIENT_SPECIMEN_RECORDS,
    payload: payload
});
export const specimenAttachFileAction =  (payload) => ({
    type: actionTypes.SPECIMEN_ATTACH_FILE,
    payload: payload
});
export const getClientSpecimenRecordsAction = (payload) => ({
    type: actionTypes.FETCH_CLIENT_SPECIMENS_HISTORY,
    payload
});
export const getPatientSpecimenRecordsAction = (payload) => ({
    type: actionTypes.FETCH_PATIENT_SPECIMENS_HISTORY,
    payload: payload
});

export const getSpecimenRecordAction = (recordId) => ({
    type: actionTypes.FETCH_SPECIMEN_RECORD,
    payload: recordId
});

export const getRequisitionAction = (requisitionId) => ({
    type: actionTypes.FETCH_REQUISITION,
    payload: requisitionId
});

export const requisitionCreateViewUnLoadedAction = () => ({
    type: actionTypes.REQUISITION_CREATE_VIEW_UNLOAD
});

export const requisitionViewUnLoadAction = () => ({
    type: actionTypes.REQUISITION_VIEW_UNLOAD
});
export const getRequisitionSpecimenRecords = (requisitionId) => ({
    type: actionTypes.FETCH_REQUISITION_SPECIMENS,
    payload: requisitionId
});

export const showRequisitionModalAction = (showOrHide) => ({
    type: actionTypes.SHOW_REQUISITION_MODAL,
    payload: showOrHide 
});
export const submitRequisitionItemAction = (data) => ({
    type: actionTypes.REQUISITION_ITEM_SUBMIT_DATA,
    payload: data
});

export const setSpecimenPatientLink = (value) =>({
    type: actionTypes.SPECIMEN_SET_PATIENT_LINK_ITEM,
    payload: value,
})

export const FETCH_FORM_DETAILS = createRequestTypes('FETCH_FORM_DETAILS');

export const formDetails = {
    request: () => action(FETCH_FORM_DETAILS[REQUEST]),
    success: (payload) => action(FETCH_FORM_DETAILS[SUCCESS], {...payload}),
    failure: (error) => action(FETCH_FORM_DETAILS[FAILURE], {...error}),
};

export const clearSpecimentDataAction = () => ({
    type: actionTypes.CLEAR_SPECIMENS_DATA
});

export const cabinetModalLoadedAction = (payload) =>({
    type: actionTypes.CABINET_MODAL_VIEW_LOADED,
    payload,
});
export const cabinetModalUnLoadAction = (payload) =>({
    type: actionTypes.CABINET_MODAL_VIEW_UNLOAD,
    payload,
});

export const setCabinetsContextItemAction = (payload) =>({
    type: actionTypes.SET_CABINET_CONTEXT_ITEM,
    payload
});
export const getSpecimensForCabinetAction = (payload) =>({
    type: actionTypes.FETCH_SPECIMENS_FOR_CABINET,
    payload: payload,
});

export const getCabinetsAction = () =>({
    type: actionTypes.FETCH_CABINETS,
});

export const submitCabinetAction = (payload) =>({
    type: actionTypes.CABINET_SUBMIT_DATA,
    payload,
});

export const editSampleStateAction =  (dataFields) => ({
    type: actionTypes.SPECIMEN_EDIT_SAMPLE_STATE,
    payload: {
       ...dataFields
    }
});

export const getClientRequisitionsAction = (clientId) => ({
    type: actionTypes.FETCH_CLIENT_REQUISITIONS,
    payload: clientId
});