import { assert } from './assert';
import { 
    SimpleSelect,
    SimpleSelectMultiple,
    Input,
    InputField
 } from '../components';

const HTMLInput = HTMLInputElement || Input || InputField || HTMLTextAreaElement || SimpleSelect || SimpleSelectMultiple;

const isHTMLInput = (input = HTMLInput) => {
    return (input).props === undefined;
}

class InputElement  {
    name = '';
    type = '';
    value = null;
    validity = null;
    feedbackMessages = {};

    constructor(input = HTMLInput) {
        
        if ( isHTMLInput(input) ) {
            this.name = input.name;
            this.type = input.type;
            this.value = input.value;
        }else if (input.type === SimpleSelect) {
            this.name = input.props.name;
            this.type = input.type.name;
            this.value = input.ref.current.state.selectedItem;
        }else if (input.type === SimpleSelectMultiple) {
            this.name = input.props.name;
            this.type = input.type.name;
            this.value = input.ref.current.state.selectedItems;
        } else {
            this.name = input.props.name;
            this.type = undefined;
            this.value = input.props.value;
        }
    }
}

export class Field {

    validations = [];
    constraits = {};
    component = null;
    _name = '';
    element = null; //HTMLInput | TextInput;

    constructor(name, errorValidations, constraits, component = null) {
        this._name = name;
        this.validations = errorValidations;
        this.constraits = constraits;
        this.component = component;
    }

    get name() {
        return this._name;
    }
}

class FieldsStore {
    
    fields = [];

    getField(fieldName) {
        const fields = this.fields.filter(_field => _field.name === fieldName);
        //assert(fields.length === 1, `Unknown field '${fieldName}'`);
        return fields.length === 1 ? fields[0] : undefined;
    }

    addField(fieldName, errorFeedbacks, constraits, component) {
        const fields = this.fields.filter(_field => _field.name === fieldName);
        assert(
          fields.length === 0 || fields.length === 1,
          `Cannot have more than 1 field matching '${fieldName}'`
        );
    
        if (fields.length === 0) {
          const newField = new Field(fieldName, errorFeedbacks, constraits, component);
          this.fields.push(newField);
          //this.emitSync(FieldEvent.Added, newField);
        } else {
          // We can have multiple FieldFeedbacks for the same field,
          // thus addField() can be called multiple times
        }
    }

    removeField(fieldName) {
        
        const fields = this.fields.filter(_field => _field.name === fieldName);
        // We can have multiple FieldFeedbacks for the same field,
        // thus removeField() can be called multiple times
        //assert(fields.length === 1, `Unknown field '${fieldName}'`);
    
        const index = this.fields.indexOf(fields[0]);
        if (index > -1) {
          this.fields.splice(index, 1);
          //this.emitSync(FieldEvent.Removed, fieldName);
        }
    }

    removeAll() {
        this.fields.map(_field => this.removeField(_field.name));
    }
}

class FormValidator {
    
    patternString = [
   'valid',
   '*',
   'phoneNumber',// phone number format
   'numberInput', // input type="number"
   'letterInput', // input type="number"
   'patternMismatch', // pattern attribute
   'rangeOverflow', // max attribute
   'rangeUnderflow', // min attribute
   'stepMismatch', // step attribute
   'tooLong', // maxlength attribute
   'tooShort', // minlength attribute
   'emailMismatch', // input type="email" or input type="url"
   'valueMissing', // required attribute
   'trn',
   'nis',
   'matchEqual']; 

    static _instance = undefined;
  
    _regExPatterns = {
        valueMissing: /^(?![\s\S])/,
        letterInput: /^[a-zA-Z\s?\-?]+$/,
        numberInput: /^[0-9]+$/,
        phoneNumber: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
        trn: /^[0-9]{3}[-]?[0-9]{3}[-]?[0-9]{3}$/,
        nis: /^[\w\s-]{6,}$/,
        emailMismatch: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    }

    _validatorDelegates = {}

    context = null;

    _fieldStore = new FieldsStore();
   
    constructor() { }

    addValidateField(name, errorValidations, constraits, component) {
        this._fieldStore.addField(name, errorValidations, constraits, component);
    }

    getValidateField(name) {
        return this._fieldStore.getField(name);
    }

    removeValidateField(name) {
        return this._fieldStore.removeField(name);
    }

    clearFields() {
        this._fieldStore.removeAll();
    }

    validate(field) {
        
        field.validations.map(((item) => {
            if (this.patternString.find((_p) => _p === item.props.filter)) {
                let regExp = null;
                let filterString = '';
                
                if (field.constraits.pattern && typeof field.constraits.pattern === 'string' && item.props.filter === 'patternMismatch') {
                    regExp = new RegExp(field.constraits.pattern);
                    filterString = 'patternMismatch';
                }else if (field.constraits.required && typeof field.constraits.required === 'bool') {
                    filterString = 'valueMissing';
                    regExp = this._regExPatterns[filterString];
                }else {
                    filterString = item.props.filter;
                    regExp = this._regExPatterns[filterString];
                }
                
                const errorKey = `${filterString}_${item.props.error ? 'error' : 'warning' }`;
               
                switch(filterString) {
                    case 'valueMissing':

                        field.element.validity = !this.isValueMissing(field.element.value);
                        field.element.feedbackMessages[errorKey] = this.isValueMissing(field.element.value);

                        break;
                    case 'letterInput':
                        field.element.validity = this.isValueMissing(field.element.value) || !this.isTextValue(field.element.value);
                        field.element.feedbackMessages[errorKey] = !this.isValueMissing(field.element.value) && !this.isTextValue(field.element.value);
                        break;

                    case 'matchEqual':
                        const matchingField = this._fieldStore.getField(field.constraits.matchField);

                        if (matchingField.element) {
                            const isMatch = this.isEqual(matchingField.element.value, field.element.value);
                            field.element.validity = isMatch
                            field.element.feedbackMessages[errorKey] = !isMatch;
                        }
                        break;
                    default:
                        const isValid = regExp.test(field.element.value);
                        field.element.validity = isValid;
                        field.element.feedbackMessages[errorKey] = !isValid && !this.isValueMissing(field.element.value);
                }            
            }
        })); 
    }

    addDelegate(key, delegateAction) {
        this._validatorDelegates[key] = delegateAction;
    }

    removeDelegate(key){
        delete this._validatorDelegates[key];
    }

    clearAllDelegates(){
        this._validatorDelegates = {};
    }

    delegateCheckFieldError(key, ...args) {
        return this._validatorDelegates[key] ? this._validatorDelegates[key].apply({}, [this , ...args]) :
        null;
    }

    hasActiveFormError(errorMap){
        const keys = Object.keys(errorMap);
        for (let key of keys) {
            if(errorMap[key]) return true;
        }
        return false;
    }

    isValueMissing(value) {
        return value == null ? true : this._regExPatterns['valueMissing'].test(value);
    }

    isNotNull(value) {
        return value == null ? true : false;
    }

    isEmptyValue(array) {
        return array.length === 0 ? true : false
    }

    isTextValue(value) {
        return this._regExPatterns['letterInput'].test(value);
    }

    isNumberValue(value) {
        return this._regExPatterns['numberInput'].test(value);
    }

    isEmailValue(value) {
        return this._regExPatterns['emailMismatch'].test(value);
    }

    isPhonenumber(value){
        return this._regExPatterns['phoneNumber'].test(value);
    }

    isTrnNumber(value){
        return this._regExPatterns['trn'].test(value);
    }

    isNisNumber(value){
        return this._regExPatterns['nis'].test(value)
    }

    isEqual(value1, value2){
        return value1 === value2;
    }

    async validateField(name) {
        const field = this._fieldStore.getField(name);
        const inputs = this.normalizeInputs([field]);
        inputs.length && this.validate(inputs[0]);
    }

    async _validateFields() { }

    normalizeInputs(inputsOrNames) {
        let inputs;
        
        inputs = inputsOrNames.filter((field) => {
            let inputEl = null;
            if (field === undefined)
                return;

            if ( field && field.component === null ) {
                const query = `[name="${field.name}"]`;
                const input = document.querySelector(query);
                inputEl = new InputElement(input);
            }else {
                inputEl = new InputElement(field.component);
            }
            field.element = inputEl;
            return field;

        });
        return inputs;
    }

    static getInstance = (classInstance) => FormValidator._instance ? FormValidator._instance : (FormValidator._instance = new classInstance());
};

export { FormValidator };