import React from 'react';
import PropTypes from 'prop-types';
import { ERROR_FILTERS, INPUT_FIELD_TYPES } from '../../../common/constant';
import DetailSection from '../DetailSection';
import {
  Button,
  ErrorFeedback,
  FormGroup,
  Input,
  Label,
  ValidateField,
} from '../../../components';
import { ErrorMessage } from '../../components/partials/ErrorMessage';
import { getIconUrl } from '../../../common/images-catalogue';

function EditableLineValue({
  fieldValue,
  fieldType,
  //the value that is from the server, not the value in field
  storedValue,
  lineName,
  validationErrors = [],
  isBeingEdited,
  onChange,
}) {
  
  if (!isBeingEdited) {
    const yesOrNoValue = typeof storedValue === 'boolean' && storedValue ? 'Yes' : 'No';
    return <p className='line-value'>{typeof storedValue !== 'boolean' ? storedValue || '--' : yesOrNoValue}</p>;
  }
  
  switch (fieldType) {
    case INPUT_FIELD_TYPES.text:
    case INPUT_FIELD_TYPES.email:
    default:
      return (
        <ValidateField>
          <Input
            className='detail-line__editable-field'
            onChange={(event) => onChange(lineName, event)}
            type={fieldType}
            value={fieldValue}
            name={lineName}
          />
          {validationErrors.map((error, index) => (
            <ErrorFeedback
              key={index}
              error
              show={error.errorMessage.trim() !== ''}
              filter={error.errorFilter}
              message={<ErrorMessage message={error.errorMessage} />}
            />
          ))}
        </ValidateField>
      );
  }
}

function DetailLine({
  lineLabel,
  lineData,
  isEditable,
  currentlyEditing,
  onChange,
}) {
  
  /* If we are editing a field and this btn shouldn't be disabled, 
    then we are editing this field.
  */
  const isBeingEdited = currentlyEditing && isEditable;

  return (
    <div className='detail-line__container'>
      { !isBeingEdited  && 
        <div className='detail-line__title-container'>
          <span className='detail-line__label--grey'>{lineLabel}</span>
        </div>
      }

      { isBeingEdited  && 
        <div className='detail-line__title-container'>
          <Label className='detail-line__label--grey'>{lineLabel}</Label>
        </div>
      }

      {/* Line Values */}
      <div>
        <EditableLineValue
          storedValue={lineData.storedValue}
          fieldValue={lineData.fieldValue}
          fieldType={lineData.fieldType}
          lineName={lineData.lineName}
          isBeingEdited={isBeingEdited}
          onChange={onChange}
          validationErrors={lineData.validationErrors}
        />
      </div>
    </div>
  );
}

class PersonalInfoPane extends React.Component {
  constructor(props) {
    super(props);

    this.generateSectionsData = this.generateSectionsData.bind(this);
    this.renderDetailLine = this.renderDetailLine.bind(this);
  }

  generateSectionsData() {
    const composeDetailSection = this.props.composeDetailSection;
    const composeDetailLine = this.props.composeDetailLine;
    const lineNames = this.props.lineNames;
    const formData = this.props.profileForm;
    const profileData = {
      ...this.props.currentUser,
      userType: this.props.currentUser.type,
      role: this.props.currentUser.role.role
    }
    const validationErrors = this.props.validationErrors;

    const section1Name = 'Role';
    const section2Name = 'Account Info';
    const section3Name = 'Email';
    const section4Name = 'Profile Card';
    const section5Name = 'Address';
    const section6Name = 'Authorizer Info';

   
    function getValue(key, formFallbackKey) {
      return profileData && profileData[key] !== undefined
        ? profileData[key]
        : formData[formFallbackKey || key];
    }

    let sectionStructure = [

      composeDetailSection(section1Name, [
        composeDetailLine('')
          .setValues(getValue('role'), formData.blank)
          .setLineName(lineNames.role)
          .isEditable(false)
          .build(),
      ]),

      composeDetailSection(section2Name, [
        composeDetailLine('User Type')
          .setValues(getValue('userType'), formData.userType)
          .setLineName(lineNames.userType)
          .isEditable(false)
          .build(),

        composeDetailLine('Username')
          .setValues(getValue('username'), formData.username)
          .setLineName(lineNames.username)
          .isEditable(false)
          .addValidationRuleToRules(
            validationErrors.usernameMissing,
            ERROR_FILTERS.valueMissing
          )
          .build(),

        composeDetailLine('Active')
          .setValues(getValue('active'), formData.blank)
          .setLineName(lineNames.active)
          .isEditable(false)
          .addValidationRuleToRules(
            validationErrors.usernameMissing,
            ERROR_FILTERS.valueMissing
          )
          .build(),

        composeDetailLine('Blocked')
          .setValues(getValue('blocked'), formData.blank)
          .setLineName(lineNames.blocked)
          .isEditable(false)
          .addValidationRuleToRules(
            validationErrors.usernameMissing,
            ERROR_FILTERS.valueMissing
          )
          .build(),

        composeDetailLine('Secondary User')
          .setValues(getValue('secondary'), formData.blank)
          .setLineName(lineNames.secondary)
          .isEditable(false)
          .addValidationRuleToRules(
            validationErrors.usernameMissing,
            ERROR_FILTERS.valueMissing
          )
          .build()

      ]),

      composeDetailSection(section3Name, [
      
        composeDetailLine('Email')
          .setValues(getValue('email'), formData.email)
          .setLineName(lineNames.email)
          .setFieldType(INPUT_FIELD_TYPES.email)
          .addValidationRuleToRules(
            validationErrors.emailMissing,
            ERROR_FILTERS.valueMissing
          )
          .addValidationRuleToRules(
            validationErrors.emailInvalid,
            ERROR_FILTERS.emailMismatch
          )
          .build(),
      ]),

      composeDetailSection(section4Name, [
        composeDetailLine('First Name')
          .setValues(getValue('firstName'), formData.firstName)
          .setLineName(lineNames.firstName)
          .addValidationRuleToRules(
            validationErrors.firstNameMissing,
            ERROR_FILTERS.valueMissing
          )
          .addValidationRuleToRules(
            validationErrors.firstNameInvalid,
            ERROR_FILTERS.letterInput
          )
          .build(),

        composeDetailLine('Last Name')
          .setValues(getValue('lastName'), formData.lastName)
          .setLineName(lineNames.lastName)
          .addValidationRuleToRules(
            validationErrors.lastNameMissing,
            ERROR_FILTERS.valueMissing
          )
          .addValidationRuleToRules(
            validationErrors.lastNameInvalid,
            ERROR_FILTERS.letterInput
          )
          .build(),

        composeDetailLine('Phone Number')
          .setValues(getValue('phoneNumber'), formData.phoneNumber)
          .setLineName(lineNames.phoneNumber)
          .addValidationRuleToRules(
            validationErrors.phoneNumberMissing,
            ERROR_FILTERS.valueMissing
          )
          .addValidationRuleToRules(
            validationErrors.phoneNumberInvalid,
            ERROR_FILTERS.phoneNumber
          )
          .build(),
      ]),

      composeDetailSection(section5Name, [
        composeDetailLine('Street')
          .setValues(getValue('street'), formData.street)
          .setLineName(lineNames.street)
          .build(),

        composeDetailLine('Street Two')
          .setValues(getValue('streetTwo'), formData.streetTwo)
          .setLineName(lineNames.streetTwo)
          .build(),

        composeDetailLine('City')
          .setValues(getValue('city'), formData.city)
          .setLineName(lineNames.city)
          .build(),

        composeDetailLine('State')
          .setValues(getValue('state'), formData.state)
          .setLineName(lineNames.state)
          .build(),
      ]),
    ];

    if (this.props.currentUser.authorizerId) {
      sectionStructure[sectionStructure.length + 1] = composeDetailSection(section6Name, [
        composeDetailLine('Profession')
          .setValues(getValue('profession'), formData.blank)
          .setLineName(lineNames.profession)
          .isEditable(false)
          .build(),

        composeDetailLine('Digital Signature')
          .setValues(getValue('digitalSignature'), formData.blank)
          .setLineName(lineNames.digitalSignature)
          .isEditable(false)
          .build(),
      ]);
    }

    return sectionStructure;
  }

  renderDetailLine(lineData) {
    return (
      <DetailLine
        lineLabel={lineData.lineLabel}
        lineData={lineData}
        isEditable={lineData.isEditable}
        currentlyEditing={lineData.currentlyEditing}
        onChange={this.props.onFieldChange}
      />
    );
  }

  render() {
    const detailSections = this.generateSectionsData();
    const profileImgSrc = this.props.profileForm.profileImage ? 
      (this.props.profileForm.profileImage instanceof File )? 
      URL.createObjectURL(this.props.profileForm.profileImage) : this.props.profileForm.profileImage :  getIconUrl('profile');

    return (
      <div className='profile-pane'>
        <h2 className='profile__pane-title'>Personal Info</h2>
        {/* Change profile pic */}
        <FormGroup className='section-inner profile-pic__container'>
          <div className='image-thumbnail-box profile-pic'>
            <div className='image-selector'>
              <img
                ref={this.props.profileImageRef}
                src={profileImgSrc}
                alt=''
              />
              <Button>
                <span>Edit</span>
              </Button>
              <div className='input-field profile-pic'>
                <Input
                  onChange={this.props.previewImage}
                  type='file'
                  name='profile-image'
                  accept='image/*'
                />
              </div>
            </div>
          </div>
          <div className='user--text-gender'>
            <h2 className='image-text'>You</h2>
            <div className='gender'>{this.props.currentUser.gender}</div>
          </div>
        </FormGroup>

        {/* Display the sections */}
        <div className='detail-section__list'>
          { detailSections.map((section, index) => {

            const sectionId = `${section.sectionLabel}_${index}`
            return (
              <DetailSection
                key={index}
                identifier={sectionId}
                sectionTitle={section.sectionLabel}
                detailLines={section.detailLines}
                renderDetailLine={this.renderDetailLine}
                onClickEdit={this.props.onClickEdit}
                onCancelEdit={this.props.onCancelEdit}
                isBeingEdited={sectionId === this.props.currentlyEditing}
                disableEdit={this.props.shouldDisableEdit(sectionId)}
            />)
          }) }
        </div>
      </div>
    );
  }
}

PersonalInfoPane.propType = {
  composeDetailSection: PropTypes.func.isRequired,
  composeDetailLine: PropTypes.func.isRequired,
  lineNames: PropTypes.object.isRequired,
  shouldDisableEdit: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onCancelEdit: PropTypes.func.isRequired,
};

export default PersonalInfoPane;
