import {all, fork} from 'redux-saga/effects';

import permissionWatchers from './permissionRoleSaga';
import commonWatchers from './commonSaga';
import specimenWatchers from './specimenSaga';


export default function * rootSaga() {
    yield all([
        fork(commonWatchers),
        fork(permissionWatchers),
        fork(specimenWatchers)
    ]);
}
  