import React from 'react';
import  * as actionTypes  from '../../../store/actionType';

import { FINDING_NORMALITY } from '../../../common/constant';
import TemplateFooter from './TemplateFooter';

class Report_RP02 extends React.PureComponent  {
    
    constructor(props) {
        super(props);
        this.state = {
            rowGroup : {}
        }

        this.collapse = this.collapse.bind(this);
    }

    componentDidMount() {
        const customizeReport = this.props.customize;
        customizeReport && this.props.apiRequestManager.queueRequest(actionTypes.FETCH_CODE_FINDINGS);
        customizeReport && this.props.apiRequestManager.queueRequest(actionTypes.FETCH_CODE_SHEETS);
    }

    collapse(evt) {
    
        const rowId = evt.currentTarget.getAttribute('rowgroup');
        const rowSet = {
            rowGroup: {
                ...this.state.rowGroup,
                [rowId]: this.state.rowGroup[rowId] ? false : true
            }
        }
        this.setState({
            ...rowSet
        });
    }

    render () {

        const {ReportHeader, ReportFooter, ReportNotes} = this.props;
        const reportData = Array.isArray(this.props.reportResults) && this.props.reportResults.length ? this.props.reportResults[0] : {};

        const dataKeys = Object.keys(reportData);

        return <React.Fragment>
            <div  className="print-template">
                { ReportHeader }
                <div className="report-body">

                    <table className="default">
                        <thead>
                            <tr>
                                <th>Form Record</th>
                                <th>Code Abbrv</th>
                                <th>Normality</th>
                                <th>Code Description</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            { dataKeys.map((key, g) => {

                                var rowGroup = [<tr key={g} onClick={this.collapse} className={`collapsible-row ${this.state.rowGroup[g] ? '--folded' : ''}`} rowgroup={g}>
                                <td>{key}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className="number">({ reportData[key].length})</td>
                                </tr>]

                                reportData[key].map((item, i) => {
                                    rowGroup.push(<tr key={item.id}  className={`smpl-row ${this.state.rowGroup[g] ? '--hide' : ''}`} groupid={g}>
                                    <td>{item.form} <small>({item.specimenRecord})</small></td>
                                    <td>{item.abbreviation}</td>
                                    <td>{item.abnormalFinding ? FINDING_NORMALITY.Abnormal : FINDING_NORMALITY.Normal }</td>
                                    <td>{item.description}</td>
                                    <td>{item.resultSheetDate}</td>
                                    </tr>)
                                });
                            
                                return rowGroup;
                            })}
                        
                        </tbody>
                    </table>
                </div>
                { <TemplateFooter 
                    ReportFooter={ReportFooter}  
                    ReportNotes={ReportNotes}
                    /> 
                }
            </div>
           
        </React.Fragment> 
    }  
}

export default Report_RP02;