import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { setupPage } from '../../components/Layout/setupPage';
import { ContextMenu, MenuItem,ContextMenuTrigger } from "react-contextmenu";
import {
    Button,
    List,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Section,
    Authority,
    PayAdviceTable,
} from '../../components';

import * as actionTypes from '../../store/actionType';
import  * as actions  from '../../store/actions';
import  ListItem  from '../components/partials/ListItem';
import * as urls from '../../config/urls';
import  { PermissionEnforcer }  from '../../utility/PermissionEnforcer';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
import {pictureInitials} from './../../utility/helpers';
import ConfirmModal from '../components/partials/ConfirmModal';
import {SEARCH_SETTINGS } from '../../config/searchable';

import './Employee.style.scss';
import { getIconUrl } from '../../common/images-catalogue';

const mapStateToProps = state => ({
    apiRequestManager: ApiRequestManager.getInstance(ApiRequestManager),
    useClientLayout: state.global.useClientDashboard,
    mode:  state.global.actionBarMode,
    permissionEnforcer: PermissionEnforcer.getInstance(PermissionEnforcer),
    ...state.employee
});

const mapDispatchToProps = dispatch => ({
    onLoad: (clentType) => dispatch(actions.employeesViewLoadedAction(clentType)),
    unLoad: () => dispatch(actions.employeesViewUnloadAction()),
    setMode: (mode) => dispatch(actions.actionBarModeAction(mode)),
    getEmployees: () => dispatch(actions.getAllEmployeesAction()),
    getPayAdvices: (employeeId) => dispatch(actions.getEmployeePayAdviceAction(employeeId)),
    setSelectedEmployee: (employee) => dispatch(actions.setSelectedEmployeeAction(employee)),
    setEmployeeData: (employee) => dispatch(actions.setUpdateEmployeeDataAction(employee)),
    submitData : (data) => dispatch(actions.submitEmployeeAction(data)),
    terminateEmployee: (employeeId) => dispatch(actions.terminateEmployeeAction(employeeId))
});


class Employee extends React.Component {

    static pageOption = { 
        pageTitle: 'Employees',
        disableBodyScroll: true,
        searchContext: SEARCH_SETTINGS.entities.employee.name
    };

    innerRef = {
        confirmDeleteCallback: null
    };

    dialogContextAction = {
        delete: 'Delete',
        terminate: 'Terminate',
    }

    constructor(props) {
        super(props);

        props.apiRequestManager.register(actionTypes.EMPLOYEE_SUBMIT_DATA, props.submitData);
        props.apiRequestManager.register(actionTypes.TERMINATE_EMPLOYEE, props.terminateEmployee);

        this.state = {
            activeTab: '1',
            selected: 0,
            confirmDialogVisible: false,
            dialogContextName: 'item',
            dialogContextItem: {},
            dialogContextAction: '',
        };

        this.contextMenuRef = React.createRef();

        props.apiRequestManager.register(actionTypes.FETCH_EMPLOYEES, props.getEmployees);
        props.apiRequestManager.register(actionTypes.FETCH_EMPLOYEE_PAYADVICE, props.getPayAdvices);
        this.didSelectEmployee = this.didSelectEmployee.bind(this);
        this.onAddEmployee = this.onAddEmployee.bind(this);
        this.showPayAdvice = this.showPayAdvice.bind(this);
        this.contextMenuWillAppear = this.contextMenuWillAppear.bind(this);
        this.contextMenuDismissed = this.contextMenuDismissed.bind(this);
        this.contextMenuOptClick = this.contextMenuOptClick.bind(this);
        this.showConfirmDialog = this.showConfirmDialog.bind(this);
        this.onConfirmAction = this.onConfirmAction.bind(this);
        this.onCancelAction = this.onCancelAction.bind(this);
        this.submitDelete = this.submitDelete.bind(this);
        this.terminateEmployee = this.terminateEmployee.bind(this);
        this.getConfirmMessage = this.getConfirmMessage.bind(this);

        window.addEventListener('REACT_CONTEXTMENU_SHOW', this.contextMenuWillAppear);
        window.addEventListener('REACT_CONTEXTMENU_HIDE', this.contextMenuDismissed);
    }

    componentDidMount() { }

    componentWillUnmount() {
        this.props.unLoad();

        window.removeEventListener('REACT_CONTEXTMENU_SHOW', this.contextMenuWillAppear);
        window.removeEventListener('REACT_CONTEXTMENU_HIDE', this.contextMenuDismissed);
    }

    static getDerivedStateFromProps(props, state) {
        if ( !props.employees && !props.apiRequestManager.inProgress(actionTypes.FETCH_EMPLOYEES) ) {
            props.apiRequestManager.queueRequest(actionTypes.FETCH_EMPLOYEES);
        }
        
        if (  !props.currentEmployee && props.employees &&  !props.employees.empty() ) {

            props.setSelectedEmployee(props.employees[0]);
            props.apiRequestManager.queueRequest(actionTypes.FETCH_EMPLOYEE_PAYADVICE, props.employees[0].id);
            return {
                selected: props.employees[0].id
            }
        } 
        
        if (props.currentEmployee && state.selected !== props.currentEmployee.id && props.employees ) {
            props.apiRequestManager.queueRequest(actionTypes.FETCH_EMPLOYEE_PAYADVICE, props.currentEmployee.id)
            return {
                selected: props.currentEmployee.id
            }
        }
        return null;
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({ activeTab: tab });
        }
    }

    didSelectEmployee(data, index) {
        this.props.setSelectedEmployee(data);
        this.setState({selected: data.id});
        if ( !this.props.payAdvices.hasOwnProperty(data.id) ) {
            this.props.apiRequestManager.queueRequest(actionTypes.FETCH_EMPLOYEE_PAYADVICE, data.id);
        }
    }

    onAddEmployee(evt) {
        evt.preventDefault();
        this.props.history.push(urls.employeeCreateUri);
    }

    showPayAdvice(payAdvice) {
        window.open(`/payroll/payslip/${payAdvice.payrollNo}?payadvice_f=`+ payAdvice.id, "_blank");
    }

    contextMenuWillAppear(evt) { }
    contextMenuDismissed(evt) { }
    
    contextMenuOptClick(evt, data, element) {
        
        const rowIndex = element.getAttribute('data-rowid');
        const employee = this.props.employees[rowIndex];
        
        switch(data.opt) {
            case 'edit':
                if (!this.props.permissionEnforcer.hasRights('change', 'employee'))
                    return
                this.props.setEmployeeData({employee: employee});
                this.props.setMode('edit');
                this.props.history.push(urls.employeeUpdateUri);
                break;
            case 'delete':
                if (!this.props.permissionEnforcer.hasRights('delete', 'employee'))
                    return
                this.showConfirmDialog('employee', employee, this.dialogContextAction.delete);
                break;
            case 'terminate':
                if (!this.props.permissionEnforcer.hasRights('change', 'employee'))
                    return
                this.showConfirmDialog('employee', employee, this.dialogContextAction.terminate);
                break;
            default:
        }
       
    }
    showConfirmDialog(contextName, contextItem, contextAction) {
        this.setState({
            confirmDialogVisible: true, 
            dialogContextName: contextName, 
            dialogContextItem: contextItem,
            dialogContextAction: contextAction,
        });
    } 

    onConfirmAction(contextItem) {
        switch(this.state.dialogContextAction){
            case this.dialogContextAction.delete:
                this.submitDelete(contextItem);
                break;

            case this.dialogContextAction.terminate:
                this.terminateEmployee(contextItem);
                break;

            default:
                break
        }

        //to hide the dialog after
        this.onCancelAction();
    } 

    onCancelAction() {
        this.setState({
            confirmDialogVisible: false, 
            dialogContextItem: {},
            dialogContextName: "",
            dialogContextAction: ''
        });
    }

    submitDelete(employee) {
        var data = {
            requestType:  'delete' ,
            employeeId: employee.id
        }

        this.props.apiRequestManager.queueRequest(actionTypes.EMPLOYEE_SUBMIT_DATA, data);
    }

    terminateEmployee(employee){
        this.props.apiRequestManager.queueRequest(actionTypes.TERMINATE_EMPLOYEE, employee.id);
    }

    getConfirmMessage(){
        if(this.state.dialogContextAction === this.dialogContextAction.delete) return 'Do you want to delete this';

        if(this.state.dialogContextAction === this.dialogContextAction.terminate) return 'Do you want to terminate this'
    }

    render () {

        const quickActionCallback = {
            'viewAction' : this.showPayAdvice,
        };
        
        const employeesData = this.props.employees ? this.props.employees : [];

        const selectedEmployee = this.props.currentEmployee;
        const employeePayAdviceData = selectedEmployee && Array.isArray(this.props.payAdvices[selectedEmployee.id]) ? this.props.payAdvices[selectedEmployee.id] : [] ;

        return (
            <React.Fragment>
            <div className='content-layout-wrap master-detail employees'>
                <div className="aside-list">
                    <div className="action-section">
                    <div className="button-wrap">
                        <Authority allowed="create" entity="employee" >
                            <Button className="default-btn add-btn" onClick={this.onAddEmployee}>
                            <span className="icon"><img src={getIconUrl("employee")} alt='' /></span>
                                Add Employee
                            </Button>
                        </Authority>
                        </div>
                    </div>
                    <div className="list-view">
                        <List className="list-items employee-list">
                            {employeesData.map((employee, i) => { 
                            
                            const attributes = {'data-employeeid': employee.id, 'data-rowid': i};

                           return (
                            <ContextMenuTrigger key={i} id="employee_item_context_menu" attributes={attributes} >
                            <li className={`employee__item item ${employee.terminated ? '--terminated' : ''}`} key={employee.id}>
                                <ListItem data={employee} index={employee.id} selectedKey={this.state.selected}
                                clickHandler={this.didSelectEmployee}>
                                <div className="item-content">
                                    <div className="employee">
                                        <div className="picture-holder">
                                            <div className="picture-content">
                                                { employee.picture ?
                                                    <img className="picture" src={employee.picture.fileDownloadUri} alt=''/>
                                                    :
                                                    pictureInitials(employee.firstName, employee.lastName)
                                                }
                                            </div>
                                        </div>
                                        <div className="employee-snippet">
                                            <h3>{employee.firstName} {employee.lastName}</h3>
                                            <p><span className="label">Emp#:</span>{employee.employeeNo}</p>
                                        </div>
                                        <div className="details">
                                            <h3>{employee.department.name}</h3>
                                            { !employee.terminated ?
                                            <p><span className="label">Hired:</span>{employee.dateHired}</p>
                                            :
                                            <p><span className="label">Terminated:</span>{employee.dateTerminated}</p>
                                            }
                                        </div>
                                    </div>
                                    <div className="quickaction">
                                        <List className="action-menu">
                                            <li className="action-item">
                                                <NavLink>
                                                    <span className="icon"><img className="icon" src={getIconUrl("option")} alt=''/></span>
                                                </NavLink>
                                            </li>
                                        </List>
                                    </div>
                                </div>
                                </ListItem>
                            </li>
                            </ContextMenuTrigger>
                            )
                             })}
                        </List>
                    </div>
                </div>
                <div className="view-layout-content detail-view">
                    <div className="detail-ly-ct tab-content-area">
                        <div className="tab-nav">
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.toggle('1'); }}
                                                >
                                        Overview
                                    </NavLink>
                                </NavItem>
                                <Authority allowed='view' entity='payadvice'>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: this.state.activeTab === '2' })}
                                        onClick={() => { this.toggle('2'); }}
                                        >
                                        Pay History
                                    </NavLink>
                                </NavItem>
                                </Authority>
                            </Nav>
                        </div>
                        <div className="content-pane">
                            <TabContent activeTab={this.state.activeTab}>

                                { !selectedEmployee ? 
                                <TabPane tabId='1' className="emplye-overview">
                                    <p className="no-selected">No employee data</p>
                                </TabPane>: 
                                <TabPane tabId='1' className="emplye-overview">
                                    
                                    <Section className="main-section">
                                        <div className="main-info">
                                            <div className="picture">
                                                <img src={ selectedEmployee.picture ? selectedEmployee.picture.fileDownloadUri : getIconUrl('profile') } alt='' />
                                            </div>
                                            <h3 className="name">{selectedEmployee.firstName} {selectedEmployee.lastName}</h3>
                                            <p className="info">Emp. No: {selectedEmployee.employeeNo}</p>
                                        </div>
                                    </Section>
                                    <Section className="emplye-section">
                                        <div className="inner">
                                        <h2 className="">Employee Info</h2>
                                        <div className="sec-content">
                                            <List className="detls-info-list">
                                                    <li className="info-line">
                                                        <span className="label">Department</span>
                                                        <span className="value">{selectedEmployee.department.name}</span>
                                                    </li>
                                                    <li className="info-line">
                                                        <span className="label">Can Login</span>
                                                        <span className="value">{selectedEmployee.loginAccess ? 'YES' : 'NO'}</span>
                                                    </li>
                                                    <li className="info-line">
                                                        <span className="label">User</span>
                                                        <span className="value">{selectedEmployee.user ? selectedEmployee.user : '--'}</span>
                                                    </li>
                                                    <li className="info-line">
                                                        <span className="label">Gender</span>
                                                         <span className="value">{selectedEmployee.gender}</span>
                                                    </li>
                                                    <li className="info-line">
                                                        <span className="label">Date of Birth</span>
                                                         <span className="value">{selectedEmployee.dateOfBirth}</span>
                                                    </li>
                                                    <li className="info-line">
                                                        <span className="label">Email</span>
                                                         <span className="value">{selectedEmployee.email ? selectedEmployee.email : '--'}</span>
                                                    </li>
                                                    
                                                </List>
                                            </div>
                                            </div>
                                        </Section>
                                    <Section className="wkspc-section">
                                        <div className="inner">
                                            <h2 className="">Details</h2>
                                            <div className="sec-content">
                                                <List className="detls-info-list">
                                                    <li className="info-line">
                                                        <span className="label">TRN</span>
                                                        <span className="value">{selectedEmployee.details.trn}</span>
                                                    </li>
                                                    <li className="info-line">
                                                        <span className="label">NIS</span>
                                                        <span className="value"> {selectedEmployee.details.nis}</span>
                                                    </li>
                                                    <li className="info-line">
                                                        <span className="label">Phone Number</span>
                                                        <span className="value">{selectedEmployee.details.phoneNumber}</span>
                                                    </li>
                                                    <li className="info-line">
                                                        <span className="label">Hourly Rate</span>
                                                        <span className="value">${selectedEmployee.details.hourlyRate.toCurrency(2)}</span>
                                                    </li>
                                                    <li className="info-line">
                                                        <span className="label">Salary</span>
                                                        <span className="value">${selectedEmployee.details.salary.toCurrency(2)}</span>
                                                    </li>
                                                    <li className="info-line">
                                                        <span className="label">Pay Period</span>
                                                        <span className="value">{selectedEmployee.details.payCycle}</span>
                                                    </li>
                                                    <li className="info-line">
                                                        <span className="label">Date Hired</span>
                                                        <span className="value">{selectedEmployee.dateHired}</span>
                                                    </li>
                                                    <li className="info-line">
                                                        <span className="label">Fired</span>
                                                        <span className="value">{selectedEmployee.terminated ? 'YES' : 'NO'}</span>
                                                    </li>
                                                    <li className="info-line">
                                                        <span className="label">Date Terminated</span>
                                                        <span className="value">{selectedEmployee.dateTerminated ? selectedEmployee.dateTerminated : '--'}</span>
                                                    </li>
                                                </List>
                                            </div>
                                        </div>
                                    </Section>
                                    { selectedEmployee.details.address && 
                                    <Section className="wkspc-section">
                                        <div className="inner">
                                            <h2 className="">Address</h2>
                                            <div className="sec-content">
                                                <List className="detls-info-list">
                                                    <li className="info-line">
                                                        <span className="label">Address Line 1</span>
                                                        <span className="value">{selectedEmployee.details.address.street}</span>
                                                    </li>
                                                    <li className="info-line">
                                                        <span className="label">Address Line 2</span>
                                                        <span className="value"> {selectedEmployee.details.address.streetTwo || '--'}</span>
                                                    </li>
                                                    <li className="info-line">
                                                        <span className="label">City</span>
                                                        <span className="value">{selectedEmployee.details.address.city}</span>
                                                    </li>
                                                    <li className="info-line">
                                                        <span className="label">Parish</span>
                                                        <span className="value">{selectedEmployee.details.address.state}, {selectedEmployee.details.address.postalCode}</span>
                                                    </li>
                                                    <li className="info-line">
                                                        <span className="label">Country</span>
                                                        <span className="value">{selectedEmployee.details.address.city}</span>
                                                    </li>
                                                </List>
                                            </div>
                                        </div>
                                    </Section>
                                    }
                                    <Section>
                                        <div className="inner">
                                        <ul className="sumry-list">
                                            <li className="sumry-item">
                                                <p className="days">
                                                    <span className="subt-count">{selectedEmployee.details.sickDaysTaken}</span>/<span className="count">{selectedEmployee.details.sickDays}</span> 
                                                </p>
                                                <h4>Sick Days</h4>
                                            </li>
                                            <li className="sumry-item">
                                                <p className="days">
                                                    <span className="subt-count">{selectedEmployee.details.vacationDaysTaken}</span>/<span className="count">{selectedEmployee.details.vacationDays}</span> 
                                                </p>
                                                <h4>Vacation Days</h4>
                                            </li>
                                        </ul>
                                        </div>
                                    </Section>
                                </TabPane>
                                }
                                <Authority allowed='view' entity='payadvice'>
                                    <TabPane tabId='2'>
                                        <PayAdviceTable
                                            tableData={employeePayAdviceData}
                                            quickActions={quickActionCallback}
                                        />
                                    </TabPane>
                                </Authority>
                            </TabContent>
                        </div>
                    </div>
                </div>
                <ContextMenu ref={this.contextMenuRef} id="employee_item_context_menu">
                    <React.Fragment>                
                        <MenuItem 
                        data={{opt: 'edit'}} 
                        disabled={!this.props.permissionEnforcer.hasRights('change', 'employee')} onClick={this.contextMenuOptClick}>
                            Edit
                        </MenuItem>
                        <MenuItem 
                        data={{opt: 'terminate'}} 
                        disabled={selectedEmployee?.terminated || !this.props.permissionEnforcer.hasRights('change', 'employee')} onClick={this.contextMenuOptClick}>
                            Terminate
                        </MenuItem>
                        <MenuItem 
                        data={{opt: 'delete'}} 
                        disabled={!this.props.permissionEnforcer.hasRights('delete', 'employee')} onClick={this.contextMenuOptClick}>
                            Delete
                        </MenuItem>
                    </React.Fragment>  
                    
                </ContextMenu>
            </div>
            { this.state.confirmDialogVisible && 
                <ConfirmModal 
                contextName={this.state.dialogContextName} 
                contextItem={this.state.dialogContextItem} 
                cancelCallback={this.onCancelAction}
                confirmCallback={this.onConfirmAction}
                mainMessage={this.getConfirmMessage()}
                isDelete={this.state.dialogContextAction === this.dialogContextAction.delete}
                />
            }
            </React.Fragment>
        );
    }
}

const EmployeeView = (props) => {
    return (
        <Employee {...props } />
    );
};

let ConfigEmployeeView = setupPage(Employee.pageOption)(EmployeeView);
ConfigEmployeeView =  connect(mapStateToProps, mapDispatchToProps)(ConfigEmployeeView);

export { ConfigEmployeeView };