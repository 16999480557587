import { requests } from './agentDialect';

export const fetchFeatureGroup = (data) => {
    let uri = "/form/group";
    return requests.get(uri, data);
};

export const createFromGroup = (data) => {
    let uri = "/form/group/create";
    return requests.post(uri, data);
};

export const updateFromGroup = (data) => {
    let uri = `/form/group/update/${data.formGroupId}`;
    return requests.put(uri, data);
};

export const deleteFromGroup = (data) => { 
    let uri = `/form/group/delete/${data.formGroupId}`;
    return requests.delete(uri, data);
};

export const fetchAppPreferences = () => {
    let uri = "/preferences";
    return requests.get(uri);
};
export const submitChangePreferences = (payload) => {
    let uri = "/preferences";
    return requests.put(uri, payload);
};

export const fetchPrintGroups = (payload) => {
    const uri = `/form/printGroup/${payload.formGroupId}`;
    return requests.get(uri);
}

export const createPrintGroup = (payload) => {
    const uri = '/form/printGroup/create';
    return requests.post(uri, payload);
 }

 export const updatePrintGroup = (payload) => {
    const uri = `/form/printGroup/update/${payload.id}`;
    return requests.put(uri, payload);
 }

 export const deletePrintGroup = (payload) => {
    const uri = `/form/printGroup/delete/${payload.id}`;
    return requests.delete(uri, payload);
 }