import { requests } from './agentDialect';
import { httpRequests } from './httpRequestDialect';

import {rolesArraySchema, permissionsArraySchema} from './schemas';

export const fetchAllPermissions = (forWorkspace) => {
    let uri =  !forWorkspace ? "/permissions" : `/permissions?workspace=${forWorkspace}`;
    return requests.get(uri);
};

export const fetchAllRoles = (forWorkspace) => {
    let uri = !forWorkspace ? "/roles" : `/roles?workspace=${forWorkspace}`;
    return requests.get(uri);
};

export const fetchWorkspaceRoles = () => {
    let uri = "/roles/workspace";
    return requests.get(uri);
};
export const submitRolePermissions = (data) => {
    let uri = "/permissions";
    return requests.post(uri, data);
};

export const updateRolePermissions = (data) => {
    let uri = "/permissions";
    return requests.put(uri, data);
};

export const deleteRolePermissions = (data) => {
    let uri = `/roles/delete/${data.role}`;
    return requests.delete(uri, data);
};

// New Request api calls
export const fetchRoles = (forWorkspace) => {
    let uri = !forWorkspace ? "/roles" : `/roles?workspace=${forWorkspace}`;
    return httpRequests.get(uri, rolesArraySchema);
};

export const fetchPermissions = (forWorkspace) => {
    let uri =  !forWorkspace ? "/permissions" : `/permissions?workspace=${forWorkspace}`;
    return httpRequests.get(uri, permissionsArraySchema);
};