import * as actionTypes from '../../actionType';
import { isError } from '../../../service/agent';

export const labcodeState = {
    labcodeSetting : {
        labCodesData: null
    }
}
    
var error = null;
let empty = {}
let emptyArray = [];

const labcodeReducer = (state, action ) => { 
    switch(action.type) {
        case actionTypes.SET_LABCODE_DATA:
            return {
                ...state,
                labcodeSetting: {
                    ...state.labcodeSetting,
                    labCodesData: action.payload
                }
            }
        case actionTypes.FETCHED_LABCODES_RESULTS:
            error = isError(action.payload);
            return {
                ...state,
                labcodeSetting: {
                    ...state.labcodeSetting,
                    labCodesData: error ? emptyArray : action.payload.map((item, i) => {
                        return {
                            index: i,
                            ...item
                        }
                    })
                }
                
            }
        case actionTypes.LABCODE_SUBMIT_DATA:
            return {
                ...state,
                requestType: action.payload.requestType,
                isSaving: true
            }
        case actionTypes.LABCODE_SUBMIT_DATA_RESULT:
            error = isError(action.payload);
            if (state.requestType === 'delete') {
                const filteredLabCodes =  error ? state.labcodeSetting.labCodesData  : state.labcodeSetting.labCodesData.filter((item) => item.id !== action.payload.deletedId);
                return {
                    ...state,
                    isSaving: false,
                    labcodeSetting: {
                        ...state.labcodeSetting,
                        labCodesData: filteredLabCodes
                    }
                }
            }else {
                return {
                    ...state,
                    isSaving: false,
                    labcodeSetting: {
                        ...state.labcodeSetting,
                        labCodesData: error ? state.labcodeSetting.labCodesData : action.payload.map((item, i) => {
                            return {
                                index: i,
                                ...item
                            }
                        })
                    }
                }
            }
        default:
            return state;
    }
};

export default labcodeReducer;
