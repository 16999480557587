import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { Comparator, dateFilter } from 'react-bootstrap-table2-filter'
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import PropType  from 'prop-types';
import { getIconUrl } from '../../common/images-catalogue';

import _ from 'lodash';
import { InputField, List , NavLink} from '../index';
import {pictureInitials} from './../../utility/helpers';
import { PaginationPanel, PaginationTotal } from './';
import { PAGE_SIZE } from '../../config/pagination';

const INITIAL_PRODUCTS_COUNT = 8;


const generateRow = (data) => ({
    id: data.id,
    account: data,
    client: data,
    workspace: data,
    details: data,
    status: data
});

const patientNameInfo = (data) => {
   
    return (
        <ContextMenuTrigger id="users_table_context_menu">
        <div className="account-info">
            <div className="account-info-holder">
                <div className="account-info-content">
                    <h3>{data.accountNo}</h3>
                </div>
            </div>
        </div>
        </ContextMenuTrigger>
    );
}

const clientsInfo = (data) => {
    
    return (
        <ContextMenuTrigger id="users_table_context_menu">
        <div className="client-info">
            <div className="client-info-holder">
                <div className="client-info-content">
                    <h3>{data.owner}</h3>
                    <p className="info">{data.clientType}</p>
                </div>
            </div>
        </div>
        </ContextMenuTrigger>
    );
}

const workspaceInfo = (data) => {
    return (
        <ContextMenuTrigger id="users_table_context_menu">
        <div className="client-info">
            <div className="client-info-holder">
                <div className="client-info-content">
                    <h3>{data.workspace.name}</h3>
                    <p className="info">{data.workspace.identifier}</p>
                </div>
            </div>
        </div>
        </ContextMenuTrigger>
    );
}

const patientDetails = (actions) => {
    //return function with cell content
    return (data) => (
        <div className="user-details">
            <ContextMenuTrigger id="users_table_context_menu">
            <div className="patient-details-holder">
                <div className="patient-details-content">
                    <p className="info">Balance: <span className="highlight">${ data.balance.toCurrency() }</span></p>
                    <p className="info">Prev. Bal.:  <span className="highlight">${ data.previousBalance.toCurrency() }</span></p>
                </div>
            </div>
            </ContextMenuTrigger>
        </div>
    );
}

const statusInfo = (data) => {
    return (
        <ContextMenuTrigger id="users_table_context_menu">
        <div className="client-info">
            <div className="toolbar">
                <List className="action-menu">
                    <li className="action-item">
                        <NavLink actiontag="view">
                            <span className="icon"><img className="icon" src={getIconUrl("edit")} alt='' /></span>
                        </NavLink>
                    </li>
                    <li className="action-item">
                        <NavLink >
                            <span className="icon"><img className="icon" src={getIconUrl("option")} alt='' /></span>
                        </NavLink>
                    </li>
                </List>
            </div>
            <div className="client-info-holder">
                <div className="client-info-content">
                    <h3>Active : <span className="highlight">{data.active ? 'YES' : 'NO'}</span></h3>
                    <p className="info highlight">{data.status}</p>
                </div>
            </div>
        </div>
        </ContextMenuTrigger>
    );
}


class AccountTable extends React.Component { 
    static propsTypes = {
        tableData: PropType.array.require,
        actionResponsers: PropType.object,
    }

    static defaultProps = {
        actionResponsers: {
            editButton: null,
        }
    }

    constructor(props) {
        super(props);
        
        this.state = {
            tableData: _.times(INITIAL_PRODUCTS_COUNT, generateRow),
            selected: [],
            rowInFocus: null
        };
        this.table = React.createRef();
        this.willEnterRow = this.willEnterRow.bind(this);
        this.didClickRow = this.didClickRow.bind(this);
        this.didContextClick = this.didContextClick.bind(this);
        this.selectFirstRow = this.selectFirstRow.bind(this);
    }

    shouldComponentUpdate(props, state) {
        (props.tableData.length > 0 && state.selected.empty()) && this.selectFirstRow(props.tableData);

        return true;
    }

    createColumnDefinitions(quickActions) { 
        return [
            {
                dataField: 'account',
                text: "Account No",
                headerAttrs: {
                    hidden: false,
                    className: 'th-patient-cell',
                },
                classes: 'id-patient-cell td-cell',
                formatter: patientNameInfo
            },
            {
                dataField: 'client',
                text: "Owner",
                headerAttrs: {
                    hidden: false,
                    className: 'th-client-cell',
                },
                classes: 'id-client-cell td-cell',
                formatter: clientsInfo
            },
            {
                dataField: 'workspace',
                text: "Workspace",
                headerAttrs: {
                    hidden: false,
                    className: 'th-workspace-cell',
                },
                classes: 'id-workspace-cell td-cell',
                formatter: workspaceInfo
            },
            {
                dataField: 'details',
                text: "Details",
                headerAttrs: {
                    hidden: false,
                    className: 'th-details-cell',
                },
                classes: 'id-details-cell td-cell',
                formatter: patientDetails( quickActions )
            },
            {
                dataField: 'status',
                text: "Status",
                headerAttrs: {
                    hidden: false,
                    className: 'th-status-cell',
                },
                classes: 'id-status-cell td-cell',
                formatter: statusInfo
            },
        ];
    }

    selectFirstRow (tableData){
        const row = tableData[0];
        this.setState({
          selected: [row.id]
        });
        this.props.quickActions.viewDetails(row);
    }

    handleSelect(row, isSelected) { 
        this.props.quickActions.viewDetails(row.account);
        this.setState({ selected: [row.id]});
    }

    handleSelectAll() { }

    handleOnChange() { }

    willEnterRow (evt, row, rowIndex) { }

    didClickRow(evt, row, index) { }  

    didContextClick (evt, row, rowIndex) {
        evt.preventDefault();
    }

    render () {
        const columnDefs = this.createColumnDefinitions(this.props.quickActions);
        const paginationDef = paginationFactory({
            paginationSize: 5,
            sizePerPage: PAGE_SIZE,
            showTotal: true,
            pageListRenderer: (props) => (
                <PaginationPanel { ...props } size="sm" className="ml-md-auto mt-2 mt-md-0" />
            ),
            paginationTotalRenderer: (from, to, size) => (
                <PaginationTotal { ...{ from, to, size } } />
            )
        
        });
        const selectRowConfig = {
            mode: 'checkbox',
            clickToSelect: true,
            classes: '--selected',
            style: { background: '#eff7ff' },
            selected: this.state.selected,
            onSelect: this.handleSelect.bind(this),
            onSelectAll: this.handleSelectAll.bind(this),
            selectionRenderer: ({ mode, checked, disabled }) => (
                <InputField id="" onChange={this.handleOnChange} type={ mode } checked={ checked } disabled={ disabled } />
            ),
            selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
                <InputField id="" onChange={this.handleOnChange} type={ mode } checked={ checked } innerRef={el => el && (el.indeterminate = indeterminate)} />
            )
        };

        const rowEventsHandlers = {
            onContextMenu: this.didContextClick,
            onMouseEnter: this.willEnterRow,
            onClick: this.didClickRow
        };

        var options = {
            noDataMessage: () => 'No patients found.'
        };

        //const accountsData = this.state.tableData;
        const accountsData = this.props.tableData.map(account => generateRow(account));
        
        return (
            <ToolkitProvider
                keyField="id"
                data={ accountsData }
                columns={columnDefs} >
                {
                    props => (
                        <React.Fragment>
                            <BootstrapTable
                            ref={this.table}
                            loading={ true }
                            classes="table-responsive accounts-table"
                            pagination={ paginationDef }
                            filter={ filterFactory() }
                            selectRow={ selectRowConfig }
                            bordered={ false }
                            responsive
                            { ...props.baseProps }
                            rowEvents={ rowEventsHandlers }
                            noDataIndication={options.noDataMessage}
                        />
                        </React.Fragment>
                    )
                }
            </ToolkitProvider>
        );
    }
}

export { AccountTable }