import * as actionTypes from '../actionType';
import { isError } from '../../service/agent';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
var initialState = {
    viewInit: false,
    clientsData: null,
    clientView: null,  
    patientsList: null,
    recordsList: null, 
    accountDetails: null,
    insightsData: null
 };

 const initialCreateViewState = {
    viewInit: false,
    assignedRole: null,
    availableRoles: [],
    workspaceRoles: null,
    userRoles: null,
    labCodes: null,
  
    clientForm: {
        clientType: '',
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        password: '',
        passwordTwo: '',
        workspaceName: '',
        officeName: '',
        officeNumber: '',
        phoneNumber: '',
        mobileNumber: '',
        active: false,
        blocked: false,
        twoFactorAuth: false,
        assignedConstraint: null,
        labCode: null,
        address: { },

        profileImage: null,
    },

    
};

const combineInitialState = {
    common: initialState,
    createView: initialCreateViewState
}
var error = null;
let empty = {};
let emptyArray = [];
let requestManager = null;
let apiOutcome = null;

const clientReducer = (state = initialState, action ) => { 

    switch(action.type) {
        case actionTypes.CLIENTS_VIEW_LOADED:
            return {
                ...state,
                clientView: action.payload
            };
        case actionTypes.CLIENTS_VIEW_UNLOAD:
            return {
                ...state,
                ...initialState,
                clientView: state.clientView,
            }
        case actionTypes.CLIENT_CREATE_VIEW_UNLOAD:
            return {
                ...initialState,
                clientView: state.clientView,
            }; 
        case actionTypes.FETCHED_CLIENTS_RESULTS: 
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.FETCH_CLIENTS) ?? {};
            return {
                ...state,
                clientsData: apiOutcome?.success ? action.payload : emptyArray,
                fetchData: false,
            };
        case actionTypes.FETCHED_CLIENT_RESULT: 
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.FETCH_CLIENT) ?? {};
            return {
                ...state,
                currentClient: apiOutcome?.success ? action.payload : null,
            }
        case actionTypes.FETCHED_OWNER_ACCOUNT_RESULT:
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.FETCH_OWNER_ACCOUNT) ?? {};
            return {
                ...state,
                accountDetails: apiOutcome?.success ? action.payload : {}
            }
        case actionTypes.SET_CLIENT_DATA:
            return {
                ...state,
                currentClient: action.payload
            }
        case actionTypes.FETCHED_CLIENT_SPECIMENS_HISTORY_RESULTS:
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.FETCH_CLIENT_SPECIMENS_HISTORY) ?? {};
            return {
                ...state,
                recordsList: apiOutcome?.success ? action.payload : emptyArray
            }
        case actionTypes.FETCHED_CLIENT_PATIENTS_RESULTS:
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.FETCH_CLIENT_PATIENTS) ?? {};
            return {
                ...state,
                patientsList: apiOutcome?.success ?  action.payload : emptyArray
            }  
        case actionTypes.FETCHED_CLIENT_OVERVIEW_DATA_RESULT:
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.FETCH_CLIENT_OVERVIEW_DATA) ?? {};
            return {
                ...state,
                insightsData: apiOutcome?.success ? action.payload : {}
            }   
        default:
            return state;
    }
};

const clientCreateViewReducer = (state = initialCreateViewState, action ) => { 
    switch(action.type) {
        case actionTypes.CLIENT_CREATE_VIEW_LOADED:
            return {
                ...state,
                viewInit: true
            };
        case actionTypes.CLIENT_CREATE_VIEW_UNLOAD:
            return {
                ...initialCreateViewState
            }; 
        case actionTypes.FETCHED_USER_WORKSPACE_ROLES_RESULTS:
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.FETCH_USER_WORKSPACE_ROLES) ?? {};

            error = apiOutcome?.error && isError(action.payload.userRoles);
            const userRoles = error? emptyArray : action.payload.userRoles;
            error = apiOutcome?.error && isError(action.payload.workspaceRoles);
            const workspaceRoles = error? emptyArray : action.payload.workspaceRoles;
            error = apiOutcome?.error && isError(action.payload.labCodes);
            const labCodes = error? emptyArray : action.payload.labCodes;
            return {
                ...state,
                userRoles: userRoles,
                workspaceRoles: workspaceRoles,
                labCodes:  labCodes,
            }
        case actionTypes.CLIENT_FIELD_CHANGED :
            return {
            ...state,
            clientForm: {
                ...state.clientForm,
                ...action.payload
            }
        }
        case actionTypes.CLIENT_ASSIGNED_ROLE :
            return {
            ...state,
            assignedRole:  action.payload.chosenRole,
            userRoles: action.payload.availableRoles,
        }
        default:
            return state;
    }
};

const clientCombineReducers = (state = combineInitialState, action ) => { 
   return {
       common: clientReducer(state = combineInitialState.common, action ),
       createView:   clientCreateViewReducer(state = combineInitialState.createView, action)
   }
}

export { clientCreateViewReducer, clientCombineReducers };

export default clientReducer;