const stringLibrary = {
  defaultMessage: '',

  specimenText: {
    createdText: 'Successfully created Specimen.',
    createUnableText: 'Unable to create Specimen.',
    updatedText: 'Successfully updated Specimen.',
    updateUnableText: 'Unable to update Specimen.',
    deletedText: 'Specimen was deleted.',
    deleteUnableText: 'Unable to delete Specimen.',
    submitUnableText: 'Unable to Submit Specimen Record.',
    submitSuccessText: 'Successfully Submitted Specimen Record.',
  },

  requisition: {
    createdText: 'Successfully created Requisition.',
    createUnableText: 'Unable to create Requisition.',
    deletedText: 'Requisition was deleted.',
    deleteUnableText: 'Unable to delete Requisition.',
  },
  requisitionItemText: {
    createdText: '',
    createUnableText: '',
    deletedText: 'Requisition Item was deleted.',
    deleteUnableText: 'Unable to delete Requisition Item.',
  },

  clientText: {
    createdText: 'Successfully created Client.',
    createUnableText: 'Unable to create Client.',
    updatedText: 'Successfully updated Client.',
    updateUnableText: 'Unable to update Client.',
    deletedText: 'Client was deleted.',
    deleteUnableText: 'Unable to delete Client.',
  },

  patientText: {
    createdText: 'Successfully created Patient.',
    createUnableText: 'Unable to create Patient.',
    updatedText: 'Successfully updated Patient.',
    updateUnableText: 'Unable to update Patient.',
    deletedText: 'Patient was deleted.',
    deleteUnableText: 'Unable to delete Patient.',
  },

  userText: {
    createdText: 'Successfully created User.',
    createUnableText: 'Unable to create User.',
    updatedText: 'Successfully updated User.',
    updateUnableText: 'Unable to update User.',
    deletedText: 'User was deleted.',
    deleteUnableText: 'Unable to delete User.',
  },

  permission: {
    createdText: 'Successfully created Role.',
    createUnableText: 'Unable to create Role.',
    updatedText: 'Successfully updated Role.',
    updateUnableText: 'Unable to update Role.',
    deletedText: 'Role was deleted.',
    deleteUnableText: 'Unable to delete Role.',
  },

  resultSheetText: {
    createdText: 'Successfully added Result Sheet.',
    createUnableText: 'Unable to create Result Sheet.',
    updatedText: 'Successfully updated Result Sheet.',
    updateUnableText: 'Unable to update Result Sheet.',
    reportText: 'Successfully change Report',
    reportChangedText: 'Successfully change Report',
    notReportsText: '',
    approveText: 'Successfully approved Result Sheet.'
  },

  formFieldsText: {
    createdText: 'Successfully created Form Fields.',
    createUnableText: 'Unable to create Form Fields.',
    updatedText: 'Successfully updated Form Fields.',
    updateUnableText: 'Unable to update Form Fields.',
    deletedText: 'Form Fields were deleted.',
    deleteUnableText: 'Unable to delete Form Fields.',
  },

  labCode: {
    createdText: 'Successfully created Lab Code.',
    createUnableText: 'Unable to create Lab Code.',
    updatedText: 'Successfully updated Lab Code.',
    updateUnableText: 'Unable to update Lab Code.',
    deletedText: 'Lab Code was deleted.',
    deleteUnableText: 'Unable to delete Lab Code.',
    submitUnableText: 'Unable to submit Lab Code.',
    submitSuccessText: 'Successfully submitted Lab Code.',
  },

  codeSheet: {
    createdText: 'Successfully created Code Sheet.',
    createUnableText: 'Unable to create Code Sheet.',
    updatedText: 'Successfully updated Code Sheet.',
    updateUnableText: 'Unable to update Code Sheet.',
    deletedText: 'Code Sheet was deleted.',
    deleteUnableText: 'Unable to delete Code Sheet.',
    submitUnableText: 'Unable to submit Code Sheet.',
    submitSuccessText: 'Successfully submitted Code Sheet.',
  },

  codeFindings: {
    createdText: 'Successfully created Code Finding.',
    createUnableText: 'Unable to create Code Finding.',
    updatedText: 'Successfully updated Code Finding.',
    updateUnableText: 'Unable to update Code Finding.',
    deletedText: 'Code Finding was deleted.',
    deleteUnableText: 'Unable to delete Code Finding.',
    submitUnableText: 'Unable to submit Code Finding.',
    submitSuccessText: 'Successfully submitted Code Finding.',
  },

  service: {
    createdText: 'Successfully created Service.',
    createUnableText: 'Unable to create Service.',
    updatedText: 'Successfully updated Service.',
    updateUnableText: 'Unable to update Service.',
    deletedText: 'Service was deleted.',
    deleteUnableText: 'Unable to delete Service.',
    submitUnableText: 'Unable to submit Service.',
    submitSuccessText: 'Successfully submitted Service.',
  },

  taxItem: {
    createdText: 'Successfully created Tax Item.',
    createUnableText: 'Unable to create Tax Item.',
    updatedText: 'Successfully updated Tax Item.',
    updateUnableText: 'Unable to update Tax Item.',
    deletedText: 'Tax Item was deleted.',
    deleteUnableText: 'Unable to delete Tax Item.',
    submitUnableText: 'Unable to submit Tax Item.',
    submitSuccessText: 'Successfully submitted Tax Item.',
  },

  settings: {
    updatedText: 'Successfully updated Preferences.',
    updateUnableText: 'Unable to update Preferences.',
  },

  department: {
    deletedText: 'Department was deleted.',
    deleteUnableText: 'Unable to delete Department.',
    submitUnableText: 'Unable to submit Department.',
    submitSuccessText: 'Successfully submitted Department.',
  },

  employee: {
    createdText: 'Successfully created Employee.',
    createUnableText: 'Unable to create Employee.',
    updatedText: 'Successfully updated Employee.',
    updateUnableText: 'Unable to update Employee.',
    deletedText: 'Employee was deleted.',
    deleteUnableText: 'Unable to delete Employee.',
  },

  payment: {
    createdText: 'Successfully created Payment.',
    createUnableText: 'Unable to create Payment.',
    updatedText: 'Successfully updated Payment.',
    updateUnableText: 'Unable to update Payment.',
  },

  billing: {
    createdText: 'Successfully created Bill.',
    createUnableText: 'Unable to create Bill.',
    updatedText: 'Successfully updated Bill.',
    updateUnableText: 'Unable to update Bill.',
    deletedText: 'Bill was deleted.',
    deleteUnableText: 'Unable to delete Bill.',
    billedText: 'Successfully billed client.',
    billedUnableText: 'Unable to bill client.',
  },

  printGroup: {
    createdText: 'Successfully created Print Group.',
    createUnableText: 'Unable to create Print Group.',
    updatedText: 'Successfully updated Print Group.',
    updateUnableText: 'Unable to update Print Group.',
    deletedText: 'Print Group was deleted.',
    deleteUnableText: 'Unable to delete Print Group.',
  },

  cabinet: {
    submitSuccessText: 'Successfully created Cabinet.',
    submitUnableText: 'Unable to create Cabinet.',
    updatedText: 'Successfully updated Cabinet.',
    updateUnableText: 'Unable to update Cabinet.',
  },

  profile: {
    updatedText: 'Successfully updated Profile.',
    updateUnableText: 'Unable to update Profile.',
  },
};

export default stringLibrary;
