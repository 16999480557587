import React from 'react';
import PropType from 'prop-types';
import { 
    Button
 } from '../../../components';

const defaultSubtitle = 'You cannot undo this action';

const ConfirmModal = (props) => {
    const {contextName, contextItem, confirmCallback, cancelCallback, isDelete, title, mainMessage } = props;

    const onConfirm = () => {
        confirmCallback(contextItem);
    }
    const onCancel = () => {
        cancelCallback();
    }

    return (<div className="delete-modal">
                <div className='delete-dialog'>
                    <div className="cont-inner">
                        {title ?
                            <h4>{title}?</h4>
                            : 
                            <h4>{mainMessage} <br/> {contextName}?</h4>
                        }
                        <p>{ props.subtitle || defaultSubtitle}</p>
                    </div>
                    <div className="action-wrap">
                        <span className="act-hldr">
                            <Button onClick={onCancel} className={isDelete ? 'cancel-btn' : 'delete-btn'}>{isDelete ? 'Cancel' : 'No' }</Button>
                        </span>
                        <span  className="act-hldr">
                            <Button onClick={onConfirm} className={isDelete ? 'delete-btn': 'cancel-btn' }>{isDelete ? 'Delete' : 'Yes'}</Button>
                        </span>
                    </div>
                </div>
        </div>);
}

const propTypes = {
    contextName: PropType.string.isRequired,
    confirmCallback: PropType.func.isRequired,
    cancelCallback: PropType.func.isRequired,
    contextItem: PropType.object.isRequired,
    title: PropType.string,
    subtitle: PropType.string,
    isDelete: PropType.bool,
    mainMessage: PropType.string,
}

const defaultProps = {
    contextName: "Item", 
    isDelete: true,
    mainMessage: 'Do you want to delete this',
    subtitle: defaultSubtitle
}

ConfirmModal.propTypes = propTypes
ConfirmModal.defaultProps = defaultProps

export default ConfirmModal;