import { normalize, denormalize } from 'normalizr'
import { camelizeKeys } from 'humps'
import 'unfetch'
import * as ENV from '../.env.json';

import { ERROR_CODES, REQUESTS_TYPE } from '../common/constant';
import { requestEnded } from './agentDialect';
import NetworkError from '../utility/error/NetworkError';
import ApiError from '../utility/error/ApiError';

const baseUri = ENV.REACT_APP_API_URI;
export const apiServer = ENV.REACT_APP_API_SERVER;
const wsHost = ENV.REACT_APP_WS_HOST;
const API_ROOT = `${apiServer}${baseUri}`;

let jwtToken = null;
let workspaceId = null;

export const REQUEST = 'REQUEST'
export const SUCCESS = 'SUCCESS'
export const FAILURE = 'FAILURE'

export function createRequestTypes(base) {
  return [REQUEST, SUCCESS, FAILURE].reduce((acc, type) => {
		acc[type] = `${base}_${type}`
		return acc
	}, {})
}

const TIMEOUT_AFTER = 30000
// process.env
const mainHeaders = {
    'Content-Type': 'application/json',
};

let metaDataProps = {
    requestType: REQUESTS_TYPE.FETCH,
    initiator: null
}

let addHeaders = (headers) => (defaultHeader) => {
    return {
        ...defaultHeader,
        ...headers
    }
};

let tokenProvider = () => {
    if (jwtToken) {
        return  {
            'Authorization': `Bearer ${jwtToken}`,
            'Authorization-Context': `${workspaceId}`,
        };
    } else {
        return {};
    }
};

// Extracts the next page URL from Github API response.
function getNextPageUrl(response) {
    const link = response.headers.get('link')
    if (!link) {
      return null
    }
  
    const nextLink = link.split(',').find(s => s.indexOf('rel="next"') > -1)
    if (!nextLink) {
      return null
    }
  
    return nextLink.split(';')[0].slice(1, -1)
}

function timeoutFetch(url, options = {}) {
  /*
   * fetches a request like the normal fetch function 
   * but with a timeout argument added.
   */
  let timeout = options.timeout || 30000;
  let timeoutError = {
      ok: false,
      status: 408
  };
  return new Promise(function(resolve, reject) {
      fetch(url, options).then(resolve, reject);
      setTimeout(reject.bind(null, timeoutError), timeout);
  });
}

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
function apiRequester(endpoint, schema, options, metadata) {
    const fullUrl = (endpoint.indexOf(API_ROOT) === -1) ? API_ROOT + endpoint : endpoint;
    
    return timeoutFetch(fullUrl, options)
      .then(response =>
        response.json().then(json => ({ json, response }))
      ).then(({ json, response }) => {

        let { data, error} = checkApiReponse(response, json, metadata?.initiator, metadata?.requestType);
        restMetaData();
        
        if (!response.ok) {
            return Promise.reject(error);
        }
        
        const camelizedJson = camelizeKeys(data);
        const nextPageUrl = getNextPageUrl(response);

        return Object.assign({},
          normalize(camelizedJson, schema),
          { nextPageUrl }
        )
      })
      .then(
        response => ({response}),
        error => ({ error: true, message: error.message || 'Something bad happened'})
      )
}

const checkApiReponse = (response, payload, actionType, requestType) => {
    // Request as ended    
    let apiOutcome = { 
        initiator: actionType,
        requestType: requestType,
        error: null,
        success: false,  
    };

    if (  response.ok ) {
        apiOutcome.success = true;
        requestEnded(apiOutcome);
        return {data: payload.data};
    } else {
        let error = null;
        //Check for Api Error
        if (payload.error) {
            apiOutcome.success = false;
            error = new ApiError(payload.error.code, payload.error.debugMessage, 
                payload.error.type, payload.error.message , payload.error.subErrors );
            error.details = payload.error.subErrors;

        }else {
            //Network connection error
            error = new NetworkError(505, 
                ERROR_CODES.ERR_SERVER_COMMUNICATION, 
                'ERR_SERVER_COMMUNICATION',
                [] );
            error.details = ERROR_CODES.ERR_SERVER_COMMUNICATION;  
            apiOutcome.success = false;
        }
        apiOutcome.error = error;
        requestEnded(apiOutcome);
        return {error};
    }
};

export const isError = (payload) => payload instanceof ApiError || payload instanceof NetworkError || payload instanceof ProgressEvent;

export function denormalizeResponse(input, schema, entries) {
    return denormalize(input, schema, entries);
}

const getRequest = (url, schema) => {
    //Start api request
    let request = apiRequester(url, schema, {
        method: 'GET',
        headers: addHeaders({
            ...mainHeaders
        })(tokenProvider()),
        timeout: TIMEOUT_AFTER
    }, metaDataProps);
    return request
};

export const httpRequests = {
    get: (url, schema) => getRequest(url, schema),
};

export const setMetaData = (metadata) => {metaDataProps = {
    ...metaDataProps,
    ...metadata
}};

const restMetaData = () => { 
    metaDataProps = {
        requestType: REQUESTS_TYPE.FETCH, 
        initiator: null
    }; 
};

export const getToken = () => { return jwtToken; };
export const setToken = (token) => { jwtToken = token; };
export const getApiRoot = () => apiServer;
export const getWebSocketHost = () => wsHost;
export const setWorkspaceContextId = (workspace) => { workspaceId = workspace; };