import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setupPage } from '../../../../components/Layout/setupPage';
import {
  List, 
  Input,
  FormGroup,
  Label,
  Section,
} from '../../../../components';

import  * as actions  from '../../../../store/actions';
import * as actionTypes from '../../../../store/actionType';
import { AddButton } from '../../../components/partials/Buttons';
import { ErrorMessage } from '../../../components/partials/ErrorMessage'
import text from '../../../../common/strings';
import { REQUESTS_TYPE, VALIDATOR_DELEGATE_KEY_GROUP, VALIDATOR_DELEGATE_TYPES } from '../../../../common/constant';
import { PromptMessage, getPromptMessageProps } from '../../../components/partials/PromptMessage';
import settingsValidatorDelegate from '../../../../forms/settingsValidator.delegate';
import { objListsAreEqual } from '../../../../utility/helpers';

import { getIconUrl } from '../../../../common/images-catalogue';


const mapStateToProps = (state) => ({
  ...state.department,
});

const mapDispatchToProps = (dispatch) => ({
  getAllDepartments: () => dispatch(actions.getAllDepartments()),
  setDepartmentsData: (departments) => dispatch(actions.setDepartmentsData(departments)),
  submitData : (payload) => dispatch(actions.submitDepartmentDataAction(payload))
})

function DepartmentItem(props) {
  const { data, actions } = props;

  const isNewClass = !data.id ? '--new' : '';
  const text = !data.id ? 'Remove' : 'Delete';

  const handleNameChange = (event) => {
    actions.changeDepartmentData({
      ...data,
      name: event.target.value.capitalize(),
    });
  }

  return (
    <li className={`department-item ${isNewClass}`}>
      <div className="department">
        <FormGroup>
            <Label>Name</Label>
            {(data.name ? '' : <ErrorMessage message='Enter a name'/>)}
            <Input onChange={(event) => handleNameChange(event)} type="text" name="name" placeholder="Department Name" value={data.name} />
        </FormGroup>
      </div>
      <div className="department-ft">
        <Link to={'#'} onClick={(event) => actions.removeDepartment(event, data)} className="remove"><img className={'icon'} src={getIconUrl('trash')} alt='' /> {text}</Link>
      </div>
    </li>
  );
}

class Department extends React.Component {

  static pageOption = {
    pageTitle: 'Department',
    disableBodyScroll: true
  };

  constructor(props){
    super(props);
    this.state = {};

    this.onSaveHandler = this.onSaveHandler.bind(this);
    this.addNewDepartment = this.addNewDepartment.bind(this);
    this.createNewDepartment = this.createNewDepartment.bind(this);
    this.updateDepartmentData = this.updateDepartmentData.bind(this);
    this.deleteDepartmentItem = this.deleteDepartmentItem.bind(this);
    this.getPromptMessage = this.getPromptMessage.bind(this);
    this.validation = this.validation.bind(this);

    props.apiRequestManager.register(actionTypes.FETCH_DEPARTMENTS, props.getAllDepartments);
    props.apiRequestManager.register(actionTypes.DEPARTMENT_SUBMIT_DATA, props.submitData);

    props.innerRef.saveHanlder = this.onSaveHandler;
    props.innerRef.confirmDeleteCallback = this.onConfirmDelete.bind(this);
  }

  componentDidMount() {
    this.props.validator.addDelegate(VALIDATOR_DELEGATE_TYPES.settings, settingsValidatorDelegate)
 }

  static getDerivedStateFromProps(props, state) {
    if(!props.departmentsData && !props.apiRequestManager.inProgress(actionTypes.FETCH_DEPARTMENTS)){
      props.apiRequestManager.queueRequest(actionTypes.FETCH_DEPARTMENTS);
    }

    return null;
  }

  shouldComponentUpdate(nextProps){
    /**
     * This runs the validation if something changed in departments list.
     */
    const prevList = this.props.departmentsData;
    const nextList = nextProps.departmentsData;

    if (Array.isArray(prevList) && Array.isArray(nextList) && !objListsAreEqual(prevList, nextList,'index')){
        const errors = this.validation(nextList);
        
        if(this.props.validator.hasActiveFormError(errors)){
            this.props.actions.enableSave(false);
        }else{
            this.props.actions.enableSave();
        }
    }

    return true;
}

  getPromptMessage(){
   const apiOutcome = this.props.apiRequestManager.getApiOutcome(actionTypes.DEPARTMENT_SUBMIT_DATA, true) ?? {};
   return getPromptMessageProps('department', apiOutcome);
  }

  onSaveHandler () {
    const payload = {
      requestType: REQUESTS_TYPE.SUBMIT,
      data: this.props.departmentsData
    }
    this.props.apiRequestManager.queueRequest(actionTypes.DEPARTMENT_SUBMIT_DATA, payload)
  }

  onConfirmDelete (department) {
    const payload = {
      requestType: REQUESTS_TYPE.DELETE,
      departmentId: department.id
    }
    this.props.apiRequestManager.queueRequest(actionTypes.DEPARTMENT_SUBMIT_DATA, payload)
  }

  createNewDepartment () {
    return {
      name: '',
      index: this.props.departmentsData ? this.props.departmentsData.length : 0,
    }
  }

  addNewDepartment() {
    let departments = [];
    if(this.props.departmentsData){
      departments = [...this.props.departmentsData];
    }

    departments.unshift(this.createNewDepartment());
    departments.sortArrayObjectByKey('index');

    this.props.setDepartmentsData(departments);
  }

  updateDepartmentData (data) {
    const departments = this.props.departmentsData.map(department =>{
      if(department.index === data.index){
        return data;
      }
      return department;
    });

    this.props.setDepartmentsData(departments);
    this.props.actions.enableSave();
  }

  validation(departments){
    return {
        ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.settings, VALIDATOR_DELEGATE_KEY_GROUP.settings.departments, departments)
    }
}

  deleteDepartmentItem (event, data) {
    event.preventDefault();
    if(data.id){
      this.props.showDeleteDialog('department', data);
    }else{
      const updatedDepartments = this.props.departmentsData.filter(department => department.index !== data.index);

      this.props.setDepartmentsData(updatedDepartments);
    }
  }

  render(){
    const departmentsData = Array.isArray(this.props.departmentsData) ? this.props.departmentsData : [];

    const dialogProps = this.getPromptMessage();
    const actions = {
      changeDepartmentData: this.updateDepartmentData,
      removeDepartment: this.deleteDepartmentItem,
    }

    return (
      <React.Fragment>
          { dialogProps.visible && 
            <PromptMessage {...dialogProps} />
          } 
          <div className='pane-layout-wrap pane department-pane'>
            <div className="pane-cont">
              <div className="setting-info">
                <p>Departments created here are used when creating or modifying an <b>Employee</b>.</p>
              </div>
              <div className="actions-btn">
                <AddButton actionHandler={this.addNewDepartment} label={'Add Department'} />
              </div>
              <Section className="departments-list">
                <List>
                  { departmentsData.map((item, i) => {
                      return <DepartmentItem 
                      key={i} 
                      data={item}
                      actions={actions} />
                  }) }
                </List>
              </Section>
            </div>
          </div>
          
      </React.Fragment>
  );
}
}


function DepartmentView(props){
  return (
    <Department {...props} />
  )
}

let ConfigDepartmentView = setupPage(Department.pageOption)(DepartmentView);
ConfigDepartmentView =  connect(mapStateToProps, mapDispatchToProps)(ConfigDepartmentView);

export { ConfigDepartmentView };