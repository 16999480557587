import { apiRequestCompletedAction, requestStart, apiResponseOutcome } from '../service/agentDialect';
import {apiSyncAbortAction} from '../store/actions';
import { store } from '../store/store';
import { getEnvironment } from '../service/agentDialect';

class ApiRequestManager {
    
    static _instance = null;
    _queuedRequests = [];
    _registerActions = {};
    _requestResponses = {};
    syncInProgress = false;
    dequeueTimeout = 0;
    timerId = null;
    dequeueScheduler = null;
    startTime = 0;
    timeout = 30000;
    removeReponseTimeout = 600;

    constructor() { 
        this.register = this.register.bind(this);
        this.queueRequest = this.queueRequest.bind(this);
        this.deregister = this.deregister.bind(this);
        this.inProgress = this.inProgress.bind(this);
        this.timeoutRequest = this.timeoutRequest.bind(this);
        this.terminateAll = this.terminateAll.bind(this);
        this.addApiResponse = this.addApiResponse.bind(this);
        this.getRequestTime = this.getRequestTime.bind(this);
        this.startDequeueTimeoutTask = this.startDequeueTimeoutTask.bind(this);
        this.startDequeueDaemon = this.startDequeueDaemon.bind(this);
    }

    retire() {
        this._queuedRequests = [];
        this._registerActions = {};
        this._requestResponses = {};
        ApiRequestManager._instance = null;
    }

    register(type, action) {
        this._registerActions[type] = action;
    }

    deregister(type) {
        delete this._registerActions[type];
    }

    terminateAll() {
        this._queuedRequests = [];
        this.dequeueRequest();
    }

    queueRequest(type, ...args) {
        
        const pendingRequest = this._queuedRequests.find((queueItem) => queueItem.actionType === type);

        if ( pendingRequest === undefined ) { 
            !this.syncInProgress && requestStart(); 
            const dispatchAction = this._registerActions[type];

            if (!dispatchAction)
                return;
            this.startTime = performance.now();
            dispatchAction.apply(this, args); 
            let queueItem = { actionType: type, inProgress: true , startTime: this.startTime };
            this._queuedRequests.push(queueItem); 
        }

        this.startDequeueDaemon();        
        this.startDequeueTimeoutTask();
    }

    startDequeueTimeoutTask() {

        if (this.timerId === null && this.dequeueScheduler) { 
            this.timerId = setTimeout(() => {
                this.timeoutRequest();
            }, this.timeout);
        }
    }

    startDequeueDaemon() {
        if (ApiRequestManager._instance.dequeueScheduler === null) {
            ApiRequestManager._instance.syncInProgress = true;
            this.dequeueScheduler = setInterval(this.dequeueRequest, this.dequeueTimeout);
        }
    }

    syncEnded(apiOutcome) {
        let { initiator, ...responseValues } = apiOutcome;

        for (const element of this._queuedRequests) {
            let queueItem = element;
            
            if (queueItem.actionType === initiator && queueItem.inProgress) {
                element.inProgress = false;
                responseValues.inProgress = false;
                this.addApiResponse(initiator, responseValues);
                break;
            }
        }

        this.stanityCheck();
    }

    stanityCheck() {
        if (!this.dequeueScheduler && this._queuedRequests.length === 1 && !this._queuedRequests.first().inProgress ) {
            this._queuedRequests =  [];
            apiRequestCompletedAction();
        }
    }

    timeoutAboutRequest() {
        if (this._queuedRequests.length > 0) {
            for (const element of this._queuedRequests) {
                const requestItem = element;
                if (this.getRequestTime(requestItem.startTime) > this.timeout ) {
                    this.abortRequest(requestItem.actionType);
                }
            }
            apiRequestCompletedAction();
            this._queuedRequests = [];
        }   
    }

    timeoutRequest = () => {
        
        if ( ApiRequestManager._instance.syncInProgress ) {
            clearTimeout(this.timerId);
            if (this._queuedRequests.length > 0) {
                apiRequestCompletedAction();
                this._queuedRequests = [];
            }   
        }
        ApiRequestManager._instance.syncInProgress = false;
    }

    getRequestTime = (startTime) => {
        const now = performance.now();
        let elapsed = now - startTime || this.startTime;
        elapsed = parseInt(elapsed);
        return elapsed;
    }

    inProgress(type) {
        let inFlight = false;
        for (const element of this._queuedRequests) {
            if (element.actionType === type ) {
                inFlight = element.inProgress
                break;
            }
        }
        return inFlight;
    }

    dequeueRequest() {
        const activeRequests = ApiRequestManager._instance._queuedRequests.filter((request) => request.inProgress === true);
        ApiRequestManager._instance._queuedRequests = activeRequests;
       
        //for (let i = 0; i < ApiRequestManager._instance._queuedRequests.length; i++) {
            //if (!ApiRequestManager._instance._queuedRequests[i].inProgress) {
            //    ApiRequestManager._instance._queuedRequests.splice(i, 1);
            //}
        //}
    
        if ( ApiRequestManager._instance._queuedRequests.empty() ) {
            if (getEnvironment() !== 'production') {
                console.log('Response time --> ', ApiRequestManager._instance.getRequestTime(), 'ms');
            }
            clearInterval(ApiRequestManager._instance.dequeueScheduler);
            clearTimeout(this.timerId);
            ApiRequestManager._instance.dequeueScheduler = null;
            ApiRequestManager._instance.syncInProgress = false;
            apiRequestCompletedAction();
            
        }
    }

    abortRequest(actionType) {
        for (let i = 0; i < ApiRequestManager._instance._queuedRequests.length; i++) {
            let queueItem = this._queuedRequests[i];
            if (queueItem.actionType === actionType && queueItem.inProgress) {
                this._queuedRequests[i].inProgress = false;
                ApiRequestManager._instance._queuedRequests.splice(i, 1);
                store.dispatch(apiSyncAbortAction(actionType));
                break;
            } 
        }
        //this.dequeueRequest();
    }

    addApiResponse(actionType, apiOutcome) {
        this._requestResponses[actionType] = apiOutcome;
        apiResponseOutcome(`${actionType}_OUTCOME`, apiOutcome);
    }

    removeApiResponse(actionType) {
        delete this._requestResponses[actionType];
    }

    clearApiResponse() {
        this._requestResponses = {};
    }

    getApiOutcome(actionType, remove=false) {
        
        if (this._requestResponses[actionType] === undefined) {
            return null;
        }else {
            const apiOutcome = this._requestResponses[actionType] ?? null;
            setTimeout(() => {
                remove && ApiRequestManager._instance.removeApiResponse(actionType);
            }, this.removeReponseTimeout);
            return apiOutcome;
        }
    }

    static getInstance = (classInstance) => ApiRequestManager._instance ? ApiRequestManager._instance : (ApiRequestManager._instance = new classInstance());
};

export { ApiRequestManager };