import React from 'react';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import { setupPage } from '../../../components/Layout/setupPage';
import * as actionTypes from '../../../store/actionType';
import * as actions from '../../../store/actions';
import { 
    Authority,
    Section,
    Button,
    RequisitionTable,
} from '../../../components';
import CreateRequisition from './CreateRequisition';
import { ApiRequestManager } from '../../../utility/ApiRequestManager';
import { PermissionEnforcer } from '../../../utility/PermissionEnforcer';
import { FormValidator } from '../../../utility/FormValidator';
import SlidingPane from "react-sliding-pane";
import { Reporting } from '../../Reports';
import { CloseButton } from '../../components/partials/Buttons';
import {SEARCH_SETTINGS } from '../../../config/searchable';

import './Requisition.style.scss';
import { getIconUrl } from '../../../common/images-catalogue';

const mapStateToProps = state => ({
    apiRequestManager: ApiRequestManager.getInstance(ApiRequestManager),
    permissionEnforcer: PermissionEnforcer.getInstance(PermissionEnforcer),
    validator: FormValidator.getInstance(FormValidator),
    workspace: state.global.workspace,
    reportResults: state.reports.reportResults,
    isGenerating: state.reports.isGenerating,
    ...state.specimen.requisitionsState,
    ...state.specimen.fetchRequisitionsState
});

const mapDispatchToProps = dispatch => ({
    fieldDidChange: (fieldset) => dispatch(actions.requisitionFormFieldChangeAction(fieldset)),
    getAllRequisitions: (payload) => dispatch(actions.getAllRequisitions(payload)),
    getAllClients: () => dispatch(actions.getAllAccountsAction()),
    getClients: () => dispatch(actions.getAllClientsAction()),
    submitData: (payload) => dispatch(actions.submitRequisitionAction(payload)),
    setRequisitionData: (data) => dispatch(actions.setRequisitionDataAction(data)),
    setRequisitionItem: (data) => dispatch(actions.setRequisitionItemAction(data)),
    setRequisitionPageNumber: (currentPage) => dispatch(actions.setRequisitionPageNumberAction(currentPage)),
    getRequisitionSpecimens: (requisitionId) => dispatch(actions.getRequisitionSpecimenRecords(requisitionId)),
    setFromView: (view) => dispatch(actions.specimenFormViewAction(view)),
    unLoad: () => dispatch(actions.requisitionViewUnLoadAction()),
    restModal: () => dispatch(actions.requisitionCreateViewUnLoadedAction()),
    submitRequisitionItem: (payload) => dispatch(actions.submitRequisitionItemAction(payload)),
    getRequisition: (requistionId) => dispatch(actions.getRequisitionAction(requistionId))
});


class Requisition extends React.Component {
    static pageOption = { 
        pageTitle: 'Create Sample',
        disableBodyScroll: true,
        searchContext: SEARCH_SETTINGS.entities.requisition.name,
    };
    
    constructor(props) {
        super(props);
        this.state = {
            showCreateModal: false,
            isPaneOpen:false,
            customize:true,
            reportIdentifier:'Report_RP11'
        };

        props.apiRequestManager.register(actionTypes.FETCH_REQUISITION, props.getRequisition);
        props.apiRequestManager.register(actionTypes.FETCH_REQUISITIONS, props.getAllRequisitions);
        props.apiRequestManager.register(actionTypes.FETCH_ACCOUNTS, props.getAllClients);
        props.apiRequestManager.register(actionTypes.REQUISITION_SUBMIT_DATA, props.submitData);
        props.apiRequestManager.register(actionTypes.FETCH_REQUISITION_SPECIMENS, props.getRequisitionSpecimens);
        this.props.apiRequestManager.register(actionTypes.REQUISITION_ITEM_SUBMIT_DATA, props.submitRequisitionItem);
    
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.showModalWithRequisition = this.showModalWithRequisition.bind(this);
        this.showReport = this.showReport.bind(this);
        this.closeReportingModal = this.closeReportingModal.bind(this);
        this.handleTableChange = this.handleTableChange.bind(this);

    }

    componentDidMount() {
        const requisitionLinkItem = this.props.requisitionLinkItem;

        if(!this.props.apiRequestManager.inProgress(actionTypes.FETCH_REQUISITION) && requisitionLinkItem && !this.props.requisition){
            this.props.apiRequestManager.queueRequest(actionTypes.FETCH_REQUISITION, requisitionLinkItem.requisitionId);
        }

        this.unlistenAbort = this.props.history.listen(() => { 
            this.props.apiRequestManager.abortRequest(actionTypes.FETCH_REQUISITIONS);
            this.props.apiRequestManager.abortRequest(actionTypes.FETCH_REQUISITION);
        });
    }

    componentWillUnmount() {
        this.props.unLoad();
        this.unlistenAbort();
    }
    
    static getDerivedStateFromProps(props, state) {
        // Return null to indicate no change to state.
        if (props.workspace && !props.requisitionsList && !props.apiRequestManager.inProgress(actionTypes.FETCH_REQUISITIONS)) {
            const payload = {
                page: props.previousRequisitionPage  || 0
            }
            
            props.apiRequestManager.queueRequest(actionTypes.FETCH_REQUISITIONS, payload);
        }

        if (props.showModal) {
            return {
                showCreateModal: props.showModal
            }
        }
        return null;
    }

    handleTableChange(tableOpt) {
        this.props.apiRequestManager.queueRequest(actionTypes.FETCH_REQUISITIONS, {page: tableOpt.page - 1});
    }

    showModal() {
        this.setState({showCreateModal: true});
    }

    showReport = (evt, data) => {
        this.setState({ isPaneOpen: true, customize: true });
    }

    hideModal(refreshView) {
        this.setState({showCreateModal: false});
        // Requisition view will unLoad if a new requsition was created in the 
        // Create requisition modal
        refreshView && this.props.setRequisitionPageNumber(0);
        refreshView && this.props.unLoad();
    }

    closeReportingModal(evt) {
        this.setState({ isPaneOpen: false, customize: false });
    }
    showModalWithRequisition(data) {
        this.props.setRequisitionData(data);
        this.showModal();
    }

    render() {

        const quickActionCallback = {
            'viewAction' : this.showModalWithRequisition,
        };

        const tableData =  !Object.blank(this.props.requisitionsList) ? this.props.requisitionsList.pageData : [];

        const tableSize =  !Object.blank(this.props.requisitionsList) ? this.props.requisitionsList.totalItems : 0;

        const currentPage =  !Object.blank(this.props.requisitionsList) ? this.props.requisitionsList.currentPage : 0;
      
        return <Fragment>
            <div className="spmt-Ly-ct-inner">
                <div className="spmt-title-d">
                    <h3 className="spmt-title">Requisitions</h3>
                </div>
            </div>
            <div className="spmt-v-lay-ct">
                <div className="content-well">
                    <Section className="recent-sec-l" style={{display:'flex',flexDirection:'row',}}>
                        <Authority allowed={ 'create' } entity="requisition" >
                        <div className="rc-create">
                            <Button className="create-btn" onClick={this.showModal}><span className="icon"><img src={getIconUrl('plus')} /></span>New Requisition</Button>
                        </div>
                        </Authority >
                        <Authority allowed={ 'view' } entity="report" >
                        <div className="print-req">
                            <Button className="create-btn" onClick={this.showReport}>Report</Button>
                        </div>
                        </Authority>
                    </Section>
                    <Section className="recent-sec-t">
                        <RequisitionTable 
                            tableData={tableData}
                            quickActions={quickActionCallback} 
                            totalSize={tableSize}
                            currentPage={currentPage + 1}
                            onTableChange={this.handleTableChange}
                            />
                    </Section>
                </div>
            </div>
            
            { this.state.showCreateModal && 
                <CreateRequisition 
                    {...this.props}
                    closeModal={this.hideModal}
                />
            }
             <SlidingPane
                    closeIcon={<CloseButton />}
                    isOpen={this.state.isPaneOpen}
                    title={ "Completed Requisition Report"}
                    from="bottom"
                    width="100%"
                    className="reporting-modal"
                    onRequestClose={this.closeReportingModal}
                >
                    <Reporting { ...this.props } customize={this.state.customize} reportIdentifier={this.state.reportIdentifier } restBackground  />
                </SlidingPane>
        </Fragment>
    }
};

const RequisitionView = (props) => {
    return (
        <Requisition {...props } />
    );
};

let ConfigRequisitionView = setupPage(Requisition.pageOption)(RequisitionView);
ConfigRequisitionView =  connect(mapStateToProps, mapDispatchToProps)(ConfigRequisitionView);

export default ConfigRequisitionView;