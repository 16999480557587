import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { Comparator, dateFilter } from 'react-bootstrap-table2-filter'
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import {  ContextMenuTrigger } from "react-contextmenu";
import PropType  from 'prop-types';

import _ from 'lodash';
import { InputField, List , NavLink} from '../index';
import { CLIENTS, FORM_TYPE, STATUS } from './../../common/constant';
import {pictureInitials} from './../../utility/helpers';
import { PaginationPanel, PaginationTotal } from './'
import { PAGE_SIZE } from '../../config/pagination';
import { getIconUrl } from '../../common/images-catalogue';

import {
    buildCustomSelectFilter,
} from './filters';

var INITIAL_PRODUCTS_COUNT = 15;
const sortCaret = (specimen) => {
    if (!specimen)
        return <i className="fa fa-fw fa-sort text-muted"></i>;
    if (specimen)
        return <i className={`fa fa-fw text-muted fa-sort-${specimen}`}></i>
}


const vialIcon = <svg viewBox="0 0 39.2 41" ><path d="M25.8,2.4c0-0.2-0.1-0.4-0.1-0.6C25.5,1,25,0.5,24.4,0.4L13,0.5c-0.9,0.1-1.5,0.9-1.5,1.8c0,2-0.1,4.1,0,6.2
c0,0.7,0.4,1.3,1.2,1.6c0.3,0.1,0.3,0.3,0.3,0.5l0.3,14.5c0,3.3-0.1,6.6,0,9.9c-0.1,2.1,1.2,4.1,3.2,4.9c0.4,0.2,1.1,0.3,1.8,0.4
s1.2-0.1,1.2-0.1h0.1c2.9-0.4,5-2.9,4.9-5.8c-0.1-5.4,0-10.9-0.1-16.3l-0.1-7.6c0-0.2-0.1-0.3,0.2-0.4c0.8-0.2,1.4-1,1.3-1.9
C25.9,6.2,25.8,4.3,25.8,2.4z M23.3,16h-9.2v-5.5h9.2V16z"/>
</svg>;

const slideIcon = <svg viewBox="0 0 39.2 41"><g>
<path d="M24.7,0.3H12.5c-0.9,0-1.7,0.8-1.7,1.7v36.4c0,0.9,0.8,1.7,1.7,1.7h12.2c0.9,0,1.7-0.8,1.7-1.7V2
    C26.4,1.1,25.6,0.3,24.7,0.3z M25,10.7c0,0.4-0.3,0.8-0.8,0.8H13c-0.4,0-0.8-0.3-0.8-0.8v-8c0-0.4,0.3-0.8,0.8-0.8h11.2
    c0.4,0,0.8,0.3,0.8,0.8V10.7z"/>
<path d="M22.4,3.9h-7.6c-0.4,0-0.7,0.3-0.7,0.7v0.3c0,0.4,0.3,0.7,0.7,0.7h7.6c0.4,0,0.7-0.3,0.7-0.7V4.6
    C23.1,4.2,22.8,3.9,22.4,3.9z"/>
<path d="M21.7,7.1h-6.9c-0.4,0-0.7,0.3-0.7,0.7v0.3c0,0.4,0.3,0.7,0.7,0.7h6.9c0.4,0,0.7-0.3,0.7-0.7V7.8
    C22.4,7.4,22.1,7.1,21.7,7.1z"/>
</g>
</svg>;

const resultSheetIcon = <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 30.91 41"><path d="M26.12,23.68v6.18c0,2.52,0,5-.08,7.56a2.71,2.71,0,0,1-2.93,2.85q-10.31,0-20.59,0c-1.24,0-1.67-.5-1.67-1.91,0-4.37,0-8.76,0-13.14V3.2C.84,1.3,1.15,1,3.09,1H27.28c1.94,0,2.34.39,2.34,2.28V19.82C29.72,23.71,28.26,23.51,26.12,23.68ZM3,3.56V38.23h19c1.55,0,1.95-.37,1.95-1.93V18.77l.64-.05c2.31-.06,2.47-.22,2.49-2.53V4.46c0-.28-.05-.56-.08-.9Z"/><path d="M12.56,5.18V9.46H5.71V5.18Z"/><path d="M6.8,15h16a.78.78,0,0,1,.77.77h0a.77.77,0,0,1-.77.77H6.8A.77.77,0,0,1,6,15.78H6A.78.78,0,0,1,6.8,15Z"/><path d="M7.13,20.38H18.85a.77.77,0,0,1,.77.77h0a.77.77,0,0,1-.77.77H7.13a.78.78,0,0,1-.77-.77h0A.78.78,0,0,1,7.13,20.38Z"/><path d="M7.13,25.86H18.85a.76.76,0,0,1,.77.77h0a.76.76,0,0,1-.77.76H7.13a.77.77,0,0,1-.77-.76h0A.77.77,0,0,1,7.13,25.86Z"/><path d="M7.13,31.34H18.85a.76.76,0,0,1,.77.76h0a.76.76,0,0,1-.77.77H7.13a.77.77,0,0,1-.77-.77h0A.77.77,0,0,1,7.13,31.34Z"/></svg>;


const generateRow = (data) => ({
    id: data.id,
    icon: data,
    patient: data,
    client: data,
    details: data,
    status: data
});

const cellIcon = (data) => {
    const status = data.statuses.last();
    const urgentClass = data.urgent && status.status !== STATUS.Approved ? '--urgent': '';
    const hasResultSheet = data.resultSheet;
    const attributes = {'data-specimenid': data.id};
    const sampleIcon = data.formType === FORM_TYPE.Gynec ? slideIcon : vialIcon;
    return (
        <ContextMenuTrigger id="specimen_table_context_menu" attributes={attributes}>
        <div className="cell-icon">
            <div className={`cell-icon-holder ${urgentClass}`}>
                <div className={`icon ${hasResultSheet ? '--result-sheet': '--default'}`}>
                    { hasResultSheet ? resultSheetIcon : sampleIcon }
                </div>
            </div>
        </div>
        </ContextMenuTrigger>
    );
}

const patientDetails = (data) => {
    const {patient} = data;
    const attributes = {'data-specimenid': data.id};

    const picture = patient.picture ? patient.picture.fileDownloadUri : pictureInitials(patient.firstName, patient.lastName);

    const hasPicture = patient.picture ? true : false;

    return (
        <ContextMenuTrigger id="specimen_table_context_menu" attributes={attributes}>
        <div className="patient-info">
            <div className="patient-info-holder">
                <div className="user-picture">
                    <div className="picture-holder">
                    {hasPicture ? 
                        <div className="picture-content">
                            <img className="picture" src={picture} alt='' />
                        </div>
                        :
                        <div className="picture-content">
                            { picture }
                        </div>
                    }
                    </div>
                </div>
                <div className="patient-data">
                    <h4>{ `${patient.firstName} ${patient.lastName}`}</h4>
                    <p><span>Reg. No.: {patient.registrationNo}</span></p>
                </div>
            </div>
        </div>
        </ContextMenuTrigger>
    );
}

const clientInfo = (data) => {
    const {client} = data;
    const clientName = client.type === CLIENTS.Lab && client.officeName.empty() ? `${client.type === CLIENTS.Doctor ? 'Dr. ' : ''}${client.firstName} ${client.lastName}` : client.officeName;
    const attributes = {'data-specimenid': data.id};

    return (
        <ContextMenuTrigger id="specimen_table_context_menu" attributes={attributes}>
        <div className="client-info">
            <div className="client-info-holder">
                <div className="client-info-content">
                    <h4>{clientName}</h4>
                    <p className="info">{client.accountNo}</p>
                </div>
            </div>
        </div>
        </ContextMenuTrigger>
    );
}

const specimenDeatils = (data) => {
    const {specimens} = data;
    //return function with cell content
    const firstSpecimen = !specimens.empty() && specimens[0];
    const label = firstSpecimen.specimenLabel;
    const reference = !data.labNumber.empty()? data.labNumber : data.identifier;

    //<p className="blood-type">Blood:<span>{bloodType} </span> <b>{count}</b></p>
    const attributes = {'data-specimenid': data.id};
    return (<div className="spmt-details">
            <ContextMenuTrigger id="specimen_table_context_menu" attributes={attributes}>
            <div className="spmt-details-holder">
                <div className="spmt-details-content">
                    <p>
                        <span className="sample-info">
                            {!data.labNumber.empty() ? <i>lab#:</i> : <i>ref:</i>} 
                            {reference}
                        </span>
                    </p>
                    <span className="sample-info info"><i>SP:</i>{label}</span>
                    
                </div>
            </div>
            </ContextMenuTrigger>
        </div>);
}

const status = (actions) => {
    //return function with cell content
    
    return (data) => {
        const {statuses} = data;
        const lastStatus = !statuses.empty() ? statuses[statuses.length -1] : null;
        const statusClassname = lastStatus ? `--${lastStatus.status.toLowerCase()}` : '';
        const statusDate = lastStatus ? lastStatus.date : 'dd mm yyyy';
        const attributes = {'data-specimenid': data.id};
       
        return <div className="spmt-status">
            <ContextMenuTrigger id="specimen_table_context_menu" attributes={attributes}>
            <div className="toolbar">
                <List className="action-menu">
                    <li className="action-item">
                        <NavLink {...attributes} onClick={ actions.editAction }>
                            <span className="icon"><img className="icon" src={getIconUrl("edit")}/></span>
                        </NavLink>
                    </li>
                    <li className="action-item">
                        <NavLink>
                            <span className="icon"><img className="icon" src={getIconUrl("option")}/></span>
                        </NavLink>
                    </li>
                </List>
            </div>
            
            <div className="spmt-status-holder">
                <div className="spmt-status-content">
                    <span className={`status-indicator ${statusClassname}`}>{ lastStatus.status }</span>
                    <p className="date">{ statusDate }</p>
                </div>
            </div>
            </ContextMenuTrigger>
        </div>
    };
}

class SpecimenTable extends React.Component { 
    static propsTypes = {
        tableData: PropType.array.require,
        actionResponsers: PropType.object,
    }

    static defaultProps = {
        actionResponsers: {
            editButton: null,
        }
    }

    constructor(props) {
        super(props);
        
        this.state = {
            //tableData: _.times(props.limit ? props.limit : INITIAL_PRODUCTS_COUNT, generateRow),
            selected: []
        };
        
        this.willEnterRow = this.willEnterRow.bind(this);
        this.didContextClick = this.didContextClick.bind(this);
        this.createColumnDefinitions = this.createColumnDefinitions.bind(this);
        this.onTableChange = this.onTableChange.bind(this);
    }

    createColumnDefinitions(quickActions) { 
        return [
            
            {
                dataField: 'icon',
                text: "",
                headerAttrs: {
                    className: 'th-icon-cell',
                    
                },
                classes: 'id-icon-cell td-cell',
                formatter: cellIcon
            },
            {
                dataField: 'patient',
                text: "Patient",
                headerAttrs: {
                    className: 'th-patient-cell',
                    hidden: false
                },
                classes: 'id-patient-cell td-cell',
                formatter: patientDetails
            },
            {
                dataField: 'client',
                text: "Client",
                headerAttrs: {
                    className: 'th-client-cell',
                    hidden: false
                },
                classes: 'id-client-cell td-cell',
                formatter: clientInfo
            },
            {
                dataField: 'details',
                text: "Details",
                headerAttrs: {
                    className: 'th-details-cell',
                    hidden: false
                },
                classes: 'id-details-cell td-cell',
                formatter: specimenDeatils,
                sortCaret,
                sort: true,
                ...buildCustomSelectFilter({
                    placeholder: 'Filter by From Type',
                    options: [
                        { value: 'Gynecology', label:  FORM_TYPE.Gynec },
                        { value: 'NonGynecology', label: FORM_TYPE.NonGynec },
                    ],
                    getFilter: (filter) => { 
                        this.statusFilter = filter; 
                    },
                    onFilter: (value) => {
                        //if no real option was selected.
                        if(!value) return;

                        const filteredList = this.props.tableData.map(specimen => generateRow(specimen)).filter(rowData => {
                            return rowData.details.formType === value;
                        });

                        return filteredList;
                    }
                })
                
            },
            {
                dataField: 'status',
                text: "Status",
                headerAttrs: {
                    className: 'th-status-cell',
                    hidden: false
                },
                classes: 'id-status-cell td-cell',
                formatter: status( quickActions ),
                sort: true,
                sortCaret,
                ...buildCustomSelectFilter({
                    placeholder: 'Filter Status',
                    options: [
                        { value: 'Pending', label: STATUS.Pending },
                        { value: 'Submitted', label: STATUS.Submitted },
                        { value: 'Processing', label: STATUS.Processing },
                        { value: 'Completed', label: STATUS.Completed },
                        { value: 'Resulted', label: STATUS.Resulted },
                        { value: 'Approved', label: STATUS.Approved }
                    ],
                    getFilter: (filter) => { 
                        this.statusFilter = filter; 
                    },
                    onFilter: (value) => {
                        //if no real option was selected.
                        if(!value) return;

                        const filteredList = this.props.tableData.map(specimen => generateRow(specimen))
                        .filter(rowData => {
                            const statusLength = Array.isArray(rowData.status.statuses) ? rowData.status.statuses.length : 0;

                            //if for some reason statuses wasn't an array
                            if(statusLength === 0) return false;

                            return rowData.status.statuses[statusLength - 1].status === value;
                        });

                        return filteredList;
                    }
                })
                }
        ];

    }

    handleSelect() { }

    handleSelectAll() { }

    handleOnChange() {}

    willEnterRow (evt, row, rowIndex) {
        //return;
         const specimen = this.props.tableData.find((item) => {
            if (item.id === row.id ) {
                return item;
            }
        });
        this.props.hoverOverRowAction({specimen: specimen});
    }

    didContextClick (evt, row, rowIndex) {
        evt.preventDefault();
    }

    onTableChange(evt, opt) {
        this.props.onTableChange && this.props.onTableChange(opt);
    }

 
    render () {

        const columnDefs = this.createColumnDefinitions(this.props.quickActions);
        const paginationDef = paginationFactory({
            paginationSize: 5,
            sizePerPage: this.props.metaData?.pageSize || PAGE_SIZE,
            showTotal: true,
            totalSize: this.props.metaData?.totalItems || 0,
            page: this.props.metaData?.currentPage + 1 || 0,
            pageListRenderer: (props) => (
                <PaginationPanel { ...props } size="sm" className="ml-md-auto mt-2 mt-md-0" />
            ),
            paginationTotalRenderer: (from, to, size) => (
                <PaginationTotal { ...{ from, to, size } } />
            )
        });

        const selectRowConfig = {
            mode: 'checkbox',
            selected: this.state.selected,
            onSelect: this.handleSelect.bind(this),
            onSelectAll: this.handleSelectAll.bind(this),
            selectionRenderer: ({ mode, checked, disabled }) => (
                <InputField onChange={this.handleOnChange} id="" type={ mode } checked={ checked } disabled={ disabled } />
            ),
            selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
                <InputField onChange={this.handleOnChange} id="" type={ mode } checked={ checked } innerRef={el => el && (el.indeterminate = indeterminate)} />
            )
        };

        const rowEventsHandlers = {
            onContextMenu: this.didContextClick,
            onMouseEnter: this.willEnterRow
        };

        var options = {
            noDataMessage: () => 'No specimens found.'
        };

        const specimensData = this.props.tableData.map(specimen => generateRow(specimen));

        return (
            <ToolkitProvider
                keyField="id"
                data={ specimensData }
                columns={columnDefs} >
                {
                    props => (
                        <React.Fragment>
                            <BootstrapTable
                            id={this.props.id}
                            remote={{ pagination: this.props.pagable}}
                            classes="table-responsive specimens"
                            pagination={ this.props.pagable ? paginationDef : null }
                            filter={ filterFactory() }
                            selectRow={ selectRowConfig }
                            bordered={ false }
                            responsive
                            { ...props.baseProps }
                            rowEvents={ rowEventsHandlers }
                            loading={ true } 
                            noDataIndication={options.noDataMessage}
                            onTableChange={this.onTableChange}
                        />
                        </React.Fragment>
                    )
                }
            </ToolkitProvider>
        );
    }
}
SpecimenTable.propsTypes = {
    id: PropType.string.require
}
export { SpecimenTable }