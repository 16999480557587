import React from 'react';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import { setupPage } from '../../components/Layout/setupPage';
import * as actionTypes from '../../store/actionType';
import  * as actions  from '../../store/actions';
import * as urls from '../../config/urls';
import  { PermissionEnforcer }  from '../../utility/PermissionEnforcer';
import  { SpecimenSupervisor }  from '../../utility/SpecimenSupervisor';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
import { ReportEntry } from './../components/partials/resultsheet/ReportEntry';
import { ResultEntry } from './../components/partials/resultsheet/ResultEntry';
import { ResultSheetPrintTemplate } from '.';
import ReactToPrint,  { PrintContextConsumer } from 'react-to-print';
import {PrintBar} from './../components/partials/PrintBar';
import { PromptMessage, getPromptMessageProps } from '../components/partials/PromptMessage';
import './ResultSheet.style.scss';
import { Loader } from '../components/partials/LoadingIndicator';
import { ErrorMessage } from '../components/partials/ErrorMessage';
import { 
    Section,
    Container,
    Row,
    Col,
    List,
    Button,
} from '../../components';

import { getIconUrl } from '../../common/images-catalogue';
import { REQUESTS_TYPE } from '../../common/constant';

const mapStateToProps = state => ({
    apiRequestManager: ApiRequestManager.getInstance(ApiRequestManager),
    useClientLayout: state.global.useClientDashboard,
    authorizeUser: state.global.authorizeUser,
    mode:  state.global.actionBarMode,
    workspace: state.client.workspace,
    ...state.specimen.samplesState,
    ...state.settings.formSetting,
    resultSheetState: state.specimen.resultSheetState,
    codeSheets: state.global.codeSheets,
    codeFindings: state.global.codeFindings,
    specimenSupervisor : SpecimenSupervisor.getInstance(SpecimenSupervisor)
});

const mapDispatchToProps = dispatch => ({
    onRedirect: (to) => dispatch(actions.redirectToAction(to)),
    onLoad: () => dispatch(actions.specimenResultSheetLoadedAction()),
    unLoad: () => dispatch(actions.specimenResultSheetUnLoadAction()),
    getCodeSheets: () => dispatch(actions.getCodeSheetsAction()),
    getCodeFindings: () => dispatch(actions.getCodeFindingsAction()),
    addResultEntry: (entry) => dispatch(actions.addResultEntry(entry)),
    addReport: (reports) => dispatch(actions.addResultSheetReport(reports)),
    discardReport: (report) => dispatch(actions.discardResultSheetReport(report)),
    setResultSheet: (resultSheet) => dispatch(actions.setSpeimenResultSheet(resultSheet)),
    submitData: (payload) => dispatch(actions.submitResultSheet(payload)),
    getPrintGroups: (payload) => dispatch(actions.getPrintGroups(payload)),
});

class ResultSheet extends React.Component {

    static pageOption = {
        pageTitle: 'Result Sheet',
        disableBodyScroll: true
    };

    constructor(props) {
        super(props);
        
        props.apiRequestManager.register(actionTypes.FETCH_PRINT_GROUPS, props.getPrintGroups);
        props.apiRequestManager.register(actionTypes.FETCH_CODE_SHEETS, props.getCodeSheets);
        props.apiRequestManager.register(actionTypes.FETCH_CODE_FINDINGS, props.getCodeFindings);
        props.apiRequestManager.register(actionTypes.RESULT_SHEET_SUBMIT, props.submitData);

        this.state = { 
            mode: 'create',
            buttonText: 'create',
            reports : [],
            reportsErrors: {
                emptyReports: false,
                incompleteReports: false 
            }
        }
        
        Object.blank(this.props.specimenRecord) && this.props.onRedirect(urls.specimensUri);

        this.addNewReport = this.addNewReport.bind(this);
        this.discardReportAction = this.discardReportAction.bind(this);
        this.didSelectCodeSheet = this.didSelectCodeSheet.bind(this);
        this.textFieldChange = this.textFieldChange.bind(this);
        this.willEnterTextField = this.willEnterTextField.bind(this);
        this.didLeaveTextField = this.didLeaveTextField.bind(this);
        this.submitFormData = this.submitFormData.bind(this);
        this.approveResultSheet = this.approveResultSheet.bind(this);
        this.generateResultEntries = this.generateResultEntries.bind(this);
        
        props.specimenSupervisor.clientDashboard = this.props.useClientLayout;
    }

    componentDidMount() {
        this.props.specimenSupervisor.hasResultSheet() && this.setResultSheetData(this.props.specimenRecord.resultSheet);

        document.body.classList.add('result-sheet');
        this.props.specimenRecord.resultSheet && this.setState({mode: 'update', buttonText: 'save'});

        if (!this.props.apiRequestManager.inProgress(actionTypes.FETCH_CODE_SHEETS)) {
            this.props.apiRequestManager.queueRequest(actionTypes.FETCH_CODE_SHEETS);
        }
        if (!this.props.apiRequestManager.inProgress(actionTypes.FETCH_CODE_FINDINGS)) {
            this.props.apiRequestManager.queueRequest(actionTypes.FETCH_CODE_FINDINGS);
        } 
    }

    componentWillUnmount() {
        this.props.pageConfig.disableBodyScroll(true);
        document.body.classList.remove('result-sheet');
        this.props.unLoad();
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        
        const formGroupId = nextProps.specimenRecord.clinicalFeatures ? nextProps.specimenRecord.clinicalFeatures.find(feature => feature?.formGroupId !== null)?.formGroupId : null;

        if(nextProps.codeSheets && !nextProps.printGroups && 
            !nextProps.apiRequestManager.inProgress(actionTypes.FETCH_PRINT_GROUPS) && formGroupId) {
       
                const payload = {
                    requestType: REQUESTS_TYPE.FETCH,
                    formGroupId,
                }
                nextProps.apiRequestManager.queueRequest(actionTypes.FETCH_PRINT_GROUPS, payload);
            }

        nextProps.specimenSupervisor.specimen = nextProps.specimenRecord;
        if (nextProps.specimenSupervisor.specimen.resultSheet ) {
            return {
                mode: 'update', buttonText: 'save'
            }
        }
        return null;
    }

    setResultSheetData(resultSheet, setStoreData=true) {
        const {resultEntries, ...newResultSheet} = resultSheet;
        newResultSheet.resultEntries = {}
       
        if (resultEntries instanceof Array) {
            for(const entry of resultEntries) {
                newResultSheet.resultEntries[entry.specimen] = entry.resultLines.map((item, i) => ({
                    index: i,
                    ...item
                }));
            }
            resultSheet = newResultSheet;
        }
        
        setStoreData = this.props.setResultSheet(resultSheet);
        return newResultSheet
    }
    
    addNewReport = () => {
        let reports = this.props.resultSheet && this.props.resultSheet.reports ? this.props.resultSheet.reports : [];
        const reportEntry = {
            index: reports.length + 1,
            content: '',
            authorizer: this.props.authorizeUser.id,
            error: false,
            interaction: 0
        };

        reports.push(reportEntry);
        this.props.addReport(reports);
        this.setState({reportsErrors: {
            ...this.state.reportsErrors,
            emptyReports: false
        }});
    }

    didSelectCodeSheet = (data) => {
        this.props.addResultEntry(data);
    }

    discardReportAction = (report) => {
        
        let filteredReports = this.props.resultSheet.reports.filter((item) => report.index !== item.index || item.id !== report.id );
        filteredReports = filteredReports.map((item, index) => {
            item.index = index;
            return item;
        })
        this.props.discardReport(filteredReports);

        this.setState({reportsErrors: {
            ...this.state.reportsErrors,
            incompleteReports: false
        }});
    }

    textFieldChange = (evt, report) => {
       
        const updatedReport = this.props.resultSheet.reports.find((item) => report.index === item.index );
        updatedReport.content = evt.target.innerText;
     
        const reports = this.props.resultSheet.reports.map((item)=> {
            return item.index === updatedReport.index ? updatedReport : item
        });
        this.props.addReport(reports);

        this.setState({reportsErrors: {
            ...this.state.reportsErrors,
            incompleteReports: false
        }});
    }

    willEnterTextField = (report) => {
        
        const updatedReport = this.props.resultSheet.reports.filter((item) => report.index === item.index ).first();
        updatedReport.interaction += 1;
       
        const reports = this.props.resultSheet.reports.map((item)=> {
            return item.index === report.index ? updatedReport : item;
        });
        this.props.addReport(reports);
    }

    didLeaveTextField = (report) => {

        const updatedReport = this.props.resultSheet.reports.filter((item) => report.index === item.index ).first();
        updatedReport.error = report.interaction && report.content.empty() ? true : false;
       
        const reports = this.props.resultSheet.reports.map((item)=> {
            return item.index === report.index ? updatedReport : item;
        });
        this.props.addReport(reports);
    }

    generateResultEntries(){
        const resultEntriesKeys = Object.keys( this.props.resultSheet.resultEntries);
            
            const resultEntries = resultEntriesKeys.map((key) => {
                const entry = {
                    [key]: this.props.resultSheet.resultEntries[key].map((codeSheet) => ({
                        id: codeSheet.id,
                        code: codeSheet.code,
                        abbreviation: codeSheet.abbreviation,
                        description: codeSheet.description,
                        findings: codeSheet.findings,
                        abnormalFinding: codeSheet.abnormalFinding
                    }))
                }
                return entry;
            });

        return resultEntries;
    }

    submitFormData() {
        const permissionEnforcer = PermissionEnforcer.getInstance(PermissionEnforcer);

        if (permissionEnforcer.isUserAuthorizer()) {
            this.saveResultSheetReports();
        }else if (this.props.resultSheet && 
            !Object.blank(this.props.resultSheet.resultEntries)) {
             
            const formData = {
                requestType: this.props.specimenSupervisor.hasResultSheet() ? 'update' : 'create',
                specimenRecord: this.props.specimenRecord.id,
                resultEntries: this.generateResultEntries()
            }; 
            
            if (this.props.specimenSupervisor.hasResultSheet()) {
                formData['resultSheetId'] = this.props.specimenSupervisor.specimen.resultSheet.id;
            }

            this.props.apiRequestManager.queueRequest(actionTypes.RESULT_SHEET_SUBMIT, formData);
        }
    }

    saveResultSheetReports() {
       // [1] Check for not reports
       if (this.props.specimenRecord.resultSheet && 
            this.props.resultSheet.reports.empty()) {
                this.setState({reportsErrors: {
                    ...this.state.reportsErrors,
                    emptyReports: true
                }});
            return;
        }

        // [2] Check if any reports has messing description
        const hasIncompleteReports = this.props.resultSheet.reports.filter((report) => report.content.empty());
        if ( !hasIncompleteReports.empty() ) {

            const reports = this.props.resultSheet.reports.map((report)=> {
                if (report.content.empty()) {
                    report.error = true;   
                }
                return  report;
            });
            this.props.addReport(reports);

            this.setState({reportsErrors: {
                ...this.state.reportsErrors,
                incompleteReports: true
            }});
            return;
        }
        // [3] Submit Reports
        const formData = {
            requestType: 'report',
            resultSheetId: this.props.specimenRecord.resultSheet.id,
            reports: this.props.resultSheet.reports.map((report) => {
                let {id, content, authorizer, authorizerReference} = report;
                report = {
                    content,
                    authorizer,
                    authorizerReference
                }
                if (id) {
                    report.id = id;
                }
                return report;
            }),
            resultEntries: this.generateResultEntries(),
            authorizer: this.props.authorizeUser.id
        }; 

        this.props.apiRequestManager.queueRequest(actionTypes.RESULT_SHEET_SUBMIT, formData);
    }

    approveResultSheet() {
        this.props.specimenSupervisor.approveResultSheet(this.props.apiRequestManager, actionTypes.RESULT_SHEET_SUBMIT);
    }

    getPromptMessage() {
        let dialogProps  = {
            visible: false,
            message: "",
        }

        let apiOutcome =   this.props.apiRequestManager.getApiOutcome(actionTypes.RESULT_SHEET_SUBMIT, true) ?? {};
        
        switch(apiOutcome.requestType) {
            case 'create':
                if (apiOutcome.error) {
                    dialogProps.error = true;
                    dialogProps.message = "Unable to create Result Sheet."
                    dialogProps.visible = true;
                }
                if (apiOutcome.success) {
                    dialogProps.success = true;
                    dialogProps.message = "Successfully added Result Sheet."
                    dialogProps.visible = true;
                }
                break;
            case 'update':
                if (apiOutcome.error) {
                    dialogProps.error = true;
                    dialogProps.message = "Unable to update Result Sheet."
                    dialogProps.visible = true;
                }
                if (apiOutcome.success) {
                    dialogProps.info = true;
                    dialogProps.message = "Successfully updated Result Sheet."
                    dialogProps.visible = true;
                }
                break;
            case 'approve':
                if (apiOutcome.success) {
                    dialogProps.success = true;
                    dialogProps.message = "Successfully approved Result Sheet."
                    dialogProps.visible = true;
                }
                break;
            case 'report':
                if (apiOutcome.success) {
                    dialogProps.info = true;
                    dialogProps.message = "Successfully change Report"
                    dialogProps.visible = true;
                }
                break;
            default:
                dialogProps.info = true;
        }
        
        return  dialogProps;
    }

    getErrorResponse = () => {
        if ( this.state.reportsErrors.emptyReports ) {
            return <ErrorMessage message='Please add a Report entry' />
        }

        if ( this.state.reportsErrors.incompleteReports ) {
            return <ErrorMessage message='One or more of your report entry is missing report details' />
        }
    }

    render() {
        const specimenSupervisor = this.props.specimenSupervisor;
        const formName = this.props.specimenRecord.formType === "Gynecology" ? "Gynecology" : "Non Gynecology";
        const isApproved = this.props.specimenRecord.resultSheet && this.props.specimenRecord.resultSheet.approved;
        
        let reportsData = this.props.resultSheet && this.props.resultSheet.reports ? this.props.resultSheet.reports : specimenSupervisor.getReports();
        reportsData  = reportsData.map((report, index) => {
            report.index = index;
            return report;
        });

        const patientDetails = {
            registerNo: this.props.specimenRecord.patient.registrationNo,
            name: `${this.props.specimenRecord.patient.firstName} ${this.props.specimenRecord.patient.lastName}`,
            gender: this.props.specimenRecord.patient.gender,
            age: this.props.specimenRecord.patient.age,
            email: this.props.specimenRecord.patient.email,
        }

        const clientDetails = {
            accountNo: this.props.specimenRecord.client.accountNo,
            name: `${this.props.specimenRecord.client.firstName} ${this.props.specimenRecord.client.lastName}`,
            type: this.props.specimenRecord.client.type,
            labcode: this.props.specimenRecord.client.labCode,
            office: this.props.specimenRecord.client.officeName || '--',
        }

        const canApprove = specimenSupervisor.hasReports() &&  !reportsData.empty() && !specimenSupervisor.isApproved()  ? false: true;

        const showSaveButton =  specimenSupervisor.canCreateResultEntry() || (specimenSupervisor.hasResultEntries() && specimenSupervisor.isAuthoriable()) ? true: false;

        const isFetchingPrintGroups = this.props.apiRequestManager.inProgress(actionTypes.FETCH_PRINT_GROUPS);

        const dialogProps = this.getPromptMessage();

        return <Fragment>
            <div className='content-layout-wrap'>
                { dialogProps.visible && 
                    <PromptMessage {...dialogProps}/>
                }
                <div className="view-layout-content --with-act-bar">
                    <div className="default-Ly-ct">
                        <Container>
                            
                            <Row>
                                <Col sm={12} md={12}>
                                <div className="alt-act-bar">
                                </div>   
                                <div className="rslt-sht-lay">
                                    <ReactToPrint content={() => this.componentRef}>
                                    <PrintContextConsumer>
                                    {({ handlePrint }) => (
                                        isApproved ? 
                                        <PrintBar handlePrint={handlePrint} isFetchingPrintGroups={isFetchingPrintGroups} /> :  null
                                    )}
                                </PrintContextConsumer>
                                    <div className="rslt-sht-cont" >
                                        <div className="innet-cont printable-area" >
                                            <div className="apprv-wrap">
                                                <h1>{formName}</h1>
                                                { !isApproved ? null : 
                                                <div className="apprv-hldr">
                                                    <div className="approved"><h3>Approved</h3><img src={getIconUrl('approvedGreen')} alt=""/></div>
                                                    <div className="apprv-date">{specimenSupervisor.getAppovedDate()}</div>
                                                </div>
                                                }
                                                { !specimenSupervisor.showUrgentBadge() ? null :
                                                <span className="urgent-badge">Urgent</span>
                                                }
                                            </div>
                                            
                                            <Section className="patient-client-sec">
                                                
                                                <div className="sec-cont -pat-clnt">
                                                    <Row>
                                                        <Col md={6} 
                                                        className="patient-details">
                                                            <h2 className="title">Patient</h2>
                                                            <p className="info-line">
                                                                <span className="label">Reg No.</span><span>{patientDetails.registerNo}</span>
                                                            </p>
                                                            <p className="info-line">
                                                                <span className="label">Name</span><span>{patientDetails.name}</span>
                                                            </p>
                                                            <p className="info-line">
                                                                <span className="label">Gender</span><span>{patientDetails.gender}</span>
                                                                <span className="label compact">Age</span><span>{patientDetails.age}</span>
                                                            </p>
                                                            <p className="info-line">
                                                                <span className="label">Email</span><span>{patientDetails.email}</span>
                                                            </p>
                                                        </Col>
                                                        <Col md={6} className="client-details">
                                                        <h2 className="title">Client</h2>
                                                            <p className="info-line">
                                                                <span className="label">Account</span><span>{clientDetails.accountNo}</span>
                                                            </p>
                                                            <p className="info-line">
                                                                <span className="label">Name</span><span>{clientDetails.name}</span>
                                                            </p>
                                                            <p className="info-line">
                                                                <span className="label">Office</span><span>{clientDetails.office}</span>
                                                            </p>
                                                            <p className="info-line">
                                                                <span className="label">Type</span><span>{clientDetails.type}</span>
                                                                <span className="label compact">Labcode</span><span>{clientDetails.labcode.code}</span>
                                                                
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Section>
                                            <Section className="specimens-sec">
                                                <h2 className="title">Specimens</h2>
                                                <div className="sec-cont -specimens">
                                                    <List>
                                                        { this.props.specimenRecord.specimens.map((specimen, i) =>
                                                            <li key={i}
                                                            className="spmt-entry">
                                                                <ResultEntry 
                                                                data={specimenSupervisor.getCodeSheetResult(specimen.id)}
                                                                specimenData={specimen}
                                                                codeSheetsHandler={this.didSelectCodeSheet}
                                                                codeSheetsData={this.props.codeSheets}
                                                                findingsData={this.props.codeFindings}
                                                                specimenSupervisor={specimenSupervisor}
                                                                formType={this.props.specimenRecord.formType}
                                                                 />
                                                            </li>
                                                         )}
                                                        
                                                    </List>
                                                </div>
                                            </Section>
                                            { !specimenSupervisor.canViewReports()? null :
                                            <Section className="report-sec">
                                            
                                                <h2 className="title">Reports</h2>

                                                { this.getErrorResponse()  }
                                            
                                                <div className="sec-cont report">
                                                    <div className="report-cont">
                                                        <div className="report-list">
                                                            {
                                                                reportsData.map((item, i) => {
                                                                    return <ReportEntry 
                                                                    key={i}
                                                                    data={item}
                                                                    textFieldChange={this.textFieldChange}
                                                                    onFocus={this.willEnterTextField}
                                                                    onBlur={this.didLeaveTextField}
                                                                    authorizer={this.props.authorizeUser}
                                                                    discardReportHandler={this.discardReportAction}
                                                                    specimenSupervisor={specimenSupervisor}
                                                                     />
                                                                })
                                                            } 
                                                        </div>
                                                        {!specimenSupervisor.isAuthoriable()? null : 
                                                        <div className="report-well">
                                                            <button 
                                                            onClick={this.addNewReport} className="add-report" >
                                                                <span>+</span>
                                                            Add Report</button>
                                                        </div>
                                                        }
                                                    </div>
                                                </div>
                                            </Section>
                                            }
                                            
                                            <Section className="actions-sec">
                                                <div className="btn-group">
                                                    { this.props.resultSheetState.inProgress && 
                                                    <Loader inline visible={true} loadingText={''} />
                                                    }
                                                    { !showSaveButton ? null : 
                                                        <Button onClick={this.submitFormData} className="add-btn">{this.state.buttonText}</Button> 
                                                    }
                                                    { !specimenSupervisor.isAuthoriable() ? null : !specimenSupervisor.isApproved() &&
                                                    <Button disabled={canApprove}
                                                    onClick={this.approveResultSheet}
                                                    className="auth-btn">
                                                        <span className="icon">
                                                            <img src={getIconUrl('approvedWhite')} alt=""/>
                                                        </span>Authorize</Button>
                                                    }
                                                </div>
                                            </Section>
                                            
                                        </div>
                                    </div>
                                    <ResultSheetPrintTemplate 
                                    specimenSupervisor={specimenSupervisor}
                                    specimenRecord={this.props.specimenRecord}
                                    printGroups={this.props.printGroups}
                                    ref={el => (this.componentRef = el)} />
                                    </ReactToPrint>
                                </div>
                                </Col>
                            </Row>
                         </Container>
                    </div>
                </div>
            </div>
        </Fragment>
    }
};

//!specimenSupervisor.canCreateResultEntry() ?  !hRS && !canRp ? null :  <button>

const ResultSheetView = (props) => {
    return (
        <ResultSheet {...props } />
    );
};

let ConfigResultSheetView = setupPage(ResultSheet.pageOption)(ResultSheetView);
ConfigResultSheetView =  connect(mapStateToProps, mapDispatchToProps)(ConfigResultSheetView);

export  { ConfigResultSheetView};