import React,{useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
    EmptyLayout, 
    ThemeConsumer,
    FormGroup,
    Button,
    List,
    Input,
    ValidateField,
    ErrorFeedback
} from '../../components';
import { SAVED_USERS_KEY } from './../../common/constant';
import Base64 from '../../utility/Base64Encoder';

import './Login.style.scss';
import { HeaderAuth } from '../components/partials/HeaderAuth';
import * as actionTypes from '../../store/actionType';
import * as actions from '../../store/actions';
import * as urls from './../../config/urls';
import { withPageConfig } from '../../components/Layout';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
import { FormValidator } from '../../utility/FormValidator';
import { ErrorMessage } from '../components/partials/ErrorMessage';
import LoadingIndicator from "../../pages/components/partials/LoadingIndicator";
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { getIconUrl } from '../../common/images-catalogue';

const mapStateToProps = state => ({
    apiRequest: ApiRequestManager.getInstance(ApiRequestManager),
    token: state.global.token,
    savedUsersList : window.localStorage.getMap(SAVED_USERS_KEY),
    validator: FormValidator.getInstance(FormValidator),
    ...state.auth,
});

const mapDispatchToProps = dispatch => ({
    onSubmit: (username, password) => dispatch(actions.loginActionCreator(username, password)),
    viewUnLoad: () => dispatch({
        type: actionTypes.LOGIN_VIEW_UNLOAD
    }),
    onLoad: () => dispatch({type: actionTypes.LOGIN_VIEW_LOADED}),
    authFieldDidChange: (fieldname, value) => dispatch({ 
        type: actionTypes.AUTH_FIELD_CHANGED, 
        payload: {
            field: fieldname,
            value: value
        }
    }),
    onRedirect: () => dispatch({ type: actionTypes.REDIRECT }),
    getAuthUser:(username) => dispatch(actions.getAuthUserAction(username)),
    setAuthUser:(user) => dispatch(actions.setAuthUserAction(user)),
    setLoginView: (view) => dispatch(actions.setLoginViewAction(view)),
    setSavedUsers:(usersList) => dispatch(actions.setSavedUsersAction(usersList))
  });

const PasswordView = (props) => {
    const { password, showPassword, handlePasswordField, 
        onChooseUser, doSignin, isPasswordVisible, rememberMe, userData } = props;
    const email = userData ? userData.email : '';
    const fieldType = isPasswordVisible ? 'text' : 'password';
    const visibleClassname = isPasswordVisible? '--visible' : '';

    return (
        <div className="password-view">
            <FormGroup>
                <div  onClick={onChooseUser} className="current-user">
                    <div className="user-icon-wrap">
                        <div className="user-icon">
                            <div className="head"></div>
                            <div className="body"></div>
                        </div>
                    </div>
                    <div className='assign-user'>
                        {email}
                        <span className="arrow-indicator"></span>
                    </div>
                </div>
            </FormGroup>
            <FormGroup>
                <div className="input-field-wrap">
                <ValidateField required >                       
                    <Input className={`ip-text password`} type={fieldType} name="password" placeholder="Password" defaultValue={ password } onChange={handlePasswordField} /> 
                        <span onClick={showPassword} className={`show-password-icon ${visibleClassname}`}>
                            <img src={getIconUrl('eye')} alt='' />
                        </span>
                    <ErrorFeedback 
                    warning
                    filter='valueMissing'
                    message={''} />
                </ValidateField>
                </div>
            </FormGroup>
            <FormGroup>
                <label className="ip-checkbox">Remember my preference?
                    <Input type="checkbox" checked={rememberMe} onChange={props.handleRememberMeField}/>
                    <span className="checkmark"></span>
                </label>
            </FormGroup>
            <FormGroup>
                <button onClick={doSignin} className="btn-primary continue">Sign In</button>
            </FormGroup>
        </div>
    );
}

const UsernameView = (props) => {
    const { username, rememberMe, handleRememberMeField, handleUsernameField, handleContinue } = props;

    return (
        <div className="username-view">
            <FormGroup>
                <ValidateField required >                       
                    <Input 
                    className="ip-text" type="text" 
                    name={'username'} autoComplete="off"
                    placeholder="Email or Username" 
                    value={ username } onChange={handleUsernameField} />
                    <ErrorFeedback 
                    warning
                    filter='valueMissing'
                    message='Enter your username or email.' />
                </ValidateField>
            </FormGroup>
            <FormGroup>
                <label className="ip-checkbox">Remember my preference?
                    <Input type="checkbox" onChange={handleRememberMeField} checked={rememberMe} />
                    <span className="checkmark"></span>
                </label>
            </FormGroup>
            <FormGroup>
                <button onClick={handleContinue} className="btn-primary continue">Contine</button>
            </FormGroup>
        </div>
    );
}

const UserEntry = (props) => {
    const {user, remove, willRemoveUser ,onClickHandler} = props;
    return (
        <a id={user.id} className="user-link" onClick={(evt) => onClickHandler(user, evt)}>
            <div className="user">
                <div className="user-icon-wrap">
                    <div className="user-icon">
                        <div className="head"></div>
                        <div className="body"></div>
                    </div>
                </div>
                <div>
                <h3>{user.name}</h3>
                <p>{user.email}</p>
                 { remove && 
                   <div id={user.id} onClick={(evt) => willRemoveUser(user, evt)} className="remove-icon" tag={'discard'}><span className="dash"></span></div>
                                }
                </div>
            </div>
        </a>
    );
}
const ChooseUserView = (props) => {
    const { onAnotherUser, willRemoveUser, didChooseUser, usersList} = props;
    const [remove, setRemove] = useState(false);

    const enableDisableRemove = () => {
        setRemove(!remove);
    }

    const onClickHandler = (data,evt) => {
        !remove && didChooseUser(data, evt);
    }
    const users = Object.values(usersList);

    return (
        <div className="choose-user-view">
            <FormGroup className="users-group">
                <List className="user-list">
                    { users.map((user) =>
                    <li key={user.id} className="user-item">
                        <UserEntry 
                            willRemoveUser={willRemoveUser}
                            onClickHandler={onClickHandler}
                            remove={remove}
                            user={user}
                        />
                    </li>
                    )}
                </List>
            </FormGroup>
            { remove ? 
                <FormGroup>
                    <a onClick={enableDisableRemove}>Done</a>
                </FormGroup>
            :
            <FormGroup>
                <div className="btn-wrap">
                <Button className="btn-chos anu" onClick={onAnotherUser}>
                    <span className="icon"><img src={getIconUrl('addAuthUser')}/></span>Use another user</Button>
                </div>
                <div  className="btn-wrap">
                <Button className="btn-chos rmv" onClick={enableDisableRemove}>
                    <span className="icon"><img src={getIconUrl('removeUser')}/></span>Remove user</Button>
                </div>
            </FormGroup>
            }
        </div>
    );
}

class LoginView extends React.Component {    
   //static contextType = StoreContext;
   static propTypes = {
        pageConfig: PropTypes.object
    };

    viewMode = {
        username: 'username',
        password: 'password',
        chooseUser: 'chooseUser'
    }

    constructor(props) {
        super(props);
        props.apiRequest.register(actionTypes.LOGIN, props.onSubmit);
        props.apiRequest.register(actionTypes.FETCH_AUTH_USER, props.getAuthUser);

        this.state = {
            useStyle: true,
            isPasswordVisible: false,
            initialStyle: 'login',
            initialColor: 'blue',
            mode: 'out-in',
            view: 'username'
        };
        if (this.props.token) {
            this.props.history.push({ pathname: urls.dashboardUri});
        }

        this.onContinue = this.onContinue.bind(this);
        this.willChooseUser = this.willChooseUser.bind(this);
        this.willRemoveUser = this.willRemoveUser.bind(this);
        this.didChooseUser = this.didChooseUser.bind(this);
        this.onAnotherUser = this.onAnotherUser.bind(this);
        this.onShowPassword = this.onShowPassword.bind(this);
        
        !props.savedUsersList ? props.setSavedUsers({}) : props.setSavedUsers(props.savedUsersList);
    }

    componentWillUnmount() {
        const { pageConfig } = this.props;
        pageConfig.setElementsVisibility({
            navbarHidden: false,
            workspaceBar: true,
        });
        
        this.props.validator.clearFields();
        this.props.viewUnLoad();
    }
    
    componentDidMount() {
    
        const { pageConfig } = this.props;
        pageConfig.setElementsVisibility({
            navbarHidden: true,
            workspaceBar: false,
        });
  
        this.initPage();
        document.title = "Login";
        this.props.onLoad();
    }

    initPage() {
        this.props.onChangeTheme({
            color: this.state.initialColor,
            style: this.state.initialStyle,
            overrideStyle: this.state.useStyle
        });
    }

    static getDerivedStateFromProps(props, state) {
        // Return null to indicate no change to state
        let view = '';

        if ( !Object.blank(props.fetchUserState.user) && props.loginState.username.empty() ) {
            props.authFieldDidChange('username', props.fetchUserState.user.username);
        }
        
        if ( !Object.blank(props.fetchUserState.user) && state.view !== 'password' ) {
            view = 'password';
            props.setLoginView(view);
            return {
                view: view
            }
        }
        
        if ( !Object.blank(props.loginState.savedUsersList)  && state.view !== 'chooseUser' &&  !state.userListView ) {
            view = 'chooseUser';
            props.setLoginView(view);
            return {
                view: view,
                userListView: true
            }
        }
        return null;
    }

    handleInputField = (event) => {
        this.props.authFieldDidChange('username', event.target.value);
    }

    handlePasswordInputField = (event) => {
        this.props.authFieldDidChange('password', Base64.encode(event.target.value));
    }

    handleRememberMeField = (event) => {
        this.props.authFieldDidChange('rememberMe', event.target.checked);
    }

    submitForm = event => {
        event.preventDefault();
        this.props.apiRequest.queueRequest(actionTypes.LOGIN, this.props.loginState.username, this.props.loginState.password);
    }

    onContinue (evt) {
        evt.preventDefault();
        if (this.props.loginState.username === '') return;
        this.props.apiRequest.queueRequest(actionTypes.FETCH_AUTH_USER, this.props.loginState.username);
    }

    onShowPassword(evt) {
        this.setState({isPasswordVisible: !this.state.isPasswordVisible});
    }

    didChooseUser = (data, evt) => {
        evt.preventDefault();
        this.props.authFieldDidChange('username', data.email);
        this.props.apiRequest.queueRequest(actionTypes.FETCH_AUTH_USER, data.username);
    }

    willRemoveUser = (data, evt) => {
        
        if ( evt.currentTarget.getAttribute('tag') ) {
            var savedUsersList = this.props.loginState.savedUsersList;
            delete savedUsersList[data.id];
            window.localStorage.setMap(SAVED_USERS_KEY, savedUsersList);
            this.props.setSavedUsers(savedUsersList);
            savedUsersList.empty() && this.setState({view: this.viewMode.username});
            this.forceUpdate();
        }
        
        evt.preventDefault();
    }

    willChooseUser (evt) {
        evt.preventDefault();
        this.props.setAuthUser(null);
        this.props.setLoginView(this.viewMode.chooseUser);
        this.setState({view: this.viewMode.chooseUser});
    }

    onAnotherUser = (evt) => {
        evt.preventDefault();
        this.props.authFieldDidChange('username', '');
        this.props.authFieldDidChange('password', '');
        this.props.setAuthUser(null);
        this.props.setLoginView(this.viewMode.username);
        this.setState({view: this.viewMode.username});
    }

    getTitle(view) {
        switch(view) {
            case this.viewMode.chooseUser:
                return 'Choose an user';
            case this.viewMode.password:
                return 'Welcome';
            case this.viewMode.username:
                return 'Sign in';
            default:
                return '';
        }
    }

    render() {
        
        const username = this.props.loginState.username;
        const rememberMe = this.props.loginState.rememberMe;
        const password = Base64.decode(this.props.loginState.password);
        const user = this.props.fetchUserState.user;
        const name = user ? `${user.firstName}` : '';
        const viewMode  = this.state.view;
        const title = this.getTitle(viewMode);
        const text = viewMode === this.viewMode.password ?  `to MedDeck ${name}` : 'to contine to CytoLabs';

        const savedUsers = this.props.loginState.savedUsersList;
        const apiOutcome = this.props.fetchUserState.error || this.props.loginState.error;

        const showActivityIndicator = viewMode === this.viewMode.password ? this.props.apiRequest.inProgress(actionTypes.LOGIN) : this.props.apiRequest.inProgress(actionTypes.FETCH_AUTH_USER);

        return (
            <EmptyLayout >
                <EmptyLayout.Section center width='420px'>
                    { /* Login Box */}
                    <div className="login-form-box">
                        <LoadingIndicator visible={showActivityIndicator} white />
                        <div className="inner">
                            <div className="mb-4">
                            { /* START Header */}
                            <HeaderAuth 
                                title={title}
                                text={text}/>
                            </div>
                            { ( apiOutcome ) && 
                               <ErrorMessage message={apiOutcome.errorResponse.message} />
                            }
                            <div  className="content-area">
                            
                                <form className="login-form">
                                    { viewMode === this.viewMode.username && 
                                        <UsernameView 
                                        username={username}
                                        rememberMe={rememberMe}
                                        handleUsernameField={this.handleInputField}
                                        handleRememberMeField={this.handleRememberMeField}
                                        handleContinue={this.onContinue}
                                        />
                                    }   

                                    { (viewMode === this.viewMode.password && user )&& 
                                        <PasswordView 
                                        password={password}
                                        handlePasswordField={this.handlePasswordInputField}
                                        userData={user}
                                        isPasswordVisible={this.state.isPasswordVisible}
                                        showPassword={this.onShowPassword}
                                        doSignin={this.submitForm}
                                        onChooseUser={this.willChooseUser}
                                        handleRememberMeField={this.handleRememberMeField}
                                        rememberMe={rememberMe}
                                        />
                                    }

                                    { viewMode === this.viewMode.chooseUser && 
                                        <ChooseUserView 
                                        user={user}
                                        usersList={savedUsers}
                                        didChooseUser={this.didChooseUser}
                                        willRemoveUser={this.willRemoveUser}
                                        onAnotherUser={this.onAnotherUser}
                                        />
                                    }   
                                </form>
                            </div>
                        </div>
                    </div>
                </EmptyLayout.Section>
            </EmptyLayout>
        );
    }
}

const ConfigLoginPage = withPageConfig(LoginView);

const LoginContextTheme = (props) => (
    <ThemeConsumer>
        {
            (themeState) => <ConfigLoginPage { ...themeState } { ...props } />
        }
    </ThemeConsumer>
)
export default connect(mapStateToProps, mapDispatchToProps)(LoginContextTheme);
