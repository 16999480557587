import {Layout} from './Layout';
import { LayoutContent } from './LayoutContent';
import { LayoutNavbar } from './LayoutNavbar';
import { LayoutWorkspaceBar } from './LayoutWorkspaceBar';
import { PageConfigContext } from './PageConfigContext';
import { withPageConfig } from './withPageConfig';
import { setupPage } from './setupPage';

Layout.Content = LayoutContent;
Layout.Navbar = LayoutNavbar;
Layout.WorkspaceBar = LayoutWorkspaceBar;

const PageConfigProvider = PageConfigContext.Provider;
const PageConfigConsumer = PageConfigContext.Consumer;

export default Layout;
export { withPageConfig, setupPage, PageConfigProvider, PageConfigConsumer };