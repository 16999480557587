import React from 'react';
import PropTypes from 'prop-types';

class DelayRender extends React.Component {

    constructor(props) {
        super(props);
        this.state = {hidden : true};
    }

    componentDidMount() {
        this.unsetTimeout = setTimeout(() => {
           (this && this.props.children) && this.setState({hidden: false});
        }, this.props.waitBeforeShow);
    }

    componentWillUnmount() {
        clearTimeout(this.unsetTimeout);
    }

    render() {
        return this.state.hidden ? null : this.props.children;
    }
}

DelayRender.propTypes = {
  waitBeforeShow: PropTypes.number.isRequired
};

export default DelayRender;