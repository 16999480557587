import * as actionTypes from '../actionType';
import {action} from './actionCreator';
import {createRequestTypes, REQUEST, SUCCESS, FAILURE} from './../../service/httpRequestDialect';

export const permissionsRolesLoadedAction = () => ({
        type: actionTypes.PERMISSIONS_ROLES_LOADED
});

export const permissionsViewUnLoadAction = () => ({
    type: actionTypes.PERMISSIONS_ROLES_VIEW_UNLOAD
});

export const getPermissionsActionCreator = (workspace) => ({
    type: actionTypes.FETCH_PERMISSIONS,
    payload: workspace
}); 

export const getAllRolesActionCreator = (workspace) => ({
    type: actionTypes.FETCH_ROLES,
    payload: workspace
}); 

export const getRolesPermissionsConcurrentAction = (workspace) => ({
    type: actionTypes.FETCH_ROLES_PERMISSIONS,
    payload: workspace
}); 

export const permissionRoleViewAction = (view) => ({
    type: actionTypes.PERMISSIONS_ROLE_VIEW,
    payload: view
});  
export const selectRoleTypeAction = (type) => ({
    type: actionTypes.PERMISSIONS_ROLE_TYPE,
    payload: type
}); 

export const selectedRoleAction = (role) => ({
    type: actionTypes.PERMISSIONS_SELECTED_ROLE,
    payload: role
}); 

export const selectedSuperuserRoleAction = (isSuperRole) => ({
    type: actionTypes.SELECTED_SUPEUSER_ROLE,
    payload: isSuperRole
}); 

export const chosenPermissionsAction = (permissions) => ({
    type: actionTypes.CHOSEN_PERMISSIONS,
    payload: permissions
}); 

export const roleNameFieldChangedAction = (value) => ({
    type: actionTypes.ROLENAME_FILED_CHANGED,
    payload: value
}); 

export const submitRolePermissionsAction = (data) => ({
    type: actionTypes.PERMISSIONS_ROLE_SUBMIT_DATA,
    payload: data
}); 

export const roleRolePermmisonsResultsAction = (payload) => ({
    type: actionTypes.PERMISSIONS_ROLE_SUBMITED_RESULTS,
    payload
}); 

export const backToPreviousViewAction = (view) => ({
    type: actionTypes.PERMISSIONS_BACK_T0_ACTION,
    payload: view
}); 

export const ROLES = createRequestTypes('ROLES');
export const PERMISSIONS = createRequestTypes('PERMISSIONS');

export const roles = {
    request: workspace => action(ROLES[REQUEST], workspace),
    success: (payload) => action(ROLES[SUCCESS], {payload}),
    failure: (error) => action(ROLES[FAILURE], {error}),
};

export const permissions = {
    request: workspace => action(PERMISSIONS[REQUEST], workspace),
    success: (payload) => action(PERMISSIONS[SUCCESS], {payload}),
    failure: (error) => action(PERMISSIONS[FAILURE], {error}),
};