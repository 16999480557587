import React from 'react'
import classNames from 'classnames';
import PropType from 'prop-types';
import {
    NavLink as Link,
} from './../../../components';
import { getIconUrl } from '../../../common/images-catalogue';

const Role = (props) => {

    const {selected, role, clickHandler } = props;
    const classnames = classNames('role-item', ( role.superRole ? 'super-role' : '' ), (selected? 'selected': ''));
    
    return (
        <React.Fragment>
        <li className={classnames}>
            <Link onClick={ () => clickHandler(role) } className="interactor">
                <div className="role">
                    {
                        role.superRole ? 
                    <span className="key">
                        <img src={ getIconUrl("key") } alt="icon"/>
                    </span>
                    : null
                    }
                    <span className="icon"><img src={ getIconUrl("blackRoleIcon") } alt="icon"/></span>
                    <p className="label">{ role.role }</p>
                    <span className="type">{ role.type } role</span>
                </div>
            </Link>
        </li>
        </React.Fragment>
    );
}

Role.propTypes = {
    //key: PropType.number
};

Role.defaultProps = {
    //key: null
};

export default Role;