export const REQUESTS_TYPE = {
  DELETE: 'delete',
  UPDATE: 'update',
  CREATE: 'create',
  VIEW: 'view',
  AUTH: 'auth',
  FETCH: 'fetch',
  SUBMIT: 'submit',
  BILLED: 'billed',
};

export const VIEWMODE = {
  EDIT: 'edit',
  DELETE: 'edit',
  CREATE: 'create',
  VIEW: 'view',
};

const getAlphabet = () => {
  let ALPHABET = [];
  // Alphabets form (A-Z) for lowercase a-z use code 97 - 122
  for (let i = 65; i <= 90; i++) {
    ALPHABET.push(String.fromCharCode(i));
  }
  return ALPHABET;
}

export const ALPHABETICALLY = [
  '...',
  ...getAlphabet()
];

export const DASHBOARD_KEY = 'dashboardKey';

export const JWT_COLLECTION_KEY = 'jwtCollection';
export const SAVED_USERS_KEY = 'savedUsersList';

export const TOKEN_EXPIRED_CODE = 803;

export const STATUS = {
  Pending: 'Pending',
  Submitted: 'Submitted',
  Processing: 'Processing',
  Completed: 'Completed',
  Approved: 'Approved',
  Resulted: 'Resulted',
  Paid: 'Paid',
  Partial: 'Partial',
  Sent: 'Sent',
};

export const STATUS_MAP = {
  Pending: [
    STATUS.Submitted,
    STATUS.Processing,
    STATUS.Completed,
    STATUS.Resulted,
  ],
  Submitted: [STATUS.Processing, STATUS.Completed, STATUS.Resulted],
  Processing: [STATUS.Completed, STATUS.Resulted],
  Completed: [STATUS.Resulted],
  Resulted: [STATUS.Approved],
  Approved: [],
};

export const ACTION_BAR_MODES = {
  none: '',
  delete: 'delete',
  create: 'create',
  edit: 'edit',
  view: 'view',
};

export const CLIENTS = {
  Doctor: 'Doctor',
  Lab: 'Laboratory',
};

export const GENDER = {
  Male: 'Male',
  Female: 'Female',
};

export const FORM_TYPE = {
  Gynec: 'Gynecology',
  NonGynec: 'NonGynecology',
};

export const USER_TYPE = {
  Standard: 'Standard',
  Authorizer: 'Authorizer',
  Client: 'Client',
};

export const CLIENT_TYPE = {
  Doctor: 'Doctor',
  Laboratory: 'Laboratory',
};

//As of April 1. 2017 according to -> https://jis.gov.jm/1-5-million-income-tax-threshold-paye-workers/
export const INCOME_TAX_THRESHOLD_LOWER = 1500096.0; // tax will be 25%
export const INCOME_TAX_THRESHOLD_UPPER = 6000000.0; // tax will now be 30%
export const TAX_CODE = {
  NHT: 'NHT',
  NIS: 'NIS',
  PAYE: 'PAYE',
  EDTAX: 'EDUTAX',
};

export const BILL_CYCLE_TYPES = {
  monthly: 'Monthly',
};

export const TAX_TYPE = {
  payroll: 'Payroll',
  service: 'Service',
};

export const MESSAGE_CODES = {
  NEW: 'NEW_MSG',
  REPLY: 'RPLY_MSG',
  DELETE: 'DEL_MSG',
  DELETE_THREAD: 'DEL_THED',
};

export const ERROR_CODES = {
  ERR_SERVER_COMMUNICATION: 'Unable to connect with server.',
};

export const COMPANY_NAME = 'Cytology Labs Ltd.';

export const FINDING_NORMALITY = {
  Normal: 'Normal',
  Abnormal: 'Abnormal',
};

export const PAYMENT_TYPE = {
  Cash: 'Cash',
  Cheque: 'Cheque',
  CreditCard: 'CreditCard',
  DebitCard: 'DebitCard',
  BankTransfer: 'BankTransfer',
};

export const FEATURE_FLAG = {
  ON: 'ON',
  OFF: 'OFF',
};

export const API_OUTCOME_SUFFIX = 'OUTCOME';

export const VALIDATOR_DELEGATE_TYPES = {
  settings: 'settings',
  employee: 'employee',
  user: 'user',
  client: 'client',
  patient: 'patient',
  payment: 'payment',
};
export const VALIDATOR_DELEGATE_KEY_GROUP = {
  settings: {
    labCodes: 'labCodes',
    codeSheet: 'codeSheet',
    codeFindings: 'codeFindings',
    services: 'services',
    taxes: 'taxes',
    company: 'company',
    notification: 'notification',
    departments: 'departments',
  },

  formFields: {
    department: 'department',
    firstName: 'firstName',
    lastName: 'lastName',
    middleName: 'middleName',
    motherMaidenName: 'motherMaidenName',
    client: 'client',
    email: 'email',
    dateOfBirth: 'dateOfBirth',
    selectedUser: 'selectedUser',
    hiredDate: 'hiredDate',
    phoneNumber: 'phoneNumber',
    trn: 'trn',
    nis: 'nis',
    hourlyRate: 'hourlyRate',
    salary: 'salary',
    sickDays: 'sickDays',
    vacationDays: 'vacationDays',
    street: 'street',
    streetTwo: 'streetTwo',
    city: 'city',
    state: 'state',
    password: 'password',
    passwordTwo: 'passwordTwo',
    currentPassword: 'currentPassword',
    workspaceName: 'workspaceName',
    labCode: 'labCode',
    officeName: 'officeName',
    officeNumber: 'officeNumber',
    mobileNumber: 'mobileNumber',
    chequeNumber: 'chequeNumber',
    bank: 'bank',
    account: 'account',
  },
};

export const PROFILE_PANES = {
  personalInfo: 'personalInfo',
  security: 'security',
};

export const INPUT_FIELD_TYPES = {
  text: 'text',
  email: 'email',
  password: 'password',
};

export const ERROR_FILTERS = {
  valueMissing: 'valueMissing',
  letterInput: 'letterInput',
  emailMismatch: 'emailMismatch',
  phoneNumber: 'phoneNumber',
  password: 'password',
  patternMismatch: 'patternMismatch',
  matchEqual: 'matchEqual',
};
