import React from 'react';
import { Fragment } from 'react';
import * as actionTypes from '../../store/actionType';
import DelayRender from './../../utility/DelayRender';
import { getIconUrl } from '../../common/images-catalogue';
import { 
    Section,
    Authority,
    SpecimenTable,
 } from '../../components';
import SpecimenCard from './../components/partials/SpecimenCard';
import {timeDelayed } from '../../utility/helpers';


class Recent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        this.unlistenAbort = this.props.history.listen(() => { 
            this.props.apiRequestManager.abortRequest(actionTypes.FETCH_RECENT_SPECIMEN_RECORDS);
        });
    }
    
    componentWillUnmount() {
        this.props.unLoad();
        this.unlistenAbort();
    }
    
    static getDerivedStateFromProps(props, state) {
        // Return null to indicate no change to state.
        
        if ( !props.recentSpecimensData && props.workspace ) {
            const requestParams = {
                workspace: props.useClientLayout && props.workspace.id,
                userType: props.authorizeUser.type
            }
            props.useClientLayout ? props.apiRequestManager.queueRequest(actionTypes.FETCH_RECENT_SPECIMEN_RECORDS, requestParams) : 
            props.apiRequestManager.queueRequest(actionTypes.FETCH_RECENT_SPECIMEN_RECORDS,requestParams);
        }

        if ( props.recentSpecimensData && props.workspace && props.lazyLoad) {
            const requestParams = {
                workspace: props.useClientLayout && props.workspace.id,
                userType: props.authorizeUser.type,
                page: props.pageLoaded 
            }
            props.useClientLayout ? props.apiRequestManager.queueRequest(actionTypes.FETCH_RECENT_SPECIMEN_RECORDS, requestParams) : 
            props.apiRequestManager.queueRequest(actionTypes.FETCH_RECENT_SPECIMEN_RECORDS,requestParams);
        }

        return null;
    }

    render() {
        
        const weekTableData =  !Object.blank(this.props.recentSpecimensData) ? this.props.recentSpecimensData.weekRecords : [];
        const monthTableData =  !Object.blank(this.props.recentSpecimensData) ? this.props.recentSpecimensData.monthRecords : [];

        const todaysRecordData =  !Object.blank(this.props.recentSpecimensData) ? this.props.recentSpecimensData.todaysRecords : [];

        const mostRecentSpecimens =  todaysRecordData.slice(0, 4);//tableData.slice(Math.max
        var baseMs = 0;
        
        const display = (specimen, i) => {
            baseMs = timeDelayed(i, 150, true, baseMs);
            return <DelayRender key={i} waitBeforeShow={timeDelayed(i, 120)} >
            
            <div key={specimen.id} className="sp-item-rec">
                <SpecimenCard 
                    data={specimen}  
                    eventHandler={this.props.handleSpecimentCardClick} 
                    bloodType={"B+"}
                    label={specimen.type} />
            </div>
            </DelayRender>
        }

        return <Fragment>
            <div className="spmt-Ly-ct-inner">
                <div className="spmt-title-d">
                    <h3 className="spmt-title">Recently</h3>
                </div>
            </div>
            <div className="spmt-v-lay-ct">
                <div className="content-well">
                    <Section className="recent-sec-l">
                        <div className="rc-title">
                            <h5>Today</h5>
                        </div>
                        <div className="rc-content-wid">
                            <div className="rc-content-list">
                                {
                                    mostRecentSpecimens.map((specimen, i) => display(specimen, i))
                                }
                            </div>
                        </div>
                    </Section>
                    <Section className="recent-sec-t specimen-table current-month">
                        <div className="sec-title">
                            <h5>Eariler this week</h5>
                        </div>
                        <SpecimenTable id="weekTable" 
                        quickActions={this.props.quickActionCallback}
                        hoverOverRowAction={this.props.willHandleTableRowMouseOver}
                        limit={6} tableData={weekTableData}/>
                    </Section>
                    <Section className="recent-sec-t specimen-table older">
                        <div className="sec-title">
                            <h5>This month</h5>
                        </div>
                        <SpecimenTable  id="mainTable"
                         quickActions={this.props.quickActionCallback} 
                         hoverOverRowAction={this.props.willHandleTableRowMouseOver}
                         tableData={monthTableData}/>
                    </Section>
               </div>
           </div>
        </Fragment>
    }
};

export default Recent;