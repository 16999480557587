import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { Comparator, dateFilter } from 'react-bootstrap-table2-filter'
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import PropType  from 'prop-types';

import { InputField, List , NavLink} from '../index';
import {pictureInitials} from './../../utility/helpers';
import { PAGE_SIZE } from '../../config/pagination';
import { PaginationPanel, PaginationTotal } from './';
import { getIconUrl } from '../../common/images-catalogue';

const generateRow = (data) => ({
    id: data.id,
    picture: data,
    username: data,
    details: data
});


const userPicture = (data) => {

    const picture = data.picture ? data.picture.fileDownloadUri : pictureInitials(data.firstName, data.lastName);

    const hasPicture = data.picture ? true : false;
    return (
        <ContextMenuTrigger id="users_table_context_menu">
        <div className="user-picture">
            <div className="picture-holder">
                { hasPicture ? 
                <div className="picture-content">
                    <img className="picture" src={picture} alt='' />
                </div>
                :
                <div className="picture-content">
                    { picture }
                </div>
                }
            </div>
        </div>
        </ContextMenuTrigger>
    );
}

const userNameInfo = (data) => {
    return (
        <ContextMenuTrigger id="users_table_context_menu">
        <div className="user-info">
            <div className="user-info-holder">
                <div className="user-info-content">
                    <h3>{ data.firstName } { data.lastName }</h3>
                    <p className="auth-info">{ data.username }</p>
                </div>
            </div>
        </div>
        </ContextMenuTrigger>
    );
}

const userDeatils = (actions) => {

    //return function with cell content
    return (data) => (
        <div className="user-details">
            <ContextMenuTrigger id="users_table_context_menu">
            <div className="toolbar">
                <List className="action-menu">
                    <li className="action-item">
                        <NavLink onClick={ actions.editAction }>
                            <span className="icon"><img className="icon" src={getIconUrl("edit")} alt='' /></span>
                        </NavLink>
                    </li>
                    <li className="action-item">
                        <NavLink>
                            <span className="icon"><img className="icon" src={getIconUrl("option")} alt='' /></span>
                        </NavLink>
                    </li>
                </List>
            </div>
            
            <div className="user-details-holder">
                <div className="user-details-content">
                    <ul className="snippet-set">
                        <li><span className="auth-snippet role"><img className="icon" src={getIconUrl("role")}alt='' /> <p>{ data.role.role }</p></span></li>
                        <li><span className="auth-snippet email">{ data.email }</span></li>
                    </ul>
                    
                </div>
            </div>
            </ContextMenuTrigger>
        </div>
    );
}

class UsersTable extends React.Component { 
    static propsTypes = {
        tableData: PropType.array.require,
        actionResponsers: PropType.object,
    }

    static defaultProps = {
        actionResponsers: {
            editButton: null,
        }
    }

    constructor(props) {
        super(props);
        
        this.state = {
            selected: []
        };
        
        this.willEnterRow = this.willEnterRow.bind(this);
        this.didContextClick = this.didContextClick.bind(this);
        this.onTableChange = this.onTableChange.bind(this);
    }


    createColumnDefinitions(quickActions) { 
        return [
            {
                dataField: 'picture',
                text: "Picture",
                headerAttrs: {
                    hidden: true
                },
                classes: 'id-picture-cell td-cell',
                formatter: userPicture
            },
            {
                dataField: 'username',
                text: "Username",
                headerAttrs: {
                    hidden: true
                },
                classes: 'id-user-cell td-cell',
                formatter: userNameInfo
            },
            {
                dataField: 'details',
                text: "Details",
                headerAttrs: {
                    hidden: true
                },
                classes: 'id-details-cell td-cell',
                formatter: userDeatils( quickActions )
            }
        ];

    }

    handleSelect() { }

    handleSelectAll() { }

    handleOnChange() {}

    willEnterRow (evt, row, rowIndex) {
        const user = this.props.tableData.find((item) => {
            return item.id === row.id;
        });

        this.props.hoverOverRowAction({user});
    }

    didContextClick (evt, row, rowIndex) {
        evt.preventDefault();
    }

    onTableChange(evt, opts) {
        this.props.onTableChange(opts);
    }

    render () {
        const columnDefs = this.createColumnDefinitions(this.props.quickActions);
        const paginationDef = paginationFactory({
            paginationSize: 1,
            sizePerPage: this.props.metaData?.pageSize || PAGE_SIZE,
            showTotal: true,
            totalSize: this.props.metaData?.totalItems || 0,
            page: this.props.metaData?.currentPage + 1 || 0,
            pageListRenderer: (props) => (
                <PaginationPanel { ...props } size="sm" className="ml-md-auto mt-2 mt-md-0" />
            ),
            paginationTotalRenderer: (from, to, size) => (
                <PaginationTotal { ...{ from, to, size } } />
            )
        
        });
        const selectRowConfig = {
            mode: 'checkbox',
            selected: this.state.selected,
            onSelect: this.handleSelect.bind(this),
            onSelectAll: this.handleSelectAll.bind(this),
            selectionRenderer: ({ mode, checked, disabled }) => (
                <InputField id="" onChange={this.handleOnChange} type={ mode } checked={ checked } disabled={ disabled } />
            ),
            selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
                <InputField id="" onChange={this.handleOnChange} type={ mode } checked={ checked } innerRef={el => el && (el.indeterminate = indeterminate)} />
            )
        };

        const rowEventsHandlers = {
            onContextMenu: this.didContextClick,
            onMouseEnter: this.willEnterRow
        };

        var options = {
            noDataMessage: () => 'No users found.'
        };

        const usersData = this.props.tableData.map(user=> generateRow(user))
        
        return (
            <ToolkitProvider
                keyField="id"
                data={ usersData }
                columns={columnDefs} >
                {
                    props => (
                        <React.Fragment>
                            <BootstrapTable
                            remote={{ pagination: this.props.metaData?.remote }}
                            classes="table-responsive"
                            pagination={ paginationDef }
                            filter={ filterFactory() }
                            selectRow={ selectRowConfig }
                            bordered={ false }
                            responsive
                            { ...props.baseProps }
                            rowEvents={ rowEventsHandlers }
                            loading={ true } 
                            noDataIndication={options.noDataMessage}
                            onTableChange={this.onTableChange}
                        />
                        </React.Fragment>
                    )
                }
            </ToolkitProvider>
        );
    }
}

export { UsersTable }