import { STATUS } from './../common/constant';
import { PermissionEnforcer } from './PermissionEnforcer';

class SpecimenSupervisor {
    
    static _instance = null;
    _specimen = null;
    _permissionEnforcer = null;
    clientDashboard = false;

    constructor() { 
        this._permissionEnforcer = PermissionEnforcer.getInstance(PermissionEnforcer);
    }

    set specimen(specimen) {
        this._specimen = specimen;
    }

    get specimen() {
        return this._specimen;
    }

    retire() {
        this._specimen = null;
        this._permissionEnforcer = null;
        SpecimenSupervisor._instance = null;
    }

    isDeletabe(workspace) {
        if (this._specimen && workspace) {
            return  this._specimen.workspace === workspace.id  && this._specimen.statuses.last().status === STATUS.Pending && this._permissionEnforcer && this._permissionEnforcer.isPermitted('record', 'delete') ? true : false;
        }else {
            return false;
        }
    }
    hasAttachment() {
        return this.specimenPresent() && this._specimen.resultSheetFile ? true : false;
    }
    canAttachFile () {
        return this.hasStatus() && this.showResultSheet() ? true : false;
    }
    isReusltEntryEditable() {
        if(this._permissionEnforcer && this._permissionEnforcer.isUserAuthorizer()) return true;

        return this._permissionEnforcer && this._permissionEnforcer.isStandardUser() && this._specimen.statuses.last().status === STATUS.Completed &&  this._permissionEnforcer.isPermitted('resultentry', 'change') ? true : false;
    }

    canCreateResultEntry() {
        return this._permissionEnforcer && this._permissionEnforcer.isStandardUser() && this._specimen.statuses.last().status === STATUS.Completed &&  this._permissionEnforcer.isPermitted('resultentry', 'create') ? true : false;
    }

    canCreateResultSheet() {
        return this._permissionEnforcer && this._permissionEnforcer.isStandardUser() && this._specimen.statuses.last().status === STATUS.Completed &&  this._permissionEnforcer.isPermitted('resultsheet', 'create') ? true : false;
    }

    isResultSheetEditable() {
        return this._specimen.statuses.last().status === STATUS.Completed &&  this._permissionEnforcer.isPermitted('resultsheet', 'change') ? true : false;
    }
    canViewReports() {
  
        return  this.isAuthoriable() || (this.hasReports() && this.userCanViewReport() && this._specimen.statuses.last().status === STATUS.Approved && this._permissionEnforcer && this._permissionEnforcer.isPermitted('report', 'view')) ? true : false;
    }

    userCanViewReport() {
        return this._permissionEnforcer && (this._permissionEnforcer.isStandardUser() || this._permissionEnforcer.isStandardUser() || this._permissionEnforcer.isClientUser());
    }

    isReportOwner(reportData) {
        return !reportData.authorizerReference ?  true : this._permissionEnforcer.user.id === reportData.authorizerReference ? true : false; 
    }

    specimenPresent() {
        return !Object.blank(this._specimen) ? true : false;
    }

    hasStatus() {
        return this.specimenPresent() && this._specimen.statuses && !this._specimen.statuses.empty()? true : false;
    }

    hasReports() {
        if (this.hasResultSheet()) {
            return  this._specimen.resultSheet.reports && !this._specimen.resultSheet.reports.empty() && this._specimen.resultSheet.reports.first().id;
        }else {
            return false;
        }
    }
    hasResultSheet() {
        return  this.specimenPresent() && this._specimen.resultSheet ? true : false
    }
    hasResultEntries() {
        return this.hasResultSheet() && !Object.blank(this._specimen.resultSheet.resultEntries) ? true : false
    }
    getCodeSheetResult(specimen) {
        
        if (this.hasResultSheet()) {
            const codeSheet = this.hasResultEntries() && this._specimen.resultSheet.resultEntries.filter((entry) => entry.specimen === specimen);

            return codeSheet && codeSheet.last();
        }else {
            return null
        }
    }
    isApproved() {
        return this.hasResultSheet() ? this._specimen.resultSheet.approved : false;
    }
    addReports(reports) {
        this._specimen.resultSheet.reports = reports;
    }
    getReports() {
        return this.hasReports()? this._specimen.resultSheet.reports : [];
    }
    addResultSheet(resultSheet) {
        this._specimen.resultSheet = resultSheet;
    }
    getStatus() {
        return this.hasStatus() ? this._specimen.statuses.last().status : null;
    }

    isAuthoriable() {
        //console.log(this._permissionEnforcer.isPermitted('record', 'authorize'));
        const isEditableAccess =  (this._specimen.statuses.last().status === STATUS.Resulted || this._specimen.statuses.last().status === STATUS.Approved) ? true : false;
        return isEditableAccess && this._permissionEnforcer && this._permissionEnforcer.isUserAuthorizer() && this._permissionEnforcer.isPermitted('resultsheet', 'authorize');

    }

    isEditable(workspace) {
        const lastStatus = this.specimenPresent() && this.getStatus();
        if( (lastStatus === STATUS.Approved) && this._permissionEnforcer.isUserAuthorizer()){
            return true;
        }
      
        //[1] Check if the user is using  client dashboard and enable or disable editing
        if (!this.clientDashboard && this.specimenPresent()) {
            const lastStatus = this._specimen.statuses?.last().status;
            return (lastStatus === STATUS.Pending || lastStatus === STATUS.Submitted) && this._permissionEnforcer && this._permissionEnforcer.isPermitted('record', 'change') ? true : false;

        }else if (this.specimenPresent() && workspace) {
            //[2] enable or disable editing if status is Pending the client has permission  to make changes
            return  this._specimen.workspace === workspace.id  && this._specimen.statuses.last().status === STATUS.Pending && this._permissionEnforcer && this._permissionEnforcer.isPermitted('record', 'change') ? true : false;
        }else {
            return true;
        }
    }

    showUrgentBadge() {
        return this.specimenPresent() && ( (this._specimen.urgent && this.hasStatus() && this._specimen.statuses.last().status !== STATUS.Approved) || (this.hasResultSheet()  && !this._specimen.resultSheet.approved) );
    }
    getAppovedDate() {
        return this.specimenPresent() && this._specimen.statuses.last().date;
    }

    canAuthorizerChangeStatus() {
        return this.specimenPresent() && this._permissionEnforcer && this._permissionEnforcer.isUserAuthorizer() && this.hasStatus() && this._specimen.statuses.last().status === STATUS.Resulted && this.hasReports()? true : false;
    }

    canUserChangeStatus() {
        return this.specimenPresent() && this._permissionEnforcer && this._permissionEnforcer.isStandardUser() && this.hasStatus() && this._specimen.statuses.last().status !== STATUS.Approved && this.getStatus() !== STATUS.Resulted && this.getStatus() !== STATUS.Pending && this.getStatus() !== STATUS.Completed;
    }

    canChangeStatusToResulted() {
        return this.hasStatus() && this.getStatus() === STATUS.Completed && this.hasResultSheet();
    }

    enableStatusChange() {
        return this.specimenPresent() && ( (this.canUserChangeStatus() || this.canChangeStatusToResulted()) ||  this.canAuthorizerChangeStatus() );
    }

    showStatusOption() {
        return this._permissionEnforcer && !this._permissionEnforcer.isSecondaryUser() && (this._permissionEnforcer.isStandardUser() || this._permissionEnforcer.isUserAuthorizer() ) ? true : false; 
    }

    showMinimalContextMenu() {
        return this._permissionEnforcer && !this._permissionEnforcer.isUserAuthorizer() && !this._permissionEnforcer.isStandardUser() && this._permissionEnforcer.isClientOrStaff();
    }

    showClientsResultSheet() {
        return this.specimenPresent() && this.hasResultSheet() && this.hasStatus() && this._specimen.statuses.last().status === STATUS.Approved? true : false;
    }
    showAutorizerResultSheet() {
        return this.specimenPresent() && this.hasResultSheet() && this._permissionEnforcer && this._permissionEnforcer.isUserAuthorizer() && 
        (this._specimen.statuses.last().status === STATUS.Resulted || this._specimen.statuses.last().status === STATUS.Approved)?  true : false;
    }

    showResultSheet() {
        return this.specimenPresent() && this._permissionEnforcer && this._permissionEnforcer.isStandardUser() 
        && this.hasStatus() && 
        (this._specimen.statuses.last().status === STATUS.Completed
        || this._specimen.statuses.last().status === STATUS.Resulted||
        this._specimen.statuses.last().status === STATUS.Approved)?   true : false;
    }

    isSpecimentTransferable() {
        return this.specimenPresent() &&  this.hasStatus() && this._specimen.statuses.last().status === STATUS.Pending && this._permissionEnforcer && this._permissionEnforcer.isClientOrStaff() ? true : false;
    }

    getTherapyItem(name) {
        var value = "";
        if (this.specimenPresent() && this._specimen.therapy) {
            value = this._specimen.therapy[name];
        }
        return value;
    }

    approveResultSheet(requstManager, reduxAction) {
        if ( this.hasResultSheet() && this.hasReports() && !this.isApproved() ) {
            const formData = {
                requestType: 'approve',
                resultSheetId: this._specimen.resultSheet.id,
                approve : true
            };
            requstManager.queueRequest(reduxAction, formData);
        }
    }

    specimenDeletable() {
        return this.hasStatus() && this.getStatus() === STATUS.Pending;
    }

    canUpdatePrintFields(){
        const status = this.getStatus();
        return status === STATUS.Pending || status === STATUS.Submitted;
    }

    canLinkToRequisition(){
        const status = this.getStatus();
        return this.specimenPresent() && this._specimen.requisitionItem === null && status !== STATUS.Approved;
    }

    static getInstance = (classInstance) => SpecimenSupervisor._instance ? SpecimenSupervisor._instance : (SpecimenSupervisor._instance = new classInstance());
};

export { SpecimenSupervisor };