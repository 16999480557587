import React from 'react';
import PropTypes from 'prop-types';

const LayoutWorkspaceBar  = (props) => {
    const {clientLayout} = props;
    const workspaceBar = React.Children.only(props.children);
    return (
        <div className={`layout__workspacebar ${clientLayout ? 'layout__clientdashboard' : ''}`} >
            {
                React.cloneElement(workspaceBar, { fixed: null })
            }
        </div>
    ); 

};

LayoutWorkspaceBar.defaultProps = {
    clientLayout: false
};

LayoutWorkspaceBar.propTypes = {
    children: PropTypes.node,
    clientLayout: PropTypes.bool
};

LayoutWorkspaceBar.layoutPartName = "workspaceBar";

export { LayoutWorkspaceBar };