import React from 'react';
import { connect } from 'react-redux';
import { setupPage } from '../../components/Layout/setupPage';

import './Receipt.style.scss';
import  * as actions  from '../../store/actions';
import * as actionTypes from '../../store/actionType';
import  { PermissionEnforcer }  from '../../utility/PermissionEnforcer';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
import { FormValidator } from '../../utility/FormValidator';
import { VALIDATOR_DELEGATE_TYPES, VALIDATOR_DELEGATE_KEY_GROUP } from '../../common/constant';
import paymentValidatorDelegate from '../../forms/paymentValidator.delegate';
import { ErrorMessage } from '../components/partials/ErrorMessage';
import { Loader } from '../components/partials/LoadingIndicator';
import { 
    Section,
    FormGroup,
    Authority,
    Input, 
    Label,
    Row,
    Col,
    Button,
    InputField,
    SimpleSelect,
    ValidateField,
    ErrorFeedback

 } from '../../components';

import {elements} from '../../components/SimpleSelect';
import { getIconUrl } from '../../common/images-catalogue';

const nameToInitial = (name)=> {
    const namesPart = name.split(' ');
    const initials = namesPart[0].substr(0,1)  + namesPart[1].substr(0,1);
    return initials;
}

const Option = ({...props}) => {

    const {item } = props;
 
    return (
    <elements.Option key={item.id} className="patient-option" {...props}>
        <div className="patient-opt">
            <div className="patinet-info">
                <span className="patient-picture">{nameToInitial(item.label)}</span>
                <label>{item.label}</label>
            </div>
            <div className="regist-no">
                <p>Acct. #:<span>{item.accountNo}</span></p>
            </div>
        </div>
    </elements.Option>
  )};

  const Indicator = ({...props}) => {
    const svgIcon = <svg><path className="cls-1" d="M59,1.67H18.41V9H59Zm0,17H18.37v7.24H59ZM18.43,35.53v7.26H58.94V35.53ZM10.47,9V1.67H1.22V9Zm0,9.63H1.2v7.25h9.27ZM1.19,35.56v7.26h9.27V35.56Z"/><path className="cls-1" d="M59,1.67V9H18.41V1.67Z"/><path className="cls-1" d="M59,18.63v7.24H18.37V18.63Z"/><path className="cls-1" d="M18.43,35.53H58.94v7.26H18.43Z"/><path className="cls-1" d="M10.47,9H1.22V1.67h9.25Z"/><path className="cls-1" d="M10.47,18.66v7.25H1.2V18.66Z"/><path className="cls-1" d="M1.19,35.56h9.27v7.26H1.19Z"/></svg>;
    const icons = {
        normalIcon: svgIcon,
        focusIcon: svgIcon
    };
    return (
        <elements.Indicator className="ip-select-indicator" {...icons} {...props} />
    )};

    const SelectedOption = ({...props}) => {
    
    const {selectedItem } = props;
 
    return (
    <elements.SelectedOption className="template-content">
        <div className="chosen-option" >
            <div className="option-template">
                <div className="template-content">
                    <div className="patient-picture">
                        <span>{nameToInitial(selectedItem.label)}</span>
                    </div>
                    <div className="patient-info">
                        <p className="name">{ selectedItem.label }</p>
                        <p className="regist-no">Acc#:<span>{selectedItem.accountNo}</span></p>
                    </div>
                    <div  className="patient-details">
                        <p className="info">User:<span>{selectedItem.owner}</span></p>
                        <p className="info">Term:<span>{selectedItem.billCycle}</span></p>
                    </div>
                </div>
            </div> 
        </div>
    </elements.SelectedOption>
)};

const mapStateToProps = state => ({
    apiRequestManager: ApiRequestManager.getInstance(ApiRequestManager),
    useClientLayout: state.global.useClientDashboard,
    account: state.global.account,
    validator: FormValidator.getInstance(FormValidator),
    ...state.payment
});

const mapDispatchToProps = dispatch => ({
    unLoad: () => dispatch(actions.paymentModelViewUnloadAction()),
    getAccounts: () => dispatch(actions.getAllAccountsAction()),
    getBillableBills: (client) => dispatch(actions.getAllBilledBillsAction(client)),
    paymentFieldDidChange: (fieldWithValue) => dispatch(actions.paymentFieldChangeAction(fieldWithValue)),
    getClientAccount: (client) => dispatch(actions.getAccountByOwnerAction(client)),
    submitData: (data) => dispatch(actions.submitPaymentAction(data)),
    setAccountBalance: (value) => dispatch(actions.setAccountBalance(value))
});

class Pay extends React.Component {

    static pageOption = {
        pageTitle: 'Client',
        disableBodyScroll: true
        };

    payment = {
        cash: 'cash',
        bankTransfer: 'bankTransfer',
        cheque: 'cheque',
        card: 'card',
    }

    constructor(props) {
        super(props);

        props.apiRequestManager.register(actionTypes.FETCH_ACCOUNTS, props.getAccounts);
        props.apiRequestManager.register(actionTypes.FETCH_BILLED_BILLS, props.getBillableBills);
        props.apiRequestManager.register(actionTypes.PAYMENT_SUBMIT_DATA, props.submitData);
        props.apiRequestManager.register(actionTypes.FETCH_OWNER_ACCOUNT, props.getClientAccount);

        this.state = { 
            isFetchingBills: false,
            isMakingPayment: false,
            formErrors: {},
        };

        this.clientSelectRef = React.createRef();

        this.didSelectOption = this.didSelectOption.bind(this);
        this.handlePillSwitches = this.handlePillSwitches.bind(this);
        this.handleTextField = this.handleTextField.bind(this);
        this.handleAmountTextField = this.handleAmountTextField.bind(this);
        this.submitFormData = this.submitFormData.bind(this);
       
        this.setFormDefaults();
    }
    componentDidMount() {
        document.body.classList.add('payment-modal');
        this.props.validator.addDelegate(VALIDATOR_DELEGATE_TYPES.payment, paymentValidatorDelegate); 
    }

    componentWillUnmount() {
        document.body.classList.remove('payment-modal');
        this.props.pageConfig.disableBodyScroll(true);

        this.props.viewContext === 'overview' && this.props.pageConfig.useGrayBackground(true);
        this.props.unLoad();
    }

    static getDerivedStateFromProps(props, state) {
        // Return null to indicate no change to state.
        if ( !props.useClientLayout && !props.accounts && !props.apiRequestManager.inProgress(actionTypes.FETCH_ACCOUNTS) ) {
            
            props.apiRequestManager.queueRequest(actionTypes.FETCH_ACCOUNTS);
        }
        if (props.useClientLayout && props.account && !props.accounts) {
            props.apiRequestManager.queueRequest(actionTypes.FETCH_OWNER_ACCOUNT, props.account.owner);
        }
        return null;
    }

    componentDidUpdate(){
        this.syncProgress(this.props.apiRequestManager.inProgress(actionTypes.FETCH_BILLED_BILLS), this.state.isFetchingBills, (globalProgressValue) => this.setState({ isFetchingBills: globalProgressValue }));

        this.syncProgress(this.props.apiRequestManager.inProgress(actionTypes.PAYMENT_SUBMIT_DATA), this.state.isMakingPayment, (globalProgressValue) => this.setState({ isMakingPayment: globalProgressValue }));

        if (this.props.useClientLayout && this.props.accounts  && this.props.paymentForm.account === null ) {
            
            const matchAccount = this.props.accounts.filter((account) => account.owner === this.props.account.owner );
            
            const initalSelectedClient = this.composeSelectOption(matchAccount[0]);
            this.didSelectOption(initalSelectedClient);            
        }
    }

    syncProgress(globalProgressBool, localProgressBool, updateLocalProgressFn){
        if(typeof updateLocalProgressFn !== 'function') throw new Error(`updateLocalProgressFn must be a function, not of type ${typeof updateLocalProgressFn}`);

        if(globalProgressBool !== localProgressBool){
            if(globalProgressBool){
                updateLocalProgressFn(globalProgressBool);
            }else{
                setTimeout(() => {
                    updateLocalProgressFn(globalProgressBool);
                }, 1000);
            }
        }
    }

    setFormDefaults() {
        this.props.paymentFieldDidChange({paymentType: this.payment.cash});
    }
    didSelectOption(account) {
        this.props.paymentFieldDidChange( { 
            'account' : account,
            'amount': 0.0,
            'bills': null,
        });
        this.props.apiRequestManager.queueRequest(actionTypes.FETCH_BILLED_BILLS, account.owner); 
        this.props.setAccountBalance(account.balance);

        this.setState({
            formErrors: {
                ...this.state.formErrors,

                ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.payment, VALIDATOR_DELEGATE_KEY_GROUP.formFields.account, account),
            }
        })
    }

    handlePillSwitches  (event) {
        const fieldSet = {
            paymentType : event.target.value 
        };
        this.props.paymentFieldDidChange(fieldSet);
    }

    handleTextField  (event) {
        const key = event.target.name;
        let finalFieldValue = event.target.value;

        let fieldSet = {
           [key] : finalFieldValue
        };
        this.props.paymentFieldDidChange(fieldSet);

        this.setState({
            formErrors: {
                ...this.state.formErrors,

                ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.payment, VALIDATOR_DELEGATE_KEY_GROUP.formFields[key], finalFieldValue),
            }
        })
    }
    
    handleAmountTextField  (event) {
        if (!this.props.paymentForm.account ) return ;

        let fieldSet = {
           [event.target.name] : event.target.value === '' ? 0.0 : parseFloat(event.target.value)
        };
        
        let balance = this.calculateBalance(fieldSet.amount);

        if ( balance < 0) {
            fieldSet.amount = parseFloat(fieldSet.amount.toFixed(2)) + parseFloat(balance.toFixed(2));
            balance = balance - (balance * 1);
        }
        this.props.paymentFieldDidChange(fieldSet);
        this.props.setAccountBalance(  balance );
    
    }

    calculateBalance(value) {
        const accountBalance = !this.props.paymentForm.account ? 0 :parseFloat(this.props.paymentForm.account.balance);
        const balance = accountBalance - value;
        return balance;
    }

    submitFormData(evt) {
        if (this.props.paymentForm.amount === 0) return;
        //var isUpdate = this.props.mode === 'edit' ? true : false;
        let billLines = this.props.bills.map((bill) => {
            return bill.id;
        });

        let data = {
            requestType: 'create' ,
            ...this.props.paymentForm,
            account: this.props.paymentForm.account.id,
            bills: billLines
        }

        const formErrors = this.validateFields(data);

        if (!this.props.validator.hasActiveFormError(formErrors) && data.amount > 0) {
            this.props.apiRequestManager.queueRequest(actionTypes.PAYMENT_SUBMIT_DATA, data);
        }else{
            this.setState({
                formErrors
            })
        }
    }

    validateFields(data){
        let chequeErrors = { }

        if(data.paymentType === this.payment.cheque){
            chequeErrors = {
                ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.payment, VALIDATOR_DELEGATE_KEY_GROUP.formFields.chequeNumber,data.chequeNumber),
    
                ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.payment, VALIDATOR_DELEGATE_KEY_GROUP.formFields.bank,data.bank),
            }
        }
        
        return {
            ...chequeErrors,

            ...this.props.validator.delegateCheckFieldError(VALIDATOR_DELEGATE_TYPES.payment, VALIDATOR_DELEGATE_KEY_GROUP.formFields.account,data.account),
        }
    }

    composeSelectOption(account) {
        return {
            ...account,
            id: account.id,
            label: `${account.clientInfo.firstName} ${account.clientInfo.lastName}`,
            value: account.accountNo,
        }
    }

    render () {

        const clients = !this.props.accounts ? [] : this.props.accounts.map((account) => {
            return this.composeSelectOption(account);
        });

        const defaultPayment = this.props.paymentForm.paymentType === '' ? true : this.props.paymentForm.paymentType === this.payment.cash ? true: false;
        const initalSelectedClient = this.props.paymentForm.account;
        
        const billsData = this.props.bills  ?  this.props.bills : [];
        const charCount = `${this.props.paymentForm.amount}`.length;
        const inputWidth = charCount + 1.5;

        const formFields = VALIDATOR_DELEGATE_KEY_GROUP.formFields;
        const enablePaymentButton = this.props.paymentForm?.amount > 0 && !this.props.apiRequestManager.inProgress(actionTypes.PAYMENT_SUBMIT_DATA) ? true : false

        return (
            <React.Fragment>
            <div id='pay-POS'>
                <Section className="header-section">
                    <div className="title-box">
                        <div className="title-area">
                            <span className="icon">
                                <img src={getIconUrl('payment')} alt='' />
                            </span>
                            <h1>Make a Payment</h1>
                        </div>
                        <div className="quick-action">

                        </div>
                    </div>
                </Section>
                <div className="inner">
                    <div className="date-bar">
					</div>	
                    
                <Section id="top">

                    <FormGroup>
                        <Label className="ip-radio-pill">
                            <Input 
                            onChange={this.handlePillSwitches } 
                            value={this.payment.cash }
                            checked={ defaultPayment } 
                            
                            type="radio" name="payment" />{' '}
                                <span className="radioselect"></span>
                                <span className="text">Cash</span>
                        </Label>
                        <Label className="ip-radio-pill">
                            <Input 
                            onChange={this.handlePillSwitches } 
                            value={this.payment.cheque }
                            type="radio" name="payment"
                            checked={this.props.paymentForm.paymentType === this.payment.cheque ? true: false}
                             />{' '}
                                <span className="radioselect"></span>
                                <span className="text">Cheque</span>
                        </Label>
                        <Label className="ip-radio-pill">
                            <Input 
                            onChange={this.handlePillSwitches } 
                            value={this.payment.card }
                            checked={this.props.paymentForm.paymentType === this.payment.card ? true: false}
                            type="radio" name="payment" />{' '}
                                <span className="radioselect"></span>
                                <span className="text">Card</span>
                        </Label>
                        <Label className="ip-radio-pill">
                            <Input 
                            onChange={this.handlePillSwitches }
                            value={this.payment.bankTransfer } 
                            checked={this.props.paymentForm.paymentType === this.payment.bankTransfer ? true: false}
                            type="radio" name="payment" />{' '}
                                <span className="radioselect"></span>
                                <span className="text">Bank Transfer</span>
                        </Label>
					</FormGroup>
                    { this.props.paymentForm.paymentType === this.payment.cheque &&
                    <div className="cheque-fields">
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Cheque No.</Label>
                                    <ValidateField>
                                        <InputField 
                                        id={formFields.chequeNumber} 
                                        type="text"
                                        name={formFields.chequeNumber}
                                        onChange={ this.handleTextField }
                                        value={this.props.paymentForm.chequeNumber }
                                        className="ip-text-default" 
                                        placeholder="000012"/>

                                        <ErrorFeedback 
                                        show={this.state.formErrors.chequeNumberMissing}
                                        warning
                                        filter='valueMissing'
                                        message={<ErrorMessage message ='Cheque number is required' />} />
                                    </ValidateField>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>Bank</Label>
                                    <ValidateField>
                                        <InputField 
                                        id={formFields.bank} 
                                        type="text"
                                        name={formFields.bank}
                                        onChange={ this.handleTextField }
                                        value={this.props.paymentForm.bank }
                                        className="ip-text-default" 
                                        placeholder="Bank name"/>

                                        <ErrorFeedback 
                                        show={this.state.formErrors.bankMissing}
                                        warning
                                        filter='valueMissing'
                                        message={<ErrorMessage message ='Bank name is required' />} />
                                    </ValidateField>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                    }
                    <FormGroup>
                        <div className="select-wrapper">
                            <div className="select-well">
                                { 
                                    <ValidateField required>
                                        <SimpleSelect
                                            components={{
                                                Indicator: Indicator,
                                                Option: Option,
                                                SelectedOption: SelectedOption
                                                }}
                                            className="ip-ff-select"
                                            ref={this.clientSelectRef}
                                            name="Client"
                                            title="Choose client"
                                            searchable={["Type Name or Account No.", "No matching client"]}
                                            filterKeys={['label',
                                                        'accountNo']}
                                            list={clients}
                                            onChange={this.didSelectOption}
                                            initialSelected={ initalSelectedClient }
                                            disabled={this.props.useClientLayout}
                                        />

                                        <ErrorFeedback 
                                        show={this.state.formErrors.accountMissing}
                                        warning
                                        filter='valueMissing'
                                        message={<ErrorMessage message ='Please select an account' />} />
                                    </ValidateField>
                                }                            
                            </div>
                        </div>
					</FormGroup>
                </Section>
    
				<Section id="mid" >
				<div className="customer info">
					<Row>
                    <Col>
                    { this.props.paymentForm.account && 
                        <React.Fragment>
                        <h2>{this.props.paymentForm.account.clientInfo.firstName} {this.props.paymentForm.account.clientInfo.lastName}</h2>
                        <div className="info-line"> 
                            <p>Office Name : {this.props.paymentForm.account.clientInfo.officeName || '--'}</p>
                            <p>Email   : {this.props.paymentForm.account.clientInfo.email}</p>
                            <p>Labcode   : {this.props.paymentForm.account.clientInfo.labCode.code} </p>
                        </div>
                        </React.Fragment>
                    }
                    </Col>
                    <Col>
                    <div className="payment">
                        <FormGroup>
                            <p>Account Balance</p>
                            <h2 className="acc-balance">${this.props.accountBalanace.toCurrency()}</h2>
                        </FormGroup>
                        <FormGroup>
                            <p>Amount</p>
                            <InputField 
                            id="pay-amt" 
                            type="number"
                            name="amount"
                            min={0}
                            style={{ width: `${inputWidth}ch`}}
                            onChange={ this.handleAmountTextField }
                            value={this.props.paymentForm.amount }
                            className="ip-text-default pay-amt" 
                            placeholder="0.0"/>
                        </FormGroup>
                    </div>
                    </Col>
                    </Row>
				</div>
				</Section>
    
    			<Section id="bot">
					<div id="table">
						<table>
                            <thead>
                                <tr className="tabletitle">
                                    <th className="item"><h2>Bill</h2></th>
                                    <th className="date"><h2>Due Date</h2></th>
                                    <th className="amount"><h2>Due Amount</h2></th>
                                </tr>
                            </thead>
                            {
                                this.state.isFetchingBills ? 
                                <>
                                <tbody>
                                    <tr>
                                        <td className="--no-item"></td>
                                        <td className="payment__loader-container">
                                            <Loader visible={this.state.isFetchingBills} loadingText="Loading Bills..." />
                                        </td>
                                        <td className="--no-item"></td>
                                    </tr>
                                </tbody>
                                </>
                                :
                                <tbody>
                                { billsData.length > 0 ? 
                                billsData.map((bill, index) =>  {
                                
                                const amountDue = bill.amountDue - bill.amountPaid ;

                                return <tr className="service" key={index}>
                                        <td className="tableitem item"><p className="itemtext">{bill.referenceNo}</p></td>
                                        <td className="tableitem date"><p className="itemtext">{bill.dueDate}</p></td>
                                        <td className="tableitem amount"><p className="itemtext">${amountDue.toCurrency()}</p></td>
                                    </tr>
                                })
                                : 
                                <tr className="">
                                    <td className="--no-item">
                                    </td>
                                    <td className="--no-item">
                                        <p className="itemtext">No billable Bills</p>
                                    </td>
                                    <td className="--no-item">
                                    </td>
                                </tr>
                                }  
                                </tbody>
                            }
						</table>
					</div>
					<div id="total" className="tabletitle">
						<div className="label">Total</div>
						<div className="payment"><span> - </span>${this.props.paymentForm.amount.toCurrency()}</div>
					</div>
					<div id="legalcopy">
						<p className="legal"><strong>Thank you for your business!</strong>  Payment is expected within 31 days; please process this invoice within that time. There will be a 5% interest charge per month on late invoices. 
						</p>
					</div>
                    <Authority allowed='create' entity='payment'>
                        <Section className="actions-sec">
                            <div className="btn-group">
                                {
                                    this.state.isMakingPayment ? <Loader visible={this.state.isMakingPayment} /> : null
                                }
                                <Button
                                disabled={!enablePaymentButton} 
                                onClick={this.submitFormData}
                                className="pay-btn">
                                    <span className="icon"></span>Make Payment
                                </Button>
                        </div>
                        </Section>
                    </Authority>
                    
				</Section>
                </div>
            </div>
            </React.Fragment>
        );
    }
}

const PayView = (props) => {
    return (
        <Pay {...props } />
    );
};

let ConfigPayView = setupPage(Pay.pageOption)(PayView);
ConfigPayView =  connect(mapStateToProps, mapDispatchToProps)(ConfigPayView);

export { ConfigPayView };