import React from 'react';
import PropTypes from 'prop-types';

const LayoutNavbar = React.forwardRef((props, ref) => {
    const navbar = React.Children.only(props.children);
    return (
        <div ref={ref} className="layout__navbar">
            {
                React.cloneElement(navbar, { fixed: null })
            }
        </div>
    ); 

});

LayoutNavbar.propTypes = {
    children: PropTypes.node
};

LayoutNavbar.layoutPartName = "navbar";

export { LayoutNavbar };