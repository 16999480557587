import { ConfigPayrollView } from './Payroll';
import { ConfigDetailsView as PayrollDetails} from './Details';
import {ConfigPayslipView as Payslip } from './Payslip';
import { ConfigPayrollWizardView as PayrollWizard } from './PayrollWizard';
import { EarningsPane } from './EarningsPane';
import { TaxesPane  } from './TaxesPane';
import { ReviewPane  } from './ReviewPane';
import { CompletePane} from './CompletePane';

export { PayrollDetails, Payslip, PayrollWizard, EarningsPane, TaxesPane, ReviewPane, CompletePane }
export default ConfigPayrollView;