import React from 'react';

import classNames from 'classnames';

const icons = {
    ArrowDown: <svg width="18" height="11" viewBox="0 0 18 11" fill="none">
    <path d="M1.60909 -1.90735e-06L0 1.5895L9 10.4211L18 1.58057L16.3909 -1.90735e-06L9 7.25991L1.60909 -1.90735e-06Z" fill="black"/>
    </svg>,
    ArrowUp: <svg width="18" height="11" viewBox="0 0 18 11" fill="none" >
    <path d="M16.3909 10.4211L18 8.83155L9 0L1.5714e-07 8.84048L1.60909 10.4211L9 3.16114L16.3909 10.4211Z" fill="black"/>
    </svg>
};

const getIconUrl = (name) => {
    return icons[name].default;
}

const Indicator = (props) => {

    const { className, inFocus, normalIcon, focusIcon, innerProps } = props;
    
    const UpIcon =  focusIcon ? focusIcon : icons.ArrowUp
    const  DownIcon =  normalIcon ? normalIcon :icons.ArrowDown;
    const icon = inFocus ? UpIcon : DownIcon;
    const focusClassname = inFocus ? '--focus' : '';

    return <span className={ `icon ${className} ${focusClassname}`} style={props.getStyles}>{ icon }</span>
}

export default Indicator;