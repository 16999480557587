import * as actionTypes from '../actionType';
import { isError } from '../../service/agent';

const initialState = {
    viewInit: false,
    isGenerating: false,
    pageForm: {
        pageHeader: null,
        subHeader: null,
        notes: null,
        footer: null,
    },
    reportForm: {
        reportType: null,
        fromDate: null,
        toDate: null,
        options: {

        }
    },
    reportSummary: null,
    reportItem: {},
    reportResults: [],
    labCodes: [],
    codeSheets: [],
    codeFindings: [],
    clientsData: []
 };
var error = null;
const reportsReducer = (state = initialState, action ) => { 
    
    switch(action.type) {
        case actionTypes.REPORTING_VIEW_UNLOAD:
           return {
               ...initialState,
               reportSummary: state.reportSummary
           };
        case actionTypes.REPORTS_VIEW_UNLOAD:
            return {
                ...initialState
            };
        case actionTypes.SET_REPORT_ITEM:
           return {
               ...state,
               reportItem: action.payload
           };
        case actionTypes.PRINT_FIELD_CHANGED:
           return {
               ...state,
               pageForm: {
                   ...state.pageForm,
                   ...action.payload
               }
           };
    
        case actionTypes.REPORT_FIELD_CHANGED:
            return {
                ...state,
                reportForm: {
                    ...state.reportForm,
                    ...action.payload
                }
            };
        case actionTypes.FETCHED_REPORTS_SUMMARY_RESULTS:
            error = isError(action.payload);
            return {
                ...state,
                reportSummary: error ? {} : action.payload,
            };
        case actionTypes.RUN_REPORT:
            return {
                ...state,
                reportResults:[],
                isGenerating: true
            };
        case actionTypes.RUN_REPORT_RESULTS:
            error = isError(action.payload);
            return {
                ...state,
                reportResults: error ? [] : action.payload,
                isGenerating: false
            };
        case actionTypes.FETCH_REQUISITION_SPECIMENS:
            return {
                ...state,
                reportResults:[],
                isGenerating: true
            };
        case actionTypes.FETCHED_REQUISITION_SPECIMENS_RESULTS:
            error = isError(action.payload);
            return {
                ...state,
                reportResults: error ? [] : action.payload,
                isGenerating: false
            };
        case actionTypes.FETCHED_LABCODES_RESULTS:
            error = isError(action.payload);
            return {
                ...state,
                labCodes: error ? [] : action.payload
            };
        case actionTypes.FETCHED_CODE_FINDINGS_RESULTS:
            error = isError(action.payload);
            return {
                ...state,
                codeFindings: error ? [] : action.payload
            };
        case actionTypes.FETCHED_CODE_SHEETS_RESULTS:
            error = isError(action.payload);
            const codesheets = action.payload.codesheets || action.payload;
            return {
                ...state,
                codeSheets: error ? [] : codesheets
            };
        case actionTypes.FETCHED_CLIENTS_RESULTS:
            error = isError(action.payload);
            return {
                ...state,
                clientsData: error ? [] : action.payload.clients
            };
        case actionTypes.REPORT_FILTER_RESET:
            return {
                ...state,
                reportForm: {
                    ...state.reportForm,
                    options: {}
                }
            };
        default:
            return state;
    }
};

export default reportsReducer;