import { FEATURE_FLAG } from "../common/constant";

const FEATURES = {
    Loans: FEATURE_FLAG.OFF,
    ReportSetting: FEATURE_FLAG.OFF,
    SecuritySetting: FEATURE_FLAG.OFF,
    Appointment: FEATURE_FLAG.OFF,
    ClientAddress: FEATURE_FLAG.ON,
    TwoFactorAuthentication: FEATURE_FLAG.OFF,
    NetworkErrorToast: FEATURE_FLAG.OFF
};

export default FEATURES;