import { VALIDATOR_DELEGATE_KEY_GROUP } from "../common/constant";

const delegate = (validator, key, value) => {
    const results = {};

    switch(key){
        case VALIDATOR_DELEGATE_KEY_GROUP.formFields.email:
            results.emailMissing = false;
            results.emailInvalid = false;

            if(validator.isValueMissing(value)) results.emailMissing = true
            if(!validator.isEmailValue(value)) results.emailInvalid = true;
            break;

        case VALIDATOR_DELEGATE_KEY_GROUP.formFields.firstName:
            results.firstNameMissing = false;
            results.firstNameInvalid = false;

            if(validator.isValueMissing(value)) results.firstNameMissing = true;
            if(!validator.isTextValue(value)) results.firstNameInvalid = true;
            break;
        
        case VALIDATOR_DELEGATE_KEY_GROUP.formFields.lastName:
            results.lastNameMissing = false;
            results.lastNameInvalid = false;

            if(validator.isValueMissing(value)) results.lastNameMissing = true;
            if(!validator.isTextValue(value)) results.lastNameInvalid = true;
            break;

        case VALIDATOR_DELEGATE_KEY_GROUP.formFields.middleName:
            // results.middleNameMissing = false;
            results.middleNameInvalid = false;

            // if(validator.isValueMissing(value)) results.middleNameMissing = true;
            if(!validator.isValueMissing(value) && !validator.isTextValue(value)) results.middleNameInvalid = true;
            break;

        case VALIDATOR_DELEGATE_KEY_GROUP.formFields.motherMaidenName:
            // results.motherMaidenNameMissing = false;
            results.motherMaidenNameInvalid = false;

            // if(validator.isValueMissing(value)) results.motherMaidenNameMissing = true;
            if(!validator.isValueMissing(value) && !validator.isTextValue(value)) results.motherMaidenNameInvalid = true;
            break;

        case VALIDATOR_DELEGATE_KEY_GROUP.formFields.phoneNumber:
            results.phoneNumberMissing = false;
            results.phoneNumberInvalid = false;

            if(validator.isValueMissing(value)) results.phoneNumberMissing = true;
            if(!validator.isPhonenumber(value)) results.phoneNumberInvalid = true;
            break;

        case VALIDATOR_DELEGATE_KEY_GROUP.formFields.client:
            results.clientMissing = false;
            // results.clientInvalid = false;

            //if(validator.isEmptyValue(value)) results.clientMissing = true;
            break;

        default:
            break;
    }

    return results;
}
export default delegate;