import React from 'react';

class ProgressRing extends React.Component {
    constructor(props) {
      super(props);
  
      const { radius, stroke } = this.props;
  
      this.normalizedRadius = radius - stroke * 2;
      this.circumference = this.normalizedRadius * 2 * Math.PI;
    }

    render() {
        const { radius, color, stroke, progress, label } = this.props;
        const strokeDashoffset = this.circumference - progress / 100 * this.circumference;
        const fullCircumference = 100.0;

        const strokeColor = color ? color : "#2d91f3";
      
        return (<React.Fragment>
            <div 
            className="progress-widget"
            height={radius * 2}
            width={radius * 2}>
                <svg
                    className={"progress-ring"}
                    height={radius * 2}
                    width={radius * 2}
                    >
                    
                    <circle
                    stroke="#dcdcdc"
                    fill="transparent"
                    strokeWidth={ stroke }
                    strokeDasharray={ this.circumference + ' ' + this.circumference }
                    style={ { fullCircumference } }
                    strokeWidth={ stroke }
                    r={ this.normalizedRadius }
                    cx={ radius }
                    cy={ radius }
                    />
                    <circle
                    stroke={strokeColor}
                    fill="transparent"
                    strokeWidth={ stroke }
                    strokeDasharray={ this.circumference + ' ' + this.circumference }
                    style={ { strokeDashoffset } }
                    strokeWidth={ stroke }
                    r={ this.normalizedRadius }
                    cx={ radius }
                    cy={ radius }
                    />
                </svg>
                <div className="stats">
                    <span>{progress}%</span>
                    <p>{label}</p>
                </div>
          </div>
          </React.Fragment>
        );
      }
    
}

export default ProgressRing;