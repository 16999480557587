import * as actionTypes from '../actionType';
import { isError } from '../../service/agent';
import { ApiRequestManager } from '../../utility/ApiRequestManager';

const initialState = {
    viewInit: false,
    patientsList: null,
    clientsData: null,
    malePatients: null,
    femalePatients: null,
    recordsList: null,

    patientForm: {
        profileImage: null,
        registrationNo: '',
        firstName: '',
        lastName: '',
        middleName: '',
        email: '',
        gender: '',
        dateOfBirth: '',
        phoneNumber: '',
        age: 0,
        height: 0.0,
        weight: 0.0,
        motherMaidenName: '',
        selectedClients: [],
        address: {

        }
    }
 };

var error = null;
let emptyArray = [];
let requestManager = null;
let apiOutcome = null;

const patientReducer = (state = initialState, action ) => { 

    switch(action.type) {
        case actionTypes.PATIENTS_VIEW_UNLOAD: 

            if (state.currentPatient) {
                return {
                    ...initialState,
                    currentPatient: state.currentPatient
                };
            }else {
                return {
                    ...initialState
                };
            }
        case actionTypes.PATIENTS_CREATE_VIEW_LOADED: 
            return {
                ...state,
                ...initialState,
                viewInit: true
            };
        case actionTypes.PATIENTS_CREATE_VIEW_UNLOAD: 
            return {
                ...initialState
            };
        
        case actionTypes.FETCHED_PATIENTS_RESULT: 
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.FETCH_PATIENTS) ?? {};
            return {
                ...state,
                patientsList: apiOutcome?.success ? action.payload : emptyArray
            };

        case actionTypes.SET_SPLIT_PATIENTS: 
            return {
                ...state,
                malePatients: action.payload.males,
                femalePatients: action.payload.females
            };
        case actionTypes.SET_PATIENT_DATA:
            return {
                ...state,
                currentPatient: action.payload
            }
        case actionTypes.PATIENT_FIELD_CHANGED :
            return {
                ...state,
                patientForm: {
                ...state.patientForm,
                ...action.payload
            }
        }
        case actionTypes.FETCHED_CLIENTS_RESULTS:
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.FETCH_CLIENTS) ?? {};
            return {
                ...state,
                clientsData: apiOutcome?.success ? action.payload : emptyArray
            }
        case actionTypes.FETCHED_PATIENT_RESULT:
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.FETCH_PATIENT) ?? {};
            return {
                ...state,
                currentPatient: apiOutcome?.success ? action.payload : null
            }
        case actionTypes.PATIENT_SUBMIT_DATA:
            return {
                ...state,
                currentPatient: null
            }

        case actionTypes.PATIENT_SUBMITED_RESULTS:
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.PATIENT_SUBMIT_DATA) ?? {};

            if (apiOutcome.requestType === 'delete') {
                const isDeletedResults = apiOutcome?.error ? {} : action.payload;
                return {
                    ...state,
                    currentPatient: apiOutcome?.error ? state.currentPatient : null,
                    patientForm: apiOutcome?.error ? state.patientForm : initialState.patientForm,
                    ...isDeletedResults
                }
            }else {
                return {
                    ...state,
                    currentPatient: apiOutcome?.success ? action.payload : null
                }
            }
        case actionTypes.FETCHED_PATIENT_SPECIMENS_HISTORY_RESULTS:
            error = isError(action.payload)
            return {
                ...state,
                recordsList: error? emptyArray : action.payload
            }
        
        default:
            return state;
    }
};

export default patientReducer;