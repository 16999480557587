import React from 'react';

import  * as actionTypes  from '../../../store/actionType';
import TemplateFooter from './TemplateFooter';
class Report_RP01 extends React.PureComponent  {
    
    componentDidMount() {
        const customizeReport = this.props.customize;
        customizeReport && this.props.apiRequestManager.queueRequest(actionTypes.FETCH_LABCODES);
    }
    render () {

        const {ReportHeader, ReportFooter, ReportNotes} = this.props;
        const reportData = Array.isArray(this.props.reportResults) ? this.props.reportResults : [];
        console.log(this.props);
        
        return (
            <div  className="print-template">
                { ReportHeader }
                <div className="report-body">

                    <table className="default">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Patient</th>
                                <th>Client</th>
                                <th>Details</th>
                                <th>Status</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            { reportData.map((data, i) => {
                                
                                const status = data.statuses[ data.statuses.length - 1];
                                const patientName = `${data.patient.firstName} ${data.patient.firstName}`;

                                const clientName = data.client.officeName ? data.client.officeName : `${data.client.firstName} ${data.client.firstName}`;

                                return (<tr key={i} className="smpl-row row-divider">
                                    <td>
                                        <p>{data.identifier}</p>
                                        <p>{data.formType}</p>
                                    </td>
                                    <td>
                                        <div className="info-line">{patientName}</div>
                                        <div className="info-line">R# {data.patient.registrationNo}</div>
                                        <div className="info-line">{data.patient.gender}</div>
                                    </td>
                                    <td>
                                        <div className="info-line">{clientName}</div>
                                        <div className="info-line">AC# {data.client.accountNo}</div>
                                        <div className="info-line">{data.client.type}</div>
                                    </td>
                                    <td>{data.urgent ? 'Urgent' : '--'}</td>
                                    <td>{status.status}</td>
                                    <td>{data.simpleDate}</td>
                                </tr>);
                            })}
                        </tbody>
                    </table>
                </div>
                { <TemplateFooter 
                    ReportFooter={ReportFooter}  
                    ReportNotes={ReportNotes}
                    /> 
                }
            </div>   
        ) 
    }  
}

export default Report_RP01;