import { requests , File} from './agentDialect';
import { httpRequests } from './httpRequestDialect';
import { formDetailsSchema } from './schemas';
import { objectToQueryString } from '../utility/helpers';

export const fetchFromDetails = (form) => {
    let uri = `/form/details?form=${form}`;
    return requests.get(uri);
};

export const fetchAllSpecimenRecords = (payload) => {
    let uri = !payload.userType ? "/specimens" : `/specimens?userType=${payload.userType}`;
    uri +=  payload.workspace ? `&workspace=${payload.workspace}` : '';
    uri +=  payload.page ? `&page=${payload.page}` : '';

    return requests.get(uri);
};

export const fetchAllClientSpecimenRecords = (params) => {
    const {clientId, ...queryParams} = params;
    queryParams['client'] = clientId;
    let uri =  '/specimens/billable';
    uri = objectToQueryString(uri, queryParams);
    return requests.get(uri);
};

export const fetchSpecimenRecord = (recordId) => {
    let uri = `/specimens/${recordId}`;
    return requests.get(uri);
};
export const fetchRequisition = (requisitionId) => {
    let uri = `/requisitions/${requisitionId}`;
    return requests.get(uri);
};

export const fetchAllApprovedSpecimenRecords = (payload) => {
    let uri = !payload.workspace ? "/specimens/approved" : `/specimens/approved?workspace=${payload.workspace}`;
    uri +=  payload.page ? !payload.workspace ? `?page=${payload.page}` : `&page=${payload.page}`: '';
    return requests.get(uri);
};

export const fetchAllRecentSpecimenRecords = (payload) => {
    let uri = !payload.workspace ? "/specimens/recent" : `/specimens/recent?workspace=${payload.workspace}`;
    uri +=  payload.page ? !payload.workspace ? `?page=${payload.page}` : `&page=${payload.page}`: '';
    return requests.get(uri);
};
export const fetchClientSpecimenRecords = (params) => {
    const {clientId, ...queryParams} = params;
    queryParams['clientId'] = clientId;
    let uri =  '/specimens/client';
    uri = objectToQueryString(uri, queryParams);

    return requests.get(uri);
};
export const fetchPatientSpecimenRecords = (params) => {
    const {client, patientId, ...queryParams} = params;
    queryParams['patientId'] = patientId;
    queryParams['client'] = client;
    let uri =  '/specimens/patient';
    uri = objectToQueryString(uri, queryParams);
    return requests.get(uri);
};
export const createSpeciment = (data) => {
    let uri = "/specimen/create";
    return requests.post(uri, data);
};

export const submitSpeciment = (data) => {
    let uri = `/specimen/submit/${data.recordId}`;
    return requests.put(uri, {hasUrgent: data.hasUrgent});
};

export const updateSpeciment = (data) => {
    let uri = `/specimen/update/${data.recordId}`;
    return requests.put(uri, data);
};

export const deleteSpecimen = (data) => {
    let uri = `/specimen/delete/${data.recordId}`;
    return requests.delete(uri, data);
};

export const addSpecimenStatus = (data) => {
    let uri = `/specimen/status/${data.recordId}`;
    return requests.patch(uri, {status: data.status});
};

export const submitResultSheet = (data) => {
    let uri = `/resultsheet/create`;
    return requests.post(uri, data);
};

export const updateResultSheet = (data) => {
    let uri = `/resultsheet/update/${data.resultSheetId}`;
    return requests.put(uri, data);
};

export const resultSheetReports = (data) => {
    let uri = `/resultsheet/reports/${data.resultSheetId}`;
    return requests.put(uri, data);
};

export const approveResultSheet = (data) => {
    let uri = `/resultsheet/approve/${data.resultSheetId}`;
    return requests.put(uri, {approve: data.approve});
};

export const attachFileUpload = (data) => {
    let uri = "/specimen/attachment";
    return File.upload(uri, data);
};

export const fetchAllRequisitions = (payload) => {
    let uri = `/requisitions`;
    uri +=  payload?.page ? `?page=${payload.page}` : '';

    return requests.get(uri);
}

export const fetchClientRequisitions = (clientId, page) => {
    let uri = `/requisitions/client/${clientId}`;
    uri +=  page ? `?page=${page}` : '';
    return requests.get(uri);
}

export const createRequisition = (data) => {
    let uri = `/requisition/create`;
    return requests.post(uri, data);
}

export const deleteRequistion = (data) => {
    let uri = `/requisition/delete/${data.requisitionId}`;
    return requests.delete(uri, data);
};

export const deleteRequistionItem = (data) =>{
    let uri = `/requisition/item/delete/${data.itemId}`;
    return requests.delete(uri, data);
}

export const fetchRequisitionSpecimenRecords = (requisitionId) => {
    let uri =  `/specimens/requisition?requisitionId=${requisitionId}`;
    return requests.get(uri);
};
// New way
export const getFromDetails = (form) => {
    let uri = `/form/details?form=${form}`;
    return httpRequests.get(uri, formDetailsSchema);
};

export const getSpecimensForCabinet = (payload) => {

    let {cabinetId, filterLetter, ...queryParams} = payload;
    let uri = `/cabinet/records/${cabinetId}`;

    queryParams['q'] = filterLetter;
    uri = objectToQueryString(uri, queryParams);

    return requests.get(uri);
}

export const getCabinets = () => {
    let uri = `/cabinets`;
    return requests.get(uri);
}

export const createCabinet = (data) => {
    let uri = `/cabinet/create`;
    return requests.post(uri, data);
}

export const updateCabinet = (data) => {
    let uri = `/cabinet/update/${data.cabinetId}`;
    return requests.put(uri, data);
}

export const deleteCabinet = (data) => {
    let uri = `/cabinet/delete/${data.cabinetId}`;
    return requests.delete(uri, data);
}