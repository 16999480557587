import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    Layout,
    ThemeProvider,
    PageConfigConsumer
} from './../components';

import  MainNavigation  from '../pages/components/partials/Navigation';
import  WorkspaceBar  from '../pages/components/partials/WorkspaceBar';
//Styles Import
import "react-sliding-pane/dist/react-sliding-pane.css";
import './../assets/styles/bootstrap.scss';
import './../assets/styles/global.scss';
import {ERROR_CODES, FEATURE_FLAG} from './../common/constant';
import { ErrorToast } from '../pages/components/partials/ErrorToast';
import LoadingIndicator from "../pages/components/partials/LoadingIndicator";
import FEATURES from './../config/featureFlags';
import { debounce } from '../utility/helpers';

const  mapStateToProps = state => {
    return {
        workspace: state.global.workspace,
        account: state.global.account,
        syncInProgress: state.global.syncInProgress,
        errors: state.global.errors
    }
};

const mapDispatchToProps = dispatch => ({
});

const RESIZE_DEBOUNCE_TIME_MS = 200;
class DefaultLayout extends React.Component {
    
    static propTypes = {
        children: PropTypes.node.isRequired
    }

    constructor(props) {
        super(props);

        this.state = {
            navHeight: 0,
        }

        this.navBarRef = React.createRef();
        this.getNavHeight = this.getNavHeight.bind(this);
        this.handleWindowResize = this.handleWindowResize.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize);
    }

    getNavHeight() {
        return this.navBarRef.current ? this.navBarRef.current.clientHeight : 0;
    }
    
    componentDidUpdate(prevProps, prevState) {
        const navHeight = this.getNavHeight();

        if(prevState.navHeight !== navHeight) {
            this.setState({navHeight});
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    handleWindowResize = debounce((event) => {
        this.setState({navHeight: this.getNavHeight()});
    }, RESIZE_DEBOUNCE_TIME_MS)
    
    render() {
        const { children, history, useClientDashboard, authorizeUser, account} = this.props;
        const useClientDashboardLayout = useClientDashboard;
        const showProgressIndicator = this.props.syncInProgress;

        const  showErrorToast = this.props.networkStatus && (!this.props.networkStatus.isOnline || !this.props.networkStatus.isConnected);
        const errorMessage = showErrorToast ? ERROR_CODES.ERR_SERVER_COMMUNICATION : "";
        return  (
        
            <ThemeProvider initialStyle="light" initialColor="primary">
                <Layout>
                    <Layout.Navbar ref={this.navBarRef}>
                        <>
                            <MainNavigation 
                                accountInfo={account} 
                                currentUser={authorizeUser} 
                                clientLayout={useClientDashboard} history={history}/>

                            {/* -------- Worspacebar Bar ------*/}
                            <Layout.WorkspaceBar
                                clientLayout={useClientDashboardLayout}>
                                <WorkspaceBar 
                                    accountData={this.props.account} 
                                    workspaceData={this.props.workspace} 
                                    show={useClientDashboardLayout} />  
                            </Layout.WorkspaceBar>
                        </>
                    </Layout.Navbar>
                    
                    { /* -------- Content ------------*/ }
                    <Layout.Content clientLayout={useClientDashboardLayout} navBarHeight={this.state.navHeight} >
                        { FEATURES.NetworkErrorToast === FEATURE_FLAG.ON && showErrorToast && <ErrorToast message={errorMessage} />}
                        <LoadingIndicator visible={showProgressIndicator ? true : false} />
                        { children }
                    </Layout.Content>
                    {/* ---- Theme Selector (DEMO) ----*/ }
                    <PageConfigConsumer>
                    {
                        () => (
                            <div></div>
                        )
                    }
                    </PageConfigConsumer>
                    <div className='build-version'>
                        <span className='beta-badge'>
                            BETA 1
                        </span>
                        <span className='build-number'>
                            B1.0.0
                        </span>
                    </div>
                </Layout>
            </ThemeProvider>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);