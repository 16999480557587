import * as actionTypes from '../../actionType';
import { isError } from '../../../service/agent';

export const serviceState = {
    serviceSetting : {
        servicesData: null
    }
}
    
var error = null;
let empty = {}
let emptyArray = [];

const serviceReducer = (state, action ) => { 
    switch(action.type) {
        case actionTypes.SET_SERVICES_DATA:
            return {
                ...state,
                serviceSetting: {
                    ...state.serviceSetting,
                    servicesData: action.payload
                }
            }
        case actionTypes.SERVICE_SUBMIT_DATA:
            return {
                ...state,
                requestType: action.payload.requestType,
                isSaving: true
            }
        case actionTypes.FETCHED_SERVICES_RESULT:
            error = isError(action.payload);
            return {
                ...state,
                serviceSetting: {
                    ...state.serviceSetting,
                    servicesData: error ? emptyArray : action.payload.map((item, i) => {
                        return {
                            index: i,
                            ...item
                        }
                    })
                }
            }
        case actionTypes.SERVICE_SUBMIT_DATA_RESULT:
            error = isError(action.payload);
            if (state.requestType === 'delete') {
                const filteredServices =  error ? state.serviceSetting.servicesData  : state.serviceSetting.servicesData.filter((item) => item.id !== action.payload.deletedId);
                return {
                    ...state,
                    isSaving: false,
                    serviceSetting: {
                        ...state.serviceSetting,
                        servicesData: filteredServices
                    }
                }
            }else {
                return {
                    ...state,
                    isSaving: false,
                    serviceSetting: {
                        ...state.serviceSetting,
                        servicesData: error ? state.serviceSetting.servicesData : action.payload.map((item, i) => {
                            return {
                                index: i,
                                ...item
                            }
                        })
                    }
                }
            }
        default:
            return state;
    }
};

export default serviceReducer;
