import * as actionTypes from '../../store/actionType'
import * as actions from '../../store/actions'
import {JWT_COLLECTION_KEY, SAVED_USERS_KEY } from '../../common/constant';
import { setToken, setWorkspaceId } from '../../service/agentDialect';
import { isError } from '../../service/agent';
import { decodeJwtToken } from "./../../utility/helpers";
import { PermissionEnforcer } from "./../../utility/PermissionEnforcer";

let jwtCollection;

const jwtStorageMiddleware = store => next => action => {
   const enforcer = PermissionEnforcer.getInstance(PermissionEnforcer);
   if ( action.type === actionTypes.USER_DETAILS) {
      enforcer.authorities = action.payload.claims.authorities;
      enforcer.superRole = action.payload.superRole;
   }

   if ( action.type === actionTypes.REQUEST_AUTH_USER_RESULT) {
      window.localStorage.setMap('workspaceContext',  action.payload.workspaceBounds);
      setWorkspaceId( action.payload.workspaceBounds);
   }
   
   if ( action.type === actionTypes.AUTH_RESULTS || action.type === actionTypes.REFRESH_JWT_TOKEN_RESULT ) {
      if ( !isError(action.payload) ) {
         //action.payload.user =  action.payload.user; //store.getState().auth;
         const {user, ...tokenData} = action.payload;
         const claims = decodeJwtToken(tokenData.accessToken);
         const activeUser = {
            user: claims.sub
         }
         user && (activeUser['superRole'] = user.role.superRole);
         jwtCollection = window.localStorage.getMap(JWT_COLLECTION_KEY);
         jwtCollection[claims.sub] = action.payload.accessToken;
         window.localStorage.setMap(JWT_COLLECTION_KEY, jwtCollection);
         window.localStorage.setMap('activeUser', activeUser);

         enforcer.authorities = claims.authorities;
         user && (enforcer.superRole = user.role.superRole);

         if (action.type === actionTypes.AUTH_RESULTS ) {
            window.localStorage.setMap('workspaceContext', user.workspaceBounds);
            setWorkspaceId(user.workspaceBounds);
         }

         setToken(action.payload.accessToken);
      }

   } else if ( action.type === actionTypes.LOGOUT ) {
      
      const authorizeUser = store.getState().global.authorizeUser;
      jwtCollection = window.localStorage.getMap(JWT_COLLECTION_KEY);
      authorizeUser && (delete jwtCollection[authorizeUser.username]);
      window.localStorage.setMap(JWT_COLLECTION_KEY, jwtCollection);
      window.localStorage.setMap('activeUser', '');
      window.localStorage.setMap('workspaceContext', '');
      enforcer.discard();
      setWorkspaceId(null);
      setToken(null);
   }
   //[]Store user in browser
   if (store.getState().auth.loginState.rememberMe && action.type === actionTypes.FETCHED_AUTH_USER_RESULT && !isError(action.payload) ) {
      
      const user = action.payload;
      let savedUsersList = window.localStorage.getMap(SAVED_USERS_KEY);
      savedUsersList = savedUsersList ? savedUsersList : {};
      
      const userEntry = {
         id: user.id,
         name: `${user.firstName} ${user.lastName}`,
         username: user.username,
         email: user.email
      }

      savedUsersList[userEntry.id] = userEntry;
      window.localStorage.setMap(SAVED_USERS_KEY, savedUsersList);
      store.dispatch(actions.setSavedUsersAction(savedUsersList));
   }
   next(action);
};

export default  jwtStorageMiddleware; 