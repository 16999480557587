import * as actionTypes from '../actionType';
import { isError } from '../../service/agent';
import { ApiRequestManager } from '../../utility/ApiRequestManager';
const initialState = {
    viewInit: false,
    view: '',
    mode: '',
    roleType: '',
    roles: [],
    chosenPermissions: [],
 };

const emptyArray = [];
var error;
let requestManager = null;
let apiOutcome = null;

const permissionsRolesReducer = (state = initialState, action ) => { 

    switch(action.type) {
        case actionTypes.PERMISSIONS_ROLES_LOADED: 
            return {
               ...state,
               ...initialState,
               viewInit: true
            };
        case actionTypes.PERMISSIONS_ROLES_VIEW_UNLOAD:
           return initialState;
        case actionTypes.PERMISSIONS_ROLE_VIEW :
            if (state.previousView === undefined) {
                return {
                    ...state,
                    view: action.payload,
                    previousView: action.payload
                }
            }else {
                return {
                    ...state,
                    view: action.payload
                }
            }
            
        case actionTypes.PERMISSIONS_ROLE_TYPE :
            return {
            ...state,
            roleType: action.payload
        }
        case actionTypes.FETCH_ROLES_PERMISSIONS_RESULTS :
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.FETCH_ROLES_PERMISSIONS) ?? {};
            
            return {
                ...state,
                roles:  apiOutcome?.success ? action.payload.roles : emptyArray,
            }
        case actionTypes.FETCH_ROLES_RESULTS :
            error = isError(action.payload);
            return {
            ...state,
            roles:  error ? emptyArray : action.payload
        }
        case actionTypes.PERMISSIONS_SELECTED_ROLE :
            return {
            ...state,
            selectedRole: action.payload
        }
        case actionTypes.CHOSEN_PERMISSIONS :
            return {
            ...state,
            chosenPermissions: action.payload
        }
        case actionTypes.SELECTED_SUPEUSER_ROLE :
            return {
            ...state,
            superRole: action.payload
        }
        case actionTypes.PERMISSIONS_ROLE_SUBMITED_RESULTS:
            
            requestManager = ApiRequestManager.getInstance(ApiRequestManager);
            apiOutcome = requestManager.getApiOutcome(actionTypes.PERMISSIONS_ROLE_SUBMIT_DATA) ?? {};
            if ( apiOutcome?.error) {
                return {
                    ...state
                }
            }else {
                var isUpdate = false;
                var rolesData = state.roles.map((role) => {
                    
                    if (action.payload.id === role.id) {
                        isUpdate = true;
                        return action.payload;
                    }else {
                        return role;
                    }
                });
                    
                if (isUpdate) {
                
                    return {
                        ...state,
                        formResults: action.payload,
                        roles: [
                            ...rolesData
                        ]
                    }

                }else {
                    return {
                        ...state,
                        formResults: action.payload,
                        roles: [
                            action.payload,
                            ...state.roles,
                        ],
                    }
                }
            }
        
            /*return {
                ...state,
                formResults: action.payload
            }*/
        case actionTypes.ROLENAME_FILED_CHANGED :
            return {
            ...state,
            roleName: action.payload
        }
        case actionTypes.PERMISSIONS_BACK_T0_ACTION :
                return {
                ...state,
                view: state.previousView !== action.payload ?  state.previousView : state.view ,
                previousView:  action.payload 
        }
        case actionTypes.ASYNC_START:
            if (action.payload.initiator === actionTypes.LOGIN) {
                return { ...state, inProgress: true };
            }
            break;
        default:
            return state;
    }
    return state;
};

export default permissionsRolesReducer;