import * as actionTypes from '../actionType';

export const addResultEntry = (entry) => ({
    type: actionTypes.RESULT_SHEET_ADD_RESULT_ENTRY,
    payload: entry
}); 

export const addResultSheetReport = (report) => ({
    type: actionTypes.RESULT_SHEET_ADD_REPORT,
    payload: report
});
export const discardResultSheetReport = (reports) => ({
    type: actionTypes.RESULT_SHEET_DISCARD_REPORT,
    payload: reports
});

export const submitResultSheet =  (payload) => ({
    type: actionTypes.RESULT_SHEET_SUBMIT,
    payload: payload
}); 

export const setSpeimenResultSheet = (resultSheet) => ({
    type: actionTypes.SET_SPECIMENT_RESULT_SHEET,
    payload: resultSheet
});